import { forwardRef } from 'react'
import formatDuration from 'helpers/utils/formatDuration'
import classes from './TimelineHelperLines.module.css'

type Props = {
  duration: number,
  zoom: number,
}

function TimelineHelperLines({ duration, zoom }: Props, ref) {
  const count = 4 * Math.pow(2, zoom - 1)
  const unit = Math.floor(duration / count) / 60
  const nodes = []
  for (let i = 0; i < count; i++) {
    const seconds = i * unit * 60
    nodes.push(
      <span key={i} style={{ left: `${(100 * seconds) / duration}%` }}>
        {formatDuration(seconds)}
      </span>
    )
  }

  return (
    <div className={classes.helpers} ref={ref}>
      {nodes}
      <span>{formatDuration(duration)}</span>
    </div>
  )
}

export default forwardRef(TimelineHelperLines)
