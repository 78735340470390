// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TCGEarningsClaimed_spinner__Vm3QI {\n  text-align: center;\n  color: var(--secondary);\n  font-size: 12px;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/users/components/TCGEarningsClaimed.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,kBAAmB;EAAnB,uBAAmB;EDAjB,eAAe;AACjB","sourcesContent":[".spinner {\n  @apply text-center text-secondary;\n  font-size: 12px;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "TCGEarningsClaimed_spinner__Vm3QI"
};
export default ___CSS_LOADER_EXPORT___;
