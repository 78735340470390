import { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import Button from 'shared/components/kit/button'
import { ReactComponent as UploadVideoSVG } from 'publisher/images/upload-video.svg'
import classes from './UploadVideoFile.module.css'

export default function UploadVideoFile({ onChange }) {
  const areaRef = useRef()

  const [status, setStatus] = useState('none')

  useEffect(() => {
    const handlerDragEnter = (event) => {
      event.preventDefault()
      event.stopPropagation()
      setStatus((prevStatus) => {
        if (prevStatus === 'none') {
          return 'over'
        }
        return prevStatus
      })
    }

    const handlerDragLeave = (event) => {
      event.preventDefault()
      event.stopPropagation()
      setStatus('none')
    }

    const handlerDragOver = (event) => {
      event.preventDefault()
      event.stopPropagation()
      setStatus('over')
    }

    const handlerDrop = (event) => {
      event.preventDefault()
      event.stopPropagation()
      onChange(event.dataTransfer.files[0])
    }

    areaRef.current.addEventListener('dragenter', handlerDragEnter, false)
    areaRef.current.addEventListener('dragleave', handlerDragLeave, false)
    areaRef.current.addEventListener('dragover', handlerDragOver, false)
    areaRef.current.addEventListener('drop', handlerDrop, false)

    return () => {
      if (areaRef.current) {
        areaRef.current.removeEventListener(
          'dragenter',
          handlerDragEnter,
          false
        )
        areaRef.current.removeEventListener(
          'dragleave',
          handlerDragLeave,
          false
        )
        areaRef.current.removeEventListener('dragover', handlerDragOver, false)
        areaRef.current.removeEventListener('drop', handlerDrop, false)
      }
    }
  }, [onChange])

  const onChooseFile = (event) => {
    onChange(event.target.files[0])
  }

  const classNames = classnames(classes.wrapper, {
    [classes.over]: status === 'over',
  })

  return (
    <div className={classNames} ref={areaRef}>
      <div className={classes.placeholder}>
        <UploadVideoSVG />
        <p>
          Drag and drop you media file here...
          <br />
          Supported formats: MP4, MP3 & WAV
        </p>
        <p>
          <small>
            (Your uploaded media will not be published at this stage)
          </small>
        </p>
        <br />
        <Button className={classes.uploadButton} component="label">
          <input
            accept="video/mp4,audio/mp3,audio/wav"
            onChange={onChooseFile}
            type="file"
          />
          Select File
        </Button>
      </div>
    </div>
  )
}
