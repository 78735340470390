export default function createFetcher(globalOptions) {
  const { apiEndpoint, middleware, headers: globalHeaders } = globalOptions

  return async function fetcher(url, options = {}) {
    const { baseUrl, body, method, ...otherFetchOptions } = options

    const headers = {
      Accept: 'application/json',
      ...globalHeaders(),
    }

    const absoluteUrl = `${baseUrl || apiEndpoint}${url}`

    let payload

    if (body instanceof FormData) {
      payload = body
    } else {
      headers['Content-Type'] = 'application/json'
      payload = JSON.stringify(body)
    }

    try {
      const response = await fetch(absoluteUrl, {
        ...otherFetchOptions,
        body: payload,
        headers,
        method,
      })

      let json

      try {
        json = await response.json()
      } catch (error) {}

      let result = {
        status: response.status,
        json,
      }

      if (middleware) {
        result = await middleware(result, {
          retry: () => fetcher(url, options),
          url,
        })
      }

      if (result.status >= 200 && result.status < 300) {
        return result
      } else {
        throw result
      }
    } catch (error) {
      throw error
    }
  }
}
