// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cell_cell__2izzp:hover {\n  border-color: var(--dark)\n}\n\n.Cell_cell__2izzp {\n  border-width: 1px;\n  display: flex;\n  flex-direction: column-reverse;\n  height: 6rem\n}\n\n.Cell_cell__2izzp:hover {\n  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);\n  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)\n}\n\n.Cell_cell__2izzp {\n  min-width: 6.6%;\n  max-width: 6.6%\n}\n\n.Cell_cell__2izzp.Cell_highlighted__2r3n7 > * {\n}\n", "",{"version":3,"sources":["webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js","webpack://src/admin/pages/ads-manager/ads-manager-campaigns/components/Cell.module.css"],"names":[],"mappings":"AAEA;EAAA;AAAmB;;AAAnB;EAAA,iBAAmB;EAAnB,aAAmB;EAAnB,8BAAmB;EAAnB;AAAmB;;AAAnB;EAAA,sFAAmB;EAAnB;AAAmB;;ACFnB;EAEE,eAAe;EACf;AACF;;AAEA;AACA","sourcesContent":["@tailwind base;\n@tailwind components;\n@tailwind utilities;",".cell {\n  @apply border flex flex-col-reverse h-24 hover:border-dark hover:shadow-xl;\n  min-width: 6.6%;\n  max-width: 6.6%;\n}\n\n.cell.highlighted > * {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": "Cell_cell__2izzp",
	"highlighted": "Cell_highlighted__2r3n7"
};
export default ___CSS_LOADER_EXPORT___;
