type EditCompany = {
  id: number,
  name: string,
  avatarUrl?: string,
  vatNumber: string,
  vatRate: string,
  streetAddress: string,
  streetNo: string,
  postalCode: string,
  city: string,
  country: string,
  contactPersonName: string,
  contactPersonEmail: string,
  contactPersonPhone: string,
  invoicingQuestionsEmail: string,
}
export default function editCompany(variables: EditCompany = {}) {
  const {
    id,
    name,
    avatarUrl,
    vatNumber,
    vatRate,
    streetAddress,
    streetNo,
    postalCode,
    city,
    country,
    contactPersonName,
    contactPersonEmail,
    contactPersonPhone,
    invoicingQuestionsEmail,
  } = variables
  return {
    method: 'PUT',
    path: `/admin/companies/${id}`,
    params: {
      name,
      avatar_url: avatarUrl,
      vat_number: vatNumber,
      vat_rate: vatRate,
      street_address: streetAddress,
      street_no: streetNo,
      postal_code: postalCode,
      city,
      country,
      contact_person_name: contactPersonName,
      contact_person_email: contactPersonEmail,
      contact_person_phone: contactPersonPhone,
      invoicing_questions_email: invoicingQuestionsEmail,
    },
  }
}
