import { useCallback, useEffect, useState } from 'react'
import useDebounceCallback from 'helpers/hooks/useDebounceCallback'
import Select from 'shared/components/form/select'

type Props = {
  loadDefaultOptions?: Function,
  loadOptions?: Function,
  onChange: Function,
  options?: Array,
  value?: string,
}

export default function AsyncSelect(props: Props) {
  const { loadDefaultOptions, loadOptions, value, ...rest } = props

  const [store, setStore] = useState({
    loading: false,
    options: [],
  })

  useEffect(() => {
    const load = async () => {
      setStore((prev) => ({
        ...prev,
        loading: true,
      }))
      try {
        const response = await loadDefaultOptions()
        setStore((prev) => ({
          loading: false,
          options: [response, ...prev.options],
        }))
      } catch (e) {
        setStore((prev) => ({
          ...prev,
          loading: false,
        }))
      }
    }
    if (
      loadDefaultOptions &&
      typeof value !== 'undefined' &&
      !store.options.some((opt) => opt.value === value)
    ) {
      load()
    }
  }, [loadDefaultOptions, store.options, value])

  const handleInputChange = useDebounceCallback(async (search) => {
    setStore((prev) => ({
      ...prev,
      loading: true,
    }))
    try {
      const response = await loadOptions(search)
      setStore({
        loading: false,
        options: response,
      })
    } catch (e) {
      setStore((prev) => ({
        ...prev,
        loading: false,
      }))
    }
  })

  const noOptionsMessage = useCallback(
    ({ inputValue }) => (inputValue ? 'No items found' : 'Type to search'),
    []
  )

  return (
    <Select
      {...rest}
      isLoading={store.loading}
      onInputChange={handleInputChange}
      options={store.options}
      noOptionsMessage={noOptionsMessage}
      value={value}
    />
  )
}
