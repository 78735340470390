type Variables = {
  key: string,
}

export default function getReasons(variables: Variables = {}) {
  const { key } = variables
  return {
    path: `/admin/options/reasons/${key}`,
  }
}
