import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import Cell from './Cell'
import { ReactComponent as ChevronLeftSVG } from 'svg/chevron-left.svg'
import { ReactComponent as ChevronRightSVG } from 'svg/chevron-right.svg'
import classes from './SlotsCalendar.module.css'

export default function SlotsCalendar({
  onClearSelection,
  onSelectedSlot,
  selectedSlots,
  startDate,
  onClickPrev,
  onClickNext,
  slots,
  tiers,
  tiersData,
  minValidDate,
}) {
  const [days, setDays] = useState([startDate])
  let today = dayjs().format('YYYY-MM-DD')

  const tiersDescriptions = [
    'Header ad on Home page',
    'Body ad below the player window (player page)',
    'Body ad above the related media on the right side (player page on DWA only)',
    'Header ad on Market page',
    'Header ad on all other pages excluding: \n -All Profile pages (signed in) \n -Saved media page (signed in) \n -Subscriptions page (signed in) \n -Hero page',
  ]

  const generateDays = () => {
    let _arr = [startDate]
    for (let i = 1; i < 14; i++) {
      _arr.push(dayjs(startDate).add(i, 'day').format('YYYY-MM-DD'))
    }
    setDays(_arr)
  }

  useEffect(() => {
    generateDays()
  }, [startDate])

  return (
    <div className={classes.wrapper}>
      <div className={classes.calendarHeaderWrapper}>
        <h3 className={classes.subHeader}>Select slots</h3>
        {onClearSelection && (
          <h4
            onClick={onClearSelection}
            className={classes.clearSelectionHeader}>
            Clear selection
          </h4>
        )}
      </div>
      <div className={classes.wrapperBox}>
        <div className={classes.calendar}>
          <ChevronLeftSVG
            className={minValidDate < today ? classes.inactive : ''}
            onClick={onClickPrev}
          />
          {tiers.map((tier, tierIndex) => {
            return (
              <div key={tierIndex} className={classes.calendarRow}>
                {days.map((day, index) => {
                  return (
                    <>
                      {index === 0 && (
                        <div
                          title={tiersDescriptions[tierIndex]}
                          className={classes.tier}>
                          {tier.name}
                        </div>
                      )}
                      <Cell
                        color={tier.color}
                        prefill={
                          tiersData.find(
                            (currentData) =>
                              currentData.tier === tier.name &&
                              day ===
                                dayjs(currentData.date).format('YYYY-MM-DD')
                          )?.prefilled || 0
                        }
                        slots={slots}
                        selectedSlots={
                          selectedSlots.find(
                            (slot) =>
                              slot.tier === tier.name && slot.date === day
                          )?.count || 0
                        }
                        tier={tier.name}
                        day={day}
                        onSelectedSlot={onSelectedSlot}
                      />
                    </>
                  )
                })}
              </div>
            )
          })}
          <ChevronRightSVG onClick={onClickNext} />
        </div>
        <div className={classes.daysRow}>
          {days.map((day, index) => {
            const today = dayjs().format('YYYY-MM-DD')
            const isCurrentDay = day === today
            return (
              <div
                className={isCurrentDay ? classes.highlighted : null}
                key={index}>
                <span>{day}</span>
                {isCurrentDay && <span>Today</span>}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
