import { useMemo } from 'react'
import Chart from 'shared/components/chart'
import useGetCSSColor from 'shared/hooks/useGetCSSColor'

export default function PointsChart({ data, labels, getDate }) {
  const getCSSColor = useGetCSSColor()

  const config = useMemo(() => {
    return {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            label: 'Points',
            backgroundColor: getCSSColor('secondary', 0.2),
            borderColor: getCSSColor('secondary'),
            data: data.map((item) => item.points),
            fill: true,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Points',
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              title: (tooltipItems) => {
                return getDate(data[tooltipItems[0].dataIndex].date)
              },
            },
          },
        },
      },
    }
  }, [getCSSColor, data, labels, getDate])

  return <Chart config={config} />
}
