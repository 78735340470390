// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Video_wrapper__3dXZ0 {\n  --tw-border-opacity: 1;\n  border-color: rgba(229, 231, 235, var(--tw-border-opacity));\n  border-radius: 0.25rem;\n  border-width: 1px;\n  max-height: 100%;\n  max-width: 100%;\n  overflow: hidden;\n  position: relative;\n  text-align: left;\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);\n}\n\n.Video_wrapper__3dXZ0 img {\n  max-height: 100%;\n  max-width: 100%;\n}\n\n.Video_wrapper__3dXZ0 h3 {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  padding: 0.75rem;\n  position: absolute;\n  top: 0px;\n  right: 0px;\n  left: 0px;\n  --tw-text-opacity: 1;\n  color: rgba(31, 41, 55, var(--tw-text-opacity));\n  background-color: rgba(255, 255, 255, 0.8);\n  line-height: 1.25;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/components/studio/components/layers/Video.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,sBAAmB;EAAnB,2DAAmB;EAAnB,sBAAmB;EAAnB,iBAAmB;EAAnB,gBAAmB;EAAnB,eAAmB;EAAnB,gBAAmB;EAAnB,kBAAmB;EAAnB,gBAAmB;EDAjB,sCAAsC;AACxC;;AAEA;ECHA,gBAAmB;EAAnB,eAAmB;ADKnB;;AAEA;ECPA,eAAmB;EAAnB,mBAAmB;EAAnB,gBAAmB;EAAnB,kBAAmB;EAAnB,QAAmB;EAAnB,UAAmB;EAAnB,SAAmB;EAAnB,oBAAmB;EAAnB,+CAAmB;EDSjB,0CAA0C;EAC1C,iBAAiB;AACnB","sourcesContent":[".wrapper {\n  @apply border border-gray-200 max-h-full max-w-full overflow-hidden relative rounded text-left;\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);\n}\n\n.wrapper img {\n  @apply max-h-full max-w-full;\n}\n\n.wrapper h3 {\n  @apply absolute left-0 p-3 right-0 text-base text-gray-800 top-0;\n  background-color: rgba(255, 255, 255, 0.8);\n  line-height: 1.25;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Video_wrapper__3dXZ0"
};
export default ___CSS_LOADER_EXPORT___;
