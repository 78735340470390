import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import useMutation from 'core/react-query/useMutation'
import useQuery from 'core/react-query/useQuery'
import useQueryClient from 'core/react-query/useQueryClient'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import formatDate from 'helpers/utils/formatDate'
import Button from 'shared/components/kit/button'
import ChannelAvatar from 'shared/components/channel-avatar'
import PaymentDetailStatus from 'shared/components/payment-detail-status'
import Table from 'shared/components/kit/table'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import Page from 'admin/components/page'
import changePaymentDetailStatus from 'admin/queries/changePaymentDetailStatus'
import getPaymentDetails from 'admin/queries/getPaymentDetails'
import archivePaymentDetail from 'admin/queries/archivePaymentDetail'
import Filters from './components/Filters'
import PaymentDetail from './components/PaymentDetail'

export default function PaymentDetails() {
  const INITIAL_FILTERS = useMemo(
    () => ({
      base: '/admin/payment-details',
      params: {
        page: 'pagination',
        sort: 'any',
        onlyArchived: 'boolean',
        status: 'new | code-sent | verified | expired | canceled',
        publisherId: 'any',
      },
    }),
    []
  )
  const queryClient = useQueryClient()
  const { id } = useParams()

  const [selectedRows, setSelectedRows] = useState([])

  const [filters, setFilters] = useFiltersFromURL(INITIAL_FILTERS)
  const { data, error, isError, isFetching } = useQuery(getPaymentDetails, {
    keepPreviousData: true,
    variables: {
      page: filters.page,
      sort: filters.sort,
      onlyArchived: filters.onlyArchived,
      status: filters.status,
      publisherId: filters.publisherId,
    },
  })

  useEffect(() => {
    setSelectedRows([])
  }, [isFetching])

  const {
    data: paymentDetails,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleFilter = (nextFilters) => {
    setFilters({
      ...nextFilters,
      page: undefined,
    })
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  const mutationMarkAsSent = useMutation(changePaymentDetailStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getPaymentDetails,
      })
      notification.success({
        title: 'Selected payment details marked as sent successfully!',
      })
    },
  })

  const handleMoveToArchive = async (e) => {
    e.preventDefault()
    Modal.open({
      title: 'Archive Payment Details',
      description: 'Are you sure you want to archive selected payment details?',
      onOk: async () => {
        try {
          await mutationMoveToArchive.mutateAsync({
            ids: selectedRows,
          })
        } catch (error) {
          notification.error({ title: error.message })
        }
      },
    })
  }

  const handleMarkAsSent = async (e) => {
    e.preventDefault()
    Modal.open({
      title: 'Change Payment Detail status',
      description: `Are you sure you want to change status to code sent?`,
      onOk: async () => {
        try {
          await mutationMarkAsSent.mutateAsync({
            ids: selectedRows,
            status: 'code-sent',
          })
        } catch (error) {
          notification.error({ title: error.message })
        }
      },
    })
  }

  const mutationMoveToArchive = useMutation(archivePaymentDetail, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getPaymentDetails,
      })
      notification.success({
        title: 'Selected payment details moved to archive successfully!',
      })
    },
  })

  if (isError) {
    return <Page error={error.message} />
  }

  const actions = (
    <>
      <Button
        onClick={handleMarkAsSent}
        disabled={selectedRows.length === 0}
        outlined
        secondary>
        Mark As Sent
      </Button>
      <Button
        onClick={handleMoveToArchive}
        disabled={selectedRows.length === 0}
        outlined
        secondary>
        Move To Archive
      </Button>
    </>
  )

  const handleRowSelect = (keys) => {
    setSelectedRows(keys)
  }

  return (
    <>
      <Page
        actions={actions}
        description={description}
        title="Proof of address requests">
        <Filters initialFilters={INITIAL_FILTERS} onSubmit={handleFilter} />
        <Table
          columns={getColumns(filters)}
          data={paymentDetails}
          loading={isFetching}
          currentPage={currentPage}
          lastPage={lastPage}
          onPaginate={handlePaginate}
          onRowSelect={handleRowSelect}
          selectedRows={selectedRows}
        />
      </Page>
      <PaymentDetail id={id} />
    </>
  )
}

function getColumns(filters) {
  return [
    {
      className: 'w-24',
      render: (paymentDetails) => (
        <ChannelAvatar
          channel={{
            name: paymentDetails.user.username,
            avatar: paymentDetails.user.avatar,
          }}
          size={14}
        />
      ),
    },
    {
      render: (paymentDetails) => paymentDetails.user.username,
    },
    {
      title: 'Code',
      render: (paymentDetails) =>
        paymentDetails.proof_code.match(/.{1,4}/g).join('-'),
    },
    {
      title: 'Address',
      render: (paymentDetails) => (
        <>
          {paymentDetails.first_name} {paymentDetails.last_name}
          <br /> {paymentDetails.company_name} <br />
          {paymentDetails.street_address} {paymentDetails.street_number}
          <br /> {paymentDetails.postal_code} {paymentDetails.city} <br />
          {paymentDetails.country}
        </>
      ),
    },
    {
      title: 'Status',
      render: (paymentDetails) => (
        <PaymentDetailStatus status={paymentDetails.status} />
      ),
    },
    {
      title: 'Creation Date',
      className: 'w-48',
      render: (paymentDetails) => formatDate(paymentDetails.created_at),
    },
    {
      className: 'text-right',
      render: (paymentDetails) => (
        <Button
          component="link"
          to={
            `/admin/payment-details/${paymentDetails.id}?` +
            (filters.page > 1 ? `&page=${filters.page}` : '') +
            (filters.sort ? `&sort=${filters.sort}` : '') +
            (filters.status ? `&status=${filters.status}` : '') +
            (filters.onlyArchived
              ? `&onlyArchived=${filters.onlyArchived}`
              : '') +
            (filters.publisherId ? `&publisherId=${filters.publisherId}` : '')
          }
          size="sm"
          secondary
          outlined>
          Manage
        </Button>
      ),
    },
  ]
}
