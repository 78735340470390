import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Input from 'shared/components/form/input'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import classes from 'admin/pages/requests/components/Filters.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <Input
        {...form.getInputPropsFor('search')}
        placeholder="Search Publisher Requests..."
      />
      <HtmlSelect {...form.getInputPropsFor('sort')}>
        <option>Sort By...</option>
        <option value="username">Username</option>
        <option value="email">Email</option>
      </HtmlSelect>
      <HtmlSelect {...form.getInputPropsFor('status')}>
        <option value="">All Requests...</option>
        <option value="confirmed">Confirmed</option>
        <option value="pending">Pending</option>
        <option value="rejected">Rejected</option>
      </HtmlSelect>
      <Button outlined>Search Publisher Requests</Button>
    </form>
  )
}
