// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignIn_signIn__2HjfD {\n  padding: 2rem;\n  width: 24rem;\n}\n\n.SignIn_signIn__2HjfD > h2 {\n  font-size: 1.5rem;\n  line-height: 2rem;\n  margin-bottom: 2rem;\n}\n\n.SignIn_forgot__1SKmJ {\n  cursor: pointer;\n  color: var(--neutral);\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/login/components/SignIn.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,YAAmB;ADAnB;;AAEA;ECFA,iBAAmB;EAAnB,iBAAmB;EAAnB,mBAAmB;ADInB;;AAEA;ECNA,eAAmB;EAAnB,qBAAmB;ADQnB","sourcesContent":[".signIn {\n  @apply p-8 w-96;\n}\n\n.signIn > h2 {\n  @apply mb-8 text-2xl;\n}\n\n.forgot {\n  @apply cursor-pointer text-neutral;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signIn": "SignIn_signIn__2HjfD",
	"forgot": "SignIn_forgot__1SKmJ"
};
export default ___CSS_LOADER_EXPORT___;
