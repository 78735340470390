import { useInfiniteQuery as useReactInfiniteQuery } from 'react-query'
import get from 'lodash/get'
import useFetcher from './useFetcher'

export default function useInfiniteQuery(query, options?) {
  const { variables, ...otherOptions } = options || {}
  const { name } = query(variables)
  const fetcher = useFetcher()
  const getData = async ({ pageParam }) => {
    const { url } = query({ ...variables, page: pageParam })
    try {
      return await fetcher(url)
    } catch (error) {
      throw error
    }
  }
  const qr = useReactInfiniteQuery(name, getData, {
    getNextPageParam: (lastPage, pages) => {
      const current = get(lastPage, 'json.meta.current_page')
      const last = get(lastPage, 'json.meta.last_page')
      if (current > 0 && last > 0 && current < last) {
        return current + 1
      }
    },
    ...otherOptions,
  })
  return {
    ...qr,
    name,
  }
}
