import { useState } from 'react'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import Button from 'shared/components/kit/button'
import Field from 'shared/components/form/field'
import RichText from 'shared/components/form/rich-text'
import Drawer from 'shared/components/kit/drawer'
import notification from 'shared/components/kit/notification'
import Input from 'shared/components/form/input'
import SendUserNotification from 'admin/queries/sendUserNotification'
import getNotificationsSentByAdmin from 'admin/queries/getNotificationsSentByAdmin'
import SelectUser from 'admin/components/form/select-user'
import Radio from 'shared/components/form/radio'
import CustomUsersSelect from 'admin/pages/messages/components/CustomUsersSelect'
import classes from './SendUserNotificationButton.module.css'

const validation = {
  message: {
    label: 'Message',
    rule: 'required',
  },
  subject: {
    label: 'Subject',
    rule: 'required',
  },
  userId: {
    rule: (value, values, validators) => {
      if (values.audienceType === 'single') {
        return validators.required('Username', value)
      }
    },
  },
  userIds: {
    rule: (value, values, validators) => {
      if (values.audienceType === 'many' && values.userGroup === 'custom') {
        return (
          validators.required('Username', value) ||
          validators.minLength('user', value, 1)
        )
      }
    },
  },
  userGroup: {
    rule: (value, values, validators) => {
      if (values.audienceType === 'many') {
        return validators.required('Audience type', value)
      }
    },
  },
}

const initialValues = {
  audienceType: 'single',
}

export default function SendUserNotificationButton() {
  const queryClient = useQueryClient()
  const [visible, setVisible] = useState(false)

  const mutation = useMutation(SendUserNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries({ query: getNotificationsSentByAdmin })
      setVisible(false)
      notification.success({
        title: 'Notification sent successfully!',
      })
    },
  })

  const form = useMiniForm({
    resetOnSuccess: true,
    validation,
    initialValues,
    async onSubmit(values) {
      try {
        await mutation.mutateAsync(values)
      } catch (error) {
        throw error
      }
    },
  })

  const handleClose = () => {
    form.reset()
    setVisible(false)
  }

  const handleShow = () => {
    setVisible(true)
  }

  return (
    <>
      <Button onClick={handleShow} outlined>
        Send a notification to user
      </Button>
      <Drawer
        title="Send a notification to user"
        onClose={handleClose}
        visible={visible}
        loading={form.submitting}>
        <form onSubmit={form.submit}>
          <div className={classes.audienceType}>
            <h4>Who user do you want to send a notification to?</h4>
            <div>
              {form.get('audienceType') === 'single' && (
                <Button
                  type="button"
                  size="sm"
                  onClick={() => form.change('audienceType', 'many')}
                  outlined
                  secondary>
                  Send to many
                </Button>
              )}
              {form.get('audienceType') === 'many' && (
                <Button
                  type="button"
                  size="sm"
                  onClick={() => form.change('audienceType', 'single')}
                  outlined
                  secondary>
                  Send to single
                </Button>
              )}
            </div>
          </div>

          <Field
            className={form.get('audienceType') === 'single' ? '' : 'hidden'}
            {...form.getErrorPropsFor('userId')}>
            <SelectUser
              key={visible ? '1' : '0'}
              {...form.getInputPropsFor('userId')}
              isClearable
              placeholder="Select user..."
            />
          </Field>
          <Field
            className={form.get('audienceType') === 'many' ? '' : 'hidden'}
            {...form.getErrorPropsFor('userGroup')}>
            <Radio
              checked={form.get('userGroup') === 'all'}
              onChange={() => {
                form.change('userGroup', 'all')
              }}
              description="All users"
            />
            <Radio
              checked={form.get('userGroup') === 'hero'}
              onChange={() => {
                form.change('userGroup', 'hero')
              }}
              description="Heroes"
            />
            <Radio
              checked={form.get('userGroup') === 'non-hero'}
              onChange={() => {
                form.change('userGroup', 'non-hero')
              }}
              description="Non Heroes"
            />
            <Radio
              checked={form.get('userGroup') === 'custom'}
              onChange={() => form.change('userGroup', 'custom')}
              description="Custom"
            />
          </Field>
          {form.get('userGroup') === 'custom' &&
            form.get('audienceType') === 'many' && (
              <Field {...form.getErrorPropsFor('userIds')}>
                <CustomUsersSelect form={form} />
              </Field>
            )}
          <Field label="Subject" {...form.getErrorPropsFor('subject')}>
            <Input
              maxLength={50}
              placeholder="Subject..."
              {...form.getInputPropsFor('subject')}
            />
          </Field>
          <Field label="Message" {...form.getErrorPropsFor('message')}>
            <RichText
              {...form.getInputPropsFor('message')}
              placeholder="Write your message here..."
              multiline
            />
          </Field>
          <Button type="submit">Send Notification</Button>
        </form>
      </Drawer>
    </>
  )
}
