import { useParams } from 'react-router'
import { useMemo, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import getUserName from 'helpers/utils/getUserName'
import formatDate from 'helpers/utils/formatDate'
import truncate from 'helpers/utils/truncate'
import UserAvatar from 'shared/components/user-avatar'
import Button from 'shared/components/kit/button'
import Table from 'shared/components/kit/table'
import Page from 'admin/components/page'
import getNotificationsSentByAdmin from 'admin/queries/getNotificationsSentByAdmin'
import SendUserNotificationButton from './components/SendUserNotificationButton'
import SendPublisherNotificationButton from './components/SendPublisherNotificationButton'
import AudiencesPopover from './components/AudiencesPopover'
import Notification from './components/Notification'
import Filters from './components/Filters'
import classes from './index.module.css'

const INITIAL_FILTERS = {
  page: 1,
  userGroup: null,
  userId: null,
}

export default function Notifications() {
  const [filters, setFilters] = useState(INITIAL_FILTERS)
  const { notificationId } = useParams()
  const { data, error, isError, isFetching } = useQuery(
    getNotificationsSentByAdmin,
    {
      keepPreviousData: true,
      variables: filters,
    }
  )

  const {
    data: notifications,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  if (isError) {
    return <Page error={error.message} />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  const actions = (
    <>
      <SendPublisherNotificationButton />
      <SendUserNotificationButton />
    </>
  )

  return (
    <>
      <Page
        title="Notifications sent by admin"
        actions={actions}
        description={description}>
        <Filters initialFilters={INITIAL_FILTERS} onSubmit={setFilters} />
        <Table
          columns={columns}
          data={notifications}
          loading={isFetching}
          currentPage={currentPage}
          lastPage={lastPage}
          onPaginate={handlePaginate}></Table>
      </Page>
      <Notification notificationId={notificationId} />
    </>
  )
}

function getColumns() {
  return [
    {
      className: classes.avatar,
      render: (notification) => <UserAvatar user={notification?.from} />,
    },
    {
      className: 'w-40',
      title: 'Username',
      render: (notification) => (
        <span className="mr-4">{getUserName(notification?.from)}</span>
      ),
    },
    {
      className: 'w-auto',
      title: 'Subject',
      render: (notification) => (
        <span title={notification.payload.subject}>
          {truncate(notification.payload.subject || '', 150)}
        </span>
      ),
    },
    {
      className: 'w-56',
      title: 'To',
      render: (notification) =>
        notification.user_group === 'publisher' ? (
          'All Publishers'
        ) : notification.user_group === 'non-publisher' ? (
          'All Users'
        ) : notification.user_group === 'hero' ? (
          'Hodl Users'
        ) : notification.user_group === 'non-hero' ? (
          'Non-Hodl Users'
        ) : notification.user_group === 'all' &&
          notification.scope === 'publisher' ? (
          'All Publishers'
        ) : notification.user_group === 'all' &&
          notification.scope === 'global' ? (
          'All Users'
        ) : (
          <AudiencesPopover notification={notification} />
        ),
    },
    {
      className: 'w-1/6',
      title: 'Date',
      render: (notification) => formatDate(notification.created_at),
    },
    {
      className: 'text-right',
      render: (notification) => (
        <Button
          component="link"
          to={`/admin/notifications/${notification.id}`}
          size="sm"
          secondary
          outlined>
          Open Conversation
        </Button>
      ),
    },
  ]
}
