// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login_wrapper__sv2i6 {\n  background-color: var(--dark);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/login/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,6BAAmB;EAAnB,aAAmB;EAAnB,mBAAmB;EAAnB,uBAAmB;EAAnB,aAAmB;ADAnB","sourcesContent":[".wrapper {\n  @apply bg-dark flex h-screen items-center justify-center;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "login_wrapper__sv2i6"
};
export default ___CSS_LOADER_EXPORT___;
