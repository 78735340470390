import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import formatNumber from 'helpers/utils/formatNumber'
import getAdminTokenPointsOverview from 'admin/queries/getAdminTokenPointsOverview'
import { ReactComponent as TCGSVG } from 'svg/tcg-circle.svg'
import classes from './ProgressBar.module.css'

export default function ProgressBar() {
  const { data, error, isError, isLoading } = useQuery(
    getAdminTokenPointsOverview
  )

  const jsonData = get(data, 'json', {})

  const tcgDistributedPercent = isLoading
    ? 0
    : (100 * jsonData.overview.total_tokens_distributed) /
      jsonData.overview.total_tokens

  return (
    <>
      <div className={classes.progressTitle}>
        <div>
          <TCGSVG /> {tcgDistributedPercent.toFixed(2)}% distributed
        </div>
        <span>
          <span>
            {jsonData.overview?.total_tokens_distributed
              ? formatNumber(jsonData.overview?.total_tokens_distributed)
              : '0'}{' '}
            /{' '}
            {jsonData.overview?.total_tokens
              ? formatNumber(jsonData.overview?.total_tokens)
              : '0'}
          </span>
        </span>
      </div>
      <div className={classes.progressBackColor}>
        <div className={classes.progress}>
          <div
            className={classes.progressValue}
            style={{ width: `${tcgDistributedPercent}%` }}
          />
        </div>
      </div>
    </>
  )
}
