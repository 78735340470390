type GetPlaylistVideosQueryVariables = {
  id: number,
  page?: number,
  includeCategory?: string,
}

export default function getPlaylistVideos(
  variables: GetPlaylistVideosQueryVariables = {}
) {
  const { page, id, includeCategory } = variables
  const include = []
  if (includeCategory) {
    include.push('category')
  }
  return {
    name: 'playlist-videos',
    path: '/publisher/videos',
    params: {
      filters: {
        playlist: id,
      },
      page,
      include: include.length ? include.join(',') : undefined,
    },
  }
}
