import { useState } from 'react'
import useQueryClient from 'core/useQueryClient'
import useMutation from 'core/useMutation'
import bulkDeleteVideos from 'publisher/queries/bulkDeleteVideos'
import getMyVideos from 'publisher/queries/getMyVideos'
import Button from 'shared/components/kit/button'
import notification from 'shared/components/kit/notification'
import Modal from 'shared/components/kit/modal'

export default function BulkDeleteButton({ videos }) {
  const [visible, setVisible] = useState(false)

  const queryClient = useQueryClient()

  const mutation = useMutation(bulkDeleteVideos, {
    onSuccess() {
      queryClient.invalidateQueries({
        query: getMyVideos,
      })
    },
  })

  const handleShow = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleOk = async () => {
    try {
      await mutation.mutateAsync({
        videos,
      })
      notification.success({
        title:
          videos.length > 1
            ? `Selected media deleted successfully!`
            : 'Media deleted successfully!',
      })
      setVisible(false)
    } catch (error) {
      setVisible(false)
      notification.error({
        title: error.message,
      })
    }
  }

  return (
    <>
      <Button disabled={videos.length === 0} onClick={handleShow}>
        {videos.length > 1 ? 'Delete Media' : 'Delete Media'}
      </Button>

      <Modal
        cancelText="No"
        okText={videos.length > 1 ? 'Delete Media' : 'Delete Media'}
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        submitting={mutation.isLoading}
        title={
          videos.length > 1 ? `Delete ${videos.length} Media` : 'Delete Media'
        }
        visible={visible}>
        {videos.length > 1
          ? `Are you sure you want to delete ${videos.length} selected media?`
          : 'Are you sure you want to delete the selected media?'}
      </Modal>
    </>
  )
}
