// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "svg.logo_logo__3-_4A .logo_path1__2Yjfn {\n  fill: var(--dark);\n}\n\nsvg.logo_logo__3-_4A .logo_path2__1N9gs {\n  fill: var(--primary);\n}\n\nsvg.logo_logo__3-_4A.logo_monoColor___ukqb.logo_forceDarkMode__g9xbm .logo_path2__1N9gs {\n  fill: var(--light);\n}\n\nsvg.logo_logo__3-_4A .logo_path3__Jsvh1 {\n  fill: var(--light);\n}\n\n.dark svg.logo_logo__3-_4A .logo_path1__2Yjfn,\nsvg.logo_forceDarkMode__g9xbm .logo_path1__2Yjfn,\nsvg.logo_monoColor___ukqb.logo_forceDarkMode__g9xbm .logo_path1__2Yjfn {\n  fill: var(--light);\n}\n\nsvg.logo_monoColor___ukqb .logo_path1__2Yjfn {\n  fill: var(--primary);\n}\n\n.dark svg.logo_logo__3-_4A .logo_path3__Jsvh1,\nsvg.logo_forceDarkMode__g9xbm .logo_path3__Jsvh1 {\n  fill: var(--dark);\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/logo/index.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;;EAGE,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;;EAEE,iBAAiB;AACnB","sourcesContent":["svg.logo .path1 {\n  fill: var(--dark);\n}\n\nsvg.logo .path2 {\n  fill: var(--primary);\n}\n\nsvg.logo.monoColor.forceDarkMode .path2 {\n  fill: var(--light);\n}\n\nsvg.logo .path3 {\n  fill: var(--light);\n}\n\n:global(.dark) svg.logo .path1,\nsvg.forceDarkMode .path1,\nsvg.monoColor.forceDarkMode .path1 {\n  fill: var(--light);\n}\n\nsvg.monoColor .path1 {\n  fill: var(--primary);\n}\n\n:global(.dark) svg.logo .path3,\nsvg.forceDarkMode .path3 {\n  fill: var(--dark);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "logo_logo__3-_4A",
	"path1": "logo_path1__2Yjfn",
	"path2": "logo_path2__1N9gs",
	"monoColor": "logo_monoColor___ukqb",
	"forceDarkMode": "logo_forceDarkMode__g9xbm",
	"path3": "logo_path3__Jsvh1"
};
export default ___CSS_LOADER_EXPORT___;
