import { useCallback } from 'react'
import { forwardRef } from 'react'
import ReactSelect from 'react-select'
import classnames from 'classnames'
import './index.css'

type Props = {
  isMulti?: boolean,
  isRawValue?: boolean,
  onChange: Function,
  options?: Array,
  value?: string,
}

function Select(props: Props, ref) {
  const { className, isMulti, onChange, options, isRawValue, value, ...rest } =
    props

  const handleChange = useCallback(
    (payload) => {
      if (onChange) {
        if (isRawValue) {
          onChange(payload)
        } else {
          if (payload) {
            onChange(
              isMulti ? payload.map((item) => item.value) : payload.value
            )
          } else {
            onChange()
          }
        }
      }
    },
    [isMulti, isRawValue, onChange]
  )

  const finalOptions = options || []

  let selectedOption = value

  if (!isRawValue) {
    const finalValue = isMulti ? value || [] : value
    selectedOption = isMulti
      ? finalOptions.filter((opt) => finalValue.indexOf(opt.value) > -1)
      : finalOptions.find((opt) => opt.value === value)
  }

  return (
    <ReactSelect
      className={classnames(className, 'rs__container')}
      classNamePrefix="rs"
      ref={ref}
      isMulti={isMulti}
      menuPlacement="auto"
      onChange={handleChange}
      options={options}
      // styles={customStyles}
      // theme={customTheme}
      value={selectedOption}
      {...rest}
    />
  )
}

export default forwardRef(Select)
