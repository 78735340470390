import { useMemo } from 'react'
import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Input from 'shared/components/form/input'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import classes from './Filters.module.css'

export default function Filters({ filters, onSubmit }) {
  const form = useMiniForm({
    initialValues: useMemo(() => {
      return {
        status: filters.status,
      }
    }, [filters]),
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })
  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <Input
        {...form.getInputPropsFor('search')}
        placeholder="Search Campaigns..."
      />
      <HtmlSelect {...form.getInputPropsFor('sort')}>
        <option value="">Sort By...</option>
        <option value="newest">Newest</option>
        <option value="oldest">Oldest</option>
      </HtmlSelect>
      <HtmlSelect {...form.getInputPropsFor('status')}>
        <option value="">All Campaigns</option>
        <option value="active">Active</option>
        <option value="draft">Draft</option>
        <option value="archive">Archived</option>
      </HtmlSelect>
      <Button outlined>Search Campaign</Button>
    </form>
  )
}
