import React, { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import useQuery from 'core/react-query/useQuery'
import cookie from 'core/cookie'
import env from 'core/env'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import formatPrice from 'helpers/utils/formatPrice'
import Table from 'shared/components/kit/table'
import EarningStatus from 'shared/components/earning-status'
import Button from 'shared/components/kit/button'
import getMyChannelEarnings from 'publisher/queries/getMyChannelEarnings'
import Page from 'publisher/components/page'
import HowItWorksModal from 'publisher/components/how-it-works-modal'
import Filters from './components/Filters'
import MonetizationEarningShare from 'publisher/components/monetization-earning-share'
import classes from './index.module.css'

export default function Payout() {
  const FILTER_CONFIG = useMemo(
    () => ({
      base: `/publisher/payout`,
      params: {
        status: 'string',
        page: 'pagination',
      },
    }),
    []
  )

  const [monetizationEarningShareVisible, setMonetizationEarningShareVisible] =
    useState(false)

  const [earningShareAmount, setEarningShareAmount] = useState(0)

  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)
  const [loaderVisible, setLoaderVisible] = useState(false)
  const [exportedEarningId, setExportedEarningId] = useState()
  const [howItWorksVisible, setHowItWorksVisible] = useState(false)
  const { data, error, isError, isFetching } = useQuery(getMyChannelEarnings, {
    keepPreviousData: true,
    variables: {
      status: filters.status,
      page: filters.page,
      includeUser: true,
      includeTransaction: true,
    },
  })

  const {
    data: earnings,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const exportPayout = async (id, date) => {
    setExportedEarningId(id)
    setLoaderVisible(true)
    let myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${cookie().get('jwt')}`)

    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    const apiBase = env('API_URL')
    await fetch(
      `${apiBase}/publisher/earnings/${id}/export-as-pdf`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', `payout-history-${date}`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setLoaderVisible(false)
      })
      .catch((error) => {
        setLoaderVisible(false)
        console.log('error', error)
      })
  }

  const handleCloseHowItWorks = () => {
    setHowItWorksVisible(false)
  }

  const handleCloseMonetizationEarningShareVisible = () => {
    setMonetizationEarningShareVisible(false)
  }

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  if (isError) {
    return <Page error={error.message} />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  const links = (
    <>
      <Button component="link" to="/publisher/monetize" outlined secondary>
        Points & Earn
      </Button>
      <Button
        component="link"
        to="/publisher/payment-details"
        outlined
        secondary>
        Payment Details
      </Button>
      <Button component="link" to="/publisher/payout" mood="neutral">
        Payout History
      </Button>
      <Button onClick={() => setHowItWorksVisible(true)}>How it works?</Button>
    </>
  )

  const downloadPdf = async (date) => {
    let myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${cookie().get('jwt')}`)

    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    const apiBase = env('API_URL')
    await fetch(
      `${apiBase}/publisher/monetization/payouts/export/pdf?month=${date}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', `publisher-earning-${date}.pdf`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => console.log('error', error))
  }

  return (
    <Page actions={links} description={description} title="Payout History">
      <Filters initialFilters={filters} onSubmit={setFilters} />
      <Table
        columns={getColumns(
          exportPayout,
          loaderVisible,
          exportedEarningId,
          setEarningShareAmount,
          setMonetizationEarningShareVisible,
          downloadPdf
        )}
        data={earnings}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
      <HowItWorksModal
        visible={howItWorksVisible}
        onClose={handleCloseHowItWorks}
      />
      <MonetizationEarningShare
        visible={monetizationEarningShareVisible}
        onClose={handleCloseMonetizationEarningShareVisible}
        amount={earningShareAmount}
      />
    </Page>
  )
}

function getColumns(
  exportPayout,
  loaderVisible,
  exportedEarningId,
  setEarningShareAmount,
  setMonetizationEarningShareVisible,
  downloadPdf
) {
  return [
    {
      align: 'left',
      title: 'Monetization Month',
      className: 'w-52',
      render: (earning) =>
        dayjs(earning.monetization.month).format('MMMM, YYYY'),
    },
    {
      title: 'Status',
      className: 'w-40',
      align: 'center',
      render: (earning) => <EarningStatus status={earning.status} />,
    },
    {
      title: 'Amount',
      className: 'w-40',
      render: (earning) => formatPrice(earning.amount, '$', 1),
    },
    {
      title: 'Currency',
      className: 'w-40',
      render: (earning) => 'USDT',
    },
    {
      title: 'Receiving Address',
      align: 'left',
      className: 'w-64',
      render: (earning) =>
        earning.wallet_address != null ? (
          earning.wallet_address
        ) : (
          <Button
            component="link"
            to="/publisher/payment-details"
            mood="neutral"
            size="md">
            Fill in payment details
          </Button>
        ),
    },
    {
      align: 'center',
      title: 'Export',
      className: 'w-72',
      render: (earning) => (
        <>
          <Button
            mood="secondary"
            onClick={() => {
              setEarningShareAmount(earning.amount)
              setMonetizationEarningShareVisible(true)
            }}>
            CHA-CHING
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            mood="secondary"
            onClick={() => {
              downloadPdf(
                dayjs(earning.monetization.month).format('YYYY-MM-01')
              )
            }}>
            report
          </Button>
        </>
      ),
    },
  ]
}
