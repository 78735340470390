// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "form.Filters_filters__20XVY {\n  display: flex;\n  margin-bottom: 2rem;\n}\n\nform.Filters_filters__20XVY .Filters_search__44n6Y {\n  margin-right: 1rem;\n  width: 24rem;\n}\n\nform.Filters_filters__20XVY .Filters_category__xxnhY {\n  margin-right: 1rem;\n  width: 14rem;\n}\n\nform.Filters_filters__20XVY .Filters_sort__1167c {\n  margin-right: 1rem;\n  width: 14rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/channel/videos/components/Filters.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,mBAAmB;ADAnB;;AAEA;ECFA,kBAAmB;EAAnB,YAAmB;ADInB;;AAEA;ECNA,kBAAmB;EAAnB,YAAmB;ADQnB;;AAEA;ECVA,kBAAmB;EAAnB,YAAmB;ADYnB","sourcesContent":["form.filters {\n  @apply flex mb-8;\n}\n\nform.filters .search {\n  @apply mr-4 w-96;\n}\n\nform.filters .category {\n  @apply mr-4 w-56;\n}\n\nform.filters .sort {\n  @apply mr-4 w-56;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": "Filters_filters__20XVY",
	"search": "Filters_search__44n6Y",
	"category": "Filters_category__xxnhY",
	"sort": "Filters_sort__1167c"
};
export default ___CSS_LOADER_EXPORT___;
