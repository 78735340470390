import React, { useCallback, useRef, useState } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import 'shared/styles/cropper-rounded.css'
import classnames from 'classnames'
import useFetcher from 'core/useFetcher'
import getFileAbsoluteUrl from 'core/getFileAbsoluteUrl'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import Spin from 'shared/components/kit/spin'
import { ReactComponent as UploadSvg } from 'svg/upload.svg'
import RangeInput from 'shared/components/form/rangeInput'
import classes from './index.module.css'

type Props = {
  className?: string,
  circle?: boolean,
  height: number,
  maxHeight?: number,
  maxSizeKB?: number,
  maxWidth?: number,
  onChange: Function,
  pixels?: boolean,
  width: number,
}

export default function Index(props: Props) {
  const {
    className,
    circle,
    height,
    maxHeight,
    maxSizeKB,
    maxWidth,
    onChange,
    pixels,
    placeholder,
    value,
    width,
  } = props
  const fetcher = useFetcher()
  const inputRef = useRef(null)
  const cropperRef = useRef(null)
  const [uploading, setUploading] = useState(false)
  const [image, setImage] = useState()
  const [zoom, setZoom] = useState(1)

  const handleChooseFile = (event) => {
    event.preventDefault()
    let file
    if (event.dataTransfer) {
      file = event.dataTransfer.files[0]
    } else if (event.target) {
      file = event.target.files[0]
    }
    const fileSize = file.size / 1024
    if (maxSizeKB && maxSizeKB < fileSize) {
      notification.error({
        title: `File too large! Please choose an image smaller than ${
          maxSizeKB / 1024
        }MB`,
      })
    } else {
      const reader = new FileReader()
      reader.onload = (e) => {
        const img = new Image()
        img.src = e.target.result
        img.onload = function () {
          const error = validateFileSize({
            height: this.height,
            width: this.width,
            maxHeight,
            maxWidth,
            minHeight: height,
            minWidth: width,
          })
          if (error) {
            notification.error({ title: error })
          } else {
            setImage({
              image: reader.result,
              type: file.type,
            })
          }
        }
      }
      reader.readAsDataURL(file)
    }
    inputRef.current.value = null
  }

  const handleChange = (e) => {
    setZoom(Number(e.target.value))
  }

  const handleZoom = (e) => {
    if (e.detail.ratio < 5) {
      setZoom(e.detail.ratio)
    }
  }

  const handleOk = useCallback(() => {
    setUploading(true)

    cropperRef?.current?.cropper.getCroppedCanvas().toBlob(async (blob) => {
      const formData = new FormData()
      formData.append('file', blob)

      try {
        const response = await fetcher('/upload', {
          method: 'post',
          formData,
        })
        onChange(response.json.file)
        setUploading(false)
        setImage(false)
      } catch (error) {
        setUploading(false)
        notification.error({
          title: error.message,
        })
      }
    }, image.type)
  }, [fetcher, image, onChange])

  const handleCancel = useCallback(() => {
    setImage(null)
  }, [])

  const wrapperClassName = classnames(classes.wrapper, className, {
    [classes.circle]: circle,
  })

  const imageUrl = getFileAbsoluteUrl(value, '')

  const wrapperStyle = pixels
    ? {
        height,
        width,
      }
    : undefined

  return (
    <>
      <label className={wrapperClassName} style={wrapperStyle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${width} ${height}`}
        />
        <input
          disabled={uploading}
          onChange={handleChooseFile}
          ref={inputRef}
          type="file"
        />
        <div className={classes.overlay}>
          {value ? (
            <div
              className={classes.preview}
              hidden={!value}
              style={{
                backgroundImage: `url(${imageUrl})`,
              }}
            />
          ) : (
            <div className={classes.placeholder}>
              <UploadSvg />
              <p>{placeholder}</p>
            </div>
          )}
          {uploading ? (
            <div className={classes.uploading}>
              <Spin />
            </div>
          ) : null}
          {!uploading && value ? (
            <div className={classnames(classes.placeholder, classes.hover)}>
              <UploadSvg />
              <p>{placeholder}</p>
            </div>
          ) : null}
        </div>
      </label>

      <Modal
        okText="Crop Image"
        onCancel={handleCancel}
        onOk={handleOk}
        submitting={uploading}
        title="Crop Image"
        visible={!!image}>
        <div className={classes.crop}>
          <Cropper
            zoom={handleZoom}
            ref={cropperRef}
            style={{ height: 350, width: '100%' }}
            zoomTo={zoom}
            aspectRatio={width / height}
            cropBoxMovable={false}
            src={image?.image}
            viewMode={1}
            dragMode="move"
            // minCropBoxHeight={height}
            // minCropBoxWidth={width}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            guides={true}
          />
          <RangeInput
            min="0"
            max="2"
            value={zoom}
            step="0.01"
            handleChange={handleChange}
          />
        </div>
      </Modal>
    </>
  )
}

Index.defaultProps = {
  maxSizeKB: 1.5 * 1024,
  placeholder: 'Choose a Picture...',
}

function validateFileSize(data) {
  const { height, maxHeight, maxWidth, minHeight, minWidth, width } = data
  if (minHeight && minWidth && (height < minHeight || width < minWidth)) {
    return `Your image should be be at least ${minWidth}x${minHeight}`
  } else if (minHeight) {
    // Ignore for now
  } else if (minWidth) {
    // Ignore for now
  }
  if (maxHeight && maxWidth && (height > maxHeight || width > maxWidth)) {
    return `Image too big! Your image must not be bigger than ${maxWidth}x${maxHeight}`
  } else if (maxHeight) {
    // Ignore for now
  } else if (maxWidth) {
    // Ignore for now
  }
}
