// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DistributionChart_charts__34TB4 {\n  display: flex;\n  justify-content: center;\n  margin-top: 7rem;\n}\n\n.DistributionChart_charts__34TB4 .DistributionChart_box__2X3pW {\n  min-width: 558px;\n  max-width: 558px;\n}\n\n.DistributionChart_charts__34TB4 select {\n  width: 12rem;\n}\n\n.DistributionChart_charts__34TB4 > .box {\n  padding: 1.5rem;\n}\n\n.DistributionChart_charts__34TB4 > .box > header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/TCG/components/DistributionChart.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,uBAAmB;EAAnB,gBAAmB;ADAnB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;ECPA,YAAmB;ADSnB;;AAEA;ECXA,eAAmB;ADanB;;AAEA;ECfA,aAAmB;EAAnB,mBAAmB;EAAnB,8BAAmB;EAAnB,mBAAmB;ADiBnB","sourcesContent":[".charts {\n  @apply flex justify-center mt-28;\n}\n\n.charts .box {\n  min-width: 558px;\n  max-width: 558px;\n}\n\n.charts select {\n  @apply w-48;\n}\n\n.charts > :global(.box) {\n  @apply p-6;\n}\n\n.charts > :global(.box) > header {\n  @apply flex items-center justify-between mb-4;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"charts": "DistributionChart_charts__34TB4",
	"box": "DistributionChart_box__2X3pW"
};
export default ___CSS_LOADER_EXPORT___;
