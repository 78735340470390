// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qualified_channelName__3RPj1 {\n  width: 25%;\n}\n\n.qualified_channelEmail__3Bi_O {\n  width: 25%;\n}\n\n.qualified_paymentDetailsStatus__2rfcQ {\n  width: 25%;\n}\n\n.qualified_action__2bXad {\n  width: 25%;\n}\n\n.qualified_paymentDetailsStatus__2rfcQ > span {\n  display: flex;\n  align-items: center;\n  font-weight: 700;\n  text-transform: capitalize;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/monetization/qualified/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,UAAmB;ADAnB;;AAEA;ECFA,UAAmB;ADInB;;AAEA;ECNA,UAAmB;ADQnB;;AAEA;ECVA,UAAmB;ADYnB;;AAEA;ECdA,aAAmB;EAAnB,mBAAmB;EAAnB,gBAAmB;EAAnB,0BAAmB;ADgBnB","sourcesContent":[".channelName {\n  @apply w-1/4;\n}\n\n.channelEmail {\n  @apply w-1/4;\n}\n\n.paymentDetailsStatus {\n  @apply w-1/4;\n}\n\n.action {\n  @apply w-1/4;\n}\n\n.paymentDetailsStatus > span {\n  @apply flex items-center capitalize font-bold;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"channelName": "qualified_channelName__3RPj1",
	"channelEmail": "qualified_channelEmail__3Bi_O",
	"paymentDetailsStatus": "qualified_paymentDetailsStatus__2rfcQ",
	"action": "qualified_action__2bXad"
};
export default ___CSS_LOADER_EXPORT___;
