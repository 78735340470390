// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notifications_avatar__1I5t9 {\n  width: 5rem;\n}\n\n.notifications_avatar__1I5t9 img {\n  border-radius: 9999px;\n  display: block;\n  max-height: 2.5rem;\n  max-width: 2.5rem;\n  min-height: 2.5rem;\n  min-width: 2.5rem;\n}\n\n.notifications_summary__2Ypmb {\n  display: flex;\n  justify-content: center;\n  padding-bottom: 1rem;\n  gap: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/notifications/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,WAAmB;ADAnB;;AAEA;ECFA,qBAAmB;EAAnB,cAAmB;EDIjB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;ECVA,aAAmB;EAAnB,uBAAmB;EAAnB,oBAAmB;EAAnB,SAAmB;ADYnB","sourcesContent":[".avatar {\n  @apply w-20;\n}\n\n.avatar img {\n  @apply block rounded-full;\n  max-height: 2.5rem;\n  max-width: 2.5rem;\n  min-height: 2.5rem;\n  min-width: 2.5rem;\n}\n\n.summary {\n  @apply flex justify-center gap-4 pb-4;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "notifications_avatar__1I5t9",
	"summary": "notifications_summary__2Ypmb"
};
export default ___CSS_LOADER_EXPORT___;
