import { useState } from 'react'
import { Link } from 'react-router-dom'
import useMiniForm from 'core/useMiniForm'
import { ReactComponent as TwitterSVG } from 'icons/twitter-circle-filled.svg'
import { ReactComponent as LinkedinSVG } from 'icons/linkedin-circle-filled.svg'
import { ReactComponent as FacebookSVG } from 'icons/facebook-circle-filled.svg'
import { ReactComponent as TelegramSVG } from 'icons/telegram-circle-filled.svg'
import Modal from 'shared/components/kit/modal'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import useMutation from 'core/react-query/useMutation'
import contact from 'publisher/mutations/contact'
import notification from 'shared/components/kit/notification'
import Logo from 'shared/components/logo'
import classes from './index.module.css'

const validationContact = {
  firstName: {
    label: 'First name',
    rule: 'required',
  },
  lastName: {
    label: 'Last name',
    rule: 'required',
  },
  email: {
    label: 'E-Mail',
    rule: (value, values, validators) => {
      return (
        validators.required('E-Mail', value) ||
        validators.email('E-Mail', value)
      )
    },
  },
  subject: {
    label: 'Subject',
    rule: 'required',
  },
  message: {
    label: 'Message',
    rule: 'required',
  },
}

export default function Footer() {
  const [visibleContact, setVisibleContact] = useState(false)

  const contactMutation = useMutation(contact, {
    onSuccess: (e) => {
      notification.success({
        title:
          'We have received your message and will get back to you as soon as possible.',
      })
      setVisibleContact(false)
    },
  })

  const handleCancelContact = () => {
    setVisibleContact(false)
    contactForm.reset()
    contactForm.clearErrors()
  }

  const contactForm = useMiniForm({
    resetOnSuccess: true,
    validation: validationContact,
    onSubmit(values) {
      try {
        contactMutation.mutate({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          subject: values.subject,
          message: values.message,
        })
      } catch (error) {
      } finally {
        contactForm.reset()
        setVisibleContact(false)
      }
    },
  })

  return (
    <>
      <footer className={classes.footer}>
        <div className={classes.leftSide}>
          <div>
            <div className={classes.footerLogo}>
              <Logo forceDarkMode={true} monoColor={true} />
            </div>
            <div>
              Made in Sweden with love. <br />
              2023 - TODAY'S CRYPTO ©
            </div>
          </div>
          <div>
            BlockBeast AB <br />
            Transportvägen 12 <br />
            SE-246 42, Löddeköpinge <br />
            SWEDEN <br />
            VAT no: SE559355317401 <br />
            Org no: 559355-3174
          </div>
          <ul>
            <li>
              <Link to="/publisher/about">About us</Link>
            </li>
            <li onClick={() => setVisibleContact(true)}>Contact us</li>
            <li>
              <Link to="/publisher/tos" target="_blank">
                Terms of Use
              </Link>
            </li>
            <li>
              <Link to="/publisher/privacy" target="_blank">
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
        <div className={classes.rightSide}>
          <div>Follow us</div>
          <div className={classes.footerSocial}>
            <a href="https://twitter.com/TodaysCryptoCom" target="_blank">
              <TwitterSVG />
            </a>
            <a
              href="https://www.linkedin.com/company/todayscryptocom/"
              target="_blank">
              <LinkedinSVG />
            </a>
            <a href="https://www.facebook.com/TodaysCrypto" target="_blank">
              <FacebookSVG />
            </a>
            <a href="https://t.me/todayscrypto" target="_blank">
              <TelegramSVG />
            </a>
          </div>
        </div>
      </footer>
      <Modal
        closeIconVisible={false}
        modalClassName={classes.contactModal}
        visible={visibleContact}
        okText="Submit"
        onCancel={handleCancelContact}
        onOk={contactForm.submit}>
        <p className="mb-4">
          Fill in the form below and we will get back to you.
        </p>
        <form className="my-8" onSubmit={contactForm.submit}>
          <div className={classes.contactFormNameFields}>
            <div>
              <Field
                label="First name"
                {...contactForm.getErrorPropsFor('firstName')}>
                <Input
                  {...contactForm.getInputPropsFor('firstName')}
                  type="text"
                />
              </Field>
            </div>
            <div>
              <Field
                label="Last name"
                {...contactForm.getErrorPropsFor('lastName')}>
                <Input
                  {...contactForm.getInputPropsFor('lastName')}
                  type="text"
                />
              </Field>
            </div>
          </div>
          <Field label="E-Mail" {...contactForm.getErrorPropsFor('email')}>
            <Input {...contactForm.getInputPropsFor('email')} type="email" />
          </Field>
          <Field label="Subject" {...contactForm.getErrorPropsFor('subject')}>
            <Input {...contactForm.getInputPropsFor('subject')} type="text" />
          </Field>
          <Field label="Message" {...contactForm.getErrorPropsFor('message')}>
            <Input {...contactForm.getInputPropsFor('message')} multiline />
          </Field>
        </form>
      </Modal>
    </>
  )
}
