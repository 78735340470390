type GetVideosQueryVariables = {
  page?: number,
  search?: string,
}

export default function getVideos(variables: GetVideosQueryVariables = {}) {
  const { page, search } = variables

  return {
    name: 'videos',
    path: '/videos',
    params: {
      filters: {
        search,
      },
      page,
    },
  }
}
