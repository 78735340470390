import createUrl from 'core/createUrl'

type getNotificationsQueryVariables = {
  page?: number,
  popup?: boolean,
  justUnread?: 0 | 1,
}
export default function getNotifications(
  variables: getNotificationsQueryVariables = {}
) {
  const { page, popup, justUnread } = variables

  return {
    name: [
      'notifications',
      {
        page,
        popup,
        justUnread,
      },
    ],
    url: createUrl('/publisher/notifications', {
      page,
      filters: {
        just_unread: Number(justUnread),
      },
    }),
  }
}
