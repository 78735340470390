// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".payout_wrapper__3dQyW {\n  display: flex;\n}\n\n.payout_eth__fS_1Z {\n  width: calc(60% - 1rem);\n}\n\n.payout_history__ReA8W {\n  margin-left: 2rem;\n  width: calc(40% - 1rem);\n}\n\n.payout_export__2zA3I {\n  cursor: pointer;\n}\n\n.payout_export__2zA3I svg {\n  fill: currentColor;\n  color: var(--neutral);\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/payout/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;ADAnB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;ECXA,eAAmB;ADanB;;AAEA;ECfA,kBAAmB;EAAnB,qBAAmB;ADiBnB","sourcesContent":[".wrapper {\n  @apply flex;\n}\n\n.eth {\n  width: calc(60% - 1rem);\n}\n\n.history {\n  margin-left: 2rem;\n  width: calc(40% - 1rem);\n}\n\n.export {\n  @apply cursor-pointer;\n}\n\n.export svg {\n  @apply fill-current text-neutral;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "payout_wrapper__3dQyW",
	"eth": "payout_eth__fS_1Z",
	"history": "payout_history__ReA8W",
	"export": "payout_export__2zA3I"
};
export default ___CSS_LOADER_EXPORT___;
