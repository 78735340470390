import useQuery from 'core/useQuery'
import getDepartments from 'admin/queries/getDepartments'
import Select from 'shared/components/form/select'

export default function SelectDepartment(props) {
  const { data, isLoading } = useQuery(getDepartments)
  const options = (data?.json || []).map((opt) => ({
    label: opt.name,
    value: opt.id,
  }))
  let defaultValue = 0

  if (props.changeNameRequest) {
    defaultValue = options.find(
      (opt) => opt.label === 'Technical Support'
    )?.value
  }

  return (
    <Select
      placeholder="Select category..."
      {...props}
      isLoading={isLoading}
      options={options}
      value={defaultValue}
    />
  )
}
