import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import VideoThumbnail from 'shared/components/video-thumbnail'
import getMyChannel from 'publisher/queries/getMyChannel'
import classes from './VideoPreview.module.css'

type Props = {
  thumbnail?: string,
  title?: string,
}

export default function VideoPreview(props: Props) {
  const { thumbnail, title } = props

  const channelQR = useQuery(getMyChannel)
  const channel = get(channelQR, 'data.json.name', '...')

  return (
    <div className={classes.wrapper}>
      <VideoThumbnail
        video={{
          thumbnail,
          title,
        }}
      />
      <div className={classes.info}>
        <h4>{title || 'Media title here...'}</h4>
        <small>{channel}</small>
      </div>
    </div>
  )
}
