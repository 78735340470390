import { useCallback, useMemo, useRef } from 'react'
import useMiniForm from 'core/useMiniForm'
import Modal from 'shared/components/kit/modal'
import Field from 'shared/components/form/field'
import SelectVideo from 'shared/components/form/select-video'
import SelectChannel from 'shared/components/form/select-channel'
import useTimelineActions from '../hooks/useTimelineActions'

type Props = {
  overlay?: Object,
  onCancel: Function,
  visible: boolean,
}

export default function EditOverlay(props: Props) {
  const { overlay, onCancel, visible } = props

  const { updateOverlay } = useTimelineActions()

  const submitButtonRef = useRef()

  const initialValues = useMemo(() => {
    if (!overlay) {
      return
    }
    const result = {
      type: overlay.type,
    }
    if (overlay.type === 'channel') {
      result.data_channel_slug = overlay.data_channel_slug
    }
    if (overlay.type === 'video') {
      result.data_video_url_hash = overlay.data_video_url_hash
    }
    return result
  }, [overlay])

  const form = useMiniForm({
    initialValues,
    onSubmit(values, event) {
      event.preventDefault()
      const result = {
        type: values.type,
      }
      if (values.type === 'channel') {
        result.data_channel_slug = values.data_channel_slug
      }
      if (values.type === 'video') {
        result.data_video_url_hash = values.data_video_url_hash
      }
      updateOverlay(overlay.id, (prev) => ({
        ...prev,
        ...result,
      }))
    },
  })

  const handleCancel = useCallback(() => {
    if (!overlay) {
      form.change('type', undefined)
    }
    onCancel()
  }, [form.change, overlay, onCancel])

  const handleOk = () => {
    submitButtonRef.current.click()
  }

  const hasTypeSelected = !!form.get('type')

  return (
    <Modal
      okText="Save"
      onCancel={handleCancel}
      onOk={handleOk}
      size="sm"
      title={
        hasTypeSelected
          ? 'Layer Config'
          : 'Which type of layer do you like to add?'
      }
      visible={visible}>
      <form onSubmit={form.submit}>
        {form.get('type') === 'channel' && (
          <>
            <Field label="Select the channel you want to show">
              <SelectChannel
                {...form.getInputPropsFor('data_channel_slug')}
                menuPosition="fixed"
                valueKey="slug"
              />
            </Field>
          </>
        )}

        {form.get('type') === 'subscribe' && (
          <>This layer has no configuration!</>
        )}

        {form.get('type') === 'video' && (
          <>
            <Field label="Select the video you want to show">
              <SelectVideo
                {...form.getInputPropsFor('data_video_url_hash')}
                menuPosition="fixed"
                valueKey="url_hash"
              />
            </Field>
          </>
        )}

        <button ref={submitButtonRef} type="submit" hidden />
      </form>
    </Modal>
  )
}
