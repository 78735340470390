type GetAdsCampaignDetails = {
  id: number,
}
export default function getAdsCampaignDetails(
  variables: GetAdsCampaignDetails = {}
) {
  const { id } = variables
  return {
    path: `/admin/ads/campaigns/${id}`,
  }
}
