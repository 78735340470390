import { useCallback, useRef } from 'react'
import { useQueryClient } from 'react-query'
import get from 'lodash/get'
import useMutation from 'core/useMutation'
import useQuery from 'core/useQuery'
import useInfiniteQuery from 'core/useInfiniteQuery'
import getUserName from 'helpers/utils/getUserName'
import truncate from 'helpers/utils/truncate'
import NotificationsPopup from 'shared/components/notifications-popup'
import Spin from 'shared/components/kit/spin'
import Icon from 'shared/components/kit/icon'
import readAllNotifications from 'shared/queries/readAllNotifications'
import VideoThumbnail from 'shared/components/video-thumbnail'
import readNotification from 'shared/queries/readNotification'
import getNotifications from 'admin/queries/getNotifications'
import getUnreadNotifications from 'admin/queries/getUnreadNotifications'
import { ReactComponent as FlagSVG } from 'svg/flag-outlined.svg'
import { ReactComponent as ReportCommentSVG } from 'svg/comments.svg'
import { ReactComponent as MegaphoneSVG } from 'svg/megaphone.svg'
import classes from './index.module.css'

export default function Notifications() {
  const loadingMoreRef = useRef(false)
  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } =
    useInfiniteQuery(getNotifications, {
      keepPreviousData: true,
    })

  const { data: newNotificationsCount } = useQuery(getUnreadNotifications)

  const queryClient = useQueryClient()

  const readAllMutation = useMutation(readAllNotifications, {
    onSuccess: () => {
      queryClient.invalidateQueries('notifications')
      queryClient.invalidateQueries('unreadNotifications')
    },
  })

  const handleMarkAllAsRead = async () => {
    await readAllMutation.mutateAsync({
      scope: 'admin',
    })
  }

  const unreadCount = get(newNotificationsCount, 'json', {})

  const handleLoadNextPage = useCallback(async () => {
    if (!loadingMoreRef.current) {
      loadingMoreRef.current = true
      await fetchNextPage()
      loadingMoreRef.current = false
    }
  }, [fetchNextPage])

  const notifications = (data?.pages || []).reduce(
    (all, page) => [...all, ...page.json.data],
    []
  )

  return (
    <div className={classes.wrapper}>
      {!isLoading ? (
        <NotificationsPopup
          isFetching={isFetching}
          hasNextPage={hasNextPage}
          onLoadNextPage={handleLoadNextPage}
          onMarkAllAsRead={handleMarkAllAsRead}
          unreadCount={unreadCount}>
          {notifications.map((notification, i) => (
            <NotificationItem notification={notification} key={i} />
          ))}
        </NotificationsPopup>
      ) : (
        <div className={classes.loading}>
          <Icon>
            <Spin />
          </Icon>
          <div>Loading...</div>
        </div>
      )}
    </div>
  )
}

function NotificationItem({ notification }) {
  const date = notification.created_at
  const readAt = notification.read_at

  const queryClient = useQueryClient()
  const mutation = useMutation(readNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries('notifications')
      queryClient.invalidateQueries('unreadNotifications')
    },
  })
  const handleNotificationClick = () => {
    mutation.mutate({
      id: notification.id,
    })
  }

  switch (notification.type) {
    case 'NewMessage':
      return (
        <NotificationsPopup.Item
          avatar={notification.payload.message.from}
          body={truncate(notification.payload.message.message, 50)}
          date={date}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          title={
            <span>
              <b>{getUserName(notification.payload.message.from)}</b>
              &nbsp;sent you a new message
            </span>
          }
          to={`/admin/messages/${
            notification.payload.message.parent_id ??
            notification.payload.message.id
          }`}
        />
      )

    case 'CustomNotification':
      return (
        <NotificationsPopup.Item
          body={notification.payload.message}
          date={date}
          icon={<MegaphoneSVG />}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          title="You have a new notification"
        />
      )

    case 'ReplyMessage':
      return (
        <NotificationsPopup.Item
          avatar={notification.payload.message.from}
          body={truncate(notification.payload.message.message, 50)}
          date={date}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          title={
            <span>
              <b>{getUserName(notification.payload.message.from)}</b>
              &nbsp;replied you a new message
            </span>
          }
          to={`/admin/messages/${
            notification.payload.message.parent_id ??
            notification.payload.message.id
          }`}
        />
      )

    case 'NewImportRequest':
      return (
        <NotificationsPopup.Item
          avatar={notification.payload.message.from}
          date={date}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          title={
            <span>
              <b>{getUserName(notification.payload.user)}</b>
              &nbsp;send you a import request
            </span>
          }
        />
      )

    case 'NewPublisherRequest':
      return (
        <NotificationsPopup.Item
          avatar={notification.payload.user}
          date={date}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          title={
            <span>
              <b>{getUserName(notification.payload.user)}</b>
              &nbsp;wants to be a publisher
            </span>
          }
        />
      )

    case 'ReportVideo':
      return (
        <NotificationsPopup.Item
          date={date}
          icon={<FlagSVG />}
          title={
            <span>
              The video "{notification.payload.video.title}" has been reported
            </span>
          }
          body={`reason: ${notification.payload.report.reason_text}`}
          to={`/admin/reports/videos/${notification.payload.video.id}`}
          image={<VideoThumbnail video={notification.payload.video} />}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
        />
      )

    case 'ReportComment':
      return (
        <NotificationsPopup.Item
          icon={<ReportCommentSVG />}
          title={
            <span>
              The comment "
              {truncate(notification.payload.comment.text || '', 50)}" has been
              reported
            </span>
          }
          body={`reason: ${notification.payload.report.reason_text}`}
          date={date}
          to={`/admin/reports/comments/${notification.payload.comment.id}`}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
        />
      )

    default:
      return (
        <NotificationsPopup.Item
          icon={<MegaphoneSVG />}
          readAt={notification.read_at}
          date={notification.published_at}
          id={notification.id}
          title={notification.payload.subject}
          message={notification.payload?.message}
        />
      )
  }
}
