import { useEffect, useLayoutEffect, useRef } from 'react'
import ChartJS from 'chart.js/auto'
import useGetCSSColor from 'shared/hooks/useGetCSSColor'

export default function Chart({ config }) {
  const containerRef = useRef()
  const ctxRef = useRef()
  const chartRef = useRef()
  const getCSSColor = useGetCSSColor()

  useLayoutEffect(() => {
    ChartJS.defaults.color = getCSSColor('neutral')
    ChartJS.defaults.font.size = 10
    ChartJS.defaults.elements.point.radius = 0
    ChartJS.defaults.scale.grid.borderColor = getCSSColor('light-2 dark:dark-3')
    ChartJS.defaults.scale.grid.color = getCSSColor('light-1 dark:dark-2')
    ChartJS.defaults.scale.grid.display = true
    ChartJS.defaults.scale.ticks.maxRotation = 0
    ChartJS.defaults.scale.ticks.minRotation = 0
    ChartJS.defaults.scale.ticks.autoSkip = true
    ChartJS.defaults.scale.ticks.autoSkipPadding = 20
    ChartJS.defaults.plugins.title.color = getCSSColor('dark-4 dark:light-4')
    ChartJS.defaults.plugins.title.display = true
    ChartJS.defaults.plugins.title.font = { size: 14 }
    ChartJS.defaults.plugins.title.position = 'bottom'
    ChartJS.defaults.plugins.tooltip.intersect = false
    ChartJS.defaults.plugins.tooltip.mode = 'nearest'
    ChartJS.defaults.plugins.tooltip.backgroundColor = getCSSColor(
      'light-2 dark:dark-4'
    )
    ChartJS.defaults.plugins.tooltip.bodyColor = getCSSColor(
      'dark-4 dark:light-4'
    )
    ChartJS.defaults.plugins.tooltip.bodyFont = { size: 12 }
    ChartJS.defaults.plugins.tooltip.borderColor = 'transparent'
    ChartJS.defaults.plugins.tooltip.borderWidth = 1
    ChartJS.defaults.plugins.tooltip.footerColor = getCSSColor('neutral')
    ChartJS.defaults.plugins.tooltip.footerFont = {
      size: 10,
      weight: undefined,
    }
    ChartJS.defaults.plugins.tooltip.padding = 12
    ChartJS.defaults.plugins.tooltip.titleColor = getCSSColor(
      'dark-4 dark:light-4'
    )
    ChartJS.defaults.plugins.tooltip.titleFont = { size: 12, weight: undefined }
    ChartJS.overrides.bar.borderRadius = 4
    ChartJS.overrides.bar.maxBarThickness = 5
    ChartJS.overrides.line.borderWidth = 2
    ChartJS.overrides.line.pointBorderWidth = 1
    ChartJS.overrides.line.pointRadius = 0
    ChartJS.overrides.line.tension = 0
  }, [getCSSColor])

  useEffect(() => {
    if (chartRef.current !== undefined) {
      chartRef.current.destroy()
    }
    chartRef.current = new ChartJS(ctxRef.current, {
      maintainAspectRatio: true,
      responsive: true,
      ...config,
    })
  }, [config])

  return (
    <div className="relative" ref={containerRef}>
      <canvas ref={ctxRef} />
    </div>
  )
}
