import { ReactComponent as CheckCircleFilledSVG } from 'icons/check-circle-filled.svg'
import { ReactComponent as ErrorCircleFilledSVG } from 'icons/error-circle-filled.svg'
import classes from './Status.module.css'

export default function Status({ active, extra, text }) {
  return (
    <div className={classes.status}>
      <span className={active ? 'text-green-400' : 'text-yellow-400'}>
        {active ? <CheckCircleFilledSVG /> : <ErrorCircleFilledSVG />}
      </span>
      &nbsp;{text}
      &nbsp;{extra}
    </div>
  )
}
