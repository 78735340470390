import { useMemo } from 'react'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import Button from 'shared/components/kit/button'
import notification from 'shared/components/kit/notification'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Password from 'shared/components/form/password'
import updateUser from 'admin/queries/updateUser'
import getUser from 'admin/queries/getUser'
import getUsers from 'admin/queries/getUsers'

const validation = {
  username: {
    label: 'Username',
    rule: 'required',
  },
  eth_address: {
    label: 'ETH Address',
    rule: 'required',
  },
  email: {
    label: 'Email',
    rule: 'required',
  },
  new_password: {
    label: 'New Password',
    rule: (value, values) => {
      if (values.new_password.length > 0 && values.new_password.length < 8) {
        return 'The password must be at least 8 characters.'
      }
    },
  },
  confirmPassword: {
    label: 'ConfirmPassword',
    rule: (value, values) => {
      if (
        (values.new_password &&
          values.new_password !== values.confirmPassword) ||
        (values.confirmPassword && !values.new_password)
      ) {
        return "Passwords don't match!"
      }
    },
  },
}

export default function EditInfo({ user }) {
  const queryClient = useQueryClient()

  const mutation = useMutation(updateUser, {
    onSuccess: (response) => {
      queryClient.invalidateQueries({ query: getUsers })
      queryClient.setQueryData({
        query: getUser,
        variables: {
          id: user.id,
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            ...response.json,
          },
        }),
      })
    },
  })

  const initialValues = useMemo(
    () => ({
      username: user.username,
      email: user.email,
      eth_address: user.eth_address,
    }),
    [user.username, user.email, user.eth_address]
  )

  const form = useMiniForm({
    initialValues,
    validation: validation,
    async onSubmit(fields) {
      try {
        await mutation.mutateAsync({
          id: user.id,
          fields,
        })
        notification.success({
          title: 'User info updated successfully!',
        })
      } catch (error) {
        notification.error({
          title: error.message,
        })
      }
    },
  })
  return (
    <>
      <form onSubmit={form.submit}>
        <Field label="Username" {...form.getErrorPropsFor('username')}>
          <Input required {...form.getInputPropsFor('username')} />
        </Field>
        <Field label="ETH Address" {...form.getErrorPropsFor('eth_address')}>
          <Input {...form.getInputPropsFor('eth_address')} />
        </Field>
        <Field label="Email" {...form.getErrorPropsFor('email')}>
          <Input type="email" required {...form.getInputPropsFor('email')} />
        </Field>
        <Field label="New Password" {...form.getErrorPropsFor('new_password')}>
          <Password {...form.getInputPropsFor('new_password')} required />
        </Field>
        <Field
          label="Confirm Password"
          {...form.getErrorPropsFor('confirmPassword')}>
          <Password {...form.getInputPropsFor('confirmPassword')} required />
        </Field>
        <Button loading={form.submitting} type="submit">
          Update
        </Button>
      </form>
    </>
  )
}
