import useQuery from 'core/react-query/useQuery'
import getChannelAvatar from 'helpers/utils/getChannelAvatar'
import classes from './Channel.module.css'
import { useEffect } from 'react'

export default function Channel({ onRefresh, slug }) {
  const { data, isError, isLoading, isSuccess } = useQuery(getChannelBySlug, {
    variables: {
      slug,
    },
  })

  useEffect(() => {
    if (isSuccess) {
      onRefresh()
    }
  }, [isSuccess, onRefresh])

  if (isError || isLoading) {
    return null
  }

  const channel = data && data.json ? data.json : null

  return (
    <div className={classes.wrapper}>
      <img alt={channel.name} src={getChannelAvatar(channel.avatar)} />
      <div>
        <h3>{channel.name}</h3>
        <span>
          {channel.subscribers_count > 0
            ? `${channel.subscribers_count} Subscribers`
            : 'No subscriber'}
        </span>
      </div>
    </div>
  )
}

function getChannelBySlug(variables) {
  const { slug } = variables

  return {
    name: 'channel',
    path: `/channels/${slug}`,
  }
}
