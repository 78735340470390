import { useCallback, useEffect, useRef, useState } from 'react'
import get from 'lodash/get'
import useMutation from 'core/useMutation'
import useQuery from 'core/useQuery'
import useQueryClient from 'core/useQueryClient'
import useInfiniteQuery from 'core/useInfiniteQuery'
import Spin from 'shared/components/kit/spin'
import Icon from 'shared/components/kit/icon'
import readAllNotifications from 'shared/queries/readAllNotifications'
import readNotification from 'shared/queries/readNotification'
import VideoThumbnail from 'shared/components/video-thumbnail'
import useProfile from 'shared/hooks/useProfile'
import NotificationsPopup from 'publisher/components/notifications-popup'
import getNotifications from 'publisher/queries/getNotifications'
import getUnreadNotifications from 'publisher/queries/getUnreadNotifications'
import { ReactComponent as MegaphoneSVG } from 'svg/megaphone.svg'
import { ReactComponent as RequestAcceptedSVG } from 'svg/ok.svg'
import { ReactComponent as HideVideoSVG } from 'svg/hide.svg'
import { ReactComponent as PublishVideoSVG } from 'icons/publish-outlined.svg'
import { ReactComponent as DeleteVideoSVG } from 'svg/video-delete.svg'
import { ReactComponent as RefreshSVG } from 'svg/refresh.svg'
import { ReactComponent as NewMessageSVG } from 'svg/newMessage.svg'
import { ReactComponent as ReplySVG } from 'svg/reply.svg'
import classes from './index.module.css'

const status = {
  freeze: {
    label: 'frozen',
  },
  draft: {
    label: 'drafted',
  },
  published: {
    label: 'published',
  },
  hidden: {
    label: 'hidden',
  },
}

export default function Notifications() {
  const loadingMoreRef = useRef(false)
  const spinRef = useRef()
  const [justUnread, setJustUnread] = useState(1)
  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } =
    useInfiniteQuery(getNotifications, {
      keepPreviousData: true,
      variables: {
        popup: true,
        justUnread: justUnread,
      },
    })

  const { data: newNotificationsCount } = useQuery(getUnreadNotifications)

  const queryClient = useQueryClient()

  const readAllMutation = useMutation(readAllNotifications, {
    onSuccess: () => {
      queryClient.invalidateQueries('notifications')
      queryClient.invalidateQueries('unreadNotifications')
    },
  })

  const handleMarkAllAsRead = async () => {
    await readAllMutation.mutateAsync({
      scope: 'publisher',
    })
  }

  const unreadCount = get(newNotificationsCount, 'json', {})

  const handleLoadNextPage = useCallback(async () => {
    if (!loadingMoreRef.current) {
      loadingMoreRef.current = true
      await fetchNextPage()
      loadingMoreRef.current = false
    }
  }, [fetchNextPage])

  const notifications = (data?.pages || []).reduce(
    (all, page) => [...all, ...page.json.data],
    []
  )

  const isFetchingNextPageRef = useRef(false)

  const hasNotifications = notifications && notifications.length > 0

  useEffect(() => {
    const handleScroll = async () => {
      if (
        hasNotifications &&
        hasNextPage &&
        spinRef.current &&
        isFetchingNextPageRef.current === false
      ) {
        const isBottom =
          spinRef.current.getBoundingClientRect().bottom <= window.innerHeight
        if (isBottom) {
          if (typeof handleLoadNextPage === 'function') {
            isFetchingNextPageRef.current = true
            await handleLoadNextPage()
            isFetchingNextPageRef.current = false
          }
        }
      }
    }

    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [hasNotifications, hasNextPage, handleLoadNextPage])

  const onChangeShowOnlyUnread = () => {
    setJustUnread((prev) => !prev)
  }

  return (
    <>
      <div className={classes.wrapper}>
        {!isLoading ? (
          <NotificationsPopup
            justUnread={justUnread}
            onChangeShowOnlyUnread={onChangeShowOnlyUnread}
            isFetching={isFetching}
            onMarkAllAsRead={handleMarkAllAsRead}
            unreadCount={unreadCount}>
            <div className={classes.list}>
              {notifications.map((notification, i) => (
                <NotificationItem notification={notification} key={i} />
              ))}
              {hasNextPage && (
                <div
                  ref={spinRef}
                  className="flex items-center justify-center opacity-50 p-8">
                  <Spin />
                </div>
              )}
            </div>
          </NotificationsPopup>
        ) : (
          <div className={classes.loading}>
            <Icon>
              <Spin />
            </Icon>
            <div>Loading...</div>
          </div>
        )}
      </div>
    </>
  )
}

function NotificationItem({ notification }) {
  const { user } = useProfile()
  const date = notification.created_at
  const readAt = notification.read_at

  const queryClient = useQueryClient()
  const mutation = useMutation(readNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries('notifications')
      queryClient.invalidateQueries('unreadNotifications')
    },
  })
  const handleNotificationClick = () => {
    mutation.mutate({
      id: notification.id,
    })
  }

  switch (notification.type) {
    case 'MentionedOnComment':
      return (
        <NotificationsPopup.Item
          avatar={notification.payload.comment.user}
          notification={notification}
          title={`${notification.payload.comment.user.username} mentioned you in a comment`}
          modalTitle={`${notification.payload.comment.user.username} mentioned you in a comment`}
          body={
            <span>
              {<span className="text-secondary">@{user.username} </span>}{' '}
              {notification.payload.comment.text}
            </span>
          }
          readAt={readAt}
          onMarkAsRead={handleNotificationClick}
          date={date}
          to={`/publisher/comments/all?commentId=${notification.payload.comment.parent_id}&replyId=${notification.payload.comment.id}`}
          redirectButtonTitle="Reply"
          id={notification.id}
        />
      )
    case 'NewMessage':
      return (
        <NotificationsPopup.Item
          notification={notification}
          icon={<NewMessageSVG />}
          title={notification.payload.message.subject}
          modalTitle={notification.payload.message.subject}
          message={notification.payload.message.message}
          date={date}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          to={`/publisher/tickets/${
            notification.payload.message.parent_id ??
            notification.payload.message.id
          }`}
          redirectButtonTitle="Go to message page"
          id={notification.id}
        />
      )

    case 'ReplyMessage':
      return (
        <NotificationsPopup.Item
          notification={notification}
          icon={<ReplySVG />}
          title={notification.payload.message.subject}
          modalTitle={notification.payload.message.subject}
          message={notification.payload.message.message}
          date={date}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          to={`/publisher/tickets/${
            notification.payload.message.parent_id ??
            notification.payload.message.id
          }`}
          redirectButtonTitle="Go to message page"
          id={notification.id}
        />
      )

    case 'CustomNotification':
      return (
        <NotificationsPopup.Item
          notification={notification}
          title={notification.payload.subject}
          modalTitle={notification.payload.subject}
          message={notification.payload.message}
          date={date}
          icon={<MegaphoneSVG />}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          id={notification.id}
        />
      )
    case 'ImportRequestAccepted':
      return (
        <NotificationsPopup.Item
          notification={notification}
          icon={<RequestAcceptedSVG />}
          date={date}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          modalTitle={<span>Your import request accepted.</span>}
          title={<span>Your import request accepted</span>}
          id={notification.id}
        />
      )

    case 'ImportRequestCompleted':
      return (
        <NotificationsPopup.Item
          notification={notification}
          icon={<RequestAcceptedSVG />}
          date={date}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          title={<span>Your import request completed</span>}
          modalTitle={<span>Your import request completed.</span>}
          id={notification.id}
        />
      )

    case 'HideVideo':
      return (
        <NotificationsPopup.Item
          notification={notification}
          date={date}
          icon={<HideVideoSVG />}
          body={`reason: ${notification.payload.video.reason_text}`}
          videoTitle={notification.payload.video.title}
          title={<span>Your video has been hidden</span>}
          modalTitle={<span>Your video has been hidden</span>}
          image={<VideoThumbnail video={notification.payload.video} />}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          id={notification.id}
        />
      )
    case 'UnHideVideo':
      return (
        <NotificationsPopup.Item
          notification={notification}
          date={date}
          icon={<PublishVideoSVG />}
          image={<VideoThumbnail video={notification.payload.video} />}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          title={<span>Your video has been published</span>}
          modalTitle={
            <span>Your video status changed from hidden to published</span>
          }
          videoTitle={notification.payload.video.title}
          id={notification.id}
        />
      )
    case 'DeleteVideo':
      return (
        <NotificationsPopup.Item
          notification={notification}
          body={`reason: ${notification.payload.video.reason_text}`}
          date={date}
          icon={<DeleteVideoSVG />}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          title={<span>Your video is deleted</span>}
          modalTitle={<span>Your video is deleted</span>}
          id={notification.id}
        />
      )

    case 'UpdateChannelStatus':
      return (
        <NotificationsPopup.Item
          notification={notification}
          date={date}
          icon={<RefreshSVG />}
          onMarkAsRead={handleNotificationClick}
          readAt={readAt}
          modalTitle={`Your channel status changed from ${
            status[notification.payload.prev_status].label
          } to ${status[notification.payload.current_status].label}`}
          title={`Your channel has been ${
            status[notification.payload.current_status].label
          }`}
          id={notification.id}
        />
      )
    default:
      return (
        <NotificationsPopup.Item
          icon={<MegaphoneSVG />}
          readAt={notification.read_at}
          date={notification.published_at}
          id={notification.id}
          title={notification.payload.subject}
          message={notification.payload?.message}
        />
      )
  }
}
