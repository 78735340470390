import Modal from 'shared/components/kit/modal'
import Button from 'shared/components/kit/button'
import classes from './index.module.css'

export default function HowItWorksModal({ visible, onClose }) {
  return (
    <Modal
      title="How it works"
      visible={visible}
      onCancel={onClose}
      footer={
        <Button onClick={onClose} transparent mood="neutral">
          Close
        </Button>
      }>
      <div className={classes.wrapper}>
        <section>
          <h1>Monetize on Today's Crypto.</h1>

          <h2>Our approach</h2>

          <p>
            We believe in fairness and pride ourselves in having the fairest
            distribution model for streamed content on the market. We do not
            decide or limit what content can be monetized but instead let the
            user interactions and data speak for themselves.
          </p>

          <h2>More to our Publishers</h2>

          <p>
            Our aim is to become the best paying streaming platform within the
            industry by distributing 30 percent of the platform's revenue back
            to our publishers through the Monetize program. This includes Hodl
            Membership fees, Advertisement revenues, and more. The remaining
            part of the revenue goes to cover operational costs, continued
            development, and maintenance of the service.
          </p>

          <h2>So how does it work?</h2>

          <p>
            It’s all about the points! At the start of each month, all channels
            qualified for the Monetize program will race to earn as many points
            as possible to secure their piece of the monthly distribution.
            Whoever manages to earn the most points by the end of the month will
            enjoy the biggest piece of the pie. There are currently three areas
            in which your channel will be able to earn points:
            <br />
            <ol style={{ listStyleType: 'decimal' }}>
              <li>
                Subscribers <br />
                Focusing on attracting subscribers to your channel is probably
                the best thing you can do to secure a steady point earning each
                month. For each subscriber your channel has at the start of the
                month, you will get 1 point, every month. You heard right, so
                take care of your subscribers and make sure to highlight the
                importance of subscribing to support the channel and not miss
                out on any new content.
              </li>
              <li>
                Views <br />
                Your creations are made to be viewed and making content that is
                widely appreciated and sparks an interest in your audience and
                preferably the masses (nobody said it was easy), is a good way
                of securing a good chunk of points. For every ten video views,
                you will get 1 point.
              </li>
              <li>
                Likes <br />A like says more than 500 views... We can all agree
                that getting a lot of views is great, but it’s not always enough
                to know what your audience really thinks, that is why we believe
                that likes are an extra powerful tool to measure the engagement
                on your content. That is why you will be awarded 50 points for
                each like on any of your content.
              </li>
            </ol>
            <br />
            The number of points your channel manages to earn during the month
            will determine your share of the money up for distribution (the
            “pie”), which in turn is determined based on the revenues of the
            platform the month before.
          </p>
          <h2>Fancy some extra points?</h2>
          <p>
            Did you know that a Like from a Hodl Member is worth 150 points, or
            three times the normal points? Or that you will be awarded double
            the points for having a Hodl Member subscribing to your channel or
            watching your content? Well, you do know!
          </p>
          <h2>Payout</h2>
          <p>
            Payouts are made approximately 10 working days after the closing of
            the month and your earnings will be sent directly to your registered
            wallet in USDT. Please note that you must verify your identity and
            residential address before any payouts can be made.
          </p>
          <p>
            You will be able to export payment documentation in the Publisher
            panel for accounting and possible taxation purposes.
          </p>
          <br />
          <h2>When can I start to Monetize my content?</h2>
          <p>
            There are two minimum requirements that your channel needs to meet
            before qualifying for the Monetize program:
            <ul className={classes.dashed}>
              <li>You must have a minimum of 500 subscribers.</li>
              <li>Your content must have a minimum of 2000 watch hours.</li>
            </ul>
          </p>
          <p>
            Once the requirements have been met, then your channel will
            automatically qualify for the Monetize program and be ready to start
            earning points.
          </p>
        </section>
      </div>
    </Modal>
  )
}
