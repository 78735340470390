import { useCallback } from 'react'
import _get from 'lodash/get'

export default function useProps({ setValues, values, error }) {
  const get = useCallback(
    (arrayName, name, tier) => {
      if (values[arrayName]) {
        const index = values[arrayName]?.findIndex((item) => item.tier == tier)
        return _get(values[arrayName][index], name)
      }
      return
    },
    [values]
  )

  const getByIndex = useCallback(
    (arrayName, name, index) => {
      if (values[arrayName]) {
        return _get(values[arrayName][index], name)
      }
      return
    },
    [values]
  )

  const getInputPropsForByTier = (arrayName, name, tier) => ({
    onChange: (value) => {
      setValues((prev) => ({
        ...prev,
        [arrayName]: prev[arrayName].map((item) =>
          item.tier == tier ? { ...item, [name]: value } : item
        ),
      }))
    },
    value: get(arrayName, name, tier),
  })

  const getInputPropsForByIndex = (arrayName, name, index) => ({
    onChange: (value) => {
      setValues((prev) => ({
        ...prev,
        [arrayName]: prev[arrayName].map((item, i) =>
          i == index ? { ...item, [name]: value } : item
        ),
      }))
    },
    value: getByIndex(arrayName, name, index),
  })

  const getErrorPropsFor = (arrayName, name, index) => {
    if (error?.json?.errors[`${arrayName}.${index}.${name}`]) {
      return {
        error: `This field is required!`,
      }
    }
    return null
  }

  return {
    getInputPropsForByTier,
    getInputPropsForByIndex,
    getErrorPropsFor,
  }
}
