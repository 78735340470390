import useQuery from 'core/react-query/useQuery'
import useProfile from 'shared/hooks/useProfile'

export default function useYouTubeSyncStatus() {
  const { channel } = useProfile()

  return useQuery(getYouTubeSyncStatus, {
    enabled: channel.import_request_status === 'sync',
    refetchInterval: 30000,
  })
}

function getYouTubeSyncStatus() {
  return {
    path: '/publisher/yi/channels/import-stats',
  }
}
