export default function formatPrice(
  amount,
  symbol = true,
  hardFraction = null
) {
  let num = Number(amount)

  if (typeof num === 'number') {
    let fraction = 2

    if (num >= 10) {
      fraction = 2
    } else if (num >= 1) {
      fraction = 4
    }

    if (typeof hardFraction === 'number') {
      fraction = hardFraction
    }

    num = num.toLocaleString(undefined, {
      minimumFractionDigits: fraction,
      maximumFractionDigits: fraction,
    })

    return symbol ? `$${num}` : num
  }

  return ''
}

export function round(value, step = 0.01) {
  const inverse = 1.0 / step
  return Math.round(value * inverse) / inverse
}
