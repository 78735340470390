import { useRef, useState } from 'react'
import classnames from 'classnames'
import Input from 'shared/components/form/input'
import insertTextAtCursor from 'helpers/utils/insertTextAtCursor'
import Emoji from './components/Emoji'
import classes from './index.module.css'

export default function RichText(props) {
  const {
    className,
    extra,
    header,
    onBlur,
    onChange,
    onFocus,
    value,
    ...rest
  } = props

  const inputRef = useRef()

  const [isFocused, setIsFocused] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const handleInsertEmoji = (emoji) => {
    const textToInsert = inputRef.current.value ? ` ${emoji}` : emoji
    const nextText = insertTextAtCursor(inputRef.current, textToInsert, {
      change: true,
      focus: true,
    })

    onChange(nextText)
  }

  const handleBlur = (event) => {
    setIsFocused(false)
    if (onBlur) {
      onBlur(event)
    }
  }

  const handleFocus = (event) => {
    setIsFocused(true)
    if (onFocus) {
      onFocus(event)
    }
  }

  const handleMouseOver = (event) => {
    setIsHovered(true)
  }

  const handleMouseOut = (event) => {
    setIsHovered(false)
  }

  const wrapperClassName = classnames(classes.wrapper, className, {
    [classes.focused]: isFocused,
    [classes.hovered]: isHovered,
  })

  return (
    <div
      className={wrapperClassName}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}>
      {header ? <div className={classes.header}>{header}</div> : null}
      <Input
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
        value={value}
        {...rest}
        ref={inputRef}
        multiline
      />
      <div className={classes.toolbar}>
        <div className={classes.leftToolbar}>
          <Emoji onSelect={handleInsertEmoji} />
        </div>
        <div className={classes.rightToolbar}>{extra}</div>
      </div>
    </div>
  )
}
