// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ForgotPassword_forgot__2Pv4i {\n  padding: 2rem;\n  width: 24rem;\n}\n\n.ForgotPassword_forgot__2Pv4i > h2 {\n  font-size: 1.5rem;\n  line-height: 2rem;\n  margin-bottom: 2rem;\n}\n\n.ForgotPassword_login__ddYL2 {\n  border-top-width: 1px;\n  margin-top: 1.5rem;\n  padding-top: 1.5rem;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/login/components/ForgotPassword.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,YAAmB;ADAnB;;AAEA;ECFA,iBAAmB;EAAnB,iBAAmB;EAAnB,mBAAmB;ADInB;;AAEA;ECNA,qBAAmB;EAAnB,kBAAmB;EAAnB,mBAAmB;EAAnB,kBAAmB;ADQnB","sourcesContent":[".forgot {\n  @apply p-8 w-96;\n}\n\n.forgot > h2 {\n  @apply mb-8 text-2xl;\n}\n\n.login {\n  @apply border-t mt-6 pt-6 text-center;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forgot": "ForgotPassword_forgot__2Pv4i",
	"login": "ForgotPassword_login__ddYL2"
};
export default ___CSS_LOADER_EXPORT___;
