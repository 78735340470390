import Button from 'shared/components/kit/button'
import Switch from 'shared/components/form/switch'
import Notification from './Notification'

export default function Index(props) {
  const {
    children,
    onChangeShowOnlyUnread,
    justUnread,
    onMarkAllAsRead,
    unreadCount,
  } = props

  return (
    <>
      <header>
        <span>Notifications</span>
        <div>
          <Switch
            description="Only show unread"
            checked={justUnread}
            onChange={onChangeShowOnlyUnread}
          />
          {unreadCount.count > 0 && (
            <Button
              onClick={onMarkAllAsRead}
              size="sm"
              outlined
              secondary
              transparent>
              Mark all as read
            </Button>
          )}
        </div>
      </header>
      <ul>
        <>
          {justUnread && unreadCount.count === 0 && (
            <span className="block text-center p-2">
              You have no unread notifications.
            </span>
          )}
          {children}
        </>
      </ul>
    </>
  )
}

Index.Item = Notification
