import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Input from 'shared/components/form/input'
import classes from './Fitlers.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <Input
        {...form.getInputPropsFor('search')}
        placeholder="Search Cryptocurrencies..."
      />

      <Button outlined>Search Cryptocurrencies</Button>
    </form>
  )
}
