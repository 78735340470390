import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import CaptchaInput from 'shared/components/form/captcha'
import FormError from 'shared/components/form/error'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Password from 'shared/components/form/password'
import EmailVerificationModal from 'shared/components/email-verification-modal'
import register from 'publisher/mutations/register'
import { ReactComponent as CheckSVG } from 'svg/check-circle-filled.svg'
import classes from './SignUp.module.css'

export default function SignUp({ onLogin }) {
  const [step, setStep] = useState('sign-up')

  const [showRequireStar, setShowRequireStar] = useState(false)

  const { mutateAsync } = useMutation(register, {
    onSuccess: (response) => {
      if (response.json?.code === 'email_verification.require') {
        setStep('verify')
      }
    },
  })

  const passwordRef = useRef()
  const captchaRef = useRef()

  const form = useMiniForm({
    async onSubmit(values, event) {
      event.preventDefault()
      try {
        await mutateAsync(values)
        form.reset()
      } catch (error) {
        captchaRef.current.reload()
        throw error.json
      }
    },
  })

  const handleCancelVerification = () => {
    setStep('sign-up')
  }

  const handleVerified = () => {
    setStep('success')
  }

  const handleCheckShowRequireStar = () => {
    if (!form.getInputPropsFor('email').value) {
      setShowRequireStar(true)
    } else {
      setShowRequireStar(false)
    }
  }

  if (step === 'success') {
    return (
      <div className={classes.verified}>
        <div>
          <CheckSVG />
        </div>
        <p>
          Right on! Your email has been successfully verified and you are ready
          for the next step in your publisher application.
          <br />
          <br />
          Please proceed to login by clicking the button below.
        </p>
        <Button className={classes.button} onClick={onLogin}>
          Proceed to Login
        </Button>
      </div>
    )
  }

  return (
    <div className={classes.wrapper}>
      <h2>Become a Publisher</h2>
      <form onSubmit={form.submit}>
        {form.error ? <FormError>{form.error.message}</FormError> : null}
        <Field
          {...form.getErrorPropsFor('email')}
          label="Email"
          required={showRequireStar}>
          <Input
            {...form.getInputPropsFor('email')}
            placeholder="Your Email..."
            forceDarkMode
            type="email"
            required
          />
        </Field>
        <Field
          {...form.getErrorPropsFor('password')}
          label="Password"
          required={showRequireStar}>
          <Password
            ref={passwordRef}
            {...form.getInputPropsFor('password')}
            placeholder="Your Password..."
            forceDarkMode
            required
          />
        </Field>
        <Field
          {...form.getErrorPropsFor('captcha')}
          label="Prove that you are human"
          required={showRequireStar}>
          <CaptchaInput
            forceDarkMode
            ref={captchaRef}
            onLoad={(captcha) => form.change('captchaKey', captcha.key)}
            {...form.getInputPropsFor('captcha')}
          />
        </Field>
        <Button
          onClick={handleCheckShowRequireStar}
          loading={form.submitting}
          type="submit"
          block>
          Register!
        </Button>
        <p className={classes.registeredParagraph}>
          By registering as a Publisher on Today's Crypto, you are accepting our{' '}
          <Link
            className="text-primary"
            target="_blank"
            to="/publisher/publisher-terms">
            Publisher Terms & Conditions
          </Link>
          , our{' '}
          <Link className="text-primary" target="_blank" to="/publisher/tos">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            className="text-primary"
            target="_blank"
            to="/publisher/privacy">
            Privacy Policy
          </Link>
        </p>
      </form>

      <p className={classes.login}>
        <Button onClick={onLogin} block secondary>
          Already a Publisher?
        </Button>
      </p>

      <EmailVerificationModal
        onCancel={handleCancelVerification}
        onVerified={handleVerified}
        visible={step === 'verify'}
      />
    </div>
  )
}
