import classnames from 'classnames'
import classes from './PositionIcon.module.css'

const positions = ['top-left', 'top-right', 'bottom-left', 'bottom-right']

export default function PositionIcon({ className, position, ...rest }) {
  if (positions.indexOf(position) === -1) {
    return null
  }

  const [prop1, prop2] = position.split('-')

  const style = {
    [prop1]: '0.2rem',
    [prop2]: '0.2rem',
  }

  return (
    <span className={classnames(classes.wrapper, className)} {...rest}>
      <span style={style} />
    </span>
  )
}
