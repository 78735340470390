type GetPayoutEarningStatistics = {
  from: string,
  to: string,
}

export default function getPayoutEarningStatistics(
  variables: GetPayoutEarningStatistics = {}
) {
  const { from, to } = variables

  const filters = {}
  if (from) {
    filters.from = from
  }

  if (to) {
    filters.to = to
  }

  return {
    name: 'payoutEarnings',
    path: `/admin/earnings/monthly`,
    params: {
      filters: {
        from,
        to,
      },
    },
  }
}
