import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import Modal from 'shared/components/kit/modal'
import Logo from 'shared/components/logo'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import notification from 'shared/components/kit/notification'
import Button from 'shared/components/kit/button'
import notify from 'publisher/mutations/notify'
import { ReactComponent as TwitterSVG } from 'icons/twitter-circle-filled.svg'
import { ReactComponent as TelegramSVG } from 'icons/telegram-circle-filled.svg'
import classes from './NotifyModal.module.css'

const validationNotify = {
  email: {
    label: 'E-Mail',
    rule: (value, values, validators) => {
      return (
        validators.required('E-Mail', value) ||
        validators.email('E-Mail', value)
      )
    },
  },
}

export default function NotifyModal({ visibleNotify, onSetVisibleNotify }) {
  const notifyMutation = useMutation(notify, {
    onSuccess: (e) => {
      notification.success({
        title: 'Thank you for showing interest! We will keep you posted!',
      })
      onSetVisibleNotify(false)
    },
  })

  const notifyForm = useMiniForm({
    resetOnSuccess: true,
    validation: validationNotify,
    onSubmit(values) {
      try {
        notifyMutation.mutate({
          email: values.email,
          location: 'MWA/landing',
        })
      } catch (error) {
      } finally {
        notifyForm.reset()
        onSetVisibleNotify(false)
        window.scrollTo(0, 0)
      }
    },
  })

  const handleCancelNotify = () => {
    onSetVisibleNotify(false)
    notifyForm.reset()
    notifyForm.clearErrors()
  }

  const footerNotify = (
    <div className={classes.notifyFooter}>
      <Button onClick={notifyForm.submit}> Notify Me </Button>
    </div>
  )

  return (
    <Modal
      modalClassName={classes.notifyModal}
      footer={footerNotify}
      onCancel={handleCancelNotify}
      visible={visibleNotify}>
      <div className={classes.notifyLogo}>
        <Logo />
      </div>
      <div className={classes.notifyBody}>
        <p className="my-4">Today's Crypto is launching soon!</p>
        <small className="block">
          We're super excited to bring you the world's first crypto-focused
          media streaming service. You can now register your interest below,
          follow us on Twitter, or join our Telegram group for updates on the
          launch.
        </small>
        <div className={classes.social}>
          <a href="https://twitter.com/TodaysCryptoCom" target="_blank">
            <TwitterSVG />
          </a>
          <a href="https://t.me/todayscrypto" target="_blank">
            <TelegramSVG />
          </a>
        </div>
      </div>
      <div className="-mx-3 -mb-14">
        <form onSubmit={notifyForm.submit}>
          <Field label="Your email" {...notifyForm.getErrorPropsFor('email')}>
            <Input {...notifyForm.getInputPropsFor('email')} type="email" />
          </Field>
        </form>
      </div>
    </Modal>
  )
}
