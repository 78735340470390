import Pusher from 'pusher-js'
import cookie from 'core/cookie'

export default function pusher() {
  if (typeof window.TC_PUSHER === 'undefined') {
    const jwt = cookie().get('jwt')

    const options = {
      authEndpoint: process.env.REACT_APP_SOCKET_AUTH_ENDPOINT,
      auth: {
        headers: {
          Accept: 'application/json',
        },
      },
      cluster: 'mt1',
      wsHost: process.env.REACT_APP_SOCKET_HOST,
      wsPort: 443,
      forceTLS: false,
      disableStats: true,
    }

    if (jwt) {
      options.auth.headers.Authorization = `Bearer ${jwt}`
    }

    const _pusher = new Pusher('local', options)

    _pusher.connection.bind('connected', () => {
      // console.log('PUSHER CONNECTION ESTABLISHED!');
    })

    _pusher.connection.bind('error', (error) => {
      console.log('PUSHER ERROR', error)
    })

    window.TC_PUSHER = _pusher
  }

  return window.TC_PUSHER
}
