import { useCallback, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import classnames from 'classnames'
import get from 'lodash/get'
import Box from 'shared/components/kit/box'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import ViewsChart from './ViewsChart'
import LikesChart from './LikesChart'
import PointsChart from './PointsChart'
import formatFullDate from 'shared/helpers/formatFullDate'
import WatchTimeChart from './WatchTimeChart'
import useQuery from 'core/react-query/useQuery'
import getVideoStatistics from 'publisher/queries/getVideoStatistics'
import classes from './Charts.module.css'

export default function Charts({ videoId }) {
  const [filters, setFilters] = useState({ statisticsPeriod: 'last_30d' })

  const { data, isFetching } = useQuery(getVideoStatistics, {
    variables: {
      statisticsPeriod: filters.statisticsPeriod,
      id: videoId,
    },
  })

  const statisticsObject = get(data, 'json.statistics', [])

  const statistics = Object.values(statisticsObject)

  const dailyFilters = [
    'last_7d',
    'last_14d',
    'last_30d',
    'last_90d',
    'last_180d',
  ]

  const monthlyFilters = ['last_365d']

  const labels = useMemo(() => {
    if (dailyFilters.includes(filters.statisticsPeriod)) {
      return statistics.map((item) => dayjs(item.date).format('D MMM'))
    } else if (monthlyFilters.includes(filters.statisticsPeriod)) {
      return statistics.map((item) => dayjs(item.date).format('MMM'))
    }
    return []
  }, [statistics, filters.statisticsPeriod])

  const getDate = useCallback(
    (date) => {
      if (monthlyFilters.includes(filters.statisticsPeriod)) {
        return dayjs(date).format('MMMM YYYY')
      }
      return formatFullDate(date)
    },
    [filters.statisticsPeriod]
  )

  const gridClassNames = classnames('grid grid-cols-2 gap-12', {
    'opacity-50': isFetching,
  })

  return (
    <Box className={classes.wrapper}>
      <header className={classes.header}>
        <h2>Charts</h2>
        <div>
          <HtmlSelect
            value={filters.statisticsPeriod}
            onChange={(val) => {
              setFilters({ statisticsPeriod: val })
            }}>
            <option value="last_7d">Last 7 Days</option>
            <option value="last_14d">Last 14 Days</option>
            <option value="last_30d">Last 30 Days</option>
            <option value="last_90d">Last 3 Months</option>
            <option value="last_180d">Last 6 Months</option>
            <option value="last_365d">Last 12 Months</option>
          </HtmlSelect>
        </div>
      </header>

      <div className={gridClassNames}>
        <PointsChart data={statistics} labels={labels} getDate={getDate} />
        <WatchTimeChart data={statistics} labels={labels} getDate={getDate} />
        <ViewsChart data={statistics} labels={labels} getDate={getDate} />
        <LikesChart data={statistics} labels={labels} getDate={getDate} />
      </div>
    </Box>
  )
}
