import { createElement, forwardRef } from 'react'
import classnames from 'classnames'
import classes from './index.module.css'

type Props = {
  multiline?: boolean,
  onChange: Function,
  value?: string,
}

function Input(props: Props, ref) {
  const { className, forceDarkMode, multiline, onChange, value, ...rest } =
    props

  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value, event)
    }
  }

  const text = typeof value === 'undefined' || value === null ? '' : value

  const componentProps = {
    ...rest,
    className: classnames(classes.input, className, {
      [classes.forceDarkMode]: forceDarkMode,
    }),
    onChange: handleChange,
    ref,
    value: text,
  }

  return createElement(multiline ? 'textarea' : 'input', componentProps)
}

export default forwardRef(Input)
