import { useHistory } from 'react-router'
import getTimeFromNow from 'helpers/utils/getTimeFromNow'
import UserAvatar from 'shared/components/user-avatar'
import classnames from 'classnames'
import classes from './Notification.module.css'

export default function Notification({
  avatar,
  body,
  date,
  icon,
  image,
  onMarkAsRead,
  readAt,
  title,
  to,
}) {
  const history = useHistory()
  const handleClick = () => {
    if (typeof onMarkAsRead === 'function') {
      onMarkAsRead()
    }
    if (typeof to !== 'undefined') {
      history.push(to)
    }
  }
  return (
    <li
      className={classnames(classes.wrapper, {
        [classes.new]: !readAt,
      })}
      onClick={handleClick}>
      {icon ? <div className={classes.icon}>{icon}</div> : null}
      {avatar ? (
        <div className={classes.avatar}>{<UserAvatar user={avatar} />}</div>
      ) : null}
      <div className={classes.body}>
        <div>{title}</div>
        <div className={classes.message}>{body}</div>
        <span className={classes.date}>{getTimeFromNow(date)}</span>
      </div>
      <div className={classes.image}>{image}</div>
    </li>
  )
}
