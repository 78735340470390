import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import useQuery from 'core/react-query/useQuery'
import Table from 'shared/components/kit/table'
import UserAvatar from 'shared/components/user-avatar'
import Button from 'shared/components/kit/button'
import getUserName from 'helpers/utils/getUserName'
import formatPrice from 'helpers/utils/formatPrice'
import formatDate from 'helpers/utils/formatDate'
import LotteryPaymentStatus from 'admin/components/lottery-payment-status'
import getLotteries from 'admin/queries/getLotteries'
import Page from 'admin/components/page'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import LotteryButton from './components/LotteryButton'
import WinnerPayButton from './components/WinnerPayButton'
import classes from './index.module.css'

export default function Lottery() {
  const { data, error, isError, isFetching } = useQuery(getLotteries, {
    keepPreviousData: true,
  })
  const lotteries = data && data.json
  const [currentMonthLottery, setCurrentMonthLottery] = useState()
  const newestMonth = dayjs().format('YYYY-MM-01')
  const oldestMonth = dayjs('2021, 10, 1').format('YYYY-MM-01')

  useEffect(() => {
    localStorage.setItem(
      'currentMonthLotteryDate',
      dayjs().format('YYYY-MM-01')
    )
  }, [])

  useEffect(() => {
    const initialMonth = {
      id: 0,
      date:
        localStorage.getItem('currentMonthLotteryDate') ||
        dayjs().format('YYYY-MM-01'),
      winners: null,
    }
    const currentMonthLotteryDate = localStorage.getItem(
      'currentMonthLotteryDate'
    )
    const currentMonthLottery =
      currentMonthLotteryDate &&
      lotteries?.find((lottery) => lottery.date === currentMonthLotteryDate)
    setCurrentMonthLottery(currentMonthLottery || initialMonth)
  }, [lotteries])

  const year = dayjs(currentMonthLottery?.date).format('YYYY')

  const month = dayjs(currentMonthLottery?.date).format('MMMM')

  const winnersList = currentMonthLottery && currentMonthLottery.winners

  const columns = useMemo(() => getColumns(), [])

  const fetchPrevMonth = () => {
    const prevMonth = dayjs(currentMonthLottery.date)
      .subtract(1, 'month')
      .format('YYYY-MM-01')
    setCurrentMonthLottery(
      lotteries.find((lottery) => lottery.date === prevMonth) || {
        id: 0,
        date: prevMonth,
        winners: null,
      }
    )
    localStorage.setItem('currentMonthLotteryDate', prevMonth)
  }
  const fetchNextMonth = () => {
    const nextMonth = dayjs(currentMonthLottery.date)
      .add(1, 'month')
      .format('YYYY-MM-01')
    setCurrentMonthLottery(
      lotteries.find((lottery) => lottery.date === nextMonth) || {
        id: 0,
        date: nextMonth,
        winners: null,
      }
    )
    localStorage.setItem('currentMonthLotteryDate', nextMonth)
  }

  const actions = (
    <>
      <Button
        disabled={currentMonthLottery?.date === oldestMonth}
        onClick={fetchPrevMonth}
        outlined
        secondary>
        Last Month
      </Button>
      <Button
        disabled={currentMonthLottery?.date === newestMonth}
        onClick={fetchNextMonth}
        outlined
        secondary>
        Next Month
      </Button>
    </>
  )

  if (isError) {
    return <Page error={error.message} />
  }

  if (isFetching) {
    return (
      <div className={classes.loading}>
        <Icon>
          <Spin />
        </Icon>
        <div>Loading...</div>
      </div>
    )
  }

  return (
    <Page actions={actions} title={`${month} ${year} Winners`}>
      {winnersList && winnersList.length > 0 ? (
        <Table columns={columns} data={winnersList} loading={isFetching} />
      ) : (
        <div className={classes.wrapper}>
          <div>
            {winnersList ? (
              <h2>There is no winner for this month</h2>
            ) : (
              <h2>There is no lottery for this month</h2>
            )}
            {!winnersList && (
              <LotteryButton currentMonthLottery={currentMonthLottery} />
            )}
          </div>
        </div>
      )}
    </Page>
  )
}

function getColumns() {
  return [
    {
      className: classes.avatar,
      render: (monthWinner) => <UserAvatar user={monthWinner.user} />,
    },
    {
      title: 'Username',
      render: (monthWinner) => (
        <>
          <span className="mr-4">{getUserName(monthWinner.user)}</span>
        </>
      ),
    },
    {
      title: 'Amount',
      render: (monthWinner) => formatPrice(monthWinner.amount),
    },
    {
      className: 'w-1/6',
      title: 'Status',
      render: (monthWinner) => (
        <LotteryPaymentStatus status={monthWinner.status} />
      ),
    },
    {
      title: 'Reference',
      render: (monthWinner) =>
        monthWinner.status === 'paid' && monthWinner.transaction.reference,
    },
    {
      render: (monthWinner) =>
        monthWinner.status === 'paid' ? (
          `Paid at ${formatDate(monthWinner.completed_at)}`
        ) : (
          <WinnerPayButton
            winnerId={monthWinner.id}
            month={dayjs(monthWinner.date).format('MMMM')}
          />
        ),
    },
  ]
}
