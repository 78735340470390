import { useState } from 'react'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import getUserName from 'helpers/utils/getUserName'
import getTimeFromNow from 'helpers/utils/getTimeFromNow'
import truncate from 'helpers/utils/truncate'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import getUserAvatar from 'helpers/utils/getUserAvatar'
import Icon from 'shared/components/kit/icon'
import VideoThumbnail from 'shared/components/video-thumbnail'
import Radio from 'shared/components/form/radio'
import notification from 'shared/components/kit/notification'
import Modal from 'shared/components/kit/modal'
import Field from 'shared/components/form/field'
import RichText from 'shared/components/form/rich-text'
import deleteComment from 'publisher/mutations/deleteComment'
import pinCommentToVideos from 'publisher/queries/pinCommentToVideos'
import getComments from 'publisher/queries/getComments'
import unpinComment from 'publisher/queries/unpinComment'
import { ReactComponent as ReplySVG } from 'svg/reply.svg'
import { ReactComponent as LikeOutlinedSVG } from 'icons/thumb-up-outlined.svg'
import { ReactComponent as LikeFilledSVG } from 'icons/thumb-up-filled.svg'
import { ReactComponent as DislikeOutlinedSVG } from 'icons/thumb-down-outlined.svg'
import { ReactComponent as DislikeFilledSVG } from 'icons/thumb-down-filled.svg'
import classes from './PinnedComment.module.css'

export default function PinnedComment(props) {
  const {
    comment,
    onLike,
    onDislike,
    showReplies,
    summary,
    onSelectComment,
    selectedComment,
    onSetVisibleDeleteComment,
    onSetVisiblePin,
    onSetVisibleUnpin,
    visibleUnpin,
    visibleDeleteComment,
    visiblePin,
  } = props
  const queryClient = useQueryClient()

  const parsedLocation = useParsedLocation()
  const isRememberedTab = parsedLocation.pathname.endsWith(
    'publisher/comments/remembered'
  )
  const isMentionedTab = parsedLocation.pathname.endsWith(
    'publisher/comments/mentioned'
  )
  const { page, sort, video, time, perPage } = parsedLocation.queryParams

  const invalidateComments = () =>
    queryClient.invalidateQueries({
      query: getComments,
    })

  const [currentlyPinnedAction, setCurrentlyPinnedAction] = useState('Unpin')
  const [text, setText] = useState()

  const unpinMutation = useMutation(unpinComment, {
    onMutate: (res) => {
      queryClient.setQueryData({
        query: getComments,
        variables: {
          perPage: 1,
          video: Number(video),
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            data: prev.json.data.map((item) => {
              if (item.id === comment.id) {
                return {
                  ...item,
                  is_pinned: false,
                }
              }
              return item
            }),
          },
        }),
      })
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
        },
        updater: (prev) => {
          return {
            ...prev,
            json: {
              ...prev.json,
              data: prev.json.data.map((itm) => {
                if (itm.id === res.id) {
                  return {
                    ...itm,
                    is_pinned: false,
                  }
                }
                return itm
              }),
            },
          }
        },
      })
    },
    onSuccess: () => {
      invalidateComments()
    },
  })

  const deleteMutation = useMutation(deleteComment, {
    onSuccess: () => {
      invalidateComments()
      if (visibleDeleteComment) {
        notification.success({
          title: `Comment deleted successfully!`,
        })
      }
    },
  })

  const pinMutation = useMutation(pinCommentToVideos, {
    onSuccess: async () => {
      if (currentlyPinnedAction === 'UnpinDelete') {
        await deleteMutation.mutateAsync({
          id: comment.id,
        })
      } else {
        invalidateComments()
      }

      setCurrentlyPinnedAction('Unpin')
      onSetVisiblePin(false)
      setText('')
      // notification.success({
      //   title: `Comment successfully created and pinned to the selected video. ${
      //     currentlyPinnedAction === 'UnpinDelete'
      //       ? '\n the previously pinned comment with its replies was deleted.'
      //       : ''
      //   }`,
      // })
    },
  })

  const handleUnpin = async () => {
    try {
      await unpinMutation.mutateAsync({ id: comment.id, comment })
    } catch (error) {
      notification.error({ title: error.message })
    } finally {
      onSetVisibleUnpin(false)
    }
  }

  const handleCancel = () => {
    setCurrentlyPinnedAction('Unpin')
    onSetVisiblePin(false)
    setText('')
  }

  const handleDelete = async () => {
    try {
      await deleteMutation.mutateAsync({ id: comment.id })
    } catch (error) {
      notification.error({ title: error.message })
    } finally {
      onSetVisibleDeleteComment(false)
    }
  }

  const handleCancelDelete = () => {
    onSetVisibleDeleteComment(false)
  }

  const handleCancelUnpin = () => {
    onSetVisibleUnpin(false)
  }

  const handleOk = async () => {
    try {
      await pinMutation.mutateAsync({
        text,
        videos: comment.video.id,
      })
    } catch (error) {
      notification.error({ title: error.message })
      setCurrentlyPinnedAction('Unpin')
      onSetVisiblePin(false)
      setText('')
    }
  }

  const handleLike = (e) => {
    e.stopPropagation()
    onLike()
  }

  const handleDislike = (e) => {
    e.stopPropagation()
    onDislike()
  }

  return (
    <>
      <div className={classes.wrapper}>
        <ul className={classes.comments}>
          <li
            className={
              comment && comment.id === selectedComment?.id
                ? classes.selected
                : ''
            }
            onClick={() => onSelectComment(comment)}>
            <div className={classes.videoInfo}>
              <VideoThumbnail video={comment.video} />
              <h4 title={comment.video.title}>
                {truncate(comment.video.title, 25)}
              </h4>
            </div>
            <div className={classes.commentInfo}>
              <div>
                <header className={classes.header}>
                  <span className={classes.userInfo}>
                    <img
                      alt={getUserName(comment.user)}
                      src={getUserAvatar(comment.user.avatar)}
                    />
                    {getUserName(comment.user)}
                  </span>
                  <span>{getTimeFromNow(comment.created_at)}</span>
                </header>

                <div title={comment.text} className="text-sm break-all">
                  {summary ? truncate(comment.text, 120) : comment.text}
                </div>

                {(typeof onDislike === 'function' ||
                  typeof onLike === 'function') && (
                  <footer className={classes.meta}>
                    {showReplies && (
                      <div className="mt-1">
                        <Icon className={classes.metaIcon}>
                          <ReplySVG />
                        </Icon>
                        <span className={classes.metaValue}>
                          {comment.replies_count}
                        </span>
                      </div>
                    )}
                    <div>
                      <button onClick={handleLike}>
                        <Icon className={classes.metaIcon}>
                          {comment.is_liked ? (
                            <LikeFilledSVG className={classes.active} />
                          ) : (
                            <LikeOutlinedSVG />
                          )}
                        </Icon>
                        <span className={classes.metaValue}>
                          {comment.likes_count}
                        </span>
                      </button>
                    </div>
                    <div>
                      <button onClick={handleDislike}>
                        <Icon className={classes.metaIcon}>
                          {comment.is_disliked ? (
                            <DislikeFilledSVG className={classes.active} />
                          ) : (
                            <DislikeOutlinedSVG />
                          )}
                        </Icon>
                        <span className={classes.metaValue}>
                          {comment.dislikes_count}
                        </span>
                      </button>
                    </div>
                  </footer>
                )}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <Modal
        title="Pin Comment"
        visible={visiblePin}
        onCancel={handleCancel}
        onOk={handleOk}
        okButtonProps={{
          disabled: !text,
        }}
        okText="Create"
        size="md"
        submitting={pinMutation.loading}>
        <Field label="Add your comment below that you would like to replace the current pinned comment with.">
          <RichText
            value={text}
            onChange={setText}
            placeholder="Add your comment here..."
            multiline
          />
        </Field>
        {comment.user.id === comment.video.user_id && (
          <Field label="What do you want to do with the comment currently pinned?">
            <Radio
              checked={currentlyPinnedAction === 'Unpin'}
              onChange={() => setCurrentlyPinnedAction('Unpin')}
              description="Unpin (conversion to a regular comment)"
            />
            <Radio
              checked={currentlyPinnedAction === 'UnpinDelete'}
              onChange={() => setCurrentlyPinnedAction('UnpinDelete')}
              description="Unpin and delete (removes comment and any replies permanently)"
            />
          </Field>
        )}
      </Modal>
      <Modal
        onOk={handleUnpin}
        onCancel={handleCancelUnpin}
        okText="Unpin"
        title="Unpin Comment"
        size="sm"
        visible={visibleUnpin}>
        <p>Are you sure you want to unpin this comment?</p>
      </Modal>
      <Modal
        onOk={handleDelete}
        onCancel={handleCancelDelete}
        okText="Delete"
        title="Delete Comment"
        size="sm"
        visible={visibleDeleteComment}>
        <p>
          Are you sure you want to delete this comment? the comment and any
          replies will be permanently removed.
        </p>
      </Modal>
    </>
  )
}
