import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import useProps from '../hooks/useProps'
import classes from '../index.module.css'

export default function InvoiceOffer({ setValues, values, error }) {
  const { getInputPropsForByTier, getInputPropsForByIndex, getErrorPropsFor } =
    useProps({ setValues, values, error })

  return (
    <>
      <h3 className={classes.subHeader}>Invoice / offer specification</h3>
      <div className={classes.invoiceOffer}>
        <div className={classes.groupNames}>
          <Field
            label="Tier 1 group name"
            {...getErrorPropsFor('tiers_data', 'group', 0)}>
            <Input {...getInputPropsForByTier('tiersData', 'group', 1)} />
          </Field>
          <Field
            label="Tier 2 group name"
            {...getErrorPropsFor('tiers_data', 'group', 1)}>
            <Input {...getInputPropsForByTier('tiersData', 'group', 2)} />
          </Field>
          <Field
            label="Tier 3 group name"
            {...getErrorPropsFor('tiers_data', 'group', 2)}>
            <Input {...getInputPropsForByTier('tiersData', 'group', 3)} />
          </Field>
          <Field
            label="Tier 4 group name"
            {...getErrorPropsFor('tiers_data', 'group', 3)}>
            <Input {...getInputPropsForByTier('tiersData', 'group', 4)} />
          </Field>
          <Field
            label="Tier 5 group name"
            {...getErrorPropsFor('tiers_data', 'group', 4)}>
            <Input {...getInputPropsForByTier('tiersData', 'group', 5)} />
          </Field>
        </div>
        <div className={classes.articleNames}>
          <Field
            label="Tier 1 article name"
            {...getErrorPropsFor('tiers_data', 'article', 0)}>
            <Input {...getInputPropsForByTier('tiersData', 'article', 1)} />
          </Field>
          <Field
            label="Tier 2 article name"
            {...getErrorPropsFor('tiers_data', 'article', 1)}>
            <Input {...getInputPropsForByTier('tiersData', 'article', 2)} />
          </Field>
          <Field
            label="Tier 3 article name"
            {...getErrorPropsFor('tiers_data', 'article', 2)}>
            <Input {...getInputPropsForByTier('tiersData', 'article', 3)} />
          </Field>
          <Field
            label="Tier 4 article name"
            {...getErrorPropsFor('tiers_data', 'article', 3)}>
            <Input {...getInputPropsForByTier('tiersData', 'article', 4)} />
          </Field>
          <Field
            label="Tier 5 article name"
            {...getErrorPropsFor('tiers_data', 'article', 4)}>
            <Input {...getInputPropsForByTier('tiersData', 'article', 5)} />
          </Field>
        </div>
      </div>
    </>
  )
}
