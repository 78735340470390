import Tag from 'shared/components/kit/tag'

const STATUSES = {
  unpaid: {
    color: 'bg-blue-400',
    label: 'Scheduled',
  },
  paid: {
    color: 'bg-green-400',
    label: 'Paid',
  },
  'N/A': {
    color: 'bg-gray-300',
    label: 'N/A',
  },
}

export default function EarningStatus({ status }) {
  const data = STATUSES[status]
  return <Tag type={data.color}>{data.label}</Tag>
}
