// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout_wrapper__3Srzf {\n  min-height: 100vh;\n  background-color: var(--publisher-panel-bg);\n}\n\n.layout_wrapper__3Srzf:after {\n  clear: both;\n  content: '';\n  display: block;\n}\n\n.layout_body__2XIZ7 {\n  margin-left: auto;\n  margin-right: auto;\n}\n", "",{"version":3,"sources":["webpack://src/landings/components/layout/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,iBAAmB;EDAjB,2CAA2C;AAC7C;;AAEA;EACE,WAAW;EACX,WAAW;EACX,cAAc;AAChB;;AAEA;ECTA,iBAAmB;EAAnB,kBAAmB;ADWnB","sourcesContent":[".wrapper {\n  @apply min-h-screen;\n  background-color: var(--publisher-panel-bg);\n}\n\n.wrapper:after {\n  clear: both;\n  content: '';\n  display: block;\n}\n\n.body {\n  @apply mx-auto;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "layout_wrapper__3Srzf",
	"body": "layout_body__2XIZ7"
};
export default ___CSS_LOADER_EXPORT___;
