import classnames from 'classnames'
import classes from './Overview.module.css'

export default function Overview({ statistics }) {
  return (
    <div className={classes.overview}>
      <div className={classnames(classes.box, 'h-56')}>
        <div>Total Days active: {statistics.overview.total_days_active}</div>
        <div>Total click thru: {statistics.overview.total_clicks}</div>
        <div>
          Avr click thru/day: {statistics.overview.avarage_clicks_per_day}
        </div>
        <div>Most click thru/day: {statistics.overview.max_clicks_per_day}</div>
        <div>
          Least click thru/day: {statistics.overview.min_clicks_per_day}
        </div>
        <div>
          Registered users: {statistics.overview.registered_users_total_clicks}
        </div>
        <div>
          Unknown users: {statistics.overview.unknown_users_total_clicks}
        </div>
      </div>
      <div className={classes.box}>
        <h5 className="mb-2">Click thru distribution per coin</h5>
        <div className="h-36 overflow-auto">
          {statistics.crypto_currencies.map((item, index) => {
            return (
              <div key={index}>
                {item.name}: {item.total_clicks}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
