// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edit_top__3254a > :not([hidden]) ~ :not([hidden]) {\n  --tw-space-x-reverse: 0;\n  margin-right: calc(1.5rem * var(--tw-space-x-reverse));\n  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));\n}\n\n.edit_top__3254a {\n  display: flex;\n}\n\n.edit_fields__2-viY {\n  width: 60%;\n}\n\n.edit_preview__3iWV0 {\n  width: 40%;\n}\n\n.edit_thumbnail__3gP1w {\n  width: 210px;\n}\n\n.edit_title__1EgKq {\n  flex-grow: 1;\n}\n", "",{"version":3,"sources":["webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js","webpack://src/publisher/pages/video/edit/index.module.css"],"names":[],"mappings":"AAEA;EAAA,uBAAmB;EAAnB,sDAAmB;EAAnB;AAAmB;;AAAnB;EAAA;AAAmB;;ACEnB;EDFA,UAAmB;ACInB;;AAEA;EDNA,UAAmB;ACQnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":["@tailwind base;\n@tailwind components;\n@tailwind utilities;",".top {\n  @apply flex space-x-6;\n}\n\n.fields {\n  @apply w-3/5;\n}\n\n.preview {\n  @apply w-2/5;\n}\n\n.thumbnail {\n  width: 210px;\n}\n\n.title {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top": "edit_top__3254a",
	"fields": "edit_fields__2-viY",
	"preview": "edit_preview__3iWV0",
	"thumbnail": "edit_thumbnail__3gP1w",
	"title": "edit_title__1EgKq"
};
export default ___CSS_LOADER_EXPORT___;
