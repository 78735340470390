export default function readableNumber(input): string {
  const num = Number(input)

  if (typeof num !== 'number') {
    return ''
  }

  if (num >= 1000000) {
    const raw = (num / 1000000).toLocaleString(undefined, {
      maximumFractionDigits: 1,
    })
    return `${raw}M`
  }

  if (num >= 1000) {
    const raw = (num / 1000).toLocaleString(undefined, {
      maximumFractionDigits: 1,
    })
    return `${raw}K`
  }

  const raw = num.toLocaleString(undefined, {
    maximumFractionDigits: 1,
  })
  return `${raw}`
}
