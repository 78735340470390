type GetChannelPerformanceVariables = {
  from?: string,
  to?: string,
  type: 'monthly' | 'total',
}

export default function getChannelPerformance(
  variables: GetChannelPerformanceVariables
) {
  const { from, to, type } = variables

  return {
    path: `/channel/performance/${type}`,
    params: {
      filters: {
        from,
        to,
      },
    },
  }
}
