export default function getPaginatedTableDescription(params) {
  const { currentPage, lastPage, total } = params

  const parts = []

  if (total >= 1) {
    parts.push(total > 1 ? `Found ${total} items` : 'Found 1 item')
    parts.push(`Page ${currentPage || 1} of ${lastPage}`)
  } else {
    parts.push('Nothing found')
  }

  return parts.join(' - ')
}
