type UpdatePlaylistMutationVariables = {
  id: number,
  name: string,
}

export default function updatePlaylist(
  variables: UpdatePlaylistMutationVariables
) {
  return {
    method: 'put',
    path: `/playlists/${variables.id}`,
    params: {
      ...variables,
    },
  }
}
