import { useMemo, useState } from 'react'
import { useParams } from 'react-router'
import get from 'lodash/get'
import useMiniForm from 'core/useMiniForm'
import useQueryClient from 'core/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import notification from 'shared/components/kit/notification'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import RichText from 'shared/components/form/rich-text'
import CrappedImageInput from 'shared/components/form/image/crappedImageInput'
import SelectLanguage from 'shared/components/form/select-language'
import SelectCryptocurrency from 'shared/components/form/select-cryptocurrency'
import SelectPlaylist from 'shared/components/form/select-playlist'
import Select from 'shared/components/form/select'
import Checkbox from 'shared/components/form/checkbox'
import getVideo from 'shared/queries/getVideo'
import SelectMediaType from 'publisher/components/form/select-media-type'
import SelectMediaCategory from 'publisher/components/form/select-media-category'
import SelectMediaTags from 'publisher/components/form/select-media-tags'
import VideoPreview from 'publisher/components/common/VideoPreview'
import Page from 'publisher/components/page'
import useVideo from 'publisher/hooks/useVideo'
import getMyVideos from 'publisher/queries/getMyVideos'
import updateVideo from 'publisher/mutations/updateVideo'
import classes from './index.module.css'

const VIDEO_STATUS_OPTIONS = [
  { label: 'Draft', value: 'draft' },
  { label: 'Published', value: 'published' },
]

const VALIDATION = {
  title: {
    label: 'Video Title',
    rule: (value) => {
      if (value === undefined || value.trim().length === 0) {
        return '*Enter a Title'
      }
    },
  },
  thumbnail: {
    label: 'thumbnail',
    rule: (value, values) => {
      if (values.status === 'published' && value === undefined) {
        return '*Select Thumbnail'
      }
    },
  },
  status: {
    label: 'status',
  },
  category: {
    label: 'category',
    rule: (value, values) => {
      if (values.status === 'published' && value === undefined) {
        return '*Select Main Category'
      }
    },
  },
  language: {
    label: 'language',
    rule: (value, values) => {
      if (values.status === 'published' && value === undefined) {
        return '*Select Language'
      }
    },
  },
  tags: {
    label: 'Tags',
    rule: (value) => {
      if (value.length > 5) {
        return 'You can not select more than 5 tags.'
      }
    },
  },
}

export default function EditVideo() {
  const params = useParams()
  const videoId = Number(params.id)
  const { video } = useVideo(videoId)
  const queryClient = useQueryClient()

  const [showPinnedComment, setShowPinnedComment] = useState(
    !!video.pinned_comment
  )

  const mutation = useMutation(updateVideo, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getVideo,
        variables: {
          id: videoId,
        },
      })
      queryClient.invalidateQueries({
        query: getMyVideos,
        variables: {
          includeCategoryRelation: true,
          includePlaylistsRelation: true,
        },
      })
      notification.success({ title: 'Video edited successfully!' })
    },
    onError: (error) => {
      Object.values(error.json.errors).forEach((er) =>
        notification.error({ title: er })
      )
    },
  })

  const initialValues = useMemo(
    () => ({
      status:
        (video?.status === 'draft_yi' ? 'published' : video?.status) || 'draft',
      title: video?.title || '',
      thumbnail: video?.thumbnail || undefined,
      description: video?.description || '',
      media_type: video.media_type,
      comment_text: video?.pinned_comment?.text || '',
      category: get(video, 'category.id'),
      language: get(video, 'language.id'),
      crypto_currencies: get(video, 'crypto_currencies', []).map(
        (currency) => ({
          data: currency,
          label: currency.name,
          value: currency.id,
        })
      ),
      playlists: get(video, 'playlists', []).map((playlist) => playlist.id),
      tags: video?.tags
        ? video?.tags.map((tag) => ({
            label: tag.name,
            value: tag.name,
          }))
        : [],
    }),
    [video?.id]
  )

  const form = useMiniForm({
    initialValues,
    validation: VALIDATION,
    async onSubmit(values) {
      try {
        await mutation.mutate({
          ...values,
          id: videoId,
          comment_text: showPinnedComment === true ? values.comment_text : '',
          crypto_currencies: (values.crypto_currencies || []).map(
            (currency) => currency.value
          ),
          tags: (values.tags || []).map((tag) => tag.label),
        })
      } catch (error) {
        throw error
      }
    },
  })

  const handleTogglePinnedComment = () => {
    setShowPinnedComment(!showPinnedComment)
  }

  return (
    <Page title="Edit" width="sm">
      <form onSubmit={form.submit}>
        <div className={classes.top}>
          <div className={classes.fields}>
            <div className={classes.title}>
              <Field {...form.getErrorPropsFor('title')}>
                <Input
                  {...form.getInputPropsFor('title')}
                  placeholder="Title"
                />
              </Field>

              <Field {...form.getErrorPropsFor('description')}>
                <RichText
                  {...form.getInputPropsFor('description')}
                  placeholder="Description"
                />
              </Field>
            </div>

            <Field {...form.getErrorPropsFor('thumbnail')}>
              <div className={classes.thumbnail}>
                <CrappedImageInput
                  placeholder="Select Thumbnail..."
                  {...form.getInputPropsFor('thumbnail')}
                  height={720}
                  width={1280}
                />
              </div>
            </Field>
          </div>

          <div className={classes.preview}>
            <VideoPreview
              title={form.get('title')}
              thumbnail={form.get('thumbnail')}
            />
          </div>
        </div>

        <SelectMediaType.Field {...form.getInputPropsFor('media_type')} />

        <SelectMediaCategory.Field
          {...form.getErrorPropsFor('category')}
          {...form.getInputPropsFor('category')}
        />

        <SelectLanguage.Field
          {...form.getErrorPropsFor('language')}
          {...form.getInputPropsFor('language')}
        />

        <SelectCryptocurrency.Field
          {...form.getErrorPropsFor('crypto_currencies')}
          {...form.getInputPropsFor('crypto_currencies')}
        />

        <SelectMediaTags.Field
          {...form.getErrorPropsFor('tags')}
          {...form.getInputPropsFor('tags')}
        />

        <Field
          help="If you want to add you video/podcast to any of your existing playlist, you may do so here."
          label="Playlists"
          {...form.getErrorPropsFor('playlists')}>
          <SelectPlaylist
            {...form.getInputPropsFor('playlists')}
            isMulti={true}
            menuPlacement="top"
          />
        </Field>
        {!video.published_at && (
          <>
            <Field className={!showPinnedComment && 'pb-4'}>
              <Checkbox
                className="-mb-6"
                description="I want to add a pinned comment"
                checked={showPinnedComment}
                onChange={handleTogglePinnedComment}
              />
            </Field>
            <Field
              className={!showPinnedComment && 'hidden'}
              {...form.getErrorPropsFor('comment_text')}
              help="Here you can add a pinned comment to your content that will be displayed on top in the comment section for everyone to see.">
              <RichText
                {...form.getInputPropsFor('comment_text')}
                placeholder="Add your Comment here..."
                multiline
              />
            </Field>
          </>
        )}
        <Field
          className="w-48"
          label="Change Status"
          {...form.getErrorPropsFor('status')}>
          <Select
            {...form.getInputPropsFor('status')}
            menuPlacement="top"
            options={VIDEO_STATUS_OPTIONS}
          />
        </Field>

        <Button loading={form.submitting} type="submit">
          Save Changes
        </Button>
      </form>
    </Page>
  )
}
