import isBeta from 'core/isBeta'
import useProfile from 'shared/hooks/useProfile'
import ChannelAvatar from 'shared/components/channel-avatar'
import Base from 'publisher/components/layouts/Base'
import { ReactComponent as FaqSVG } from 'svg/new-menu-icons/help.svg'
import { ReactComponent as ChannelSVG } from 'svg/new-menu-icons/dashboard.svg'
import { ReactComponent as CommentsSVG } from 'svg/new-menu-icons/comments.svg'
import { ReactComponent as DollarSVG } from 'svg/new-menu-icons/monetize.svg'
import { ReactComponent as TCGSVG } from 'svg/new-menu-icons/tcg-token.svg'
import { ReactComponent as GovernanceSVG } from 'svg/new-menu-icons/tcg-governance.svg'
import { ReactComponent as PlaylistsSVG } from 'svg/new-menu-icons/playlists.svg'
import { ReactComponent as UserSVG } from 'svg/new-menu-icons/channel.svg'
import { ReactComponent as VideosSVG } from 'svg/new-menu-icons/my-media.svg'
import { ReactComponent as AddFriendSVG } from 'svg/new-menu-icons/invite-and-earn.svg'
import classes from './index.module.css'

export default function PublisherDefaultLayout({ children }) {
  const { isPublisher, publisherRequest, user } = useProfile()
  const tcgGovernanceURL = isBeta()
    ? 'https://todayscrypto.com/governance'
    : 'https://todayscrypto.com/governance'

  const channel = user?.channel

  const sidebar = (
    <>
      <div className={classes.channel}>
        <ChannelAvatar channel={channel} />
        {channel ? (
          <>
            <h3>{channel.name}</h3>
            <small>{channel.subscribers_count} Subscribers</small>
          </>
        ) : null}
      </div>

      <ul className={classes.sideMenu}>
        <li>
          <Base.NavLink
            disabled={!isPublisher}
            icon={<ChannelSVG />}
            text="Dashboard"
            to="/publisher"
            exact
          />
        </li>
        <li>
          <Base.NavLink
            disabled={!isPublisher}
            icon={<VideosSVG />}
            text="My Media"
            to="/publisher/media"
            exact
          />
        </li>
        <li>
          <Base.NavLink
            disabled={!isPublisher}
            icon={<PlaylistsSVG />}
            text="My Playlists"
            to="/publisher/playlists"
            exact
          />
        </li>
        <li>
          <Base.NavLink
            disabled={!isPublisher}
            icon={<UserSVG />}
            text="My Channel"
            to="/publisher/channel"
            exact
          />
        </li>
        <li>
          <Base.NavLink
            disabled={!isPublisher}
            icon={<CommentsSVG />}
            text="Comments"
            to="/publisher/comments"
          />
        </li>
        <li>
          <Base.NavLink
            disabled={!isPublisher}
            icon={<DollarSVG />}
            isActive={(match, location) =>
              match || location.pathname === '/publisher/payout'
            }
            text="Monetize"
            to="/publisher/monetize"
            exact
          />
        </li>
        <li>
          <Base.NavLink
            disabled={!isPublisher}
            icon={<TCGSVG className={classes.tcgSVG} />}
            text="TCG Token"
            to="/publisher/tcg"
            exact
          />
        </li>
        <li>
          <Base.NavLink
            disabled={!isPublisher}
            icon={<GovernanceSVG />}
            text="TCG Governance"
            to={{ pathname: tcgGovernanceURL }}
            target="_blank"
            exact
          />
        </li>
        <li>
          <Base.NavLink
            disabled={!isPublisher}
            icon={<AddFriendSVG />}
            text="Invite & Earn"
            to="/publisher/invite-earn"
            exact
          />
        </li>
        <li>
          <Base.NavLink
            disabled={!isPublisher && !publisherRequest.hasRequested}
            icon={<FaqSVG />}
            text="Help"
            to="/publisher/faq"
            exact
          />
        </li>
      </ul>
    </>
  )

  return <Base main={children} sidebar={sidebar} />
}
