import { useMemo } from 'react'
import { useParams } from 'react-router'
import get from 'lodash/get'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import useQuery from 'core/react-query/useQuery'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Button from 'shared/components/kit/button'
import notification from 'shared/components/kit/notification'
import editCompany from 'admin/queries/editCompany'
import Page from 'admin/components/page'
import CompanyAvatar from './company-avatar'
import getCompanyDetails from 'admin/queries/getCompanyDetails'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import classes from './EditCRM.module.css'

const VALIDATION = {
  name: {
    label: 'Company Name',
    rule: 'required',
  },
  vatNumber: {
    label: 'Vat Number',
    rule: 'required',
  },
  vatRate: {
    label: 'Vat Rate',
    rule: 'required',
  },
  streetAddress: {
    label: 'Street Address',
    rule: 'required',
  },
  streetNo: {
    label: 'Street No',
    rule: 'required',
  },
  postalCode: {
    label: 'Postal Code',
    rule: 'required',
  },
  city: {
    label: 'City',
    rule: 'required',
  },
  country: {
    label: 'Country',
    rule: 'required',
  },
  contactPersonName: {
    label: 'Contact Person Name',
    rule: 'required',
  },
  contactPersonEmail: {
    label: 'Contact Person Email',
    rule: 'required',
  },
  invoicingQuestionsEmail: {
    label: 'Invoicing Questions Email',
    rule: 'required',
  },
}

export default function EditCRM() {
  const queryClient = useQueryClient()
  const { companyId } = useParams()

  const { data, isLoading } = useQuery(getCompanyDetails, {
    variables: {
      companyId,
    },
  })

  const company = get(data, 'json', {})

  const mutation = useMutation(editCompany, {
    onSuccess() {
      queryClient.invalidateQueries({
        query: getCompanyDetails,
        variables: {
          companyId,
        },
      })
      notification.success({ title: 'Company updated successfully!' })
    },
  })

  const form = useMiniForm({
    validation: VALIDATION,
    initialValues: useMemo(() => {
      return {
        id: company.id,
        name: company.name,
        avatarUrl: company.avatar_url,
        vatNumber: company.vat_number,
        vatRate: company.vat_rate,
        streetAddress: company.street_address,
        streetNo: company.street_no,
        postalCode: company.postal_code,
        city: company.city,
        country: company.country,
        contactPersonName: company.contact_person_name,
        contactPersonEmail: company.contact_person_email,
        contactPersonPhone: company.contact_person_name,
        invoicingQuestionsEmail: company.invoicing_questions_email,
      }
    }, [company.id]),
    onSubmit(values) {
      try {
        mutation.mutate(values)
      } catch (error) {
        throw error
      }
    },
  })

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <Icon>
          <Spin />
        </Icon>
        <div>Loading...</div>
      </div>
    )
  }

  return (
    <Page title="Edit Customer">
      <form onSubmit={form.submit}>
        <div className={classes.container}>
          <h3 className={classes.companyDetails}>Company details </h3>
          <div className={classes.img}>
            <Field>
              <CompanyAvatar
                className="w-36 h-36"
                placeholder="Choose a Picture..."
                circle
                height={100}
                width={100}
                {...form.getInputPropsFor('avatarUrl')}
              />
            </Field>
          </div>
          <div className={classes.name}>
            <Field
              label="Company name"
              {...form.getErrorPropsFor('name')}
              required>
              <Input {...form.getInputPropsFor('name')} />
            </Field>
          </div>
          <div className={classes.vatNumber}>
            <Field
              label="Vat number"
              {...form.getErrorPropsFor('vatNumber')}
              required>
              <Input {...form.getInputPropsFor('vatNumber')} />
            </Field>
          </div>
          <div className={classes.vatRate}>
            <Field
              label="Vat rate"
              {...form.getErrorPropsFor('vatRate')}
              required>
              <Input
                type="number"
                min="0"
                max="100"
                {...form.getInputPropsFor('vatRate')}
              />
            </Field>
          </div>
          <h3 className={classes.contactDetails}>Contact details </h3>
          <div className={classes.contactPerson}>
            <Field
              label="Contact person"
              {...form.getErrorPropsFor('contactPersonName')}
              required>
              <Input {...form.getInputPropsFor('contactPersonName')} />
            </Field>
          </div>
          <div className={classes.emailContactPerson}>
            <Field
              label="Email to contact person"
              {...form.getErrorPropsFor('contactPersonEmail')}
              required>
              <Input
                type="email"
                {...form.getInputPropsFor('contactPersonEmail')}
              />
            </Field>
          </div>
          <div className={classes.phoneContactPerson}>
            <Field
              label="Phone number to contact person"
              {...form.getErrorPropsFor('contactPersonPhone')}>
              <Input {...form.getInputPropsFor('contactPersonPhone')} />
            </Field>
          </div>
          <div className={classes.emailInvoicing}>
            <Field
              label="Email for invoicing questions"
              {...form.getErrorPropsFor('invoicingQuestionsEmail')}
              required>
              <Input
                type="email"
                {...form.getInputPropsFor('invoicingQuestionsEmail')}
              />
            </Field>
          </div>
          <h3 className={classes.billingAddress}>Billing Address</h3>
          <div className={classes.streetAddress}>
            <Field
              label="Street Address"
              {...form.getErrorPropsFor('streetAddress')}
              required>
              <Input {...form.getInputPropsFor('streetAddress')} />
            </Field>
          </div>
          <div className={classes.streetNo}>
            <Field
              label="Street No"
              {...form.getErrorPropsFor('streetNo')}
              required>
              <Input {...form.getInputPropsFor('streetNo')} />
            </Field>
          </div>
          <div className={classes.postalCode}>
            <Field
              label="Postal code"
              {...form.getErrorPropsFor('postalCode')}
              required>
              <Input {...form.getInputPropsFor('postalCode')} />
            </Field>
          </div>
          <div className={classes.city}>
            <Field label="City" {...form.getErrorPropsFor('city')} required>
              <Input {...form.getInputPropsFor('city')} />
            </Field>
          </div>
          <div className={classes.country}>
            <Field
              label="Country"
              {...form.getErrorPropsFor('country')}
              required>
              <Input {...form.getInputPropsFor('country')} />
            </Field>
          </div>
          <div className={classes.campaignHistory}>
            <h3>Campaign History</h3>
            <table>
              <thead>
                <tr>
                  <td>Campaign ID</td>
                  <td>Company Name</td>
                  <td>Customer ID</td>
                  <td>Campaign name</td>
                  <td>Reach</td>
                  <td>CT</td>
                  <td>Status</td>
                  <td>Creation date</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={classes.actionButtons}>
            <Button
              component="link"
              to="/admin/companies"
              transparent
              mood="neutral">
              Back
            </Button>
            <Button type="submit">Edit Customer</Button>
          </div>
        </div>
      </form>
    </Page>
  )
}
