import { useCallback, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import useQuery from 'core/react-query/useQuery'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import formatDateOnly from 'helpers/utils/formatDateOnly'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import VideoStatus from 'shared/components/video-status'
import notification from 'shared/components/kit/notification'
import Table from 'shared/components/kit/table'
import ChooseVideoButton from 'shared/components/choose-video-button'
import VideoThumbnail from 'shared/components/video-thumbnail'
import BackTo from 'shared/components/kit/back-to'
import Page from 'publisher/components/page'
import getPlaylist from 'publisher/queries/getPlaylist'
import bulkAddVideosToPlaylists from 'publisher/queries/bulkAddVideosToPlaylists'
import removeVideoFromPlaylist from 'publisher/queries/removeVideoFromPlaylist'
import getPlaylistVideos from 'publisher/queries/getPlaylistVideos'
import EditPlaylistButton from './components/EditPlaylistButton'
import DeletePlaylistButton from './components/DeletePlaylistButton'
import classes from './index.module.css'

const INITIAL_FILTERS = {
  page: 1,
}

export default function Playlist() {
  const params = useParams()

  const playlistId = Number(params.id)

  const history = useHistory()

  const queryClient = useQueryClient()

  const [filters, setFilters] = useState(INITIAL_FILTERS)

  const playlistQR = useQuery(getPlaylist, {
    variables: {
      id: playlistId,
    },
  })

  const playlist = playlistQR.data?.json

  const videosQR = useQuery(getPlaylistVideos, {
    variables: {
      id: playlistId,
      page: filters.page,
      includeCategory: true,
    },
  })

  const {
    data: videos,
    currentPage,
    lastPage,
  } = getPaginatedDataFromAPI(videosQR.data?.json)

  const bulkAdd = useMutation(bulkAddVideosToPlaylists, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getPlaylistVideos,
      })
      notification.success({
        title: 'The selected media has been added to your playlist.',
      })
    },
  })

  const remove = useMutation(removeVideoFromPlaylist, {
    onSuccess: async () => {
      queryClient.invalidateQueries({
        query: getPlaylistVideos,
      })
      notification.success({
        title: 'Media has been removed from your playlist.',
      })
    },
  })

  const handleRemove = useCallback(
    async (video) => {
      try {
        await remove.mutateAsync({
          playlist: playlist.id,
          video: video.id,
        })
      } catch (error) {
        console.log(error)
      }
    },
    [remove, playlist]
  )

  const columns = useMemo(
    () => [
      {
        className: classes.thumbnail,
        title: 'Thumbnail',
        render: (video) => <VideoThumbnail video={video} />,
      },
      {
        className: classes.title,
        title: 'Title',
        render: (video) => (
          <>
            {video.title ? (
              video.title
            ) : (
              <span className={classes.untitled}>Untitled</span>
            )}
          </>
        ),
      },
      {
        title: 'Category',
        className: classes.category,
        render: (video) => video.category?.name,
      },
      {
        title: 'Status',
        className: classes.status,
        render: (video) => <VideoStatus status={video.status} />,
      },
      {
        className: classes.published,
        title: 'Publish Date',
        render: (video) =>
          video.published_at && formatDateOnly(video.published_at),
      },
      {
        className: classes.actions,
        render: (video) => (
          <Button
            mood="primary"
            onClick={() => handleRemove(video)}
            size="sm"
            transparent>
            Remove
          </Button>
        ),
      },
    ],
    [handleRemove]
  )

  const handleAddVideos = (videos) => {
    bulkAdd.mutate({
      playlists: [playlist.id],
      videos: videos.map((video) => video.id),
    })
  }

  const handleDeleted = () => {
    history.push(`/publisher/playlists`)
  }

  const handlePaginate = (page) => {
    setFilters((prev) => ({
      ...prev,
      page,
    }))
  }

  if (playlistQR.isError) {
    return <Page error={playlistQR.error.message} />
  }

  if (videosQR.isError) {
    return <Page error={videosQR.error.message} />
  }

  const actions = (
    <>
      <ChooseVideoButton
        key={videos.map((video) => video.id).join('-')}
        excludePlaylist={playlistId}
        okText="Add to Playlist"
        onChoose={handleAddVideos}
      />
      <EditPlaylistButton playlist={playlist} />
      <DeletePlaylistButton id={playlistId} onDeleted={handleDeleted} />
    </>
  )

  const pageTitle = playlistQR.isLoading
    ? `Manage Playlist: #${playlistId}`
    : `Manage Playlist: ${playlist.name}`
  const pageDescription = playlistQR.isLoading
    ? ''
    : `Uploads: ${playlist.all_videos_count}`

  return (
    <Page
      actions={actions}
      backTo={BackTo(`/publisher/playlists`, 'My Playlists')}
      description={pageDescription}
      title={pageTitle}>
      <Table
        columns={columns}
        currentPage={currentPage}
        data={videos}
        lastPage={lastPage}
        loading={videosQR.isFetching}
        onPaginate={handlePaginate}
      />
    </Page>
  )
}
