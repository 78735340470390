import { createElement, forwardRef } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import Spin from 'shared/components/kit/spin'
import classes from './index.module.css'

type Props = {
  block?: boolean,
  circle?: boolean,
  className?: '',
  component?: 'button' | 'a' | 'link' | 'label',
  loading?: boolean,
  mood?: 'neutral' | 'primary' | 'secondary',
  noHorizontalPadding?: boolean,
  secondary?: boolean,
  size?: 'sm' | 'md',
  to?: string,
  square?: boolean,
  transparent?: boolean,
}

function ButtonComponent(props: Props, ref) {
  const {
    block,
    children,
    circle,
    className,
    component,
    disabled,
    loading,
    mood,
    noHorizontalPadding,
    outlined,
    secondary,
    size,
    square,
    transparent,
    ...rest
  } = props

  const ComponentClass = component === 'link' ? Link : component

  const moodClassNames = classnames(
    secondary ? classes.secondary : classes[mood]
  )

  return createElement(ComponentClass, {
    className: classnames(
      'button',
      classes.button,
      className,
      moodClassNames,
      classes[size],
      {
        [classes.disabled]: disabled,
        [classes.circle]: circle,
        [classes.fullWidth]: block,
        [classes.noHorizontalPadding]: noHorizontalPadding,
        [classes.filled]: !transparent,
        [classes.transparent]: transparent,
        [classes.square]: square,
        [classes.loading]: loading,
      }
    ),
    ...rest,
    ref,
    disabled: disabled || loading,
    children: (
      <>
        <span className={classes.children}>{children}</span>
        {loading && (
          <span className={classes.spin}>
            <Spin />
          </span>
        )}
      </>
    ),
  })
}

const Button = forwardRef(ButtonComponent)

Button.defaultProps = {
  component: 'button',
  mood: 'primary',
  size: 'md',
}

export default Button
