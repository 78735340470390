import { useCallback, useEffect, useState } from 'react'
import cookie from 'core/cookie'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'

type Props = {
  description?: any,
  onCancel?: Function,
  onVerified: Function,
  title?: any,
  visible: boolean,
}

export default function EmailVerificationModal(props: Props) {
  const { description, onCancel, onVerified, title, visible } = props

  const { isLoading, mutateAsync: verify } = useMutation(verifyMutation, {
    onSuccess: (response) => {
      onVerified?.(response)
    },
  })

  const form = useMiniForm({
    validation: {
      email_verification_code: {
        rule: 'required',
        label: 'E-mail Verification Code',
      },
    },
    onSubmit: async (values) => {
      try {
        const response = await verify(values)
        if (response?.json?.token) {
          cookie().set('jwt', response.json.token)
          window.localStorage.removeItem('auth_key')
          window.location.href = '/' + window.location.href.split('/')[1]
        }
      } catch (error) {
        throw error.json
      }
    },
  })

  useEffect(() => {
    if (!visible) {
      form.reset()
    }
  }, [form.reset, visible])

  return (
    <Modal
      footer={null}
      modalClassName="w-96"
      onCancel={onCancel}
      size={null}
      title={title}
      visible={visible}>
      {description ? <p className="mb-8">{description}</p> : null}

      <form onSubmit={form.submit}>
        <Field
          {...form.getErrorPropsFor('email_verification_code')}
          help="Enter the 6-digit code sent to your mail"
          label="E-mail Verification Code"
          required>
          <div className="relative">
            <Input
              maxLength={6}
              placeholder="xxxxxx"
              {...form.getInputPropsFor('email_verification_code')}
            />
            <div className="absolute right-4 top-1 z-10">
              {visible ? <GetCodeButton /> : null}
            </div>
          </div>
        </Field>

        <Button className="mt-2" loading={isLoading} type="submit" block>
          Submit
        </Button>
      </form>
    </Modal>
  )
}

EmailVerificationModal.defaultProps = {
  description:
    'Please open your mailbox and enter the 6-digit code in the following field:',
  title: 'Check your mail',
}

function GetCodeButton() {
  const [showTimer, setShowTimer] = useState(true)

  const { isLoading, isSuccess, mutate } = useMutation(
    sendCodeToEmailMutation,
    {
      onSuccess: () => {
        setShowTimer(true)
      },
    }
  )

  const handleSendCodeToEmail = (event) => {
    event.preventDefault()
    mutate()
  }

  const handleTimeOver = useCallback(() => {
    setShowTimer(false)
  }, [])

  if (showTimer) {
    return <Timer onTimeOver={handleTimeOver} />
  }

  return (
    <Button
      loading={isLoading}
      mood="secondary"
      onClick={handleSendCodeToEmail}
      size="sm"
      noHorizontalPadding
      transparent>
      {isSuccess ? 'Resend Code' : 'Send Code'}
    </Button>
  )
}

function Timer({ onTimeOver }) {
  const [seconds, setSeconds] = useState(59)

  useEffect(() => {
    if (seconds < 1) {
      onTimeOver()
      return
    }
    const ref = setTimeout(() => {
      setSeconds((prev) => prev - 1)
    }, 1000)
    return () => {
      clearTimeout(ref)
    }
  }, [onTimeOver, seconds])

  return <span className="leading-8 text-neutral">{seconds}s</span>
}

function verifyMutation(variables) {
  return {
    method: 'put',
    path: '/email/verify',
    params: {
      ...variables,
    },
  }
}

function sendCodeToEmailMutation() {
  return {
    method: 'put',
    path: '/email/send-code',
  }
}
