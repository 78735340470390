import { useCallback, useState } from 'react'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import SignIn from './components/SignIn'
import ForgotPassword from './components/ForgotPassword'
import classes from './index.module.css'

export default function Login() {
  const location = useParsedLocation()

  const [form, setForm] = useState(location.queryParams.form || 'login')

  const handleLogin = useCallback(() => setForm('login'), [])

  const handleForgot = useCallback(() => setForm('forgot'), [])

  return (
    <div className={classes.wrapper}>
      {form === 'login' && (
        <SignIn error={location.queryParams.error} onForgot={handleForgot} />
      )}
      {form === 'forgot' && <ForgotPassword onLogin={handleLogin} />}
    </div>
  )
}
