import { useState, forwardRef } from 'react'
import classnames from 'classnames'
import useMutation from 'core/react-query/useMutation'
import getFileAbsoluteUrl from 'core/getFileAbsoluteUrl'
import { ReactComponent as PlusSVG } from 'svg/plus.svg'
import { ReactComponent as UploadSVG } from 'svg/upload.svg'
import { ReactComponent as DeleteSVG } from 'icons/close.svg'
import Spin from 'shared/components/kit/spin'
import Modal from 'shared/components/kit/modal'
import classes from './index.module.css'

type Props = {
  className?: string,
  circle?: boolean,
  height: number,
  onChange?: Function,
  pixels?: boolean,
  width: number,
  onChangeImages?: Function,
  images?: [],
  canUpload?: boolean,
  canDelete?: boolean,
}

function ImageInput(props: Props, ref) {
  const {
    className,
    circle,
    height,
    onChange,
    pixels,
    placeholder,
    value,
    width,
    onChangeImages,
    images,
    canDelete,
    canUpload,
  } = props
  const [previewVisible, setPreviewVisible] = useState(false)
  const mutation = useMutation(uploadImage, {
    onSuccess: (response) => {
      onChange(response.json.file)
    },
  })

  const handleChange = async (event) => {
    const files = event.target.files
    try {
      await mutation.mutateAsync({
        file: files[0],
      })
    } catch (error) {}
  }

  const wrapperClassName = classnames(classes.wrapper, className, {
    [classes.circle]: circle,
  })

  const imageUrl = getFileAbsoluteUrl(value, '')

  const wrapperStyle = pixels
    ? {
        height,
        width,
      }
    : undefined

  const handleCancel = () => {
    setPreviewVisible(false)
  }

  const handleClick = () => {
    setPreviewVisible(true)
  }

  const handleDelete = () => {
    setPreviewVisible(false)
    onChangeImages(images.filter((img) => img !== value))
  }
  return (
    <>
      <div className={classes.container}>
        {canDelete && (
          <div className={classes.delete} onClick={handleDelete}>
            <DeleteSVG />
          </div>
        )}
        <label className={wrapperClassName} style={wrapperStyle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${width} ${height}`}
          />

          {canUpload && (
            <input
              ref={ref}
              onClick={(event) => {
                event.target.value = null
              }}
              disabled={mutation.isLoading}
              onChange={handleChange}
              accept="image/jpeg, image/png"
              type="file"
            />
          )}
          <div className={classes.overlay}>
            {value ? (
              <>
                <div
                  className={classes.preview}
                  onClick={handleClick}
                  hidden={!value}
                  style={{
                    backgroundImage: `url(${imageUrl})`,
                  }}
                />
              </>
            ) : (
              <div className={classes.placeholder}>
                {images?.length > 0 ? (
                  <PlusSVG style={{ marginBottom: 0 }} />
                ) : (
                  <>
                    <UploadSVG />
                    <span>{placeholder}</span>
                  </>
                )}
              </div>
            )}
            {mutation.isLoading ? (
              <div className={classes.uploading}>
                <Spin />
              </div>
            ) : null}
            {!mutation.isLoading && value && canUpload ? (
              <div className={classnames(classes.placeholder, classes.hover)}>
                {images?.length > 0 ? (
                  <PlusSVG style={{ marginBottom: 0 }} />
                ) : (
                  <>
                    <UploadSVG />
                    <span>{placeholder}</span>
                  </>
                )}
              </div>
            ) : null}
          </div>
        </label>
      </div>
      <Modal
        title={`Preview`}
        visible={previewVisible}
        onCancel={handleCancel}
        footer={null}>
        <img className="mx-auto" src={value} alt="" />
      </Modal>
    </>
  )
}

ImageInput.defaultProps = {
  placeholder: 'Choose a Picture...',
  canUpload: false,
}

function uploadImage({ file }) {
  const params = new FormData()

  params.append('file', file)

  return {
    path: '/upload',
    params,
  }
}

const ImageInputComponent = forwardRef(ImageInput)
ImageInputComponent.defaultProps = {
  placeholder: 'Choose a Picture...',
  canUpload: false,
}
export default ImageInputComponent
