import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Input from 'shared/components/form/input'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import classes from './Filters.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <Input
        {...form.getInputPropsFor('search')}
        placeholder="Search Tags..."
      />
      <HtmlSelect {...form.getInputPropsFor('status')}>
        <option value="">Select Status...</option>
        <option value="published">Published</option>
        <option value="delisted">Delisted</option>
      </HtmlSelect>
      <HtmlSelect {...form.getInputPropsFor('sort')}>
        <option value="">Sort By...</option>
        <option value="most_favorited">Most Followers</option>
        <option value="most_video">Video mentions</option>
      </HtmlSelect>
      <Button outlined>Search Tags</Button>
    </form>
  )
}
