import { Link } from 'react-router-dom'
import Box from './Box'
import classes from './Boxes.module.css'

export default function Boxes() {
  const whyTC = (
    <p>
      Today’s Crypto has been built for you, the crypto believer! <br />
      Creating your channel on Today's Crypto is intuitive, secure and easily
      found by our community. <br />
      Youtube creator? No problem, as we can copy over your channel content!{' '}
      <br />
      We only allow the best as we review all publishers manually, ensuring no
      cloned channels, copied content, or scammers. <br />
      Join us and continue the creation of Today's Crypto, a secure space where
      all like-minded people can share their knowledge and experiences.
    </p>
  )

  const boostValue = (
    <p>
      Give your audience the best possible experience while watching your
      content. Easily add connected market data for the coins and tokens you
      cover in your streams. <br />
      When uploading your content, you can now select the coins or tokens most
      relevant to your content. Retime data for your chosen cryptos will then be
      visible to your audience as they view your content. <br />
      Today’s Crypto audience has access to full live market data. The audience
      can navigate to the chosen coin or token while continuing to watch your
      video. No more zapping between different pages when someone's interested
      in the coins or tokens you cover!
    </p>
  )

  const everythingYouNeed = (
    <p>
      Manage everything you need in one streamlined interface. <br />
      First, upload your media, such as videos or podcasts, and then add various
      elements to your media with our easy-to-use studio. <br />
      Your audience can then watch and interact with your channel through likes,
      dislikes, and comments while you collect all the necessary statistics to
      analyze.
      <br />
      Once you’ve reached 500 subscribers and 2000 watch hours, you will be able
      to monetize your content and start to earn USDC monthly
      <span className="text-primary">
        <Link to="/landings/monetize"> here</Link>
      </span>
      .
    </p>
  )

  const commentManager = (
    <p>
      We know how important it is to interact with your audience but also the
      time and energy it takes as your subscribers grow. <br />
      So, we have developed a comment manager that keeps you up the speed with
      your audience feedback. <br />
      Our easy-to-use interface has many intelligent features, such as filters
      and sorting options. Manage your pinned comments and mentions easily. Why
      not save comments you don't have time to answer for a later date. <br />
      Easy but so powerful!
    </p>
  )

  const dashboard = (
    <p>
      Want a killer overview of your statistics? <br />
      We’ve developed awesome dashboards with complete channel insight. <br />
      Dashboards are the first things you see every time you log into Today’s
      Crypto, giving you live data to optimize your channel and increase
      subscribers and interactions. <br />
      Your dashboard highlights all the necessary data to keep track of your
      channel: Earnings, Watch Hours, Views, Subscribers, Likes, and much more!
    </p>
  )

  return (
    <div class={classes.boxes}>
      <Box
        title="Why Today's Crypto?"
        section="PP/landing/sectionA"
        image={<img src="/why-tc.png" />}
        content={whyTC}
      />
      <Box
        title="Boost value with data"
        section="PP/landing/sectionB"
        image={<img src="/market-data.png" />}
        content={boostValue}
      />
      <Box
        title="Everything in one place"
        section="PP/landing/sectionC"
        image={<img src="/everything-you-need.png" />}
        content={everythingYouNeed}
      />
      <Box
        title="Comment manager PRO"
        section="PP/landing/sectionD"
        image={<img src="/comment-manager.png" />}
        content={commentManager}
      />
      <Box
        title="Beautiful dashboard"
        section="PP/landing/sectionE"
        image={<img src="/dashboard.png" />}
        content={dashboard}
      />
    </div>
  )
}
