import { useEffect, useMemo, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import formatDateOnly from 'helpers/utils/formatDateOnly'
import VideoThumbnail from 'shared/components/video-thumbnail'
import getMyVideos from 'publisher/queries/getMyVideos'
import Modal from 'shared/components/kit/modal'
import Table from 'shared/components/kit/table'
import VideoStatus from 'shared/components/video-status'
import Filters from './components/Filters'
import classes from './index.module.css'

type ChooseVideoModalProps = {
  cancelText?: string,
  excludePlaylist?: number,
  excludeVideos?: Array<number>,
  okText?: string,
  onCancel: Function,
  onChoose: Function,
  title?: string,
  visible: boolean,
}

export default function ChooseVideoModal(props: ChooseVideoModalProps) {
  const {
    cancelText,
    excludePlaylist,
    excludeVideos,
    okText,
    onCancel,
    onChoose,
    title,
    visible,
  } = props

  const [filters, setFilters] = useState()

  const [selectedRows, setSelectedRows] = useState([])

  const { data, error, isError, isFetching, refetch } = useQuery(getMyVideos, {
    variables: {
      ...filters,
      excludePlaylist,
      excludeVideos,
      includeCategoryRelation: true,
    },
  })

  useEffect(() => {
    setSelectedRows([])
  }, [isFetching])

  const videos = data?.json.data || []

  const columns = useMemo(() => getColumns(refetch), [refetch])

  const handleRowSelect = (keys) => {
    setSelectedRows(keys)
  }

  const handleCancel = () => {
    setSelectedRows([])
    onCancel()
  }

  const handleOk = () => {
    const selectedVideos = videos.filter(
      (video) => selectedRows.indexOf(video.id) > -1
    )
    setSelectedRows([])
    onChoose(selectedVideos)
  }

  return (
    <Modal
      okText={okText}
      okButtonProps={{ disabled: selectedRows.length === 0 }}
      cancelText={cancelText}
      onCancel={handleCancel}
      onOk={handleOk}
      title={title}
      visible={visible}>
      <Filters onSubmit={setFilters} />
      <Table
        columns={columns}
        data={videos}
        loading={isFetching}
        onRowSelect={handleRowSelect}
        selectedRows={selectedRows}
      />
    </Modal>
  )
}

ChooseVideoModal.defaultProps = {
  cancelText: 'Cancel',
  okText: 'Choose',
  title: 'Search Media',
  visible: false,
}

function getColumns(refetch) {
  return [
    {
      className: classes.thumbnail,
      title: 'Thumbnail',
      render: (video) => <VideoThumbnail video={video} />,
    },
    {
      className: classes.title,
      title: 'Title',
      render: (video) => (
        <>
          {video.title ? (
            video.title
          ) : (
            <span className={classes.untitled}>Untitled</span>
          )}
        </>
      ),
    },
    {
      title: 'Category',
      className: classes.category,
      render: (video) => video.category?.name,
    },
    {
      title: 'Status',
      className: classes.status,

      render: (video) => <VideoStatus status={video.status} />,
    },
    {
      className: classes.publishDate,
      title: 'Publish Date',
      render: (video) =>
        video.published_at && formatDateOnly(video.published_at),
    },
    {
      className: 'w-8',
      render: () => null,
    },
  ]
}
