type Variables = {
  name: string,
  status: string,
}

export default function CreateTag(variables: Variables = {}) {
  const { name, status } = variables
  return {
    name: 'createTag',
    path: '/admin/tags',
    params: {
      name,
      status,
    },
  }
}
