import { useQuery } from 'react-query'
import getUserClaimableTokensRaw from 'shared/queries/getUserClaimableTokens.raw'

export default function useUserClaimableTokens(address) {
  const queryKey = ['claimable-tokens', address]
  const { data, ...rest } = useQuery(queryKey, getUserClaimableTokensRaw)
  return {
    ...rest,
    ...data,
  }
}
