import { useState } from 'react'
import useMutation from 'core/react-query/useMutation'
import useMiniForm from 'core/useMiniForm'
import notification from 'shared/components/kit/notification'
import Field from 'shared/components/form/field'
import PasswordInput from 'shared/components/form/password'
import Button from 'shared/components/kit/button'
import EmailVerificationModal from 'shared/components/email-verification-modal'

const validation = {
  new_password: {
    label: 'New Password',
    rule: (value, values, rules) => {
      if (rules.required('New Password', value)) {
        return rules.required('New Password', value)
      }
      if (!value && values.current_password) {
        return 'The new password field is required when current password is present.'
      }
      if (value && value.length < 6) {
        return 'The new password must be at least 6 characters.'
      }
    },
  },
  current_password: {
    label: 'Current Password',
    rule: (value, values, rules) => {
      if (rules.required('Current Password', value)) {
        return rules.required('Current Password', value)
      }
      if (!value && values.new_password) {
        return 'The current password field is required when new password is present.'
      }
      if (value && value.length < 6) {
        return 'The current password must be at least 6 characters.'
      }
    },
  },
}

export default function Password() {
  const [showEmailVerification, setShowEmailVerification] = useState(false)

  const update = useMutation(updatePasswordMutation)

  const form = useMiniForm({
    validation,
    async onSubmit(values, event) {
      if (event) {
        event.preventDefault()
      }
      try {
        await update.mutateAsync(values)
        notification.success({ title: 'Password updated successfully!' })
      } catch (error) {
        if (error?.json?.code === 'email_verification.require') {
          setShowEmailVerification(true)
        } else {
          throw error.json
        }
      }
    },
  })

  const handleCancel = () => {
    setShowEmailVerification(false)
  }

  const handleVerified = () => {
    setShowEmailVerification(false)
    form.submit()
  }

  return (
    <>
      <form onSubmit={form.submit}>
        <p className="mb-4 text-neutral text-sm">
          To change your password, fill in the fields below and click on update
          password.
        </p>
        <Field
          label="Current Password"
          {...form.getErrorPropsFor('current_password')}>
          <PasswordInput
            autocomplete="new-password"
            {...form.getInputPropsFor('current_password')}
            label="Current password"
            placeholder="Current password"
          />
        </Field>
        <Field label="New Password" {...form.getErrorPropsFor('new_password')}>
          <PasswordInput
            {...form.getInputPropsFor('new_password')}
            label="New password"
            name="password"
            placeholder="New password"
          />
        </Field>
        <Button loading={form.submitting} type="submit">
          Update Password
        </Button>
      </form>

      <EmailVerificationModal
        onCancel={handleCancel}
        onVerified={handleVerified}
        visible={showEmailVerification}
      />
    </>
  )
}

function updatePasswordMutation(variables) {
  return {
    path: '/profile/password',
    params: {
      ...variables,
    },
  }
}
