import { useState } from 'react'
import { useHistory } from 'react-router'
import get from 'lodash/get'
import useQueryClient from 'core/react-query/useQueryClient'
import useQuery from 'core/react-query/useQuery'
import useMutation from 'core/useMutation'
import useMiniForm from 'core/useMiniForm'
import notification from 'shared/components/kit/notification'
import formatDate from 'helpers/utils/formatDate'
import RichText from 'shared/components/form/rich-text'
import Button from 'shared/components/kit/button'
import Field from 'shared/components/form/field'
import getUserAvatar from 'helpers/utils/getUserAvatar'
import getUserName from 'helpers/utils/getUserName'
import getMessage from 'admin/queries/getMessage'
import getMessages from 'admin/queries/getMessages'
import sendReplyToMessage from 'admin/queries/sendReplyToMessage'
import Drawer from 'shared/components/kit/drawer'
import MessageStatus from 'admin/components/message-status'
import ArchiveMessageButton from './ArchiveMessageButton'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import ImageInput from 'shared/components/form/image'
import seenMessage from 'admin/queries/seenMessage'
import classes from './Message.module.css'

export default function Message({ messageId }) {
  const queryClient = useQueryClient()
  const history = useHistory()

  const { data, isLoading, error, isError } = useQuery(getMessage, {
    enabled: typeof messageId !== 'undefined',
    variables: {
      id: messageId,
    },
    onSuccess: (res) => {
      if (
        res.json.status === 'new_by_user' ||
        res.json.status === 'replied_by_user'
      ) {
        seenMutation.mutateAsync({
          id: res.json.id,
        })
      }
    },
  })

  const seenMutation = useMutation(seenMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getMessage,
        variables: { id: messageId },
      })
      queryClient.invalidateQueries({ query: getMessages })
    },
  })

  const handleCloseDrawer = () => {
    history.push('/admin/messages')
  }

  const messageData = get(data, 'json')
  const replies = get(messageData, 'replies', [])

  return (
    <Drawer
      error={error?.json}
      isloading={isLoading}
      isError={isError}
      onClose={handleCloseDrawer}
      visible={!!messageId}>
      {messageData ? (
        <>
          <div className="flex">
            <h2>
              {messageData.subject}
              <MessageStatus className="ml-2" status={messageData.status} />
              {messageData.status !== 'close' ? (
                <ArchiveMessageButton messageId={messageId} />
              ) : null}
            </h2>
          </div>
          <h4 className="text-overlayColor-mutedText">
            Department: {messageData?.department.name}
          </h4>
          <br />
          <ul className={classes.messages}>
            <li>
              <MessageItem message={messageData} />
            </li>
            <li>
              <SendReplyForm messageId={messageId} />
            </li>
            {replies.map((reply) => (
              <li key={reply.id}>
                <MessageItem message={reply} />
              </li>
            ))}
          </ul>
        </>
      ) : !isError ? (
        <div className={classes.loading}>
          <Icon>
            <Spin />
          </Icon>
          <div>Loading...</div>
        </div>
      ) : null}
    </Drawer>
  )
}

function MessageItem({ message }) {
  if (typeof message === 'undefined') {
    return false
  }

  return (
    <>
      <header>
        <img src={getUserAvatar(message.from?.avatar)} />
        <span>{getUserName(message.from ?? {})}</span>
        <span>{formatDate(message.created_at)}</span>
      </header>
      <div
        dangerouslySetInnerHTML={{
          __html: message.message.replaceAll('\\n', '<br/>'),
        }}
      />
      {message?.files && (
        <div className="mt-4">
          <p className="opacity-75">Attached images</p>
          <ul className={classes.files}>
            {message?.files.map((file) => {
              return (
                <ImageInput
                  value={file}
                  canUpload={false}
                  pixels={true}
                  height={100}
                  width={100}
                />
              )
            })}
          </ul>
        </div>
      )}
    </>
  )
}

function SendReplyForm({ messageId }) {
  const [message, setMessage] = useState()
  const queryClient = useQueryClient()

  const mutation = useMutation(sendReplyToMessage, {
    onError: (error) => {
      notification.error({
        title: error.message,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getMessage,
        variables: {
          id: messageId,
        },
      })
      queryClient.invalidateQueries({ query: getMessages })
      setMessage('')
      notification.success({
        title: 'Reply sent to the message successfully!',
      })
    },
  })

  const form = useMiniForm({
    async onSubmit(values, event) {
      event.preventDefault()
      try {
        await mutation.mutateAsync({
          id: messageId,
          message,
        })
      } catch (error) {
        throw error
      }
    },
  })

  return (
    <form onSubmit={form.submit}>
      <Field>
        <RichText
          onChange={setMessage}
          placeholder="Write a reply..."
          value={message}
        />
      </Field>
      <Button type="submit">Reply</Button>
    </form>
  )
}
