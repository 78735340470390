import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import classnames from 'classnames'
import Box from 'shared/components/kit/box'
import Spin from 'shared/components/kit/spin'
import Icon from 'shared/components/kit/icon'
import Page from 'admin/components/page'
import getDashboardStatistics from 'admin/queries/getDashboardStatistics'
import VideoUploads from 'admin/pages/dashboard/components/Uploads'
import ChannelSubscribers from 'admin/pages/dashboard/components/ChannelSubscribers'
import ChannelStatistics from 'admin/pages/dashboard/components/ChannelStatistics'
import getChannelStatisticsOverview from 'admin/queries/getChannelStatisticsOverview'
import ChannelPoints from './components/ChannelPoints'
import Earnings from './components/Earnings'
import PayoutEarnings from './components/PayoutEarnings'
import classes from './index.module.css'

export default function AdminDashboard() {
  const { data, isLoading, error, isError } = useQuery(getDashboardStatistics)
  const statistics = get(data, 'json', [])

  const {
    data: totalData,
    isLoading: totalIsLoading,
    error: totalError,
    isError: totalIsError,
  } = useQuery(getChannelStatisticsOverview)
  const totalStatistics = get(totalData, 'json', [])

  if (isError) {
    return <Page error={error.message} />
  }

  if (totalIsError) {
    return <Page error={totalError.message} />
  }

  if (isLoading || totalIsLoading) {
    return (
      <div className={classes.loading}>
        <Icon>
          <Spin />
        </Icon>
        <div>Loading...</div>
      </div>
    )
  }

  return (
    <Page title="Dashboard">
      <Box className={classnames(classes.summaryBox)}>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Total users</h4>
          <strong>{statistics.total_registered_users}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>This month users</h4>
          <strong>{statistics.new_registered_users_this_month}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Total expired members</h4>
          <strong>{statistics.total_expired_members}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>This month expired members</h4>
          <strong>{statistics.expired_members_this_month}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Total hodl members</h4>
          <strong>{statistics.total_hero_members}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>This month hodl members</h4>
          <strong>{statistics.new_hero_members_this_month}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Total subscribers</h4>
          <strong>{Math.floor(totalStatistics.subscribers_total)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Hodl subscribers</h4>
          <strong>{Math.floor(totalStatistics.subscribers_hero)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Non hodl subscribers</h4>
          <strong>{Math.floor(totalStatistics.subscribers_non_hero)}</strong>
        </div>

        <div className={classnames(classes.summaryDiv)}>
          <h4>Total unSubscribers</h4>
          <strong>{Math.floor(totalStatistics.unsubscribers_total)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Hodl unSubscribers</h4>
          <strong>{Math.floor(totalStatistics.unsubscribers_hero)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Non hodl unSubscribers</h4>
          <strong>{Math.floor(totalStatistics.unsubscribers_non_hero)}</strong>
        </div>

        <div className={classnames(classes.summaryDiv)}>
          <h4>Total channels</h4>
          <strong>{statistics.total_channels}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>This month channels</h4>
          <strong>{statistics.new_channels_this_month}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Total videos</h4>
          <strong>{statistics.total_videos}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Total videos by min</h4>
          <strong>{statistics.videos_hours_minutes}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>This month videos</h4>
          <strong>{statistics.new_videos_this_month}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Total Videos by MB</h4>
          <strong>{statistics.videos_sizes_MB}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Total reported comments</h4>
          <strong>{statistics.reported_comments}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Total reported videos</h4>
          <strong>{statistics.reported_videos}</strong>
        </div>

        <div className={classnames(classes.summaryDiv)}>
          <h4>Total channel points</h4>
          <strong>{Math.floor(totalStatistics.points)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Total views</h4>
          <strong>{Math.floor(totalStatistics.views_total)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Hodl views</h4>
          <strong>{Math.floor(totalStatistics.views_hero)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Non hodl views</h4>
          <strong>{Math.floor(totalStatistics.views_non_hero)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Total likes</h4>
          <strong>{Math.floor(totalStatistics.likes_total)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Hodl likes</h4>
          <strong>{Math.floor(totalStatistics.likes_hero)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Non hodl likes</h4>
          <strong>{Math.floor(totalStatistics.likes_non_hero)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Total dislikes</h4>
          <strong>{Math.floor(totalStatistics.dislikes_total)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Hodl dislikes</h4>
          <strong>{Math.floor(totalStatistics.dislikes_hero)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Non hodl dislikes</h4>
          <strong>{Math.floor(totalStatistics.dislikes_non_hero)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Total comments</h4>
          <strong>{Math.floor(totalStatistics.comments_total)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Hodl comments</h4>
          <strong>{Math.floor(totalStatistics.comments_hero)}</strong>
        </div>
        <div className={classnames(classes.summaryDiv)}>
          <h4>Non hodl comments</h4>
          <strong>{Math.floor(totalStatistics.comments_non_hero)}</strong>
        </div>
      </Box>
      <div className={classes.charts}>
        <ChannelSubscribers />
        <ChannelStatistics />
        <Earnings />
        <PayoutEarnings />
        <ChannelPoints />
        <VideoUploads />
      </div>
    </Page>
  )
}
