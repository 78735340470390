type UnarchivedPaymentDetailVariables = {
  ids: Array<string>,
}

export default function unArchivePaymentDetail(
  variables: UnarchivedPaymentDetailVariables = {}
) {
  const { ids } = variables
  return {
    method: 'POST',
    path: '/admin/payment-details/mark-as-non-archive',
    params: {
      ids,
    },
  }
}
