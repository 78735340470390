import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import classes from './Filters.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <HtmlSelect
        {...form.getInputPropsFor('status')}
        className={classes.status}>
        <option value="">All</option>
        <option value="paid">Paid</option>
        <option value="pending">Pending</option>
        <option value="N/A">N/A</option>
      </HtmlSelect>
      <Button outlined>Search Earnings</Button>
    </form>
  )
}
