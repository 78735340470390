import { forwardRef } from 'react'
import classnames from 'classnames'
import Spin from 'shared/components/kit/spin'
import Icon from 'shared/components/kit/icon'
import classes from './index.module.css'

function Box(props, ref) {
  const { children, className, fetching, loading, ...rest } = props

  return (
    <div
      className={classnames('box', className, classes.box)}
      {...rest}
      ref={ref}>
      {loading ? (
        <div className={classes.loading}>
          <Icon>
            <Spin />
          </Icon>
          <div>Loading...</div>
        </div>
      ) : (
        children
      )}
    </div>
  )
}

export default forwardRef(Box)
