import { useState } from 'react'
import get from 'lodash/get'
import { forwardRef } from 'react'
import useQuery from 'core/react-query/useQuery'
import Select from 'shared/components/form/select'
import useDebounceCallback from 'helpers/hooks/useDebounceCallback'
import getPaymentMethods from 'admin/queries/getPaymentMethods'

function SelectPaymentMethod(props, ref) {
  const [search, setSearch] = useState('')

  const { data, isLoading } = useQuery(getPaymentMethods, {
    variables: {
      search,
    },
  })

  const options = get(data, 'json', []).map((opt) => ({
    label: opt.name,
    value: opt.id,
  }))

  const handleInputChange = useDebounceCallback((text) => {
    setSearch(text)
  })

  return (
    <Select
      ref={ref}
      {...props}
      isLoading={isLoading}
      onInputChange={handleInputChange}
      options={options}
    />
  )
}

export default forwardRef(SelectPaymentMethod)
