type LikeCommentMutationVariables = {
  id: number,
}

export default function likeComment(variables: LikeCommentMutationVariables) {
  return {
    method: 'put',
    path: `/comments/${variables.id}/like`,
  }
}
