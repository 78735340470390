import { useHistory } from 'react-router'
import useQuery from 'core/react-query/useQuery'
import { Link } from 'react-router-dom'
import { useMemo, useState } from 'react'
import Drawer from 'shared/components/kit/drawer'
import get from 'lodash/get'
import Table from 'shared/components/kit/table'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getVideoReports from 'admin/queries/getVideoReports'
import getVideo from 'admin/queries/getVideo'
import UserAvatar from 'shared/components/user-avatar'
import getUserName from 'helpers/utils/getUserName'
import formatDate from 'helpers/utils/formatDate'
import HideVideoButton from './HideVideoButton'
import Filters from './Filter'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import classes from './VideoDetails.module.css'

const INITIAL_FILTERS = {
  page: 1,
  reason: '',
}

export default function VideoDetails({ videoId }) {
  const history = useHistory()
  const [filters, setFilters] = useState(INITIAL_FILTERS)
  const {
    data: videoData,
    error: videoError,
    isError: videoIsError,
    isFetching: videoIsFetching,
    isLoading: videoIsLoading,
  } = useQuery(getVideo, {
    enabled: typeof videoId !== 'undefined',
    keepPreviousData: true,
    variables: {
      id: videoId,
    },
  })

  const video = get(videoData, 'json')

  const { data, error, isError, isFetching } = useQuery(getVideoReports, {
    keepPreviousData: true,
    variables: {
      page: filters.page,
      reason: filters.reason,
      id: videoId,
    },
  })

  const {
    data: reports,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleCloseDrawer = () => {
    history.push('/admin/reports/videos')
  }

  return (
    <Drawer
      error={videoError?.json}
      isError={videoIsError}
      isloading={videoIsLoading}
      onClose={handleCloseDrawer}
      visible={!!videoId}>
      {video ? (
        <div className={classes.wrapper}>
          <h3>{video.title}</h3>
          <ul>
            <li>Publisher: {getUserName(video.user)}</li>
            <li>Creation Date: {formatDate(video.user?.created_at)}</li>
          </ul>
          <div>
            <p>
              This video is reported {total} times. If you need to take action,
              you can hide this video using the "Hide" button.
            </p>
            <HideVideoButton id={video.id} />
          </div>
        </div>
      ) : null}
      {videoData ? (
        <>
          <h5 className={classes.total}>{total} reports for this video</h5>
          <Filters
            videoId={videoId}
            initialFilters={INITIAL_FILTERS}
            onSubmit={setFilters}
          />
          <Table
            bordered={false}
            columns={columns}
            data={reports}
            loading={isFetching}
            currentPage={currentPage}
            lastPage={lastPage}
            onPaginate={handlePaginate}
          />
        </>
      ) : (
        <div className={classes.loading}>
          <Icon>
            <Spin />
          </Icon>
          <div>Loading...</div>
        </div>
      )}
    </Drawer>
  )
}

function getColumns() {
  return [
    {
      className: classes.avatar,
      render: (report) => <UserAvatar user={report.user} />,
    },
    {
      title: 'Username',
      render: (report) => (
        <Link to={`/admin/users?id=${report.user.id}`}>
          {getUserName(report.user)}
        </Link>
      ),
    },
    {
      title: 'Reason',
      render: (report) => report.reason_text,
    },
    {
      align: 'center',
      title: 'Create Date',
      render: (report) => formatDate(report.created_at),
    },
  ]
}
