import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import env from 'core/env'
import useMutation from 'core/react-query/useMutation'
import Modal from 'shared/components/kit/modal'
import Logo from 'shared/components/logo'
import Button from 'shared/components/kit/button'
import Input from 'shared/components/form/input'
import notification from 'shared/components/kit/notification'
import useForceColorScheme from 'shared/hooks/useForceColorScheme'
import Footer from 'shared/components/footer'
import Icon from 'shared/components/kit/icon'
import feedback from 'publisher/mutations/feedback'
import NotifyModal from 'publisher/pages/login/components/NotifyModal'
import { ReactComponent as ThumbUpFilledSVG } from 'svg/like-filled.svg'
import { ReactComponent as ThumbDownFilledSVG } from 'svg/dislike-filled.svg'
import { ReactComponent as CloseSVG } from 'icons/close.svg'
import { ReactComponent as MenuSVG } from 'icons/menu.svg'
import classes from './index.module.css'

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}

export default function Monetize() {
  useForceColorScheme('dark')
  const [visibleNotify, setVisibleNotify] = useState(false)
  const [visibleDislike, setVisibleDislike] = useState()
  const [visibleLike, setVisibleLike] = useState()
  const [textDislike, setTextDislike] = useState()
  const [textLike, setTextLike] = useState()
  const [exampleModalVisible, setExampleModalVisible] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [windowSize, setWindowSize] = useState(getWindowSize())
  const textDislikeRef = useRef()
  const textLikeRef = useRef()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [location])

  useEffect(() => {
    textDislikeRef.current.focus()
  }, [visibleDislike])

  useEffect(() => {
    textLikeRef.current.focus()
  }, [visibleLike])

  const handleShowExampleModal = () => {
    setExampleModalVisible(true)
  }

  const handleCloseExampleModal = () => {
    setExampleModalVisible(false)
  }

  const [sentFeedbackSections, setSentFeedbackSections] = useState({
    'PP/landing/monetize': '',
  })

  const triggerMenu = () => {
    setIsMenuOpen((prev) => !prev)
  }

  const feedbackMutation = useMutation(feedback, {
    onSuccess: () => {
      notification.success({
        title: 'We received your feedback. Thank you.',
      })
    },
  })

  const handleUpdateSentFeedbacks = (value) => {
    setSentFeedbackSections(value)
  }

  const handleFeedback = async (type, location, value, text) => {
    try {
      await feedbackMutation.mutateAsync({
        type,
        location,
        value,
        text,
      })
    } catch (error) {
      notification.error({ title: 'Something went wrong in sent feedback!' })
    }
  }

  const handleThumbUp = async (textLike) => {
    await handleFeedback('thumb', 'PP/landing/monetize', 1, textLike)
    handleUpdateSentFeedbacks({
      ...sentFeedbackSections,
      'PP/landing/monetize': 'like',
    })
    setVisibleLike(false)
    setTextLike('')
  }

  const handleThumbDown = async (text) => {
    await handleFeedback('thumb', 'PP/landing/monetize', -1, text)
    handleUpdateSentFeedbacks({
      ...sentFeedbackSections,
      'PP/landing/monetize': 'dislike',
    })
    setVisibleDislike(false)
    setTextDislike('')
  }

  const handleCancel = async () => {
    setTextDislike('')
    setVisibleDislike(false)
    await handleThumbDown('')
  }

  const handleCancelLike = async () => {
    setVisibleLike(false)
    setTextLike('')
    await handleThumbUp('')
  }

  const handleChangeDislike = (value) => {
    setTextDislike(value)
  }

  const handleShowModalDislike = () => {
    setVisibleDislike(true)
  }

  const handleChangeLike = (value) => {
    setTextLike(value)
  }

  const handleShowModalLike = () => {
    setVisibleLike(true)
  }

  const handleBecomePublisher = () => {
    if (env('env') === 'beta') {
      history.push('?form=register')
    } else {
      setVisibleNotify(true)
    }
  }

  const handleUserSignUp = () => {
    if (env('env') === 'beta') {
      window.location.href = env('END_USER_LANDING_URL')
    } else {
      setVisibleNotify(true)
    }
  }

  const handlePublisherLogin = () => {
    if (env('env') === 'beta') {
      history.push('/')
    } else {
      setVisibleNotify(true)
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.nav}>
        <ul>
          <li className={classes.logo}>
            <Link to="/">
              <div>
                <Logo forceDarkMode={true} />
              </div>
            </Link>
          </li>
        </ul>
        {windowSize.innerWidth > 768 && (
          <div className={classes.menuWrapper}>
            <Button size="sm" secondary onClick={handleUserSignUp}>
              User Signup
            </Button>
            <Button secondary size="sm" onClick={handlePublisherLogin}>
              Publisher Login/Signup
            </Button>
          </div>
        )}
        {isMenuOpen && windowSize.innerWidth < 768 && (
          <ul className={classes.menuWrapper}>
            <li onClick={handleUserSignUp}>User Signup</li>
            <li onClick={handlePublisherLogin}>Publisher Login/Signup</li>
          </ul>
        )}
        <div className={classes.menu}>
          <a onClick={triggerMenu}>
            <Icon>{isMenuOpen === true ? <CloseSVG /> : <MenuSVG />}</Icon>
          </a>
        </div>
      </div>
      <section>
        <h1>Monetize on Today's Crypto.</h1>
        <h2>Our approach</h2>
        <p>
          We believe in fairness and pride ourselves in having the fairest
          distribution model for streamed content on the market. We do not
          decide or limit what content can be monetized but instead let the user
          interactions and data speak for themselves.
        </p>

        <h2>More to our Publishers</h2>

        <p>
          Our aim is to become the best paying streaming platform within the
          industry by distributing 30 percent of the platform's revenue back to
          our publishers through the Monetize program. This includes Hodl
          Membership fees, Advertisement revenues, and more. The remaining part
          of the revenue goes to cover operational costs, continued development,
          and maintenance of the service.
        </p>

        <h2>So how does it work?</h2>

        <p>
          It’s all about the points! At the start of each month, all channels
          qualified for the Monetize program will race to earn as many points as
          possible to secure their piece of the monthly distribution. Whoever
          manages to earn the most points by the end of the month will enjoy the
          biggest piece of the pie. There are currently three areas in which
          your channel will be able to earn points:
          <br />
          <ul>
            <li>
              1.Subscribers <br />
              Focusing on attracting subscribers to your channel is probably the
              best thing you can do to secure a steady point earning each month.
              For each subscriber your channel has at the start of the month,
              you will get 1 point, every month. You heard right, so take care
              of your subscribers and make sure to highlight the importance of
              subscribing to support the channel and not miss out on any new
              content.
            </li>
            <li>
              2.Views <br />
              Your creations are made to be viewed and making content that is
              widely appreciated and sparks an interest in your audience and
              preferably the masses (nobody said it was easy), is a good way of
              securing a good chunk of points. For every ten video views, you
              will get 1 point.
            </li>
            <li>
              3.Likes <br />A like says more than 500 views... We can all agree
              that getting a lot of views is great, but it’s not always enough
              to know what your audience really thinks, that is why we believe
              that likes are an extra powerful tool to measure the engagement on
              your content. That is why you will be awarded 50 points for each
              like on any of your content.
            </li>
          </ul>
          <br />
          The number of points your channel manages to earn during the month
          will determine your share of the money up for distribution (the
          “pie”), which in turn is determined based on the revenues of the
          platform the month before.
        </p>
        <Button
          className={classes.exampleModalButton}
          secondary
          onClick={handleShowExampleModal}>
          Show me an example
        </Button>
        <h2>Fancy some extra points?</h2>
        <p>
          Did you know that a Like from a Hodl Member is worth 150 points, or
          three times the normal points? Or that you will be awarded double the
          points for having a Hodl Member subscribing to your channel or
          watching your content? Well, you do know!
        </p>
        <h2>Payout</h2>
        <p>
          Payouts are made approximately 10 working days after the closing of
          the month and your earnings will be sent directly to your registered
          wallet in USDC. Please note that you must verify your identity and
          residential address before any payouts can be made.
        </p>
        <p>
          You will be able to export payment documentation in the Publisher
          panel for accounting and possible taxation purposes.
        </p>
        <br />
        <h2>When can I start to Monetize my content?</h2>
        <p>
          There are two minimum requirements that your channel needs to meet
          before qualifying for the Monetize program:
          <ul className={classes.dashed}>
            <li>You must have a minimum of 500 subscribers.</li>
            <li>Your content must have a minimum of 2000 watch hours.</li>
          </ul>
        </p>
        <p>
          Once the requirements have been met, then your channel will
          automatically qualify for the Monetize program and be ready to start
          earning points.
        </p>
        <h2>Did you find it easy to understand how the monetization works?</h2>
        <div className={classes.actions}>
          <div>
            <button
              className={
                sentFeedbackSections['PP/landing/monetize'] === 'like' &&
                classes.selected
              }
              onClick={handleShowModalLike}>
              <ThumbUpFilledSVG />
            </button>
            <button
              className={
                sentFeedbackSections['PP/landing/monetize'] === 'dislike' &&
                classes.selected
              }
              onClick={handleShowModalDislike}>
              <ThumbDownFilledSVG />
            </button>
          </div>
          <Button onClick={handleBecomePublisher} secondary>
            Become a Publisher
          </Button>
        </div>
        <Modal
          forceDarkMode={true}
          title="Thanks for your valuable feedback!"
          visible={visibleDislike}
          onCancel={handleCancel}
          footer={
            <Button onClick={() => handleThumbDown(textDislike)}>Submit</Button>
          }
          size="md">
          <Input
            placeholder="Tell us a little bit more about what was unclear to you? Our team will review the information based on your feedback. (Optional)"
            ref={textDislikeRef}
            value={textDislike}
            onChange={handleChangeDislike}
            multiline
          />
        </Modal>
        <Modal
          forceDarkMode={true}
          title="Thanks for your valuable feedback!"
          visible={visibleLike}
          onCancel={handleCancelLike}
          footer={
            <Button onClick={() => handleThumbUp(textLike)}>Submit</Button>
          }
          size="md">
          <Input
            placeholder="Give us some feedback, and we will do everything to make things even better. (Optional)"
            ref={textLikeRef}
            value={textLike}
            onChange={handleChangeLike}
            multiline
          />
        </Modal>
        <Modal
          modalClassName={classes.modalWrapper}
          footer={
            <Button
              transparent
              mood="neutral"
              onClick={handleCloseExampleModal}>
              Close
            </Button>
          }
          forceDarkMode={true}
          title="Point value and distribution example"
          visible={exampleModalVisible}
          onCancel={handleCloseExampleModal}>
          <p>
            For this example let’s say Today’s Crypto had revenues of 10,000 USD
            during the month May, that would mean that 3,000 USDC (30 Percent)
            would be up for distribution for the month of June.
          </p>
          <p>
            And during June there was three channels that were qualified to
            Monetize, this is how they performed:
            <ul>
              <li>Channel A manages to earn 10,000 points.</li>
              <li>Channel B manages to earn 7,000 points.</li>
              <li>Channel C manages to earn 3,000 points.</li>
            </ul>
          </p>
          <p>
            So when we put together the earned points of all qualified channels
            we could conclude that the total points earnings in the month of
            June was 20,000.
          </p>
          <p>
            We then divided the amount up for distribution, which was 3,000
            USDC, with the total number of points, which would give us a point
            value of 0,15 USDC for the month of June:
          </p>
          <p>3,000 / 20,000 = 0,15 USDC per point</p>
          <p>
            Based on the point value, the payout amount for each of the
            participating channels could be calculated:
          </p>
          <p>
            Points earned * point value = Payout
            <ul>
              <li>Channel A would be paid 1,500 USDC.</li>
              <li>Channel B would be paid 1,050 USDC.</li>
              <li>Channel C would be paid 450 USDC.</li>
            </ul>
          </p>
          <p>The Payouts for June would then be executed by mid July.</p>
        </Modal>
      </section>
      <div className={classes.footerContainer}>
        <Footer />
      </div>
      <NotifyModal
        visibleNotify={visibleNotify}
        onSetVisibleNotify={setVisibleNotify}
      />
    </div>
  )
}
