import { useEffect } from 'react'
import { useParams } from 'react-router'
import useMiniForm from 'core/useMiniForm'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Checkbox from 'shared/components/form/checkbox'
import Button from 'shared/components/kit/button'
import classes from './PlanForm.module.css'

const validation = {
  name: {
    label: ' Name',
    rule: 'required',
  },
  description: {
    label: 'Description',
    rule: 'required',
  },
  interval: {
    label: 'Interval',
    rule: 'required',
  },
  stripeAmount: {
    label: 'Stripe Amount',
    rule: (value, values, validators) => {
      if (values.enableStripeAmount && !value) {
        return validators.required('Stripe amount', value)
      }
    },
  },
  coinbaseAmount: {
    label: 'Coinbase Amount',
    rule: (value, values, validators) => {
      if (values.enableCoinbaseAmount && !value) {
        return validators.required('Coinbase amount', value)
      }
    },
  },
}

export default function PlanForm({ initialValues, onSubmit, submitText }) {
  const params = useParams()

  useEffect(() => {
    if (!params.planId) {
      form.reset()
    }
  }, [params.planId])

  const form = useMiniForm({
    initialValues,
    validation,
    async onSubmit(values, event) {
      event.preventDefault()
      onSubmit(values)
      form.reset()
    },
  })

  return (
    <form onSubmit={form.submit}>
      <Field label="Name" {...form.getErrorPropsFor('name')}>
        <Input {...form.getInputPropsFor('name')} />
      </Field>
      <Field label="Description" {...form.getErrorPropsFor('description')}>
        <Input {...form.getInputPropsFor('description')} />
      </Field>
      <Field label="Interval" {...form.getErrorPropsFor('interval')}>
        <Input type="number" {...form.getInputPropsFor('interval')} />
      </Field>
      <Field>
        <Checkbox
          description="Do you want this plan to be active?"
          checked={form.get('status')}
          onChange={(checked) => {
            if (checked) {
              form.change('status', true)
            } else {
              form.change('status', false)
            }
          }}
        />
      </Field>
      <Field>
        <Checkbox
          description="Do you want this plan to be displayed as popular?"
          checked={form.get('isPopular')}
          onChange={(checked) => {
            if (checked) {
              form.change('isPopular', true)
            } else {
              form.change('isPopular', false)
            }
          }}
        />
      </Field>
      <div className={classes.row}>
        <Field>
          <Checkbox
            description="Enable Stripe Payment"
            checked={form.get('enableStripeAmount')}
            onChange={(checked) => {
              if (checked) {
                form.change('enableStripeAmount', true)
              } else {
                form.change('enableStripeAmount', false)
                form.change('stripeAmount', undefined)
              }
            }}
          />
        </Field>
        <Field {...form.getErrorPropsFor('stripeAmount')}>
          <Input
            disabled={!form.get('enableStripeAmount')}
            {...form.getInputPropsFor('stripeAmount')}
            type="number"
            placeholder="Stripe amount..."
          />
        </Field>
      </div>
      <div className={classes.row}>
        <Field>
          <Checkbox
            description="Enable Coinbase Payment"
            checked={form.get('enableCoinbaseAmount')}
            onChange={(checked) => {
              if (checked) {
                form.change('enableCoinbaseAmount', true)
              } else {
                form.change('enableCoinbaseAmount', false)
                form.change('coinbaseAmount', undefined)
              }
            }}
          />
        </Field>
        <Field {...form.getErrorPropsFor('coinbaseAmount')}>
          <Input
            disabled={!form.get('enableCoinbaseAmount')}
            {...form.getInputPropsFor('coinbaseAmount')}
            type="number"
            placeholder="Coinbase amount..."
          />
        </Field>
      </div>
      <Button type="submit"> {submitText}</Button>
    </form>
  )
}
