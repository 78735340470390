// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChannelPaymentDetailStatus_statusIndicator__19vvf {\n  border-radius: 9999px;\n  display: inline-block;\n  height: 1rem;\n  margin-left: 0.5rem;\n  margin-right: 0.5rem;\n  width: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/monetization/qualified/components/ChannelPaymentDetailStatus.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,qBAAmB;EAAnB,qBAAmB;EAAnB,YAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,WAAmB;ADAnB","sourcesContent":[".statusIndicator {\n  @apply inline-block w-4 h-4 rounded-full mx-2;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusIndicator": "ChannelPaymentDetailStatus_statusIndicator__19vvf"
};
export default ___CSS_LOADER_EXPORT___;
