type RememberCommentMutationVariables = {
  id: number,
}

export default function rememberComment(
  variables: RememberCommentMutationVariables
) {
  return {
    method: 'PUT',
    path: `/publisher/comments/${variables.id}/remember`,
  }
}
