// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DeleteCommentButton_deleteButton__3yxkJ {\n  margin-top: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/reports/components/comments/DeleteCommentButton.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,kBAAmB;ADAnB","sourcesContent":[".deleteButton {\n  @apply mt-6;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteButton": "DeleteCommentButton_deleteButton__3yxkJ"
};
export default ___CSS_LOADER_EXPORT___;
