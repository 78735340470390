import { createElement, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import cookie from 'core/cookie'
import useFetcher from 'core/useFetcher'
import useProfile from 'shared/hooks/useProfile'
import Icon from 'shared/components/kit/icon'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import TopNavigation from 'admin/components/top-navigation'
import classes from './Base.module.css'

export default function AdminBaseLayout({ main, sidebar }) {
  const { user } = useProfile()
  const fetcher = useFetcher()
  const [showLogout, setShowLogout] = useState(false)

  const handleLogout = () => {
    fetcher(`/logout`, {
      method: 'get',
    })
      .then((res) => {
        notification.success({ title: res.json.message })
        cookie().remove('jwt')
        window.location.href = '/admin/login'
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <>
      <div className={classes.wrapper}>
        <TopNavigation />
        <div className={classes.sidebar}>
          <Link to="/admin" className={classes.logo}>
            <img src="/tc-logo-white.png" alt="TodaysCrypto" />
          </Link>
          {sidebar}
          <ul className={classes.userNav}>
            <li>
              <AdminBaseLayout.NavLink
                onClick={() => setShowLogout(true)}
                text="Logout"
              />
            </li>
          </ul>
        </div>

        <div className={classes.content}>{main}</div>
      </div>

      <Modal
        okText="Yes, Log out"
        onCancel={() => setShowLogout(false)}
        onOk={handleLogout}
        size="sm"
        title="Logout"
        visible={showLogout}>
        <p>Log out of admin area?</p>
      </Modal>
    </>
  )
}

AdminBaseLayout.NavLink = ({ icon, image, text, to, ...props }) => {
  return createElement(to ? NavLink : 'a', {
    ...props,
    activeClassName: classes.active,
    to,
    children: (
      <>
        {image ? (
          <span className={classes.image}>{image}</span>
        ) : (
          <Icon className={classes.icon}>{icon}</Icon>
        )}
        <span className={classes.text}>{text}</span>
      </>
    ),
  })
}
