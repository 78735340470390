import { useMemo, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import notification from 'shared/components/kit/notification'
import Modal from 'shared/components/kit/modal'
import getComments from 'publisher/queries/getComments'
import unrememberedAllComments from 'publisher/mutations/unrememberedAllComments'
import Layout from 'publisher/components/page'
import Page from 'publisher/components/page'
import Main from '../shared/components/Main'
import Filters from '../shared/components/Filters'

export default function RememberedComments() {
  const FILTER_CONFIG = useMemo(
    () => ({
      base: `/publisher/comments/remembered`,
      params: {
        page: 'pagination',
        sort: " '' | most_liked | oldest",
        video: 'number',
        time: 'string',
        perPage: '50 | 75 | 100',
        onlyRemembers: 'boolean',
      },
    }),
    []
  )

  const parsedLocation = useParsedLocation()
  const { sort, video, time, perPage } = parsedLocation.queryParams

  const queryClient = useQueryClient()
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)

  const [selectedComment, setSelectedComment] = useState()

  const { data, error, isError, isFetching, isLoading } = useQuery(
    getComments,
    {
      keepPreviousData: true,
      variables: {
        page: Number(filters.page) || 1,
        sort: filters.sort,
        video: Number(filters.video),
        time: filters.time,
        perPage: filters.perPage,
        onlyRemembers: true,
        onlyMyMentions: false,
      },
    }
  )

  const {
    data: comments,
    currentPage,
    lastPage,
  } = getPaginatedDataFromAPI(data?.json)

  const clearRememberedMutation = useMutation(unrememberedAllComments, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getComments,
      })
      notification.success({ title: 'All comments unremembered successfully!' })
    },
  })

  const handleUnrememberedAll = async () => {
    Modal.open({
      title: 'CLEAR COMMENTS',
      description: 'Are you sure you want to clear all remembered comments?',
      okText: 'Yes',
      onOk: () => {
        try {
          clearRememberedMutation.mutateAsync()
          setSelectedComment()
        } catch (error) {
          notification.error({ title: error.message })
        }
      },
    })
  }

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleCloseDrawer = () => {
    setSelectedComment()
  }

  const handleFilter = (next) => {
    setFilters(next)
    handleCloseDrawer()
  }

  const handleSetSelectedComment = (value) => {
    setSelectedComment(value)
  }

  if (isError) {
    return <Page error={error.message} />
  }

  return (
    <Layout width="lg">
      <Filters initialFilters={filters} onSubmit={handleFilter} />
      <Main
        comments={comments}
        isLoading={isLoading}
        isFetching={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
        onCloseDrawer={handleCloseDrawer}
        onSetSelectedComment={handleSetSelectedComment}
        selectedComment={selectedComment}
        onUnrememberedAllComments={handleUnrememberedAll}
      />
    </Layout>
  )
}
