type GetCoinsWithActiveCampaigns = {
  search: string,
  page?: number,
}

export default function getCoinsWithActiveCampaigns(
  variables: GetCoinsWithActiveCampaigns = {}
) {
  const { page, search } = variables
  return {
    path: '/admin/cryptocurrencies/relatedto-campaigns',
    params: {
      page: page > 1 ? page : undefined,
      per_page: 15,
      filters: {
        search,
      },
    },
  }
}
