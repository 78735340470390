import { useCallback } from 'react'
import useQueryClient from 'core/react-query/useQueryClient'
import getProfile2FA from 'shared/queries/getProfile2FA'

export default function useRefetchProfile2FA() {
  const queryClient = useQueryClient()

  return useCallback(
    () =>
      queryClient.invalidateQueries({
        query: getProfile2FA,
      }),
    [queryClient]
  )
}
