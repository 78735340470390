import { useParams } from 'react-router'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import notification from 'shared/components/kit/notification'
import Modal from 'shared/components/kit/modal'
import changePaymentDetailStatus from 'admin/queries/changePaymentDetailStatus'
import getPaymentDetail from 'admin/queries/getPaymentDetail'
import getPaymentDetails from 'admin/queries/getPaymentDetails'

export default function ChangePaymentDetailStatus({ paymentDetailData }) {
  const { status, onlyArchived, publisherId, sort, page } = useParams()
  const queryClient = useQueryClient()

  const mutation = useMutation(changePaymentDetailStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getPaymentDetail,
        variables: { id: Number(paymentDetailData.id) },
      })
      queryClient.invalidateQueries({
        query: getPaymentDetails,
        variables: {
          status,
          only_archived: onlyArchived,
          publisher_id: publisherId,
          sort,
          page,
        },
      })
      notification.success({
        title: 'Payment details status updated successfully!',
      })
    },
  })

  const handleChangeStatus = async (e, status) => {
    e.preventDefault()
    Modal.open({
      title: 'Change Payment Detail status',
      description: `Are you sure you want to change status to ${status}?`,
      onOk: async () => {
        try {
          await mutation.mutateAsync({
            ids: [paymentDetailData.id],
            status,
          })
        } catch (error) {
          notification.error({ title: error.message })
        }
      },
    })
  }

  return (
    <div className="flex items-center gap-2 mt-2">
      <span>Change status to</span>
      {paymentDetailData.status !== 'new' && (
        <Button
          onClick={(e) => {
            handleChangeStatus(e, 'new')
          }}
          size="sm"
          mood="neutral">
          New
        </Button>
      )}
      {paymentDetailData.status !== 'code-sent' && (
        <Button
          onClick={(e) => {
            handleChangeStatus(e, 'code-sent')
          }}
          size="sm"
          mood="neutral">
          Code sent
        </Button>
      )}
      {paymentDetailData.status !== 'canceled' && (
        <Button
          onClick={(e) => {
            handleChangeStatus(e, 'canceled')
          }}
          size="sm"
          mood="neutral">
          Canceled
        </Button>
      )}
    </div>
  )
}
