// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messages_fromAvatars__T-7iT {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.messages_avatarImg__1MoRa {\n  border-radius: 9999px;\n  border-width: 2px;\n  max-height: 2rem;\n  max-width: 2rem;\n  min-height: 2rem;\n  min-width: 2rem;\n}\n\n.messages_new__1sI12 {\n  --tw-bg-opacity: 1;\n  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));\n}\n\n.messages_channelAvatar__mrFwq {\n  height: 2rem;\n  margin-right: 0.5rem;\n  width: 2rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/messages/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,mBAAmB;EAAnB,2BAAmB;ADAnB;;AAEA;ECFA,qBAAmB;EAAnB,iBAAmB;EDIjB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;ECVA,kBAAmB;EAAnB,2DAAmB;ADYnB;;AAEA;ECdA,YAAmB;EAAnB,oBAAmB;EAAnB,WAAmB;ADgBnB","sourcesContent":[".fromAvatars {\n  @apply flex items-center justify-start;\n}\n\n.avatarImg {\n  @apply rounded-full border-2;\n  max-height: 2rem;\n  max-width: 2rem;\n  min-height: 2rem;\n  min-width: 2rem;\n}\n\n.new {\n  @apply bg-gray-50;\n}\n\n.channelAvatar {\n  @apply mr-2 h-8 w-8;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fromAvatars": "messages_fromAvatars__T-7iT",
	"avatarImg": "messages_avatarImg__1MoRa",
	"new": "messages_new__1sI12",
	"channelAvatar": "messages_channelAvatar__mrFwq"
};
export default ___CSS_LOADER_EXPORT___;
