import useQuery from 'core/useQuery'
import getCategories from 'publisher/queries/getCategories'
import Select from 'shared/components/form/select'

export default function SelectCategory(props) {
  const { data, isLoading } = useQuery(getCategories)
  const options = (data?.json || []).map((opt) => ({
    label: opt.name,
    value: opt.id,
  }))

  return (
    <Select
      placeholder="Select Category..."
      {...props}
      isLoading={isLoading}
      options={options}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
    />
  )
}
