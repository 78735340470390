import useQuery from 'core/react-query/useQuery'
import getVideoThumbnail from 'helpers/utils/getVideoThumbnail'
import classes from './Video.module.css'
import { useEffect } from 'react'

export default function Video({ onRefresh, urlHash }) {
  const { data, isError, isLoading, isSuccess } = useQuery(getVideoByUrlHash, {
    variables: {
      urlHash,
    },
  })

  useEffect(() => {
    if (isSuccess) {
      onRefresh()
    }
  }, [isSuccess, onRefresh])

  if (isError || isLoading) {
    return null
  }

  const video = data && data.json ? data.json : null

  return (
    <div className={classes.wrapper}>
      <img alt={video.title} src={getVideoThumbnail(video)} />
      <h3>{video.title}</h3>
    </div>
  )
}

function getVideoByUrlHash(variables) {
  const { urlHash } = variables

  return {
    name: 'video',
    path: `/videos/${urlHash}`,
  }
}
