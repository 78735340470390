// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CampaignsList_actions__2UzGo {\n  text-align: right;\n  width: 6rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/ads-manager/buy-buttons/components/campaign-list/CampaignsList.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,iBAAmB;EAAnB,WAAmB;ADAnB","sourcesContent":[".actions {\n  @apply text-right w-24;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "CampaignsList_actions__2UzGo"
};
export default ___CSS_LOADER_EXPORT___;
