import React from 'react'
import { useHistory, useParams } from 'react-router'
import Page from 'admin/components/page'
import Tabs from 'shared/components/kit/tabs'
import PublisherFAQ from './components/PublisherFAQ'
import MobileFAQ from './components/MobileFAQ'

export default function FAQ() {
  const { activeTab } = useParams()
  const history = useHistory()

  const handleTabChange = (val) => {
    history.push(`/admin/faq/${val}`)
  }
  return (
    <Page title="FAQ">
      <Tabs activeName={activeTab} onTabChange={handleTabChange}>
        <Tabs.Tab name="publisher" title="Publisher FAQ">
          <PublisherFAQ />
        </Tabs.Tab>
        <Tabs.Tab name="mobile" title="Mobile FAQ">
          <MobileFAQ />
        </Tabs.Tab>
      </Tabs>
    </Page>
  )
}
