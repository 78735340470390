import { useCallback } from 'react'
import { useMutation as useReactMutation, useQueryClient } from 'react-query'

export default function useMutation(mutation, options) {
  const queryClient = useQueryClient()

  const mutationFn = useCallback(
    (variables = {}) => {
      const { method, path, params } = mutation(variables)

      const fetchOptions = {
        body: params,
        method: method || 'POST',
      }

      return queryClient.fetcher(path, fetchOptions)
    },
    [queryClient, mutation]
  )

  return useReactMutation(mutationFn, options)
}
