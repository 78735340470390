import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import formatDate from 'helpers/utils/formatDate'
import Tag from 'shared/components/kit/tag'
import notificationComponent from 'shared/components/kit/notification'
import getNotificationsSentByAdmin from 'admin/queries/getNotificationsSentByAdmin'
import restoreNotification from 'admin/queries/restoreNotification'
import getNotification from 'admin/queries/getNotification'

export default function RestoreNotificationButton({ notification }) {
  const queryClient = useQueryClient()
  const mutation = useMutation(restoreNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getNotification,
        variables: {
          id: notification.id,
        },
      })
      queryClient.invalidateQueries({
        query: getNotificationsSentByAdmin,
      })
      notificationComponent.success({
        title: 'Notification restored successfully!',
      })
    },
  })

  const handleRestoredClick = async (e) => {
    e.preventDefault()
    await mutation.mutateAsync({
      id: notification.id,
    })
  }

  return (
    <>
      <Tag type="bg-gray-400 ml-2">
        <span className="block rounded text-sm text-overlayColor-mutedText">
          Archived
        </span>
      </Tag>
      <br />
      <span className="text-overlayColor-mutedText text-sm whitespace-nowrap">
        {' '}
        Archived By <b> {notification.deleted_by.username} </b>
        on {formatDate(notification.deleted_at)}.
        <b>
          <span
            className="cursor-pointer text-primary"
            onClick={handleRestoredClick}>
            {' '}
            RESTORE NOTIFICATION
          </span>
        </b>
      </span>
    </>
  )
}
