import HtmlSelect from 'shared/components/form/select/HtmlSelect'

export default function Filters({ statisticsPeriod, onChange }) {
  return (
    <HtmlSelect value={statisticsPeriod} onChange={onChange}>
      <option value="last_7d">Last 7 Days</option>
      <option value="last_14d">Last 14 Days</option>
      <option value="last_30d">Last 30 Days</option>
      <option value="last_90d">Last 3 Months</option>
      <option value="last_180d">Last 6 Months</option>
      <option value="last_365d">Last 12 Months</option>
    </HtmlSelect>
  )
}
