type getReportedVideosVariables = {
  page?: number,
}

export default function getReportedVideos(
  variables: getReportedVideosVariables
) {
  const { page } = variables

  return {
    name: 'reportedVideos',
    path: '/admin/reports/video',
    params: {
      page: page > 1 ? page : undefined,
    },
  }
}
