import { useState } from 'react'
import ChooseVideoModal from 'shared/components/choose-video-modal'
import Button from 'shared/components/kit/button'

type ChooseVideoButtonProps = {
  buttonText?: string,
  cancelText?: string,
  excludePlaylist?: number,
  excludeVideos?: Array<number>,
  okText?: string,
  onCancel?: Function,
  onChoose: Function,
  title?: string,
}

export default function ChooseVideoButton(props: ChooseVideoButtonProps) {
  const {
    buttonText,
    cancelText,
    excludePlaylist,
    excludeVideos,
    okText,
    onCancel,
    onChoose,
    title,
    ...buttonProps
  } = props

  const [visible, setVisible] = useState(false)

  const handleCancel = () => {
    setVisible(false)
    if (onCancel) {
      onCancel()
    }
  }

  const handleChoose = (videos) => {
    setVisible(false)
    onChoose(videos)
  }

  return (
    <>
      <Button {...buttonProps} onClick={() => setVisible(true)}>
        {buttonText}
      </Button>

      <ChooseVideoModal
        cancelText={cancelText}
        excludePlaylist={excludePlaylist}
        excludeVideos={excludeVideos}
        okText={okText}
        onCancel={handleCancel}
        onChoose={handleChoose}
        title={title}
        visible={visible}
      />
    </>
  )
}

ChooseVideoButton.defaultProps = {
  buttonText: 'Add Media',
}
