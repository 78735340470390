type LoginVariables = {
  email: string,
  password: string,
  captcha: string,
  captchaKey: string,
}

export default function login(variables: LoginVariables) {
  const { email, password, captcha, captchaKey } = variables

  return {
    path: '/login',
    params: {
      email,
      password,
      captcha,
      captcha_key: captchaKey,
    },
  }
}
