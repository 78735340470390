import createUrl from 'core/createUrl'

type getVideoReportsQueryVariables = {
  page?: number,
  reason?: string,
  id: number,
}

export default function getVideoReports(
  variables: getVideoReportsQueryVariables = {}
) {
  const { id, page, reason } = variables

  return {
    name: 'videoReports',
    path: `/admin/reports/video/${id}`,
    params: {
      filters: {
        reason: reason || undefined,
      },
      page: page > 1 ? page : undefined,
    },
  }
}
