type GetPublisherPoAHistoryVariables = {
  channelId: number,
}

export default function getPublishersPoAHistory(
  variables: GetPublisherPoAHistoryVariables = {}
) {
  const { channelId } = variables
  return {
    path: `/admin/channels/${channelId}/address-history`,
  }
}
