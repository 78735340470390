import { ReactComponent as ChannelSVG } from '../images/overlay-channel.svg'
import { ReactComponent as MediaSVG } from '../images/overlay-media.svg'
import { ReactComponent as SubscribeSVG } from '../images/overlay-subscribe.svg'
import classes from './ChooseOverlayInput.module.css'

export default function ChooseOverlayInput({ onChange, value }) {
  return (
    <ul className={classes.types}>
      <li onClick={() => onChange('channel')}>
        <div className={classes.icon}>
          <ChannelSVG />
        </div>
        <div className={classes.content}>
          <h3>Channel</h3>
          <p>
            Promote your own, a friend, or associates channel with a clickable
            channel graphic. The channel page is opened in a new tab while the
            media playing gets paused.
          </p>
        </div>
      </li>
      <li onClick={() => onChange('subscribe')}>
        <div className={classes.icon}>
          <SubscribeSVG />
        </div>
        <div className={classes.content}>
          <h3>Subscribe</h3>
          <p>
            Encourage your viewers to don’t miss out on any content with this
            clickable subscribe graphic.
          </p>
        </div>
      </li>
      <li onClick={() => onChange('video')}>
        <div className={classes.icon}>
          <MediaSVG />
        </div>
        <div className={classes.content}>
          <h3>Media</h3>
          <p>
            Expose your viewers to more of your content by adding clickable
            related media graphics. Any related media is opened in a new tab
            while the media playing gets paused.
          </p>
        </div>
      </li>
    </ul>
  )
}
