type Variables = {
  id: number,
  statisticsPeriod?:
    | 'last_7d'
    | 'last_14d'
    | 'last_30d'
    | 'last_90d'
    | 'last_180d'
    | 'last_365d',
}

export default function getVideoStatistics(variables: Variables) {
  const { id, statisticsPeriod } = variables

  const filters = {}
  if (statisticsPeriod) {
    filters.statistics_period = statisticsPeriod
  }

  return {
    name: 'videoStatistics',
    path: `/publisher/videos/${id}/statistics`,
    params: {
      filters,
    },
  }
}
