// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "ul.UserStats_stats__1mpA5 {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 4rem;\n  text-align: center;\n}\n\nul.UserStats_stats__1mpA5 li {\n  width: 7rem;\n}\n\nul.UserStats_stats__1mpA5 li:not(:last-child) {\n  border-right-width: 1px;\n  margin-right: 1rem;\n  padding-right: 1rem;\n}\n\nul.UserStats_stats__1mpA5 li p:first-child {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  margin-bottom: 0.5rem;\n  color: var(--overlay-color-muted-text);\n}\n\nul.UserStats_stats__1mpA5 li p:last-child {\n  font-weight: 300;\n  font-size: 1.875rem;\n  line-height: 2.25rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/feedbacks/components/UserStats.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,uBAAmB;EAAnB,mBAAmB;EAAnB,kBAAmB;ADAnB;;AAEA;ECFA,WAAmB;ADInB;;AAEA;ECNA,uBAAmB;EAAnB,kBAAmB;EAAnB,mBAAmB;ADQnB;;AAEA;ECVA,mBAAmB;EAAnB,oBAAmB;EAAnB,qBAAmB;EAAnB,sCAAmB;ADYnB;;AAEA;ECdA,gBAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;ADgBnB","sourcesContent":["ul.stats {\n  @apply flex justify-center mb-16 text-center;\n}\n\nul.stats li {\n  @apply w-28;\n}\n\nul.stats li:not(:last-child) {\n  @apply border-r mr-4 pr-4;\n}\n\nul.stats li p:first-child {\n  @apply mb-2 text-overlayColor-mutedText text-sm;\n}\n\nul.stats li p:last-child {\n  @apply font-light text-3xl;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stats": "UserStats_stats__1mpA5"
};
export default ___CSS_LOADER_EXPORT___;
