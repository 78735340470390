type Options = {
  convertNewLineToBreak: boolean,
  noFollowLink: boolean,
  openLinkInNewTab: boolean,
}

export default function formatHTML(content, options: Options = {}) {
  const {
    convertNewLineToBreak = true,
    noFollowLink = false,
    openLinkInNewTab = false,
  } = options

  if (content && typeof content === 'string') {
    let result = content
    if (convertNewLineToBreak) {
      result = result.replace(/(?:\r\n|\r|\n)/g, '<br/>')
    }

    const linksRegex = /([^<]*)<a([^<]*)>([^<]*)<\/a>([^<]*)/g

    if (noFollowLink) {
      result = result.replace(linksRegex, '$1<a$2 rel="nofollow">$3</a>$4')
    }

    if (openLinkInNewTab) {
      result = result.replace(linksRegex, '$1<a$2 target="_blank">$3</a>$4')
    }

    return result
  }

  return ''
}
