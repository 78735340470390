type GetTokensHistory = {
  userId: number,
  page?: number,
}

export default function getTokensHistory(variables: GetTokensHistory = {}) {
  const { userId, page } = variables
  const filters = {}

  if (userId) {
    filters.user_id = userId
  }

  return {
    path: '/admin/tokens/history',
    params: {
      page: page > 1 ? page : undefined,
      filters,
    },
  }
}
