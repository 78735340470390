import { useMemo } from 'react'
import Chart from 'shared/components/chart'
import useGetCSSColor from 'shared/hooks/useGetCSSColor'
import secondsToHours from 'shared/helpers/secondsToHours'

export default function WatchTimeChart({ data, labels, getDate }) {
  const getCSSColor = useGetCSSColor()

  const config = useMemo(() => {
    return {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            label: 'Watch Hours',
            backgroundColor: getCSSColor('secondary', 0.2),
            borderColor: getCSSColor('secondary'),
            data: data.map((item) => secondsToHours(item.watch_time_total)),
            fill: true,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Watch Time (Hours)',
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              footer: (tooltipItems) => {
                let normal = 0,
                  hero = 0
                tooltipItems.forEach((tooltipItem) => {
                  normal += data[tooltipItem.dataIndex].watch_time_non_hero
                  hero += data[tooltipItem.dataIndex].watch_time_hero
                })
                return `Users: ${normal}\nHodls: ${hero}`
              },
              label: (context) =>
                `${context.dataset.label}: ${Math.abs(context.raw)}`,
              title: (tooltipItems) =>
                getDate(data[tooltipItems[0].dataIndex].date),
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    }
  }, [getCSSColor, data, labels, getDate])

  return <Chart config={config} />
}
