import { useMemo } from 'react'
import useQuery from 'core/react-query/useQuery'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import formatDate from 'helpers/utils/formatDate'
import Table from 'shared/components/kit/table'
import getNotifyEmailsList from 'admin/queries/getNotifyEmailsList'
import Page from 'admin/components/page'
import Filters from './components/Filters'

const FILTER_CONFIG = {
  base: '/admin/emails',
  params: {
    page: 'pagination',
    location: 'any',
  },
}

export default function Emails() {
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)

  const { data, error, isError, isFetching } = useQuery(getNotifyEmailsList, {
    keepPreviousData: true,
    variables: filters,
  })

  const {
    data: emails,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleFilter = (nextFilters) => {
    setFilters({
      ...nextFilters,
      page: undefined,
    })
  }

  if (isError) {
    return <Page error={error.message} />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  return (
    <>
      <Page description={description} title="Publisher landing email's">
        <Filters initialFilters={filters} onSubmit={handleFilter} />
        <Table
          columns={columns}
          data={emails}
          loading={isFetching}
          currentPage={currentPage}
          lastPage={lastPage}
          onPaginate={handlePaginate}
        />
      </Page>
    </>
  )
}

function getColumns() {
  return [
    {
      title: 'Email',
      className: 'w-24',
      render: (emails) => emails.email,
    },
    {
      title: 'Location',
      render: (emails) => emails.location,
    },
    {
      title: 'Creation Date',
      render: (emails) => formatDate(emails.created_at),
    },
  ]
}
