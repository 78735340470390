import { useLayoutEffect, useState } from 'react'

export default function useColorScheme() {
  const [scheme, setScheme] = useState(() => window.COLOR_SCHEME.theme)

  useLayoutEffect(() => {
    if (window.location.pathname.indexOf('/publisher') === 0) {
      return window.COLOR_SCHEME.subscribe((nextTheme) => {
        setScheme(nextTheme)
      })
    }
  })

  return scheme
}
