type VerifyEmailVerificationToken = {
  token: string,
}

export default function verifyEmailVerificationToken(
  variables: VerifyEmailVerificationToken = {}
) {
  const { token } = variables
  return {
    name: 'verifyEmailVerificationToken',
    path: `/users/verify/${token}`,
  }
}
