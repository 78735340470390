import Button from 'shared/components/kit/button'

export default function ContactButton({ email, filters, userId }) {
  return email ? (
    <Button
      component="link"
      to={`/admin/feedbacks?${
        filters.page > 1
          ? `page=${filters.page}&user-id=${userId}&email=${email}`
          : `user-id=${userId}&email=${email}`
      }`}
      size="sm"
      secondary>
      Contact
    </Button>
  ) : (
    <Button size="sm" disabled secondary>
      Contact
    </Button>
  )
}
