import { useParams } from 'react-router'
import getChannelAvatar from 'helpers/utils/getChannelAvatar'
import useChannel from 'admin/hooks/useChannel'
import Base from '../Base'

export default function AdminChannelLayout({ children }) {
  const { channelId } = useParams()
  const { channel } = useChannel(channelId)

  const channelAvatar = getChannelAvatar(channel?.avatar)

  const sidebar = (
    <ul>
      <li>
        <Base.NavLink
          image={<img src={channelAvatar} alt={channel.name} />}
          text={channel.name}
          to={`/admin/channels/${channel.id}`}
          exact
        />
      </li>
      <li>
        <Base.NavLink
          text="Videos"
          to={`/admin/channels/${channel.id}/videos`}
          exact
        />
      </li>
      <li>
        <Base.NavLink
          text="Earnings"
          to={`/admin/channels/${channel.id}/earnings`}
        />
      </li>
      <li>
        <Base.NavLink
          text="Address history"
          to={`/admin/channels/${channel.id}/address-history`}
        />
      </li>
      <li>
        <Base.NavLink text="Back" to="/admin/channels" exact />
      </li>
    </ul>
  )

  return <Base main={children} sidebar={sidebar} />
}
