type UnHideVideoMutationVariables = {
  id: number,
}

export default function unHideVideo(variables: UnHideVideoMutationVariables) {
  return {
    method: 'put',
    url: `/admin/videos/${variables.id}/unhide`,
    variables,
  }
}
