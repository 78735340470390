import { useEffect } from 'react'
import useMutation from 'core/useMutation'
import Box from 'shared/components/kit/box'
import Button from 'shared/components/kit/button'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import Page from 'publisher/components/page'
import { ReactComponent as DislikeSVG } from 'svg/dislike-filled.svg'
import { ReactComponent as LikeSVG } from 'svg/like-filled.svg'

export default function ImportRequest() {
  const { isError, isLoading, mutate } = useMutation(importRequest)

  useEffect(() => {
    mutate()
  }, [mutate])

  let content

  if (isLoading) {
    content = (
      <>
        <Icon className="text-5xl">
          <Spin />
        </Icon>
        <br />
        <p>Please wait...</p>
      </>
    )
  } else if (isError) {
    content = (
      <>
        <Icon className="text-5xl">
          <DislikeSVG />
        </Icon>
        <br />
        <p>There was a problem finishing your request.</p>
        <br />
        <Button onClick={mutate} outlined>
          Try Again
        </Button>
      </>
    )
  } else {
    content = (
      <>
        <Icon className="text-5xl">
          <LikeSVG />
        </Icon>
        <br />
        <p>
          Your request submitted successfully and
          <br />
          you will be notified as soon as we finished your import process.
        </p>
      </>
    )
  }

  return (
    <Page title="Import Request">
      <Box className="flex flex-col h-96 items-center justify-center text-center w-full">
        {content}
      </Box>
    </Page>
  )
}

function importRequest() {
  return {
    method: 'post',
    url: '/publisher/channels/request-import',
  }
}
