type ManualPaymentEarningVariables = {
  paymentMethodId: number,
  reference: string,
  id: number,
}

export default function manualPaymentEarning(
  variables: ManualPaymentEarningVariables
) {
  return {
    method: 'PUT',
    name: 'manualPaymentEarnings',
    path: `/admin/earnings/${variables.id}/paid`,
    params: {
      payment_method_id: variables.paymentMethodId,
      reference: variables.reference,
    },
  }
}
