import Tag from 'shared/components/kit/tag'
import classnames from 'classnames'

const STATUSES = {
  pending: {
    color: 'bg-blue-400',
    label: 'Pending',
  },
  paid: {
    color: 'bg-green-400',
    label: 'Paid',
  },
  'N/A': {
    color: 'bg-gray-400',
    label: 'N/A',
  },
  failed: {
    color: 'bg-red-400',
    label: 'Failed',
  },
}

export default function LotteryPaymentStatus({ status, className }) {
  const data = STATUSES[status]
  return <Tag type={classnames(data.color, className)}>{data.label}</Tag>
}
