import CampaignsList from './components/campaign-list/CampaignsList'
import CoinsActiveCampaigns from './components/coins-Active-compaign/CoinsActiveCampaigns'

export default function BuyButtons() {
  return (
    <>
      <CampaignsList />
      <div className="mt-24">
        <CoinsActiveCampaigns />
      </div>
    </>
  )
}
