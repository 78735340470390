import Cookie from 'js-cookie'
import env from 'core/env'

export default function cookie() {
  return {
    get(name, options = {}) {
      return Cookie.get(name, {
        domain: env('REACT_APP_COOKIE_DOMAIN'),
        ...options,
      })
    },
    remove(name, value, options = {}) {
      return Cookie.remove(name, {
        domain: env('REACT_APP_COOKIE_DOMAIN'),
        ...options,
      })
    },
    set(name, value, options = {}) {
      return Cookie.set(name, value, {
        domain: env('REACT_APP_COOKIE_DOMAIN'),
        ...options,
      })
    },
  }
}
