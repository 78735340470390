type UpdateUserMutationVariables = {
  id: number,
  fields: {
    avatar?: string,
    email?: string,
    eth_address?: string,
    hero_due_at?: string,
    is_hero?: boolean,
    new_password?: string,
    is_mute?: boolean,
    muted_until?:
      | '1_week'
      | '2_week'
      | '1_month'
      | '3_month'
      | '6_month'
      | '1_year'
      | 'permanent',
    role?: string,
    username?: string,
  },
}

export default function updateUser(variables: UpdateUserMutationVariables) {
  const { id, fields } = variables
  return {
    method: 'put',
    url: `/admin/users/${id}`,
    variables: fields,
  }
}
