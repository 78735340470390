// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading_loading__3dn3s {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  font-size: 3.75rem;\n  line-height: 1;\n  color: var(--primary-color-default);\n  width: 100vw;\n  min-height: var(--content-height);\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/kit/loading/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,mBAAmB;EAAnB,uBAAmB;EAAnB,aAAmB;EAAnB,kBAAmB;EAAnB,cAAmB;EAAnB,mCAAmB;EAAnB,YAAmB;EDAjB,iCAAiC;AACnC","sourcesContent":[".loading {\n  @apply flex items-center justify-center text-6xl text-primaryColor-default h-screen w-screen;\n  min-height: var(--content-height);\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "loading_loading__3dn3s"
};
export default ___CSS_LOADER_EXPORT___;
