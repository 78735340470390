type Variables = {
  includeFavoriteCount: boolean,
  includeVideoCount: boolean,
  sort?: string,
  page?: number,
  search: string,
  status: string,
}

export default function getTags(variables: Variables = {}) {
  const {
    includeFavoriteCount,
    includeVideoCount,
    sort,
    page,
    search,
    status,
  } = variables
  const includes = []
  if (includeVideoCount) {
    includes.push('videos_count')
  }

  if (includeFavoriteCount) {
    includes.push('favorited_count')
  }

  const params = {
    include: includes.length > 0 ? includes.join(',') : null,
    sort,
    page: page > 1 ? page : undefined,
    filters: {
      search,
      status,
    },
  }
  return {
    name: 'getTags',
    path: '/tags',
    params,
  }
}
