// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "form.Filters_filters__Y0my6 {\n  display: flex;\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n}\n\nform.Filters_filters__Y0my6 .Filters_video__36ohg {\n  margin-right: 1rem;\n  width: 24rem;\n}\n\nform.Filters_filters__Y0my6 .Filters_select__2o3fV {\n  margin-right: 1rem;\n  width: 14rem;\n}\n\n.Filters_perPageSelect__eDjJU {\n  margin-right: 1rem;\n  width: 7rem;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/comments/shared/components/Filters.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,gBAAmB;EAAnB,mBAAmB;ADAnB;;AAEA;ECFA,kBAAmB;EAAnB,YAAmB;ADInB;;AAEA;ECNA,kBAAmB;EAAnB,YAAmB;ADQnB;;AAEA;ECVA,kBAAmB;EAAnB,WAAmB;ADYnB","sourcesContent":["form.filters {\n  @apply flex my-8;\n}\n\nform.filters .video {\n  @apply mr-4 w-96;\n}\n\nform.filters .select {\n  @apply mr-4 w-56;\n}\n\n.perPageSelect {\n  @apply mr-4 w-28;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": "Filters_filters__Y0my6",
	"video": "Filters_video__36ohg",
	"select": "Filters_select__2o3fV",
	"perPageSelect": "Filters_perPageSelect__eDjJU"
};
export default ___CSS_LOADER_EXPORT___;
