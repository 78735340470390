// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".password_wrapper__2lOrI {\n  position: relative;\n}\n\n.password_wrapper__2lOrI input {\n  padding-right: 5rem;\n}\n\n.password_wrapper__2lOrI button {\n  background-color: transparent !important;\n  border-width: 0px !important;\n  height: 2.5rem !important;\n  padding: 0.5rem !important;\n  position: absolute !important;\n  right: 0.5rem !important;\n  top: 50% !important;\n  color: var(--neutral) !important;\n  width: 2.5rem !important;\n  --tw-translate-x: 0 !important;\n  --tw-translate-y: 0 !important;\n  --tw-rotate: 0 !important;\n  --tw-skew-x: 0 !important;\n  --tw-skew-y: 0 !important;\n  --tw-scale-x: 1 !important;\n  --tw-scale-y: 1 !important;\n  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;\n  --tw-translate-y: -50% !important;\n}\n\n.password_wrapper__2lOrI button svg {\n  height: 1.5rem;\n  fill: currentColor;\n  width: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/form/password/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,kBAAmB;ADAnB;;AAEA;ECFA,mBAAmB;ADInB;;AAEA;ECNA,wCAAmB;EAAnB,4BAAmB;EAAnB,yBAAmB;EAAnB,0BAAmB;EAAnB,6BAAmB;EAAnB,wBAAmB;EAAnB,mBAAmB;EAAnB,gCAAmB;EAAnB,wBAAmB;EAAnB,8BAAmB;EAAnB,8BAAmB;EAAnB,yBAAmB;EAAnB,yBAAmB;EAAnB,yBAAmB;EAAnB,0BAAmB;EAAnB,0BAAmB;EAAnB,sNAAmB;EAAnB,iCAAmB;ADQnB;;AAEA;ECVA,cAAmB;EAAnB,kBAAmB;EAAnB,aAAmB;ADYnB","sourcesContent":[".wrapper {\n  @apply relative;\n}\n\n.wrapper input {\n  @apply pr-20;\n}\n\n.wrapper button {\n  @apply absolute bg-transparent border-0 h-10 p-2 right-2 text-neutral top-1/2 transform -translate-y-1/2 w-10 !important;\n}\n\n.wrapper button svg {\n  @apply fill-current h-6 w-6;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "password_wrapper__2lOrI"
};
export default ___CSS_LOADER_EXPORT___;
