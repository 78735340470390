import formatHTML from 'helpers/utils/formatHTML'
import Modal from 'shared/components/kit/modal'
import formatDate from 'helpers/utils/formatDate'
import classes from './ModalNotification.module.css'

export default function ModalNotification({
  body,
  message,
  image,
  videoTitle,
  date,
  footer,
  title,
  onCancel,
  visible,
}) {
  return (
    <Modal
      title={
        <div className="flex flex-col">
          <span className={classes.date}>Received {formatDate(date)}</span>
          <span className={classes.title}>{title}</span>
        </div>
      }
      visible={visible}
      onCancel={onCancel}
      size="md"
      footer={footer}>
      <li className={classes.wrapper}>
        <div className={classes.body}>
          <div className={classes.message}>{body}</div>
          <div className={classes.image}>
            {image}
            <div
              className={classes.message}
              dangerouslySetInnerHTML={{ __html: formatHTML(message) }}
            />
            <div className="ml-2">{videoTitle}</div>
          </div>
        </div>
      </li>
    </Modal>
  )
}
