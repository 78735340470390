import { useHistory } from 'react-router'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import ManageUserDrawer from './components/ManageUserDrawer'
import UsersList from './components/UsersList'

export default function Users() {
  const history = useHistory()
  const { queryParams } = useParsedLocation()
  const userId = queryParams.id ? Number(queryParams.id) : undefined

  const handleCloseUserDrawer = () => {
    let search = history.location.search.substr(
      0,
      history.location.search.indexOf('&id')
    )
    history.push('/admin/users' + search)
  }

  return (
    <>
      <UsersList />
      <ManageUserDrawer
        userId={userId}
        onClose={handleCloseUserDrawer}
        visible={!!userId}
      />
    </>
  )
}
