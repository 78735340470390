import Box from 'shared/components/kit/box'
import Chart from 'shared/components/chart'

export default function VideoUploadsChart({
  data,
  Filter,
  range,
  chartedData,
  title,
}) {
  return (
    <Box>
      <header>
        <h4>{title}</h4>
        {Filter}
      </header>
      <div>
        <Chart
          config={{
            type: 'bar',
            data: chartedData(data, range),
            options: {
              tooltips: {
                callbacks: {
                  label: (tooltipItem) => tooltipItem.value + ' Videos',
                },
              },
              elements: {
                line: {
                  tension: 0,
                },
                point: {
                  radius: 0,
                },
              },
              responsive: true,
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      callback: function (value, index) {
                        if (range !== 'this_month' && range !== 'last_month') {
                          return value
                        } else if (index === 0 || (index + 1) % 5 === 0) {
                          return value
                        }
                      },
                      autoSkip: false,
                      maxRotation: 0,
                      minRotation: 0,
                    },
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      callback: function (value) {
                        if (value % 1 === 0) {
                          return value
                        }
                      },
                    },
                  },
                ],
              },
              title: {
                display: false,
              },
            },
          }}
        />
      </div>
    </Box>
  )
}
