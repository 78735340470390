// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reset-password_wrapper__3P1uz {\n  margin-top: 12rem;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/reset-password/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,iBAAmB;ADAnB","sourcesContent":[".wrapper {\n  @apply mt-48;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "reset-password_wrapper__3P1uz"
};
export default ___CSS_LOADER_EXPORT___;
