import Tag from 'shared/components/kit/tag'
import classnames from 'classnames'

const STATUSES = {
  archive: {
    color: 'bg-red-400',
    label: 'archive',
  },
  draft: {
    color: 'bg-gray-400',
    label: 'Draft',
  },
  active: {
    color: 'bg-green-400',
    label: 'Active',
  },
}

export default function CampaignStatus({ status, className }) {
  const data = STATUSES[status]
  return <Tag type={classnames(data.color, className)}>{data.label}</Tag>
}
