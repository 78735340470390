// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select-channel_option__W45lx {\n  display: flex;\n  align-items: center;\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n}\n\n.select-channel_option__W45lx .select-channel_avatar__2DGZ6 {\n  margin-right: 0.5rem;\n  max-height: 2.5rem;\n  min-height: 2.5rem;\n  max-width: 2.5rem;\n  min-width: 2.5rem;\n}\n\n.select-channel_option__W45lx ul.select-channel_info__3aGyz {\n  flex-grow: 1;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  color: var(--overlay-color-muted-text);\n  white-space: nowrap;\n}\n\n.select-channel_option__W45lx .select-channel_info__3aGyz > div {\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://src/admin/components/form/select-channel/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,mBAAmB;EAAnB,mBAAmB;EAAnB,sBAAmB;ADAnB;;AAEA;ECFA,oBAAmB;EDIjB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;ECVA,YAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,sCAAmB;EAAnB,mBAAmB;ADYnB;;AAEA;ECdA,mBAAmB;ADgBnB","sourcesContent":[".option {\n  @apply flex items-center py-2;\n}\n\n.option .avatar {\n  @apply mr-2;\n  max-height: 2.5rem;\n  min-height: 2.5rem;\n  max-width: 2.5rem;\n  min-width: 2.5rem;\n}\n\n.option ul.info {\n  @apply flex-grow text-overlayColor-mutedText text-sm whitespace-nowrap;\n}\n\n.option .info > div {\n  @apply whitespace-nowrap;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": "select-channel_option__W45lx",
	"avatar": "select-channel_avatar__2DGZ6",
	"info": "select-channel_info__3aGyz"
};
export default ___CSS_LOADER_EXPORT___;
