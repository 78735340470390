// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserInfo_wrapper__2u4ie {\n  margin-bottom: 4rem;\n  text-align: center;\n}\n\n.UserInfo_wrapper__2u4ie .UserInfo_avatar__1ZlT4 {\n  display: inline-block;\n  margin-bottom: 1rem;\n  width: 12rem;\n  min-width: 168px;\n  min-height: 168px;\n}\n\n.UserInfo_wrapper__2u4ie ul {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  color: var(--overlay-color-muted-text);\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/feedbacks/components/UserInfo.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,mBAAmB;EAAnB,kBAAmB;ADAnB;;AAEA;ECFA,qBAAmB;EAAnB,mBAAmB;EAAnB,YAAmB;EDIjB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;ECRA,mBAAmB;EAAnB,oBAAmB;EAAnB,sCAAmB;ADUnB","sourcesContent":[".wrapper {\n  @apply mb-16 text-center;\n}\n\n.wrapper .avatar {\n  @apply inline-block mb-4 w-48;\n  min-width: 168px;\n  min-height: 168px;\n}\n\n.wrapper ul {\n  @apply text-overlayColor-mutedText text-sm;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "UserInfo_wrapper__2u4ie",
	"avatar": "UserInfo_avatar__1ZlT4"
};
export default ___CSS_LOADER_EXPORT___;
