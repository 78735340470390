import classnames from 'classnames'
import Checkbox from 'shared/components/form/checkbox'
import Icon from 'shared/components/kit/icon'
import Pagination from 'shared/components/kit/pagination'
import Spin from 'shared/components/kit/spin'
import { ReactComponent as VideosSVG } from 'svg/videos.svg'
import classes from './index.module.css'

type Props = {
  bordered?: boolean,
  className?: string,
  columns: Array<{
    align?: 'center' | 'left' | 'right',
    className?: string,
    footer?: Function,
    render?: Function,
    title?: any,
  }>,
  currentPage?: number,
  data?: Array,
  emptyText?: any,
  lastPage?: number,
  loading?: boolean,
  onPaginate?: Function,
  onRowSelect?: Function,
  rowClassName?: Function,
  rowKey?: Function,
  selectedRows?: Array<number>,
}

export default function Table(props: Props) {
  const {
    bordered,
    className,
    columns,
    currentPage,
    data,
    emptyText,
    lastPage,
    loading,
    onPaginate,
    onRowSelect,
    rowClassName,
    rowKey,
    selectedRows,
  } = props

  const hasSelectCol = typeof onRowSelect === 'function'
  const selectedKeys = selectedRows || []
  const totalColumns = columns.length + (hasSelectCol ? 1 : 0)
  const hasData = data && data.length > 0
  const isSelectedAll =
    hasData &&
    selectedKeys.length === data.length &&
    data.every((row) => selectedKeys.indexOf(row.id) > -1)
  const wrapperClassName = classnames(className, classes.wrapper, {
    [classes.bordered]: bordered,
    [classes.fetching]: hasData && loading,
  })

  const handlePaginate = (page) => {
    onPaginate(page)
    window.scrollTo(0, 0)
  }

  return (
    <>
      <div className={wrapperClassName}>
        <table>
          <thead>
            <tr>
              {hasSelectCol && (
                <th className={classes.checkbox}>
                  <Checkbox
                    checked={isSelectedAll}
                    onChange={(checked) => {
                      if (checked) {
                        onRowSelect(data.map(rowKey))
                      } else {
                        onRowSelect([])
                      }
                    }}
                    type="checkbox"
                  />
                </th>
              )}
              {columns.map((column, key) => (
                <th key={key} align={column.align || 'left'}>
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {hasData &&
              data.map((row, vKey) => {
                const _rowKey = rowKey(row)
                const _isSelected = selectedKeys.indexOf(_rowKey) > -1
                const _rowClassName = classnames(
                  rowClassName ? rowClassName(row, vKey) : null,
                  {
                    [classes.selected]: _isSelected,
                  }
                )
                return (
                  <tr key={vKey} className={_rowClassName}>
                    {hasSelectCol && (
                      <td className={classes.checkbox}>
                        <Checkbox
                          checked={_isSelected}
                          onChange={(checked) => {
                            if (checked) {
                              onRowSelect([...selectedKeys, _rowKey])
                            } else {
                              onRowSelect(
                                selectedKeys.filter((k) => k !== _rowKey)
                              )
                            }
                          }}
                        />
                      </td>
                    )}
                    {columns.map((column, cKey) => (
                      <td
                        key={`${vKey}-${cKey}`}
                        align={column.align || 'left'}
                        className={column.className}>
                        {column.render(row, vKey)}
                      </td>
                    ))}
                  </tr>
                )
              })}
            {!hasData && !loading ? (
              <tr>
                <td colSpan={totalColumns}>
                  <div className={classes.empty}>
                    <Icon>
                      <VideosSVG />
                    </Icon>
                    <div>{emptyText}</div>
                  </div>
                </td>
              </tr>
            ) : null}
            {!hasData && loading ? (
              <tr>
                <td colSpan={totalColumns}>
                  <div className={classes.loading}>
                    <Icon>
                      <Spin />
                    </Icon>
                    <div>Loading...</div>
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>

      {lastPage > 1 && (
        <div className={classes.pagination}>
          <Pagination
            current={currentPage}
            last={lastPage}
            onPaginate={handlePaginate}
          />
        </div>
      )}
    </>
  )
}

Table.defaultProps = {
  bordered: true,
  emptyText: 'No records found.',
  rowKey: (row) => row.id,
}
