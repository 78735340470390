// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Subscribe_button__2m1A9 {\n  font-size: 1.125rem !important;\n  line-height: 1.75rem !important;\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);\n}\n", "",{"version":3,"sources":["webpack://src/publisher/components/studio/components/layers/Subscribe.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,8BAAmB;EAAnB,+BAAmB;EDAjB,sCAAsC;AACxC","sourcesContent":[".button {\n  @apply text-lg !important;\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Subscribe_button__2m1A9"
};
export default ___CSS_LOADER_EXPORT___;
