type GetPlaylistQueryVariables = {
  id: number,
}

export default function getPlaylist(variables: GetPlaylistQueryVariables) {
  const { id } = variables

  return {
    name: 'playlist',
    path: `/playlists/${id}`,
  }
}
