import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Input from 'shared/components/form/input'
import SelectPlaylist from 'shared/components/form/select-playlist'
import Select from 'shared/components/form/select'
import classes from './Filters.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  const mediaTypeOptions = [
    {
      label: 'Video',
      value: 'video',
    },
    {
      label: 'Podcast',
      value: 'podcast',
    },
  ]

  const options = [
    {
      label: 'Publish Date',
      value: 'published_at',
    },
    {
      label: 'Most Viewed',
      value: 'most_viewed',
    },
    {
      label: 'Most Liked',
      value: 'most_liked',
    },
  ]

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <Input
        {...form.getInputPropsFor('search')}
        placeholder="Search media..."
      />
      <Select
        className={classes.select}
        {...form.getInputPropsFor('mediaType')}
        options={mediaTypeOptions}
        placeholder="Select media type..."
        isClearable={true}
      />
      <SelectPlaylist
        className={classes.select}
        {...form.getInputPropsFor('playlistId')}
        placeholder="Select playlist..."
        isClearable={true}
      />
      <Select
        className={classes.select}
        {...form.getInputPropsFor('sort')}
        options={options}
        placeholder="Sort By..."
      />

      <Button mood="secondary">Apply</Button>
    </form>
  )
}
