import dayjs from 'dayjs'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'

export default function SelectYears({ onChange }) {
  const handleChange = (value) => {
    onChange(value)
  }

  return (
    <>
      <HtmlSelect onChange={handleChange}>
        <option value={dayjs().year()}>{dayjs().year()}</option>
        <option value={dayjs().add(1, 'year').format('YYYY')}>
          {dayjs().add(1, 'year').format('YYYY')}
        </option>
        <option value={dayjs().subtract(1, 'year').format('YYYY')}>
          {dayjs().subtract(1, 'year').format('YYYY')}
        </option>
        <option value={dayjs().subtract(2, 'year').format('YYYY')}>
          {dayjs().subtract(2, 'year').format('YYYY')}
        </option>
      </HtmlSelect>
    </>
  )
}
