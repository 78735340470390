import { useHistory } from 'react-router'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import Drawer from 'shared/components/kit/drawer'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import getPaymentDetail from 'admin/queries/getPaymentDetail'
import ChannelAvatar from 'shared/components/channel-avatar'
import PaymentDetailStatus from 'shared/components/payment-detail-status'
import formatDate from 'helpers/utils/formatDate'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import UnArchivePaymentDetailButton from './UnArchivePaymentDetailButton'
import ArchivePaymentDetailButton from './ArchivePaymentDetailButton'
import ChangePaymentDetailStatus from './ChangePaymentDetailStatus'
import classes from './PaymentDetail.module.css'

export default function PaymentDetail({ id }) {
  const history = useHistory()
  const { data, isLoading, error, isError } = useQuery(getPaymentDetail, {
    enabled: typeof id !== 'undefined',
    variables: {
      id,
    },
  })

  const handleCloseDrawer = () => {
    history.replace(
      `/admin/payment-details${history.location.search.replace('&', '')}`
    )
  }

  const paymentDetailData = get(data, 'json')
  return (
    <Drawer
      error={error?.json}
      isloading={isLoading}
      isError={isError}
      onClose={handleCloseDrawer}
      visible={!!id}>
      {paymentDetailData ? (
        <div>
          <div className="flex items-center gap-2">
            <ChannelAvatar
              channel={{
                name: paymentDetailData.user.username,
                avatar: paymentDetailData.user.avatar,
              }}
              size={14}
            />
            <span>{paymentDetailData.user.username}</span>
            <PaymentDetailStatus status={paymentDetailData.status} />
            {paymentDetailData.is_archive ? (
              <UnArchivePaymentDetailButton paymentDetailId={id} />
            ) : (
              <ArchivePaymentDetailButton paymentDetailId={id} />
            )}
          </div>
          {paymentDetailData.status === 'code-sent' && (
            <div className="text-neutral text-sm mt-2">
              Code sent at: {formatDate(paymentDetailData.created_at)}
            </div>
          )}
          <ChangePaymentDetailStatus paymentDetailData={paymentDetailData} />
          <div>
            <h3 className="mt-12 mb-8">Payment Details:</h3>
            <fieldset disabled={true}>
              <div className={classes.nameFields}>
                <Field label="First name">
                  <Input
                    label="First name"
                    placeholder="First name"
                    value={paymentDetailData.first_name}
                  />
                </Field>
                <Field label="Last name">
                  <Input
                    label="Last name"
                    placeholder="Last name"
                    value={paymentDetailData.last_name}
                  />
                </Field>
              </div>
              <Field label="Company name">
                <Input
                  label="Company name"
                  placeholder="Company name"
                  value={paymentDetailData.company_name}
                />
              </Field>
              <div className={classes.streetFields}>
                <Field label="Street address">
                  <Input
                    label="Street address"
                    placeholder="Street address"
                    value={paymentDetailData.street_address}
                  />
                </Field>
                <Field label="Street no">
                  <Input
                    label="Street no"
                    placeholder="Street no"
                    value={paymentDetailData.street_number}
                  />
                </Field>
              </div>
              <div className={classes.postalCityFields}>
                <Field label="Postal code">
                  <Input
                    label="Postal code"
                    placeholder="Postal code"
                    value={paymentDetailData.postal_code}
                  />
                </Field>
                <Field label="City">
                  <Input
                    label="City"
                    placeholder="City"
                    value={paymentDetailData.city}
                  />
                </Field>
              </div>
              <div className={classes.countryVatFields}>
                <Field label="Country">
                  <Input
                    label="Country"
                    placeholder="Country"
                    value={paymentDetailData.country}
                  />
                </Field>
                <Field label="VAT number">
                  <Input
                    label="VAT number"
                    placeholder="VAT number"
                    value={paymentDetailData.vat_number}
                  />
                </Field>
              </div>
              <Field label="ETH-address">
                <Input
                  label="ETH-address"
                  placeholder="ETH-address"
                  value={paymentDetailData.eth_address}
                />
              </Field>
            </fieldset>
            <h3 className="text-center">
              Code: {paymentDetailData.proof_code.match(/.{1,4}/g).join('-')}
            </h3>
          </div>
        </div>
      ) : !isError ? (
        <div className={classes.loading}>
          <Icon>
            <Spin />
          </Icon>
          <div>Loading...</div>
        </div>
      ) : null}
    </Drawer>
  )
}
