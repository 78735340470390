import { useState } from 'react'
import useMiniForm from 'core/useMiniForm'
import useQuery from 'core/react-query/useQuery'
import get from 'lodash/get'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/useMutation'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import Field from 'shared/components/form/field'
import Radio from 'shared/components/form/radio'
import Input from 'shared/components/form/input'
import deleteVideo from 'admin/queries/deleteVideo'
import getVideos from 'admin/queries/getVideos'
import getReasons from 'admin/queries/getReasons'

export default function DeleteVideoButton({ id }) {
  const { data, error, isError, loading, isLoading } = useQuery(getReasons, {
    variables: {
      key: 'video_delete_reasons',
    },
  })

  const reasons = get(data, 'json', [])

  const [step, setStep] = useState('')

  const form = useMiniForm({
    async onSubmit({ custom, reason }) {
      try {
        await mutation.mutateAsync({
          id,
          reason: reason >= 0 ? reasons[reason].key : custom,
        })
        notification.success({
          title: 'Video deleted successfully!',
        })
        setStep('')
        form.reset()
      } catch (error) {
        notification.error({
          title: error.message,
        })
      }
    },
  })

  const queryClient = useQueryClient()

  const mutation = useMutation(deleteVideo, {
    onSuccess() {
      queryClient.invalidateQueries({ query: getVideos })
    },
  })

  const handleShow = () => {
    setStep('confirm')
  }

  const handleCancel = () => {
    setStep('')
    form.reset()
  }

  const handleOk = async () => {
    if (step === 'confirm') {
      setStep('reason')
    } else {
      await form.submit()
    }
  }

  const canOk =
    step === 'confirm' ||
    form.get('reason') >= 0 ||
    (form.get('reason') === -1 && form.get('custom'))

  return (
    <>
      <Button onClick={handleShow} size="sm" outlined>
        Delete
      </Button>
      <Modal
        loading={mutation.isLoading}
        okButtonProps={{
          disabled: !canOk,
        }}
        okText="Delete Video"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Delete Video"
        visible={step !== ''}>
        {step === 'confirm' && (
          <p>Are you sure you want to delete this video?</p>
        )}
        {step === 'reason' && (
          <>
            <Field label="What's the reason for deleting this video?">
              {reasons.map((text, key) => (
                <Radio
                  checked={form.get('reason') === key}
                  onChange={() => form.change('reason', key)}
                  description={text.value}
                />
              ))}
              <Radio
                checked={form.get('reason') === -1}
                onChange={() => form.change('reason', -1)}
                description="Other..."
              />
            </Field>

            {form.get('reason') === -1 && (
              <Field>
                <Input
                  {...form.getInputPropsFor('custom')}
                  placeholder="Add your custom reason here..."
                  multiline
                />
              </Field>
            )}
          </>
        )}
      </Modal>
    </>
  )
}
