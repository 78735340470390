// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GoogleAuthenticator_modal__3ivGq {\n  width: 28rem;\n}\n\n.GoogleAuthenticator_wrapper__2GuvE figure {\n  border-color: var(--light-2);\n  border-radius: 0.5rem;\n  border-width: 1px;\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n  margin-left: auto;\n  margin-right: auto;\n  width: 256px;\n}\n\n.GoogleAuthenticator_wrapper__2GuvE figure img {\n  border-radius: 0.5rem;\n  display: block;\n  width: 100%;\n  aspect-ratio: 1/1;\n}\n\n.GoogleAuthenticator_key__29Rdo {\n  display: flex;\n  margin-bottom: 3rem;\n  gap: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/profile/components/GoogleAuthenticator.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;ECFA,4BAAmB;EAAnB,qBAAmB;EAAnB,iBAAmB;EAAnB,gBAAmB;EAAnB,mBAAmB;EAAnB,iBAAmB;EAAnB,kBAAmB;EDIjB,YAAY;AACd;;AAEA;ECPA,qBAAmB;EAAnB,cAAmB;EAAnB,WAAmB;EDSjB,iBAAiB;AACnB;;AAEA;ECZA,aAAmB;EAAnB,mBAAmB;EAAnB,WAAmB;ADcnB","sourcesContent":[".modal {\n  width: 28rem;\n}\n\n.wrapper figure {\n  @apply border border-light-2 mx-auto my-8 rounded-lg;\n  width: 256px;\n}\n\n.wrapper figure img {\n  @apply block rounded-lg w-full;\n  aspect-ratio: 1/1;\n}\n\n.key {\n  @apply flex gap-2 mb-12;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "GoogleAuthenticator_modal__3ivGq",
	"wrapper": "GoogleAuthenticator_wrapper__2GuvE",
	"key": "GoogleAuthenticator_key__29Rdo"
};
export default ___CSS_LOADER_EXPORT___;
