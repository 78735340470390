import dayjs from 'dayjs'

export default function getTimeFromNow(datetime) {
  const text = dayjs(datetime).fromNow()

  if (text.indexOf('a few') !== 0) {
    // "1 minute ago" instead of "a minute ago"
    if (text.indexOf('a ') === 0) {
      return text.replace(/^a /, '1 ')
    }

    // "1 hour ago" instead of "an hour ago"
    if (text.indexOf('an ') === 0) {
      return text.replace(/^an /, '1 ')
    }
  }

  return text
}
