import { ReactComponent as IntroSVG } from './intro.svg'
import { ReactComponent as PointsSVG } from './points.svg'
import { ReactComponent as HeroSVG } from './hero.svg'
import introBg from './intro-bg.png'
import classes from './index.module.css'

export default function HowItWorks() {
  return (
    <>
      <section
        className="bg-no-repeat bg-cover flex flex-col h-screen-minus-top-nav items-center justify-center text-center"
        style={{ backgroundImage: `url(${introBg})` }}>
        <h1 className="font-heading font-black leading-tight text-8xl">
          Create Great Content <br /> and Earn Crypto
        </h1>
        <IntroSVG className="h-96 mt-16" />
      </section>

      <section>
        <div className="container flex flex-row-reverse items-center mx-auto py-32 text-2xl">
          <PointsSVG className="ml-32 w-1/3" />
          <div className="w-2/3">
            <h2 className="font-heading mb-8 text-5xl">Points Per Action</h2>
            <p className="mb-8">
              Collect points from views, likes and subscribers!
            </p>
            <p className="mb-8">
              Your points then turns into a USD pegged stable coin and
              distributes directly to your ETH-wallet on a monthly basis.
            </p>
            <p className="mb-8">
              The points you get from your subscribers are not one-time points.
              If your channel has 100 subscribers, this would generate 100
              points every month as long as your subscribers are loyal. This
              way, you can build up your income over time.
            </p>
            <div className={classes.pointsStats}>
              <table className={classes.stats}>
                <tbody>
                  <tr>
                    <th>Video Views</th>
                    <td>
                      1 Point <br />
                      <small>Per 100 Views</small>
                    </td>
                  </tr>
                  <tr>
                    <th>Video Likes</th>
                    <td>50 Points</td>
                  </tr>
                  <tr>
                    <th>Subscribers</th>
                    <td>
                      1 Point <br />
                      <small>Per Subscriber</small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-900">
        <div className="container flex items-center mx-auto py-32 text-2xl text-white">
          <HeroSVG className="mr-32 w-1/3" />
          <div className="w-2/3">
            <h2 className="font-heading mb-8 text-5xl">Embrace your HODLs</h2>
            <p className="mb-8">
              The more of your followers/audience who are HODLs, the more you
              will earn!
            </p>
            <p className="mb-8">
              If a HODL member likes your video, you get three times the points
              and for each subscriber who is a HODL member, you get three times
              as many points continuously every month.
            </p>
            <p className="mb-8">
              This means that if you have 100,000 subscribers who are HERO
              members, you are guaranteed 300,000 points on them every month
              regardless. This way, you can build up your income over time.
            </p>
            <div className={classes.heroStats}>
              <table className={classes.stats}>
                <tbody>
                  <tr>
                    <th>Video Views</th>
                    <td>
                      1 Point <br />
                      <small>Per 100 Views</small>
                    </td>
                  </tr>
                  <tr>
                    <th>Video Likes</th>
                    <td>150 Points</td>
                  </tr>
                  <tr>
                    <th>Subscribers</th>
                    <td>
                      3 Points <br />
                      <small>Per Subscriber</small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
