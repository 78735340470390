import { useState } from 'react'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import Input from 'shared/components/form/input'
import Box from 'shared/components/kit/box'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import getChannel from 'admin/queries/getChannel'
import updateChannel from 'admin/queries/updateChannel'

export default function EditPoints({ channel }) {
  const [visible, setVisible] = useState(false)
  const [points, setPoints] = useState(channel.points || 0)

  const queryClient = useQueryClient()

  const mutation = useMutation(updateChannel, {
    onError: (error) => {
      notification.error({
        title: error.message,
      })
    },
    onMutate: ({ points }) => {
      queryClient.setQueryData({
        query: getChannel,
        variables: {
          id: channel.id,
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            points,
          },
        }),
      })
    },
    onSuccess: () => {
      notification.success({
        title: 'Changed channel points successfully!',
      })
    },
  })

  const handleCancel = () => {
    setVisible(false)
  }

  const handleOk = () => {
    setVisible(false)
    mutation.mutate({
      id: channel.id,
      points,
    })
  }

  return (
    <>
      <Box className="p-6">
        <h4 className="mb-2">Channel Points</h4>
        <p className="h-16 text-overlayColor-mutedText">
          In rare cases, you may want to edit channel points. Current
          Points:&nbsp;
          <b className="text-primaryColor-default">{channel.points}</b>
        </p>
        <footer className="flex justify-between">
          <span />
          <Button onClick={() => setVisible(true)} size="sm" outlined secondary>
            Edit Points
          </Button>
        </footer>
      </Box>

      <Modal
        okButtonProps={{
          disabled: !points,
        }}
        okText="Send"
        onOk={handleOk}
        onCancel={handleCancel}
        size="sm"
        title={`Edit Channel Points: ${channel.name}`}
        visible={visible}>
        <Input
          onChange={setPoints}
          placeholder="Change channel points..."
          type="number"
          value={points}
        />
      </Modal>
    </>
  )
}
