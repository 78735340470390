type SavePaymentDetailsVariables = {
  userId?: number,
  firstName?: string,
  lastName?: string,
  companyName?: string,
  streetAddress: string,
  streetNo: string,
  postalCode: string,
  city: string,
  country: string,
  vatNumber?: string,
  ethAddress?: string,
}

export default function savePaymentDetails(
  variables: SavePaymentDetailsVariables = {}
) {
  const {
    userId,
    firstName,
    lastName,
    companyName,
    streetAddress,
    streetNo,
    postalCode,
    city,
    country,
    vatNumber,
    ethAddress,
  } = variables

  return {
    method: 'POST',
    path: `/admin/users/${userId}/payment-details`,
    params: {
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
      street_address: streetAddress,
      street_number: streetNo,
      postal_code: postalCode,
      city: city,
      country: country,
      vat_number: vatNumber,
      eth_address: ethAddress,
    },
  }
}
