import getVideoThumbnail from 'helpers/utils/getVideoThumbnail'

export default function VideoThumbnail({ video }) {
  return (
    <img
      className="rounded-xl w-full"
      alt={video?.title}
      src={getVideoThumbnail(video)}
    />
  )
}
