import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import useMutation from 'core/react-query/useMutation'
import useMiniForm from 'core/useMiniForm'
import useQuery from 'core/react-query/useQuery'
import Field from 'shared/components/form/field'
import notification from 'shared/components/kit/notification'
import Loading from 'shared/components/kit/loading'
import Result from 'shared/components/kit/result'
import Button from 'shared/components/kit/button'
import verifyResetPasswordToken from 'shared/queries/verifyResetPasswordToken'
import ResetPassword from 'shared/queries/resetPassword'
import Password from 'shared/components/form/password'
import classes from './index.module.css'

const validation = {
  token: {
    label: 'Token',
  },
  password: {
    label: 'Password',
    rule: (value, values) => {
      if (values.password.length < 8) {
        return 'The password must be at least 8 characters.'
      }
    },
  },
  confirmPassword: {
    label: 'ConfirmPassword',
    rule: (value, values) => {
      if (values.password && values.password !== values.confirmPassword) {
        return "Passwords don't match!"
      }
    },
  },
}
export default function Index() {
  const params = useParams()
  const urlToken = params.token
  const { data, isLoading } = useQuery(verifyResetPasswordToken, {
    variables: {
      token: urlToken,
    },
  })

  const token = data ? data.json : {}

  const resetMutation = useMutation(ResetPassword, {
    onSuccess: () => {},
    onError: (error) => {
      notification.error({
        title: error.json.errors.password[0],
      })
    },
  })

  const form = useMiniForm({
    resetOnSuccess: true,
    validation,
    onSubmit(values, event) {
      try {
        resetMutation.mutate({
          token: urlToken,
          password: values.password,
        })
      } catch (error) {}
    },
  })

  if (isLoading) {
    return <Loading />
  }

  if (token.status !== 'ok') {
    return (
      <Result
        className="mt-48"
        title="Page Not Found"
        status="error"
        description="Sorry, the page you were looking for could not be found."
        footer={
          <Link to="/admin/login">
            <Button>Log In</Button>
          </Link>
        }
      />
    )
  }

  if (resetMutation.isSuccess) {
    return (
      <Result
        className="mt-48"
        title="Password Changed!"
        status="success"
        description="Your password has been changed successfully."
        footer={
          <Link to="/admin/login">
            <Button>Log In</Button>
          </Link>
        }
      />
    )
  }
  return (
    <div className={classes.wrapper}>
      <h1>Change Your Password</h1>
      <form onSubmit={form.submit}>
        <Field label="New password" {...form.getErrorPropsFor('password')}>
          <Password {...form.getInputPropsFor('password')} required />
        </Field>
        <Field
          label="Confirm new password"
          {...form.getErrorPropsFor('confirmPassword')}>
          <Password {...form.getInputPropsFor('confirmPassword')} required />
        </Field>
        <Button>Change My Password</Button>
      </form>
    </div>
  )
}
