type GetChannelQueryVariables = {
  id?: number,
  slug?: string,
}

export default function getChannels(variables: GetChannelQueryVariables = {}) {
  const { id, slug } = variables

  return {
    name: 'channel-single', // @TODO: Refactor this after refactoring myChannel query
    path: `/channels/${id || slug}`,
  }
}
