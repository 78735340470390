import { useHistory, useParams } from 'react-router'
import useQuery from 'core/react-query/useQuery'
import get from 'lodash/get'
import Tabs from 'shared/components/kit/tabs'
import Page from 'publisher/components/page'
import getCommentsStats from 'publisher/queries/getCommentsStats'
import AllComments from './all-comments'
import RememberedComments from './remembered-comments'
import MentionedComments from './mentioned-comments'

export default function Comments() {
  const { activeTab } = useParams()
  const history = useHistory()
  const { data } = useQuery(getCommentsStats)
  const { remembers, unread_mentions } = get(data, 'json', {
    remembers: 0,
    unread_mentions: 0,
  })
  const handleTabChange = (val) => {
    history.push(
      `/publisher/comments/${val !== 'mentioned' ? val : val + '?unread=false'}`
    )
  }

  return (
    <Page width="lg">
      <Tabs activeName={activeTab} onTabChange={handleTabChange}>
        <Tabs.Tab name="all" title="All Comments">
          <AllComments />
        </Tabs.Tab>
        <Tabs.Tab
          name="remembered"
          title={`Remembered Comments (${remembers})`}>
          <RememberedComments />
        </Tabs.Tab>
        <Tabs.Tab
          name="mentioned"
          title={`Mentioned Comments (${unread_mentions})`}>
          <MentionedComments />
        </Tabs.Tab>
      </Tabs>
    </Page>
  )
}
