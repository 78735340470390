type ArchiveMessageMutationVariables = {
  id: number,
}

export default function archiveMessage(
  variables: ArchiveMessageMutationVariables
) {
  return {
    method: 'put',
    url: `/admin/messages/${variables.id}/close`,
    variables,
  }
}
