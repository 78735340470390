import { useHistory, useParams } from 'react-router'
import Alert from 'shared/components/kit/alert'
import useChannel from 'admin/hooks/useChannel'
import Summary from './components/Summary'
import FreezeChannel from './components/FreezeChannel'
import SendMessage from './components/SendMessage'
import EditPoints from './components/EditPoints'
import EditChannelDrawer from './components/EditChannelDrawer'
import EditChannel from './components/EditChannel'
import YoutubeImportRequest from './components/YoutubeImportRequest'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import ChannelSubscribers from './components/ChannelSubscribers'
import ChannelStatistics from './components/ChannelStatistics'
import ChannelPoints from './components/ChannelPoints'
import ChangeChannelStatus from './components/ChangeChannelStatus'
import VideoUploads from './components/Uploads'
import ManagePublisher from './components/ManagePublisher'
import ManagePublisherDrawer from './components/ManagePublisherDrawer'
import Page from 'admin/components/page'
import classes from './index.module.css'

export default function ChannelOverview() {
  const { id, section } = useParams()
  const channelId = Number(id)
  const history = useHistory()
  const searchParams = new URLSearchParams(history.location.search)
  const userId = searchParams.get('user-id')

  const { data, error, isError, isLoading } = useChannel(channelId)

  const handleCloseEditDrawer = () => {
    history.push(`/admin/channels/${id}`)
  }

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <Icon>
          <Spin />
        </Icon>
        <div>Loading...</div>
      </div>
    )
  }

  if (isError) {
    return <Alert title={error.json.message} type="error" />
  }

  const channel = data.json

  const handleCloseUserDrawer = () => {
    history.push(`/admin/channels/${channel.id}`)
  }

  return (
    <Page title="Overview">
      <Summary channel={channel} />
      <div className={classes.charts}>
        <ChannelSubscribers channelId={channelId} />
        <ChannelStatistics channelId={channelId} />
        <ChannelPoints channelId={channelId} />
        <VideoUploads channelId={channelId} />
      </div>
      <div className="grid grid-cols-3 gap-8">
        <EditChannel channel={channel} />
        <ManagePublisher channel={channel} />
        <SendMessage channel={channel} />
        <FreezeChannel channel={channel} />
        <EditPoints channel={channel} />
        <YoutubeImportRequest channel={channel} />
        <ChangeChannelStatus channel={channel} />
      </div>
      <EditChannelDrawer
        channel={channel}
        onClose={handleCloseEditDrawer}
        visible={section === 'edit'}
      />

      <ManagePublisherDrawer
        userId={channel.user_id}
        onClose={handleCloseUserDrawer}
        visible={!!userId}
      />
    </Page>
  )
}
