import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import Box from 'shared/components/kit/box'
import readableNumber from 'shared/helpers/readableNumber'
import getVideoStatistics from 'publisher/queries/getVideoStatistics'
import classes from './Summary.module.css'

export default function Summary({ videoId }) {
  const { data, isError, isLoading } = useQuery(getVideoStatistics, {
    keepPreviousData: true,
    variables: {
      id: videoId,
    },
  })

  const views = readableNumber(get(data, 'json.overview.views_total') || 0)
  const points = readableNumber(get(data, 'json.overview.points') || 0)
  const likes = readableNumber(get(data, 'json.overview.likes_total') || 0)
  const dislikes = readableNumber(
    get(data, 'json.overview.dislikes_total') || 0
  )
  const watchTime = readableNumber(
    (get(data, 'json.overview.watch_time_total') || 0) / 3600
  )

  return (
    <Box className={classes.wrapper}>
      <ul>
        <SummaryItem
          error={isError}
          loading={isLoading}
          title="Points"
          value={Math.floor(points)}
        />
        <SummaryItem
          error={isError}
          loading={isLoading}
          title="Watch Hours"
          value={watchTime}
        />
        <SummaryItem
          error={isError}
          loading={isLoading}
          title="Views"
          value={views}
        />
        <SummaryItem
          error={isError}
          loading={isLoading}
          title="Likes"
          value={likes}
        />
        <SummaryItem
          error={isError}
          loading={isLoading}
          title="Dislikes"
          value={dislikes}
        />
      </ul>
    </Box>
  )
}

function SummaryItem({ loading, title, value }) {
  return (
    <li>
      <span>{loading ? '...' : value}</span>
      <small>Total {title}</small>
    </li>
  )
}
