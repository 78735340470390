import classnames from 'classnames'
import classes from './index.module.css'

export default function index() {
  return (
    <div className={classes.wrapper}>
      <div className={classes.intro}>
        <h1>Publisher Terms & Conditions - Today’s Crypto </h1>
        <p className="italic">Last updated: April 1st, 2023 </p>
      </div>
      <h2>Introduction</h2>
      <p>
        Welcome to Today’s Crypto. These are the Terms governing the use of the
        Service available to You as a publisher and the agreement that operates
        between You and Today’s Crypto. These Terms set out the rights and
        obligations of all Publishers on the Service.
      </p>
      <p>
        Your access and use of the Service are subject to these Publisher Terms
        & Conditions, Our Terms of Service, and Privacy Policy collectively
        referred to as the “Terms”. Please read these Terms carefully before
        accessing and using the Service. By accessing and using the Service You
        agree to be bound by these Terms. If You disagree with any part of the
        Terms, or if You do not understand them, then You may not access the
        Service.
      </p>
      <p>
        By using the Service on behalf of a company or organization, You
        represent and warrant that You have the authority to act on behalf of
        that entity, grant all permissions and licenses described in these
        Terms, and bind the entity to these Terms.
      </p>
      <p>
        <h3>Age requirement for Private individuals</h3>
        If You are accessing the Publisher Service as a Private individual, You
        must be over the age of 18.
      </p>

      <h2>Interpretation and Definitions</h2>
      <p>
        <h3>Interpretation</h3>
        The words in which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plurals.
      </p>

      <p>
        <h3>Definitions</h3>
        For the purposes of this Terms of Service document:
        <ul className={classes.bullet}>
          <li>
            <b>Account</b> means a unique account created for You to access our
            Service or parts of our Service.
          </li>
          <li>
            <b>Affiliate</b> means an entity that controls, is controlled by, or
            is under common control with a party, where “control” means
            ownership of 50% or more of the shares, equity interest, or other
            securities entitled to vote for the election of directors or other
            managing authority.
          </li>
          <li>
            <b>Company</b> (referred to as either “Today’s Crypto”, “We”, “Us”
            or “Our” in this Agreement) refers to BlockBeast AB, Transportvägen
            12, 246 42, Löddeköpinge, Sweden.
          </li>
          <li>
            <b>Service</b> refers to the Website.
          </li>
          <li>
            <b>Third-party</b> means any services or content (including data,
            information, products, or services) provided by a third party that
            may be displayed, included, or made available by the Service.
          </li>
          <li>
            <b>Website</b> refers to Today’s Crypto, accessible from{' '}
            <a target="_blank" href="https://todayscrypto.com">
              <span className="text-blue-400 underline">
                www.todayscrypto.com
              </span>
            </a>
          </li>
          <li>
            <b>You</b> mean the individual accessing or using the Service, or
            the company, or other legal entity on behalf of which such
            individual is accessing or using the Service, as applicable.
          </li>
        </ul>
      </p>
      <h2>About the Service</h2>
      <p>
        Today´s Crypto is created for the Crypto Currency enthusiast. Our
        mission is to create a safe, scam-free, easy-to-use, streaming service
        for everything Crypto.
      </p>

      <p>
        <h3>What is Today’s Crypto?</h3>
        Today´s Crypto is an entertainment and education platform to discover,
        watch, and engage in conversation on Crypto-related videos, podcasts,
        etc. among a global community. The Service functions as a distribution
        platform for original content by independent content creators,
        businesses, and advertisers of various sizes. We believe that trust is
        key, so to ensure that Today’s Crypto is a safe space for our users to
        consume content, all channels must go through a manual application
        process before setting up their channel and start publishing content.{' '}
      </p>
      <h2>Your use of the Service</h2>
      <p>
        <h3>Access to the service</h3>
        Access to the Publisher Service is limited to approved Publishers on
        Today´s Crypto. To gain access to the Publisher Service You are required
        to apply for a Publisher Account. As a Publisher, You will also gain
        access to and be able to represent Your channel (with limited
        functionality) through the user interface.
      </p>
      <h2>Publisher Account</h2>
      <p>
        <h3>Account application</h3>
        Applications for Publisher Accounts are to be submitted through Our
        Website. Applications are then reviewed manually in the order that they
        have been submitted. If Your application has been approved or rejected,
        or if we require any additional information from You to process the
        application, we will contact You through the email provided in the
        application.
      </p>
      <p>We reserve the right to reject your application if:</p>
      <ol className={classes.lowerLatin}>
        <li>
          We are unable to ensure the intention or purpose of Your channel or;
        </li>
        <li>
          We are unable to verify the authenticity and ownership of Your
          channel, for example, in cases where a channel already exists on other
          media platforms or;
        </li>
        <li>
          We are required to do so to comply with a legal requirement or a court
          order or;
        </li>
        <li>
          We reasonably believe there is a risk of conduct that may create
          liability or harm to any user, other third party, Today’s Crypto, our
          Affiliates or;
        </li>
        <li>
          We find that Your Channel, in any way, does not meet the terms set out
          in this agreement.
        </li>
      </ol>
      <p>
        <h3>Uploading Content</h3>
        As a Publisher on Today´s Crypto, You will be able to upload Content to
        Your Channel. You may use your Content to promote Your business or
        artistic enterprise. If You decide to upload Content to Your Channel,
        You are obligated to ensure that it complies with this Agreement and/or
        the law. For example, the Content You upload must not include
        third-party intellectual property (such as copyrighted material) unless
        You have permission from that party or are otherwise legally entitled to
        do so. Please note that You are legally responsible for the Content You
        upload to Today’s Crypto, and we may use automated systems to analyze
        and help detect infringement and abuse, such as spam, malware, and
        illegal/non-allowed content.
      </p>
      <p>
        <h3>Rights you Grant</h3>
        You will retain the ownership rights to your Content, what is yours
        stays yours. However, by uploading Content to Your Channel You grant
        Today’s Crypto and other users of the Service certain rights, as
        described below.
      </p>
      <p>
        <h3>License to Today’s Crypto</h3>
        By uploading Content to Your Channel, You grant Today’s Crypto a
        worldwide, non-exclusive, royalty-free, transferable, sub-licensable
        license to use that Content (including to reproduce, distribute, modify,
        display, and perform it) for the purpose of operating, promoting, and
        improving the Service.
      </p>
      <p>
        <h3>Duration of Licence</h3>
        The licenses You grant are valid until the uploaded Content is removed
        from Today’s Crypto as described in the section below. Once the Content
        has been removed, the licenses will terminate, except if the operation
        of the Service, use of the Content permitted before Your removal, or the
        law requires otherwise. For example, removal of Content by You does not
        require Today’s Crypto to:
      </p>
      <ol className={classes.lowerLatin}>
        <li>
          recall Content that is being used by other users within any limited
          offline viewing functionality of the Service or;
        </li>
        <li>delete copies we reasonably need to keep for legal purposes.</li>
      </ol>
      <p>
        <h3>Removing Your Content</h3>
        You may remove Your Content from Today’s Crypto at any time. You must
        remove Your Content if You no longer have the rights required by these
        terms.
      </p>
      <p>
        <h3>Removal of Content By Today’s Crypto</h3>
        If we reasonably believe that any Content is in violation of this
        Agreement or may cause harm to Today’s Crypto, our users, or third
        parties, we may remove or take down some or all of such Content. We will
        notify you of the reason for our action unless we reasonably believe
        that to do so:
      </p>
      <ol className={classes.lowerLatin}>
        <li>
          would violate the law or the direction of a legal enforcement
          authority, or would otherwise risk legal liability for Today’s Crypto
          or our Affiliates;
        </li>
        <li>
          would compromise an investigation or the integrity or operation of the
          Service or;
        </li>
        <li>
          would cause harm to any user, other third parties, Today’s Crypto, or
          our Affiliates.
        </li>
      </ol>
      <p>
        <h3>Copyright Protection</h3>
        If You believe Your copyright has been infringed on the Service, please
        contact Us. We review any claims manually and might ask you for
        additional information regarding the Content before any removal can take
        place.
      </p>
      <h2>Monetize program</h2>
      <p>
        As a publisher, You will have the possibility to get compensated through
        Our Monetize Program for the content you publish on your channel. Your
        monthly compensation will be determined by the performance of Your
        Content, Your active follower base in relation to the other publisher’s
        performance on Today’s Crypto.
      </p>
      <p>
        Please note that Your Channel will not be included in the monthly
        distribution or start generating points until You have qualified for the
        program.
      </p>
      <p>
        <h3>Qualification</h3>
        The following are the minimum requirements to qualify for the Monetize
        Program and take part in the monthly distribution, once the requirements
        have been met You will unlock the monetize page and Your channel will be
        ready to start generating points:
      </p>
      <ul className={classes.bullet}>
        <li>500 Subscribers</li>
        <li>2000 Watch hours</li>
      </ul>
      <p>
        <h3>How points are awarded</h3>
        Points are awarded based on the performance of the Your Content (Views &
        Likes), and the size of Your active follower base. Views, Likes, and
        Subscriptions by Hodl Members are worth twice the number of points as of
        regular users. Points are awarded as follows:
      </p>
      <ul className={classes.dash}>
        <li>50 points/Like</li>
        <li>1 Point/10 Views</li>
        <li>1 Point/Active subscriber/Month</li>
      </ul>
      <p>An Active follower is a user who regularly uses the service.</p>
      <p>
        <h3>How points value is determined</h3>
        The value of a point is determined by the amount to distribute in
        relation to the points generated by all Channels under a given month,
        the point value will therefore vary. After each month’s closing, when
        the total number of points generated has been established, the point
        value is calculated by the amount to distribute divided by the total
        points. For example; The total amount to be distributed for June is
        1.000 USDC.
      </p>
      <ul className={classes.dash}>
        <li>Channel A managed to generate 1000 points.</li>
        <li>Channel B managed to generate 700 points.</li>
        <li>Channel C managed to generate 300 points.</li>
      </ul>
      <p>
        The total points generated on the platform in June was 2000 points. We
        then divide the amount to be distributed for June (1000 USDC) by the
        total number of points generated in June (2000) giving us a value of 0.5
        USDC/point. The payout for June would therefore be:
      </p>
      <ul className={classes.dash}>
        <li>Channel A would be paid 500 USDC.</li>
        <li>Channel B would be paid 350 USDC.</li>
        <li>Channel C would be paid 150 USDC.</li>
      </ul>
      <p>
        <h3>How the amount to distribute is determined</h3>
        The amount to distribute each month is set by the total advertising and
        Hodl Membership revenues (30%) of the month before. The rest of the
        revenues will cover the costs of operation, development, and marketing.
      </p>
      <h2>Account Suspension and Termination</h2>
      <p>
        <h3>Terminations by You</h3>
        You may stop using the Service at any time. You may also delete Your
        Publisher Account and any Content uploaded by You.{' '}
      </p>
      <p>
        <h3>Suspension and Termination by Us</h3>
        Today’s Crypto may suspend or terminate Your access and/or Your
        account’s access to all or part of the Service if:{' '}
      </p>
      <ol className={classnames(classes.lowerLatin, 'ml-24')}>
        <li>You materially or repeatedly violate any part of these terms;</li>
        <li>
          We are required to do so to comply with a legal requirement or a court
          order; or
        </li>
        <li>
          We reasonably believe there has been conduct that creates liability or
          harm to any user, other third party, Today’s Crypto or our Affiliates.
        </li>
      </ol>
      <h2>Changes to The Publisher Terms & Conditions</h2>
      <p>
        We reserve the right, at Our sole discretion, to modify or replace these
        Terms at any time. If a revision is material We will make reasonable
        efforts to provide at least 30 days notice prior to any new terms taking
        effect. What constitutes a material change will be determined at Our
        sole discretion.
      </p>
      <p>
        By continuing to access or use Our Service after those revisions become
        effective, You agree to be bound by the revised terms. If You do not
        agree to the new terms, in whole or in part, please stop using the
        website and the Service.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about these Terms, You can contact us: <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; By using
        our contact form on our website:{' '}
        <a target="_blank" href="https://todayscrypto.com">
          <span className="text-blue-400">todayscrypto.com</span>
        </a>
      </p>
    </div>
  )
}
