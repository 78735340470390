import { useRef, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import Box from 'shared/components/kit/box'
import Pagination from 'shared/components/kit/pagination'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import RichText from 'shared/components/form/rich-text'
import Field from 'shared/components/form/field'
import Switch from 'shared/components/form/switch'
import notification from 'shared/components/kit/notification'
import getComments from 'publisher/queries/getComments'
import pinCommentToVideos from 'publisher/queries/pinCommentToVideos'
import likeComment from 'publisher/queries/likeComment'
import dislikeComment from 'publisher/queries/dislikeComment'
import Comments from './Comments'
import Replies from './Replies'
import PinnedComment from './PinnedComment'
import classes from './Main.module.css'

export default function Main(props) {
  const {
    comments,
    isLoading,
    onCloseDrawer,
    onSetSelectedComment,
    selectedComment,
    currentPage,
    lastPage,
    onPaginate,
    onUnrememberedAllComments,
    onMarkAllAsRead,
    onlyShowMentions,
    onChangeShowOnlyMentions,
  } = props

  const [visiblePin, setVisiblePin] = useState()
  const [visibleUnpin, setVisibleUnpin] = useState()
  const [visibleDeleteComment, setVisibleDeleteComment] = useState()
  const queryClient = useQueryClient()
  const [visible, setVisible] = useState()
  const [text, setText] = useState()
  const wrapperRef = useRef()

  const parsedLocation = useParsedLocation()

  const isAllCommentsTab = parsedLocation.pathname.endsWith(
    'publisher/comments/all'
  )

  const isRememberedTab = parsedLocation.pathname.endsWith(
    'publisher/comments/remembered'
  )

  const isMentionedTab = parsedLocation.pathname.endsWith(
    'publisher/comments/mentioned'
  )

  const { sort, video, time, perPage } = parsedLocation.queryParams

  const { data: selectedVideosCommentsData } = useQuery(getComments, {
    enabled: !!video && isAllCommentsTab,
    variables: {
      perPage: 1,
      video: Number(video),
    },
  })
  const selectedVideosComments = selectedVideosCommentsData?.json?.data || []
  const pinnedComment = selectedVideosComments.find(
    (_comment) => _comment.is_pinned
  )
  const disabledMarkAll =
    comments.length === 0 ||
    comments.find((comment) => {
      return comment.is_read_replies === false
    }) === undefined

  const like = useMutation(likeComment, {
    onMutate: () => {
      const snapshotOfPreviousComment = queryClient.getQueryData({
        query: getComments,
        variables: {
          perPage: 1,
          video: Number(video),
        },
      })
      queryClient.setQueryData({
        query: getComments,
        variables: {
          perPage: 1,
          video: Number(video),
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            data: prev.json.data.map((item) => {
              if (item.id === pinnedComment.id) {
                return {
                  ...item,
                  dislikes_count: item.is_disliked
                    ? item.dislikes_count - 1
                    : item.dislikes_count,
                  is_disliked: false,
                  is_liked: !item.is_liked,
                  likes_count: item.likes_count + (item.is_liked ? -1 : 1),
                }
              }
              return item
            }),
          },
        }),
      })

      return {
        snapshotOfPreviousComment,
      }
    },
    onError: (error, res, { snapshotOfPreviousComment }) => {
      queryClient.setQueryData({
        query: getComments,
        variables: {
          perPage: 1,
          video: Number(video),
        },
        updater: (prev) => ({
          ...snapshotOfPreviousComment,
        }),
      })
    },
  })

  const dislike = useMutation(dislikeComment, {
    onMutate: () => {
      const snapshotOfPreviousComment = queryClient.getQueryData({
        query: getComments,
        variables: {
          perPage: 1,
          video: Number(video),
        },
      })

      queryClient.setQueryData({
        query: getComments,
        variables: {
          perPage: 1,
          video: Number(video),
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            data: prev.json.data.map((item) => {
              if (item.id === pinnedComment.id) {
                return {
                  ...item,
                  dislikes_count:
                    item.dislikes_count + (item.is_disliked ? -1 : 1),
                  is_disliked: !item.is_disliked,
                  is_liked: false,
                  likes_count: item.is_liked
                    ? item.likes_count - 1
                    : item.likes_count,
                }
              }
              return item
            }),
          },
        }),
      })
      return {
        snapshotOfPreviousComment,
      }
    },
    onError: (error, res, { snapshotOfPreviousComment }) => {
      queryClient.setQueryData({
        query: getComments,
        variables: {
          perPage: 1,
          video: Number(video),
        },
        updater: (prev) => ({
          ...snapshotOfPreviousComment,
        }),
      })
    },
  })

  const pinMutation = useMutation(pinCommentToVideos, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getComments,
      })
      setVisible(false)
      setText('')
      // notification.success({
      //   title: 'Comment successfully created and pinned to the selected video.',
      // })
    },
  })

  const handleToggleLike = (commentId) => {
    like.mutate(
      { id: commentId },
      {
        onError(error) {
          queryClient.setQueryData({
            query: getComments,
            variables: {
              perPage: 1,
              video: Number(video),
            },
            updater: (prev) => ({
              ...prev,
              json: {
                ...prev.json,
                data: prev.json.data.map((item) => {
                  if (item.id === commentId) {
                    return {
                      ...item,
                      dislikes_count: item.is_disliked
                        ? item.dislikes_count + 1
                        : item.dislikes_count,
                      is_disliked: false,
                      is_liked: !item.is_liked,
                      likes_count: item.likes_count + (item.is_liked ? +1 : 1),
                    }
                  }
                  return item
                }),
              },
            }),
          })

          notification.error({ title: error.message })
        },
      }
    )
  }

  const handleToggleDislike = (commentId) => {
    dislike.mutate(
      { id: commentId },
      {
        onError(error) {
          queryClient.setQueryData({
            query: getComments,
            variables: {
              perPage: 1,
              video: Number(video),
            },
            updater: (prev) => ({
              ...prev,
              json: {
                ...prev.json,
                data: prev.json.data.map((item) => {
                  if (item.id === pinnedComment.id) {
                    return {
                      ...item,
                      dislikes_count:
                        item.dislikes_count + (item.is_disliked ? +1 : 1),
                      is_disliked: !item.is_disliked,
                      is_liked: false,
                      likes_count: item.is_liked
                        ? item.likes_count + 1
                        : item.likes_count,
                    }
                  }
                  return item
                }),
              },
            }),
          })

          notification.error({ title: error.message })
        },
        onSuccess(response) {},
      }
    )
  }

  const handleCancel = () => {
    setVisible(false)
    setText('')
  }

  const handleOk = () => {
    try {
      pinMutation.mutate({
        text,
        videos: video,
      })
    } catch (error) {
      notification.error({ title: error.message })
      setVisible(false)
      setText('')
    }
  }

  const handleSelectComment = (comment) => {
    onSetSelectedComment(comment)
  }

  const handleCloseParent = () => {
    onSetSelectedComment(null)
  }

  const showModal = () => {
    setVisible(true)
  }

  const showModalPin = () => {
    setVisiblePin(true)
  }

  const showModalUnpin = () => {
    setVisibleUnpin(true)
  }

  const showModalDelete = () => {
    setVisibleDeleteComment(true)
  }

  return (
    <>
      {isAllCommentsTab && video && comments && !pinnedComment && (
        <div className={classes.noPinned}>
          <span>There is no pinned comment on this video/podcast.</span>
          <Button onClick={showModal} mood="secondary">
            Write new
          </Button>
        </div>
      )}
      {isAllCommentsTab && video && pinnedComment && (
        <div className="w-3/5">
          <span className="block text-sm mb-2">
            Pinned comment for: {pinnedComment.video.title}
          </span>
          <PinnedComment
            comment={pinnedComment}
            onLike={() => handleToggleLike(pinnedComment.id)}
            onDislike={() => handleToggleDislike(pinnedComment.id)}
            onSelectComment={handleSelectComment}
            onSetVisiblePin={setVisiblePin}
            onSetVisibleUnpin={setVisibleUnpin}
            onSetVisibleDeleteComment={setVisibleDeleteComment}
            visiblePin={visiblePin}
            visibleUnpin={visibleUnpin}
            visibleDeleteComment={visibleDeleteComment}
            selectedComment={selectedComment}
            showReplies
            summary
          />
          <div className={classes.actions}>
            <Button onClick={showModalPin} mood="secondary">
              {' '}
              Write new
            </Button>
            <Button onClick={showModalUnpin} mood="secondary">
              {' '}
              Unpin
            </Button>
            {pinnedComment.user.id === pinnedComment.video.user_id && (
              <Button onClick={showModalDelete}>Delete</Button>
            )}
          </div>
        </div>
      )}

      {isRememberedTab && (
        <div className={classes.clear}>
          <Button
            disabled={comments.length === 0}
            onClick={onUnrememberedAllComments}
            transparent
            mood="neutral">
            Clear all remembered comments
          </Button>
        </div>
      )}

      {isMentionedTab && (
        <>
          <Switch
            className="-mb-10"
            description="Only show unread mentions"
            checked={onlyShowMentions}
            onChange={onChangeShowOnlyMentions}
          />
          <div className={classes.clear}>
            <Button
              disabled={disabledMarkAll}
              onClick={onMarkAllAsRead}
              transparent
              mood="neutral">
              Mark all as read
            </Button>
          </div>
        </>
      )}
      <div className={classes.wrapper}>
        <div className={classes.comments}>
          <Box loading={isLoading} ref={wrapperRef}>
            <Comments
              comments={comments}
              onSelectComment={handleSelectComment}
              selectedComment={selectedComment}
              onCloseParent={handleCloseParent}
            />
          </Box>
          {lastPage > 1 && (
            <div className={classes.pagination}>
              <Pagination
                current={currentPage}
                last={lastPage}
                onPaginate={onPaginate}
              />
            </div>
          )}
        </div>

        <div className={classes.comment}>
          <Replies commentId={selectedComment?.id} onClose={onCloseDrawer} />
        </div>
      </div>
      <Modal
        title="Pin Comment"
        visible={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        okButtonProps={{
          disabled: !text,
        }}
        okText="Create"
        size="md"
        submitting={pinMutation.loading}>
        <Field label="Add your comment below and press create to post. You can later Unpin, delete or replace your pinned comment at any time.">
          <RichText
            value={text}
            onChange={setText}
            placeholder="Add your comment here..."
            multiline
          />
        </Field>
      </Modal>
    </>
  )
}
