// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".channel-avatar_wrapper__3Wb4H {\n  position: relative;\n}\n\n.channel-avatar_wrapper__3Wb4H img {\n  height: 100%;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/channel-avatar/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,kBAAmB;ADAnB;;AAEA;ECFA,YAAmB;EAAnB,WAAmB;ADInB","sourcesContent":[".wrapper {\n  @apply relative;\n}\n\n.wrapper img {\n  @apply h-full w-full;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "channel-avatar_wrapper__3Wb4H"
};
export default ___CSS_LOADER_EXPORT___;
