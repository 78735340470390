import cookie from 'core/cookie'
import fetcher from 'core/fetcher'

export default function useFetcher() {
  return async (url, options = {}) =>
    await fetcher(url, {
      jwt: cookie().get('jwt'),
      ...options,
    })
}
