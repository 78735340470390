import Modal from 'shared/components/kit/modal'
import Button from 'shared/components/kit/button'
import classes from './index.module.css'
import formatPrice from 'helpers/utils/formatPrice'

export default function MonetizationEarningShare({ amount, visible, onClose }) {
  const downloadImage = () => {
    const img = new Image()
    img.crossOrigin = 'anonymous' // This tells the browser to request cross-origin access when trying to download the image data.
    // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#Implementing_the_save_feature
    img.src = `https://cl-dev.todayscrypto.com/api/monetization/generate-image?amount=${formatPrice(
      amount,
      null,
      1
    )}`
    img.onload = () => {
      // create Canvas
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)
      // create a tag
      const a = document.createElement('a')
      a.download = 'download.png'
      a.href = canvas.toDataURL('image/png')
      a.click()
    }
  }
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closeIconVisible={false}
      footer={null}
      header={null}
      size="sm">
      <div className={classes.wrapper}>
        <section>
          <img
            src={`https://cl-dev.todayscrypto.com/api/monetization/generate-image?amount=${formatPrice(
              amount,
              null,
              1
            )}`}
          />
        </section>
        <Button block onClick={downloadImage} className="mt-4">
          Download Image
        </Button>
      </div>
    </Modal>
  )
}
