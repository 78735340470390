import useQuery from 'core/react-query/useQuery'
import getProfile2FA from 'shared/queries/getProfile2FA'

export default function useProfile2FA() {
  const { data, ...rest } = useQuery(getProfile2FA, {
    staleTime: 5 * 60 * 1000,
    usePreviousData: true,
  })

  return {
    ...rest,
    appEnabled: data?.json?.app_enable,
    appType: data?.json?.app_type,
    emailEnabled: data?.json?.email_enable,
  }
}
