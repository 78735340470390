import { useState } from 'react'
import useMutation from 'core/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import acceptPublisherRequest from 'admin/queries/acceptPublisherRequest'
import getPublisherRequests from 'admin/queries/getPublisherRequests'

export default function AcceptRequestButton({ userId, disabled }) {
  const [visible, setVisible] = useState(false)

  const queryClient = useQueryClient()

  const mutation = useMutation(acceptPublisherRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries({ query: getPublisherRequests })
    },
  })

  const handleShow = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleOk = async () => {
    try {
      await mutation.mutateAsync({
        id: userId,
      })
      setVisible(false)
      notification.success({ title: 'Publisher request accepted!' })
    } catch (error) {
      setVisible(false)
      notification.error({ title: error.message })
    }
  }

  return (
    <>
      <Button
        title={disabled && 'This request has already confirmed!'}
        disabled={disabled}
        onClick={handleShow}
        size="sm"
        outlined>
        Confirm
      </Button>
      <Modal
        loading={mutation.isLoading}
        okText="Accept Request"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Accept Publisher Request"
        visible={visible}>
        <p>Are you sure you want to accept this publisher request?</p>
      </Modal>
    </>
  )
}
