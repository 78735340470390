import Tag from 'shared/components/kit/tag'
import classnames from 'classnames'

const STATUSES = {
  new_by_user: {
    color: 'bg-blue-400',
    label: 'New',
  },
  new_by_admin: {
    color: 'bg-gray-400',
    label: 'New by admin',
  },
  seen: {
    color: 'bg-green-400',
    label: 'Seen',
  },
  close: {
    color: 'bg-red-400',
    label: 'Close',
  },
  replied_by_admin: {
    color: 'bg-gray-400',
    label: 'Replied by admin',
  },
  replied_by_user: {
    color: 'bg-blue-400',
    label: 'Replied by user',
  },
}

export default function MessageStatus({ status, className }) {
  const data = STATUSES[status]
  return <Tag type={classnames(data.color, className)}>{data.label}</Tag>
}
