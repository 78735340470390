import { useState } from 'react'
import useFetcher from 'core/useFetcher'
import Modal from 'shared/components/kit/modal'
import Field from 'shared/components/form/field'
import Radio from 'shared/components/form/radio'
import PinnedComment from './PinnedComment'
import classes from './PinButton.module.css'

export default function PinButton(props) {
  const {
    className,
    comment,
    children,
    currentlyPinnedAction,
    onPin,
    onSetCurrentlyPinnedAction,
  } = props

  const fetcher = useFetcher()
  const [visible, setVisible] = useState(false)
  const [pinnedComment, setPinnedComment] = useState(null)
  const handleCancel = () => {
    onSetCurrentlyPinnedAction('Unpin')
    setVisible(false)
  }

  const handleClick = (event) => {
    event.stopPropagation()
    fetcher(`/publisher/comments?filters[videos]=${comment.video.id}`).then(
      (response) => {
        setPinnedComment(response.json.data.find((itm) => itm.is_pinned))
        if (
          response.json.data.find((itm) => itm.is_pinned) &&
          response.json.data.find((itm) => itm.is_pinned).id !== comment.id &&
          !visible
        ) {
          setVisible(true)
        } else {
          onPin()
          setVisible(false)
        }
      }
    )
  }

  const handleOk = () => {
    onPin(pinnedComment)
    setVisible(false)
  }

  return (
    <>
      <button className={className} onClick={handleClick}>
        {children}
      </button>
      <Modal
        title="Pin Comment"
        okText="Confirm"
        onOk={handleOk}
        onCancel={handleCancel}
        visible={visible}
        size="md">
        <span className="block mb-4">
          There is already a pinned comment for: {pinnedComment?.video?.title}
        </span>
        {pinnedComment && (
          <>
            <div className="relative">
              <div className={classes.container}>
                <PinnedComment
                  comment={pinnedComment}
                  onLike={() => {}}
                  onDislike={() => {}}
                  onSelectComment={() => {}}
                  selectedComment={null}
                  showReplies
                  summary
                />
              </div>
            </div>
            {pinnedComment.user.id === pinnedComment.video.user_id && (
              <Field
                className="mt-8"
                label="What do you want to do with the comment currently pinned?">
                <Radio
                  checked={currentlyPinnedAction === 'Unpin'}
                  onChange={() => onSetCurrentlyPinnedAction('Unpin')}
                  description="Unpin (conversion to a regular comment)"
                />
                <Radio
                  checked={currentlyPinnedAction === 'UnpinDelete'}
                  onChange={() => onSetCurrentlyPinnedAction('UnpinDelete')}
                  description="Unpin and delete (removes comment and any replies permanently)"
                />
              </Field>
            )}
            {pinnedComment.user.id !== pinnedComment.video.user_id && (
              <>
                <p className="mt-6">
                  Are you sure you want to unpin and replace the current
                  comment?
                </p>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  )
}
