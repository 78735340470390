import React, { useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import getContent from 'admin/queries/getContent'
import get from 'lodash/get'
import useMutation from 'core/react-query/useMutation'
import saveContents from 'admin/queries/saveContents'
import notification from 'shared/components/kit/notification'
import editContents from 'admin/queries/editContents'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import { ReactComponent as DeleteSVG } from 'icons/delete-filled.svg'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Button from 'shared/components/kit/button'
import SelectFAQStatus from '../select-faq-status'
import classes from './index.module.css'

export default function Index() {
  const [faqContent, setFaqContent] = useState({
    id: undefined,
    content: {
      questions: [{ question: '', answer: '' }],
    },
    status: 'draft',
  })

  const { isLoading } = useQuery(getContent, {
    onSuccess: (res) => {
      const contentList = get(res, 'json.data', [])
      const _faqContent = contentList.length > 0 ? contentList[0] : undefined
      if (_faqContent) {
        setFaqContent(_faqContent)
      }
    },
    keepPreviousData: true,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    variables: {
      key: 'mwa:faq',
    },
  })

  const handleChange = (index, name, value) => {
    let _list = [...faqContent.content.questions]
    _list[index][name] = value
    setFaqContent((prev) => ({
      ...prev,
      content: { ...prev.content, questions: _list },
    }))
  }

  const saveContentMutation = useMutation(saveContents, {
    onSuccess: () => {
      notification.success({ title: 'FAQ questions saved successfully!' })
    },
  })

  const editContentMutation = useMutation(editContents, {
    onSuccess: () => {
      notification.success({ title: 'FAQ questions edited successfully!' })
    },
  })

  const handleAddItem = () => {
    setFaqContent((prev) => ({
      ...prev,
      content: {
        ...prev.content,
        questions: [...prev.content.questions, { question: '', answer: '' }],
      },
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (faqContent && faqContent.id) {
      editContentMutation.mutate({
        id: faqContent.id,
        key: 'mwa:faq',
        status: faqContent.status,
        content: { questions: faqContent.content.questions },
      })
    } else {
      saveContentMutation.mutate({
        key: 'mwa:faq',
        status: faqContent.status,
        content: { questions: faqContent.content.questions },
      })
    }
  }

  const handleRemove = (index) => {
    setFaqContent((prev) => ({
      ...prev,
      content: {
        ...prev.content,
        questions: prev.content.questions.filter(
          (q, _index) => _index !== index
        ),
      },
    }))
  }

  if (isLoading) {
    return (
      <div className="absolute flex h-full items-center justify-center left-0 opacity-50 text-lg top-0 w-full">
        <Icon>
          <Spin />
        </Icon>
        <div>Loading...</div>
      </div>
    )
  }
  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ul className={classes.wrapper}>
          {faqContent.content.questions.map((element, index) => (
            <div key={index}>
              <li>
                {faqContent.content.questions.length > 1 && (
                  <div className="flex justify-end -mt-4">
                    <button type="button" onClick={() => handleRemove(index)}>
                      <Icon>
                        <DeleteSVG />
                      </Icon>
                    </button>
                  </div>
                )}
                <div>
                  <Field label="Question">
                    <Input
                      name="question"
                      value={element.question}
                      onChange={(value) =>
                        handleChange(index, 'question', value)
                      }
                      required
                    />
                  </Field>
                </div>
                <Field label="Answer">
                  <Input
                    name="answer"
                    value={element.answer}
                    onChange={(value) => handleChange(index, 'answer', value)}
                    multiline
                    required
                  />
                </Field>
              </li>
              {faqContent.content.questions.length !== index + 1 && (
                <hr className="-mx-4 mb-4" />
              )}
            </div>
          ))}
          <div className="flex justify-between">
            <Button type="button" onClick={handleAddItem} secondary>
              Add new item
            </Button>
          </div>
        </ul>
        <div className={classes.actions}>
          <SelectFAQStatus
            className="w-56 mr-4"
            value={faqContent.status}
            name="status"
            onChange={(value) =>
              setFaqContent((prev) => ({ ...prev, status: value }))
            }
          />
          <Button>Save</Button>
        </div>
      </form>
    </>
  )
}
