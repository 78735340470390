import React, { useState } from 'react'
import Button from 'shared/components/kit/button'
import ProgressBar from './components/ProgressBar'
import { ReactComponent as TCGSVG } from 'svg/tcg.svg'
import WhitePaperModal from './components/WhitePaperModal'
import Page from 'publisher/components/page'
import useTokenPointsOverview from 'publisher/hooks/useTokenPointsOverview'
import RoadmapModal from './components/RoadmapModal'
import Summary from './components/Summary'
import TransparencyModal from './components/TransparencyModal'
import Modal from 'shared/components/kit/modal'
import classes from './index.module.css'

export default function TCG() {
  const [whitePaperVisibleModal, setWhitePaperVisibleModal] = useState(false)
  const [roadmapVisibleModal, setRoadmapVisibleModal] = useState(false)
  const [claimTokensModalVisible, setClaimTokensModalVisible] = useState(false)
  const [transparencyVisibleModal, setTransparencyVisibleModal] =
    useState(false)

  const { isQualified } = useTokenPointsOverview()

  const handleCloseWhitePaperModal = () => {
    setWhitePaperVisibleModal(false)
  }

  const handleCloseRoadmapModal = () => {
    setRoadmapVisibleModal(false)
  }

  const handleCloseClaimTokensModal = () => {
    setClaimTokensModalVisible(false)
  }

  const handleCloseTransparencyModal = () => {
    setTransparencyVisibleModal(false)
  }

  const footer = (
    <Button mood="neutral" onClick={handleCloseClaimTokensModal} transparent>
      Close
    </Button>
  )

  return (
    <Page width="xl">
      <div className={classes.wrapper}>
        <ProgressBar />
        <Summary />
        <div className={classes.claimDiv}>
          <Button
            disabled={!isQualified}
            onClick={() => {
              setClaimTokensModalVisible(true)
            }}
            size="sm"
            mood="secondary">
            Claim tokens
          </Button>
        </div>
        <section>
          <h2>TCG - GOVERNANCE TOKEN</h2>
          <p>
            In our journey to build the best Web3 streaming service for Crypto
            content, we have launched our governance token (TCG). The token will
            play a vital role in the future of Today's Crypto since our goal is
            to be a community-driven platform run and governed by its
            users/token holders. The TCG is an ERC20 token built on top of
            Polygon.
          </p>
          <div className={classes.modalButtons}>
            <Button
              onClick={() => setWhitePaperVisibleModal(true)}
              mood="secondary">
              WhitePaper
            </Button>
            <Button
              onClick={() => setRoadmapVisibleModal(true)}
              mood="secondary">
              Roadmap
            </Button>
            <Button
              onClick={() => setTransparencyVisibleModal(true)}
              mood="secondary">
              Transparency
            </Button>
          </div>
        </section>
        <section>
          <h2>Earn TCG token</h2>
          <p>
            Fairness between users is crucial for us, which is one reason a
            token sale won't occur. TCGs are for those that use the platform,
            and there are multiple ways to earn the token through out the
            platform. You can earn tokens right after signing up without having
            an Ethereum wallet or MetaMask.
          </p>
        </section>
        <div className={classes.actions}>
          <div className={classes.rows}>
            <div className={classes.item}>
              <span className={classes.name}>
                <TCGSVG />
                Post a video &nbsp;-&nbsp; 500 tokens
              </span>
              <span className={classes.earning}></span>
            </div>
            <div className={classes.item}>
              <span className={classes.name}>
                <TCGSVG />
                Answer on comment &nbsp;-&nbsp; 10 tokens
              </span>
              <span className={classes.earning}></span>
            </div>
            <div className={classes.item}>
              <span className={classes.name}>
                <TCGSVG />
                Audience bonus &nbsp;-&nbsp; xxxxx tokens{' '}
                <span className="text-primary ml-1">**</span>
              </span>
              <span className={classes.earning}></span>
            </div>
          </div>
        </div>
        <section>
          <h2>
            <span className="text-primary">**</span> AUDIENCE BONUS
          </h2>
          <p>
            We love our publishers! Channels on TC can claim 1 token for every
            follower on their YouTube channel, and Twitter combined. This bonus
            is only valid for our existing channels and those who sign up during
            the token distribution. To qualify for the audience bonus, you must
            cover Today's Crypto (the platform) and our ongoing token
            distribution in a YouTube video. Then, Post four tweets over a
            period of four months. where you promote your channel at Today's
            Crypto and our ongoing token distribution. Once fulfilled, you will
            receive your bonus tokens.
          </p>
        </section>
        <section>
          <h2>TCG AIRDROP’s</h2>
          <p>
            There will be multiple Airdrop events in the coming year that will
            benefit early users and existing token holders. We will share
            instructions and dates via our official Twitter channel&nbsp;
            <a
              className="text-secondary"
              target="_blank"
              href="https://twitter.com/TodaysCryptoCom">
              @TodaysCryptoCom
            </a>
          </p>
        </section>
        <p>
          Be aware. We will never ask you to invest or share any wallet details!
        </p>
        <section>
          <h2>CLAIM EARNED TCG TOKEN</h2>
          <p>
            Claim your earned TCG Tokens by clicking the "claim tokens" button
            under your unclaimed tokens. Connect your MetaMask wallet before
            starting the claiming process, and follow the instructions. To
            claim, you must have earned a minimum of 500 tokens.
          </p>
        </section>
      </div>
      <WhitePaperModal
        visible={whitePaperVisibleModal}
        onClose={handleCloseWhitePaperModal}
      />
      <RoadmapModal
        visible={roadmapVisibleModal}
        onClose={handleCloseRoadmapModal}
      />
      <TransparencyModal
        visible={transparencyVisibleModal}
        onClose={handleCloseTransparencyModal}
      />
      <Modal
        footer={footer}
        title="CLAIM TOKENS"
        size="sm"
        onCancel={handleCloseClaimTokensModal}
        visible={claimTokensModalVisible}>
        <p>
          Publisher token claim will open shortly, please keep an eye out in
          your email for updates.{' '}
        </p>
      </Modal>
    </Page>
  )
}
