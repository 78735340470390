import { useRef } from 'react'
import useMiniForm from 'core/useMiniForm'
import cookie from 'core/cookie'
import useMutation from 'core/react-query/useMutation'
import login from 'shared/mutations/login'
import Box from 'shared/components/kit/box'
import CaptchaInput from 'shared/components/form/captcha'
import FormError from 'shared/components/form/error'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Password from 'shared/components/form/password'
import Button from 'shared/components/kit/button'
import classes from './SignIn.module.css'

const validation = {
  email: {
    label: 'E-Mail',
    rule: 'required',
  },
  password: {
    label: 'Password',
    rule: 'required',
  },
  captcha: {
    label: 'CAPTCHA',
    rule: 'required',
  },
}

export default function SignIn({ error, onForgot }) {
  const { mutateAsync } = useMutation(login)

  const captchaRef = useRef()

  const form = useMiniForm({
    validation,
    async onSubmit(values, event) {
      event.preventDefault()
      try {
        const response = await mutateAsync(values)
        cookie().set('jwt', response.json.token)
        window.location.href = '/admin'
      } catch (error) {
        captchaRef.current.reload()
        throw error.json
      }
    },
  })

  return (
    <Box className={classes.signIn}>
      <h2>Login</h2>

      <form onSubmit={form.submit}>
        {error === 'unauthorized' ? (
          <FormError>You do not have permission!</FormError>
        ) : form.error ? (
          <FormError>{form.error.message}</FormError>
        ) : null}

        <Field {...form.getErrorPropsFor('email')} label="E-Mail" required>
          <Input
            {...form.getInputPropsFor('email')}
            placeholder="Your E-mail"
            type="email"
            required
          />
        </Field>

        <Field
          {...form.getErrorPropsFor('password')}
          help={
            <span className={classes.forgot} onClick={onForgot}>
              Forgot your password?
            </span>
          }
          label="Password"
          required>
          <Password
            {...form.getInputPropsFor('password')}
            placeholder="Your Password"
            required
          />
        </Field>

        <Field
          {...form.getErrorPropsFor('captcha')}
          label="Prove that you are human"
          required>
          <CaptchaInput
            ref={captchaRef}
            onLoad={(captcha) => form.change('captchaKey', captcha.key)}
            {...form.getInputPropsFor('captcha')}
            required
          />
        </Field>

        <Button loading={form.submitting} type="submit" block>
          LOG IN!
        </Button>
      </form>
    </Box>
  )
}
