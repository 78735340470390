import TopNavigation from 'landings/components/top-navigation'
import classes from './index.module.css'

export default function LandingsLayout({ children }) {
  return (
    <div className={classes.wrapper}>
      <TopNavigation />

      <div className={classes.body}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  )
}
