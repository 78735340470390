type DeletePlaylistMutationVariables = {
  id: number,
}

export default function deletePlaylist(
  variables: DeletePlaylistMutationVariables
) {
  return {
    method: 'delete',
    path: `/playlists/${variables.id}`,
  }
}
