import { useState } from 'react'
import useMiniForm from 'core/useMiniForm'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/useMutation'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import getUser from 'admin/queries/getUser'
import getUsers from 'admin/queries/getUsers'
import updateUser from 'admin/queries/updateUser'

const DEFAULT_DURATIONS = {
  '1_week': 'One Week',
  '2_week': 'Two Weeks',
  '1_month': 'One Month',
  '3_month': 'Three Months',
  '6_month': 'Six Months',
  '1_year': 'One Year',
  permanent: 'Forever',
}

export default function MutePublisher({ user }) {
  const queryClient = useQueryClient()

  const mutation = useMutation(updateUser, {
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        query: getUsers,
      })

      queryClient.setQueryData({
        query: getUser,
        variables: {
          id: user.id,
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            ...response.json,
          },
        }),
      })
    },
  })

  return user.is_mute ? (
    <Unmute mutation={mutation} user={user} />
  ) : (
    <Mute mutation={mutation} user={user} />
  )
}

function Mute({ mutation, user }) {
  const [visible, setVisible] = useState(false)

  const form = useMiniForm({
    async onSubmit({ duration }) {
      try {
        await mutation.mutateAsync({
          id: user.id,
          fields: {
            is_mute: true,
            muted_until: duration,
          },
        })
        notification.success({
          title: 'Publisher muted successfully!',
        })
      } catch (error) {
        notification.error({
          title: error.message,
        })
      }
    },
  })

  const handleConfirm = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleOk = () => {
    form.submit()
  }

  return (
    <>
      <p className="mb-4">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>

      <div className="flex">
        <HtmlSelect
          className="mr-2 w-72"
          {...form.getInputPropsFor('duration')}>
          <option value="">Choose duration...</option>
          {Object.entries(DEFAULT_DURATIONS).map(([key, text]) => (
            <option key={key} value={key}>
              {text}
            </option>
          ))}
        </HtmlSelect>
        <Button disabled={!form.get('duration')} onClick={handleConfirm}>
          Mute Publisher
        </Button>
      </div>

      <Modal
        loading={mutation.isLoading}
        okText="Mute this publisher"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Mute publisher"
        visible={visible}>
        <p>Are you sure you want to mute this publisher?</p>
      </Modal>
    </>
  )
}

function Unmute({ mutation, user }) {
  const [visible, setVisible] = useState(false)

  const handleConfirm = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleOk = () => {
    mutation.mutate({
      id: user.id,
      fields: {
        is_mute: false,
      },
    })
  }

  return (
    <>
      <p className="mb-4">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
      <Button loading={mutation.isLoading} onClick={handleConfirm}>
        Unmute Publisher
      </Button>
      <Modal
        loading={mutation.isLoading}
        okText="Unmute this publisher"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Unmute publisher"
        visible={visible}>
        <p>Are you sure you want to unmute this publisher?</p>
      </Modal>
    </>
  )
}
