type ArchivePaymentDetailVariables = {
  ids: Array<string>,
}

export default function archivePaymentDetail(
  variables: ArchivePaymentDetailVariables = {}
) {
  const { ids } = variables
  return {
    method: 'POST',
    path: '/admin/payment-details/mark-as-archive',
    params: {
      ids: ids,
    },
  }
}
