type GetDashboardDataVariables = {
  statisticsPeriod?:
    | 'last_7d'
    | 'last_30d'
    | 'last_14d'
    | 'last_90d'
    | 'last_180d'
    | 'last_365d'
    | 'custom',
}

export default function getDashboardData(
  variables: GetDashboardDataVariables = {}
) {
  const { statisticsPeriod } = variables

  return {
    method: 'GET',
    path: '/publisher/dashboard/charts',
    params: {
      filters: {
        statistics_period: statisticsPeriod,
      },
    },
  }
}
