export type GetNotificationQueryVariableType = {
  id: number,
}

export default function getNotification(
  variables: GetNotificationQueryVariableType = {}
) {
  const { id } = variables
  return {
    path: `/admin/notifications/${id}`,
  }
}
