import { useEffect, useMemo, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import formatDate from 'helpers/utils/formatDate'
import useQueryClient from 'core/react-query/useQueryClient'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import Table from 'shared/components/kit/table'
import VideoStatus from 'shared/components/video-status'
import VideoThumbnail from 'shared/components/video-thumbnail'
import notification from 'shared/components/kit/notification'
import Button from 'shared/components/kit/button'
import SelectCategory from 'shared/components/form/select-category'
import Modal from 'shared/components/kit/modal'
import assignCategoryToVideo from 'admin/queries/assignCategoryToVideo'
import getVideos from 'admin/queries/getVideos'
import Page from 'admin/components/page'
import HideVideoButton from './components/HideVideoButton'
import Filters from './components/Filters'
import UnHideVideoButton from './components/UnHideVideoButton'
import DeleteVideoButton from './components/DeleteVideoButton'
import classes from './index.module.css'

const FILTER_CONFIG = {
  base: '/admin/videos',
  params: {
    category: 'number',
    channel: 'number',
    page: 'pagination',
    search: 'any',
    sort: 'any',
  },
}

export default function MyVideos() {
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)
  const [selectedRows, setSelectedRows] = useState([])
  const [categoryModalVisible, setCategoryModalVisible] = useState(false)

  const { data, error, isError, isFetching } = useQuery(getVideos, {
    keepPreviousData: true,
    variables: {
      categoryId: filters.category,
      channelId: filters.channel,
      page: filters.page,
      search: filters.search,
      sort: filters.sort,
    },
  })

  const {
    data: videos,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  useEffect(() => {
    setSelectedRows([])
  }, [isFetching])

  const queryClient = useQueryClient()

  const assignCategoryMutateAsync = useMutation(assignCategoryToVideo, {
    onSuccess: () => {
      queryClient.invalidateQueries({ query: getVideos })
      notification.success({
        title: `Category assigned to selected ${
          selectedRows.length > 1 ? 'videos' : 'video'
        } successfully!`,
      })
    },
    onError: (error) => {
      notification.error({ title: error.json.message })
    },
  })

  const form = useMiniForm({
    async onSubmit(values) {
      try {
        await assignCategoryMutateAsync.mutate({
          videoIds: selectedRows,
          categoryId: values.categoryId,
        })
        handleCloseCategoryModal()
      } catch (error) {
        notification.error({
          title: error.message,
        })
        handleCloseCategoryModal()
      }
    },
  })

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleFilter = (nextFilters) => {
    setFilters({
      ...nextFilters,
      page: undefined,
    })
  }

  if (isError) {
    return <Page error={error.message} />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  const handleRowSelect = (keys) => {
    setSelectedRows(keys)
  }

  const handleCloseCategoryModal = () => {
    setCategoryModalVisible(false)
    form.reset()
  }

  const handleSaveAssignCategory = () => {
    form.submit()
  }

  const handleOpenAssignCategoryModal = () => {
    if (selectedRows.length === 1) {
      form.change(
        'categoryId',
        videos.find((video) => video.id === selectedRows[0]).category_id
      )
    }
    setCategoryModalVisible(true)
  }

  const actions = (
    <Button
      onClick={handleOpenAssignCategoryModal}
      disabled={selectedRows.length === 0}>
      Add Category
    </Button>
  )

  return (
    <Page description={description} actions={actions} title="Videos">
      <Filters initialFilters={filters} onSubmit={handleFilter} />
      <Table
        columns={columns}
        data={videos}
        loading={isFetching}
        currentPage={currentPage}
        onRowSelect={handleRowSelect}
        selectedRows={selectedRows}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
      <Modal
        size="sm"
        title="Add Category"
        okText="Update / Add"
        onOk={handleSaveAssignCategory}
        visible={categoryModalVisible}
        onCancel={handleCloseCategoryModal}>
        <form onSubmit={form.submit}>
          <SelectCategory
            key={categoryModalVisible ? '1' : '0'}
            {...form.getInputPropsFor('categoryId')}
            isClearable
          />
        </form>
      </Modal>
    </Page>
  )
}

function getColumns() {
  return [
    {
      className: classes.thumbnail,
      title: 'Thumbnail',
      render: (video) => <VideoThumbnail video={video} />,
    },
    {
      className: classes.info,
      title: 'Title',
      render: (video) => (
        <>
          {video.title ? (
            video.title
          ) : (
            <span className={classes.untitled}>Untitled</span>
          )}
        </>
      ),
    },
    {
      className: '',
      title: 'Channel',
      render: (video) => video.channel?.name || '-',
    },
    {
      className: classes.categories,
      title: 'Category',
      render: (video) =>
        video.category?.name || <span className="block text-center">-</span>,
    },
    {
      align: 'center',
      className: classes.views,
      title: 'Views',
      render: (video) => video.view_count,
    },
    {
      align: 'center',
      className: classes.comments,
      title: 'Comments',
      render: (video) => video.comment_count,
    },
    {
      align: 'center',
      className: classes.likes,
      title: 'Likes',
      render: (video) => video.likes_count,
    },
    {
      align: 'center',
      className: classes.reports,
      title: 'Reports',
      render: (video) => video.reports_count,
    },
    {
      align: 'center',
      className: classes.status,
      title: 'Status',
      render: (video) => <VideoStatus status={video.status} />,
    },
    {
      align: 'center',
      className: classes.publishDate,
      title: 'Publish Date',
      render: (video) => formatDate(video.published_at),
    },
    {
      className: classes.actions,
      render: (video) => (
        <div className="whitespace-nowrap">
          {video.status === 'hidden' ? (
            <UnHideVideoButton id={video.id} />
          ) : (
            <HideVideoButton id={video.id} />
          )}
          &nbsp;&nbsp;
          <DeleteVideoButton id={video.id} />
        </div>
      ),
    },
  ]
}
