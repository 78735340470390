import { createElement, useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import UploadVideoModal from 'publisher/components/upload-video-modal'

export default function UploadVideoLink({ children, disabled }) {
  const history = useHistory()

  const [showUploadVideo, setShowUploadVideo] = useState(false)

  const handleCancelVideoUpload = useCallback(
    () => setShowUploadVideo(false),
    []
  )
  const handleSuccessVideoUpload = useCallback(
    (video) => {
      history.push(`/publisher/media/${video.id}`)
      setShowUploadVideo(false)
    },
    [history]
  )

  const handleShowUploadVideo = (event) => {
    event.preventDefault()
    setShowUploadVideo(true)
  }

  const link = createElement(disabled ? 'span' : 'a', {
    children,
    onClick: disabled ? undefined : handleShowUploadVideo,
  })

  return (
    <>
      {link}
      <UploadVideoModal
        onCancel={handleCancelVideoUpload}
        onSuccess={handleSuccessVideoUpload}
        visible={showUploadVideo}
      />
    </>
  )
}
