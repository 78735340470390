import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Select from 'shared/components/form/select'
import SelectUser from 'admin/components/form/select-user'
import classes from './Filters.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  const options = [
    {
      label: 'Pending ',
      value: 'pending',
    },
    {
      label: 'Waiting for Confirmation',
      value: 'pending_blockchain',
    },
    {
      label: 'Completed',
      value: 'completed',
    },
    {
      label: 'Failed',
      value: 'failed',
    },
    {
      label: 'Canceled',
      value: 'canceled',
    },
  ]

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <SelectUser
        className={classes.users}
        {...form.getInputPropsFor('userId')}
        isClearable
        placeholder="Select User..."
      />
      <Select
        {...form.getInputPropsFor('status')}
        placeholder="Select Status..."
        className={classes.status}
        options={options}
        isClearable
      />
      <Button outlined>Search</Button>
    </form>
  )
}
