// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".requests_wrapper__2OD1A {\n  display: flex;\n}\n\n.requests_wrapper__2OD1A > button {\n  margin-left: 0.5rem;\n}\n\n.requests_status__1CzA5 {\n  text-transform: capitalize;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/requests/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;ADAnB;;AAEA;ECFA,mBAAmB;ADInB;;AAEA;ECNA,0BAAmB;ADQnB","sourcesContent":[".wrapper {\n  @apply flex;\n}\n\n.wrapper > button {\n  @apply ml-2;\n}\n\n.status {\n  @apply capitalize;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "requests_wrapper__2OD1A",
	"status": "requests_status__1CzA5"
};
export default ___CSS_LOADER_EXPORT___;
