import { useMemo } from 'react'
import get from 'lodash/get'
import cookie from 'core/cookie'
import useQuery from 'core/react-query/useQuery'
import getProfile from 'shared/queries/getProfile'

export default function useProfile() {
  const jwt = cookie().get('jwt')
  const qr = useQuery(getProfile, {
    staleTime: 5 * 60 * 1000,
    usePreviousData: true,
  })

  const user = get(qr, 'data.json')

  const publisherRequest = useMemo(
    () =>
      user?.role === 'publisher'
        ? undefined
        : {
            ...user?.publisher_request,
            hasRequested: !!user?.publisher_request?.status,
          },
    [user]
  )

  return {
    ...qr,
    channel: get(user, 'channel'),
    channelStatus: get(user, 'channel.status'),
    isAdmin: get(qr, 'data.json.role') === 'admin',
    isLoggedIn: !!jwt,
    isPublisher: user?.role === 'publisher',
    publisherRequest,
    role: user?.role,
    user,
  }
}
