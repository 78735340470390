import { useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import get from 'lodash/get'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import useQuery from 'core/react-query/useQuery'
import Drawer from 'shared/components/kit/drawer'
import Button from 'shared/components/kit/button'
import notification from 'shared/components/kit/notification'
import getPlans from 'admin/queries/getPlans'
import createPlan from 'admin/queries/createPlan'
import getPaymentMethods from 'admin/queries/getPaymentMethods'
import PlanForm from './PlanForm'

export default function CreatePlan() {
  const params = useParams()

  const queryClient = useQueryClient()

  const history = useHistory()

  const initialValues = useMemo(() => {
    const result = {}
    result.isPopular = false
    return result
  }, [])

  const { data: paymentMethodsData, isLoading } = useQuery(getPaymentMethods)

  const paymentMethods = get(paymentMethodsData, 'json', [])

  const stripeMethod = paymentMethods.find((method) => method.name === 'Stripe')

  const coinbaseMethod = paymentMethods.find(
    (method) => method.name === 'Coinbase'
  )

  const createMutation = useMutation(createPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getPlans,
      })
      notification.success({
        title: 'Membership plan created successfully!',
      })
      history.push('/admin/plans')
    },
    onError: (error) => {
      notification.error({
        title: error.message,
      })
    },
  })

  const handleSubmit = async (values) => {
    try {
      const pricing = []
      if (values.enableStripeAmount) {
        pricing.push({
          amount: values.stripeAmount,
          paymentMethodId: stripeMethod.id,
          currency: 'usd',
        })
      }

      if (values.enableCoinbaseAmount) {
        pricing.push({
          amount: values.coinbaseAmount,
          paymentMethodId: coinbaseMethod.id,
          currency: 'usd',
        })
      }

      await createMutation.mutateAsync({
        name: values.name,
        interval: values.interval,
        description: values.description,
        isPopular: values.isPopular,
        status: false,
        pricing: pricing,
      })
    } catch (error) {
      throw error
    }
  }

  const handleCloseDrawer = () => {
    history.push('/admin/plans')
  }

  const handleShow = () => {
    history.push('/admin/plans/create')
  }

  return (
    <>
      <Button onClick={handleShow} outlined>
        Create a Plan
      </Button>
      <Drawer
        onClose={handleCloseDrawer}
        visible={params.planId === 'create'}
        title="Create Membership Plan"
        loading={createMutation.isLoading}>
        <PlanForm
          submitText="Create Plan"
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      </Drawer>
    </>
  )
}
