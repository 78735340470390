// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HideVideoButton_hideButton__38IPz {\n  margin-top: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/reports/components/videos/HideVideoButton.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,kBAAmB;ADAnB","sourcesContent":[".hideButton {\n  @apply mt-2;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hideButton": "HideVideoButton_hideButton__38IPz"
};
export default ___CSS_LOADER_EXPORT___;
