type PublisherRequestVariables = {
  channel_name: string,
  platform: string,
}

export default function publisherRequest(variables: PublisherRequestVariables) {
  const { channel_name, platform } = variables

  return {
    path: '/publisher/apply',
    params: {
      channel_name,
      platform,
    },
  }
}
