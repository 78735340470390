import { useParams } from 'react-router'
import useVideo from 'publisher/hooks/useVideo'
import Page from 'publisher/components/page'
import Charts from './components/Charts'
import Summary from './components/Summary'

export default function VideoOverview() {
  const params = useParams()
  const id = Number(params.id)
  const { video } = useVideo(id)
  return (
    <Page title={`Overview: ${video.title}`} width="xl">
      <Summary videoId={id} />
      <Charts videoId={id} />
    </Page>
  )
}
