import { useHistory, useParams } from 'react-router'
import Skeleton from 'shared/components/kit/skeleton'
import VideoStatus from 'shared/components/video-status'
import formatDate from 'helpers/utils/formatDate'
import VideoThumbnail from 'shared/components/video-thumbnail'
import Base from 'publisher/components/layouts/Base'
import useVideo from 'publisher/hooks/useVideo'
import { ReactComponent as BackSVG } from 'icons/arrow-back.svg'
import { ReactComponent as EditOutlinedSVG } from 'icons/edit-outlined.svg'
import { ReactComponent as LineChartSVG } from 'icons/line-chart.svg'
import { ReactComponent as StudioOutlinedSVG } from 'icons/movie-creation-outlined.svg'
import classes from './index.module.css'
import PublishButton from './PublishButton'

export default function VideoLayout({ children }) {
  const params = useParams()
  const history = useHistory()
  const id = Number(params.id)
  const searchParams = new URLSearchParams(history.location.search)
  const page = searchParams.get('page')
  const { isLoading, video } = useVideo(id)

  const sidebar = (
    <>
      <section className={classes.video}>
        {isLoading ? (
          <Skeleton align="center">
            <Skeleton.Rect height={12} />
            <Skeleton.Rect size={40} />
            <Skeleton.Rect size={24} />
          </Skeleton>
        ) : (
          <div className={classes.videoInfo}>
            <VideoThumbnail video={video} />
            <h3>{video.title}</h3>
            <VideoStatus status={video.status} />
            {video.status === 'published' && (
              <div className="text-xs text-neutral h-0">
                Published {formatDate(video.published_at)}
              </div>
            )}
            <PublishButton video={video} />
          </div>
        )}
      </section>

      <ul>
        <li>
          <Base.NavLink
            icon={<LineChartSVG />}
            text="Overview"
            to={`/publisher/media/${id}${page > 1 ? `?page=${page}` : ''}`}
            exact
          />
        </li>
        <li>
          <Base.NavLink
            icon={<EditOutlinedSVG />}
            text="Edit Info"
            to={`/publisher/media/${id}/edit${page > 1 ? `?page=${page}` : ''}`}
            exact
          />
        </li>
        <li>
          <Base.NavLink
            icon={<StudioOutlinedSVG />}
            text="Studio"
            to={`/publisher/media/${id}/studio${
              page > 1 ? `?page=${page}` : ''
            }`}
            exact
          />
        </li>
        <li>
          <Base.NavLink
            icon={<BackSVG />}
            text="My Media"
            to={`/publisher/media${page > 1 ? `?page=${page}` : ''}`}
            exact
          />
        </li>
      </ul>
    </>
  )

  return <Base main={children} sidebar={sidebar} />
}
