import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import classnames from 'classnames'
import formatDuration from 'helpers/utils/formatDuration'
import Chapters from './Chapters'
import TimelineOverlays from './TimelineOverlays'
import OverlayLeftActions from './OverlayLeftActions'
import OverlayRightActions from './OverlayRightActions'
import AddOverlayButton from './AddOverlayButton'
import ChaptersLeftActions from './ChaptersLeftActions'
import TimelineHelperLines from './TimelineHelperLines'
import useTimelineData from '../hooks/useTimelineData'
import classes from './Timeline.module.css'

type Props = {
  duration: number,
  id: number,
  onSeek: Function,
}

function Timeline(props: Props, ref) {
  const { id, onSeek } = props

  const { chapters, duration, overlays, overlayErrors, zoom } =
    useTimelineData()

  const leftRef = useRef()
  const rightRef = useRef()
  const centerRef = useRef()
  const timelineRef = useRef()
  const currentTimeRef = useRef()
  const ghostCursorRef = useRef()
  const ghostTimeRef = useRef()
  const helpersRef = useRef()
  const cursorRef = useRef()
  const leftShadowRef = useRef()
  const rightShadowRef = useRef()
  const timeRef = useRef(0)

  const getCurrentTime = useCallback(() => timeRef.current, [])

  useImperativeHandle(ref, () => ({
    setCurrentTime: (time) => {
      timeRef.current = time
      const percent = duration > 0 ? (time / duration) * 100 : 0
      if (percent <= 50) {
        cursorRef.current.style.left = `${percent}%`
        cursorRef.current.style.right = 'auto'
      } else {
        cursorRef.current.style.left = 'auto'
        cursorRef.current.style.right = `${100 - percent}%`
      }

      currentTimeRef.current.innerHTML = formatDuration(time)
      const timelineRect = timelineRef.current.getBoundingClientRect()
      const cursorRect = cursorRef.current.getBoundingClientRect()
      const currentTimeRect = currentTimeRef.current.getBoundingClientRect()
      if (
        timelineRect.left +
          timelineRect.width -
          (cursorRect.left + cursorRect.width) <=
        currentTimeRect.width / 2
      ) {
        currentTimeRef.current.style.left = `${4 - currentTimeRect.width}px`
      } else if (
        cursorRect.left - timelineRect.left <=
        currentTimeRect.width / 2
      ) {
        currentTimeRef.current.style.left = '-4px'
      } else {
        currentTimeRef.current.style.left = `-${currentTimeRect.width / 2}px`
      }
    },
  }))

  const lockWindowScroll = useCallback((event) => {
    if (Math.abs(event.deltaY) > 0) {
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      return false
    }
  }, [])

  const addScrollableShadows = useCallback(() => {
    if (centerRef.current.scrollLeft > 0) {
      leftShadowRef.current.style.display = 'block'
    } else {
      leftShadowRef.current.style.display = 'none'
    }

    const timelineWidth = timelineRef.current.scrollWidth
    const minWidth = centerRef.current.getBoundingClientRect().width

    if (centerRef.current.scrollLeft + minWidth < timelineWidth) {
      rightShadowRef.current.style.display = 'block'
    } else {
      rightShadowRef.current.style.display = 'none'
    }
  }, [])

  useEffect(() => {
    addScrollableShadows()
  }, [zoom, addScrollableShadows])

  const handleWheel = (event) => {
    // if (Math.abs(event.deltaY) > 0) {
    //   const minWidth = centerRef.current.getBoundingClientRect().width
    //   const width = timelineRef.current.scrollWidth
    //   let nextWidth = Math.min(Math.max(minWidth, width - 2 * event.deltaY), 5000)
    //   timelineRef.current.style.width = `${nextWidth}px`
    //   addScrollableShadows()
    // }
  }

  const handleScroll = (event) => {
    addScrollableShadows()
  }

  const handleMouseOverCenter = (event) => {
    // window.addEventListener('mousewheel', lockWindowScroll, { passive: false })
  }

  const handleMouseOutCenter = (event) => {
    // window.removeEventListener('mousewheel', lockWindowScroll)
  }

  const handleMoveCursor = (initialEvent) => {
    let timelineRect = timelineRef.current.getBoundingClientRect()

    function setGhostPosition(num, element) {
      let percent
      const min = timelineRect.left
      const max = timelineRect.left + timelineRect.width
      if (num < min) {
        percent = 0
      } else if (num > max) {
        percent = 100
      } else {
        percent = (100 * (num - timelineRect.left)) / timelineRect.width
      }
      if (percent <= 50) {
        element.style.left = `${percent}%`
        element.style.right = 'auto'
      } else {
        element.style.left = 'auto'
        element.style.right = `${100 - percent}%`
      }

      ghostTimeRef.current.innerHTML = formatDuration(
        (duration * percent) / 100
      )
      const cursorRect = element.getBoundingClientRect()
      const ghostTimeRect = ghostTimeRef.current.getBoundingClientRect()
      if (
        timelineRect.left +
          timelineRect.width -
          (cursorRect.left + cursorRect.width) <=
        ghostTimeRect.width / 2
      ) {
        ghostTimeRef.current.style.left = `${4 - ghostTimeRect.width}px`
      } else if (
        cursorRect.left - timelineRect.left <=
        ghostTimeRect.width / 2
      ) {
        ghostTimeRef.current.style.left = '-4px'
      } else {
        ghostTimeRef.current.style.left = `-${ghostTimeRect.width / 2}px`
      }

      return percent
    }

    ghostCursorRef.current.style.display = 'block'
    setGhostPosition(initialEvent.clientX, ghostCursorRef.current)

    function handleMouseMove(event) {
      setGhostPosition(event.clientX, ghostCursorRef.current)
    }

    function handleMouseUp(event) {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
      ghostCursorRef.current.style.display = 'none'
      const percent = setGhostPosition(event.clientX, cursorRef.current)
      onSeek(percent)
    }

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
  }

  const zoomPercent = 100 * Math.pow(2, zoom - 1)

  return (
    <div className={classes.wrapper}>
      <div className={classes.left} ref={leftRef}>
        <div className="h-16" />
        <ChaptersLeftActions
          chaptersCount={chapters.length}
          getCurrentTime={getCurrentTime}
          videoId={id}
        />
        {overlays.map((overlay) => (
          <OverlayLeftActions
            key={overlay.id}
            error={!!overlayErrors[overlay.id]}
            localId={overlay.id}
            position={overlay.position}
            type={overlay.type}
          />
        ))}
        <div className="h-16" />
      </div>
      <div className={classes.leftShadow} ref={leftShadowRef} />
      <div
        className={classes.center}
        onMouseOver={handleMouseOverCenter}
        onMouseOut={handleMouseOutCenter}
        onScroll={handleScroll}
        ref={centerRef}>
        <div
          className={classes.timeline}
          onMouseDown={handleMoveCursor}
          onWheel={handleWheel}
          ref={timelineRef}
          style={{ width: `${zoomPercent}%` }}>
          <div
            className={classes.cursor}
            onMouseDown={handleMoveCursor}
            ref={cursorRef}>
            <span ref={currentTimeRef} />
          </div>
          <div
            key="ghost"
            className={classnames(classes.cursor, classes.ghost)}
            ref={ghostCursorRef}>
            <span ref={ghostTimeRef} />
          </div>
          <TimelineHelperLines
            duration={duration}
            ref={helpersRef}
            zoom={zoom}
          />
          <Chapters chapters={chapters} duration={duration} videoId={id} />
          <TimelineOverlays />
        </div>
        <div className={classes.addOverlay}>
          <AddOverlayButton duration={duration} />
        </div>
      </div>
      <div className={classes.rightShadow} ref={rightShadowRef} />
      <div className={classes.right} ref={rightRef}>
        <div className="h-16" />
        <div className="h-16" />
        {overlays.map((overlay) => (
          <OverlayRightActions key={overlay.id} overlay={overlay} />
        ))}
        <div className="h-16" />
      </div>
    </div>
  )
}

export default forwardRef(Timeline)
