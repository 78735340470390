type getCommentQueryVariables = {
  id: number,
}

export default function getComment(variables: getCommentQueryVariables = {}) {
  const { id } = variables
  return {
    name: ['comment', id],
    path: `/comments/${id}`,
  }
}
