import classnames from 'classnames'
import classes from './index.module.css'

export default function Tag(props) {
  const { children, className, type } = props

  return (
    <span className={classnames(classes.wrapper, className || type)}>
      {children}
    </span>
  )
}

Tag.defaultProps = {
  type: 'bg-blue-500',
}
