import { useState } from 'react'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import Popover from 'shared/components/kit/popover'
import Box from 'shared/components/kit/box'
import getTotalDistributedMoney from 'publisher/queries/getTotalDistributedMoney'
import formatPrice from 'helpers/utils/formatPrice'
import formatCryptocurrency from 'helpers/utils/formatCryptocurrency'
import useChannelTotalPerformance from '../hooks/useChannelTotalPerformance'
import { ReactComponent as UsdcSVG } from 'svg/usdc.svg'
import { ReactComponent as UsdtSVG } from 'svg/usdt.svg'
import { ReactComponent as NewWindow } from 'svg/new-window.svg'
import { ReactComponent as InfoSVG } from 'svg/info_outlined.svg'
import classes from './Summary.module.css'
import Button from '../../../../shared/components/kit/button'
import { Link } from 'react-router-dom'

export default function Summary() {
  const [visibleEarnedMonth, setVisibleEarnedMonth] = useState()
  const [visiblePointsMonth, setVisiblePointsMonth] = useState()
  const [visibleTotalAmount, setVisibleTotalAmount] = useState()

  const handleHideEarnedMonth = () => {
    setVisibleEarnedMonth(false)
  }

  const handleHidePointsMonth = () => {
    setVisiblePointsMonth(false)
  }

  const handleHideTotalAmount = () => {
    setVisibleTotalAmount(false)
  }

  const { data, error, isError, isLoading } = useChannelTotalPerformance()
  const {
    data: totalDistributedMoneyData,
    error: totalDistributedMoneyError,
    isError: totalDistributedMoneyIsError,
    isLoading: totalDistributedMoneyIsLoading,
  } = useQuery(getTotalDistributedMoney)
  const totalPoints = get(data, 'json.points_total', 0)
  const channelPoints = get(data, 'json.points_channel', 0)
  const totalDistributedMoney = get(totalDistributedMoneyData, 'json', 0)

  const totalPoint =
    (totalPoints > 0 ? (channelPoints * 100) / totalPoints : 0) > 0.01
      ? totalPoints > 0
        ? (channelPoints * 100) / totalPoints
        : 0
      : ' < 0.01'

  const channelEarned = (totalDistributedMoney * totalPoint) / 100

  const earnedPointsContent = (
    <p>
      This number shows your earned monetization points this month. Your base
      points generated by your subscribers are added to your points when you
      enter a new monetization month.
      <br />
      Click on the “HOW IT WORKS?” button in the upper right corner to learn how
      the monetization engine works and how to maximize your earnings.
    </p>
  )

  const earnedUsdtContent = (
    <p>
      This amount shows your monetization earnings so far for the current month.
      Expect this amount to fluctuate since your final earnings depend on your
      channel’s performance in comparison to others on the platform.
      <br />
      Click on the “HOW IT WORKS?” button in the upper right corner to learn how
      the monetization engine works and how to maximize your earnings.
    </p>
  )

  const handleClickInfoEarnedMonth = () => {
    setVisibleEarnedMonth(true)
    setVisiblePointsMonth(false)
    setVisibleTotalAmount(false)
  }

  const handleClickInfoPointsMonth = () => {
    setVisiblePointsMonth(true)
    setVisibleEarnedMonth(false)
    setVisibleTotalAmount(false)
  }

  const handleClickInfoTotal = () => {
    setVisibleEarnedMonth(false)
    setVisiblePointsMonth(false)
    setVisibleTotalAmount(true)
  }

  const totalAmountContent = (
    <p>
      The amount shown here is the total amount distributed for the month. We
      allocate 30% of the total platform revenue to the monetization engine.
      This number will grow together with the growth of Today's Crypto.
    </p>
  )
  return (
    <div className={classes.container}>
      <Box className={classes.box}>
        <ul>
          <li>
            <span style={{ color: '#51ac93' }}>
              {isLoading
                ? '...'
                : channelEarned > 0
                ? '$' + formatPrice(channelEarned, false, 1)
                : '0'}
            </span>
            <small className="flex text-white dark:text-light">
              Your earned USDT this month
              <Popover
                onHide={handleHideEarnedMonth}
                visible={visibleEarnedMonth}
                content={earnedUsdtContent}
                placement="bottom">
                <span
                  onClick={handleClickInfoEarnedMonth}
                  className={classes.info}>
                  <InfoSVG />
                </span>
              </Popover>
            </small>
          </li>
        </ul>
      </Box>
      <Box className={classes.box}>
        <ul>
          <li>
            <span>
              {isLoading
                ? '...'
                : formatCryptocurrency(channelPoints, false, 1)}
            </span>
            <small className="flex text-white dark:text-light">
              Your earned points this month
              <Popover
                onHide={handleHidePointsMonth}
                visible={visiblePointsMonth}
                content={earnedPointsContent}
                placement="bottom">
                <span
                  onClick={handleClickInfoPointsMonth}
                  className={classes.info}>
                  <InfoSVG />
                </span>
              </Popover>
            </small>
          </li>
        </ul>
      </Box>
      <Box className={classes.box}>
        <ul>
          <li>
            <span>
              {totalDistributedMoneyIsLoading ? (
                '...'
              ) : (
                <div title="usdt" className={classes.totalMoney}>
                  <UsdtSVG />
                  &nbsp;{formatPrice(totalDistributedMoney, false, 0)}&nbsp;
                  <span>
                    <a
                      target="_blank"
                      href="https://polygonscan.com/address/0x849BC042F24E2513EbfDBCcAC5e45134F3932b84">
                      <NewWindow />
                    </a>
                  </span>
                </div>
              )}
            </span>
            <small className="flex text-white dark:text-light -mt-2.5">
              Total platform distribution this month
              <Popover
                onHide={handleHideTotalAmount}
                visible={visibleTotalAmount}
                content={totalAmountContent}
                placement="bottom">
                <span onClick={handleClickInfoTotal} className={classes.info}>
                  <InfoSVG />
                </span>
              </Popover>
            </small>
          </li>
        </ul>
      </Box>
    </div>
  )
}
