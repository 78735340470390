type GetCryptosQueryVariables = {
  page?: number,
  search: string,
}

export default function getCrypto(variables: GetCryptosQueryVariables = {}) {
  const { page, search } = variables

  return {
    name: 'cryptos',
    path: '/cryptocurrencies',
    params: {
      page,
      filters: {
        search,
      },
    },
  }
}
