// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon_icon__1CzPO {\n  display: inline-block;\n  vertical-align: middle;\n}\n\n.icon_icon__1CzPO svg {\n  display: block !important;\n  fill: currentColor !important;\n  height: 1em;\n  width: 1em;\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/kit/icon/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,qBAAmB;EAAnB,sBAAmB;ADAnB;;AAEA;ECFA,yBAAmB;EAAnB,6BAAmB;EDIjB,WAAW;EACX,UAAU;AACZ","sourcesContent":[".icon {\n  @apply align-middle inline-block;\n}\n\n.icon svg {\n  @apply block fill-current !important;\n  height: 1em;\n  width: 1em;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "icon_icon__1CzPO"
};
export default ___CSS_LOADER_EXPORT___;
