export default function ReplyButton({
  children,
  onAddMentioned,
  onRemoveMentioned,
  mentioned,
  reply,
}) {
  const handleClick = () => {
    if (mentioned && mentioned.replyId === reply.id) {
      onRemoveMentioned()
    } else {
      onAddMentioned({
        user: reply.user,
        replyId: reply.id,
      })
    }
  }
  return (
    <>
      <button
        className={
          mentioned && mentioned.replyId === reply.id
            ? 'bg-light-2 dark:bg-dark-3'
            : ''
        }
        onClick={handleClick}>
        {children}
      </button>
    </>
  )
}
