import { useState, forwardRef } from 'react'
import Input from 'shared/components/form/input'
import { ReactComponent as VisibilitySVG } from 'icons/visibility-outlined.svg'
import { ReactComponent as VisibilityOffSVG } from 'icons/visibility-off-outlined.svg'
import classes from './index.module.css'

function Password(props, ref) {
  const [visible, setVisible] = useState(false)

  const handleToggle = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setVisible((prev) => !prev)
  }

  return (
    <div className={classes.wrapper}>
      <Input ref={ref} {...props} type={visible ? 'text' : 'password'} />
      <button
        onClick={handleToggle}
        title={`${visible ? 'Hide' : 'Show'} password`}
        type="button">
        {visible ? <VisibilityOffSVG /> : <VisibilitySVG />}
      </button>
    </div>
  )
}

export default forwardRef(Password)
