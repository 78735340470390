import { useCallback } from 'react'
import get from 'lodash/get'
import useQueryClient from 'core/react-query/useQueryClient'
import ChannelAvatar from 'shared/components/channel-avatar'
import getUserName from 'helpers/utils/getUserName'
import AsyncSelect from 'shared/components/form/select/AsyncSelect'
import getChannel from 'admin/queries/getChannel'
import getChannels from 'admin/queries/getChannels'
import classes from './index.module.css'

export default function SelectChannel(props) {
  const queryClient = useQueryClient()

  const loadDefaultOptions = useCallback(async () => {
    try {
      const response = await queryClient.fetchQuery({
        query: getChannel,
        variables: {
          id: props.value,
        },
      })
      return {
        channel: response.json,
        label: response.json.name,
        value: response.json.id,
      }
    } catch (e) {
      return {}
    }
  }, [queryClient, props.value])

  const loadOptions = useCallback(
    async (search) => {
      try {
        const response = await queryClient.fetchQuery({
          query: getChannels,
          variables: {
            includeOwner: true,
            search,
          },
        })
        return get(response, 'json.data', []).map((opt) => ({
          channel: opt,
          label: opt.name,
          value: opt.id,
        }))
      } catch (e) {
        return []
      }
    },
    [queryClient]
  )

  const formatOptionLabel = useCallback(
    ({ label, channel, value }, { context }) => {
      if (context === 'value') {
        return label
      }
      return (
        <div className={classes.option}>
          <ChannelAvatar className={classes.avatar} channel={channel} />
          <div className={classes.info}>
            <div>
              {channel.name}{' '}
              {channel?.status !== 'published' ? (
                <small>{channel?.status}</small>
              ) : null}
            </div>
            <small className="whitespace-nowrap">
              Owner: {channel.owner ? getUserName(channel.owner) : 'Unknown'}
              <>
                {' '}
                {typeof channel?.owner?.email !== 'undefined'
                  ? `(${channel?.owner?.email})`
                  : null}
              </>
            </small>
          </div>
        </div>
      )
    },
    []
  )

  const noOptionsMessage = useCallback(
    ({ inputValue }) =>
      inputValue ? 'No channels found' : 'Type to search channels',
    []
  )

  return (
    <AsyncSelect
      {...props}
      formatOptionLabel={formatOptionLabel}
      loadDefaultOptions={loadDefaultOptions}
      loadOptions={loadOptions}
      noOptionsMessage={noOptionsMessage}
      placeholder="Select Channel..."
    />
  )
}
