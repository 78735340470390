import { useState } from 'react'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import Box from 'shared/components/kit/box'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import getChannel from 'admin/queries/getChannel'
import getChannels from 'admin/queries/getChannels'
import updateChannel from 'admin/queries/updateChannel'

export default function FreezeChannel({ channel }) {
  const queryClient = useQueryClient()

  const mutation = useMutation(updateChannel, {
    onError: (error) => {
      notification.error({
        title: error.message,
      })
    },
    onMutate: ({ status }) => {
      queryClient.setQueryData({
        query: getChannel,
        variables: {
          id: channel.id,
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            status,
          },
        }),
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ query: getChannels })
    },
  })

  const handleFreeze = () => {
    mutation.mutate({
      id: channel.id,
      status: 'freeze',
    })
    notification.success({
      title: 'The channel froze successfully!',
    })
  }

  const handleUnfreeze = () => {
    mutation.mutate({
      id: channel.id,
      status: 'published',
    })
    notification.success({
      title: 'The channel unfroze successfully !',
    })
  }

  return channel.status === 'freeze' ? (
    <Unfreeze channel={channel} onUpdate={handleUnfreeze} />
  ) : (
    <Freeze channel={channel} onUpdate={handleFreeze} />
  )
}

function Freeze({ channel, onUpdate }) {
  const [visible, setVisible] = useState(false)

  const handleCancel = () => {
    setVisible(false)
  }

  const handleOk = () => {
    onUpdate()
    setVisible(false)
  }

  return (
    <>
      <Box className="p-6">
        <h4 className="mb-2">Freeze Channel</h4>
        <p className="h-16 text-overlayColor-mutedText">
          In this area, you can freeze this channel.
        </p>
        <footer className="text-right">
          <Button onClick={() => setVisible(true)} size="sm" outlined secondary>
            Freeze this Channel
          </Button>
        </footer>
      </Box>

      <Modal
        okText="Freeze Channel"
        onOk={handleOk}
        onCancel={handleCancel}
        size="sm"
        title={`Freeze Channel: ${channel.name}`}
        visible={visible}>
        <p>Are you sure you want to freeze this channel?</p>
      </Modal>
    </>
  )
}

function Unfreeze({ channel, onUpdate }) {
  const [visible, setVisible] = useState(false)

  const handleCancel = () => {
    setVisible(false)
  }

  const handleOk = () => {
    onUpdate()
    setVisible(false)
  }

  return (
    <>
      <Box className="p-6">
        <h4 className="mb-2">Un-freeze Channel</h4>
        <p className="h-16 text-overlayColor-mutedText">
          In this area, you can un-freeze this channel.
        </p>
        <footer className="text-right">
          <Button onClick={() => setVisible(true)} size="sm" outlined secondary>
            Un-freeze this Channel
          </Button>
        </footer>
      </Box>

      <Modal
        okText="Un-freeze Channel"
        onOk={handleOk}
        onCancel={handleCancel}
        size="sm"
        title={`Un-freeze Channel: ${channel.name}`}
        visible={visible}>
        <p>Are you sure you want to un-freeze this channel?</p>
      </Modal>
    </>
  )
}
