// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoginForm_container__3THqn {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/login/components/LoginForm/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,sBAAmB;EAAnB,mBAAmB;EAAnB,uBAAmB;EAAnB,aAAmB;ADAnB","sourcesContent":[".container {\n  @apply flex flex-col h-screen justify-center items-center;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LoginForm_container__3THqn"
};
export default ___CSS_LOADER_EXPORT___;
