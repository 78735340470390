import Field from 'shared/components/form/field'
import SelectCategory from 'shared/components/form/select-category'

export default function SelectMediaCategory(props) {
  return <SelectCategory {...props} maxMenuHeight={200} />
}

SelectMediaCategory.Field = ({ error, help, label, ...inputProps }) => (
  <Field error={error} help={help} label={label}>
    <SelectCategory {...inputProps} />
  </Field>
)

SelectMediaCategory.Field.defaultProps = {
  label: 'Select Main Category',
}
