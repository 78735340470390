import Tag from 'shared/components/kit/tag'

const STATUSES = {
  new: {
    color: 'bg-red-400',
    label: 'new',
  },
  'code-sent': {
    color: 'bg-yellow-400',
    label: 'code sent',
  },
  verified: {
    color: 'bg-green-400',
    label: 'verified',
  },
  expired: {
    color: 'bg-gray-400',
    label: 'expired',
  },
  canceled: {
    color: 'bg-gray-200',
    label: 'canceled',
  },
}

export default function PaymentDetailStatus({ status }) {
  const data = STATUSES[status]

  if (data) {
    return (
      <Tag type={data.color}>
        {data.icon}
        <span>{data.label}</span>
      </Tag>
    )
  }

  return null
}
