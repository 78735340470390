import { Link } from 'react-router-dom'
import { useMemo, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import Page from 'admin/components/page'
import Table from 'shared/components/kit/table'
import Button from 'shared/components/kit/button'
import VideoThumbnail from 'shared/components/video-thumbnail'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import VideoStatus from 'shared/components/video-status'
import formatDate from 'helpers/utils/formatDate'
import getReportedVideos from 'admin/queries/getReportedVideos'
import getUserName from 'helpers/utils/getUserName'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import VideoDetails from 'admin/pages/reports/components/videos/VideoDetails'
import classes from './ReportedVideos.module.css'

const INITIAL_FILTERS = {
  page: 1,
}

export default function ReportedVideos({ id: videoId }) {
  const [filters, setFilters] = useState(INITIAL_FILTERS)
  const { data, error, isError, isFetching } = useQuery(getReportedVideos, {
    keepPreviousData: true,
    variables: filters,
  })
  const {
    data: videos,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  if (isError) {
    return <Page error={error.message} />
  }
  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  return (
    <>
      <Page description={description} loading={isFetching} title=" ">
        <Table
          columns={columns}
          data={videos}
          loading={isFetching}
          currentPage={currentPage}
          lastPage={lastPage}
          onPaginate={handlePaginate}
        />
      </Page>
      <VideoDetails videoId={videoId} />
    </>
  )
}

function getColumns() {
  return [
    {
      className: classes.thumbnail,
      title: 'Thumbnail',
      render: (video) => <VideoThumbnail video={video} />,
    },
    {
      title: 'Title',
      render: (video) => (
        <>{video.title ? video.title : <span>Untitled</span>}</>
      ),
    },
    {
      title: 'Username',
      render: (video) => (
        <Link to={`/admin/users?id=${video.user.id}`}>
          {getUserName(video.user)}
        </Link>
      ),
    },
    {
      title: 'Channel',
      render: (video) => video.channel?.name || '-',
    },
    {
      align: 'center',
      title: 'Reports',
      render: (video) => video.reports_count,
    },
    {
      align: 'center',
      title: 'Views',
      render: (video) => video.view_count,
    },
    {
      align: 'center',
      title: 'Status',
      render: (video) => <VideoStatus status={video.status} />,
    },
    {
      align: 'center',
      title: 'Publish Date',
      render: (video) => formatDate(video.published_at),
    },
    {
      render: (video) => (
        <Button
          component="link"
          size="sm"
          to={`/admin/reports/videos/${video.id}`}
          secondary
          outlined>
          Details
        </Button>
      ),
    },
  ]
}
