type LotteryDraw = {
  month?: string,
}

export default function lotteryDraw(variables: LotteryDraw = {}) {
  const { month } = variables
  return {
    method: 'POST',
    path: '/admin/lotteries',
    params: {
      month,
    },
  }
}
