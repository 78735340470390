import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Input from 'shared/components/form/input'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import classes from './Filters.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <Input
        {...form.getInputPropsFor('search')}
        className={classes.search}
        placeholder="Search campaigns..."
      />
      <HtmlSelect {...form.getInputPropsFor('sort')} className={classes.sort}>
        <option value="">Sort By...</option>
        <option value="oldest">Oldest</option>
        <option value="newest">Newest</option>
      </HtmlSelect>
      <HtmlSelect {...form.getInputPropsFor('status')} className={classes.sort}>
        <option value="active">Active</option>
        <option value="draft">Draft</option>
        <option value="paused">Paused</option>
        <option value="archived">Archived</option>
      </HtmlSelect>
      <Button outlined>Search</Button>
    </form>
  )
}
