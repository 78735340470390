import { useEffect, useRef, useState } from 'react'
import cookie from 'core/cookie'
import env from 'core/env'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import FormError from 'shared/components/form/error'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Password from 'shared/components/form/password'
import CaptchaInput from 'shared/components/form/captcha'
import magicLogin from 'shared/mutations/magicLogin'
import login from 'shared/mutations/login'
import Result from 'shared/components/kit/result'
import Modal from 'shared/components/kit/modal'
import NotifyModal from '../NotifyModal'
import classes from './SignIn.module.css'

const IS_PRODUCTION = env('env') === 'production'

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}

export default function SignIn({ onRegister, onForgot }) {
  const [visibleNotify, setVisibleNotify] = useState(false)
  const [magicLoginMode, setMagicLoginMode] = useState(false)
  const [visibleVisitDesktopVersionModal, setVisibleVisitDesktopVersionModal] =
    useState(false)
  const [windowSize, setWindowSize] = useState(getWindowSize())

  const handleCancelVisitDesktopVersion = () => {
    setVisibleVisitDesktopVersionModal(false)
  }

  const [showRequireStar, setShowRequireStar] = useState(false)
  const [showRequireStarMagic, setShowRequireStarMagic] = useState(false)

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const { mutateAsync } = useMutation(login)
  const {
    data: dataMagicLogin,
    mutateAsync: magicLoginMutateAsync,
    isSuccess: isSuccessMagic,
  } = useMutation(magicLogin)

  const passwordRef = useRef()
  const captchaRef = useRef()

  const form = useMiniForm({
    async onSubmit(values, event) {
      event.preventDefault()
      try {
        if (!magicLoginMode) {
          const response = await mutateAsync(values)
          cookie().set('jwt', response.json.token)
          window.location.href = '/publisher'
        } else {
          await magicLoginMutateAsync({
            login: values.login,
            captcha: values.captcha,
            captchaKey: values.captchaKey,
          })
        }
      } catch (error) {
        captchaRef.current.reload()
        throw error.json
      }
    },
  })

  if (isSuccessMagic) {
    return (
      <Result
        title="Magic Link Sent!"
        status="info"
        description={dataMagicLogin.json.message}
      />
    )
  }

  const handleClick = (e) => {
    if (windowSize.innerWidth < 768) {
      setVisibleVisitDesktopVersionModal(true)
    } else if (IS_PRODUCTION) {
      setVisibleNotify(true)
    }
  }

  const handleCheckShowRequireStar = () => {
    if (!form.getInputPropsFor('email').value) {
      setShowRequireStar(true)
    } else {
      setShowRequireStar(false)
    }
  }

  const handleCheckShowRequireStarMagic = () => {
    if (!form.getInputPropsFor('login').value) {
      setShowRequireStarMagic(true)
    } else {
      setShowRequireStarMagic(false)
    }
  }

  const footer = <Button onClick={handleCancelVisitDesktopVersion}>OK</Button>

  return (
    <div className={classes.wrapper}>
      {!magicLoginMode ? (
        <>
          <h2>Publisher Login</h2>

          <div onClick={handleClick}>
            <div
              className={
                IS_PRODUCTION || windowSize.innerWidth < 768
                  ? 'pointer-events-none'
                  : ''
              }>
              <form onSubmit={form.submit}>
                {form.error ? (
                  <FormError>{form.error.message}</FormError>
                ) : null}

                <Field
                  {...form.getErrorPropsFor('email')}
                  label="Email"
                  required={showRequireStar}>
                  <Input
                    {...form.getInputPropsFor('email')}
                    placeholder="Your Email..."
                    type="email"
                    forceDarkMode
                    required
                  />
                </Field>

                <Field
                  {...form.getErrorPropsFor('password')}
                  label="Password"
                  required={showRequireStar}>
                  <Password
                    ref={passwordRef}
                    {...form.getInputPropsFor('password')}
                    placeholder="Your Password..."
                    forceDarkMode
                    required
                  />
                  <div className={classes.forgot}>
                    <a onClick={onForgot}>Forgot your password?</a>
                  </div>
                </Field>
                <Field
                  {...form.getErrorPropsFor('captcha')}
                  label="Prove that you are human"
                  required={showRequireStar}>
                  <CaptchaInput
                    forceDarkMode
                    ref={captchaRef}
                    onLoad={(captcha) => form.change('captchaKey', captcha.key)}
                    {...form.getInputPropsFor('captcha')}
                    required
                  />
                </Field>
                <Button
                  onClick={handleCheckShowRequireStar}
                  loading={form.submitting}
                  type="submit"
                  block>
                  LOG IN!
                </Button>

                <Button
                  onClick={() => {
                    setMagicLoginMode(true)
                  }}
                  className="mt-4 uppercase "
                  block
                  transparent>
                  <span className="text-white">LOG IN VIA E-MAIL</span>
                </Button>
              </form>

              <p className={classes.register}>
                <Button onClick={onRegister} block secondary>
                  Want to Become a Publisher?
                </Button>
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <h2>Magic Login</h2>

          <form onSubmit={form.submit}>
            {form.error ? <FormError>{form.error.message}</FormError> : null}

            <Field
              {...form.getErrorPropsFor('login')}
              label="Email or Username"
              required={showRequireStarMagic}>
              <Input
                {...form.getInputPropsFor('login')}
                placeholder="Your Email or Username..."
                forceDarkMode
                required
              />
            </Field>

            <Field
              {...form.getErrorPropsFor('captcha')}
              label="Prove that you are human"
              required={showRequireStarMagic}>
              <CaptchaInput
                ref={captchaRef}
                onLoad={(captcha) => form.change('captchaKey', captcha.key)}
                {...form.getInputPropsFor('captcha')}
                required
              />
            </Field>

            <Button
              onClick={handleCheckShowRequireStarMagic}
              loading={form.submitting}
              type="submit"
              block>
              LOG IN VIA E-MAIL!
            </Button>

            <div className={classes.loginMode}>
              <a
                onClick={(e) => {
                  setMagicLoginMode(false)
                }}>
                LOG IN
              </a>
            </div>
          </form>
        </>
      )}
      <NotifyModal
        visibleNotify={visibleNotify}
        onSetVisibleNotify={setVisibleNotify}
      />
      <Modal
        closeIconVisible={false}
        onCancel={handleCancelVisitDesktopVersion}
        visible={visibleVisitDesktopVersionModal}
        footer={footer}
        size="sm">
        Please visit our desktop version for login or sign up.
      </Modal>
    </div>
  )
}
