type GetChannelsQueryVariables = {
  includeOwner?: false,
  page?: number,
  search?: string,
  sort?:
    | ''
    | 'most_uploads'
    | 'most_subscribers'
    | 'most_points'
    | 'last_deleted',
  onlyDeleted?: boolean,
}

export default function getChannels(variables: GetChannelsQueryVariables = {}) {
  const { page, search, sort, onlyDeleted } = variables

  const filters = {}

  if (search) {
    filters.search = search
  }

  if (onlyDeleted) {
    filters.only_deleted = onlyDeleted
  }

  return {
    name: 'channels',
    path: '/admin/channels',
    params: {
      page: page > 0 ? page : undefined,
      sort: sort || undefined,
      filters,
    },
  }
}
