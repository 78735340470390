import { useHistory, useParams } from 'react-router'
import useProfile2FA from 'shared/hooks/useProfile2FA'
import Accordion from 'shared/components/kit/accordion'
import Page from 'publisher/components/page'
import AccountDeletion from './components/AccountDeletion'
import Status from './components/Status'
import TwoFactorAuthentication from './components/TwoFactorAuthentication'
import Email from './components/Email'
import Password from './components/Password'

export default function Profile() {
  const { activeTab } = useParams()
  const history = useHistory()
  const { appEnabled, emailEnabled, isLoading: isLoading2FA } = useProfile2FA()

  const handleTabChange = (val) => {
    history.push(`/publisher/profile/${val}`)
  }

  const twoFaActive = emailEnabled || appEnabled

  const twoFactorAuthenticationStatus = (
    <Status
      active={twoFaActive}
      text={twoFaActive ? 'Active' : 'Inactive'}
      extra={twoFaActive ? `(${emailEnabled && appEnabled ? 2 : 1}/2)` : null}
    />
  )

  return (
    <Page title="Security Settings" width="sm">
      <Accordion
        activeName={activeTab}
        onChange={handleTabChange}
        bordered={false}>
        <Accordion.Item name="email" title="Email">
          <Email />
        </Accordion.Item>

        <Accordion.Item name="password" title="Password">
          <Password />
        </Accordion.Item>

        <Accordion.Item
          name="2fa"
          title="Two-Factor Authentication (2FA)"
          extra={twoFactorAuthenticationStatus}>
          {isLoading2FA ? 'Loading...' : <TwoFactorAuthentication />}
        </Accordion.Item>

        <Accordion.Item name="account-deletion" title="Channel Deletion">
          <AccountDeletion />
        </Accordion.Item>
      </Accordion>
    </Page>
  )
}
