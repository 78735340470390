import { useMemo } from 'react'
import { useHistory } from 'react-router'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import CampaignStatus from 'admin/components/campaign-status'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import SelectCryptocurrency from 'shared/components/form/select-cryptocurrency'
import Button from 'shared/components/kit/button'
import SelectCampaignStatus from 'shared/components/form/select-campaign-status'
import RichText from 'shared/components/form/rich-text'
import createCampaign from 'admin/queries/createCampaign'
import Page from 'admin/components/page'
import Index from 'admin/components/form/campaign-image'
import { ReactComponent as CloseSVG } from 'svg/close-icon.svg'
import classes from './CreateCampaign.module.css'

const VALIDATION = {
  exchangeReferralUrl: {
    label: 'Exchange referral url',
    rule: 'required',
  },
  exchangeMainUrl: {
    label: 'Exchange main url',
    rule: 'required',
  },
  exchangeName: {
    label: 'Exchange Name',
    rule: 'required',
  },
  status: {
    label: 'Status',
    rule: 'required',
  },
  name: {
    label: 'Name',
    rule: 'required',
  },
  cryptoCurrencies: {
    label: 'CryptoCurrencies',
    rule: (value) => {
      if (value.length === 0) {
        return 'CryptoCurrencies is required.'
      }
    },
  },
}

export default function CreateCampaign() {
  const history = useHistory()

  const mutation = useMutation(createCampaign, {
    onSuccess(res) {
      history.push(`/admin/ads-manager/campaigns/${res.json.id}/edit`)
    },
  })

  const form = useMiniForm({
    validation: VALIDATION,
    initialValues: useMemo(() => {
      return {
        status: 'draft',
        cryptoCurrencies: [],
      }
    }, []),
    onSubmit(values) {
      try {
        let cryptoCurrencies = values.cryptoCurrencies.map((item) => item.value)
        mutation.mutate({ ...values, cryptoCurrencies })
      } catch (error) {
        throw error
      }
    },
  })

  const removeCoin = (coin) => {
    let coins = form.values.cryptoCurrencies.filter(
      (item) => item.value !== coin.value
    )
    form.change('cryptoCurrencies', coins)
  }

  const title = (
    <>
      <span className="mr-4">New campaign</span>{' '}
      <CampaignStatus status="draft" />{' '}
    </>
  )

  return (
    <Page title={title}>
      <form onSubmit={form.submit}>
        <div className={classes.container}>
          <div className={classes.name}>
            <Field
              label="Campaign name (Internal)"
              {...form.getErrorPropsFor('name')}>
              <Input {...form.getInputPropsFor('name')} />
            </Field>
          </div>
          <div className={classes.id}>
            <Field label="Campaign ID">
              <Input disabled />
            </Field>
          </div>
          <div className={classes.img}>
            <Field label="Image">
              <Index
                className="w-48"
                {...form.getInputPropsFor('thumbnail')}
                placeholder="Choose a Picture"
              />
            </Field>
          </div>
          <div className={classes.exchangeName}>
            <Field
              label="Exchange name"
              {...form.getErrorPropsFor('exchangeName')}>
              <Input {...form.getInputPropsFor('exchangeName')} />
            </Field>
          </div>
          <div className={classes.crypto}>
            <Field
              {...form.getErrorPropsFor('cryptoCurrencies')}
              label="Add or remove cryptocurrencies for this campaign">
              <ul>
                {form?.values?.cryptoCurrencies?.map((coin, key) => {
                  const handleRemove = (event) => {
                    event.preventDefault()
                    removeCoin(coin)
                  }
                  return (
                    <li key={key}>
                      <span>{coin.label}</span>
                      <button onClick={handleRemove}>
                        <CloseSVG />
                      </button>
                    </li>
                  )
                })}
              </ul>
              <SelectCryptocurrency
                {...form.getInputPropsFor('cryptoCurrencies')}
                controlShouldRenderValue={false}
                isClearable={false}
              />
            </Field>
          </div>
          <div className={classes.headline}>
            <Field
              label="Campaign headline"
              {...form.getErrorPropsFor('headline')}>
              <Input {...form.getInputPropsFor('headline')} />
            </Field>
          </div>
          <div className={classes.exchangeUrl}>
            <Field
              label="Main exchange URL (for display only)"
              {...form.getErrorPropsFor('exchangeMainUrl')}>
              <Input {...form.getInputPropsFor('exchangeMainUrl')} />
            </Field>
          </div>
          <div className={classes.status}>
            <Field label="Change status">
              <SelectCampaignStatus {...form.getInputPropsFor('status')} />
            </Field>
          </div>
          <div className={classes.referralUrl}>
            <Field
              label="Referral URL (redirect URL)"
              {...form.getErrorPropsFor('exchangeReferralUrl')}>
              <Input
                type="url"
                {...form.getInputPropsFor('exchangeReferralUrl')}
              />
            </Field>
          </div>
          <div className={classes.description}>
            <Field
              label="Campaign description"
              {...form.getErrorPropsFor('description')}>
              <RichText {...form.getInputPropsFor('description')} />
            </Field>
          </div>
          <div className={classes.actionButtons}>
            <div className={classes.actionButtons}>
              <Button disabled transparent mood="neutral">
                Delete campaign
              </Button>
              <Button>Save changes</Button>
            </div>
          </div>
        </div>
      </form>
    </Page>
  )
}
