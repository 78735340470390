import { useHistory } from 'react-router'
import { useMemo } from 'react'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import formatDate from 'helpers/utils/formatDate'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import formatHTML from 'helpers/utils/formatHTML'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import useQuery from 'core/react-query/useQuery'
import Table from 'shared/components/kit/table'
import getFeedbacks from 'admin/queries/getFeedbacks'
import Page from 'admin/components/page'
import PageHeader from 'admin/components/page-header'
import { ReactComponent as LikeFilledSVG } from 'icons/thumb-up-filled.svg'
import { ReactComponent as DislikeFilledSVG } from 'icons/thumb-down-filled.svg'
import { ReactComponent as StarFilledSVG } from 'icons/star-filled.svg'
import { ReactComponent as CommentSVG } from 'svg/comments.svg'
import { ReactComponent as LinkSVG } from 'svg/link.svg'
import Filters from './components/Filters'
import ContactButton from './components/ContactButton'
import ManageUserDrawer from './components/ManageUserDrawer'
import classes from './index.module.css'

export default function Feedbacks() {
  const INITIAL_FILTERS = useMemo(
    () => ({
      base: '/admin/feedbacks',
      params: {
        page: 'pagination',
        sort: 'any',
        type: 'default | star | thumb',
        location: 'any',
      },
    }),
    []
  )
  const [filters, setFilters] = useFiltersFromURL(INITIAL_FILTERS)
  const { data, error, isError, isFetching } = useQuery(getFeedbacks, {
    keepPreviousData: true,
    variables: {
      page: filters.page,
      type: filters.type,
      location: filters.location,
    },
  })

  const history = useHistory()
  const { queryParams } = useParsedLocation()
  const userId = queryParams['user-id']
    ? Number(queryParams['user-id'])
    : undefined
  const email = queryParams.email
  const {
    data: feedbacks,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleFilter = (nextFilters) => {
    setFilters({
      ...nextFilters,
      page: undefined,
    })
  }

  if (isError) {
    return <Page error={error.message} />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  const handleCloseUserDrawer = () => {
    const searchParams = new URLSearchParams(history.location.search)
    const page = searchParams.get('page')

    history.push(`/admin/feedbacks${page > 1 ? `?page=${page}` : ''}`)
  }

  return (
    <>
      <PageHeader description={description} title="Feedbacks" />
      <Filters initialFilters={filters} onSubmit={handleFilter} />
      <Table
        columns={getColumns(filters)}
        data={feedbacks}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
      <ManageUserDrawer
        userId={userId}
        email={email}
        onClose={handleCloseUserDrawer}
        visible={!!email}
      />
    </>
  )
}

function getColumns(filters) {
  return [
    {
      title: 'Text',
      className: classes.text,
      render: (feedback) => (
        <span dangerouslySetInnerHTML={{ __html: formatHTML(feedback.text) }} />
      ),
    },
    {
      title: 'Location',
      className: classes.location,
      render: (feedback) => feedback.location,
    },
    {
      title: 'Value',
      align: 'center',
      className: classes.value,
      render: (feedback) => {
        if (feedback.type === 'thumb') {
          if (feedback.value === 1) {
            return <LikeFilledSVG className={classes.like} />
          } else if (feedback.value === -1) {
            return <DislikeFilledSVG className={classes.dislike} />
          }
        } else if (feedback.type === 'star') {
          const stars = []
          for (let i = 1; i <= feedback.value; i++) {
            stars.push(<StarFilledSVG className={classes.star} />)
          }
          return stars
        } else if (feedback.type === 'default') {
          return <CommentSVG className={classes.comment} />
        }
      },
    },
    {
      title: 'Origin',
      className: classes.origin,
      render: (feedback) => (
        <a title={feedback.origin} target="_blank" href={feedback.origin}>
          <LinkSVG className={classes.link} />
        </a>
      ),
    },
    {
      title: 'Creation Date',
      className: classes.createdAt,
      render: (feedback) => formatDate(feedback.created_at),
    },
    {
      className: classes.contact,
      render: (feedback) => (
        <ContactButton
          email={feedback.email}
          filters={filters}
          userId={feedback.user_id}
        />
      ),
    },
  ]
}
