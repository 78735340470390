// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reportedVideoReasons_key__ovmL- {\n  width: 33.333333%;\n}\n\n.reportedVideoReasons_value__1WNTR {\n  width: 33.333333%;\n}\n\n.reportedVideoReasons_status__3LRaz {\n  width: 33.333333%;\n}\n\n.reportedVideoReasons_updateReason__mBK5- {\n  width: 33.333333%;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/setting/reportedVideoReasons/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,iBAAmB;ADAnB;;AAEA;ECFA,iBAAmB;ADInB;;AAEA;ECNA,iBAAmB;ADQnB;;AAEA;ECVA,iBAAmB;ADYnB","sourcesContent":[".key {\n  @apply w-1/3;\n}\n\n.value {\n  @apply w-1/3;\n}\n\n.status {\n  @apply w-1/3;\n}\n\n.updateReason {\n  @apply w-1/3;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"key": "reportedVideoReasons_key__ovmL-",
	"value": "reportedVideoReasons_value__1WNTR",
	"status": "reportedVideoReasons_status__3LRaz",
	"updateReason": "reportedVideoReasons_updateReason__mBK5-"
};
export default ___CSS_LOADER_EXPORT___;
