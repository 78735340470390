import { useState } from 'react'
import Icon from 'shared/components/kit/icon'
import Popover from 'shared/components/kit/popover'
import Notifications from 'admin/components/notifications'
import { ReactComponent as NotificationSVG } from 'svg/notification-filled.svg'
import { ReactComponent as NotificationOutlinedSVG } from 'svg/notification-outlined.svg'
import useQuery from 'core/useQuery'
import getUnreadNotifications from 'admin/queries/getUnreadNotifications'
import get from 'lodash/get'
import classes from './index.module.css'

export default function TopNavigation() {
  const [showNotifications, setShowNotifications] = useState(false)

  const { data } = useQuery(getUnreadNotifications)

  const unreadCount = get(data, 'json', {})

  const handleToggleNotifications = (event) => {
    event.preventDefault()
    setShowNotifications((prev) => !prev)
  }

  const handleHideNotifications = () => {
    setShowNotifications(false)
  }

  return (
    <div className={classes.wrapper}>
      <header className={classes.header}>
        <nav className={classes.secondaryMenu}>
          <ul>
            <li className={classes.notifications}>
              <Popover
                className={classes.notificationsPopover}
                content={<Notifications />}
                onHide={handleHideNotifications}
                visible={showNotifications}>
                <a onClick={handleToggleNotifications}>
                  <Icon>
                    {unreadCount.count > 0 ? (
                      <NotificationSVG />
                    ) : (
                      <NotificationOutlinedSVG />
                    )}
                  </Icon>
                  {unreadCount.count > 0 ? (
                    <span className={classes.dot}>{unreadCount.count}</span>
                  ) : (
                    <span />
                  )}
                </a>
              </Popover>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  )
}
