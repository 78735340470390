import Base from '../Base'

export default function AdminMonetizationLayout({ children }) {
  const sidebar = (
    <ul>
      <li>
        <Base.NavLink text="Overview" to="/admin/monetization/overview" exact />
      </li>
      <li>
        <Base.NavLink text="Qualified" to="/admin/monetization/qualified" />
      </li>
      <li>
        <Base.NavLink text="Payouts" to="/admin/monetization/payouts" />
      </li>
      <li>
        <Base.NavLink text="Back" to="/admin" exact />
      </li>
    </ul>
  )
  return <Base main={children} sidebar={sidebar} />
}
