import { useMemo } from 'react'
import dayjs from 'dayjs'
import useQuery from 'core/react-query/useQuery'
import getChannelPerformance from 'publisher/queries/getChannelPerformance'

export default function useChannelTotalPerformance() {
  const from = useMemo(() => dayjs().startOf('month').format('YYYY-MM-DD'), [])
  const to = useMemo(() => dayjs().endOf('month').format('YYYY-MM-DD'), [])

  return useQuery(getChannelPerformance, {
    usePreviousData: true,
    variables: {
      from,
      to,
      type: 'total',
    },
  })
}
