import Channel from './layers/Channel'
import Subscribe from './layers/Subscribe'
import Video from './layers/Video'

export default function VideoPlayerLayer({ layer, onRefresh }) {
  if (layer.type === 'channel') {
    return <Channel onRefresh={onRefresh} slug={layer.data_channel_slug} />
  }

  if (layer.type === 'subscribe') {
    return <Subscribe position={layer.position} />
  }

  if (layer.type === 'video') {
    return <Video onRefresh={onRefresh} urlHash={layer.data_video_url_hash} />
  }

  return null
}
