import Base from '../Base'

export default function AdminSettingLayout({ children }) {
  const sidebar = (
    <ul>
      <li>
        <Base.NavLink
          text="Administrators"
          to="/admin/setting/administrators"
          exact
        />
      </li>
      <li>
        <Base.NavLink
          text="Reported Video Reasons"
          to="/admin/setting/reportedVideoReasons"
        />
      </li>
      <li>
        <Base.NavLink
          text="Reported Comment Reasons"
          to="/admin/setting/reportedCommentReasons"
        />
      </li>
      <li>
        <Base.NavLink
          text="Deleted Video Reasons"
          to="/admin/setting/deleteVideoReasons"
        />
      </li>
      <li>
        <Base.NavLink
          text="Hide Video Reasons"
          to="/admin/setting/hideVideoReasons"
        />
      </li>
      <li>
        <Base.NavLink
          text="Delete Comment Reasons"
          to="/admin/setting/deleteCommentReasons"
        />
      </li>
      <li>
        <Base.NavLink
          text="Publisher Request Reject Reasons"
          to="/admin/setting/publisherRequestRejectReasons"
        />
      </li>
      <li>
        <Base.NavLink text="Security" to="/admin/setting/security" />
      </li>
      <li>
        <Base.NavLink text="Back" to="/admin" exact />
      </li>
    </ul>
  )
  return <Base main={children} sidebar={sidebar} />
}
