import { useMemo, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import Table from 'shared/components/kit/table'
import Page from 'admin/components/page'
import getPayoutItems from 'admin/queries/getPayoutItems'
import getBudgets from 'admin/queries/getBudgets'
import ChannelAvatar from 'shared/components/channel-avatar'
import classes from './index.module.css'
import SelectYears from './components/SelectYears'
import ChannelStatistic from './components/ChannelStatistic'
import AddBudget from './components/AddBudget'
import formatNumber from 'helpers/utils/formatNumber'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import dayjs from 'dayjs'
import classnames from 'classnames'

export default function Overview() {
  const [year, setYear] = useState(dayjs().year())
  const [month, setMonth] = useState(dayjs().month() + 1)
  const [page, setPage] = useState(1)

  const [budgetModalVisible, setBudgetModalVisible] = useState(false)

  const { data, error, isError, isFetching } = useQuery(getPayoutItems, {
    keepPreviousData: true,
    variables: {
      month: year + '-' + month,
      page: page,
      per_page: 50,
    },
  })

  const {
    data: payoutItems,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const { data: budgetsData } = useQuery(getBudgets, {
    keepPreviousData: true,
    variables: {
      year: year,
    },
  })

  const handleYearChange = (year) => {
    setYear(year)
  }

  const handleMonthChange = (month) => {
    setMonth(month)
  }

  const handlePaginate = (page) => {
    setPage(page)
  }

  const handleBudgetModalOpen = () => {
    setBudgetModalVisible(true)
  }

  const handleBudgetModalClose = () => {
    setBudgetModalVisible(false)
  }

  const columns = useMemo(() => getColumns(payoutItems), [payoutItems])
  const budgetMonths = useMemo(
    () => getBudgetsData(budgetsData?.json),
    [budgetsData]
  )

  const actions = <SelectYears onChange={handleYearChange} />
  return (
    <>
      <Page actions={actions} title="Monetizaion Overview">
        <div className={classes.budgetSetting}>
          <h1>Monthly monetization budget - {year}</h1>
          <div>
            {budgetMonths.map((budgetMonth) => {
              const itemClasses = {
                closed: classes.closed,
                locked: classes.locked,
                draft: classes.draft,
              }
              return (
                <div>
                  <div
                    className={classnames(
                      itemClasses[budgetMonth.status],
                      classes.budgetItem
                    )}
                    key={budgetMonth.month}>
                    <span className={classes.budgetValue}>
                      {budgetMonth.amount > 0
                        ? '$' + formatNumber(budgetMonth.amount)
                        : 'NA'}
                    </span>
                    <span className={classes.budgetStatus}>
                      {budgetMonth.status === 'draft' ? (
                        <>&nbsp;</>
                      ) : (
                        budgetMonth.status
                      )}
                    </span>
                    <span className={classes.budgetAction}>
                      {budgetMonth.status === 'draft' ? (
                        <span onClick={handleBudgetModalOpen}>edit</span>
                      ) : (
                        'view'
                      )}
                    </span>
                  </div>
                  <p
                    className="text-center font-bold pt-1 cursor-pointer"
                    onClick={() => {
                      handleMonthChange(budgetMonth.month)
                    }}>
                    {budgetMonth.name}
                  </p>
                </div>
              )
            })}
          </div>
        </div>

        <h3 className="py-4">
          Monetization -{' '}
          {dayjs()
            .month(month - 1)
            .format('MMM')}{' '}
          {year}
        </h3>

        <Table
          columns={columns}
          data={payoutItems}
          loading={isFetching}
          currentPage={currentPage}
          lastPage={lastPage}
          onPaginate={handlePaginate}
        />

        <AddBudget
          year={year}
          month={month + 1}
          visible={budgetModalVisible}
          handleClose={handleBudgetModalClose}
        />
      </Page>
    </>
  )
}

function getBudgetAmountByMonth(budgetsData, month) {
  let monthBudget
  budgetsData.map((budgetData) => {
    if (dayjs(budgetData.month).month() === month) {
      monthBudget = budgetData.budget
    }
  })
  return monthBudget
}

function getColumns(payoutItems) {
  return [
    {
      className: classes.channelName,
      title: 'Channel name',
      render: (payoutItem) => (
        <div className="flex items-center">
          <ChannelAvatar channel={payoutItem.channel} size={14} />{' '}
          <span className="mx-2">{payoutItem.channel.name}</span>
        </div>
      ),
    },
    {
      className: classes.statistic,
      title: 'Statistic',
      render: (payoutItem) => <ChannelStatistic data={payoutItem.metrics} />,
    },
    {
      className: classes.earning,
      title: 'Earning',
      render: (payoutItem) => (
        <span>
          USDT So Far:{' '}
          <span className="font-bold text-xl text-green-500">
            {' '}
            ${formatNumber(payoutItem.amount)}
          </span>
        </span>
      ),
    },
  ]
}

function getBudgetsData(budgetsData) {
  const currentMonth = dayjs().month()

  let budgetMonths = []

  if (budgetsData) {
    for (let i = 0; i < 12; i++) {
      let name = dayjs().month(i).format('MMM')
      let month = i + 1
      let status =
        i < currentMonth ? 'closed' : i === currentMonth ? 'locked' : 'draft'
      let amount = getBudgetAmountByMonth(budgetsData, i)
      budgetMonths.push({
        month: month,
        name: name,
        amount: amount,
        status: status,
      })
    }
  }

  return budgetMonths
}
