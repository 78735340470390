type GetAdminsQueryVariables = {
  page?: number,
  search?: string,
  sort?: string,
  include?: string,
}

export default function getAdmins(variables: GetAdminsQueryVariables = {}) {
  const { page, search, sort, include } = variables

  return {
    name: 'admins',
    path: '/admin/admins',
    params: {
      page,
      sort,
      filters: {
        search,
      },
      include,
    },
  }
}
