import { useEffect, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import get from 'lodash/get'
import Drawer from 'shared/components/kit/drawer'
import Accordion from 'shared/components/kit/accordion'
import getUser from 'admin/queries/getUser'
import MuteUser from './MuteUser'
import UserStats from './UserStats'
import UserInfo from './UserInfo'
import DeleteUser from './DeleteUser'
import HeroMembership from './HeroMembership'
import EditInfo from './EditInfo'
import EarningsHistory from './EarningsHistory'
import ClaimsHistory from './ClaimsHistory'
import classes from './ManageUserDrawer.module.css'

export default function ManageUserDrawer(props) {
  const { userId, onClose, visible } = props

  const [activeName, setActiveName] = useState('')

  const { data, isLoading, error, isError } = useQuery(getUser, {
    enabled: userId > 0,
    variables: {
      id: userId,
    },
  })

  useEffect(() => {
    if (!visible) {
      setActiveName('')
    }
  }, [visible])

  const handleCloseDrawer = () => {
    onClose()
    setActiveName('')
  }

  const handleChangeAccordion = (name) => {
    setActiveName((prevName) => (prevName === name ? '' : name))
  }

  const user = data?.json || { id: userId }

  const userError = get(error, 'json', {})

  return (
    <Drawer
      error={userError}
      isError={isError}
      loading={isLoading}
      onClose={handleCloseDrawer}
      visible={visible}>
      {isLoading === false && isError === false && (
        <>
          <UserInfo user={user} />
          <UserStats user={user} />
          <Accordion
            activeName={activeName}
            className={classes.actions}
            onChange={handleChangeAccordion}>
            <Accordion.Item name="hodl" title="Hodl Membership">
              <HeroMembership user={user} />
            </Accordion.Item>
            <Accordion.Item name="info" title="User Info">
              <EditInfo user={user} />
            </Accordion.Item>
            <Accordion.Item
              name="mute"
              title={user.is_mute ? 'Unmute User' : 'Mute User'}>
              <MuteUser user={user} />
            </Accordion.Item>
            <Accordion.Item
              name="tcg-earnings-history"
              title="TCG Earnings History">
              <EarningsHistory userId={user.id} />
            </Accordion.Item>
            <Accordion.Item name="tcg-claims-history" title="TCG Claim History">
              <ClaimsHistory address={user.wallet} />
            </Accordion.Item>
            <Accordion.Item name="delete" title="Delete User">
              <DeleteUser user={user} />
            </Accordion.Item>
          </Accordion>
        </>
      )}
    </Drawer>
  )
}
