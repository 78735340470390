import useMutation from 'core/react-query/useMutation'
import useProfile2FA from 'shared/hooks/useProfile2FA'
import useRefetchProfile2FA from 'shared/hooks/useRefetchProfile2FA'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import Status from './Status'

export default function EmailVerification() {
  const { emailEnabled } = useProfile2FA()

  return (
    <div className="mb-8">
      <header className="flex items-center">
        <h4 className="mr-4 text-lg">Email Verification</h4>
        <Status
          active={emailEnabled}
          text={emailEnabled ? 'Active' : 'Inactive'}
        />
      </header>
      <p className="mb-4 mt-2 text-neutral text-sm">
        6-digit codes will be sent to your registered email.
      </p>
      {emailEnabled ? (
        <DisableEmailVerification />
      ) : (
        <EnableEmailVerification />
      )}
    </div>
  )
}

function EnableEmailVerification() {
  const refetchProfile2FA = useRefetchProfile2FA()

  const { isLoading, mutate } = useMutation(enable, {
    onSuccess: async () => {
      await refetchProfile2FA()
      notification.success({
        title: 'Email Verification enabled successfully!',
      })
    },
    onError(error) {
      if (error.json.message) {
        notification.error({
          title: error.json.message,
        })
      }
    },
  })

  const handleEnable = () => {
    mutate()
  }

  return (
    <Button loading={isLoading} onClick={handleEnable}>
      Enable
    </Button>
  )
}

function DisableEmailVerification() {
  const refetchProfile2FA = useRefetchProfile2FA()

  const { isLoading, mutate } = useMutation(disable, {
    onSuccess: async () => {
      await refetchProfile2FA()
      notification.success({
        title: 'Email Verification disabled successfully!',
      })
    },
    onError(error) {
      if (error.json.message) {
        notification.error({
          title: error.json.message,
        })
      }
    },
  })

  const handleDisable = () => {
    Modal.open({
      title: 'Disable Email Verification',
      description: 'Are you sure you want to disable Email Verification?',
      okText: 'Disable',
      onOk: () => {
        mutate()
      },
    })
  }

  return (
    <Button loading={isLoading} mood="neutral" onClick={handleDisable}>
      Disable
    </Button>
  )
}

function enable() {
  return {
    method: 'put',
    path: '/2fa/email/enable',
  }
}

function disable() {
  return {
    method: 'put',
    path: '/2fa/email/disable',
  }
}
