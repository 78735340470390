import { useState } from 'react'
import get from 'lodash/get'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import useQuery from 'core/react-query/useQuery'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Radio from 'shared/components/form/radio'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import getPublisherRequests from 'admin/queries/getPublisherRequests'
import rejectPublisherRequest from 'admin/queries/rejectPublisherRequest'
import getReasons from 'admin/queries/getReasons'

export default function RejectRequestButton({ userId, messageId, disabled }) {
  const [step, setStep] = useState('')

  const { data, error, isError, loading, isLoading } = useQuery(getReasons, {
    variables: {
      key: 'publisher_request_reject_reasons',
    },
  })

  const reasons = get(data, 'json', [])

  const form = useMiniForm({
    async onSubmit({ custom, reason }) {
      try {
        await mutation.mutateAsync({
          userId,
          reason: reason >= 0 ? reasons[reason].key : custom,
          messageId: messageId,
        })
        notification.success({
          title: 'Publisher request rejected successfully!',
        })
        setStep('')
        form.reset()
      } catch (error) {
        notification.error({
          title: error.message,
        })
      }
    },
  })

  const queryClient = useQueryClient()

  const mutation = useMutation(rejectPublisherRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries({ query: getPublisherRequests })
    },
  })

  const handleShow = () => {
    setStep('reject')
  }

  const handleCancel = () => {
    setStep('')
    form.reset()
  }

  const handleOk = async () => {
    if (step === 'reject') {
      setStep('reason')
    } else {
      await form.submit()
    }
  }

  const canOk =
    step === 'reject' ||
    form.get('reason') >= 0 ||
    (form.get('reason') === -1 && form.get('custom'))

  return (
    <>
      <Button
        title={disabled && 'This request has already rejected!'}
        disabled={disabled}
        onClick={handleShow}
        size="sm"
        outlined
        secondary>
        Reject
      </Button>
      <Modal
        loading={mutation.isLoading}
        okButtonProps={{
          disabled: !canOk,
        }}
        okText="Reject Request"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Reject Request"
        visible={step !== ''}>
        {step === 'reject' && (
          <p>Are you sure you want to reject this request?</p>
        )}
        {step === 'reason' && (
          <>
            <Field label="What's the reason of rejecting this request?">
              {reasons.map((text, key) => (
                <Radio
                  checked={form.get('reason') === key}
                  onChange={() => form.change('reason', key)}
                  description={text.value}
                  key={key}
                />
              ))}
              <Radio
                checked={form.get('reason') === -1}
                onChange={() => form.change('reason', -1)}
                description="Other..."
              />
            </Field>

            {form.get('reason') === -1 && (
              <Field>
                <Input
                  {...form.getInputPropsFor('custom')}
                  placeholder="Add your custom reason here..."
                  multiline
                />
              </Field>
            )}
          </>
        )}
      </Modal>
    </>
  )
}
