type DeleteCommentMutationVariables = {
  id: number,
}

export default function deleteComment(
  variables: DeleteCommentMutationVariables = {}
) {
  return {
    method: 'delete',
    url: `/admin/comments/${variables.id}`,
    variables,
  }
}
