import { useMemo } from 'react'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
import useQuery from 'core/react-query/useQuery'
import getChannelEarnings from 'admin/queries/getChannelEarnings'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import formatDate from 'helpers/utils/formatDate'
import formatPrice from 'helpers/utils/formatPrice'
import Table from 'shared/components/kit/table'
import EarningStatus from 'shared/components/earning-status'
import Page from 'admin/components/page'
import Filters from './components/Filters'
import ManualPayment from './components/ManualPayment'

export default function ChannelEarnings() {
  const params = useParams()

  const channelId = Number(params.id)

  const FILTER_CONFIG = useMemo(
    () => ({
      base: `/admin/channels/${channelId}/earnings`,
      params: {
        status: 'string',
        page: 'pagination',
      },
    }),
    [channelId]
  )

  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)

  const { data, error, isError, isFetching } = useQuery(getChannelEarnings, {
    keepPreviousData: true,
    variables: {
      includeTransaction: true,
      channelId,
      status: filters.status,
      page: filters.page,
    },
  })

  const {
    data: earnings,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  if (isError) {
    return <Page error={error.message} />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  return (
    <Page description={description} title="Channel Earnings">
      <Filters initialFilters={filters} onSubmit={setFilters} />
      <Table
        columns={columns}
        data={earnings}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
    </Page>
  )
}

function getColumns() {
  return [
    {
      title: 'Month',
      render: (earning) => dayjs(earning.date).format('MMMM YYYY'),
    },
    {
      title: 'Amount',
      render: (earning) => formatPrice(earning.amount),
    },
    {
      align: 'center',
      render: (earning) => <EarningStatus status={earning.status} />,
    },
    {
      title: 'Description',
      render: (earning) =>
        earning.transaction
          ? earning.status === 'pending'
            ? 'Payment started at ' + formatDate(earning.transaction.created_at)
            : 'Payed at ' + formatDate(earning.transaction.completed_at)
          : null,
    },
    {
      render: (earning) =>
        earning.status === 'pending' && (
          <ManualPayment initialValues={{ id: earning.id }} />
        ),
    },
  ]
}
