// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "ul.MobileFAQ_wrapper__36qid {\n  border-radius: 0.5rem;\n  border-width: 2px;\n  padding: 1rem;\n}\n\n.MobileFAQ_wrapper__36qid li {\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n  position: relative;\n}\n\n.MobileFAQ_actions__3pnQJ {\n  display: flex;\n  align-items: flex-end;\n  justify-content: flex-end;\n  margin-top: 2rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/faq/components/MobileFAQ/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,qBAAmB;EAAnB,iBAAmB;EAAnB,aAAmB;ADAnB;;AAEA;ECFA,iBAAmB;EAAnB,oBAAmB;EAAnB,kBAAmB;ADInB;;AAEA;ECNA,aAAmB;EAAnB,qBAAmB;EAAnB,yBAAmB;EAAnB,gBAAmB;ADQnB","sourcesContent":["ul.wrapper {\n  @apply border-2 rounded-lg p-4;\n}\n\n.wrapper li {\n  @apply relative py-4;\n}\n\n.actions {\n  @apply flex justify-end items-end mt-8;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "MobileFAQ_wrapper__36qid",
	"actions": "MobileFAQ_actions__3pnQJ"
};
export default ___CSS_LOADER_EXPORT___;
