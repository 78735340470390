// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".truncated-list_more__5ddtL {\n  cursor: pointer;\n  color: var(--primary-color-default);\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/kit/truncated-list/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,eAAmB;EAAnB,mCAAmB;EAAnB,mBAAmB;ADAnB","sourcesContent":[".more {\n  @apply cursor-pointer text-primaryColor-default whitespace-nowrap;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"more": "truncated-list_more__5ddtL"
};
export default ___CSS_LOADER_EXPORT___;
