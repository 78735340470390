// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".video_video__2OJYu {\n  padding: 2rem;\n  padding-top: 0px;\n  text-align: center;\n}\n\n.video_videoInfo__2znrH > :not([hidden]) ~ :not([hidden]) {\n  --tw-space-y-reverse: 0;\n  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));\n  margin-bottom: calc(1rem * var(--tw-space-y-reverse));\n}\n\n.video_videoInfo__2znrH h3 {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  line-height: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/components/layouts/video/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,gBAAmB;EAAnB,kBAAmB;ADAnB;;ACAA;EAAA,uBAAmB;EAAnB,4DAAmB;EAAnB;AAAmB;;ADMnB;ECNA,eAAmB;EAAnB,mBAAmB;EAAnB,mBAAmB;ADQnB","sourcesContent":[".video {\n  @apply p-8 pt-0 text-center;\n}\n\n.videoInfo {\n  @apply space-y-4;\n}\n\n.videoInfo h3 {\n  @apply leading-6 text-base;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video": "video_video__2OJYu",
	"videoInfo": "video_videoInfo__2znrH"
};
export default ___CSS_LOADER_EXPORT___;
