import { useHistory } from 'react-router'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import getUserAvatar from 'helpers/utils/getUserAvatar'
import getUserName from 'helpers/utils/getUserName'
import formatDate from 'helpers/utils/formatDate'
import formatHTML from 'helpers/utils/formatHTML'
import truncate from 'helpers/utils/truncate'
import Drawer from 'shared/components/kit/drawer'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import getNotification from 'admin/queries/getNotification'
import ArchiveNotificationButton from './ArchiveNotificationButton'
import RestoreNotificationButton from './RestoreNotificationButton'
import classes from './Notification.module.css'

export default function Notification({ notificationId }) {
  const history = useHistory()
  const { data, isLoading, error, isError } = useQuery(getNotification, {
    enabled: typeof notificationId !== 'undefined',
    variables: {
      id: notificationId,
    },
  })
  const notification = get(data, 'json')
  const handleCloseDrawer = () => {
    history.push('/admin/notifications')
  }

  return (
    <Drawer onClose={handleCloseDrawer} visible={!!notificationId}>
      {notification ? (
        <div className={classes.wrapper}>
          <h2>
            {notification.payload.subject}
            {!notification.deleted_at && (
              <ArchiveNotificationButton notificationId={notification.id} />
            )}
            {notification.deleted_at && (
              <RestoreNotificationButton notification={notification} />
            )}
          </h2>
          <br />
          <header>
            <img src={getUserAvatar(notification.from?.avatar)} />
            <span>{getUserName(notification.from ?? {})}</span>
            <span>{formatDate(notification.created_at)}</span>
          </header>
          <p
            dangerouslySetInnerHTML={{
              __html: formatHTML(notification.payload.message),
            }}
          />
          <span className="block text-gray-400 mt-8">To:</span>
          <div className={classes.wrapperUsersInfos}>
            {notification.user_group === 'custom' &&
              notification.to.map((user, index) => (
                <div className={classes.userInfoBox} key={index}>
                  <img src={getUserAvatar(user?.avatar)} />
                  <div>
                    <div className="mb-1">{user.username}</div>
                    <div title={user.email?.length > 35 ? user.email : ''}>
                      {truncate(user.email, 35)}
                    </div>
                  </div>
                </div>
              ))}
            {notification.user_group === 'hero' && 'Hero Users'}
            {notification.user_group === 'non-hero' && 'Non Hero Users'}
            {notification.user_group === 'all' &&
              notification.scope === 'global' &&
              'All Users'}
            {notification.user_group === 'all' &&
              notification.scope === 'publisher' &&
              'All Publishers'}
          </div>
        </div>
      ) : !isError ? (
        <div className={classes.loading}>
          <Icon>
            <Spin />
          </Icon>
          <div>Loading...</div>
        </div>
      ) : null}
    </Drawer>
  )
}
