import Chapter from './Chapter'
import classes from './Chapters.module.css'

type Props = {
  duration: number,
  videoId: number,
}

export default function Chapters(props: Props) {
  const { chapters: rawChapters, duration, videoId } = props

  const chapters = getNormalizedChapters(rawChapters, duration)

  return (
    <div className={classes.wrapper}>
      {chapters.length > 0 && (
        <ul className={classes.chapters}>
          {chapters.map((chapter, index) => (
            <Chapter
              key={chapter.id}
              chapter={chapter}
              chapterNumber={index + 1}
              videoId={videoId}
            />
          ))}
        </ul>
      )}
    </div>
  )
}

function getNormalizedChapters(chapters, duration) {
  const result = []

  for (let i = 0; i < chapters.length; i++) {
    let to

    if (i === chapters.length - 1) {
      to = duration
    } else {
      to = chapters[i + 1].from
    }

    result.push({
      ...chapters[i],
      to,
    })
  }

  return result.map((chapter) => ({
    ...chapter,
    width: `${((chapter.to - chapter.from) / duration) * 100}%`,
  }))
}
