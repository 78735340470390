import { useMemo } from 'react'
import Chart from 'shared/components/chart'
import useGetCSSColor from 'shared/hooks/useGetCSSColor'

export default function LikesChart({ data, labels, getDate }) {
  const getCSSColor = useGetCSSColor()

  const config = useMemo(() => {
    return {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Likes',
            backgroundColor: getCSSColor('success'),
            data: data.map((item) => item.likes_total),
            fill: true,
          },
          {
            label: 'Dislikes',
            backgroundColor: getCSSColor('primary'),
            data: data.map((item) => 1 * item.dislikes_total),
            fill: true,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          title: {
            text: 'Likes / Dislikes',
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              footer: (tooltipItems) => {
                let normal = 0,
                  hero = 0
                tooltipItems.forEach((tooltipItem) => {
                  if (tooltipItem.datasetIndex === 0) {
                    normal += data[tooltipItem.dataIndex].likes_non_hero
                    hero += data[tooltipItem.dataIndex].likes_hero
                  } else if (tooltipItem.datasetIndex === 1) {
                    normal += data[tooltipItem.dataIndex].dislikes_non_hero
                    hero += data[tooltipItem.dataIndex].dislikes_hero
                  }
                })
                return `Users: ${normal}\nHodls: ${hero}`
              },
              label: (context) =>
                `${context.dataset.label}: ${Math.abs(context.raw)}`,
              title: (tooltipItems) =>
                getDate(data[tooltipItems[0].dataIndex].date),
            },
          },
        },
        scales: {
          x: {
            stacked: false,
          },
          y: {
            beginAtZero: true,
            stacked: false,
            ticks: {
              callback: function (value) {
                if (value % 1 === 0) {
                  return value
                }
              },
            },
          },
        },
      },
    }
  }, [getCSSColor, data, labels, getDate])

  return <Chart config={config} />
}
