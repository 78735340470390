type ChannelStatisticsOverView = {
  from: string,
  to: string,
}

export default function getChannelStatisticsOverview(
  variables: ChannelStatisticsOverView = {}
) {
  const { from, to } = variables

  const filters = {}
  if (from) {
    filters.from = from
  }

  if (to) {
    filters.to = to
  }

  return {
    name: 'channelStatisticsOverview',
    path: `/admin/channels/statistics/total`,
    params: {
      filters: {
        from,
        to,
      },
    },
  }
}
