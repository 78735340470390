// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UsersList_avatar__DmWZY {\n  width: 5rem;\n}\n\n.UsersList_avatar__DmWZY img {\n  border-radius: 9999px;\n  display: block;\n  max-height: 2.5rem;\n  max-width: 2.5rem;\n  min-height: 2.5rem;\n  min-width: 2.5rem;\n}\n\n.UsersList_wrapper__2eyl- {\n  display: flex;\n}\n\n.UsersList_wrapper__2eyl- > button {\n  margin-left: 0.5rem;\n}\n\n.UsersList_badges__3vWsh {\n  display: flex;\n}\n\n.UsersList_badgesPopover__3OyFi {\n  padding: 0px;\n}\n\n.UsersList_svgTags__1XyMk {\n  margin-left: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/users/components/UsersList.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,WAAmB;ADAnB;;AAEA;ECFA,qBAAmB;EAAnB,cAAmB;EDIjB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;ECVA,aAAmB;ADYnB;;AAEA;ECdA,mBAAmB;ADgBnB;;AAEA;EClBA,aAAmB;ADoBnB;;AAEA;ECtBA,YAAmB;ADwBnB;;AAEA;EC1BA,mBAAmB;AD4BnB","sourcesContent":[".avatar {\n  @apply w-20;\n}\n\n.avatar img {\n  @apply block rounded-full;\n  max-height: 2.5rem;\n  max-width: 2.5rem;\n  min-height: 2.5rem;\n  min-width: 2.5rem;\n}\n\n.wrapper {\n  @apply flex;\n}\n\n.wrapper > button {\n  @apply ml-2;\n}\n\n.badges {\n  @apply flex;\n}\n\n.badgesPopover {\n  @apply p-0;\n}\n\n.svgTags {\n  @apply ml-2;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "UsersList_avatar__DmWZY",
	"wrapper": "UsersList_wrapper__2eyl-",
	"badges": "UsersList_badges__3vWsh",
	"badgesPopover": "UsersList_badgesPopover__3OyFi",
	"svgTags": "UsersList_svgTags__1XyMk"
};
export default ___CSS_LOADER_EXPORT___;
