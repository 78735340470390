import classnames from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import EditOverlay from './EditOverlay'
import useTimelineActions from '../hooks/useTimelineActions'
import useTimelineData from '../hooks/useTimelineData'
import classes from './TimelineOverlays.module.css'

const MIN_LENGTH = 4

export default function TimelineOverlays() {
  const { duration, overlays, overlayErrors } = useTimelineData()

  return (
    <>
      {overlays.map((overlay) => (
        <TimelineOverlay
          key={overlay.id}
          data={overlay}
          error={!!overlayErrors[overlay.id]}
          loading={false}
          videoDuration={duration}
        />
      ))}
    </>
  )
}

function TimelineOverlay(props) {
  const { data, error, videoDuration } = props

  const [isEditMode, setIsEditMode] = useState(false)

  const { updateOverlay } = useTimelineActions()

  const boxRef = useRef()
  const wrapperRef = useRef()

  const max = videoDuration
  const hasValidInitialRange = !!(
    data &&
    typeof data.start === 'number' &&
    typeof data.end === 'number'
  )
  const initialStart = hasValidInitialRange ? data.start : max - 20
  const initialEnd = hasValidInitialRange ? data.end : max
  const [range, setRange] = useState(() => [initialStart, initialEnd])

  useEffect(() => {
    setRange([initialStart, initialEnd])
  }, [initialStart, initialEnd])

  const start = range && typeof range[0] === 'number' ? range[0] : 0
  const end = range && typeof range[1] === 'number' ? range[1] : max

  const getWrapperWidth = () => wrapperRef.current.getBoundingClientRect().width

  const handleSaveRange = (nextRange) => {
    updateOverlay(data.id, (prev) => ({
      ...prev,
      start: nextRange[0],
      end: nextRange[1],
    }))
  }

  const handleResizeFromLeft = (event) => {
    event.stopPropagation()

    let nextRange = [...range]
    let initialX = event.clientX
    let wrapperWidth = getWrapperWidth()

    function handleMouseMove(event) {
      const diffX = event.clientX - initialX
      initialX = event.clientX
      setRange((prev) => {
        const nextStart = prev[0] + (diffX * max) / wrapperWidth
        nextRange = [limitNumber(nextStart, 0, prev[1] - MIN_LENGTH), prev[1]]
        return nextRange
      })
    }

    function handleMouseUp() {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
      handleSaveRange(nextRange)
    }

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
  }

  const handleResizeFromRight = (event) => {
    event.stopPropagation()

    let nextRange = [...range]
    let initialX = event.clientX
    let wrapperWidth = getWrapperWidth()

    function handleMouseMove(event) {
      const diffX = initialX - event.clientX
      initialX = event.clientX
      setRange((prev) => {
        const nextEnd = prev[1] - (diffX * max) / wrapperWidth
        nextRange = [prev[0], limitNumber(nextEnd, prev[0] + MIN_LENGTH, max)]
        return nextRange
      })
    }

    function handleMouseUp() {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
      handleSaveRange(nextRange)
    }

    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('mousemove', handleMouseMove)
  }

  const handleMove = (event) => {
    event.stopPropagation()

    let nextRange = [...range]
    let initialX = event.clientX
    let boxWidth = boxRef.current.getBoundingClientRect().width

    function handleMouseMove(event) {
      const diffX = ((event.clientX - initialX) * max) / getWrapperWidth()
      const length = (boxWidth * max) / getWrapperWidth()
      initialX = event.clientX
      setRange((prev) => {
        nextRange = [
          limitNumber(prev[0] + diffX, 0, max - length),
          limitNumber(prev[1] + diffX, length, max),
        ]
        return nextRange
      })
    }

    function handleMouseUp() {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
      handleSaveRange(nextRange)
    }

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
  }

  const handleDoubleClick = () => {
    if (data.type !== 'subscribe') {
      setIsEditMode(true)
    }
  }

  const handleCancelEdit = useCallback(() => setIsEditMode(false), [])

  const wrapperClassNames = classnames(classes.wrapper, {
    [classes.error]: error,
  })

  return (
    <>
      <div className={wrapperClassNames}>
        <div className={classes.row} ref={wrapperRef}>
          <div
            className={classes.range}
            style={{
              left: `${(start * 100) / max}%`,
              right: `${100 - (end * 100) / max}%`,
            }}>
            <span
              className={classnames(classes.box, classes[data.type])}
              onDoubleClick={handleDoubleClick}
              onMouseDown={handleMove}
              ref={boxRef}>
              <span>{data.type}</span>
            </span>
            <span
              className={classes.leftResizer}
              onMouseDown={handleResizeFromLeft}
            />
            <span
              className={classes.rightResizer}
              onMouseDown={handleResizeFromRight}
            />
          </div>
        </div>
      </div>

      <EditOverlay
        onCancel={handleCancelEdit}
        overlay={data}
        visible={isEditMode}
      />
    </>
  )
}

function limitNumber(num, min, max) {
  if (num < min) {
    return min
  }
  if (num > max) {
    return max
  }
  return num
}
