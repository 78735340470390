type GetChannelStatistics = {
  id: number,
  range: string,
  from: string,
  to: string,
}

export default function getChannelStatistics(
  variables: GetChannelStatistics = {}
) {
  const { id, range, from, to } = variables

  const filters = {}
  if (from) {
    filters.from = from
  }

  if (to) {
    filters.to = to
  }

  return {
    name: 'channelStatistics',
    path: `/admin/channels/${id}/statistics/${range}`,
    params: {
      filters: {
        from,
        to,
      },
    },
  }
}
