import Button from 'shared/components/kit/button'
import UploadTabs from './UploadTabs'
import classes from './BannerAdsCreateCampaign.module.css'

export default function Step2({ changeStep, form, selectedSlots }) {
  return (
    <div className={classes.step2}>
      <UploadTabs selectedSlots={selectedSlots} />
      <div className={classes.actions}>
        <Button type="button" mood="neutral" onClick={() => changeStep('1')}>
          back
        </Button>
        <Button>Save Changes</Button>
        <Button
          disabled={!form.getInputPropsFor('companyId').value}
          onClick={() => changeStep('3')}
          mood="secondary">
          Continue
        </Button>
      </div>
    </div>
  )
}
