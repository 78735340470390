import { useState } from 'react'
import { Link } from 'react-router-dom'
import useQuery from 'core/react-query/useQuery'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import Tabs from 'shared/components/kit/tabs'
import notification from 'shared/components/kit/notification'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import updateBannerAdsSettings from 'admin/queries/updateBannerAdsSettings'
import getAdsSettings from 'admin/queries/getAdsSettings'
import SelectTier from './components/SelectTier'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import useProps from './hooks/useProps'
import InvoiceOffer from './components/InvoiceOffer'
import CpmTiers from './components/CpmTiers'
import classes from './index.module.css'

export default function Settings() {
  const queryClient = useQueryClient()
  const [values, setValues] = useState({ tiersData: [], tiersDiscounts: [] })
  const [status, setStatus] = useState()
  const [error, setError] = useState()
  const [activeTab, setActiveTab] = useState('banner')
  const [historyModalVisible, setHistoryModalVisible] = useState(false)

  const { getInputPropsForByIndex, getErrorPropsFor } = useProps({
    setValues,
    values,
    error,
  })

  const {
    data,
    error: DataError,
    isError,
    isLoading,
  } = useQuery(getAdsSettings, {
    onMutate: () => {
      setStatus('loading')
    },
    onSuccess: (res) => {
      setError(false)
      setStatus('success')
      setValues({
        tiersData: res.json.tiers_data,
        tiersDiscounts: res.json.tiers_discounts,
      })
    },
    onError: (er) => {
      setError(true)
      setStatus('error')
    },
  })
  const mutation = useMutation(updateBannerAdsSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getAdsSettings,
      })
      notification.success({
        title: 'Banner Ads setting updated successfully!',
      })
      setStatus('success')
    },
    onError: (err) => {
      setError(err)
    },
  })

  const handleTabChange = (val) => {
    setActiveTab(val)
  }

  const handleAddCPM = () => {
    setValues((prev) => {
      return {
        ...prev,
        tiersDiscounts: [
          ...prev.tiersDiscounts,
          {
            tier: '1',
            amount: '0',
            start_at: null,
            end_at: null,
            type: 'fixed',
          },
        ],
      }
    })
  }

  const handleShowHistory = () => {
    setHistoryModalVisible(true)
  }

  const handleCancel = () => {
    setHistoryModalVisible(false)
  }

  const handleSave = () => {
    try {
      const timeFormattedTiersDiscounts = values.tiersDiscounts.map((item) => {
        if (item.start_at) {
          let startAt = new Date(item.start_at)
          let year = startAt.getFullYear()
          let month =
            Number(startAt.getMonth() + 1) < 10
              ? '0' + (startAt.getMonth() + 1).toString()
              : startAt.getMonth() + 1
          let day =
            Number(startAt.getDate()) < 10
              ? '0' + startAt.getDate().toString()
              : startAt.getDate()
          item.start_at = `${year}-${month}-${day}`
        }
        if (item.end_at) {
          let endAt = new Date(item.end_at)
          let year = endAt.getFullYear()
          let month =
            Number(endAt.getMonth() + 1) < 10
              ? '0' + (endAt.getMonth() + 1).toString()
              : endAt.getMonth() + 1
          let day =
            Number(endAt.getDate()) < 10
              ? '0' + endAt.getDate().toString()
              : endAt.getDate()
          item.end_at = `${year}-${month}-${day}`
        }
        return item
      })
      mutation.mutate({
        tiersData: values.tiersData,
        tiersDiscounts: timeFormattedTiersDiscounts,
      })
    } catch (err) {
      setStatus('error')
      setError(err)
    }
  }

  if (isLoading) {
    return (
      <div className={classes.isLoading}>
        <Icon>
          <Spin />
        </Icon>
        <div>Loading...</div>
      </div>
    )
  }

  return (
    <div>
      <Tabs activeName={activeTab} onTabChange={handleTabChange}>
        <Tabs.Tab name="banner" title="banner ads">
          <div className={classes.wrapper}>
            <CpmTiers error={error} setValues={setValues} values={values} />

            {values?.tiersDiscounts &&
              values.tiersDiscounts.map((item, index) => (
                <SelectTier
                  item={item}
                  key={index}
                  index={index}
                  getInputPropsForByIndex={getInputPropsForByIndex}
                  getErrorPropsFor={getErrorPropsFor}
                  values={values}
                  setValues={setValues}
                />
              ))}
            <div className={classes.actionsRow}>
              <Link to="#" onClick={handleAddCPM}>
                + Add CPM discount period
              </Link>
              <Link className="pr-14" to="#" onClick={handleShowHistory}>
                View discount history
              </Link>
            </div>
            <InvoiceOffer error={error} setValues={setValues} values={values} />
            <div className={classes.footerActions}>
              <Button
                loading={mutation.isLoading}
                disabled={mutation.isLoading}
                onClick={handleSave}>
                Save changes
              </Button>
            </div>
          </div>
        </Tabs.Tab>
        <Tabs.Tab name="buy" title="Buy buttons">
          Buy buttons
        </Tabs.Tab>
      </Tabs>
      <Modal
        title="View discount history"
        visible={historyModalVisible}
        onCancel={handleCancel}>
        View discount history
      </Modal>
    </div>
  )
}
