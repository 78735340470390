// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoginMethod_wallet__27Cs9 {\n  --tw-text-opacity: 1;\n  color: rgba(236, 72, 153, var(--tw-text-opacity));\n}\n\n.LoginMethod_email__1Gg5z {\n  color: var(--primary);\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/users/components/LoginMethod.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,oBAAmB;EAAnB,iDAAmB;ADAnB;;AAEA;ECFA,qBAAmB;ADInB","sourcesContent":[".wallet {\n  @apply text-pink-500;\n}\n\n.email {\n  @apply text-primary;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wallet": "LoginMethod_wallet__27Cs9",
	"email": "LoginMethod_email__1Gg5z"
};
export default ___CSS_LOADER_EXPORT___;
