// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManagePublisherDrawer_actions__2mHXX {\n  border-right-width: 0px !important;\n  border-left-width: 0px !important;\n  margin-left: -2rem !important;\n  margin-right: -2rem !important;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/channel/overview/components/ManagePublisherDrawer.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,kCAAmB;EAAnB,iCAAmB;EAAnB,6BAAmB;EAAnB,8BAAmB;ADAnB","sourcesContent":[".actions {\n  @apply border-l-0 border-r-0 -mx-8 !important;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "ManagePublisherDrawer_actions__2mHXX"
};
export default ___CSS_LOADER_EXPORT___;
