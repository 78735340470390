import { useState } from 'react'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import deletePlaylist from 'publisher/queries/deletePlaylist'
import getMyPlaylists from 'publisher/queries/getMyPlaylists'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'

export default function DeletePlaylistButton({ id, onDeleted }) {
  const queryClient = useQueryClient()

  const mutation = useMutation(deletePlaylist, {
    onSuccess() {
      queryClient.invalidateQueries({
        query: getMyPlaylists,
      })
    },
  })

  const [visible, setVisible] = useState(false)

  const handleCancel = () => {
    setVisible(false)
  }

  const handleOk = async () => {
    try {
      await mutation.mutateAsync({
        id,
      })
      notification.success({
        title: 'Playlist is deleted successfully!',
      })
      if (typeof onDeleted === 'function') {
        onDeleted()
      }
    } catch (error) {
      notification.error({
        title: error.message,
      })
      setVisible(false)
    }
  }

  return (
    <>
      <Button onClick={() => setVisible(true)}>Delete Playlist</Button>
      <Modal
        okText="Delete Playlist"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        submitting={mutation.isLoading}
        title="Delete Playlist"
        visible={visible}>
        Are you sure you want to delete this playlist?
        <br />
        This action can't be undone.
      </Modal>
    </>
  )
}
