type SendUserNotificationMutationVariables = {
  audienceType: string,
  message: string,
  subject: string,
  userGroup: string,
  userIds: Array<number>,
  userId: number,
}

export default function SendUserNotification(
  variables: SendUserNotificationMutationVariables
) {
  let { audienceType, message, subject, userGroup, userIds, userId } = variables
  if (audienceType === 'single') {
    userIds = [userId]
    userGroup = 'custom'
  } else if (audienceType === 'many' && userGroup !== 'custom') {
    userIds = []
  }

  return {
    method: 'post',
    url: '/admin/notifications/user',
    variables: {
      user_group: userGroup,
      user_ids: userIds,
      message,
      subject,
    },
  }
}
