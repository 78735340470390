type CreateUserMutationVariables = {
  username: string,
  email: string,
  role: string,
}

export default function createUser(variables: CreateUserMutationVariables) {
  return {
    method: 'post',
    url: '/admin/users',
    variables,
  }
}
