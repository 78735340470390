import { QueryClientProvider as Provider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

export default function QueryClientProvider({ children, client }) {
  return (
    <Provider client={client}>
      {children}
      <ReactQueryDevtools />
    </Provider>
  )
}
