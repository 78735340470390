import { useMemo, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import Table from 'shared/components/kit/table'
import Page from 'admin/components/page'
import getCoinsWithActiveCampaigns from 'admin/queries/getCoinsWithActiveCampaigns'
import Filters from './Filter'
import classes from './CoinsActiveCampaigns.module.css'

export default function CoinsActiveCampaigns() {
  const INITIAL_FILTERS = {
    page: 1,
    search: '',
  }
  const [filters, setFilters] = useState(INITIAL_FILTERS)

  const { data, isFetching, error, isError } = useQuery(
    getCoinsWithActiveCampaigns,
    {
      keepPreviousData: true,
      variables: {
        page: filters.page,
        search: filters.search,
      },
    }
  )

  const {
    data: campaigns,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleFilter = (nextFilters) => {
    setFilters({
      ...nextFilters,
      page: undefined,
    })
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  if (isError) {
    return <Page error={error.message} />
  }

  return (
    <Page title="Coins with active Campaigns" description={description}>
      <Filters onSubmit={handleFilter} />
      <Table
        columns={columns}
        data={campaigns}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
    </Page>
  )
}

function getColumns() {
  return [
    {
      className: classes.thumbnail,
      render: (crypto) => (
        <img
          className="rounded-full"
          src={crypto?.thumbnails.small}
          alt={crypto?.name}
        />
      ),
    },
    {
      title: 'Name',
      className: classes.name,
      render: (crypto) => (
        <div className={classes.cryptoName}>
          <span>{crypto.name}</span>
          <small className="text-neutral">{crypto.symbol}</small>
        </div>
      ),
    },
    {
      title: 'Campaigns',
      className: classes.campaigns,
      render: (crypto) =>
        crypto.active_campaigns.map((item, index) => (
          <div key={index}>{item.name}</div>
        )),
    },
  ]
}
