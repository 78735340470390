import { QueryClient } from 'react-query'
import createFetcher from './createFetcher'

export default function createQueryClient(config) {
  const { apiEndpoint, middleware, headers, ...clientConfig } = config

  const queryClient = new QueryClient(clientConfig)

  queryClient.fetcher = createFetcher({
    apiEndpoint,
    middleware,
    headers,
  })

  return queryClient
}
