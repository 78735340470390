import Select from 'shared/components/form/select'

export default function SelectLoginMethod(props) {
  const options = [
    {
      label: 'Login with wallet members',
      value: 'wallet',
    },
    {
      label: 'Login with email members',
      value: 'email',
    },
  ]

  return (
    <Select {...props} options={options} placeholder="Select Login Type..." />
  )
}
