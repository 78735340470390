import { useParams } from 'react-router'
import useQueryClient from 'core/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import notification from 'shared/components/kit/notification'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import getPaymentDetail from 'admin/queries/getPaymentDetail'
import getPaymentDetails from 'admin/queries/getPaymentDetails'
import unArchivePaymentDetail from 'admin/queries/unArchivePaymentDetail'

export default function UnArchivePaymentDetailButton({ paymentDetailId }) {
  const { status, onlyArchived, publisherId, sort, page } = useParams()
  const queryClient = useQueryClient()
  const mutation = useMutation(unArchivePaymentDetail, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getPaymentDetail,
        variables: { id: paymentDetailId },
      })
      queryClient.invalidateQueries({
        query: getPaymentDetails,
        variables: {
          status,
          only_archived: onlyArchived,
          publisher_id: publisherId,
          sort,
          page,
        },
      })
      notification.success({
        title: 'Payment detail unarchived successfully!',
      })
    },
  })

  const handleUnArchiveClick = async (e) => {
    e.preventDefault()
    Modal.open({
      title: 'Unarchived Payment Detail',
      description: 'Are you sure you want to unarchived?',
      onOk: async () => {
        try {
          await mutation.mutateAsync({
            ids: [paymentDetailId],
          })
        } catch (error) {
          notification.error({ title: error.message })
        }
      },
    })
  }

  return (
    <Button onClick={handleUnArchiveClick} outlined secondary size="sm">
      Unarchived
    </Button>
  )
}
