import Tag from 'shared/components/kit/tag'
import classnames from 'classnames'

const STATUSES = {
  pending: {
    color: 'bg-blue-400',
    label: 'Pending',
  },
  pending_blockchain: {
    color: 'bg-blue-400',
    label: 'Processing',
  },
  canceled: {
    color: 'bg-gray-400',
    label: 'Canceled',
  },
  completed: {
    color: 'bg-green-400',
    label: 'Completed',
  },
  failed: {
    color: 'bg-red-400',
    label: 'Failed',
  },
}

export default function PaymentStatus({ status, className }) {
  const data = STATUSES[status]
  return <Tag type={classnames(data.color, className)}>{data.label}</Tag>
}
