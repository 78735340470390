import { useState } from 'react'
import Tabs from 'shared/components/kit/tabs'
import Input from 'shared/components/form/input'
import Field from 'shared/components/form/field'
import Checkbox from 'shared/components/form/checkbox'
import AdItem from './AdItem'
import classes from './UploadTab.module.css'

export default function UploadTab(props) {
  const {
    adLocationText,
    adKeyDesktop,
    adKeyMobile,
    adItemDesktopTitle,
    adItemMobileTitle,
    adItemWidthDesktop,
    adItemWidthMobile,
    adItemHeightDesktop,
    adItemHeightMobile,
    tierName,
    uploaderText,
  } = props
  const [activeTab, setActiveTab] = useState('desktop')

  const handleTabChange = (val) => {
    setActiveTab(val)
  }

  return (
    <div className="mb-12">
      <h3 className={classes.subHeader}>Upload and Configure</h3>
      <Tabs activeName={activeTab} onTabChange={handleTabChange}>
        <Tabs.Tab name="desktop" title="Desktop">
          <div className={classes.tabWrapper}>
            <div className={classes.leftSide}>
              <h2>{tierName}</h2>
              <div className={classes.tabDescription}>
                <div className={classes.tabDescriptionRow}>
                  <h3>Ad location:</h3>
                  <p className={classes.tabDescriptionText}>{adLocationText}</p>
                </div>
                <div className={classes.tabDescriptionRow}>
                  <h3>Uploading images:</h3>
                  <p className={classes.tabDescriptionText}>{uploaderText}</p>
                </div>
                <div className={classes.tabDescriptionRow}>
                  <h3>Display time:</h3>
                  <p className={classes.tabDescriptionText}>
                    The ads will be displayed for a total of 12 minutes per hour
                    for each selection slot during a 24 hour period
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.rightSide}>
              <div className={classes.adItems}>
                <AdItem
                  adsKey={adKeyDesktop}
                  src={[adKeyDesktop]?.url}
                  redirectTo={[adKeyDesktop]?.redirect_to}
                  title={adItemDesktopTitle}
                  width={adItemWidthDesktop}
                  height={adItemHeightDesktop}
                  status={[adKeyDesktop] === undefined ? 'Draft' : 'Live'}
                />
                <AdItem
                  adsKey={adKeyDesktop}
                  src={[adKeyDesktop]?.url}
                  redirectTo={[adKeyDesktop]?.redirect_to}
                  title={adItemDesktopTitle}
                  width={adItemWidthDesktop}
                  height={adItemHeightDesktop}
                  status={[adKeyDesktop] === undefined ? 'Draft' : 'Live'}
                />
              </div>
              <div className={classes.redirectToBox}>
                <div className={classes.tabRedirectBoxRow}>
                  <h3>Redirect to:</h3>
                  <Input />
                </div>
                <div className={classes.tabRedirectBoxRow}>
                  <h3>Configuration:</h3>
                  <Field>
                    <Checkbox
                      description={
                        <div className={classes.configuration}>
                          Switch between ads every <Input /> seconds.
                        </div>
                      }
                      checked={true}
                      onChange={() => null}
                    />
                  </Field>
                </div>
              </div>
            </div>
          </div>
        </Tabs.Tab>
        <Tabs.Tab name="mobile" title="Mobile">
          <div className={classes.tabWrapper}>
            <div className={classes.leftSide}>
              <h2>{tierName}</h2>
              <div className={classes.tabDescription}>
                <div className={classes.tabDescriptionRow}>
                  <h3>Ad location:</h3>
                  <p className={classes.tabDescriptionText}>{adLocationText}</p>
                </div>
                <div className={classes.tabDescriptionRow}>
                  <h3>Uploading images:</h3>
                  <p className={classes.tabDescriptionText}>{uploaderText}</p>
                </div>
                <div className={classes.tabDescriptionRow}>
                  <h3>Display time:</h3>
                  <p className={classes.tabDescriptionText}>
                    The ads will be displayed for a total of 12 minutes per hour
                    for each selection slot during a 24 hour period
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.rightSide}>
              <div className={classes.row}>
                <AdItem
                  square
                  adsKey={adKeyMobile}
                  src={[adKeyMobile]?.url}
                  redirectTo={[adKeyMobile]?.redirect_to}
                  title={adItemMobileTitle}
                  width={adItemWidthMobile}
                  height={adItemHeightMobile}
                  status={[adKeyMobile] === undefined ? 'Draft' : 'Live'}
                />
                <AdItem
                  square
                  adsKey={adKeyMobile}
                  src={[adKeyMobile]?.url}
                  redirectTo={[adKeyMobile]?.redirect_to}
                  title={adItemMobileTitle}
                  width={adItemWidthMobile}
                  height={adItemHeightMobile}
                  status={[adKeyMobile] === undefined ? 'Draft' : 'Live'}
                />
              </div>
              <div className={classes.redirectToBox}>
                <div className={classes.tabRedirectBoxRow}>
                  <h3>Redirect to:</h3>
                  <Input />
                </div>
                <div className={classes.tabRedirectBoxRow}>
                  <h3>Configuration:</h3>
                  <Field>
                    <Checkbox
                      description={
                        <div className={classes.configuration}>
                          Switch between ads every <Input /> seconds.
                        </div>
                      }
                      checked={true}
                      onChange={() => null}
                    />
                  </Field>
                </div>
              </div>
            </div>
          </div>
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}
