type BulkDeleteVideosMutationVariables = {
  videos: Array<number>,
}

export default function bulkDeleteVideos(
  variables: BulkDeleteVideosMutationVariables
) {
  return {
    method: 'delete',
    url: '/publisher/videos',
    variables,
  }
}
