import { useRef, useState } from 'react'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/useMutation'
import Box from 'shared/components/kit/box'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'

export default function YoutubeImportRequest({ channel }) {
  const status = channel?.import_request_status || ''

  if (status === 'requested') {
    return <Requested channel={channel} />
  }

  return <Request channel={channel} status={status} />
}

function Request({ channel, status }) {
  const [step, setStep] = useState('')

  const submitButtonRef = useRef()

  const { mutateAsync } = useMutation(requestImport, {
    onError: (error) => {
      notification.error({
        title: error?.message || 'Something went wrong, please try again.',
      })
    },
    onSuccess: () => {
      setStep('')
      notification.success({
        title: 'Request submitted successfully.',
      })
    },
  })

  const form = useMiniForm({
    onSubmit({ youtubeChannelId }) {
      mutateAsync({
        id: channel.id,
        youtubeChannelId,
      })
    },
  })

  const handleCancel = () => {
    setStep('')
  }

  const handleOk = () => {
    if (step === 'confirm') {
      setStep('import')
    } else {
      form.submit()
    }
  }

  return (
    <>
      <Box className="p-6">
        <h4 className="mb-2">Import from Youtube</h4>
        <p className="h-16 text-overlayColor-mutedText">
          {status === 'completed'
            ? 'This channel has already requested for import and is marked as completed.'
            : 'Queue for importing videos and playlists from Youtube channel.'}
        </p>
        <footer className="text-right">
          <Button
            onClick={() => setStep('confirm')}
            size="sm"
            outlined
            secondary>
            {status === 'completed' ? 'Request Again' : 'Request Import'}
          </Button>
        </footer>
      </Box>
      <Modal
        loading={form.submitting}
        okButtonProps={{
          disabled: step === 'import' && !form.get('youtubeChannelId'),
        }}
        okText={step === 'import' ? 'Request Import' : 'Yes'}
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Request Import"
        visible={step !== ''}>
        {step === 'confirm' && (
          <p>
            Are you sure you want to start importing videos from Youtube
            channel?
          </p>
        )}
        {step === 'import' && (
          <form onSubmit={form.submit}>
            <p>
              You need to manually get Youtube Channel ID and pass it in the
              input below.
            </p>
            <br />
            <Field label="Youtube Channel ID">
              <Input {...form.getInputPropsFor('youtubeChannelId')} required />
            </Field>
            <button ref={submitButtonRef} type="submit" hidden />
          </form>
        )}
      </Modal>
    </>
  )
}

function Requested({ channel }) {
  const [visible, setVisible] = useState(false)

  const { isLoading, mutate } = useMutation(completeImport, {
    onError: (error) => {
      notification.error({
        title: error?.message || 'Something went wrong, please try again.',
      })
    },
    onSuccess: () => {
      setVisible(false)
      notification.success({
        title: 'Marked as completed successfully.',
      })
    },
  })

  const handleCancel = () => setVisible(false)

  const handleOk = () => {
    mutate({
      id: channel.id,
    })
  }

  return (
    <>
      <Box className="p-6">
        <h4 className="mb-2">Import from Youtube</h4>
        <p className="h-16 text-overlayColor-mutedText">
          Request sent to import video from Youtube channel.
        </p>
        <footer className="text-right">
          <Button onClick={() => setVisible(true)} size="sm" outlined secondary>
            Complete Import
          </Button>
        </footer>
      </Box>
      <Modal
        loading={isLoading}
        okText="Complete Import"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Complete Import"
        visible={visible}>
        <p>Are you sure you want to stop importing and mark as completed?</p>
      </Modal>
    </>
  )
}

function requestImport(variables) {
  const { id, youtubeChannelId } = variables
  return {
    method: 'put',
    url: `/admin/channels/${id}/import-request`,
    variables: {
      youtube_channel_id: youtubeChannelId,
    },
  }
}

function completeImport(variables) {
  const { id } = variables
  return {
    method: 'post',
    url: `/admin/channels/${id}/import-completed`,
  }
}
