import { useCallback, useEffect, useMemo, useState } from 'react'
import cookie from 'core/cookie'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'

export default function TwoFactorAuthenticationModal(props) {
  const { app, email, onAuth, onCancel, visible } = props

  const { isLoading, mutateAsync: verify } = useMutation(verifyMutation, {
    onSuccess: (response) => {
      onAuth?.(response)
    },
  })

  const validation = useMemo(() => {
    const rules = {}
    if (app) {
      rules.app_2fa_secret = {
        rule: 'required',
        label: 'Authenticator Code',
      }
    }
    if (email) {
      rules.email_2fa_code = {
        rule: 'required',
        label: 'E-mail Verification Code',
      }
    }
    return rules
  }, [app, email])

  const form = useMiniForm({
    validation,
    onSubmit: async (values) => {
      try {
        const response = await verify(values)
        if (response?.json?.token) {
          cookie().set('jwt', response.json.token)
          window.localStorage.removeItem('auth_key')
          window.location.href = '/' + window.location.href.split('/')[1]
        }
      } catch (error) {
        throw error.json
      }
    },
  })

  useEffect(() => {
    if (!visible) {
      form.reset()
    }
  }, [form.reset, visible])

  return (
    <Modal
      footer={null}
      modalClassName="w-96"
      onCancel={onCancel}
      size={null}
      title="Verify It's You"
      visible={visible}>
      <p className="mb-8">
        In order to proceed with this action, you are required to fill in the
        following fields:
      </p>

      <form onSubmit={form.submit}>
        {email && (
          <Field
            {...form.getErrorPropsFor('email_2fa_code')}
            help="Enter the 6-digit code sent to your mail"
            label="E-mail Verification Code"
            required>
            <div className="relative">
              <Input
                maxLength={6}
                placeholder="xxxxxx"
                {...form.getInputPropsFor('email_2fa_code')}
              />
              <div className="absolute right-4 top-1 z-10">
                <GetCodeButton />
              </div>
            </div>
          </Field>
        )}

        {app && (
          <Field
            {...form.getErrorPropsFor('app_2fa_secret')}
            help="Enter the 6-digit code from Google Authenticator"
            label="Authenticator Code"
            required>
            <Input
              maxLength={6}
              placeholder="xxxxxx"
              {...form.getInputPropsFor('app_2fa_secret')}
            />
          </Field>
        )}

        <Button className="mt-2" loading={isLoading} type="submit" block>
          Submit
        </Button>
      </form>
    </Modal>
  )
}

function GetCodeButton() {
  const [showTimer, setShowTimer] = useState(false)

  const { isLoading, isSuccess, mutate } = useMutation(
    sendCodeToEmailMutation,
    {
      onSuccess: () => {
        setShowTimer(true)
      },
    }
  )

  const handleSendCodeToEmail = (event) => {
    event.preventDefault()
    mutate()
  }

  const handleTimeOver = useCallback(() => {
    setShowTimer(false)
  }, [])

  if (showTimer) {
    return <Timer onTimeOver={handleTimeOver} />
  }

  return (
    <Button
      loading={isLoading}
      mood="secondary"
      onClick={handleSendCodeToEmail}
      size="sm"
      type="button"
      noHorizontalPadding
      transparent>
      {isSuccess ? 'Resend Code' : 'Send Code'}
    </Button>
  )
}

function Timer({ onTimeOver }) {
  const [seconds, setSeconds] = useState(59)

  useEffect(() => {
    if (seconds < 1) {
      onTimeOver()
      return
    }
    const ref = setTimeout(() => {
      setSeconds((prev) => prev - 1)
    }, 1000)
    return () => {
      clearTimeout(ref)
    }
  }, [onTimeOver, seconds])

  return <span className="leading-8 text-neutral">{seconds}s</span>
}

function verifyMutation(variables) {
  return {
    method: 'put',
    path: '/2fa/verify',
    params: {
      ...variables,
    },
  }
}

function sendCodeToEmailMutation() {
  return {
    method: 'put',
    path: '/2fa/email/send',
  }
}
