import { useMutation as useReactMutation } from 'react-query'
import useFetcher from './useFetcher'

type Options = {
  refetchQueries?: Array,
  onSuccess?: Function,
}

export default function useMutation(mutation: Function, options?: Options) {
  const fetcher = useFetcher()
  const request = async (variables = {}) => {
    const { method, url, variables: body } = mutation(variables)
    try {
      return await fetcher(url, {
        method: method || 'post',
        body,
      })
    } catch (error) {
      throw error
    }
  }
  return useReactMutation(request, options)
}
