import { useMemo } from 'react'
import useQuery from 'core/react-query/useQuery'
import getVideo from 'shared/queries/getVideo'

export default function useVideo(videoId: number) {
  const qr = useQuery(getVideo, {
    keepPreviousData: true,
    enabled: !!videoId,
    variables: {
      id: videoId,
    },
  })
  const video = useMemo(() => qr?.data?.json || {}, [qr.data])
  return {
    ...qr,
    video,
  }
}
