import { useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import formatDate from 'helpers/utils/formatDate'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import Alert from 'shared/components/kit/alert'
import Table from 'shared/components/kit/table'
import RequestStatus from 'shared/components/request-status'
import Page from 'admin/components/page'
import getPublisherRequests from 'admin/queries/getPublisherRequests'
import truncate from 'helpers/utils/truncate'
import Filters from 'admin/pages/requests/components/Filters'
import AcceptRequestButton from './components/AcceptRequestButton'
import RejectRequestButton from './components/RejectRequestButton'
import classes from './index.module.css'

const INITIAL_FILTERS = {
  page: 1,
  status: null,
}

export default function Requests() {
  const [filters, setFilters] = useState(INITIAL_FILTERS)

  const { data, error, isError, isFetching } = useQuery(getPublisherRequests, {
    keepPreviousData: true,
    variables: filters,
  })

  const {
    data: requests,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  if (isError) {
    return <Alert title={error} content={error?.message} type="error" />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  return (
    <Page description={description} title="Publisher Requests">
      <Filters initialFilters={INITIAL_FILTERS} onSubmit={setFilters} />
      <Table
        columns={columns}
        data={requests}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
    </Page>
  )
}

const columns = [
  {
    title: 'Email',
    render: (user) => (
      <span title={user.email?.length > 35 ? user.email : ''}>
        {truncate(user.email, 35)}
      </span>
    ),
  },
  {
    title: 'Channel Name',
    render: (user) =>
      user.request_details.message
        .substr(user.request_details.message.indexOf('Channel name: '))
        .split('\\n')[0]
        .replace('Channel name:', '')
        .trim(),
  },
  {
    title: 'Details',
    render: (user) => (
      <ul>
        {user.request_details.message.split(`\\n`).map((segment, key) => (
          <li key={key}>{segment}</li>
        ))}
      </ul>
    ),
  },
  {
    align: 'center',
    className: classes.status,
    title: 'Status',
    render: (user) => <RequestStatus status={user.publisher_request.status} />,
  },
  {
    title: 'Request Date',
    render: (user) => formatDate(user.created_at),
  },
  {
    className: 'whitespace-nowrap',
    align: 'right',
    render: (user) => (
      <>
        {user.publisher_request.status === 'pending' && (
          <>
            <AcceptRequestButton userId={user.id} />
            &nbsp;&nbsp;
            <RejectRequestButton
              messageId={user.request_details.id}
              userId={user.id}
            />
          </>
        )}
        {user.publisher_request.status === 'confirmed' && (
          <>
            <AcceptRequestButton disabled userId={user.id} />
            &nbsp;&nbsp;
            <RejectRequestButton
              disabled
              messageId={user.request_details.id}
              userId={user.id}
            />
          </>
        )}
        {user.publisher_request.status === 'rejected' && (
          <>
            <AcceptRequestButton userId={user.id} />
            &nbsp;&nbsp;
            <RejectRequestButton
              disabled
              messageId={user.request_details.id}
              userId={user.id}
            />
          </>
        )}
      </>
    ),
  },
]
