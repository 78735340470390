import formatDate from 'helpers/utils/formatDate'
import getUserName from 'helpers/utils/getUserName'
import truncate from 'helpers/utils/truncate'
import UserAvatar from 'shared/components/user-avatar'
import classes from './UserInfo.module.css'

export default function UserInfo({ user }) {
  return (
    <div className={classes.wrapper}>
      <UserAvatar className={classes.avatar} user={user} />
      <h3>{getUserName(user)}</h3>
      <ul>
        <li>
          <span title={user.email?.length > 70 ? user.email : ''}>
            {truncate(user.email, 70)}
          </span>
        </li>
        <li>Signed Up on {formatDate(user.created_at)}</li>
      </ul>
    </div>
  )
}
