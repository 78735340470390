// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".how-it-works-modal_wrapper__1VkzS section h1 {\n  font-size: 1.5rem;\n  line-height: 2rem;\n}\n\n.how-it-works-modal_wrapper__1VkzS section h2 {\n  font-weight: 700;\n  font-size: 1.25rem;\n  line-height: 1.75rem;\n  margin-top: 2rem;\n}\n\n.how-it-works-modal_wrapper__1VkzS section p {\n  margin-top: 1rem;\n}\n\n.how-it-works-modal_wrapper__1VkzS ol {\n  margin-left: 5rem;\n}\n\n.how-it-works-modal_wrapper__1VkzS ol li, .how-it-works-modal_wrapper__1VkzS ul li {\n  margin-top: 1rem;\n}\n\n.how-it-works-modal_wrapper__1VkzS ul.how-it-works-modal_dashed__1n9NJ li:before {\n  margin-right: 1.5rem;\n  margin-left: 4rem;\n  content: '-';\n}\n\n.how-it-works-modal_borderedBox__2wg4z {\n  border-radius: 1rem;\n  border-width: 1px;\n  margin-top: 1.5rem;\n  margin-bottom: 1.5rem;\n  padding-left: 2rem;\n  padding-right: 2rem;\n  padding-bottom: 2rem;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/components/how-it-works-modal/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,iBAAmB;EAAnB,iBAAmB;ADAnB;;AAEA;ECFA,gBAAmB;EAAnB,kBAAmB;EAAnB,oBAAmB;EAAnB,gBAAmB;ADInB;;AAEA;ECNA,gBAAmB;ADQnB;;AAEA;ECVA,iBAAmB;ADYnB;;ACZA;EAAA;AAAmB;;ADmBnB;ECnBA,oBAAmB;EAAnB,iBAAmB;EDqBjB,YAAY;AACd;;AAEA;ECxBA,mBAAmB;EAAnB,iBAAmB;EAAnB,kBAAmB;EAAnB,qBAAmB;EAAnB,kBAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;AD0BnB","sourcesContent":[".wrapper section h1 {\n  @apply text-2xl;\n}\n\n.wrapper section h2 {\n  @apply font-bold mt-8 text-xl;\n}\n\n.wrapper section p {\n  @apply mt-4;\n}\n\n.wrapper ol {\n  @apply ml-20;\n}\n\n.wrapper ol li,\n.wrapper ul li {\n  @apply mt-4;\n}\n\n.wrapper ul.dashed li:before {\n  @apply ml-16 mr-6;\n  content: '-';\n}\n\n.borderedBox {\n  @apply border rounded-2xl px-8 pb-8 my-6;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "how-it-works-modal_wrapper__1VkzS",
	"dashed": "how-it-works-modal_dashed__1n9NJ",
	"borderedBox": "how-it-works-modal_borderedBox__2wg4z"
};
export default ___CSS_LOADER_EXPORT___;
