import { Route, Switch } from 'react-router'
import Layout from 'admin/components/layouts/setting'
import ReportedVideoReasons from './reportedVideoReasons'
import ReportedCommentReasons from './reportedCommentReasons'
import DeletedVideoReasons from './deletedVideoReasons'
import HiddenVideoReasons from './hiddenVideoReasons'
import Administrators from './administrators'
import DeletedCommentReasons from './deleteCommentReasons'
import RejectPublisherRequestReasons from './rejectPublisherRequestReasons'
import Security from './security'

export default function Setting() {
  return (
    <Layout>
      <Switch>
        <Route
          path="/admin/setting/reportedVideoReasons"
          component={ReportedVideoReasons}
          exact
        />
        <Route
          path="/admin/setting/reportedCommentReasons"
          component={ReportedCommentReasons}
          exact
        />
        <Route
          path="/admin/setting/deleteVideoReasons"
          component={DeletedVideoReasons}
          exact
        />
        <Route
          path="/admin/setting/hideVideoReasons"
          component={HiddenVideoReasons}
          exact
        />
        <Route
          path="/admin/setting/deleteCommentReasons"
          component={DeletedCommentReasons}
          exact
        />
        <Route
          path="/admin/setting/publisherRequestRejectReasons"
          component={RejectPublisherRequestReasons}
          exact
        />
        <Route
          path="/admin/setting/administrators"
          component={Administrators}
          exact
        />
        <Route
          path="/admin/setting/security/:active?"
          component={Security}
          exact
        />
      </Switch>
    </Layout>
  )
}
