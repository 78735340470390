import { useState } from 'react'
import useMiniForm from 'core/useMiniForm'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/useMutation'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import getVideos from 'admin/queries/getVideos'
import unHideVideo from 'admin/queries/unHideVideo'

export default function UnHideVideoButton({ id }) {
  const [visible, setVisible] = useState(false)

  const form = useMiniForm({
    async onSubmit({ custom, reason }) {
      try {
        await mutation.mutateAsync({
          id,
        })
        notification.success({
          title: 'Video is unHidden successfully!',
        })
      } catch (error) {
        notification.error({
          title: error.message,
        })
      }
    },
  })

  const queryClient = useQueryClient()

  const mutation = useMutation(unHideVideo, {
    onSuccess() {
      queryClient.invalidateQueries({ query: getVideos })
    },
  })

  const handleShow = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.reset()
  }

  const handleOk = async () => {
    await form.submit()
    setVisible(false)
  }

  return (
    <>
      <Button onClick={handleShow} size="sm" outlined secondary>
        UnHide
      </Button>
      <Modal
        loading={mutation.isLoading}
        okText="UnHide Video"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="UnHide Video"
        visible={visible}>
        <p>Are you sure you want to UnHide this video?</p>
      </Modal>
    </>
  )
}
