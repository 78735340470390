import { Link } from 'react-router-dom'
import useQuery from 'core/react-query/useQuery'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import formatDate from 'helpers/utils/formatDate'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import Alert from 'shared/components/kit/alert'
import Button from 'shared/components/kit/button'
import Table from 'shared/components/kit/table'
import ChannelAvatar from 'shared/components/channel-avatar'
import ChannelStatus from 'admin/components/channel-status'
import getChannels from 'admin/queries/getChannels'
import Page from 'admin/components/page'
import Filters from './components/Filter'

const FILTER_CONFIG = {
  base: '/admin/channels',
  params: {
    page: 'pagination',
    search: 'any',
    sort: 'any',
  },
}

export default function Channels() {
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)

  const { data, error, isError, isFetching } = useQuery(getChannels, {
    keepPreviousData: true,
    variables: filters,
  })

  const {
    data: channels,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  if (isError) {
    return <Alert title={error} content={error?.message} type="error" />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  const handleFilter = (nextFilters) => {
    setFilters({
      ...nextFilters,
      page: undefined,
    })
  }

  return (
    <Page description={description} title="Channels">
      <Filters initialFilters={filters} onSubmit={handleFilter} />
      <Table
        columns={columns}
        data={channels}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
    </Page>
  )
}

const columns = [
  {
    className: 'w-24',
    render: (channel) => <ChannelAvatar channel={channel} size={14} />,
  },
  {
    render: (channel) => channel.name,
  },
  {
    align: 'center',
    className: 'w-30',
    title: 'Points',
    render: (channel) => channel.points,
  },
  {
    align: 'center',
    className: 'w-30',
    title: 'Subscribers',
    render: (channel) => {
      if (channel.owner) {
        return (
          <Link
            className="text-secondary"
            target="_blank"
            to={`/admin/users?search=Sub%3D${channel.owner.email}`}>
            {channel.subscribers_count}
          </Link>
        )
      } else {
        return <span className="text-neutral">{channel.subscribers_count}</span>
      }
    },
  },
  {
    align: 'center',
    className: 'w-30',
    title: 'Uploads',
    render: (channel) => channel.uploads_count,
  },
  {
    align: 'center',
    className: 'w-30',
    title: 'Total Views',
    render: (channel) => channel.total_views,
  },
  {
    align: 'center',
    className: 'w-30',
    title: 'Total Likes',
    render: (channel) => channel.total_likes,
  },
  {
    align: 'center',
    className: 'w-30',
    title: 'Total Comments',
    render: (channel) => channel.comments_count,
  },
  {
    align: 'center',
    className: `w-30`,
    title: 'Referrals',
    render: (channel) => {
      if (channel.owner) {
        return (
          <Link
            className="text-secondary"
            target="_blank"
            to={`/admin/users?search=Ref%3D${channel.owner.email}`}>
            {channel.referrals_count}
          </Link>
        )
      } else {
        return <span className="text-neutral"> {channel.referrals_count}</span>
      }
    },
  },
  {
    align: 'center',
    title: 'Status',
    render: (channel) => <ChannelStatus status={channel.status} />,
  },
  {
    align: 'center',
    className: 'w-48',
    title: 'Creation Date',
    render: (channel) => formatDate(channel.created_at),
  },
  {
    align: 'center',
    className: 'w-48',
    title: 'Last Active',
    render: (channel) => formatDate(channel.owner.last_actived_at),
  },
  {
    className: 'w-30',
    render: (channel) => (
      <Button
        component="link"
        size="sm"
        to={`/admin/channels/${channel.id}/overview`}
        outlined
        secondary>
        Manage
      </Button>
    ),
  },
]
