import { useState } from 'react'
import useMiniForm from 'core/useMiniForm'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import getTags from 'admin/queries/getTags'
import deleteTag from 'admin/queries/deleteTag'

export default function DeleteTagButton({ tag }) {
  const [visible, setVisible] = useState(false)
  const form = useMiniForm({
    async onSubmit() {
      try {
        await mutation.mutateAsync({
          id: tag.id,
        })
        notification.success({
          title: 'Tag deleted successfully!',
        })
        setVisible(false)
      } catch (error) {
        notification.error({
          title: error.message,
        })
      }
    },
  })

  const queryClient = useQueryClient()

  const mutation = useMutation(deleteTag, {
    onSuccess() {
      queryClient.invalidateQueries({ query: getTags })
    },
  })

  const handleShow = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    form.reset()
  }

  const handleOk = async () => {
    await form.submit()
  }

  return (
    <>
      <Button className="ml-2" onClick={handleShow} size="sm" outlined>
        Delete
      </Button>
      <Modal
        submitting={mutation.isLoading}
        okText="Delete Tag"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title={`Delete Tag: ${tag.name}`}
        visible={visible}>
        <p>Are you sure you want to delete this tag?</p>
      </Modal>
    </>
  )
}
