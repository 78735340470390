import { Children } from 'react'
import classes from './index.module.css'

type Props = {
  activeName: string,
  onTabChange: Function,
}

export default function Tabs(props: Props) {
  const { activeName, children, onTabChange } = props

  const navItems = []

  let content

  Children.forEach(children, (child, key) => {
    navItems.push(
      <li
        key={key}
        className={
          activeName === child.props.name ? classes.active : undefined
        }>
        <button type="button" onClick={() => onTabChange(child.props.name)}>
          {child.props.title}
        </button>
      </li>
    )

    if (child.props.name === activeName) {
      content = child
    }
  })

  return (
    <div className={classes.wrapper}>
      <nav>
        <ul>{navItems}</ul>
      </nav>
      <section>{content}</section>
    </div>
  )
}

const Tab = (props) => props.children

Tabs.Tab = Tab
