// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error_wrapper__1PiZ- {\n  --tw-bg-opacity: 1;\n  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));\n  border-radius: 0.5rem;\n  margin-bottom: 1.5rem;\n  padding-top: 0.75rem;\n  padding-bottom: 0.75rem;\n  padding-left: 1rem;\n  padding-right: 1rem;\n  color: var(--light);\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/form/error/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,kBAAmB;EAAnB,yDAAmB;EAAnB,qBAAmB;EAAnB,qBAAmB;EAAnB,oBAAmB;EAAnB,uBAAmB;EAAnB,kBAAmB;EAAnB,mBAAmB;EAAnB,mBAAmB;ADAnB","sourcesContent":[".wrapper {\n  @apply bg-errorColor-darker mb-6 px-4 py-3 rounded-lg text-light;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "error_wrapper__1PiZ-"
};
export default ___CSS_LOADER_EXPORT___;
