import { ethers } from 'ethers'
import SmartContractABI from 'core/tcg/SmartContractABI.json'

const TCG_CONTRACT_ADDRESS = process.env.REACT_APP_TCG_CONTRACT_ADDRESS
const POLYGON_RPC = process.env.REACT_APP_TCG_POLYGON_RPC

export default async function getWalletAssetsRaw({ queryKey }) {
  const [_key, address] = queryKey

  const polygonProvider = new ethers.providers.JsonRpcProvider(POLYGON_RPC)

  let maticBalance = 0
  try {
    maticBalance = await polygonProvider.getBalance(address)
  } catch (error) {
    console.log('ETH ERROR', error)
  }

  const tcgContract = new ethers.Contract(
    TCG_CONTRACT_ADDRESS,
    SmartContractABI,
    polygonProvider
  )

  let tcgBalance = 0
  try {
    tcgBalance = await tcgContract.balanceOf(address)
  } catch (error) {
    console.log(error)
  }

  return {
    matic: ethers.utils.formatEther(maticBalance),
    tcg: ethers.utils.formatEther(tcgBalance),
  }
}
