import { useQueryClient as useReactQueryClient } from 'react-query'
import createUrl from 'core/createUrl'

export default function useQueryClient() {
  const queryClient = useReactQueryClient()

  return {
    cancelQueries: ({ query, variables }) => {
      const { name, path, params } = query(variables)
      const queryKey = variables ? [name, path, params] : name
      return queryClient.cancelQueries(queryKey)
    },

    fetchQuery: ({ query, variables, ...options }) => {
      const { name, path, params } = query(variables)
      const queryKey = [name, path, params]
      const queryFn = () => queryClient.fetcher(createUrl(path, params))
      return queryClient.fetchQuery(queryKey, queryFn, options)
    },

    getQueryData: ({ query, variables }) => {
      const { name, path, params } = query(variables)
      const queryKey = [name, path, params]
      return queryClient.getQueryData(queryKey)
    },

    invalidateQueries: ({ query, filters, refetchOptions, variables }) => {
      const { name, path, params } = query(variables)
      const queryKey = variables ? [name, path, params] : name
      return queryClient.invalidateQueries(queryKey, filters, refetchOptions)
    },

    setQueryData: ({ query, updater, variables }) => {
      const { name, path, params } = query(variables)
      const queryKey = [name, path, params]
      return queryClient.setQueryData(queryKey, updater)
    },
  }
}
