// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "form.Filters_filters__12Esd {\n  display: flex;\n  margin-bottom: 2rem;\n}\n\nform.Filters_filters__12Esd .Filters_search__13eWM {\n  margin-right: 1rem;\n  width: 20rem;\n}\n\nform.Filters_filters__12Esd .Filters_category__1ZObY {\n  margin-right: 1rem;\n  width: 14rem;\n}\n\nform.Filters_filters__12Esd .Filters_channel__Z2d-V {\n  margin-right: 1rem;\n  width: 14rem;\n}\n\nform.Filters_filters__12Esd .Filters_sort__3qI5O {\n  margin-right: 1rem;\n  width: 14rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/videos/components/Filters.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,mBAAmB;ADAnB;;AAEA;ECFA,kBAAmB;EAAnB,YAAmB;ADInB;;AAEA;ECNA,kBAAmB;EAAnB,YAAmB;ADQnB;;AAEA;ECVA,kBAAmB;EAAnB,YAAmB;ADYnB;;AAEA;ECdA,kBAAmB;EAAnB,YAAmB;ADgBnB","sourcesContent":["form.filters {\n  @apply flex mb-8;\n}\n\nform.filters .search {\n  @apply mr-4 w-80;\n}\n\nform.filters .category {\n  @apply mr-4 w-56;\n}\n\nform.filters .channel {\n  @apply mr-4 w-56;\n}\n\nform.filters .sort {\n  @apply mr-4 w-56;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": "Filters_filters__12Esd",
	"search": "Filters_search__13eWM",
	"category": "Filters_category__1ZObY",
	"channel": "Filters_channel__Z2d-V",
	"sort": "Filters_sort__3qI5O"
};
export default ___CSS_LOADER_EXPORT___;
