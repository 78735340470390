import classes from './ChannelStatistic.module.css'
import formatNumber from '../../../../../helpers/utils/formatNumber'

export default function ChannelStatistic({ data }) {
  return (
    <>
      <table className={classes.statTable}>
        <tbody>
          <tr>
            <td>Subs: {formatNumber(data.subscribers_non_hero)}</td>
            <td>Views: {formatNumber(data.views)}</td>
            <td>Likes: {formatNumber(data.likes_non_hero)}</td>
            <td>Points: {formatNumber(data.points)}</td>
          </tr>
          <tr>
            <td>HODL: {formatNumber(data.subscribers_hero)}</td>
            <td>W-Hours: {formatNumber(data.watch_times / 3600)}</td>
            <td>HODL: {formatNumber(data.likes_hero)}</td>
            <td>Share: {formatNumber(data.share)}%</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
