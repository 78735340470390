import Button from 'shared/components/kit/button'
import formatDate from 'helpers/utils/formatDate'
import { ReactComponent as ErrorSVG } from 'icons/error-circle-filled.svg'
// import { ReactComponent as RedoSVG } from 'icons/redo.svg'
// import { ReactComponent as UndoSVG } from 'icons/undo.svg'
import { ReactComponent as ZoomInSVG } from 'icons/zoom-in.svg'
import { ReactComponent as ZoomOutSVG } from 'icons/zoom-out.svg'
import useTimelineActions from '../hooks/useTimelineActions'
import useTimelineData from '../hooks/useTimelineData'
import SubtitlesButton from './SubtitlesButton'
import classes from './Toolbar.module.css'

export default function Toolbar({
  isPublishing,
  isSavingDraft,
  meta,
  onPublish,
}) {
  const draftData = useTimelineData()
  const hasPublishedData = !!meta.studio
  const publishedUpdateNumber = meta.studio?.value?.updateNumber || 0
  const lastPublishedSavedAt = meta.studio?.updated_at
  const draftUpdateNumber = draftData.updateNumber
  const lastDraftSavedAt = meta.studio_draft?.updated_at
  const publishable = draftUpdateNumber > publishedUpdateNumber

  const { hasError, zoom } = useTimelineData()
  const { zoomIn, zoomOut } = useTimelineActions()

  const scale = Math.pow(2, zoom - 1)

  return (
    <header className={classes.wrapper}>
      <div className={classes.left}>
        {/*<Button mood="neutral" square transparent>*/}
        {/*  <UndoSVG />*/}
        {/*</Button>*/}
        {/*<Button mood="neutral" square transparent>*/}
        {/*  <RedoSVG />*/}
        {/*</Button>*/}
        {/*<span className={classes.separator} />*/}
        <SubtitlesButton />
        <span className={classes.separator} />
        <Button
          disabled={!publishable || hasError}
          loading={isPublishing}
          mood="secondary"
          onClick={onPublish}>
          Publish Changes
        </Button>
        <span className={classes.status}>
          {hasError ? (
            <span className={classes.error}>
              <ErrorSVG /> Can't save, some overlays overlap!
            </span>
          ) : isSavingDraft ? (
            <>Saving...</>
          ) : publishable ? (
            <>Draft saved at {formatDate(lastDraftSavedAt)}</>
          ) : hasPublishedData ? (
            <>Published at {formatDate(lastPublishedSavedAt)}</>
          ) : null}
        </span>
      </div>

      <div className={classes.zoom}>
        <Button
          disabled={zoom < 2}
          mood="neutral"
          onClick={zoomOut}
          square
          transparent>
          <ZoomOutSVG />
        </Button>
        <span>{scale}x</span>
        <Button
          disabled={zoom >= 5}
          mood="neutral"
          onClick={zoomIn}
          square
          transparent>
          <ZoomInSVG />
        </Button>
      </div>
    </header>
  )
}
