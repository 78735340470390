import { useState } from 'react'
import Popover from 'shared/components/kit/popover'
import UserAvatar from 'shared/components/user-avatar'
import getUserName from 'helpers/utils/getUserName'
import truncate from 'helpers/utils/truncate'
import ChannelAvatar from 'shared/components/channel-avatar'
import classnames from 'classnames'
import classes from './AudiencePopover.module.css'

export default function AudiencesPopover({ notification }) {
  const [showPopover, setShowPopover] = useState(false)
  const handleTogglePopover = (event) => {
    event.preventDefault()
    setShowPopover((prev) => !prev)
  }

  const handleHidePopover = () => {
    setShowPopover(false)
  }

  const audienceList = (notification) => (
    <ul className={classes.audiencePopover}>
      {notification.to.map((to, i) => (
        <li key={i}>
          {to.channel ? (
            <>
              <ChannelAvatar
                className={classes.channelAvatar}
                channel={to.channel}
              />{' '}
              &nbsp;{' '}
              <span title={to.channel.name.length > 25 ? to.channel.name : ''}>
                {truncate(to.channel.name, 25)}
              </span>
            </>
          ) : (
            <>
              <UserAvatar className={classes.avatar} user={to} />
              <span title={getUserName(to).length > 25 ? getUserName(to) : ''}>
                {' '}
                &nbsp; {truncate(getUserName(to), 25)}
              </span>
            </>
          )}
        </li>
      ))}
    </ul>
  )

  return (
    <Popover
      content={audienceList(notification)}
      onHide={handleHidePopover}
      visible={showPopover && notification.to.length > 1}>
      <div className={classes.avatars} onClick={handleTogglePopover}>
        {notification.to.length > 1 ? (
          notification.to.map((to, i) => {
            return i < 3 ? (
              to.channel ? (
                <ChannelAvatar
                  key={i}
                  className={classnames(classes.avatar, 'cursor-pointer')}
                  channel={to.channel}
                />
              ) : (
                <UserAvatar
                  key={i}
                  className={classnames(classes.avatar, 'cursor-pointer')}
                  user={to}
                />
              )
            ) : null
          })
        ) : (
          <div className={classes.toAvatars}>
            {notification.to.length === 1 ? (
              <>
                {notification.to.channel ? (
                  <>
                    <ChannelAvatar
                      className={classes.channelAvatar}
                      channel={notification.to[0].channel}
                    />
                    <>
                      &nbsp;{' '}
                      <span
                        title={
                          notification.to[0].channel.name.length > 25
                            ? notification.to[0].channel.name
                            : ''
                        }>
                        {truncate(notification.to[0].channel.name, 25)}
                      </span>{' '}
                    </>
                  </>
                ) : (
                  <>
                    <UserAvatar
                      className={classes.avatarImg}
                      user={notification.to[0]}
                    />
                    <>
                      &nbsp;
                      <span
                        title={
                          getUserName(notification.to[0]).length > 25
                            ? getUserName(notification.to[0])
                            : ''
                        }>
                        {truncate(getUserName(notification.to[0]), 25)}
                      </span>
                    </>
                  </>
                )}
              </>
            ) : (
              <>All </>
            )}
          </div>
        )}
        {notification.to.length > 3 ? (
          <span className={classes.moreUsers}>
            {' '}
            and {notification.to.length - 3} more{' '}
          </span>
        ) : null}
      </div>
    </Popover>
  )
}
