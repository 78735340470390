import { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import get from 'lodash/get'
import useDebounceCallback from 'helpers/hooks/useDebounceCallback'
import useQuery from 'core/react-query/useQuery'
import getTags from 'publisher/queries/getTags'
import classnames from 'classnames'
import classes from './index.module.css'

export default function CreatableMultiSelect(props: Props) {
  const { isValidNewOption, Menu, ...rest } = props
  const [search, setSearch] = useState('')

  const { data, isLoading } = useQuery(getTags, {
    variables: {
      search,
    },
  })
  const options = get(data, 'json.data', []).map((opt) => ({
    label: opt.name || 'Untitled Video',
    value: opt.name,
  }))

  const handleInputChange = useDebounceCallback((text) => {
    setSearch(text)
  })

  return (
    <CreatableSelect
      components={Menu}
      className={classnames(classes.rs__container, 'rs__container')}
      classNamePrefix="rs"
      formatCreateLabel={(inputText) => (
        <span className="text-secondary">
          Press enter or click here to create tag: {inputText}
        </span>
      )}
      onInputChange={handleInputChange}
      options={options}
      isValidNewOption={isValidNewOption}
      {...rest}
    />
  )
}
