import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Auth from 'publisher/wrappers/Auth'
import DefaultLayout from 'publisher/components/layouts/default'
import Login from 'publisher/pages/login'
import Dashboard from 'publisher/pages/dashboard'
import Channel from 'publisher/pages/channel'
import Videos from 'publisher/pages/videos'
import Video from 'publisher/pages/video'
import Comments from 'publisher/pages/comments'
import Playlists from 'publisher/pages/playlists'
import Playlist from 'publisher/pages/playlist'
import Monetize from 'publisher/pages/monetize'
import PaymentDetails from 'publisher/pages/payment-details'
import Payout from 'publisher/pages/payout'
import Messages from 'publisher/pages/support'
import ImportRequest from 'publisher/pages/import-request'
import Profile from 'publisher/pages/profile'
import Confirm from 'publisher/pages/profile/components/Confirm'
import ConfirmAccountDeletion from 'publisher/pages/profile/components/ConfirmAccountDeletion'
import ResetPassword from 'publisher/pages/reset-password'
import Tos from 'publisher/pages/tos'
import PublisherTerms from 'publisher/pages/publisher-terms'
import About from 'publisher/pages/about'
import Privacy from 'publisher/pages/privacy'
import ChannelDeleted from 'publisher/pages/channel-deleted'
import EmailVerify from 'publisher/pages/verify'
import PublisherRequest from 'publisher/pages/publisher-request'
import MagicSignInVerification from './pages/login/components/LoginForm/MagicSignInVerification'
import InviteEarn from 'publisher/pages/invite-earn'
import TCG from 'publisher/pages/tcg'

export default function PublisherApp() {
  return (
    <>
      <Helmet>
        <title>Publishers - Today's crypto</title>
      </Helmet>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route
          path="/publisher/password/reset/:token"
          component={ResetPassword}
          exact
        />
        <Route path="/publisher/verify/:token" component={EmailVerify} />
        <Route
          path="/publisher/profile/eth/confirm/:token"
          component={Confirm}
          exact
        />
        <Route
          path="/publisher/profile/account-deletion/confirm/:token"
          component={ConfirmAccountDeletion}
          exact
        />
        <Route
          path="/publisher/login/magic/:token"
          component={MagicSignInVerification}
        />
        <Route path="/publisher/tos" component={Tos} />
        <Route path="/publisher/publisher-terms" component={PublisherTerms} />
        <Route path="/publisher/about" component={About} />
        <Route path="/publisher/privacy" component={Privacy} />
        <Route path="/publisher/channel-deleted" component={ChannelDeleted} />
        <Route path="/publisher*">
          <Auth>
            <Auth.Member>
              <DefaultLayout>
                <Switch>
                  <Route
                    path="/publisher/request"
                    component={PublisherRequest}
                  />
                  <Redirect to="/publisher/request" />
                </Switch>
              </DefaultLayout>
            </Auth.Member>
            <Auth.PendingPublisher>
              <DefaultLayout>
                <Switch>
                  <Route path="/publisher/faq" component={Messages} />
                  <Redirect to="/publisher/faq" />
                </Switch>
              </DefaultLayout>
            </Auth.PendingPublisher>
            <Auth.Publisher>
              <Switch>
                <Route path="/publisher/media/:id(\d+)" component={Video} />
                <Route path="/publisher*">
                  <DefaultLayout>
                    <Switch>
                      <Route path="/publisher" component={Dashboard} exact />
                      <Route
                        path="/publisher/comments/:activeTab"
                        component={Comments}
                      />
                      <Redirect
                        from="/publisher/comments"
                        to="/publisher/comments/all"
                      />
                      <Route
                        path="/publisher/profile/:activeTab"
                        component={Profile}
                      />
                      <Redirect
                        from="/publisher/profile"
                        to="/publisher/profile/info"
                      />
                      <Route
                        path="/publisher/import-request"
                        component={ImportRequest}
                        exact
                      />
                      <Route
                        path="/publisher/channel"
                        component={Channel}
                        exact
                      />
                      <Route path="/publisher/media" component={Videos} exact />
                      <Route
                        path="/publisher/playlists"
                        component={Playlists}
                        exact
                      />
                      <Route
                        path="/publisher/playlists/:id"
                        component={Playlist}
                        exact
                      />
                      <Route
                        path="/publisher/monetize"
                        component={Monetize}
                        exact
                      />
                      <Route
                        path="/publisher/invite-earn"
                        component={InviteEarn}
                        exact
                      />
                      <Route
                        path="/publisher/payout"
                        component={Payout}
                        exact
                      />
                      <Route
                        path="/publisher/payment-details"
                        component={PaymentDetails}
                        exact
                      />
                      <Route path="/publisher/tcg" component={TCG} exact />
                      <Route path="/publisher/faq" component={Messages} />
                      <Redirect to="/publisher/faq" />
                    </Switch>
                  </DefaultLayout>
                </Route>
              </Switch>
            </Auth.Publisher>
          </Auth>
        </Route>
      </Switch>
    </>
  )
}
