export default function insertTextAtCursor(element, text, options = {}) {
  //IE support
  if (document.selection) {
    element.focus()
    // sel = document.selection.createRange();
    // sel.text = text;
  }
  //MOZILLA and others
  else if (element.selectionStart || element.selectionStart === '0') {
    const startPos = element.selectionStart
    const endPos = element.selectionEnd
    element.value =
      element.value.substring(0, startPos) +
      text +
      element.value.substring(endPos, element.value.length)
  } else {
    element.value += text
  }

  const { change, focus } = options

  if (change) {
    const event = new Event('change', {
      bubbles: true,
    })
    element.dispatchEvent(event)
  }

  if (focus) {
    element.focus()
  }

  return element.value
}
