type GetAdsCampaign = {
  search: string,
  status: 'active' | 'archive' | 'draft',
  sort?: 'oldest' | 'newest',
  page?: number,
}

export default function getAdsCampaign(variables: GetAdsCampaign = {}) {
  const { page, sort, status, search } = variables
  return {
    path: '/admin/ads/campaigns',
    params: {
      page: page > 1 ? page : undefined,
      sort,
      filters: {
        search,
        status,
      },
    },
  }
}
