type GetCampaigns = {
  search: string,
  status: 'active' | 'archive' | 'draft',
  sort?: 'oldest' | 'newest',
  page?: number,
}

export default function getCampaigns(variables: GetCampaigns = {}) {
  const { page, sort, status, search } = variables
  return {
    path: '/admin/crypto-campaigns',
    params: {
      page: page > 1 ? page : undefined,
      sort,
      filters: {
        search,
        status,
      },
    },
  }
}
