// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Confirm_form__1HQHU button {\n  margin-left: 0.5rem;\n  margin-right: 0.5rem;\n  width: 7rem;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/profile/components/Confirm.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,mBAAmB;EAAnB,oBAAmB;EAAnB,WAAmB;ADAnB","sourcesContent":[".form button {\n  @apply mx-2 w-28;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "Confirm_form__1HQHU"
};
export default ___CSS_LOADER_EXPORT___;
