type GetVideosQueryVariables = {
  categoryId?: number,
  channelId?: number,
  excludePlaylist?: number,
  excludeVideos?: Array<number>,
  page?: number,
  search?: string,
  sort?: '' | 'most_viewed' | 'most_liked' | 'most_commented',
}

export default function getVideos(variables: GetVideosQueryVariables = {}) {
  const {
    categoryId,
    channelId,
    excludePlaylist,
    excludeVideos,
    page,
    search,
    sort,
  } = variables

  return {
    name: 'videos',
    path: '/admin/videos',
    params: {
      exclude_playlist: excludePlaylist,
      exclude_videos: excludeVideos,
      include: 'category,channel',
      page: page > 1 ? page : undefined,
      sort: sort || undefined,
      filters:
        categoryId || channelId || search
          ? {
              category_id: categoryId || undefined,
              channel: channelId || undefined,
              search: search || undefined,
            }
          : undefined,
    },
  }
}
