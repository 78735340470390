type DeleteMyAccount = {
  feedback: string,
}
export default function deleteMyAccount(variables: DeleteMyAccount = {}) {
  const { feedback } = variables
  return {
    method: 'DELETE',
    path: '/publisher/account/delete',
    params: {
      feedback: feedback,
    },
  }
}
