// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Channel_wrapper__1e6fM {\n  --tw-bg-opacity: 1;\n  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));\n  --tw-border-opacity: 1;\n  border-color: rgba(229, 231, 235, var(--tw-border-opacity));\n  border-radius: 0.25rem;\n  border-width: 1px;\n  display: inline-flex;\n  align-items: center;\n  max-height: 100%;\n  max-width: 100%;\n  overflow: hidden;\n  padding: 0.75rem;\n  position: relative;\n  text-align: left;\n  --tw-text-opacity: 1;\n  color: rgba(31, 41, 55, var(--tw-text-opacity));\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);\n}\n\n.Channel_wrapper__1e6fM img {\n  border-radius: 9999px;\n  height: 4rem;\n  width: 4rem;\n}\n\n.Channel_wrapper__1e6fM > div {\n  padding-right: 0.5rem;\n  padding-left: 1rem;\n}\n\n.Channel_wrapper__1e6fM > div h3 {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  margin-bottom: 0.25rem;\n  line-height: 1.25;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/components/studio/components/layers/Channel.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,kBAAmB;EAAnB,2DAAmB;EAAnB,sBAAmB;EAAnB,2DAAmB;EAAnB,sBAAmB;EAAnB,iBAAmB;EAAnB,oBAAmB;EAAnB,mBAAmB;EAAnB,gBAAmB;EAAnB,eAAmB;EAAnB,gBAAmB;EAAnB,gBAAmB;EAAnB,kBAAmB;EAAnB,gBAAmB;EAAnB,oBAAmB;EAAnB,+CAAmB;EDAjB,sCAAsC;AACxC;;AAEA;ECHA,qBAAmB;EAAnB,YAAmB;EAAnB,WAAmB;ADKnB;;AAEA;ECPA,qBAAmB;EAAnB,kBAAmB;ADSnB;;AAEA;ECXA,eAAmB;EAAnB,mBAAmB;EAAnB,sBAAmB;EDajB,iBAAiB;AACnB","sourcesContent":[".wrapper {\n  @apply bg-white border border-gray-200 inline-flex items-center max-h-full max-w-full overflow-hidden p-3 relative rounded text-left text-gray-800;\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);\n}\n\n.wrapper img {\n  @apply h-16 rounded-full w-16;\n}\n\n.wrapper > div {\n  @apply pl-4 pr-2;\n}\n\n.wrapper > div h3 {\n  @apply mb-1 text-base;\n  line-height: 1.25;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Channel_wrapper__1e6fM"
};
export default ___CSS_LOADER_EXPORT___;
