type Variables = {
  commentId: number,
  text: string,
  mentions?: Array<number>,
}

export default function replyToComment(variables: Variables) {
  const { commentId, text, mentions } = variables
  return {
    method: 'post',
    path: `/comments/${commentId}/reply`,
    params: {
      text,
      mentions,
    },
  }
}
