import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Input from 'shared/components/form/input'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import Select from 'shared/components/form/select'
import classes from './Filters.module.css'

export default function Filters({ onSubmit }) {
  const mediaTypeOptions = [
    {
      label: 'Video',
      value: 'video',
    },
    {
      label: 'Podcast',
      value: 'podcast',
    },
  ]

  const form = useMiniForm({
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <Input
        {...form.getInputPropsFor('search')}
        placeholder="Search media..."
      />
      <Select
        className={classes.select}
        {...form.getInputPropsFor('mediaType')}
        options={mediaTypeOptions}
        placeholder="Select media type..."
        isClearable={true}
      />
      <HtmlSelect {...form.getInputPropsFor('sort')}>
        <option value="">Sort By...</option>
        <option value="published_at">Publish Date</option>
      </HtmlSelect>
      <Button outlined>Search</Button>
    </form>
  )
}
