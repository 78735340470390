type GetBudgetsQueryVariables = {
  year?: string,
}

export default function getBudgets(variables: GetBudgetsQueryVariables = {}) {
  const { year } = variables
  return {
    name: 'monetizationBudgets',
    path: '/admin/monetization/budget',
    params: {
      year: year,
    },
  }
}
