type GetCommentsQueryVariables = {
  page?: number,
  sort?: '' | 'most_liked' | 'oldest',
  video?: number,
  time?: string,
  perPage?: number,
  onlyRemembers?: true | false,
  onlyMyMentions?: true | false,
  onlyUnreadReplies?: true | false,
}

export default function getComments(variables: GetCommentsQueryVariables = {}) {
  const {
    page,
    sort,
    video,
    time,
    perPage,
    onlyRemembers,
    onlyMyMentions,
    onlyUnreadReplies,
  } = variables

  const filters = {}
  if (video) {
    filters.videos = `${video}`
  }

  if (time) {
    filters.time = time
  }

  if (onlyRemembers) {
    filters.only_remembers = onlyRemembers
  }

  if (onlyUnreadReplies) {
    filters.only_unread_replies = onlyUnreadReplies
  }
  if (onlyMyMentions) {
    filters.only_my_mentions = onlyMyMentions
  }

  return {
    path: '/publisher/comments',
    params: {
      filters,
      page,
      sort,
      per_page: perPage ? Number(perPage) : 50,
    },
  }
}
