import { useMemo } from 'react'
import useQuery from 'core/react-query/useQuery'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import Table from 'shared/components/kit/table'
import Page from 'admin/components/page'
import getCrypto from './queries/getCryptos'
import Filters from './components/Filters'
import Tag from 'shared/components/kit/tag'
import formatCryptocurrency from 'helpers/utils/formatCryptocurrency'

const FILTER_CONFIG = {
  base: '/admin/cryptoCurrencies',
  params: {
    page: 'pagination',
    search: 'any',
  },
}

export default function CryptoCurrencies() {
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)

  const { data, error, isError, isFetching } = useQuery(getCrypto, {
    keepPreviousData: true,
    variables: filters,
  })

  const {
    data: cryptos,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleFilter = (nextFilters) => {
    setFilters({
      ...nextFilters,
      page: undefined,
    })
  }

  if (isError) {
    return <Page error={error.message} />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  return (
    <>
      <Page description={description} title="Cryptocurrencies">
        <Filters initialFilters={filters} onSubmit={handleFilter} />
        <Table
          columns={columns}
          data={cryptos}
          loading={isFetching}
          currentPage={currentPage}
          lastPage={lastPage}
          onPaginate={handlePaginate}
        />
      </Page>
    </>
  )
}

function getColumns() {
  return [
    {
      className: 'w-24',
      render: (crypto) => (
        <img
          className="rounded-full"
          src={crypto?.thumbnails.small}
          alt={crypto?.name}
        />
      ),
    },
    {
      title: 'Name',
      render: (coin) => (
        <>
          {
            <>
              <span>
                {coin.symbol}
                {coin.status === 'delist' ? (
                  <>
                    &nbsp; <Tag type="bg-red-500">{coin.label}Delist</Tag>
                  </>
                ) : null}
              </span>
              <br />
              <span className="text-overlayColor-mutedText text-sm">
                {coin.name}
              </span>
            </>
          }
        </>
      ),
    },
    {
      title: 'Price',
      render: (coin) =>
        coin.ratio?.price ? formatCryptocurrency(coin.ratio?.price) : '-',
    },
    // {
    //   title: '24h Change',
    //   render: (coin) =>
    //     <Change value={coin.ratio?.percent_change_24h} /> > 0 ? (
    //       <Change value={coin.ratio?.percent_change_24h} />
    //     ) : coin.ratio?.percent_change_24h < 0 ? (
    //       <Change value={coin.ratio?.percent_change_24h} />
    //     ) : (
    //       <Change value={coin.ratio?.percent_change_24h} />
    //     ),
    // },
    {
      title: 'Market Cap',
      render: (coin) =>
        coin.ratio?.market_cap
          ? formatCryptocurrency(coin.ratio?.market_cap)
          : '-',
    },
  ]
}

function Change({ value }) {
  let className = ''

  if (value > 0) {
    className = 'text-green-400'
  } else if (value < 0) {
    className = 'text-red-400'
  }

  return (
    <span className={className}>
      {value
        ? value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + '%'
        : '-'}
    </span>
  )
}
