// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".user-avatar_wrapper__2Z8GL {\n  position: relative;\n}\n\n.user-avatar_wrapper__2Z8GL img {\n  height: 100%;\n  width: 100%;\n}\n\n.user-avatar_badge__3qVp1 {\n  bottom: 0;\n  position: absolute;\n  left: 61%;\n  width: 40%;\n}\n\n.user-avatar_badge__3qVp1 svg {\n  height: auto !important;\n  width: 100% !important;\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/user-avatar/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,kBAAmB;ADAnB;;AAEA;ECFA,YAAmB;EAAnB,WAAmB;ADInB;;AAEA;EACE,SAAS;EACT,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,uBAAuB;EACvB,sBAAsB;AACxB","sourcesContent":[".wrapper {\n  @apply relative;\n}\n\n.wrapper img {\n  @apply h-full w-full;\n}\n\n.badge {\n  bottom: 0;\n  position: absolute;\n  left: 61%;\n  width: 40%;\n}\n\n.badge svg {\n  height: auto !important;\n  width: 100% !important;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "user-avatar_wrapper__2Z8GL",
	"badge": "user-avatar_badge__3qVp1"
};
export default ___CSS_LOADER_EXPORT___;
