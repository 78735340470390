import Popover from 'shared/components/kit/popover'
import { useEffect, useRef, useState } from 'react'
import classes from './LoginMethod.module.css'

export default function LoginMethod({ user }) {
  const timeoutRef = useRef()
  const [popoverVisible, setPopoverVisible] = useState({})

  useEffect(() => {
    if (user) {
      setPopoverVisible({ visible: false, method: '' })
    }
  }, [user])

  const handleTogglePopover = (userId, loginMethod, visible) => {
    if (!visible) {
      clearTimeout(timeoutRef.current)
      setPopoverVisible((prev) => ({ ...prev, visible: false }))
    } else {
      timeoutRef.current = setTimeout(() => {
        setPopoverVisible({ visible: visible, loginMethod: loginMethod })
      }, 500)
    }
  }

  return (
    <Popover
      onHide={() => {}}
      visible={popoverVisible.visible}
      content={user[popoverVisible.loginMethod]}
      placement="bottom"
      className="text-primary">
      <div>
        {user.wallet && (
          <div
            onMouseEnter={() => handleTogglePopover(user.id, 'wallet', true)}
            onMouseLeave={() => handleTogglePopover(user.id, 'wallet', false)}
            className={classes.wallet}>
            Wallet
          </div>
        )}
        {user.email && (
          <div
            onMouseEnter={() => handleTogglePopover(user.id, 'email', true)}
            onMouseLeave={() => handleTogglePopover(user.id, 'email', false)}
            className={classes.email}>
            Email
          </div>
        )}
      </div>
    </Popover>
  )
}
