// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "form.Filters_filters__2j78Y {\n  display: flex;\n  margin-bottom: 2rem;\n}\n\nform.Filters_filters__2j78Y input {\n  margin-right: 1rem;\n  width: 24rem;\n}\n\nform.Filters_filters__2j78Y .Filters_userTypes__1LcaY {\n  margin-right: 1rem;\n  width: 20rem;\n}\n\nform.Filters_filters__2j78Y select {\n  margin-right: 1rem;\n  width: 18rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/users/components/Filters.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,mBAAmB;ADAnB;;AAEA;ECFA,kBAAmB;EAAnB,YAAmB;ADInB;;AAEA;ECNA,kBAAmB;EAAnB,YAAmB;ADQnB;;AAEA;ECVA,kBAAmB;EAAnB,YAAmB;ADYnB","sourcesContent":["form.filters {\n  @apply flex mb-8;\n}\n\nform.filters input {\n  @apply mr-4 w-96;\n}\n\nform.filters .userTypes {\n  @apply w-80  mr-4;\n}\n\nform.filters select {\n  @apply mr-4 w-72;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": "Filters_filters__2j78Y",
	"userTypes": "Filters_userTypes__1LcaY"
};
export default ___CSS_LOADER_EXPORT___;
