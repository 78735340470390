type UnpinCommentMutationVariables = {
  id: number,
}

export default function unpinComment(variables: UnpinCommentMutationVariables) {
  return {
    method: 'put',
    path: `/comments/${variables.id}/unpin`,
  }
}
