import { Children } from 'react'
import classnames from 'classnames'
import Icon from 'shared/components/kit/icon'
import { ReactComponent as DownSVG } from 'svg/down.svg'
import { ReactComponent as UpSVG } from 'svg/up.svg'
import classes from './index.module.css'

type AccordionProps = {
  activeName?: string,
  bordered?: boolean,
  className?: string,
  onChange?: Function,
}

export default function Accordion(props: AccordionProps) {
  const { activeName, bordered, children, className, onChange } = props

  const wrapperClassName = classnames(classes.wrapper, className, {
    [classes.bordered]: bordered,
  })

  return (
    <div className={wrapperClassName}>
      {Children.map(children, (child, key) => (
        <div
          key={key}
          className={activeName === child.props.name ? 'active' : undefined}>
          <header onClick={() => onChange(child.props.name)}>
            <h3>{child.props.title}</h3>
            {child.props.extra ? <div>{child.props.extra}</div> : null}
            <Icon>
              {activeName === child.props.name ? <UpSVG /> : <DownSVG />}
            </Icon>
          </header>
          <section>{child.props.children}</section>
        </div>
      ))}
    </div>
  )
}

Accordion.Item = () => null

Accordion.defaultProps = {
  bordered: true,
}
