import { createElement, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import useQuery from 'core/useQuery'
import useColorScheme from 'shared/hooks/useColorScheme'
import useProfile from 'shared/hooks/useProfile'
import Modal from 'shared/components/kit/modal'
import Popover from 'shared/components/kit/popover'
import ChannelAvatar from 'shared/components/channel-avatar'
import notification from 'shared/components/kit/notification'
import NotificationsList from 'publisher/components/notifications'
import UploadVideoLink from 'publisher/components/upload-video-link'
import getUnreadNotifications from 'publisher/queries/getUnreadNotifications'
import useLogout from 'publisher/hooks/useLogout'
import { ReactComponent as UploadSVG } from 'svg/upload.svg'
import { ReactComponent as NotificationSVG } from 'svg/notification-filled.svg'
import { ReactComponent as SecuritySVG } from 'svg/security.svg'
import { ReactComponent as NotificationOutlinedSVG } from 'svg/notification-outlined.svg'
import { ReactComponent as LogoutSVG } from 'icons/logout.svg'
import { ReactComponent as DarkModeSVG } from 'icons/dark-mode-filled.svg'
import { ReactComponent as LightModeSVG } from 'icons/light-mode-filled.svg'
import { ReactComponent as AccountSVG } from 'icons/account-circle-outlined.svg'
import { ReactComponent as SettingsSVG } from 'icons/settings-outlined.svg'
import YouTubeSync from './YouTubeSync'
import classes from './TopNavigation.module.css'

export default function TopNavigation() {
  const { channelStatus, isPublisher, user, publisherRequest } = useProfile()

  const disableUploadVideoLink = !isPublisher || channelStatus === 'freeze'

  return (
    <div className={classes.wrapper}>
      <header className={classes.header}>
        <nav className={classes.secondaryMenu}>
          <ul>
            <YouTubeSync />

            <li className={classes.upload}>
              <UploadVideoLink disabled={disableUploadVideoLink}>
                <UploadSVG />
                <span className="text-white">UPLOAD MEDIA</span>
              </UploadVideoLink>
            </li>

            <Notifications disabled={!isPublisher} />

            <Channel
              isPublisher={isPublisher}
              user={user}
              publisherRequest={publisherRequest}
            />
          </ul>
        </nav>
      </header>
    </div>
  )
}

function Notifications({ disabled }) {
  const [visible, setVisible] = useState(false)
  const location = useLocation()
  useEffect(() => {
    handleHide()
  }, [location])

  const handleShow = useCallback(() => {
    localStorage.setItem('notificationPopoverState', 'popover')
    setVisible(true)
  }, [])

  const handleHide = useCallback(() => {
    setVisible(false)
  }, [])

  const qr = useQuery(getUnreadNotifications)

  const count = get(qr, 'data.json.count', {})

  const link = createElement(disabled ? 'span' : 'a', {
    children: (
      <>
        {count > 0 ? <NotificationSVG /> : <NotificationOutlinedSVG />}
        {count > 0 && <span className={classes.dot}>{count}</span>}
      </>
    ),
    onClick: disabled ? undefined : handleShow,
  })

  return (
    <li className={classes.notifications}>
      <Popover
        className={classes.notificationsPopover}
        content={<NotificationsList />}
        onHide={handleHide}
        visible={visible}>
        {link}
      </Popover>
    </li>
  )
}

function Channel({ isPublisher, user, publisherRequest }) {
  const channel = user?.channel || {}

  const { mutateAsync: logout } = useLogout()

  const [showMenu, setShowMenu] = useState(false)

  const [showLogout, setShowLogout] = useState(false)

  const colorScheme = useColorScheme()

  const handleHide = useCallback(() => {
    setShowMenu(false)
  }, [])

  const handleShow = useCallback(() => {
    setShowMenu(true)
  }, [])

  const handleHideLogout = useCallback(() => {
    setShowLogout(false)
  }, [])

  const handleShowLogout = useCallback(() => {
    setShowMenu(false)
    setShowLogout(true)
  }, [])

  const handleLogout = useCallback(async () => {
    try {
      const res = await logout()
      notification.success({ title: res.json.message })
      window.location.href = '/'
    } catch (error) {}
  }, [logout])

  const handleSwitchTheme = () => {
    window.COLOR_SCHEME.switch()
  }

  const channelMenu = (
    <>
      <div className={classes.channel}>
        <ChannelAvatar channel={channel} />
        <span>
          {isPublisher ? channel.name : publisherRequest.channel_name}
        </span>
        <a className={classes.scheme} onClick={handleSwitchTheme}>
          {colorScheme === 'light' ? <DarkModeSVG /> : <LightModeSVG />}
        </a>
      </div>
      <ul>
        {isPublisher && (
          <>
            <li>
              <Link onClick={handleHide} to="/publisher/channel">
                <AccountSVG />
                <span>Edit Channel Info</span>
              </Link>
            </li>
            <li>
              <Link onClick={handleHide} to="/publisher/profile">
                <SecuritySVG />
                <span>Security</span>
              </Link>
            </li>
          </>
        )}
        <li>
          <a onClick={handleShowLogout}>
            <LogoutSVG />
            <span>Logout</span>
          </a>
        </li>
      </ul>
    </>
  )

  return (
    <>
      <li className={classes.settings}>
        <Popover
          className={classes.userMenu}
          content={channelMenu}
          onHide={handleHide}
          visible={showMenu}>
          <a onClick={handleShow}>
            <SettingsSVG />
          </a>
        </Popover>
      </li>

      <Modal
        okText="Yes, Log out"
        onCancel={handleHideLogout}
        onOk={handleLogout}
        size="sm"
        title="Log out"
        visible={showLogout}>
        <p>Log out of publisher panel?</p>
      </Modal>
    </>
  )
}
