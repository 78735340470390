import Select from 'shared/components/form/select'

export default function SelectUserType(props) {
  const options = [
    {
      label: 'Hodl members',
      value: 'yes',
    },
    {
      label: 'Non Hodl members',
      value: 'no',
    },
  ]

  return (
    <Select {...props} options={options} placeholder="Select User Type..." />
  )
}
