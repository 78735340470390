import dayjs from 'dayjs'
import Slot from './Slot'
import classnames from 'classnames'
import classes from './Cell.module.css'

export default function Cell({
  color,
  prefill,
  tier,
  day,
  slots,
  selectedSlots,
  onSelectedSlot,
}) {
  const today = dayjs().format('YYYY-MM-DD')
  const isCurrentDay = day === today
  return (
    <div
      className={classnames(
        classes.cell,
        isCurrentDay ? classes.highlighted : ''
      )}
      onClick={() => (onSelectedSlot ? onSelectedSlot(tier, day) : null)}>
      {slots.map((slot, index) => {
        return (
          <Slot
            key={index}
            selected={selectedSlots + prefill > index}
            prefilled={prefill > index}
            color={color}
          />
        )
      })}
    </div>
  )
}
