type GetContentVariables = {
  key: string,
}

export default function getContent(variables: GetContentVariables = {}) {
  const { key } = variables
  const filters = {}
  if (key) {
    filters.key = key
  }
  return {
    path: '/admin/contents',
    params: {
      filters,
    },
  }
}
