type Variables = {
  data: any,
  videoId: number,
}

export default function saveVideoStudioDraft(variables: Variables) {
  const { data, videoId } = variables

  return {
    path: `/publisher/videos/${videoId}/meta/studio_draft`,
    params: {
      value: data,
    },
  }
}
