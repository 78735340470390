import { useState } from 'react'
import dayjs from 'dayjs'
import useMiniForm from 'core/useMiniForm'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import lotteryDraw from 'admin/queries/lotteryDraw'
import getLotteries from 'admin/queries/getLotteries'

export default function LotteryButton({ currentMonthLottery }) {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()

  const lotteryMutation = useMutation(lotteryDraw, {
    onSuccess: () => {
      localStorage.setItem('currentMonthLotteryDate', currentMonthLottery.date)
      queryClient.invalidateQueries({
        query: getLotteries,
      })
      setVisible(false)
    },
  })

  const form = useMiniForm({
    resetOnSuccess: true,
    async onSubmit() {
      try {
        setLoading(true)
        const month = `${currentMonthLottery.date}`
        const monthName = dayjs(month).format('MMMM')
        await lotteryMutation.mutateAsync({
          month: month,
        })
        setVisible(false)
        form.reset()
        notification.success({
          title: `The lottery was held in ${monthName}`,
        })
      } catch (error) {
        throw error
      } finally {
        setLoading(false)
      }
    },
  })
  //

  const handleCancel = () => {
    setVisible(false)
    form.reset()
  }

  const handleOk = () => {
    form.submit()
  }

  return (
    <>
      <Button className="mt-4 mx-28" onClick={() => setVisible(true)}>
        Lottery
      </Button>

      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        okText="Lottery"
        loading={loading}
        title={`Lottery of ${dayjs(currentMonthLottery?.date).format(
          'MMMM YYYY'
        )}`}
        size="sm"
        visible={visible}>
        Are you sure about the lottery?
      </Modal>
    </>
  )
}
