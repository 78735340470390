// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tag_wrapper__yGMEf {\n  border-radius: 1rem;\n  display: inline-flex;\n  align-items: center;\n  font-size: 0.75rem;\n  line-height: 1rem;\n  line-height: 1rem;\n  padding-top: 0.25rem;\n  padding-bottom: 0.25rem;\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n  text-transform: uppercase;\n  vertical-align: middle;\n  white-space: nowrap;\n}\n\n.tag_wrapper__yGMEf svg {\n  height: 1rem;\n  margin-right: 0.125rem;\n  fill: currentColor;\n  width: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/kit/tag/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,mBAAmB;EAAnB,oBAAmB;EAAnB,mBAAmB;EAAnB,kBAAmB;EAAnB,iBAAmB;EAAnB,iBAAmB;EAAnB,oBAAmB;EAAnB,uBAAmB;EAAnB,oBAAmB;EAAnB,qBAAmB;EAAnB,yBAAmB;EAAnB,sBAAmB;EAAnB,mBAAmB;ADAnB;;AAEA;ECFA,YAAmB;EAAnB,sBAAmB;EAAnB,kBAAmB;EAAnB,WAAmB;ADInB","sourcesContent":[".wrapper {\n  @apply align-middle inline-flex items-center leading-4 rounded-2xl px-2 py-1 text-xs uppercase whitespace-nowrap;\n}\n\n.wrapper svg {\n  @apply fill-current h-4 mr-0.5 w-4;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "tag_wrapper__yGMEf"
};
export default ___CSS_LOADER_EXPORT___;
