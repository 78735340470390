type sendReplyToMessageMutationVariables = {
  id: number,
  message: string,
}

export default function sendReplyToMessage(
  variables: sendReplyToMessageMutationVariables
) {
  const { message, id } = variables
  return {
    method: 'post',
    url: `/admin/messages/${id}/reply`,
    variables: { message },
  }
}
