import { useLayoutEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import dayjs from 'dayjs'
import useQuery from 'core/react-query/useQuery'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import Card from 'shared/components/kit/card'
import formatDate from 'helpers/utils/formatDate'
import Page from 'admin/components/page'
import formatNumber from 'helpers/utils/formatNumber'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import SelectUser from 'admin/components/form/select-user'
import { ReactComponent as ChevronLeftSVG } from 'svg/chevron-left.svg'
import { ReactComponent as ChevronRightSVG } from 'svg/chevron-right.svg'
import { ReactComponent as CloseSVG } from 'icons/close.svg'
import { ReactComponent as ErrorSVG } from 'icons/error-circle-filled.svg'
import formatDateForAPI from 'shared/helpers/formatDateForAPI'
import formatDateOnly from 'helpers/utils/formatDateOnly'
import Spin from 'shared/components/kit/spin'
import Users from './components/Users'

const FILTERS = {
  base: '/admin/security/traffic',
  params: {
    user_id: 'number',
    ip_address: 'any',
    route: 'any',
    date: 'any',
  },
}

export default function Traffic() {
  const [filters, setFilters] = useFiltersFromURL(FILTERS)

  const [userIds, setUserIds] = useState([])

  useLayoutEffect(() => {
    setUserIds([])
  }, [filters])

  const date = dayjs(filters.date || undefined)

  const { data, error, isError, isFetching, isLoading } = useQuery(
    getRateLimitOverview,
    {
      keepPreviousData: true,
      variables: {
        user_id: filters.user_id,
        ip_address: filters.ip_address,
        route: filters.route,
        date: date ? formatDateForAPI(filters.date) : null,
      },
    }
  )

  const groupedByUserId = data?.json?.user_id || []
  const groupedByIpAddress = data?.json?.ip_address || []
  const groupedByApiRoute = data?.json?.route || []
  const suspiciousIpAddresses = data?.json?.suspicious_ips || {}

  const suspiciousIps = useMemo(
    () => Object.keys(suspiciousIpAddresses),
    [suspiciousIpAddresses]
  )

  const handleSearchUser = (userId) => {
    setFilters((prev) => ({
      ...prev,
      user_id: userId,
    }))
  }

  const handleNextDate = () => {
    setFilters((prev) => ({
      ...prev,
      date: formatDateOnly(date.add(1, 'day')),
    }))
  }

  const handlePrevDate = () => {
    setFilters((prev) => ({
      ...prev,
      date: formatDateOnly(date.subtract(1, 'day')),
    }))
  }

  const handleDisplayUsersDetail = () => {
    setUserIds(groupedByUserId.map((user) => user._id))
  }

  const total = data?.json.total || 1

  return (
    <Page title="Traffic">
      <header className="mb-8">
        <div className="flex gap-4">
          <div className="border border-light-2 bg-light rounded-lg w-48 flex items-center">
            <button className="px-2 text-neutral" onClick={handlePrevDate}>
              <ChevronLeftSVG className="h-6 w-6 fill-current" />
            </button>
            <span className="flex-grow text-center">
              {formatDateOnly(date)}
            </span>
            <button className="px-2 text-neutral" onClick={handleNextDate}>
              <ChevronRightSVG className="h-6 w-6 fill-current" />
            </button>
          </div>

          {filters.user_id ? (
            <SelectedFilter
              label="User ID"
              value={filters.user_id}
              onRemove={() =>
                setFilters((prev) => ({ ...prev, user_id: undefined }))
              }
            />
          ) : null}

          {filters.ip_address ? (
            <SelectedFilter
              label="IP Address"
              value={filters.ip_address}
              onRemove={() =>
                setFilters((prev) => ({ ...prev, ip_address: undefined }))
              }
            />
          ) : null}

          {filters.route ? (
            <SelectedFilter
              label="API Route"
              value={filters.route.replace('\\App\\Http\\Controllers\\', '')}
              onRemove={() =>
                setFilters((prev) => ({ ...prev, route: undefined }))
              }
            />
          ) : null}

          <span className="flex-grow" />

          <div style={{ minWidth: 250 }}>
            <SelectUser
              key={filters.user_id || 0}
              onChange={handleSearchUser}
              value={null}
              placeholder="Search for user..."
            />
          </div>
        </div>
      </header>

      <div
        className={classnames(
          'grid grid-cols-3 text-lg bg-light border border-light-2 rounded-lg',
          {
            'opacity-80': isFetching,
          }
        )}>
        <div>
          <header className="flex items-center justify-between h-12 px-4 border-b border-light-1 text-neutral">
            <span>
              API Calls by User ID &nbsp; {isFetching ? <Spin /> : null}
            </span>
            {filters.ip_address && groupedByUserId.length > 0 ? (
              <Button
                disabled={isFetching || isLoading}
                size="sm"
                onClick={handleDisplayUsersDetail}
                transparent
                noHorizontalPadding>
                Load Users Report
              </Button>
            ) : null}
          </header>
          <div className="max-h-64 overflow-y-auto py-2">
            {groupedByUserId.length > 0 ? (
              groupedByUserId.map(({ _id, count_all, count_blocked }, key) => (
                <div key={key} className="flex gap-4 items-center px-4 py-1">
                  {_id ? (
                    <span
                      className="text-blue-500 cursor-pointer hover:underline"
                      onClick={() =>
                        setFilters((prev) => ({ ...prev, user_id: _id }))
                      }>
                      {_id}
                    </span>
                  ) : (
                    <span className="text-neutral">Unknown</span>
                  )}
                  <span className="flex-grow" />
                  <span className="text-neutral whitespace-nowrap">
                    <span className="text-red-500">
                      {formatNumber(count_blocked)}
                    </span>
                    <small> / </small>
                    <small>{formatNumber(count_all)}</small>
                  </span>
                  <Progress value={count_all} total={total} />
                </div>
              ))
            ) : (
              <p className="px-4 py-1">
                {isLoading ? 'Loading...' : 'No data'}
              </p>
            )}
          </div>
        </div>

        <div className="border-l border-light-2">
          <header className="flex items-center h-12 px-4 border-b border-light-1 text-neutral">
            API Calls by IP Address &nbsp; {isFetching ? <Spin /> : null}
          </header>
          <div className="max-h-64 overflow-y-auto py-2">
            {groupedByIpAddress.length > 0 ? (
              groupedByIpAddress.map(
                ({ _id, count_all, count_blocked }, key) => (
                  <div key={key} className="flex gap-4 items-center px-4 py-1">
                    {_id ? (
                      <span
                        className="inline-flex items-center text-blue-500 cursor-pointer hover:underline"
                        onClick={() =>
                          setFilters((prev) => ({ ...prev, ip_address: _id }))
                        }>
                        {_id}{' '}
                        {suspiciousIps.indexOf(_id) > -1 && (
                          <span
                            className={classnames(
                              'ml-2',
                              suspiciousIpAddresses[_id] >= 4
                                ? 'text-red-500'
                                : 'text-gray-300'
                            )}>
                            <ErrorSVG className="h-4 w-4 fill-current" />
                          </span>
                        )}
                      </span>
                    ) : (
                      <span className="text-neutral">Unknown</span>
                    )}
                    <span className="flex-grow" />
                    <span className="text-neutral whitespace-nowrap">
                      <span className="text-red-500">
                        {formatNumber(count_blocked)}
                      </span>
                      <small> / </small>
                      <small>{formatNumber(count_all)}</small>
                    </span>
                    <Progress value={count_all} total={total} />
                  </div>
                )
              )
            ) : (
              <p className="px-4 py-1">
                {isLoading ? 'Loading...' : 'No data'}
              </p>
            )}
          </div>
        </div>

        <div className="border-l border-light-2">
          <header className="flex items-center h-12 px-4 border-b border-light-1 text-neutral">
            API Calls by API Route &nbsp; {isFetching ? <Spin /> : null}
          </header>
          <div className="max-h-64 overflow-y-auto py-2">
            {groupedByApiRoute.length > 0 ? (
              groupedByApiRoute.map(
                ({ _id, count_all, count_blocked }, key) => (
                  <div key={key} className="flex gap-4 items-center px-4 py-1">
                    {_id ? (
                      <span
                        className="text-blue-500 cursor-pointer hover:underline"
                        onClick={() =>
                          setFilters((prev) => ({ ...prev, route: _id }))
                        }>
                        {_id.replace('\\App\\Http\\Controllers\\', '')}
                      </span>
                    ) : (
                      <span className="text-neutral">Unknown</span>
                    )}
                    <span className="flex-grow" />
                    <span className="text-neutral whitespace-nowrap">
                      <span className="text-red-500">
                        {formatNumber(count_blocked)}
                      </span>
                      <small> / </small>
                      <small>{formatNumber(count_all)}</small>
                    </span>
                    <Progress value={count_all} total={total} />
                  </div>
                )
              )
            ) : (
              <p className="px-4 py-1">
                {isLoading ? 'Loading...' : 'No data'}
              </p>
            )}
          </div>
        </div>
      </div>

      {userIds.length > 0 ? (
        <Users filters={filters} userIds={userIds} />
      ) : filters.ip_address && groupedByUserId.length > 0 ? (
        <div className="text-center pb-8 pt-24">
          <Button
            disabled={isFetching || isLoading}
            size="lg"
            onClick={handleDisplayUsersDetail}>
            Load Users Report
          </Button>
        </div>
      ) : null}
    </Page>
  )
}

function SelectedFilter({ label, value, onRemove }) {
  return (
    <span className="inline-flex items-center gap-4 px-3 bg-light border border-light-2 rounded-lg">
      <span className="flex flex-col">
        <small className="leading-4 text-neutral">{label}</small>
        <span
          className="leading-4 overflow-hidden overflow-ellipsis"
          style={{ maxWidth: 250 }}>
          {value}
        </span>
      </span>
      <span
        className="bg-red-400 text-light h-5 w-5 p-0.5 rounded-full inline-flex items-center justify-center cursor-pointer hover:bg-red-300"
        onClick={onRemove}>
        <CloseSVG className="fill-current" />
      </span>
    </span>
  )
}

function Progress({ value, total }) {
  return null
  return (
    <span className="flex bg-light-2 rounded-lg overflow-hidden w-36">
      <span
        className="bg-blue-500 h-2"
        style={{ width: `${Math.min(100, (100 * value) / total)}%` }}
      />
    </span>
  )
}

function getRateLimitOverview({ user_id, ip_address, route, date }) {
  const filters = {}

  if (user_id) {
    filters.user_id = user_id
  }

  if (ip_address) {
    filters.ip_address = ip_address
  }

  if (route) {
    filters.route = route
  }

  if (date) {
    filters.date = date
  }

  const params = Object.keys(filters).length > 0 ? { filters } : {}

  return {
    path: '/security-rate-limit/report',
    params,
  }
}
