import { useState } from 'react'
import useMiniForm from 'core/useMiniForm'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import setTotalDistributedMoney from 'admin/queries/setTotalDistributedMoney'
import getTotalDistributedMoney from 'admin/queries/getTotalDistributedMoney'

const VALIDATION = {
  month: {
    label: 'Month',
    rule: 'required',
  },
  totalDistributedMoney: {
    label: 'Total Distributed Money',
    rule: 'required',
  },
}

export default function SetTotalDistributedMoney({ initialValues }) {
  const queryClient = useQueryClient()

  const [visible, setVisible] = useState(false)

  const mutation = useMutation(setTotalDistributedMoney, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getTotalDistributedMoney,
      })
      notification.success({ title: 'Monthly Total Distributed Money Saved!' })
    },
    onError: (error) => {
      Object.values(error.json.errors).forEach((er) =>
        notification.error({ title: er })
      )
    },
  })

  const form = useMiniForm({
    resetOnSuccess: true,
    validation: VALIDATION,
    initialValues: initialValues,
    async onSubmit(values) {
      try {
        await mutation.mutateAsync(values)
        setVisible(false)
      } catch (error) {
        throw error
      }
    },
  })

  const handleCancel = () => {
    setVisible(false)
    form.reset()
  }

  const handleOk = () => {
    form.submit()
  }

  return (
    <>
      <Button onClick={() => setVisible(true)} secondary outlined>
        Set Total Distributed Money
      </Button>

      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        okText="Save"
        loading={mutation.isLoading}
        size="sm"
        title="Set Total Distributed Money"
        visible={visible}>
        <form onSubmit={form.submit}>
          <Field
            label="Total Distributed Money (USD)"
            {...form.getErrorPropsFor('totalDistributedMoney')}>
            <Input
              type="number"
              min="1"
              required
              {...form.getInputPropsFor('totalDistributedMoney')}
            />
          </Field>
        </form>
      </Modal>
    </>
  )
}
