import React from 'react'
import Spin from '../spin/index'
import Icon from '../icon'
import classes from './index.module.css'

export default function Loading() {
  return (
    <div className={classes.loading}>
      <Icon>
        <Spin />
      </Icon>
    </div>
  )
}
