import Button from 'shared/components/kit/button'
import classes from './BannerAdsCreateCampaign.module.css'

export default function Step3({ changeStep, form, onConfirm, isEditMode }) {
  return (
    <div className={classes.step3}>
      <h3 className={classes.subHeader}>Summary</h3>
      <table className={classes.summaryTable}>
        <thead>
          <tr>
            <td>Specification</td>
            <td>Quantity</td>
            <td>Unit Price (excl VAT)</td>
            <td>VAT rate</td>
            <td>VAT</td>
            <td>Amount</td>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
      <div className={classes.totalTable}>
        <div>
          <span>Total Amount excl VAT:</span>
          <span>Total VAT:</span>
          <span>
            <b>Total amount payable:</b>
          </span>
        </div>
        <div>
          <span>$123</span>
          <span>$0</span>
          <span>
            <b>$3240</b>
          </span>
        </div>
      </div>
      <div className={classes.actions}>
        <Button type="button" mood="neutral" onClick={() => changeStep('2')}>
          back
        </Button>
        {!isEditMode && (
          <Button
            disabled={!form.getInputPropsFor('companyId').value}
            type="button"
            onClick={onConfirm}>
            Confirm Campaign
          </Button>
        )}
      </div>
    </div>
  )
}
