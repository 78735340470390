import { useRef, useState } from 'react'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import notification from 'shared/components/kit/notification'
import createAdmin from 'admin/queries/createAdmin'
import getAdmins from 'admin/queries/getAdmins'

const VALIDATION = {
  username: {
    label: 'Username',
    rule: 'required',
  },
  email: {
    label: 'Email',
    rule: 'required',
  },
}

export default function CreateAdminButton() {
  const submitButtonRef = useRef()

  const [visible, setVisible] = useState(false)

  const queryClient = useQueryClient()

  const mutation = useMutation(createAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries({ query: getAdmins })
    },
    onError: (error) => {
      Object.values(error.json.errors).forEach((er) =>
        notification.error({ title: er })
      )
    },
  })

  const form = useMiniForm({
    resetOnSuccess: true,
    validation: VALIDATION,
    async onSubmit(values) {
      try {
        await mutation.mutateAsync(values)
        setVisible(false)
        notification.success({
          title: (
            <>
              Admin created successfully!
              <br />
              An email was sent to new admin to assign a password.
            </>
          ),
        })
      } catch (error) {
        throw error
      }
    },
  })

  const handleCancel = () => {
    setVisible(false)
    form.reset()
  }

  const handleOk = () => {
    submitButtonRef.current.click()
  }

  return (
    <>
      <Button onClick={() => setVisible(true)} outlined>
        Create a Admin
      </Button>

      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Create a Admin"
        visible={visible}>
        <form onSubmit={form.submit}>
          <Field label="Username" {...form.getErrorPropsFor('username')}>
            <Input required {...form.getInputPropsFor('username')} />
          </Field>
          <Field label="Email" {...form.getErrorPropsFor('email')}>
            <Input type="email" required {...form.getInputPropsFor('email')} />
          </Field>
          <input
            ref={submitButtonRef}
            hidden
            type="submit"
            id="tempSubmitBtn"
          />
        </form>
      </Modal>
    </>
  )
}
