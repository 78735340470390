type AcceptPublisherRequestMutationVariables = {
  id: number,
}

export default function acceptPublisherRequest(
  variables: AcceptPublisherRequestMutationVariables
) {
  return {
    method: 'put',
    url: `/admin/publisher-requests/${variables.id}/confirm`,
    variables,
  }
}
