import Tag from 'shared/components/kit/tag'
import classnames from 'classnames'

const STATUSES = {
  verified: {
    color: 'bg-green-400',
    label: 'Verified',
  },
  not_verified: {
    color: 'bg-red-400',
    label: 'Not verified',
  },
}

export default function AccountStatus({ status, className }) {
  const data = STATUSES[status]
  return <Tag type={classnames(data.color, className)}>{data.label}</Tag>
}
