// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".overview_charts__30MMg {\n  display: grid;\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n  gap: 2rem;\n  grid-template-columns: repeat(2, minmax(0, 1fr));\n}\n\n.overview_charts__30MMg > .box {\n  padding: 1.5rem;\n}\n\n.overview_charts__30MMg > .box > header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 1rem;\n}\n\n.overview_charts__30MMg select {\n  width: 14rem;\n}\n\n.overview_loading__249AB {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  font-size: 1.125rem;\n  line-height: 1.75rem;\n  opacity: 0.5;\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n}\n\n.overview_boxLoading__2Dc-9 {\n  min-height: 346px;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/channel/overview/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,gBAAmB;EAAnB,mBAAmB;EAAnB,SAAmB;EAAnB,gDAAmB;ADAnB;;AAEA;ECFA,eAAmB;ADInB;;AAEA;ECNA,aAAmB;EAAnB,mBAAmB;EAAnB,8BAAmB;EAAnB,mBAAmB;ADQnB;;AAEA;ECVA,YAAmB;ADYnB;;AAEA;ECdA,aAAmB;EAAnB,mBAAmB;EAAnB,uBAAmB;EAAnB,YAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,YAAmB;EAAnB,kBAAmB;EAAnB,QAAmB;EAAnB,SAAmB;EAAnB,WAAmB;ADgBnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".charts {\n  @apply grid grid-cols-2 gap-8 mb-8 mt-8;\n}\n\n.charts > :global(.box) {\n  @apply p-6;\n}\n\n.charts > :global(.box) > header {\n  @apply flex items-center justify-between mb-4;\n}\n\n.charts select {\n  @apply w-56;\n}\n\n.loading {\n  @apply absolute flex h-full items-center justify-center left-0 opacity-50 text-lg top-0 w-full;\n}\n\n.boxLoading {\n  min-height: 346px;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"charts": "overview_charts__30MMg",
	"loading": "overview_loading__249AB",
	"boxLoading": "overview_boxLoading__2Dc-9"
};
export default ___CSS_LOADER_EXPORT___;
