type SetReasonsVariables = {
  reasons: Array<{
    key: string,
    value: string,
  }>,
  key: string,
}

export default function setReasons(variables: SetReasonsVariables) {
  const { key, reasons } = variables
  return {
    method: 'post',
    url: `/admin/options/reasons/${key}`,
    variables: {
      reasons: reasons,
    },
  }
}
