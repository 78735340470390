import { useMemo } from 'react'
import useQuery from 'core/react-query/useQuery'
import get from 'lodash/get'
import Table from 'shared/components/kit/table'
import ReasonStatus from 'shared/components/reason-status'
import getReasons from 'admin/queries/getReasons'
import AddReason from 'admin/pages/setting/reportedVideoReasons/components/AddReason'
import Page from 'admin/components/page'
import UpdateReason from 'admin/pages/setting/reportedVideoReasons/components/UpdateReason'
import classes from './index.module.css'

export default function Payouts() {
  // const { data, isLoading } = useQuery(getReasons, {
  //   keepPreviousData: true,
  //   variables: {
  //     key: 'video_report_reasons',
  //   },
  // })

  // let reasons = get(data, 'json', []).filter((reason) => {
  //   reason.status = reason.status === 'active' ? 'active' : 'inactive'
  //   return reason
  // })

  // const columns = useMemo(() => getColumns(reasons), [reasons])

  //const actions = <AddReason reasons={reasons} />
  return (
    <>
      <Page title="Monetizaion Payouts">
        <h1>Monetization payouts</h1>
      </Page>
    </>
  )
}

function getColumns(reasons) {
  return [
    {
      className: classes.key,
      title: 'Key',
      render: (reason) => reason.key,
    },
    {
      className: classes.value,
      title: 'Reason',
      render: (reason) => reason.value,
    },
    {
      className: classes.status,
      title: 'Status',
      render: (reason) => <ReasonStatus status={reason.status} />,
    },
    {
      align: 'right',
      className: classes.updateReason,
      render: (reason) => <UpdateReason reason={reason} reasons={reasons} />,
    },
  ]
}
