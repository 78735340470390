import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import classnames from 'classnames'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Button from 'shared/components/kit/button'
import createModal from './createModal'
import modals from './modals'
import classes from './index.module.css'

type Props = {
  cancelButtonProps?: Object,
  cancelText?: any,
  closeIconVisible?: boolean,
  closeOnBackdrop?: boolean,
  footer?: any,
  footerLeft?: any,
  footerRight?: any,
  header?: any,
  loading?: boolean,
  forceDarkMode?: boolean,
  modalClassName?: string,
  okButtonProps?: Object,
  okText?: any,
  onCancel?: Function,
  onOk?: Function,
  size?: 'lg' | 'md' | 'sm',
  submitting?: boolean,
  title?: any,
  visible?: boolean,
}

export default function Modal(props: Props) {
  const {
    cancelButtonProps,
    cancelText,
    children,
    closeOnBackdrop,
    closeIconVisible,
    forceDarkMode,
    footer,
    footerLeft,
    footerRight,
    modalClassName,
    okButtonProps,
    okText,
    onCancel,
    onOk,
    size,
    submitting,
    title,
    visible,
  } = props

  const [id] = useState(() => modals().getNextId())

  const targetRef = useRef()

  if (!targetRef.current) {
    targetRef.current = document.createElement('div')
    document.body.appendChild(targetRef.current)
  }

  useLayoutEffect(() => {
    if (visible) {
      modals().add(id, targetRef.current)
    } else {
      modals().remove(id)
    }
  }, [id, visible])

  useLayoutEffect(() => {
    return () => {
      modals().remove(id)
    }
  }, [id])

  useLayoutEffect(() => {
    if (visible) {
      disableBodyScroll(window)
    } else {
      enableBodyScroll(window)
    }
  }, [visible])

  useEffect(() => {
    return () => {
      if (targetRef.current) {
        targetRef.current.parentNode.removeChild(targetRef.current)
      }
    }
  }, [])

  const handleClickBackdrop = () => {
    if (closeOnBackdrop && !submitting) {
      onCancel()
    }
  }

  const handleCancel = () => {
    if (onCancel && !submitting) {
      onCancel()
    }
  }

  const handleOk = () => {
    if (onOk) {
      onOk()
    }
  }

  const wrapperClassName = classnames(classes.wrapper, {
    [classes[size]]: !!size,
    [classes.visible]: visible,
    [classes.forceDarkMode]: forceDarkMode,
  })

  let modalFooter

  if (
    typeof footer === 'undefined' &&
    typeof footerLeft === 'undefined' &&
    typeof footerRight === 'undefined'
  ) {
    modalFooter = (
      <footer className={classes.simpleFooter}>
        <Button
          {...cancelButtonProps}
          mood="neutral"
          onClick={handleCancel}
          transparent>
          {cancelText}
        </Button>
        <Button loading={submitting} {...okButtonProps} onClick={handleOk}>
          {okText}
        </Button>
      </footer>
    )
  } else if (footerLeft || footerRight) {
    modalFooter = (
      <footer className={classes.advancedFooter}>
        <div className={classes.leftFooter}>{footerLeft}</div>
        <div className={classes.rightFooter}>{footerRight}</div>
      </footer>
    )
  } else if (footer) {
    modalFooter = <footer className={classes.simpleFooter}>{footer}</footer>
  }

  const stopEventBubbling = (event) => {
    event.stopPropagation()
  }

  const content = (
    <div
      className={wrapperClassName}
      onClick={stopEventBubbling}
      onMouseDown={stopEventBubbling}
      onMouseEnter={stopEventBubbling}
      onMouseLeave={stopEventBubbling}
      onMouseMove={stopEventBubbling}
      onMouseOut={stopEventBubbling}
      onMouseOver={stopEventBubbling}
      onMouseUp={stopEventBubbling}>
      <div className={classes.backdrop} onClick={handleClickBackdrop} />
      <div className={classnames(classes.modal, modalClassName)}>
        {(closeIconVisible || title) && (
          <header>
            <span>{title}</span>
            {closeIconVisible && (
              <button className={classes.close} onClick={handleCancel}>
                ✕
              </button>
            )}
          </header>
        )}
        <section>{children}</section>
        {modalFooter}
      </div>
    </div>
  )

  if (targetRef.current) {
    return createPortal(content, targetRef.current)
  }

  return null
}

Modal.open = createModal
Modal.modals = modals

Modal.defaultProps = {
  cancelText: 'Cancel',
  closeOnBackdrop: true,
  closeIconVisible: true,
  okText: 'Ok',
  size: 'lg',
}
