// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimelineHelperLines_helpers__3DL88 {\n  height: 4rem;\n  font-size: 0.75rem;\n  line-height: 1rem;\n  padding-top: 1.5rem;\n  position: relative;\n  color: var(--neutral);\n}\n\n.TimelineHelperLines_helpers__3DL88 > span {\n  border-color: var(--light-2);\n  border-left-width: 1px;\n  display: block;\n  padding-left: 0.5rem;\n  position: absolute;\n  top: 2.5rem;\n  bottom: -1px;\n}\n\n.dark .TimelineHelperLines_helpers__3DL88 > span {\n  border-color: var(--dark-2);\n}\n\n.TimelineHelperLines_helpers__3DL88 > span:first-child {\n  border-left-width: 0px;\n}\n\n.TimelineHelperLines_helpers__3DL88 > span:last-child {\n  border-right-width: 0px;\n  border-left-width: 0px;\n  padding-left: 0px;\n  padding-right: 0.5rem;\n  right: 0px;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/components/studio/components/TimelineHelperLines.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,YAAmB;EAAnB,kBAAmB;EAAnB,iBAAmB;EAAnB,mBAAmB;EAAnB,kBAAmB;EAAnB,qBAAmB;ADAnB;;AAEA;ECFA,4BAAmB;EAAnB,sBAAmB;EAAnB,cAAmB;EAAnB,oBAAmB;EAAnB,kBAAmB;EAAnB,WAAmB;EAAnB,YAAmB;ADKnB;;ACLA;EAAA;AAAmB;;ADOnB;ECPA,sBAAmB;ADSnB;;AAEA;ECXA,uBAAmB;EAAnB,sBAAmB;EAAnB,iBAAmB;EAAnB,qBAAmB;EAAnB,UAAmB;ADanB","sourcesContent":[".helpers {\n  @apply h-16 pt-6 relative text-neutral text-xs;\n}\n\n.helpers > span {\n  @apply absolute block border-l border-light-2 -bottom-px pl-2 top-10;\n  @apply dark:border-dark-2;\n}\n\n.helpers > span:first-child {\n  @apply border-l-0;\n}\n\n.helpers > span:last-child {\n  @apply border-l-0 border-r-0 pl-0 pr-2 right-0;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": "TimelineHelperLines_helpers__3DL88",
	"dark": "dark"
};
export default ___CSS_LOADER_EXPORT___;
