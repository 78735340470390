import Box from 'shared/components/kit/box'
import Button from 'shared/components/kit/button'
import ViewChart from './components/ViewChart'
import LikeChart from './components/LikeChart'
import CommentChart from './components/CommentChart'

export default function ChannelStatisticsChart({
  currentChart,
  setCurrentChart,
  data,
  Filter,
  range,
  likeChartedData,
  viewChartedData,
  commentChartedData,
  title,
}) {
  return (
    <Box>
      <header>
        <h4>{title}</h4>
        {Filter}
      </header>
      <div className="space-x-2 text-center">
        <Button
          onClick={() => setCurrentChart('views')}
          size="sm"
          secondary={currentChart !== 'views'}
          outlined>
          Views
        </Button>
        <Button
          onClick={() => setCurrentChart('likes')}
          size="sm"
          secondary={currentChart !== 'likes'}
          outlined>
          Likes
        </Button>
        <Button
          onClick={() => setCurrentChart('comments')}
          size="sm"
          secondary={currentChart !== 'comments'}
          outlined>
          Comments
        </Button>
      </div>
      {currentChart === 'views' && (
        <div>
          <ViewChart chartedData={viewChartedData} data={data} range={range} />
        </div>
      )}
      {currentChart === 'likes' && (
        <div>
          <LikeChart chartedData={likeChartedData} data={data} range={range} />
        </div>
      )}
      {currentChart === 'comments' && (
        <div>
          <CommentChart
            chartedData={commentChartedData}
            data={data}
            range={range}
          />
        </div>
      )}
    </Box>
  )
}
