import createUrl from 'core/createUrl'

type getCommentReportsQueryVariables = {
  page?: number,
  reason?: string,
  id: number,
}

export default function getCommentReports(
  variables: getCommentReportsQueryVariables = {}
) {
  const { id, page, reason } = variables
  return {
    name: 'commentReports',
    path: `/admin/reports/comment/${id}`,
    params: {
      filters: {
        reason: reason || undefined,
      },
      page: page > 1 ? page : undefined,
    },
  }
}
