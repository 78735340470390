import classnames from 'classnames'
import classes from './index.module.css'

type Props = {
  className?: string,
  content?: any,
  icon?: any,
  title?: any,
  footer?: any,
  type: 'error' | 'info' | 'success' | 'warning',
}

export default function Alert(props: Props) {
  const { className, content, icon, title, type, footer } = props
  const classNames = classnames(classes.wrapper, classes[type], className)

  return (
    <div className={classNames}>
      <div>
        {icon ? <div className={classes.icon}>{icon}</div> : null}
        <div>
          {title ? <h2 className={classes.title}>{title}</h2> : null}
          {content ? <div className={classes.content}>{content}</div> : null}
        </div>
      </div>
      {footer && (
        <div className={classes.simpleFooter}>
          <footer>{footer}</footer>
        </div>
      )}
    </div>
  )
}
