import classnames from 'classnames'
import classes from './index.module.css'

export default function index() {
  return (
    <div className={classes.wrapper}>
      <div className={classes.intro}>
        <h1>Terms of Service - Today’s Crypto</h1>
        <p className="italic">Last updated: April 1st, 2023 </p>
      </div>
      <h2>Introduction</h2>
      <p>
        Welcome to Today’s Crypto. These are the Terms governing the use of the
        Service and the agreement that operates between You and Today’s Crypto.
        These Terms set out the rights and obligations of all users regarding
        the use of the Service.
      </p>
      <p>
        Your access and use of the Service are subject to these Terms of Service
        and Our Privacy Policy, collectively referred to as the “Terms”. These
        Terms apply to all visitors, users, and others who access or use the
        Service. Please read These Terms carefully before accessing and using
        the Service. By accessing or using the Service You agree to be bound by
        these Terms. If You disagree with any part of the Terms, or if you do
        not understand them, then You may not access the Service.
      </p>

      <p>
        Our Privacy Policy describes Our policies and procedures on the
        collection, use, and disclosure of Your personal information when You
        use the Application or the Website and tells You about Your privacy
        rights and how the law protects You.
      </p>
      <p>
        By using the Service on behalf of a company or organization, You
        represent and warrant that You have the authority to act on behalf of
        that entity, and bind the entity to these Terms.
      </p>
      <p>
        <h3>Age Requirement</h3>
        You may use the service if you are over the age of 13. However, We do
        not permit those under the age of 18 to use the Service unless They have
        permission from either their parents or legal guardians to use the
        Service. Please have them read this agreement with You. If you are a
        parent or legal guardian of a user under the age of 18, by allowing your
        child to use the Service, you are subject to the terms of this agreement
        and responsible for your child’s activity on the Service.
      </p>
      <h2>Interpretation and Definitions</h2>
      <p>
        <h3>Interpretation</h3>
        The words in which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <p>
        <h3>Definitions</h3>
        For the purposes of this Terms of Service document:
      </p>
      <ul>
        <li>
          <b>Account</b> means a unique account created for You to access our
          Service or parts of our Service.
        </li>
        <br />
        <li>
          <b>Affiliate</b> means an entity that controls, is controlled by, or
          is under common control with a party, where “control” means ownership
          of 50% or more of the shares, equity interest, or other securities
          entitled to vote for the election of directors or other managing
          authority.
        </li>
        <br />
        <li>
          <b>Country</b> refers to Sweden
        </li>
        <br />
        <li>
          <b>Company</b> (referred to as either “Today’s Crypto”, “We”, “Us” or
          “Our” in this Agreement) refers to BlockBeast AB, Transportvägen 12,
          246 42, Löddeköpinge, Sweden.
        </li>
        <br />
        <li>
          <b>Device</b> means any device that can access the Service such as a
          computer, a cellphone, or a digital tablet.
        </li>
        <br />
        <li>
          <b>Service</b> refers to the Website.
        </li>
        <br />
        <li>
          <b>Service Provide</b> means any natural or legal person who processes
          the data on behalf of the Company. It refers to third-party companies
          or individuals employed by the Company to facilitate the Service, to
          provide the Service on behalf of the Company, to perform services
          related to the Service or to assist the Company in analyzing how the
          Service is used.
        </li>
        <br />
        <li>
          <b>Third-party</b> means any services or content (including data,
          information, products, or services) provided by a third party that may
          be displayed, included, or made available by the Service.
        </li>
        <br />
        <li>
          <b>Website</b> refers to Today’s Crypto, accessible from{' '}
          <a target="_blank" href="https://todayscrypto.com">
            <span className="text-blue-400 underline">
              www.todayscrypto.com
            </span>
          </a>
        </li>
        <br />
        <li>
          <b>You</b> mean the individual accessing or using the Service, or the
          company, or other legal entity on behalf of which such individual is
          accessing or using the Service, as applicable.
        </li>
      </ul>
      <h2>About the Service</h2>
      <p>
        Today´s Crypto is created for the Crypto Currency enthusiast. Our
        mission is to create a safe, scam-free, easy-to-use, streaming service
        for everything Crypto.
      </p>
      <p>
        <h3>What is Today’s Crypto?</h3>
        Today´s Crypto is an entertainment and education platform to discover,
        watch, and engage in conversation on Crypto-related videos, podcasts,
        etc. among a global community. The Service functions as a distribution
        platform for original content by independent content creators,
        businesses, and advertisers of various sizes. We believe that trust is
        key, so to ensure that Today’s Crypto is a safe space for our users to
        consume content, all channels must go through a manual application
        process before setting up their channel and start publishing content.
      </p>
      <h2>Disclaimer</h2>
      <p>
        Before using the service, please be aware that none of the content
        published on Today’s Crypto should be considered financial or investment
        advice. Always do Your own research or seek independent financial advice
        from a licensed professional before making any investment or other
        financial decision.
      </p>
      <h2>Your use of the Service</h2>
      <p>
        <h3>Access to the service</h3>
        You may use parts of the Service such as browsing the platform and
        watching content without any type of account. To gain access to more of
        the Services features (for example liking, commenting, saving content,
        etc.) You will have to register for an Account.
      </p>
      <p>
        <h3>Content on the Service</h3>
        The content on the Service includes videos, podcasts, audio (for example
        music, etc.), graphics, photos, text (such as comments), branding
        (including names, trademarks, service marks, or logos), interactive
        features, software, metrics, and other materials (collectively,
        "Content”). Content may be provided to the Service and distributed by
        our users and Today’s Crypto is a provider of hosting services for such
        Content. The Content published is the responsibility of the person or
        entity that provides it to the Service. If you see any Content, that You
        do not believe complies with these terms or the law, please use the
        report feature or contact Us directly.
      </p>
      <p>
        <h3>Prohibited Conduct</h3>
        The following restrictions apply to your use of the Service. You are not
        allowed to:
      </p>
      <ol className={classes.decimal}>
        <li>
          access, reproduce, download, distribute, transmit, broadcast, display,
          sell, license, alter, modify, or otherwise use any part of the Service
          or any Content except:
          <ol className={classes.lowerLatin}>
            <li>as specifically permitted by the Service;</li>
            <li>
              with prior written permission from Today’s Crypto and, if
              applicable, the respective rights holders or;
            </li>
            <li>as permitted by applicable law;</li>
          </ol>
        </li>
        <li>
          circumvent, disable, fraudulently engage, or otherwise interfere with
          the Service (or attempt to do any of these things), including
          security-related features or features that:
          <ol className={classes.lowerLatin}>
            <li>prevent or restrict the copying or other use of Content or;</li>
            <li>limit the use of the Service or Content;</li>
          </ol>
        </li>
        <li>
          access the Service using any automated means (such as robots, botnets,
          or scrapers);
        </li>
        <li>
          collect or use any information that might identify a person (for
          example, harvesting usernames), unless permitted by that person;
        </li>
        <li>
          use the Service to distribute unsolicited promotional or commercial
          content or other unwanted or mass solicitations (spam);
        </li>
        <li>
          cause or encourage any inaccurate measurements of genuine user
          engagement with the Service, including by paying people or providing
          them with incentives to increase a video’s views, likes, or dislikes,
          or to increase a channel’s subscribers, or otherwise manipulate
          metrics;
        </li>
        <li>
          misuse any reporting, flagging, complaint, dispute, or appeals
          process, including by making groundless, vexatious, or frivolous
          submissions;
        </li>
        <li>
          run contests on or through the Service that do not comply with our
          Terms;
        </li>
        <li>
          use the Service to view or listen to Content other than for personal,
          non-commercial use (for example, you may not publicly screen videos or
          podcasts from the Service) or;
        </li>
        <li>
          use the Service to:
          <ol className={classes.lowerLatin}>
            <li>
              sell any advertising, sponsorships, or promotions placed on,
              around, or within the Service or Content, other than those allowed
              in the policies (such as compliant product placements) or;
            </li>
            <li>
              sell advertising, sponsorships, or promotions on any page of any
              website or application that only contains Content from the Service
              or where Content from the Service is the primary basis for such
              sales (for example, selling ads on a webpage where media from
              Today’s Cryptos are the only content of value).
            </li>
          </ol>
        </li>
      </ol>
      <p>
        <h3>Reservation</h3>
        Any right not expressly granted to You in this Agreement remains the
        right of Today’s Crypto or the respective rights holders. This means,
        for example, that using the Service does not give You ownership of any
        intellectual property rights in the Content you access (including any
        branding used on or displayed in the Service).
      </p>
      <h2>User Account</h2>
      <p>
        You may sign up for a User account, by doing so You will gain access to
        additional features such as liking and commenting on content,
        subscribing to channels, saving content on Your Account, adding a
        profile picture, configuring your content feed, etc.
      </p>
      <p>
        We will assign You a temporary username when setting up your account,
        whereupon you will be able to choose Your own public username. Please
        note that it is mandatory to set Your public username before posting
        comments or replies on the service. You cannot change your username once
        it has been chosen.
      </p>
      <p>
        We reserve the full right to disqualify any chosen username that is
        found to be offensive, hateful, sexual, etc., or in other ways
        misleading or with fraudulent intent. In the event of disqualification,
        You may be given the chance to choose a new name.
      </p>
      <p>
        Your User account is personal and may not be shared with anyone
        else.Some features may be unavailable or limited and may require an
        active Membership plan to access fully.
      </p>
      <p>
        <h3>Your Conduct on the Service</h3>
        Today´s Crypto does not permit behavior that is motivated by hatred,
        prejudice, or intolerance, including behavior that promotes or
        encourages discrimination, denigration, harassment, or violence based on
        the following protected characteristics: race, ethnicity, color, caste,
        national origin, immigration status, religion, sex, gender, gender
        identity, sexual orientation, disability, serious medical condition, and
        veteran status.
      </p>
      <p>
        For the safety of our community, we also require users to respect all
        applicable local, national, and international laws while using our
        services. Any content or activity featuring, encouraging, offering, or
        soliciting illegal activity is prohibited and may be reported to law
        enforcement.
      </p>
      <p>
        <h3>Loyalty program</h3>
        By signing up and using Your User account, You are participating in our
        Loyalty program that rewards You with Loyalty points when streaming
        content, making appreciated contributions in the comment sections, or
        inviting Your friends through Your personal invitation link. The Loyalty
        point usage is at this stage not fully determined and will be presented
        at a later stage.
      </p>
      <p>
        <h3>Account Security</h3>
        Protect Your account with a strong password. We also recommend using the
        Two-Factor Authentication options available on Your account for
        increased security.
      </p>
      <p>
        <h3>Account Termination by You</h3>
        You may stop using the service and delete Your account at any time.
      </p>
      <p>
        <h3>Account Suspension and Termination by Us</h3>
        Today’s Crypto may suspend or terminate Your access and/or Your
        account’s access to all or part of the Service if:
      </p>
      <ol className={classnames(classes.lowerLatin, 'ml-20')}>
        <li>You materially or repeatedly violate any part of these terms;</li>
        <li>
          We are required to do so to comply with a legal requirement or a court
          order or;
        </li>
        <li>
          We reasonably believe there has been conduct that creates liability or
          harm to any user, other third party, Today’s Crypto or our Affiliates.
        </li>
      </ol>
      <h2>Hodl Membership</h2>
      <p>
        As a registered user on the service, You will have the option to
        purchase a Hodl Membership plan for Your Account. By purchasing a Hodl
        Membership plan you get an ad-free experience along with other benefits,
        the current benefits included in the Membership are listed on the
        Website.
      </p>
      <p>
        <h3>Billing</h3>
        You can purchase a Hodl Membership by:
      </p>
      <ol className={classnames(classes.lowerLatin, 'ml-20')}>
        <li>
          paying a Membership fee in advance on a monthly or yearly basis or any
          other recurring interval available to You prior to purchase; or
        </li>
        <li>
          pay in advance to access the Membership for a fixed period of time ("
          Pre-paid Period ").
        </li>
      </ol>
      <p>
        The tax you have to pay is calculated based on the information You give
        us and the applicable tax rate when the Membership fee is paid.
      </p>
      <p>
        <h3>Price and tax changes</h3>
        Today´s Crypto may from time to time change the price of the Hodl
        Membership, including recurring Membership fees, and the prepaid period
        (for periods not yet paid), and will inform You of any price changes
        with reasonable notice. The price changes take effect at the beginning
        of the next subscription period after the price change date. Subject to
        applicable law, you will be deemed to have agreed to the price change if
        You continue to use the Membership after the price change has taken
        effect. If you do not agree to a price change, You may reject it by
        canceling the applicable paid subscription before the price change takes
        effect.
      </p>
      <p>
        The tax You have to pay is based on the tax rates that apply when You
        pay Your monthly fee. These amounts may change over time depending on
        local tax requirements in your country, state, territory, or even Your
        locality. Any changes to the tax rate are automatically applied based on
        the account details You provide.
      </p>
      <p>
        <h3>Membership Renewal and Termination</h3>
        Except for Memberships with a pre-paid period (Coinbase Commerce
        payments), Your payment to Today´s Crypto will automatically renew at
        the end of the applicable Membership period unless You cancel Your Hodl
        Membership before the end of the current Membership period. The
        termination will take effect the day after the last day of the current
        Membership period. We do not provide refunds or credits for any
        incomplete subscription periods other than as expressly provided in
        these Terms.
      </p>
      <p>
        <h3>Changes to the benefits</h3>
        Today´s Crypto may from time to time add or remove benefits included in
        the Membership or offer time limited campaigns, If a revision of the
        benefits is material We will make reasonable efforts to provide notice
        as soon as possible.
      </p>
      <p>
        <h3>Right of withdrawal</h3>
        By purchasing a Membership, You expressly agree that we provide You with
        the service immediately after Your purchase, that you lose Your right of
        withdrawal, and that You authorize Today´s Crypto to automatically bill
        You in accordance with Your selected Membership plan until You cancel
        the Membership (recurring Membership fee).
      </p>
      <h2>Payment Terms</h2>
      <p>
        We accept payment through major credit cards, certain debit cards,
        Coinbase commerce, and/or such other payment methods we may make
        available to You from time to time on our site. You are subject to all
        terms and conditions of the Payment Method you choose. By making payment
        through the Services on Today´s Crypto, You represent and warrant that
        you are authorized to use the designated Payment Method and authorize
        Today´s Crypto, or its designated payment processor, to charge the
        Payment Method you specify for the purchase amount, including all taxes
        and fees. All payments are to be made in United States Dollars or by a
        selection of cryptocurrencies.
      </p>
      <p>
        Please note that Our payment processing partners may request that You
        provide certain personal data such as a valid government-issued ID, Your
        legal name, address, and date of birth) for the purpose of making
        payments through its financial institutions and complying with
        applicable international, national, federal, state, and local laws and
        regulations. They may also communicate directly with You regarding any
        issues with a payment.
      </p>
      <p>
        If a purchase has been declined online due to issues with Your Payment
        Method, we may suspend or cancel Your payment automatically. You are
        responsible for resolving any problem we encounter in order to proceed
        with Your purchase.
      </p>
      <p>
        Our payment processing partners may impose additional transaction fees
        on selected payment methods. Such transaction fee will be disclosed to
        You prior to accepting the transaction.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        Notwithstanding any damages that You might incur, the entire liability
        of the Company and any of its suppliers under any provision of this
        Terms and Your exclusive remedy for all of the foregoing shall be
        limited to the amount actually paid by You through the Service or 100
        USD if You haven't purchased anything through the Service.
      </p>
      <p>
        To the maximum extent permitted by applicable law, in no event shall the
        Company or its suppliers be liable for any special, incidental,
        indirect, or consequential damages whatsoever (including, but not
        limited to, damages for loss of profits, loss of data or other
        information, for business interruption, for personal injury, loss of
        privacy arising out of or in any way related to the use of or inability
        to use the Service, third-party software and/or third-party hardware
        used with the Service, or otherwise in connection with any provision of
        this Terms), even if the Company or any supplier has been advised of the
        possibility of such damages and even if the remedy fails of its
        essential purpose.
      </p>
      <p>
        Some states do not allow the exclusion of implied warranties or
        limitation of liability for incidental or consequential damages, which
        means that some of the above limitations may not apply. In these states,
        each party's liability will be limited to the greatest extent permitted
        by law.
      </p>
      <h2>"AS IS" and "AS AVAILABLE" Disclaimer</h2>
      <p>
        The Service is provided to You "AS IS" and "AS AVAILABLE" and with all
        faults and defects without warranty of any kind. To the maximum extent
        permitted under applicable law, the Company, on its own behalf and on
        behalf of its Affiliates and its and their respective licensors and
        service providers, expressly disclaims all warranties, whether express,
        implied, statutory or otherwise, with respect to the Service, including
        all implied warranties of merchantability, fitness for a particular
        purpose, title and non-infringement, and warranties that may arise out
        of course of dealing, course of performance, usage or trade practice.
        Without limitation to the foregoing, the Company provides no warranty or
        undertaking, and makes no representation of any kind that the Service
        will meet Your requirements, achieve any intended results, be compatible
        or work with any other software, applications, systems or services,
        operate without interruption, meet any performance or reliability
        standards or be error free or that any errors or defects can or will be
        corrected.
      </p>
      <p>
        Without limiting the foregoing, neither the Company nor any of the
        company's provider makes any representation or warranty of any kind,
        express or implied: (i) as to the operation or availability of the
        Service, or the information, content, and materials or products included
        thereon; (ii) that the Service will be uninterrupted or error-free;
        (iii) as to the accuracy, reliability, or currency of any information or
        content provided through the Service; or (iv) that the Service, its
        servers, the content, or e-mails sent from or on behalf of the Company
        are free of viruses, scripts, trojan horses, worms, malware, timebombs
        or other harmful components.
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of certain types of
        warranties or limitations on applicable statutory rights of a consumer,
        so some or all of the above exclusions and limitations may not apply to
        You. But in such a case the exclusions and limitations set forth in this
        section shall be applied to the greatest extent enforceable under
        applicable law.
      </p>
      <h2>Governing Law</h2>
      <p>
        The laws of the Country, excluding its conflicts of law rules, shall
        govern these Terms and Your use of the Service. Your use of the
        Application may also be subject to other local, state, national, or
        international laws.
      </p>
      <h2>Disputes Resolution</h2>
      <p>
        If You have any concerns or disputes about the Service, You agree to
        first try to resolve the dispute informally by contacting the Company.
      </p>
      <p>
        <h3>Trial in Court</h3>
        Disputes due to this agreement or legal relationship arising from this
        agreement that we are unable to resolve informally shall, to the
        exclusion of other applicable forums, be decided by a court in Sweden
        with Malmö district court as the first instance. Today’s Crypto also has
        the right to file a lawsuit in another country if the Account Holder is
        domiciled there or has assets in that country.
      </p>
      <h2>For European Union (EU) Users</h2>
      <p>
        If You are a European Union consumer, You will benefit from any
        mandatory provisions of the law of the country in which you are a
        resident.
      </p>
      <h2>United States Legal Compliance</h2>
      <p>
        You represent and warrant that (i) You are not located in a country that
        is subject to the United States government embargo, or that has been
        designated by the United States government as a "terrorist supporting"
        country, and (ii) You are not listed on any United States government
        list of prohibited or restricted parties.
      </p>
      <h2>Severability and Waiver</h2>
      <p>
        <h3>Severability</h3>
        If any provision of these Terms is held to be unenforceable or invalid,
        such provision will be changed and interpreted to accomplish the
        objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions will continue in full force
        and effect.
      </p>

      <p>
        <h3>Waiver</h3>
        Except as provided herein, the failure to exercise a right or to require
        the performance of an obligation under these Terms shall not affect a
        party's ability to exercise such right or require such performance at
        any time thereafter nor shall the waiver of a breach constitute a waiver
        of any subsequent breach.
      </p>

      <h2>Translation Interpretation</h2>
      <p>
        These Terms and Conditions may have been translated if We have made them
        available to You on our Service. You agree that the original English
        text shall prevail in the case of a dispute.
      </p>
      <h2>Changes to The Terms of Service</h2>
      <p>
        We reserve the right, at Our sole discretion, to modify or replace these
        Terms at any time. If a revision is material We will make reasonable
        efforts to provide at least 30 days notice prior to any new terms taking
        effect. What constitutes a material change will be determined at Our
        sole discretion.
      </p>
      <p>
        By continuing to access or use Our Service after those revisions become
        effective, You agree to be bound by the revised terms. If You do not
        agree to the new terms, in whole or in part, please stop using the
        website and the Service.
      </p>
      <h2>Changes to the service</h2>
      <p>
        Today’s Crypto is constantly developing and improving the Service. We
        may also need to alter or discontinue the Service, or any part of it, in
        order to make performance or security improvements, change functionality
        and features, make changes to comply with applicable law, or prevent
        illegal activities on, or abuse of our systems. We will always consider
        and balance the impact of such changes on the use of the Service.
        Whenever reasonably possible, we will provide notice when we discontinue
        or make material changes to our Service that will have an adverse impact
        on the use of our Service. However, there will be times when we need to
        make such changes without notice, for example when we need to take
        urgent actions to improve the security and operability of our Service,
        prevent abuse, or meet our legal requirements.
      </p>
      <h2>Links to Other Websites</h2>
      <p>
        Our Service may contain links to third-party websites or services that
        are not owned or controlled by the Company. The Company has no control
        over and assumes no responsibility for, the content, privacy policies,
        or practices of any third-party websites or services. You further
        acknowledge and agree that the Company shall not be responsible or
        liable, directly or indirectly, for any damage or loss caused or alleged
        to be caused by or in connection with the use of or reliance on any such
        content, goods, or services available on or through any such web sites
        or services.
      </p>
      <p>
        We strongly advise You to read the terms and conditions and privacy
        policies of any third-party websites or services that You visit.
      </p>
      <h2>Downloadable software and Open Source</h2>
      <p>
        <h3>Downloadable Software</h3>
        When the Service requires or includes downloadable software, You give
        permission for that software to update automatically on Your device once
        a new version or feature is available, subject to Your device's
        settings. Unless that software is governed by additional terms which
        provide a license, Today’s Crypto gives You a personal, worldwide,
        royalty-free, non-assignable, and non-exclusive license to use the
        software provided to You by Today’s Crypto as part of the Service. This
        license is for the sole purpose of enabling You to use and enjoy the
        benefit of the Service as provided by Today’s Crypto, in the manner
        permitted by this Agreement. You are not allowed to copy, modify,
        distribute, sell, or lease any part of the software, or to
        reverse-engineer or attempt to extract the source code of that software,
        unless laws prohibit these restrictions or you have Today’s Crypto’s
        written permission.
      </p>
      <p>
        <h3>Open Source</h3>
        Some software used in our Service may be offered under an open-source
        license. There may be provisions in an open-source license that
        expressly override some of these terms. If so, we will make that
        open-source license available to You.
      </p>
      <h2>Copyright Protection</h2>
      <p>
        If you believe your copyright has been infringed on the Service, please
        contact us. We review any claims manually and might ask you for
        additional information regarding the Content before any removal can take
        place.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about these Terms, You can contact us: <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; By using
        our contact form on our website:{' '}
        <a target="_blank" href="https://todayscrypto.com">
          <span className="text-blue-400 underline">www.todayscrypto.com</span>
        </a>
      </p>
    </div>
  )
}
