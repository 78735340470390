type Variables = {
  month: string,
  totalDistributedMoney: number,
}

export default function setTotalDistributedMoney(variables: Variables) {
  const { month, totalDistributedMoney } = variables
  return {
    method: 'PUT',
    path: '/admin/earnings/total-distributed-money',
    params: {
      month,
      total_distributed_money: Number(totalDistributedMoney),
    },
  }
}
