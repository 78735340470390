type GetChannelsStatistics = {
  range: string,
  from: string,
  to: string,
}

export default function getChannelsStatistics(
  variables: GetChannelsStatistics = {}
) {
  const { range, from, to } = variables

  const filters = {}
  if (from) {
    filters.from = from
  }

  if (to) {
    filters.to = to
  }

  return {
    name: 'channelsStatistics',
    path: `/admin/channels/statistics/${range}`,
    params: {
      filters: {
        from,
        to,
      },
    },
  }
}
