import Modal from 'shared/components/kit/modal'
import Button from 'shared/components/kit/button'
import classes from './WhitePaperModal.module.css'

export default function WhitePaperModal({ visible, onClose }) {
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={
        <Button onClick={onClose} transparent mood="neutral">
          Close
        </Button>
      }>
      <div className={classes.wrapper}>
        <h1>TCG - Token Whitepaper</h1>
        <h2>
          Today's Crypto - A "first of a kind" streaming service for Web3,
          Crypto, and Blockchain content.
        </h2>

        <ol className={classes.decimal}>
          <li>Introduction</li>
          <p>
            Entering the era of Web3, Today's Crypto will become the first media
            outlet governed by its users through the TCG (Today's Crypto
            Governance Token).
          </p>
          <p>
            We are starting with our purpose-built streaming platform as we
            enter this new and exciting world of Web3. Today's Crypto
            fundamentally changes how we consume Web3, Crypto, and Blockchain
            content. This space deserves a better medium than currently offered
            with the all-and-everything streaming platforms we use today.
          </p>

          <li>TCG Governance Token</li>
          <p>
            The TCG Token is the main tool for voting on proposals and securing
            the content quality on the platform. The governance module will be
            integrated gradually to ensure the community is strong enough to
            govern the platform without risks.
          </p>
          <img
            className={classes.polygonImage}
            src="/polygon-(matic).png"
            alt="polygon (matic)"
          />
          <p>
            We have chosen Polygon for the TCG Governance Token. Our contracts
            will be deployed in early Q2 2023.
          </p>
          <p>
            The TCG Governance Token grants one vote for future proposals on
            Today's Crypto. We aim for token holders to govern everything from
            securing content to revenue distribution on the platform.
          </p>
          <p>
            The governance system is under development. More details will be
            shared along the road. However, we estimate that the governance
            system will launch in late 2024.
          </p>
          <li>
            Tokenomics
            <p>
              Utility is key, and with great token utilities comes actual
              demand.
            </p>
            <ol className={classes.decimal}>
              <li>Revenue Sharing</li>
              <p>
                As a governance token holder, you can vote for changes and
                quality assurance and earn rewards. 10% of the platform revenue
                will be allocated to a governance pool. Everyone who votes will
                be rewarded in proportion to their voting power. Higher voting
                power results in higher earnings. Voting rewards will be paid
                out in USDT or USDC.
              </p>
              <div className={classes.imageContainer}>
                <img
                  src="/reward-flow.png"
                  alt="Reward Flow"
                  className="w-11/12 h-auto"
                />
              </div>
              <li>Monetization Tiers</li>
              <p>
                Monetization tiers allow content creators on Today's Crypto to
                earn more when monetizing their content. Holding the TCG tokens
                in the connected wallet adds a multiplier to their earnings
                depending on the number of tokens held. The tiers range from
                Tier 5 (1m TCG held) to Tier 1 (3m TCG held).
              </p>
              <li>
                Content Booster{' '}
                <img src="/boozt.png" alt="BOOZT" className="inline w-20" />
              </li>
              <p>
                "BOOZT" allows creators and businesses to steer traffic to
                specific videos by adding extra TCG watch earnings to those who
                watch their videos! This feature acts as a way of marketing but
                can also be used by new channels wanting to grow faster.
              </p>
            </ol>
          </li>
          <li>
            Distribution
            <p>
              $TCG Token Distribution <br />
              Total Supply: 5,000,000,000 TCG
            </p>
            <div className={classes.imageContainer}>
              <img src="/distribution.png" alt="Member distribution" />
            </div>
            <p>
              Core Team Holdings <br />
              12 months lockup period
            </p>
            <ol className={classes.decimal}>
              <li>Distribution (detailed)</li>
            </ol>
            <p>
              <b>60% (3,000,000,000)</b> - will be given to users and publishers
              for using the service as a reward.
            </p>
            <h3>Rewards for platform users:</h3>
            <ul className={classes.circle}>
              <li>Watching videos: 1 token per minute</li>
              <li>Setting un custom Feed: 100 tokens</li>
              <li>Buying yearly membership: 5000 tokens</li>
            </ul>
            <h3>Rewards for Hodl Members:</h3>
            <ul className={classes.circle}>
              <li>Watching a video: 2 token per minute</li>
              <li>Setting up custom feed: 200 tokens</li>
              <li>Renew membership: 10.000 tokens</li>
            </ul>
            <h3>Rewards for content creators:</h3>
            <ul className={classes.circle}>
              <li>Posting a video: 500 tokens/video</li>
              <li>Invite users: 1000 tokens/registered user</li>
              <br />
              <li>
                5% (250,000,000) - Will be used for our equity program. These
                tokens will be distributed along with the equity (company
                shares) to channels participating in the equity program.
              </li>
              <br />
              <li>
                5% (250,000,000) - Will be Airdropped to the community/token
                holders through various airdrops. Twitter will be our main
                platform for communication - @TodaysCryptoCom
              </li>
              <br />
              <li>
                15% (750,000,000 - Held as a development fund. This fund will
                also cover future partnerships and act as a platform reserve.
              </li>
              <br />
              <li>
                15% (750,000,000) - Held by the founding team and developer.
                Tokens held by the team will have a lockup period of 12 months.
              </li>
            </ul>
          </li>
          <li>
            Platform Sustainability
            <p>
              For the platform to succeed, it must have a sustainable
              architecture. We have used prover models and prepared them for
              Web3.
            </p>
            <p>
              Today's Crypto's primary revenue source will come from CPM
              Advertisement, CPC platform partners, and Membership fees.
            </p>
            <p>The combined revenue will then be distributed:</p>
            <ul className={classes.circle}>
              <li>30% Publishers - Rewarded for posting on the platform.</li>
              <li>
                10% Governance Pool - This act as a governance incentive for all
                $TCG voters.
              </li>
              <li>
                60% Business - Continued R&D along with operational expenditure.
              </li>
            </ul>
            <ol className={classes.decimal}>
              <li>Revenue flow & Distribution</li>
            </ol>
          </li>
        </ol>
        <div className={classes.imageContainer}>
          <img src="/revenue-flow-dark-back.png" alt="revenue flow dark back" />
        </div>
        <h3>Disclaimer</h3>
        <p>
          This paper is for general information purposes only. It does not
          constitute investment advice, a recommendation, or a solicitation to
          buy or sell an investment and should not be used to evaluate the
          merits of making an investment decision. It should not be relied upon
          for accounting, legal, or tax advice or investment recommendations.
          This paper reflects the current opinions of the authors and is not
          made on behalf of Today's Crypto or their affiliates and does not
          necessarily reflect the opinions of Today's Crypto, their affiliates,
          or individuals associated with them. The opinions reflected herein are
          subject to change without being updated.
        </p>
      </div>
    </Modal>
  )
}
