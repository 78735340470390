import { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import useFetcher from 'core/useFetcher'
import useMiniForm from 'core/useMiniForm'
import Result from 'shared/components/kit/result'
import Button from 'shared/components/kit/button'
import classes from './Confirm.module.css'

export default function Confirm() {
  const history = useHistory()
  const fetcher = useFetcher()
  const { token } = useParams()
  const [result, setResult] = useState()

  const form = useMiniForm({
    resetOnSuccess: true,
    onSubmit(values, event) {
      try {
        fetcher(`/confirm-eth-address/${token}`, {
          method: 'get',
        })
          .then((res) => {
            if (res.json.status === 'ok') {
              setResult('ok')
            } else {
              setResult('error')
            }
          })
          .catch((e) => {
            setResult('error')
          })
      } catch (error) {
        console.log(error)
      }
    },
  })

  if (result && result === 'cancel') {
    return (
      <Result
        className="mt-48"
        title="Changing ETH Address Canceled!"
        status="info"
        description="Changing ETH address canceled by user!"
        footer={
          <>
            <Button onClick={() => history.push('/publisher')}>Home</Button>
          </>
        }
      />
    )
  }
  if (result && result === 'error') {
    return (
      <Result
        className="mt-48"
        title="Error"
        status="error"
        description="Cannot change ETH address!"
        footer={
          <>
            <Button onClick={() => history.push('/publisher')}>Home</Button>
          </>
        }
      />
    )
  }

  if (result && result === 'ok') {
    return (
      <Result
        className="mt-48"
        title="ETH address changed!"
        status="success"
        description="Your ETH address has been changed successfully."
        footer={
          <>
            <Button onClick={() => history.push('/publisher')}>Home</Button>
          </>
        }
      />
    )
  }

  const handleCancel = (e) => {
    e.preventDefault()
    setResult('cancel')
  }

  return (
    <form className={classes.form} onSubmit={form.submit}>
      <Result
        className="mt-48"
        title="Verify New ETH Address"
        status="info"
        description="We have received an email to change your ETH address.
            Do you want to change it?"
        footer={
          <>
            <Button onClick={handleCancel} secondary>
              Cancel
            </Button>
            <Button>Yes</Button>
          </>
        }
      />
    </form>
  )
}
