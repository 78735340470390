import classes from './ChannelPaymentDetailStatus.module.css'
import classnames from 'classnames'

export default function ChannelPaymentDetailStatus({ paymentDetails }) {
  const className = classnames(
    classes.statusIndicator,
    checkPaymentDetailsStatus(paymentDetails) ? 'bg-green-600' : 'bg-red-600'
  )
  return (
    <span>
      status <span className={className}></span>
    </span>
  )
}

function checkPaymentDetailsStatus(paymentDetails) {
  if (paymentDetails === null) {
    return false
  }

  const requiredKeys = [
    'first_name',
    'last_name',
    'street_address',
    'postal_code',
    'city',
    'country',
    'eth_address',
  ]

  for (const key of requiredKeys) {
    if (typeof paymentDetails[key] === null || paymentDetails[key] === '-') {
      return false
    }
  }

  return true
}
