import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import useQuery from 'core/react-query/useQuery'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import Button from 'shared/components/kit/button'
import Table from 'shared/components/kit/table'
import formatDate from 'helpers/utils/formatDate'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import truncate from 'helpers/utils/truncate'
import Page from 'admin/components/page'
import getCampaigns from 'admin/queries/getCampaigns'
import CampaignStatus from 'admin/components/campaign-status'
import Filters from './Filter'
import classes from './CampaignsList.module.css'

export default function CampaignsList() {
  const FILTER_CONFIG = useMemo(() => ({
    base: '/admin/ads-manager/campaigns',
    params: {
      page: 'pagination',
      search: 'any',
      status: 'active | draft | archive',
      sort: 'any',
    },
  }))
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)
  const actions = (
    <Link to="/admin/ads-manager/campaigns/create">
      <Button>Create new campaign</Button>
    </Link>
  )

  const { data, isFetching, error, isError } = useQuery(getCampaigns, {
    keepPreviousData: true,
    variables: {
      page: filters.page,
      search: filters.search,
      status: filters.status,
      sort: filters.sort,
    },
  })

  const {
    data: campaigns,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleFilter = (nextFilters) => {
    setFilters({
      ...nextFilters,
      page: undefined,
    })
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  if (isError) {
    return <Page error={error.message} />
  }

  return (
    <Page title="Campaigns" description={description} actions={actions}>
      <Filters filters={filters} onSubmit={handleFilter} />
      <Table
        columns={columns}
        data={campaigns}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
    </Page>
  )
}

function getColumns() {
  return [
    {
      title: 'Campaign ID',
      className: 'w-36',
      render: (campaign) => campaign.id,
    },
    {
      title: 'Exchange',
      className: 'w-64',
      render: (campaign) => truncate(campaign.exchange_name, 40),
    },
    {
      title: 'Campaign name',
      className: 'w-64',
      render: (campaign) => truncate(campaign.name, 40),
    },
    {
      title: 'CT',
      render: (campaign) => campaign.total_clicks,
    },
    {
      align: 'center',
      title: 'Number of coins',
      className: 'w-30',
      render: (campaign) => campaign.crypto_currencies_count,
    },
    {
      align: 'center',
      title: 'Status',
      render: (campaign) => <CampaignStatus status={campaign.status} />,
    },
    {
      align: 'center',
      title: 'Creation Date',
      className: 'w-48',
      render: (campaign) => formatDate(campaign.created_at),
    },
    {
      className: classes.actions,
      render: (campaign) => (
        <Button
          component="link"
          size="sm"
          mood="secondary"
          to={`/admin/ads-manager/campaigns/${campaign.id}/edit`}>
          Edit
        </Button>
      ),
    },
  ]
}
