type Variables = {
  playlists: Array<number>,
  videos: Array<number>,
}

export default function bulkAddVideosToPlaylists(variables: Variables) {
  const { playlists, videos } = variables

  return {
    method: 'put',
    path: '/playlist/add',
    params: {
      playlists,
      videos,
    },
  }
}
