type RemoveVideoFromPlaylistMutationVariables = {
  playlist: number,
  video: number,
}

export default function removeVideoFromPlaylist(
  variables: RemoveVideoFromPlaylistMutationVariables
) {
  const { playlist, video } = variables

  return {
    method: 'put',
    path: `/playlists/${playlist}/remove/${video}`,
  }
}
