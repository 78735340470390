type EditAdsCampaign = {
  id: number,
  name: string,
  companyId: number,
  status: string,
  slots: Array<Object>,
  tiersData: Object,
}

export default function editAdsCampaign(variables: EditAdsCampaign = {}) {
  const { id, name, companyId, status, slots, tiersData } = variables
  return {
    method: 'PUT',
    path: `/admin/ads/campaigns/${id}`,
    params: {
      name,
      company_id: companyId,
      status,
      slots,
      tiers_data: tiersData,
    },
  }
}
