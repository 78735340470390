type SetBudgetVariables = {
  budget: number,
  month: string,
}

export default function setBudget(variables: SetBudgetVariables) {
  const { budget, month } = variables
  return {
    method: 'PUT',
    url: `/admin/monetization/budget`,
    variables: {
      budget,
      month,
    },
  }
}
