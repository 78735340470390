import React, { useState } from 'react'
import { ReactComponent as InfoSVG } from 'svg/info_outlined.svg'
import Spin from 'shared/components/kit/spin'
import formatNumber from 'helpers/utils/formatNumber'
import Popover from 'shared/components/kit/popover'
import useTcgTokenData from 'shared/hooks/useTcgTokenData'
import classes from './Summary.module.css'

export default function Summary() {
  const {
    claimableTokens,
    totalTokensDistributed,
    userTotalEarningsTokens,
    userHoldingTokens,
    tokenPointsOverviewIsLoading,
    claimableIsLoading,
    walletIsLoading,
    myHoldings,
  } = useTcgTokenData()

  const [visible, setVisible] = useState('')
  const spinner = (
    <div className={classes.spinner}>
      <Spin />
    </div>
  )

  return (
    <div className={classes.summary}>
      {/*
        <div className={classes.summaryItem}>
        <small>TCG Distributed</small>
        <h2>
          {tokenPointsOverviewIsLoading
            ? spinner
            : formatNumber(totalTokensDistributed)}
        </h2>
      </div>
      */}
      <div className={classes.summaryItem}>
        <small className="ml-4">
          My TCG Tokens
          <Popover
            onHide={() => setVisible('')}
            className={classes.popover}
            onClickOutsideContent={() => setVisible('')}
            placement="bottom"
            visible={visible === 'totalTokens'}
            content={
              <p className={classes.content}>
                Here, you see all the TCG tokens you have in your provided
                wallet.
              </p>
            }>
            <span className="relative">
              <InfoSVG onClick={() => setVisible('totalTokens')} />
            </span>
          </Popover>{' '}
        </small>
        <h2>
          {tokenPointsOverviewIsLoading || walletIsLoading || claimableIsLoading
            ? spinner
            : formatNumber(myHoldings)}
        </h2>
      </div>
      <div className={classes.summaryItem}>
        <small className="ml-4">
          Claimable TCG Tokens{' '}
          <Popover
            onHide={() => setVisible('')}
            className={classes.popover}
            onClickOutsideContent={() => setVisible('')}
            placement="bottom"
            visible={visible === 'claimable'}
            content={
              <p className={classes.content}>
                Once you have earned your first 500 tokens you will be available
                to start claiming, any new tokens earned after that will be
                claimable within 24 hours. Please note that you must link your
                wallet before your earnings can be made claimable.
              </p>
            }>
            <span className="relative">
              <InfoSVG onClick={() => setVisible('claimable')} />
            </span>
          </Popover>
        </small>
        <h2>{claimableIsLoading ? spinner : formatNumber(claimableTokens)}</h2>
      </div>
      <div className={classes.summaryItem}>
        <small className="ml-4">
          Locked TCG Tokens
          <Popover
            onHide={() => setVisible('')}
            className={classes.popover}
            onClickOutsideContent={() => setVisible('')}
            placement="bottom"
            visible={visible === 'locked'}
            content={
              <p className={classes.content}>
                These are tokens that are earned and locked until the next
                release.
              </p>
            }>
            <span className="relative">
              <InfoSVG onClick={() => setVisible('locked')} />
            </span>
          </Popover>
        </small>
        <h2>
          {tokenPointsOverviewIsLoading
            ? spinner
            : formatNumber(userTotalEarningsTokens)}
        </h2>
      </div>
    </div>
  )
}
