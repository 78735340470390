import { useEffect, useState } from 'react'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import useMiniForm from 'core/useMiniForm'
import Modal from 'shared/components/kit/modal'
import SelectCryptocurrency from 'shared/components/form/select-cryptocurrency'
import getVideo from 'shared/queries/getVideo'
import notification from 'shared/components/kit/notification'
import Checkbox from 'shared/components/form/checkbox'
import Field from 'shared/components/form/field'
import CrappedImageInput from 'shared/components/form/image/crappedImageInput'
import SelectMediaCategory from 'publisher/components/form/select-media-category'
import SelectMediaTags from 'publisher/components/form/select-media-tags'
import SelectMediaType from 'publisher/components/form/select-media-type'
import updateVideo from 'publisher/mutations/updateVideo'
import getMyVideos from 'publisher/queries/getMyVideos'
import classes from './PublisherMedialModal.module.css'

const INITIAL_VALUES = {
  crypto_currencies: [],
  tags: [],
  agreeThumbnail: null,
}

const VALIDATION = {
  media_type: {
    label: 'Media type',
    rule: 'required',
  },
  category: {
    label: 'Main category',
    rule: 'required',
  },
  crypto_currencies: {
    label: 'cryptocurrencies',
  },
  tags: {
    label: 'tags',
    rule: 'minLength:1|maxLength:5',
  },
  thumbnail: {
    label: 'thumbnail',
    rule: (value, values) => {
      if (values.status === 'published' && value === undefined) {
        return '*Select Thumbnail'
      }
    },
  },
  agreeThumbnail: {
    label: 'Confirming the thumbnail',
    rule: (value) => {
      if (value !== true) {
        return 'Confirming the thumbnail is required.'
      }
    },
  },
}

export default function PublishMediaModal(props) {
  const { media, onCancel } = props

  const [thumbnail, setThumbnail] = useState()

  useEffect(() => {
    setThumbnail(media?.thumbnail)
  }, [media])

  const mediaId = media?.id
  const mediaTitle = media?.title
  const queryClient = useQueryClient()

  const mutation = useMutation(updateVideo, {
    onSuccess: () => {
      onCancel()
      queryClient.invalidateQueries({
        query: getVideo,
        variables: {
          id: mediaId,
        },
      })
      queryClient.invalidateQueries({
        query: getMyVideos,
      })
      notification.success({ title: 'Media published successfully!' })
    },
  })

  const form = useMiniForm({
    initialValues: INITIAL_VALUES,
    validation: VALIDATION,
    async onSubmit(values) {
      try {
        await mutation.mutateAsync({
          ...values,
          thumbnail: thumbnail,
          id: mediaId,
          status: 'published',
          crypto_currencies: (values.crypto_currencies || []).map(
            (currency) => currency.value
          ),
          tags: (values.tags || []).map((tag) => tag.label),
        })
      } catch (error) {
        throw error.json
      }
    },
  })

  const handleCancel = () => {
    onCancel()
    form.reset()
  }

  const handleChangeThumbnail = (val) => {
    setThumbnail(val)
  }

  const handleOk = () => {
    form.submit()
  }

  return (
    <Modal
      loading={mutation.isLoading}
      okText="Publish"
      onCancel={handleCancel}
      onOk={handleOk}
      size="sm"
      title="Express Publisher"
      visible={typeof mediaId === 'number'}>
      <p className="mb-4 -mt-8">
        Create a killer experience for your audience and get more views by
        filling in the details below.
      </p>

      <h3 className="mb-8">{mediaTitle}</h3>

      <form>
        <div className={classes.thumbnail}>
          <Field help="Click on the thumbnail to reupload.">
            <CrappedImageInput
              placeholder="Select Thumbnail..."
              value={thumbnail}
              onChange={handleChangeThumbnail}
              height={720}
              width={1280}
            />
          </Field>
        </div>
        <p>
          Does the thumbnail look good?
          <span className="block text-neutral text-sm mb-4">
            For us to be able to show the thumbnail correctly, it is vital that
            the thumbnail fills the whole space without any padding or black
            areas.
          </span>
        </p>
        <Field
          label="Confirm the thumbnail"
          {...form.getErrorPropsFor('agreeThumbnail')}>
          <Checkbox
            className="mb-4 opacity-75"
            {...form.getCheckboxPropsFor('agreeThumbnail')}
            description="Yes, the thumbnail looks good"
          />
        </Field>

        <SelectMediaType.Field
          help="Select if it's a Video or a Podcast"
          {...form.getErrorPropsFor('media_type')}
          {...form.getInputPropsFor('media_type')}
        />

        <SelectMediaCategory.Field
          help="Select a category for your media"
          {...form.getErrorPropsFor('category')}
          {...form.getInputPropsFor('category')}
        />

        <SelectCryptocurrency.Field
          menuPosition="fixed"
          {...form.getErrorPropsFor('crypto_currencies')}
          {...form.getInputPropsFor('crypto_currencies')}
        />

        <SelectMediaTags.Field
          label="Add Media Tags"
          menuPosition="fixed"
          {...form.getErrorPropsFor('tags')}
          {...form.getInputPropsFor('tags')}
        />
      </form>
    </Modal>
  )
}
