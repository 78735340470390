// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Chart_overview__1vr1b {\n  display: flex;\n  flex-direction: column;\n  width: 40%;\n}\n\n.Chart_box__3kXI- {\n  --tw-bg-opacity: 1;\n  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));\n  border-radius: 1rem;\n  margin-bottom: 1rem;\n  padding: 1rem;\n  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);\n  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);\n}\n\n.Chart_box__3kXI- div {\n  margin-bottom: 0.25rem;\n}\n\n.Chart_charts___yWVA {\n  width: 60%;\n}\n\n.Chart_charts___yWVA > .box {\n  padding: 1.5rem;\n}\n\n.Chart_charts___yWVA > .box > header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 1rem;\n}\n\n.Chart_charts___yWVA select {\n  width: 14rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/ads-manager/buy-buttons/components/statistics/Chart.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,sBAAmB;EAAnB,UAAmB;ADAnB;;AAEA;ECFA,kBAAmB;EAAnB,2DAAmB;EAAnB,mBAAmB;EAAnB,mBAAmB;EAAnB,aAAmB;EAAnB,oFAAmB;EAAnB,uGAAmB;ADInB;;AAEA;ECNA,sBAAmB;ADQnB;;AAEA;ECVA,UAAmB;ADYnB;;AAEA;ECdA,eAAmB;ADgBnB;;AAEA;EClBA,aAAmB;EAAnB,mBAAmB;EAAnB,8BAAmB;EAAnB,mBAAmB;ADoBnB;;AAEA;ECtBA,YAAmB;ADwBnB","sourcesContent":[".overview {\n  @apply flex flex-col w-2/5;\n}\n\n.box {\n  @apply bg-white shadow-lg rounded-2xl p-4 mb-4;\n}\n\n.box div {\n  @apply mb-1;\n}\n\n.charts {\n  @apply w-3/5;\n}\n\n.charts > :global(.box) {\n  @apply p-6;\n}\n\n.charts > :global(.box) > header {\n  @apply flex items-center justify-between mb-4;\n}\n\n.charts select {\n  @apply w-56;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overview": "Chart_overview__1vr1b",
	"box": "Chart_box__3kXI-",
	"charts": "Chart_charts___yWVA"
};
export default ___CSS_LOADER_EXPORT___;
