type GetPaymentDetailVariables = {
  id: number,
}
export default function getPaymentDetail(
  variables: GetPaymentDetailVariables = {}
) {
  const { id } = variables
  return {
    path: `/admin/payment-details/${id}`,
  }
}
