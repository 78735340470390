import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import getChannel from 'admin/queries/getChannel'

export default function useChannel(channelId: number) {
  const qr = useQuery(getChannel, {
    keepPreviousData: true,
    variables: {
      id: channelId,
    },
  })
  return {
    ...qr,
    channel: get(qr, 'data.json', {}),
  }
}
