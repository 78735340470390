import { useRef, useState } from 'react'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import { ReactComponent as PlusSVG } from 'svg/plus.svg'
import useTimelineActions from '../hooks/useTimelineActions'

type Props = {
  chaptersCount: number,
  getCurrentTime: Function,
}

export default function ChaptersLeftActions(props: Props) {
  const { chaptersCount, getCurrentTime } = props

  const { insertChapter } = useTimelineActions()
  const [addChapterAt, setAddChapterAt] = useState()
  const [title, setTitle] = useState('')

  const submitButtonRef = useRef()

  const handleOk = () => {
    submitButtonRef.current.click()
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    insertChapter({
      from: chaptersCount > 0 ? addChapterAt : 0,
      title,
    })
    handleCloseAddModal()
  }

  const handleAddChapter = () => {
    setTitle('')
    setAddChapterAt(getCurrentTime())
  }

  const handleCloseAddModal = () => {
    setAddChapterAt()
  }

  return (
    <>
      <div>
        {chaptersCount >= 1 ? (
          <>
            <h4>Chapters</h4>
            <Button
              mood="secondary"
              onClick={handleAddChapter}
              size="sm"
              square>
              <PlusSVG />
            </Button>
          </>
        ) : (
          <Button mood="secondary" onClick={handleAddChapter} size="sm" block>
            <PlusSVG /> Add Chapter
          </Button>
        )}
      </div>

      <Modal
        okText="Add Chapter"
        onCancel={handleCloseAddModal}
        onOk={handleOk}
        size="sm"
        title="Add a New Chapter"
        visible={typeof addChapterAt === 'number'}>
        <form onSubmit={handleSubmit}>
          <Field label="Chapter Title" required>
            <Input
              onChange={setTitle}
              placeholder="Chapter Title"
              value={title}
              required
            />
          </Field>
          <button ref={submitButtonRef} hidden />
        </form>
      </Modal>
    </>
  )
}
