import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import classnames from 'classnames'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Spin from '../spin'
import Alert from 'shared/components/kit/alert'
import classes from './index.module.css'

type Props = {
  closeOnBackdrop?: boolean,
  loading?: boolean,
  onClose?: Function,
  title?: any,
  visible?: boolean,
}

export default function Drawer(props: Props) {
  const {
    children,
    closeOnBackdrop,
    error,
    isError,
    loading,
    onClose,
    title,
    visible,
  } = props

  const targetRef = useRef()

  if (!targetRef.current) {
    targetRef.current = document.createElement('div')
    document.body.appendChild(targetRef.current)
  }

  useEffect(() => {
    if (visible) {
      disableBodyScroll(window)
    } else {
      enableBodyScroll(window)
    }
  }, [visible])

  useEffect(() => {
    return () => {
      if (targetRef.current) {
        targetRef.current.parentNode.removeChild(targetRef.current)
      }
    }
  }, [])

  const handleClickBackdrop = () => {
    if (closeOnBackdrop && !loading) {
      onClose()
    }
  }

  const handleClose = () => {
    if (onClose && !loading) {
      onClose()
    }
  }

  const wrapperClassName = classnames(classes.wrapper, {
    [classes.visible]: visible,
  })

  const content = (
    <div className={wrapperClassName}>
      <div className={classes.backdrop} onClick={handleClickBackdrop} />
      <div className={classes.drawer}>
        <button className={classes.close} onClick={handleClose}>
          ✕
        </button>
        {title ? <span className={classes.title}>{title}</span> : null}
        <section>{children}</section>
        {loading && (
          <div className={classes.loading}>
            <Spin />
          </div>
        )}
        {isError && (
          <div className={classes.error}>
            <Alert title={error?.message} type="error" />
          </div>
        )}
      </div>
    </div>
  )

  if (targetRef.current) {
    return createPortal(content, targetRef.current)
  }

  return null
}

Drawer.defaultProps = {
  closeOnBackdrop: true,
}
