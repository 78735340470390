// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChannelStatistic_statTable__23a3B {\n  border-width: 0px !important;\n}\n\n.ChannelStatistic_statTable__23a3B tbody td {\n  border-top-width: 0px !important;\n  padding: 0px !important;\n  width: 25% !important;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/monetization/overview/components/ChannelStatistic.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,4BAAmB;ADAnB;;AAEA;ECFA,gCAAmB;EAAnB,uBAAmB;EAAnB,qBAAmB;ADInB","sourcesContent":[".statTable {\n  @apply border-0 !important;\n}\n\n.statTable tbody td {\n  @apply p-0 border-t-0 w-1/4 !important;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statTable": "ChannelStatistic_statTable__23a3B"
};
export default ___CSS_LOADER_EXPORT___;
