type UpdateVideoStatusVariables = {
  id: number,
  status: 'draft' | 'published',
}

export default function updateVideoStatus(
  variables: UpdateVideoStatusVariables
) {
  const { id, status } = variables

  return {
    method: 'PUT',
    path: `/publisher/videos/${id}/status/${status}`,
  }
}
