import { useEffect } from 'react'
import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Select from 'shared/components/form/select'
import SelectPublisher from 'admin/components/form/select-publisher'
import SelectUser from 'admin/components/form/select-user'
import classes from './Filters.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  const userGroupOptions = [
    {
      label: 'User group',
      value: 'all',
    },
    {
      label: 'Publisher group',
      value: 'publisher',
    },
    {
      label: 'User single',
      value: 'singleUser',
    },
    {
      label: 'Publisher single',
      value: 'singlePublisher',
    },
  ]

  const typeOptions = [
    {
      label: 'Active',
      value: 0,
    },
    {
      label: 'Recalled',
      value: 1,
    },
  ]

  useEffect(() => {
    form.change('publisherId', null)
    form.change('userId', null)
  }, [form.get('userGroup')])

  useEffect(() => {
    form.change('justDeleted', 0)
  }, [])

  const disableSearchButton =
    (form.get('userGroup') === 'singlePublisher' && !form.get('publisherId')) ||
    (form.get('userGroup') === 'singleUser' && !form.get('userId'))

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <Select
        className={classes.select}
        options={userGroupOptions}
        {...form.getInputPropsFor('userGroup')}
        isClearable
        placeholder="All"
      />

      {form.get('userGroup') === 'singlePublisher' && (
        <SelectPublisher
          className={classes.UserSelect}
          {...form.getInputPropsFor('publisherId')}
          isClearable
          placeholder="Select publisher..."
        />
      )}

      {form.get('userGroup') === 'singleUser' && (
        <SelectUser
          className={classes.UserSelect}
          {...form.getInputPropsFor('userId')}
          isClearable
          placeholder="Select user..."
        />
      )}
      <Select
        className={classes.select}
        options={typeOptions}
        {...form.getInputPropsFor('justDeleted')}
      />
      <Button disabled={disableSearchButton} outlined>
        Search
      </Button>
    </form>
  )
}
