import { useState } from 'react'
import { useHistory } from 'react-router'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/useMutation'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import getUsers from 'admin/queries/getUsers'

export default function DeleteUser({ user }) {
  const [visible, setVisible] = useState(false)

  const history = useHistory()

  const queryClient = useQueryClient()

  const { isLoading, mutate } = useMutation(deleteUser, {
    onSuccess: () => {
      notification.success({
        title: 'User deleted successfully!',
      })
      setVisible(false)
      queryClient.invalidateQueries({ query: getUsers })

      let search = history.location.search.substr(
        0,
        history.location.search.indexOf('&id')
      )
      history.push('/admin/users' + search)
    },
  })

  const handleCancel = () => {
    setVisible(false)
  }

  const handleClick = () => {
    setVisible(true)
  }

  const handleOk = async () => {
    mutate({ id: user.id })
  }

  return (
    <>
      <p className="mb-4">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
      <Button onClick={handleClick}>Delete User</Button>
      <Modal
        loading={isLoading}
        okText="Delete this User"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Delete User"
        visible={visible}>
        <p>Are you sure you want to delete this user?</p>
      </Modal>
    </>
  )
}

function deleteUser(variables) {
  return {
    method: 'delete',
    url: `/admin/users/${variables.id}`,
    variables,
  }
}
