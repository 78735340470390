import classes from './ChoosePositionInput.module.css'

const positions = [
  { className: 'top-4 left-4', value: 'top-left' },
  { className: 'top-4 right-4', value: 'top-right' },
  { className: 'bottom-4 left-4', value: 'bottom-left' },
  { className: 'bottom-4 right-4', value: 'bottom-right' },
]

export default function ChoosePositionInput({ onChange, value }) {
  return (
    <div className={classes.wrapper}>
      {positions.map((position) => (
        <div
          className={position.className}
          data-selected={position.value === value ? 'yes' : 'no'}
          onClick={() => onChange(position.value)}
          role="button">
          {position.value}
        </div>
      ))}
    </div>
  )
}
