import Field from 'shared/components/form/field'
import SelectPublisher from 'admin/components/form/select-publisher'
import Icon from 'shared/components/kit/icon'
import truncate from 'helpers/utils/truncate'
import { ReactComponent as CloseSVG } from 'svg/close-icon.svg'
import ChannelAvatar from 'shared/components/channel-avatar'
import ChannelStatus from 'admin/components/channel-status'
import classes from './CustomPublishersSelect.module.css'

export default function CustomPublisherSelect({ form }) {
  let userArray = []
  const handleChange = (user) => {
    userArray = form.get('userArray')
    if (!Array.isArray(userArray)) {
      userArray = []
    }

    if (
      userArray.filter((_user) => {
        return _user.user.id === user.user.id
      }).length === 0
    ) {
      userArray = [...userArray, user]
    }
    form.change('userArray', userArray)
    form.change(
      'userIds',
      userArray.map((user) => user.user.id)
    )
  }
  const handleDeleteUser = (userId) => {
    if (isNaN(userId)) return false
    const userArray = form.get('userArray')
    const usersListAfterDelete = userArray.filter((user) => {
      return user.user.id !== userId
    })
    form.change('userArray', usersListAfterDelete)
    form.change(
      'userIds',
      usersListAfterDelete.map((user) => user.user.id)
    )
  }

  return (
    <div>
      <Field>
        <SelectPublisher
          onChange={handleChange}
          isClearable
          isRawValue={true}
          value={null}
          placeholder="Search for publisher..."
        />
      </Field>
      <div className={classes.wrapperUsersInfos}>
        {typeof form.get('userArray') !== 'undefined' &&
          form.get('userArray').map((data, index) => (
            <div className={classes.userInfoBox} key={index}>
              <ChannelAvatar
                className={classes.avatar}
                channel={data.user?.channel}
              />
              <div className={classes.info}>
                <div className="mb-1">{data.user.channel?.name}</div>
                {typeof data.user?.channel?.status !== 'undefined' ? (
                  <ChannelStatus status={data.user?.channel?.status} />
                ) : null}
                <div
                  className="mt-1"
                  title={data.user.email?.length > 35 ? data.user.email : ''}>
                  {truncate(data.user.email, 35)}
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  handleDeleteUser(data.user.id)
                }}>
                <Icon>
                  <CloseSVG />
                </Icon>
              </button>
            </div>
          ))}
      </div>
    </div>
  )
}
