import { ethers } from 'ethers'
import RewardDistribution from 'core/tcg/RewardDistribution.json'

const POLYGON_RPC = process.env.REACT_APP_TCG_POLYGON_RPC
const CONTRACT_ADDRESS = process.env.REACT_APP_TCG_REWARD_CONTRACT_ADDRESS

export default async function getUserClaimableTokensRaw({ queryKey }) {
  const [_key, address] = queryKey

  const provider = new ethers.providers.JsonRpcProvider(POLYGON_RPC)

  const contract = new ethers.Contract(
    CONTRACT_ADDRESS,
    RewardDistribution.abi,
    provider
  )

  try {
    const claimableWei = await contract.claimable(address)
    const balanceText = ethers.utils.formatEther(claimableWei)
    return {
      balanceText,
      balance: balanceText ? Number(balanceText) : 0,
    }
  } catch (error) {
    throw error
  }
}
