import { useCallback, useEffect, useRef, useState } from 'react'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'

type Config = {
  initialValues?: Function | Object,
  onSubmit: Function,
  resetOnSuccess?: boolean,
  validation?: Object,
}

export default function useMiniForm(config: Config = {}) {
  const { initialValues, onSubmit, resetOnSuccess, validation } = config

  const [status, setStatus] = useState({
    submitting: false,
  })

  const isInitRef = useRef(true)

  const [values, setValues] = useState(
    (typeof initialValues === 'function' ? initialValues() : initialValues) ||
      {}
  )

  useEffect(() => {
    if (isInitRef.current) {
      isInitRef.current = false
    } else {
      setValues(
        typeof initialValues === 'function'
          ? initialValues()
          : initialValues || {}
      )
    }
  }, [initialValues])

  const get = useCallback((name) => _get(values, name), [values])

  const change = useCallback((name, value) => {
    setValues((prev) => {
      let payload = {}
      if (typeof name === 'string') {
        payload[name] = value
      } else if (typeof name === 'function') {
        payload = { ...name(prev) }
      } else {
        payload = { ...name }
      }
      return { ...prev, ...payload }
    })
  }, [])

  const initialize = useCallback((nextValues) => setValues(nextValues), [])

  const reset = useCallback(
    (freshValues) => {
      let nextValues
      if (freshValues) {
        nextValues = _cloneDeep(freshValues)
      } else {
        nextValues =
          (typeof initialValues === 'function'
            ? initialValues()
            : initialValues) || {}
      }
      setValues(nextValues)
      clearErrors()
      return nextValues
    },
    [initialValues]
  )

  const validate = useCallback(
    (rules) => {
      if (rules) {
        const syncError = createValidator(rules)(values)
        if (syncError) {
          setStatus({
            error: syncError,
            submitting: false,
          })
          return syncError
        }
      }
      setStatus({ submitting: false })
      return true
    },
    [values]
  )

  const clearErrors = useCallback(() => {
    setStatus((prev) => ({
      ...prev,
      error: undefined,
    }))
  }, [])

  const submit = useCallback(
    async (event, ...params) => {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      if (validate(validation) !== true) {
        return
      }
      setStatus({ submitting: true })
      try {
        await onSubmit(values, event, ...params)
        const nextStatus = { submitting: false }
        if (resetOnSuccess) {
          reset()
        } else {
          nextStatus.succeeded = true
        }
        setStatus(nextStatus)
      } catch (error) {
        setStatus({
          error,
          failed: true,
          submitting: false,
        })
      }
    },
    [onSubmit, reset, resetOnSuccess, validate, validation, values]
  )

  const getInputPropsFor = (name) => ({
    onChange: (value) => {
      change(name, value)
    },
    value: get(name),
  })

  const getCheckboxPropsFor = (name) => ({
    onChange: (value) => {
      change(name, value)
    },
    checked: !!get(name),
  })

  const getErrorPropsFor = (name) => {
    const fieldError = _get(status.error, `errors.${name}`)
    if (fieldError) {
      return {
        error: fieldError,
      }
    }
  }

  return {
    get,
    change,
    clearErrors,
    error: status.error,
    failed: !!status.failed,
    initialize,
    getInputPropsFor,
    getCheckboxPropsFor,
    getErrorPropsFor,
    reset,
    submitting: status.submitting,
    submit,
    succeeded: !!status.succeeded,
    validate,
    values,
  }
}

function isEmpty(value) {
  return (
    typeof value === 'undefined' ||
    value === undefined ||
    value === null ||
    value === ''
  )
}

export const validators = {
  maxLength(name, value, length) {
    if (
      (typeof value === 'number' || typeof value === 'string') &&
      `${value}`.length > length
    ) {
      return `${name} length should not be more than ${length}.`
    } else if (Array.isArray(value) && value.length > length) {
      if (length > 1) {
        return `You are not allowed to select more than ${length} items.`
      } else {
        return 'You are not allowed to select more than one item.'
      }
    }
  },
  min(name, value, length) {
    if (!isEmpty(value)) {
      const number = Number(value)
      if (isNaN(number)) {
        return 'Must be a valid number.'
      } else if (number < length) {
        return `Must be at least ${length}`
      }
    }
  },
  minLength(name, value, length) {
    if (
      (typeof value === 'number' || typeof value === 'string') &&
      `${value}`.length < length
    ) {
      return `${name} length should be at least ${length}.`
    } else if (Array.isArray(value) && value.length < length) {
      if (length > 1) {
        return `You must select at least ${length} items.`
      } else {
        return 'You must select at least one item.'
      }
    }
  },
  required(name, value) {
    if (isEmpty(value)) {
      return `${name} is required.`
    }
  },
  match(name, value, pattern) {
    if (!value.match(pattern)) {
      return `${name} format is not correct.`
    }
  },
  email(name, value) {
    if (
      !value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return `Please enter a valid email address.`
    }
  },
}

function validateSingleField(name, values, rule, label) {
  let value = values[name]
  let finalLabel = label || name

  if (typeof rule === 'string') {
    let rules = rule.split('|')

    for (let i = 0; i < rules.length; i++) {
      let [ruleName, params] = rules[i].split(':')
      let ruleParams = params ? params.split(',') : []
      let error = validators[ruleName](finalLabel, value, ...ruleParams)

      if (error) {
        return error
      }
    }
  }

  if (typeof rule === 'function') {
    return rule(value, values, validators)
  }

  if (rule && rule.rule) {
    return validateSingleField(name, values, rule.rule, rule.label)
  }
}

function createValidator(
  rules = {},
  genericMessage = 'Please fix the following errors'
) {
  return function (values = {}) {
    const errors = {}
    let hasError = false

    Object.keys(rules).forEach((name) => {
      const message = validateSingleField(name, values, rules[name])
      if (typeof message === 'string' && message.length > 0) {
        errors[name] = message
        hasError = true
      }
    }, {})

    if (hasError) {
      return {
        errors,
        message: genericMessage,
      }
    }
  }
}
