type VerifyResetPasswordTokenVariables = {
  token: string,
}

export default function verifyResetPasswordToken(
  variables: VerifyResetPasswordTokenVariables = {}
) {
  const { token } = variables
  return {
    name: 'verifyResetPasswordToken',
    path: `/password/verify/${token}`,
  }
}
