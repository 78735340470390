import classnames from 'classnames'
import classes from './HtmlSelect.module.css'

export default function HtmlSelect(props) {
  const { children, className, onChange, ...rest } = props

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <select
      className={classnames(classes.select, className)}
      onChange={handleChange}
      {...rest}>
      {children}
    </select>
  )
}
