type ArchiveNotificationMutationVariables = {
  id: number,
}

export default function archiveNotification(
  variables: ArchiveNotificationMutationVariables
) {
  return {
    method: 'DELETE',
    path: `/admin/notifications/${variables.id}`,
  }
}
