type GetChannelEarningsVariables = {
  channelId: number,
  page?: number,
  status?: string,
  includeTransaction?: boolean,
}

export default function getChannelEarnings(
  variables: GetChannelEarningsVariables = {}
) {
  const { channelId, page, status, includeTransaction } = variables

  const includes = []

  if (includeTransaction) {
    includes.push('transaction')
  }

  const filters = {}
  if (channelId) {
    filters.channel_id = channelId
  }

  if (status) {
    filters.status = status
  }

  return {
    name: 'channelEarnings',
    path: `/admin/earnings`,
    params: {
      include: includes.length ? includes.join(',') : undefined,
      page: page > 0 ? page : undefined,
      filters,
    },
  }
}
