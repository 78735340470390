import { useRef } from 'react'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/useMutation'
import useMiniForm from 'core/useMiniForm'
import Filed from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import setBudget from 'admin/queries/setBudget'
import getBudgets from 'admin/queries/getBudgets'

const VALIDATION = {
  budget: {
    rule: 'required',
  },
}

export default function AddBudget({ year, month, visible, handleClose }) {
  const submitButtonRef = useRef()
  const queryClient = useQueryClient()

  const mutation = useMutation(setBudget, {
    onSuccess: () => {
      notification.success({
        title: 'Budget saved successfully!',
      })
      queryClient.invalidateQueries({
        query: getBudgets,
        variables: {
          year: year,
        },
      })
    },
  })

  const form = useMiniForm({
    validation: VALIDATION,
    resetOnSuccess: true,
    async onSubmit(values) {
      try {
        const params = {}
        params.budget = values.budget
        params.month = `${year}-${
          month.toString().length === 1 ? '0' + month : month
        }`
        await mutation.mutateAsync(params)
        handleClose()
      } catch (error) {
        throw error
      }
    },
  })

  const handleCancel = () => {
    form.reset()
    handleClose()
  }

  const handleOk = () => {
    submitButtonRef.current.click()
  }

  return (
    <>
      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        okText="Save"
        size="sm"
        title="Add budget"
        visible={visible}>
        <p className="mb-4">
          Set budget for{' '}
          {`${year}-${month.toString().length === 1 ? '0' + month : month}`}
        </p>
        <form onSubmit={form.submit}>
          <Filed {...form.getErrorPropsFor('budget')}>
            <Input
              required
              placeholder="Set budget"
              {...form.getInputPropsFor('budget')}
            />
          </Filed>
          <button ref={submitButtonRef} hidden type="submit" />
        </form>
      </Modal>
    </>
  )
}
