import { useEffect } from 'react'
import useQueryClient from 'core/react-query/useQueryClient'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import Field from 'shared/components/form/field'
import RichText from 'shared/components/form/rich-text'
import Button from 'shared/components/kit/button'
import notification from 'shared/components/kit/notification'
import getComments from 'publisher/queries/getComments'
import replyToComment from 'publisher/queries/replyToComment'
import getComment from 'publisher/queries/getComment'
import classes from './ReplyForm.module.css'

export default function ReplyForm({ commentId, mentioned, onRemoveMentioned }) {
  useEffect(() => {
    document.getElementsByTagName('textarea')[0].focus()
  }, [mentioned, commentId])

  const form = useMiniForm({
    resetOnSuccess: true,
    async onSubmit(values) {
      try {
        await reply.mutateAsync({
          commentId,
          text: values.text,
          mentions: mentioned ? [mentioned.user.id] : [],
        })
      } catch (error) {
        throw error
      }
    },
  })

  const queryClient = useQueryClient()
  const reply = useMutation(replyToComment, {
    onSuccess(response) {
      onRemoveMentioned()
      queryClient.invalidateQueries({
        query: getComment,
        variables: {
          id: commentId,
        },
      })
      queryClient.invalidateQueries({
        query: getComments,
      })
      notification.success({ title: 'Reply added successfully!' })
    },
    onError(error) {
      form.change('text', '')
      notification.error({ title: error.message })
    },
  })

  const disableReply = !form.get('text')?.toString().trim()
  return (
    <form onSubmit={form.submit}>
      <Field className={classes.text}>
        <RichText
          {...form.getInputPropsFor('text')}
          placeholder="Write your reply..."
          autoFocus
          required
          header={
            mentioned && (
              <div className={classes.mention}>
                <div>
                  <span className="text-neutral text-sm">
                    Replying to &nbsp;
                  </span>
                  {mentioned.user.username}
                </div>
                <span onClick={onRemoveMentioned} className={classes.close}>
                  Cancel mention ✕
                </span>
              </div>
            )
          }
        />
      </Field>
      <div className={classes.submitContainer}>
        <Button disabled={disableReply} loading={form.submitting} type="submit">
          Post reply
        </Button>
      </div>
    </form>
  )
}
