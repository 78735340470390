import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import cookie from 'core/cookie'
import useMutation from 'core/react-query/useMutation'
import MagicLoginVerification from 'shared/mutations/magicLoginVerification'
import Loading from 'shared/components/kit/loading'
import Result from 'shared/components/kit/result'
import Button from 'shared/components/kit/button'

export default function MagicSignInVerification() {
  const params = useParams()
  const history = useHistory()
  const { token } = params
  const { isLoading, isError, mutate } = useMutation(MagicLoginVerification, {
    onSuccess: (response) => {
      cookie().set('jwt', response.json.token)
      const searchParams = new URLSearchParams(history.location.search)
      window.location.href = searchParams.get('redirect') ?? '/publisher'
    },
    error: (response) => {},
  })

  useEffect(() => {
    mutate({ token })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    isError && (
      <div className="flex justify-center pt-48">
        <Result
          title="Token is not correct!"
          status="error"
          footer={
            <Button block className="w-full" onClick={() => history.push('/')}>
              Log In
            </Button>
          }
        />
      </div>
    )
  )
}
