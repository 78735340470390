import { Route, Switch, useParams } from 'react-router'
import Alert from 'shared/components/kit/alert'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import useVideo from 'publisher/hooks/useVideo'
import VideoLayout from 'publisher/components/layouts/video'
import EditVideo from './edit'
import VideoOverview from './overview'
import VideoStudio from './studio'
import classes from './index.module.css'

export default function Video() {
  const params = useParams()
  const id = Number(params.id)

  const { error, isError, isLoading } = useVideo(id)

  if (isError) {
    return (
      <VideoLayout>
        <div className={classes.error}>
          <Alert title={error.json.message} type="error" />
        </div>
      </VideoLayout>
    )
  }

  return (
    <VideoLayout>
      {isLoading ? (
        <div className={classes.loading}>
          <Icon>
            <Spin />
          </Icon>
          <div>Loading...</div>
        </div>
      ) : (
        <Switch>
          <Route component={VideoOverview} path="/publisher/media/:id" exact />
          <Route component={EditVideo} path="/publisher/media/:id/edit" exact />
          <Route
            component={VideoStudio}
            path="/publisher/media/:id/studio"
            exact
          />
        </Switch>
      )}
    </VideoLayout>
  )
}
