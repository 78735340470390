import { useEffect, useMemo, useRef, useState } from 'react'
import useQueryClient from 'core/useQueryClient'
import useMutation from 'core/useMutation'
import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Filed from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import getReasons from 'admin/queries/getReasons'
import setReasons from 'admin/queries/setReasons'

const VALIDATION = {
  value: {
    label: 'Reason',
    rule: 'required',
  },
}

export default function AddReason({ reasons }) {
  if (reasons === undefined || reasons === null) {
    reasons = []
  }
  const valueRef = useRef()
  const submitButtonRef = useRef()
  const [visible, setVisible] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    valueRef.current.focus()
  }, [visible])

  const mutation = useMutation(setReasons, {
    onSuccess: () => {
      notification.success({
        title: 'Deleted video reasons added successfully!',
      })
      queryClient.invalidateQueries({
        query: getReasons,
        variables: {
          key: 'video_delete_reasons',
        },
      })
    },
  })

  const form = useMiniForm({
    validation: VALIDATION,
    resetOnSuccess: true,
    initialValues: useMemo(() => {
      return {
        status: 'active',
      }
    }, []),
    async onSubmit(values) {
      try {
        const newValue = {
          key: `other-${Date.now()}`,
          value: values.value,
          status: values.status,
        }
        reasons.push(newValue)
        const params = {}
        params.reasons = reasons
        params.key = 'video_delete_reasons'
        await mutation.mutateAsync(params)
        setVisible(false)
      } catch (error) {
        throw error
      }
    },
  })

  const handleCancel = () => {
    setVisible(false)
    form.reset()
  }

  const handleOk = () => {
    submitButtonRef.current.click()
  }

  return (
    <>
      <Button onClick={() => setVisible(true)} outlined>
        Add a Reason
      </Button>

      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        okText="Save"
        title="Add new delete video reason"
        visible={visible}>
        <form onSubmit={form.submit}>
          <Filed label="Reason" {...form.getErrorPropsFor('value')}>
            <Input
              ref={valueRef}
              required
              placeholder="Write your reason here..."
              {...form.getInputPropsFor('value')}
            />
          </Filed>
          <Filed label="Status" {...form.getErrorPropsFor('status')}>
            <HtmlSelect className="w-full" {...form.getInputPropsFor('status')}>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </HtmlSelect>
          </Filed>
          <button ref={submitButtonRef} hidden type="submit" />
        </form>
      </Modal>
    </>
  )
}
