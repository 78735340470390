import { components } from 'react-select'
import Field from 'shared/components/form/field'
import CreatableMultiSelect from 'publisher/components/form/creatable-multi-select'

export default function MediaTags(props) {
  const { menuPosition, onChange, value } = props

  const tags = value || []

  const isValidNewOption = (inputValue, selectValue) => {
    return (
      inputValue.length <= 25 &&
      inputValue.length > 0 &&
      selectValue.length < 5 &&
      /^[A-Za-z\s]/.test(inputValue)
    )
  }

  const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 5 ? (
          props.children
        ) : (
          <div style={{ margin: 10 }}>
            The maximum number of tags can not be more than 5.
          </div>
        )}
      </components.Menu>
    )
  }

  const handleInputChange = (text) => {
    let inputValue = text.replace(/[^a-zA-Z\s]/, '').replace(/\s\s+/g, ' ')
    if (inputValue.length > 25) {
      inputValue = inputValue.substr(0, 25)
    }
    return inputValue
  }

  return (
    <CreatableMultiSelect
      isMulti={true}
      Menu={{ Menu }}
      isOptionDisabled={() => tags.length > 4}
      menuPosition={menuPosition}
      onChange={onChange}
      value={value}
      isValidNewOption={isValidNewOption}
      onInputChange={handleInputChange}
    />
  )
}

MediaTags.Field = ({ error, label, ...inputProps }) => (
  <Field
    error={error}
    help="By selecting tags or creating your own tags, you will reach more users who are interested in more specific topics. Users choose which content they want to see based on the tags they choose."
    label={label}>
    <MediaTags {...inputProps} />
  </Field>
)

MediaTags.Field.defaultProps = {
  label: 'Tags',
}
