import { useEffect, useRef, useState } from 'react'
import useMutation from 'core/react-query/useMutation'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import Input from 'shared/components/form/input'
import feedback from 'publisher/mutations/feedback'
import { ReactComponent as ThumbUpFilledSVG } from 'svg/like-filled.svg'
import { ReactComponent as ThumbDownFilledSVG } from 'svg/dislike-filled.svg'
import classes from './Box.module.css'

export default function Box({ image, section, content, title }) {
  const [visible, setVisible] = useState()
  const [visibleLike, setVisibleLike] = useState()
  const [text, setText] = useState()
  const [textLike, setTextLike] = useState()
  const textRef = useRef()
  const textLikeRef = useRef()

  useEffect(() => {
    textRef.current.focus()
  }, [visible])

  useEffect(() => {
    textLikeRef.current.focus()
  }, [visibleLike])

  const [sentFeedbackSections, setSentFeedbackSections] = useState({
    'PP/landing/sectionA': '',
    'PP/landing/sectionB': '',
    'PP/landing/sectionC': '',
    'PP/landing/sectionD': '',
    'PP/landing/sectionE': '',
  })

  const feedbackMutation = useMutation(feedback, {
    onSuccess: () => {
      notification.success({
        title: 'We received your feedback. Thank you.',
      })
    },
  })

  const handleUpdateSentFeedbacks = (value) => {
    setSentFeedbackSections(value)
  }

  const handleFeedback = async (type, location, value, text) => {
    try {
      await feedbackMutation.mutateAsync({
        type,
        location,
        value,
        text,
      })
    } catch (error) {
      notification.error({ title: 'Something went wrong in sent feedback!' })
    }
  }

  const handleThumbUp = async (textLike) => {
    await handleFeedback('thumb', section, 1, textLike)
    handleUpdateSentFeedbacks({ ...sentFeedbackSections, [section]: 'like' })
    setVisibleLike(false)
    setTextLike('')
  }

  const handleThumbDown = async (text) => {
    await handleFeedback('thumb', section, -1, text)
    handleUpdateSentFeedbacks({ ...sentFeedbackSections, [section]: 'dislike' })
    setVisible(false)
    setText('')
  }

  const handleCancel = async () => {
    setText('')
    setVisible(false)
    await handleThumbDown('')
  }

  const handleCancelLike = async () => {
    setVisibleLike(false)
    setTextLike('')
    await handleThumbUp('')
  }

  const handleChange = (value) => {
    setText(value)
  }

  const handleChangeLike = (value) => {
    setTextLike(value)
  }

  const handleShowModal = () => {
    setVisible(true)
  }

  const handleShowModalLike = () => {
    setVisibleLike(true)
  }

  return (
    <div className={classes.box}>
      <span className={classes.boxTitle}>{title}</span>
      {image}
      {content}
      <div className={classes.actions}>
        <button
          onClick={handleShowModalLike}
          className={
            sentFeedbackSections[section] === 'like' && classes.selected
          }>
          <ThumbUpFilledSVG />
        </button>
        <button
          onClick={handleShowModal}
          className={
            sentFeedbackSections[section] === 'dislike' && classes.selected
          }>
          <ThumbDownFilledSVG />
        </button>
      </div>
      <Modal
        closeIconVisible={false}
        forceDarkMode={true}
        visible={visible}
        onCancel={handleCancel}
        okText="Submit"
        onOk={() => handleThumbDown(text)}
        size="md">
        <p className="mb-4">Thanks for your valuable feedback!</p>
        <Input
          placeholder="What can we do better? (optional)"
          ref={textRef}
          value={text}
          onChange={handleChange}
          multiline
        />
      </Modal>
      <Modal
        closeIconVisible={false}
        forceDarkMode={true}
        visible={visibleLike}
        okText="Submit"
        onOk={() => handleThumbUp(textLike)}
        onCancel={handleCancelLike}
        size="md">
        <p className="mb-4">Thanks for your valuable feedback!</p>
        <Input
          placeholder="Is there anything else you would like to share? (optional)"
          ref={textLikeRef}
          value={textLike}
          onChange={handleChangeLike}
          multiline
        />
      </Modal>
    </div>
  )
}
