// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".support_new__b0_DY {\n  font-weight: 700;\n}\n\n.support_new__b0_DY .support_subject__SXQtS {\n  font-weight: 700;\n  color: var(--secondary);\n}\n\n.support_loading__1j0on {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  font-size: 1.125rem;\n  line-height: 1.75rem;\n  opacity: 0.5;\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n}\n\n.support_archive__1gTqW {\n  /*@apply text-neutral;*/\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/support/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,gBAAmB;ADAnB;;AAEA;ECFA,gBAAmB;EAAnB,uBAAmB;ADInB;;AAEA;ECNA,aAAmB;EAAnB,mBAAmB;EAAnB,uBAAmB;EAAnB,YAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,YAAmB;EAAnB,kBAAmB;EAAnB,QAAmB;EAAnB,SAAmB;EAAnB,WAAmB;ADQnB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".new {\n  @apply font-bold;\n}\n\n.new .subject {\n  @apply font-bold text-secondary;\n}\n\n.loading {\n  @apply absolute flex h-full items-center justify-center left-0 opacity-50 text-lg top-0 w-full;\n}\n\n.archive {\n  /*@apply text-neutral;*/\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"new": "support_new__b0_DY",
	"subject": "support_subject__SXQtS",
	"loading": "support_loading__1j0on",
	"archive": "support_archive__1gTqW"
};
export default ___CSS_LOADER_EXPORT___;
