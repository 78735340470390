import { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import cookie from 'core/cookie'
import env from 'core/env'
import createQueryClient from 'core/react-query/createQueryClient'
import Provider from 'core/react-query/QueryClientProvider'
import TwoFactorAuthenticationModal from './TwoFactorAuthenticationModal'

dayjs.extend(relativeTime)

export default function AppWrapper({ children }) {
  const [verifyModalOptions, setVerifyModalOptions] = useState(null)
  const queryClient = useMemo(() => {
    return createQueryClient({
      apiEndpoint: env('API_URL'),
      middleware: (response, { url, retry }) => {
        const { json, status } = response

        if (json?.auth_key) {
          window.localStorage.setItem('auth_key', json?.auth_key)
        }

        if (status === 403 && json?.code === '2fa.require') {
          return new Promise((resolve, reject) => {
            setVerifyModalOptions({
              app: json?.errors.app,
              email: json?.errors.email,
              onAuth: async (verifyResponse) => {
                setVerifyModalOptions(null)
                if (url === '/login') {
                  resolve(verifyResponse)
                  return
                }
                try {
                  const retriedResponse = await retry()
                  resolve(retriedResponse)
                } catch (err) {
                  reject(err)
                }
              },
              onCancel: () => {
                setVerifyModalOptions(null)
                reject({
                  json: {},
                })
              },
            })
          })
        }

        return Promise.resolve(response)
      },
      headers: () => {
        const headers = {
          Authorization: `Bearer ${cookie().get('jwt')}`,
        }
        if (window.localStorage.getItem('auth_key')) {
          headers['tc-auth-key'] = window.localStorage.getItem('auth_key')
        }
        return headers
      },
      // defaultOptions: {
      //   queries: {
      //     refetchInterval: true,
      //     refetchIntervalInBackground: true,
      //     refetchOnReconnect: true,
      //     refetchOnWindowFocus: true,
      //     cacheTime: 5 * 60 * 1000, // 5 minutes
      //     staleTime: 5 * 60 * 1000, // 5 minutes
      //   },
      // },
    })
  }, [])

  return (
    <Provider client={queryClient}>
      {children}
      <TwoFactorAuthenticationModal
        app={verifyModalOptions?.app}
        email={verifyModalOptions?.email}
        onAuth={verifyModalOptions?.onAuth}
        onCancel={verifyModalOptions?.onCancel}
        visible={!!verifyModalOptions}
      />
    </Provider>
  )
}
