import { useState } from 'react'
import Modal from 'shared/components/kit/modal'
import { ReactComponent as ErrorSVG } from 'icons/error-circle-filled.svg'
import ChoosePositionInput from './ChoosePositionInput'
import PositionIcon from './PositionIcon'
import useTimelineActions from '../hooks/useTimelineActions'

export default function OverlayLeftActions({ error, localId, position, type }) {
  const { updateOverlay } = useTimelineActions()

  const handleChangePosition = (nextPosition) => {
    updateOverlay(localId, (prev) => ({
      ...prev,
      position: nextPosition,
    }))
  }

  return (
    <div className="relative">
      <h4>
        {type}
        {error && (
          <ErrorSVG className="absolute inline h-4 fill-current -left-5 text-red-500 w-4" />
        )}
      </h4>
      <ChangeOverlayPositionButton
        onChange={handleChangePosition}
        value={position}
      />
    </div>
  )
}

function ChangeOverlayPositionButton({ onChange, value }) {
  const [visible, setVisible] = useState(false)

  const handleChange = (position) => {
    onChange(position)
    setVisible(false)
  }

  return (
    <>
      <PositionIcon
        onClick={() => setVisible(true)}
        position={value}
        role="button"
      />

      <Modal
        onCancel={() => setVisible(false)}
        footer={null}
        size="md"
        title="Change position"
        visible={visible}>
        <ChoosePositionInput onChange={handleChange} value={value} />
      </Modal>
    </>
  )
}
