import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import get from 'lodash/get'
import dayjs from 'dayjs'
import Button from 'shared/components/kit/button'
import Page from 'admin/components/page'
import BannerAdsCampaignStatus from 'admin/components/banner-ads-campaign-status'
import CreateCustomerModal from './CreateCustomerModal'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import useQuery from 'core/react-query/useQuery'
import notification from 'shared/components/kit/notification'
import createAdsCampaign from 'admin/queries/createAdsCampaign'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import getAdsCampaignDetails from 'admin/queries/getAdsCampaignDetails'
import editAdsCampaign from 'admin/queries/editAdsCampaign'
import deleteAdsCampaign from 'admin/queries/deleteAdsCampaign'
import Modal from 'shared/components/kit/modal'
import SelectCompanySection from './SelectCompanySection'
import classes from './BannerAdsCreateCampaign.module.css'

export default function BannerAdsCreateCampaign() {
  const { campaignID } = useParams()
  const isEditMode = typeof campaignID !== 'undefined'
  const history = useHistory()
  const [visibleCustomerModal, setVisibleCustomerModal] = useState(false)
  const [step, setStep] = useState('1')
  const [isConfirm, setIsConfirm] = useState(false)
  const [selectedSlots, setSelectedSlots] = useState([])
  const [createdCompanyId, setCreatedCompanyId] = useState()
  const [edited, setEdited] = useState(false)

  const slots = [1, 2, 3, 4, 5]
  const tiers = [
    { index: '1', name: 'Tier1', color: 'green' },
    { index: '2', name: 'Tier2', color: 'blue' },
    { index: '3', name: 'Tier3', color: 'yellow' },
    { index: '4', name: 'Tier4', color: 'purple' },
    { index: '5', name: 'Tier5', color: 'red' },
  ]
  const tiersData = [
    { tier: 'Tier1', date: '2023-04-25T14:54:56.000000Z', prefilled: 1 },
    { tier: 'Tier1', date: '2023-04-26T14:54:56.000000Z', prefilled: 1 },
    { tier: 'Tier1', date: '2023-04-25T14:54:56.000000Z', prefilled: 1 },
    { tier: 'Tier1', date: '2023-04-26T14:54:56.000000Z', prefilled: 1 },
    { tier: 'Tier1', date: '2023-04-27T14:54:56.000000Z', prefilled: 1 },
    { tier: 'Tier2', date: '2023-04-25T14:54:56.000000Z', prefilled: 2 },
    { tier: 'Tier2', date: '2023-04-24T14:54:56.000000Z', prefilled: 2 },
    { tier: 'Tier3', date: '2023-04-27T14:54:56.000000Z', prefilled: 3 },
    { tier: 'Tier3', date: '2023-04-28T14:54:56.000000Z', prefilled: 3 },
    { tier: 'Tier4', date: '2023-04-29T14:54:56.000000Z', prefilled: 4 },
    { tier: 'Tier4', date: '2023-04-28T14:54:56.000000Z', prefilled: 4 },
    { tier: 'Tier5', date: '2023-04-27T14:54:56.000000Z', prefilled: 5 },
    { tier: 'Tier5', date: '2023-04-27T14:54:56.000000Z', prefilled: 5 },
  ]

  const { data, error, isError, isLoading } = useQuery(getAdsCampaignDetails, {
    enabled: isEditMode,
    variables: {
      id: campaignID,
    },
  })

  const campaign = get(data, 'json', {})

  const handleSelectedSlot = (selectedSlotsTier, selectedSlotsDate) => {
    let prefilledCount =
      tiersData.find(
        (currentData) =>
          currentData.tier === selectedSlotsTier &&
          selectedSlotsDate === dayjs(currentData.date).format('YYYY-MM-DD')
      )?.prefilled || 0

    let _selectedSlots = selectedSlots.map((slot) => {
      if (selectedSlotsTier === slot.tier && slot.date === selectedSlotsDate) {
        return {
          ...slot,
          count: slot.count + prefilledCount > 4 ? 0 : slot.count + 1,
        }
      }
      return slot
    })

    if (
      prefilledCount < 5 &&
      !_selectedSlots.find(
        (slot) =>
          selectedSlotsTier === slot.tier && slot.date === selectedSlotsDate
      )
    ) {
      _selectedSlots.push({
        count: 1,
        tier: selectedSlotsTier,
        date: selectedSlotsDate,
      })
    }

    setSelectedSlots(_selectedSlots.filter((slot) => slot.count > 0))
  }

  const deleteMutation = useMutation(deleteAdsCampaign, {
    onSuccess() {
      notification.success({ title: 'Campaign deleted successfully!' })
      history.push(`/admin/ads-manager/banner-ads-campaigns`)
    },
  })

  const createMutation = useMutation(createAdsCampaign, {
    onSuccess(res) {
      if (isConfirm) {
        notification.success({
          title: 'Ads manager campaign confirmed successfully!',
        })
      } else {
        notification.success({
          title: 'Ads manager campaign created successfully!',
        })
      }

      history.push(
        `/admin/ads-manager/banner-ads-campaigns/${res.json.id}/edit`
      )
    },
  })

  const editMutation = useMutation(editAdsCampaign, {
    onSuccess(res) {
      notification.success({
        title: 'Ads manager campaign updated successfully!',
      })
    },
  })

  const form = useMiniForm({
    initialValues: useMemo(() => {
      return {
        id: campaign.id,
        status: campaign.status || 'draft',
        companyId: campaign?.company?.id,
        name: campaign.name,
      }
    }, [campaign.id]),
    onSubmit(values) {
      try {
        if (isEditMode) {
          editMutation.mutate(values)
        } else {
          createMutation.mutate(values)
        }
      } catch (error) {
        throw error
      }
    },
  })

  useEffect(() => {
    if (campaign.name !== form.values.name) {
      setEdited(true)
    } else {
      setEdited(false)
    }
  }, [form])

  useEffect(() => {
    if (createdCompanyId) {
      form.change('companyId', createdCompanyId)
    }
  }, [createdCompanyId])

  const handleDelete = () => {
    Modal.open({
      title: 'Delete Ads Campaign',
      description: 'Are you sure you want to delete this campaign?',
      onOk() {
        deleteMutation.mutate({
          id: campaignID,
        })
      },
    })
  }

  const handlePause = () => {}

  const handleReActiveCampaign = () => {}

  const actions = (
    <>
      {isEditMode && (
        <Button
          onClick={
            form.values.status === 'paused'
              ? handleReActiveCampaign
              : handlePause
          }>
          {form.values.status === 'paused'
            ? 'Re-activate campaign'
            : 'Pause campaign'}
        </Button>
      )}
      <Button onClick={handleDelete} disabled={!isEditMode}>
        Delete campaign
      </Button>
      {step === '3' && <Button secondary>Export to excel</Button>}
    </>
  )
  const title = (
    <>
      <span className={classes.title}>
        {isEditMode ? 'Edit' : 'New'} campaign
      </span>
      <BannerAdsCampaignStatus status={form.values.status} />
    </>
  )

  const changeStep = (step) => {
    setStep(step)
  }

  const handleCancelCustomerModal = () => {
    setVisibleCustomerModal(false)
  }

  const handleConfirm = () => {
    setIsConfirm(true)
    form.submit()
  }

  const handleClearSelection = () => {
    setSelectedSlots([])
  }

  return (
    <Page title={title} actions={actions}>
      <form onSubmit={form.submit}>
        <SelectCompanySection
          form={form}
          isEditMode={isEditMode}
          step={step}
          setVisibleCustomerModal={setVisibleCustomerModal}
        />
        {step === '1' && (
          <Step1
            changeStep={changeStep}
            edited={edited}
            form={form}
            tiersData={tiersData}
            tiers={tiers}
            slots={slots}
            onSelectedSlot={handleSelectedSlot}
            onClearSelection={handleClearSelection}
            selectedSlots={selectedSlots}
          />
        )}
        {step === '2' && (
          <Step2
            changeStep={changeStep}
            form={form}
            selectedSlots={selectedSlots}
          />
        )}
        {step === '3' && (
          <Step3
            changeStep={changeStep}
            form={form}
            onConfirm={handleConfirm}
            isEditMode={isEditMode}
          />
        )}
      </form>
      <CreateCustomerModal
        visible={visibleCustomerModal}
        onCancel={handleCancelCustomerModal}
        setCreatedCompanyId={setCreatedCompanyId}
      />
    </Page>
  )
}
