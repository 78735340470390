import Modal from 'shared/components/kit/modal'
import Button from 'shared/components/kit/button'
import classes from './index.module.css'

export default function MonetizationTiersHowItWorksModal({ visible, onClose }) {
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closeIconVisible={false}
      footer={null}
      size="sm">
      <div className={classes.wrapper}>
        <h2>Monetization tiers</h2>
        <br />
        <section>
          <p>
            Monetization tiers allow you to earn more when monetizing your
            content on Today's Crypto. By holding TCG tokens in your connected
            wallet, you can add a multiplier to your earnings depending on the
            number of tokens you hold. The tiers range from 5 (entry tier) to 1
            (highest tier).
          </p>
          <p>
            Add your ERC20 wallet address under the "Payment Details" tab and
            hold your TCG tokens in that specific wallet.
          </p>
          <p>
            You must have a Tier 5 status or higher to monetize your content on
            Today's Crypto!
          </p>
          <p>Monetization Tiers Earning Multiplier</p>
          <p>
            Tier 5: x1 (Base earnings)
            <br />
            Tier 4: x1,1 (10%)
            <br />
            Tier 3: x1,2 (20%)
            <br />
            Tier 2: x1,3 (30%)
            <br />
            Tier 1: x1,5 (50%)
            <br />
          </p>
          <br />
          <Button secondary block>
            <a
              target="_blank"
              href="https://app.uniswap.org/explore/tokens/polygon/0x98b435147eb5496f2180cf4d0836157e1744e9d0">
              Buy TCG Tokens
            </a>
          </Button>
        </section>
      </div>
    </Modal>
  )
}
