type GetPublishersQueryVariables = {
  page?: number,
  search?: string,
  sort?: string,
  include?: string,
}

export default function getPublishers(
  variables: GetPublishersQueryVariables = {}
) {
  const { page, search, sort, include } = variables
  return {
    name: 'publishers',
    path: '/admin/publishers',
    params: {
      page,
      sort,
      filters: {
        search,
      },
      include,
    },
  }
}
