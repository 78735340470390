import { useState } from 'react'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import useMiniForm from 'core/useMiniForm'
import notification from 'shared/components/kit/notification'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import getLotteries from 'admin/queries/getLotteries'
import winnerPay from 'admin/queries/winnerPay'
import SelectPaymentMethod from 'admin/components/form/select-payment-method'

const validation = {
  paymentMethodId: {
    label: 'Payment Method',
    rule: 'required',
  },
  reference: {
    label: 'Reference',
    rule: 'required',
  },
  amount: {
    label: 'Amount',
    rule: 'required',
  },
}

export default function WinnerPayButton({ winnerId, month }) {
  const [visible, setVisible] = useState()
  const queryClient = useQueryClient()

  const payMutation = useMutation(winnerPay, {
    onSuccess: () => {
      queryClient.invalidateQueries({ query: getLotteries })
      setVisible(false)
      notification.success({
        title: 'Lottery payment registered successfully!',
      })
    },
  })

  const form = useMiniForm({
    resetOnSuccess: true,
    validation,
    async onSubmit(values) {
      try {
        await payMutation.mutateAsync({
          paymentMethodId: values.paymentMethodId,
          reference: values.reference,
          amount: values.amount,
          id: winnerId,
        })
      } catch (error) {
        notification.error({
          title: error.message,
        })
      }
    },
  })

  const handleCancel = () => {
    setVisible(false)
    form.reset()
  }

  const handleOk = () => {
    form.submit()
  }

  const handleShow = () => {
    setVisible(true)
  }

  return (
    <>
      <Button onClick={handleShow} outlined>
        Checkout
      </Button>
      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        loading={payMutation.isLoading}
        okText="Checkout"
        size="sm"
        title={`Checkout ${month}'s Lottery Winners`}
        visible={visible}>
        <form onSubmit={form.submit}>
          <Field
            label="Payment Method"
            {...form.getErrorPropsFor('paymentMethodId')}>
            <SelectPaymentMethod
              placeholder="Select payment method..."
              isClearable={true}
              {...form.getInputPropsFor('paymentMethodId')}
            />
          </Field>
          <Field label="Reference" {...form.getErrorPropsFor('reference')}>
            <Input required {...form.getInputPropsFor('reference')} />
          </Field>
          <Field label="Amount(USD)" {...form.getErrorPropsFor('amount')}>
            <Input
              type="number"
              required
              {...form.getInputPropsFor('amount')}
            />
          </Field>
        </form>
      </Modal>
    </>
  )
}
