type Variables = {
  id: number,
}

export default function deleteTag(variables: Variables = {}) {
  const { id } = variables
  return {
    method: 'DELETE',
    path: `/admin/tags/${id}`,
  }
}
