import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import getProfile from 'shared/queries/getProfile'
import Button from 'shared/components/kit/button'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import Modal from 'shared/components/kit/modal'
import Checkbox from 'shared/components/form/checkbox'
import useProfile from 'shared/hooks/useProfile'
import Page from 'publisher/components/page'
import publisherRequest from 'publisher/mutations/publisherRequest'
import { ReactComponent as InfoIcon } from 'icons/information-icon.svg'
import classes from './index.module.css'

const VALIDATION = {
  channel_name: {
    label: 'Your channel name',
    rule: 'required',
  },
  other: {
    rule: (value, values, validators) => {
      if (values.platform === 'other') {
        return validators.required('Platform', value?.trim())
      }
    },
  },
  platform: {
    label: 'Platform',
    rule: 'required',
  },
}

export default function PublisherRequest() {
  const { user } = useProfile()
  const queryClient = useQueryClient()
  const [readInformation, setReadInformation] = useState(false)
  const [informationVisible, setInformationVisible] = useState(false)
  const otherRef = useRef()

  const { mutateAsync } = useMutation(publisherRequest, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        query: getProfile,
      })
    },
  })

  const form = useMiniForm({
    validation: VALIDATION,
    async onSubmit(values) {
      try {
        await mutateAsync({
          channel_name: values.channel_name,
          platform:
            values.platform !== 'other' ? values.platform : values.other,
        })
        form.reset()
        setInformationVisible(false)
        document.getElementById('platform').selectedIndex = 0
      } catch (error) {
        setInformationVisible(false)
        throw error.json
      }
    },
  })

  useEffect(() => {
    if (form.get('platform') === 'other') {
      otherRef.current.focus()
    }
  }, [form.get('platform')])

  const handleApplyButton = (e) => {
    e.preventDefault()
    if (form.validate(VALIDATION) === true) {
      if (user.is_conversion) {
        setInformationVisible(true)
      } else {
        form.submit()
      }
    }
  }

  const handleCancel = () => {
    setReadInformation(false)
    setInformationVisible(false)
  }

  return (
    <Page
      description={
        <span className="text-lg">
          Awesome that you want to become a publisher! Start by filling in the
          details below and submit your application.
        </span>
      }
      title="Become a Publisher"
      width="xs">
      <form>
        {form.error ? (
          <p className="mb-6 text-errorColor-default">{form.error.message}</p>
        ) : null}
        <Field
          {...form.getErrorPropsFor('channel_name')}
          label="What is your desired channel name?"
          help="This will be your channel name on Today’s Crypto. Once selected no one will be able to use the same name."
          required>
          <Input
            {...form.getInputPropsFor('channel_name')}
            placeholder="Channel name"
            required
          />
        </Field>

        <Field
          {...form.getErrorPropsFor('platform')}
          label="Which platform do you use today?"
          required>
          <HtmlSelect id="platform" {...form.getInputPropsFor('platform')}>
            <option disabled selected value="">
              Select platform
            </option>
            <option value="youtube">YouTube</option>
            <option value="twitch">Twitch</option>
            <option value="tiktok">Tiktok</option>
            <option value="instagram">Instagram</option>
            <option value="other">Other</option>
            <option value="notactive">
              I’m not active on any platform yet!
            </option>
          </HtmlSelect>
        </Field>

        <Field
          className={form.get('platform') === 'other' ? '' : 'hidden'}
          {...form.getErrorPropsFor('other')}
          required
          label="Write the name of the platform">
          <Input ref={otherRef} {...form.getInputPropsFor('other')} />
        </Field>

        <div className={classes.notice}>
          <div>
            <InfoIcon />
          </div>
          <p>
            To avoid scammers and copycats, we review all applications manually.
            Once your channel is approved and good to go, we will reach out to
            you by email.
          </p>
        </div>

        <Button onClick={handleApplyButton}>Apply to Become a Publisher</Button>
      </form>

      <p className="mt-4 text-sm text-neutral">
        By creating a channel on Today’s Crypto, you accept our <br />
        <Link to="/publisher/tos" target="_blank">
          <span className="text-primary">Terms of Service</span>
        </Link>
        &nbsp;and&nbsp;
        <Link to="/publisher/privacy" target="_blank">
          <span className="text-primary">Privacy Policy</span>
        </Link>
        .
      </p>
      <Modal
        size="sm"
        okButtonProps={{
          disabled: !readInformation,
        }}
        submitting={form.submitting}
        visible={informationVisible}
        onOk={form.submit}
        loading={form.submitting}
        okText="Send application"
        cancelText="Cancel application"
        onCancel={handleCancel}
        title="Important notice!">
        <p>
          By submitting your application, you are requesting a conversion of
          your user account to a channel, meaning that any earned loyalty points
          will be lost as the loyalty program is a benefit only available for
          users and not for publishers. If you wish to keep your current user
          account and loyalty point, you will have to register for a publisher
          account with a different email.
          <br />
          The conversion cannot be reversed once completed.
        </p>
        <br />
        <Checkbox
          checked={readInformation}
          description="I have read and understood the information above."
          onChange={(checked) => setReadInformation(checked)}
        />
      </Modal>
    </Page>
  )
}
