import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import getVideo from 'shared/queries/getVideo'
import updateVideoStatus from 'publisher/mutations/updateVideoStatus'
import { ReactComponent as PublishSVG } from 'icons/publish-outlined.svg'

export default function PublishButton({ video }) {
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(updateVideoStatus, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        query: getVideo,
        variables: {
          id: video?.id,
        },
      })
      notification.success({
        title: 'Video published successfully!',
      })
    },
  })

  const publishable =
    video?.channel?.status === 'published' &&
    video?.status === 'draft' &&
    video?.title &&
    video?.thumbnail &&
    video?.category_id &&
    video?.language_id

  const handlePublish = () => {
    Modal.open({
      title: 'Publish Video',
      description: 'Are you sure you want to publish this video?',
      cancelText: 'No',
      okText: 'Yes, Publish Now',
      onOk: async () => {
        try {
          await mutateAsync({
            id: video.id,
            status: 'published',
          })
        } catch (error) {
          notification.error({
            title: error.json.message,
          })
        }
      },
    })
  }

  if (publishable) {
    return (
      <Button onClick={handlePublish} size="sm" block>
        <PublishSVG /> Publish Video
      </Button>
    )
  }

  return null
}
