import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import useQuery from 'core/react-query/useQuery'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import Button from 'shared/components/kit/button'
import getCompanies from 'admin/queries/getCompanies'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import Page from 'admin/components/page'
import Table from 'shared/components/kit/table'
import formatDate from 'helpers/utils/formatDate'
import classes from './index.module.css'

export default function CRM() {
  const FILTER_CONFIG = useMemo(() => ({
    base: '/admin/companies',
    params: {
      page: 'pagination',
    },
  }))
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)
  const actions = (
    <Link to="/admin/companies/create">
      <Button>Create new customer</Button>
    </Link>
  )

  const { data, isFetching, error, isError } = useQuery(getCompanies, {
    keepPreviousData: true,
    variables: {
      page: filters.page,
    },
  })

  const {
    data: campaigns,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  if (isError) {
    return <Page error={error.message} />
  }

  return (
    <Page
      title="Business Customers"
      description={description}
      actions={actions}>
      <Table
        columns={columns}
        data={campaigns}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
    </Page>
  )
}

function getColumns() {
  return [
    {
      className: classes.thumbnail,
      render: (company) => (
        <div>
          <img
            className="rounded-full"
            src={company?.avatar_url}
            alt={company?.name}
          />
        </div>
      ),
    },
    {
      title: 'Company name',
      className: 'w-1/6',
      render: (company) => company.name,
    },
    {
      title: 'Customer ID',
      className: 'w-1/6',
      render: (company) => company.id,
    },
    {
      title: 'Country',
      className: 'w-1/6',
      render: (company) => company.country,
    },
    {
      title: 'Account creation',
      render: (company) => formatDate(company.created_at),
    },
    {
      className: 'w-1/6',
      align: 'right',
      render: (company) => (
        <Button
          component="link"
          size="sm"
          mood="secondary"
          to={`/admin/companies/${company.id}/edit`}>
          Manage
        </Button>
      ),
    },
  ]
}
