import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import useProps from '../hooks/useProps'
import classes from '../index.module.css'

export default function CpmTiers({ setValues, values, error }) {
  const { getInputPropsForByTier, getErrorPropsFor } = useProps({
    setValues,
    values,
    error,
  })

  return (
    <>
      <h3 className={classes.subHeader}>CPM Pricing and discount</h3>
      <div className={classes.cpmTiers}>
        <Field label="CPM Tier 1" {...getErrorPropsFor('tiers_data', 'cpm', 0)}>
          <Input
            {...getInputPropsForByTier('tiersData', 'cpm', 1)}
            placeholder="$ 0.00"
            type="number"
          />
        </Field>
        <Field label="CPM Tier 2" {...getErrorPropsFor('tiers_data', 'cpm', 1)}>
          <Input
            {...getInputPropsForByTier('tiersData', 'cpm', 2)}
            placeholder="$ 0.00"
            type="number"
          />
        </Field>
        <Field label="CPM Tier 3" {...getErrorPropsFor('tiers_data', 'cpm', 2)}>
          <Input
            {...getInputPropsForByTier('tiersData', 'cpm', 3)}
            placeholder="$ 0.00"
            type="number"
          />
        </Field>
        <Field label="CPM Tier 4" {...getErrorPropsFor('tiers_data', 'cpm', 3)}>
          <Input
            {...getInputPropsForByTier('tiersData', 'cpm', 4)}
            placeholder="$ 0.00"
            type="number"
          />
        </Field>
        <Field label="CPM Tier 5" {...getErrorPropsFor('tiers_data', 'cpm', 4)}>
          <Input
            {...getInputPropsForByTier('tiersData', 'cpm', 5)}
            placeholder="$ 0.00"
            type="number"
          />
        </Field>
      </div>
    </>
  )
}
