import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import classnames from 'classnames'
import unmuteIcon from '../images/unmute-icon.png'
import unmuteExtendedIcon from '../images/unmute-extended-icon.png'
import pauseIcon from '../images/pause-icon.png'
import './VideoPlayer.css'
import styles from './VideoPlayer.module.css'

import 'videojs-overlay'
import 'videojs-overlay/dist/videojs-overlay.css'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import VideoPlayerLayer from './VideoPlayerLayer'
import useVideoSubtitles from '../hooks/useVideoSubtitles'

const videoJsOptions = {
  controls: true,
  autoplay: '',
  playsinline: true,
  controlBar: {
    pictureInPictureToggle: false,
  },
}

const overlayClassNames = {
  topLeft: '',
  topRight: '',
  bottomLeft: '',
  bottomRight: '',
}

type Props = {
  id: number,
  onTimeUpdate?: Function,
  url: string,
}

function VideoPlayer(props: Props, ref) {
  const { id, onTimeUpdate, overlays, url } = props

  const { subtitles } = useVideoSubtitles()

  const layers = overlays

  const videoNode = useRef()
  const pauseIconNode = useRef()
  const unmuteIconNode = useRef()
  const unmuteExtendedIconNode = useRef()
  const overlaysSourceRef = useRef()

  const handleTimeUpdate = useCallback(
    (api) => {
      let player = videojs(videoNode.current)

      if (player.muted()) {
        if (player.currentTime() < 7) {
          unmuteExtendedIconNode.current.classList.remove('hidden')
          unmuteIconNode.current.classList.add('hidden')
        } else {
          unmuteExtendedIconNode.current.classList.add('hidden')
          unmuteIconNode.current.classList.remove('hidden')
        }
      } else {
        unmuteExtendedIconNode.current.classList.add('hidden')
        unmuteIconNode.current.classList.add('hidden')
      }

      if (typeof onTimeUpdate === 'function') {
        onTimeUpdate(api)
      }
    },
    [onTimeUpdate]
  )

  useEffect(() => {
    videojs(videoNode.current, videoJsOptions, function () {
      this.on('timeupdate', function () {
        handleTimeUpdate(this)
      })
    })
  }, [handleTimeUpdate, overlays])

  useEffect(() => {
    videojs(videoNode.current).src(url)
  }, [url])

  const refreshOverlays = useCallback(() => {
    const videoJsOverlays = [
      {
        content: pauseIconNode.current,
        class: 'player-pause',
        align: 'center',
        showBackground: false,
        start: 'playing',
        end: 'pause',
      },
      {
        content: unmuteIconNode.current,
        class: 'player-unmute',
        align: 'top-left',
        showBackground: false,
        start: 'playing',
        end: 'pause',
      },
      {
        content: unmuteExtendedIconNode.current,
        class: 'player-unmute-extended',
        align: 'top-left',
        showBackground: false,
        start: 'playing',
        end: 'pause',
      },
    ]

    if (layers) {
      layers.forEach((layer) => {
        const source = overlaysSourceRef.current.querySelector(
          `div[data-id=${layer.id}]`
        )
        videoJsOverlays.push({
          align: layer.position,
          // class: '',
          content: source.innerHTML,
          end: layer.end,
          showBackground: false,
          start: layer.start,
        })
      })
    }
    videojs(videoNode.current).overlay({
      overlays: videoJsOverlays,
    })
  }, [layers])

  useEffect(() => {
    refreshOverlays()
  }, [refreshOverlays])

  useEffect(() => {
    const player = videojs(videoNode.current)
    const oldTracks = player.remoteTextTracks()
    let i = oldTracks.length
    while (i--) {
      player.removeRemoteTextTrack(oldTracks[i])
    }
    subtitles.forEach(function (subtitle) {
      player.addRemoteTextTrack({
        src: subtitle.url,
        srcLang: subtitle.language.code,
        label: subtitle.language.display_name,
      })
    })
  }, [subtitles])

  useImperativeHandle(ref, () => ({
    setCurrentTime: (time) => {
      videojs(videoNode.current).currentTime(time)
    },
  }))

  const handlePause = (event) => {
    event.stopPropagation()
    videojs(videoNode.current).pause()
  }

  const onUnmute = () => {
    videojs(videoNode.current).muted(false)
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={classnames(styles.unmuteIcon, 'hidden')}
        ref={unmuteIconNode}>
        <img src={unmuteIcon} onClick={onUnmute} alt="player unmute icon" />
      </div>

      <div
        className={classnames(styles.unmuteExtendedIcon, 'hidden')}
        ref={unmuteExtendedIconNode}>
        <img
          src={unmuteExtendedIcon}
          onClick={onUnmute}
          alt="player unmute icon"
        />
      </div>

      <div className={styles.pauseIcon} ref={pauseIconNode}>
        <img src={pauseIcon} onClick={handlePause} alt="player pause icon" />
      </div>

      <div ref={overlaysSourceRef} style={{ display: 'none' }}>
        {overlays.map((overlay) => (
          <div data-id={overlay.id} key={overlay.id}>
            <div
              className={classnames(
                'relative',
                overlay.position
                  .split('-')
                  .map((cl) => `${cl}-8`)
                  .join(' ')
              )}>
              <VideoPlayerLayer layer={overlay} onRefresh={refreshOverlays} />
            </div>
          </div>
        ))}
      </div>

      <video
        ref={videoNode}
        className="video-js vjs-show-big-play-button-on-pause object-cover w-full h-full p-0"
        width="100%"
        height="100%"
        loop={false}
      />
    </div>
  )
}

export default forwardRef(VideoPlayer)
