import React, { createElement, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import classnames from 'classnames'
import useClickOutside from 'helpers/hooks/useClickOutside'
import Modal from 'shared/components/kit/modal'
import classes from './index.module.css'

type Props = {
  className?: string,
  content: any,
  onHide: Function,
  placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end',
  visible?: boolean,
}

export default function Popover(props: Props) {
  const { children, className, content, onHide, placement, visible } = props

  const skipRef = useRef()
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)

  useClickOutside({ current: popperElement }, () => {
    if (Modal.modals().getVisibleModalsCount() === 0) {
      if (skipRef.current) {
        skipRef.current = false
      } else {
        onHide()
      }
    }
  })

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
          padding: 16,
        },
      },
    ],
  })

  return (
    <>
      {createElement(children.type, {
        ...children.props,
        onClick(...params) {
          skipRef.current = true
          if (children.props.onClick) {
            children.props.onClick(...params)
          }
        },
        ref: setReferenceElement,
      })}

      {visible && (
        <div
          className={classnames(classes.popover, className)}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}>
          <div className={classes.content}>{content}</div>
          <div
            className={classes.arrow}
            ref={setArrowElement}
            style={styles.arrow}
          />
        </div>
      )}
    </>
  )
}

Popover.defaultProps = {
  placement: 'auto',
}
