import getFileAbsoluteUrl from 'core/getFileAbsoluteUrl'

export default function getVideoThumbnail(video) {
  if (video && video.thumbnail) {
    return getFileAbsoluteUrl(video.thumbnail)
  }

  const num = video && video.id ? (video.id % 6) + 1 : 1
  return `/images/default-video-thumbnail-${num}.jpg`
}
