type EditCampaign = {
  id: number,
  name: string,
  headline?: string,
  description?: string,
  exchangeName: string,
  exchangeMainUrl: string,
  exchangeReferralUrl: string,
  thumbnail?: string,
  status: 'draft' | 'archived' | 'active',
  cryptoCurrencies?: Array<number>,
}
export default function editCampaign(variables: EditCampaign = {}) {
  const {
    id,
    name,
    headline,
    description,
    exchangeName,
    exchangeMainUrl,
    exchangeReferralUrl,
    thumbnail,
    status,
    cryptoCurrencies,
  } = variables
  return {
    method: 'PUT',
    path: `/admin/crypto-campaigns/${id}`,
    params: {
      name,
      headline,
      description,
      exchange_name: exchangeName,
      exchange_main_url: exchangeMainUrl,
      exchange_referral_url: exchangeReferralUrl,
      thumbnail,
      status,
      crypto_currencies: cryptoCurrencies,
    },
  }
}
