import createUrl from 'core/createUrl'

type getNotificationsQueryVariables = {
  page?: number,
}

export default function getNotifications(
  variables: getNotificationsQueryVariables = {}
) {
  const { page } = variables

  return {
    name: 'notifications',
    url: createUrl('/admin/notifications', {
      page,
    }),
  }
}
