import Alert from 'shared/components/kit/alert'
import classes from './index.module.css'

export default function Page(props) {
  const { actions, children, description, error, loading, title } = props

  return (
    <>
      {title || actions ? (
        <header className={classes.header}>
          <div className={classes.text}>
            <h1>{title}</h1>
            {description ? <p>{description}</p> : null}
          </div>
          <div className={classes.actions}>{actions}</div>
        </header>
      ) : null}
      {loading ? <div className={classes.loading}>LOADING...</div> : null}
      {error ? (
        <div className={classes.error}>
          <Alert title={error} type="error" />
        </div>
      ) : null}
      {!loading && !error ? children : null}
    </>
  )
}
