import { useEffect, useRef, useState } from 'react'
import get from 'lodash/get'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import useQuery from 'core/react-query/useQuery'
import Button from 'shared/components/kit/button'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Modal from 'shared/components/kit/modal'
import Page from 'publisher/components/page'
import Summary from './components/Summary'
import Charts from './components/Charts'
import activeReferralPoints from 'publisher/mutations/activeReferralPoints'
import getProfile from 'shared/queries/getProfile'
import notification from 'shared/components/kit/notification'
import useProfile from 'shared/hooks/useProfile'
import getReferralStatistics from 'publisher/queries/getReferralStatistics'
import { ReactComponent as CopySVG } from 'svg/copy.svg'
import { ReactComponent as CheckSVG } from 'icons/check.svg'
import classes from './index.module.css'

export default function InviteEarn() {
  const { user } = useProfile()
  const queryClient = useQueryClient()
  const [visible, setVisible] = useState()
  const [isCopiedCode, setIsCopiedCode] = useState(false)
  const timeoutCodeRef = useRef()
  const [isCopiedLink, setIsCopiedLink] = useState(false)
  const timeoutLinkRef = useRef()

  useEffect(() => {
    if (isCopiedCode) {
      timeoutCodeRef.current = window.setTimeout(() => {
        setIsCopiedCode(false)
      }, 2000)
    }
    return () => {
      if (timeoutCodeRef.current) {
        window.clearTimeout(timeoutCodeRef.current)
      }
    }
  }, [isCopiedCode])

  useEffect(() => {
    if (isCopiedLink) {
      timeoutLinkRef.current = window.setTimeout(() => {
        setIsCopiedLink(false)
      }, 2000)
    }
    return () => {
      if (timeoutLinkRef.current) {
        window.clearTimeout(timeoutLinkRef.current)
      }
    }
  }, [isCopiedLink])

  const { data, isError, isLoading } = useQuery(getReferralStatistics)
  const dataObject = get(data, 'json') || {}

  const mutation = useMutation(activeReferralPoints, {
    onSuccess: () => {
      notification.success({ title: 'Referral points activated for you!' })
      queryClient.invalidateQueries({
        query: getProfile,
      })
    },
    onError: () => {
      notification.error({ title: 'Referral points cannot activated for you!' })
    },
  })

  const handleActive = () => {
    mutation.mutate()
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleCopyCode = () => {
    navigator.clipboard.writeText(dataObject.referral_code)
    setIsCopiedCode(true)
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `https://todayscrypto.com/register?referral_code=${dataObject.referral_code}`
    )
    setIsCopiedLink(true)
  }

  return (
    <Page width="xl" title="Invite & Earn">
      <div className={classes.actions}>
        {!dataObject.monetize_referral_points_is_active && !isLoading && (
          <Button
            disabled={!user.channel.monetization_qualified_at}
            onClick={handleActive}
            size="sm"
            secondary>
            Active & Redeem
          </Button>
        )}
        <Button onClick={() => setVisible(true)} circle size="sm" secondary>
          <span className="lowercase text-xl">i</span>
        </Button>
      </div>
      <Summary
        overview={dataObject?.overview}
        monetizeReferralPointsIsActive={
          dataObject.monetize_referral_points_is_active
        }
        isError={isError}
        isLoading={isLoading}
      />
      <Charts />
      <div className={classes.referral}>
        <div className={classes.code}>
          <Field label="Your unique referral link">
            <div className="flex items-center gap-4">
              <Input
                value={
                  !dataObject.referral_code
                    ? 'Loading referral link for you...'
                    : `https://todayscrypto.com/register?referral_code=${dataObject.referral_code}`
                }
                disabled
              />
              <Button
                mood="neutral"
                square
                disabled={!dataObject.referral_code}
                title="Copy Link"
                onClick={handleCopyLink}>
                {isCopiedLink ? <CheckSVG /> : <CopySVG />}
              </Button>
            </div>
          </Field>
          <Field label="Your unique referral code">
            <div className="flex items-center gap-4">
              <Input
                value={
                  !dataObject.referral_code
                    ? 'Loading referral code for you...'
                    : dataObject.referral_code
                }
                disabled
              />
              <Button
                mood="neutral"
                disabled={!dataObject.referral_code}
                square
                title="Copy Link"
                onClick={handleCopyCode}>
                {isCopiedCode ? <CheckSVG /> : <CopySVG />}
              </Button>
            </div>
          </Field>
        </div>
        <div className={classes.text}>
          Inviting your audience to Today’s Crypto is the easiest and fastest
          way to grow your channel. At the same time, you are contributing to
          building the community around TC which favors everyone on the
          platform. You will be rewarded with 100 extra monetize points for
          every user who signs up with your referral code. In addition, the user
          will also receive 500 extra loyalty points for use later on within the
          platform.
        </div>
      </div>
      <Modal
        size="sm"
        footer={
          <Button mood="neutral" onClick={handleCancel}>
            Close
          </Button>
        }
        closeIconVisible={false}
        title="Activate and redeem"
        visible={visible}
        onCancel={handleCancel}>
        <div className="-my-8">
          <p>
            Once your channel has qualified for monetization you can activate
            and redeem your accumulated points from your referrals, the redeemed
            points will be added to your channel’s points earnings on the month
            of the activation. Post activation, any new points will be included
            in your monthly earnings automatically.
          </p>
        </div>
      </Modal>
    </Page>
  )
}
