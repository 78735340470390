// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ForgotPassword_wrapper__1CwJh {\n  width: 320px;\n}\n\n.ForgotPassword_wrapper__1CwJh .ForgotPassword_logo__McPWx {\n  margin-left: auto;\n  margin-right: auto;\n  margin-bottom: 4rem;\n  width: 12rem;\n}\n\n.ForgotPassword_wrapper__1CwJh h2 {\n  font-size: 1.5rem;\n  line-height: 2rem;\n  margin-bottom: 2rem;\n  text-align: center;\n}\n\n.ForgotPassword_login__2zNXG {\n  border-color: var(--light-1);\n}\n\n.dark .ForgotPassword_login__2zNXG {\n  border-color: var(--dark-1);\n}\n\n.ForgotPassword_login__2zNXG {\n  border-top-width: 1px;\n  margin-top: 2rem;\n  padding-top: 2rem;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/login/components/LoginForm/ForgotPassword.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;ECFA,iBAAmB;EAAnB,kBAAmB;EAAnB,mBAAmB;EAAnB,YAAmB;ADInB;;AAEA;ECNA,iBAAmB;EAAnB,iBAAmB;EAAnB,mBAAmB;EAAnB,kBAAmB;ADQnB;;AAEA;ECVA,4BAAmB;ADYnB;;ACZA;EAAA;AAAmB;;AAAnB;EAAA,qBAAmB;EAAnB,gBAAmB;EAAnB,iBAAmB;EAAnB;AAAmB","sourcesContent":[".wrapper {\n  width: 320px;\n}\n\n.wrapper .logo {\n  @apply mb-16 mx-auto w-48;\n}\n\n.wrapper h2 {\n  @apply mb-8 text-2xl text-center;\n}\n\n.login {\n  @apply border-t border-light-1 mt-8 pt-8 text-center dark:border-dark-1;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ForgotPassword_wrapper__1CwJh",
	"logo": "ForgotPassword_logo__McPWx",
	"login": "ForgotPassword_login__2zNXG",
	"dark": "dark"
};
export default ___CSS_LOADER_EXPORT___;
