type DeleteSubtitleVariables = {
  id: number,
}

export default function deleteSubtitle(variables: DeleteSubtitleVariables) {
  const { id } = variables

  return {
    method: 'DELETE',
    url: `/publisher/subtitles/${id}`,
  }
}
