import { useMemo, useRef } from 'react'
import useQueryClient from 'core/react-query/useQueryClient'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import ImageInput from 'shared/components/form/image'
import notification from 'shared/components/kit/notification'
import Field from 'shared/components/form/field'
import uploadAdSpaces from 'admin/queries/uploadAdSpaces'
import getAdSpaces from 'admin/queries/getAdSpaces'
import Modal from 'shared/components/kit/modal'
import classes from './AdItem.module.css'

export default function AdItem({
  adsKey,
  height,
  src,
  redirectTo,
  square,
  width,
}) {
  const ref = useRef()
  const queryClient = useQueryClient()
  const form = useMiniForm({
    initialValues: useMemo(() => {
      return {
        redirectTo: redirectTo,
        file: src,
      }
    }, [redirectTo, src]),
    resetOnSuccess: true,
    async onSubmit(values) {
      try {
        await mutation.mutateAsync({
          key: adsKey,
          url: values.file,
          redirectTo: values.redirectTo,
        })
      } catch (error) {
        throw error
      }
    },
  })

  const mutation = useMutation(uploadAdSpaces, {
    onSuccess: () => {
      notification.success({
        title: 'Ad uploaded successfully!',
      })
      queryClient.invalidateQueries({
        query: getAdSpaces,
      })
    },
  })

  const deleteMutation = useMutation(uploadAdSpaces, {
    onSuccess: () => {
      notification.success({
        title: 'Ad removed successfully!',
      })
      queryClient.invalidateQueries({
        query: getAdSpaces,
      })
    },
  })

  const handleUpload = (e) => {
    e.preventDefault()
    ref.current.click()
  }

  const handleDelete = (e) => {
    e.preventDefault()
    Modal.open({
      title: 'Delete Ad',
      description: 'Are you sure you want to delete this Ad?',
      okText: 'yes',
      cancelText: 'No',
      onOk: () => {
        deleteMutation.mutate({
          key: adsKey,
        })
        form.change('redirectTo', '')
        form.change('file', '')
      },
    })
  }

  const content = () => {
    if (square) {
      return (
        <div className={classes.wrapperSquare}>
          <form>
            <Field help="Required size: 290 × 250 px">
              <ImageInput
                ref={ref}
                {...form.getInputPropsFor('file')}
                canUpload={true}
                pixels={true}
                height={height}
                width={width}
              />
            </Field>
            <div className={classes.actionButtonsSquare}>
              <Button onClick={handleUpload} secondary size="sm">
                Upload
              </Button>
              <Button
                disabled={!form.getInputPropsFor('file').value}
                mood="neutral"
                onClick={handleDelete}
                size="sm">
                Delete
              </Button>
            </div>
          </form>
        </div>
      )
    }
    return (
      <div className={classes.wrapper}>
        <form>
          <Field help="Recommended size: 2250 × 250 px">
            <ImageInput
              ref={ref}
              {...form.getInputPropsFor('file')}
              canUpload={true}
              pixels={true}
              height={height}
              width={width}
            />
          </Field>
          <div className={classes.actionButtons}>
            <Button onClick={handleUpload} secondary size="sm">
              Upload
            </Button>
            <Button
              disabled={!form.getInputPropsFor('file').value}
              mood="neutral"
              onClick={handleDelete}
              size="sm">
              Delete
            </Button>
          </div>
        </form>
      </div>
    )
  }

  return content()
}
