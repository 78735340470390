type GetPublisherRequestsQueryVariables = {
  page?: number,
  search?: string,
  sort?: string,
  status?: string,
}

export default function getPublisherRequests(
  variables: GetPublisherRequestsQueryVariables = {}
) {
  const { page, search, sort, status } = variables

  return {
    name: 'publisherRequests',
    path: '/admin/publisher-requests',
    params: {
      page,
      sort,
      filters: {
        email: search,
        status: status,
      },
    },
  }
}
