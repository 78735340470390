import React from 'react'
import useUserClaimableTokens from 'shared/hooks/useUserClaimableTokens'
import Spin from 'shared/components/kit/spin'
import classes from './TCGEarningsClaimed.module.css'

export default function TCGEarningsClaimed({ user }) {
  const claimable = useUserClaimableTokens(user.wallet)
  const claimableTokens = claimable.balance ? Number(claimable.balance) : 0
  const spinner = (
    <div className={classes.spinner}>
      <Spin />
    </div>
  )

  return (
    <span>
      {' '}
      {user.token_points_total_amount}/<br />{' '}
      {claimable.isLoading
        ? spinner
        : user.token_points_total_amount -
          user.token_points_locked_amount -
          claimableTokens}{' '}
    </span>
  )
}
