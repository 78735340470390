import { useRef, useState, useMemo, useEffect } from 'react'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/useMutation'
import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Filed from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import getReasons from 'admin/queries/getReasons'
import setReasons from 'admin/queries/setReasons'

const VALIDATION = {
  value: {
    label: 'Reason',
    rule: 'required',
  },
}

export default function UpdateReason({ reasons, reason }) {
  if (reasons === undefined || reasons === null) {
    reasons = []
  }
  const valueRef = useRef()
  const submitButtonRef = useRef()
  const [visible, setVisible] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    valueRef.current.focus()
  }, [visible])

  const mutation = useMutation(setReasons, {
    onSuccess: () => {
      notification.success({
        title: 'Delete video reasons updated successfully!',
      })
      queryClient.invalidateQueries({
        query: getReasons,
        variables: {
          key: 'video_delete_reasons',
        },
      })
    },
  })
  const initialValues = useMemo(
    () => ({
      value: reason.value,
      status: reason.status,
    }),
    [reason.status, reason.value, reason.key]
  )
  const form = useMiniForm({
    initialValues: initialValues,
    validation: VALIDATION,
    resetOnSuccess: false,
    async onSubmit(values) {
      try {
        reasons.filter((rsn) => {
          if (rsn.key === reason.key) {
            rsn.value = values.value
            rsn.status = values.status
          }
          return rsn
        })
        const params = {}
        params.reasons = reasons
        params.key = 'video_delete_reasons'
        await mutation.mutateAsync(params)
        setVisible(false)
      } catch (error) {
        throw error
      }
    },
  })

  const handleCancel = () => {
    setVisible(false)
    form.reset()
  }

  const handleOk = () => {
    submitButtonRef.current.click()
  }

  return (
    <>
      <Button onClick={() => setVisible(true)} outlined secondary size="sm">
        Edit
      </Button>
      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        okText="Save"
        title="Edit delete video reason"
        visible={visible}>
        <form onSubmit={form.submit}>
          <Filed label="Reason" {...form.getErrorPropsFor('value')}>
            <Input
              ref={valueRef}
              required
              placeholder="Write your reason here..."
              {...form.getInputPropsFor('value')}
            />
          </Filed>
          <Filed label="Status" {...form.getErrorPropsFor('status')}>
            <HtmlSelect className="w-full" {...form.getInputPropsFor('status')}>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </HtmlSelect>
          </Filed>
          <button ref={submitButtonRef} hidden type="submit" />
        </form>
      </Modal>
    </>
  )
}
