import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useMemo, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import Page from 'admin/components/page'
import Table from 'shared/components/kit/table'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import formatDate from 'helpers/utils/formatDate'
import getUserName from 'helpers/utils/getUserName'
import getReportedComments from 'admin/queries/getReportedComments'
import Button from 'shared/components/kit/button'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import CommentDetails from 'admin/pages/reports/components/comments/CommentDetails'
import classes from './ReportedComments.module.css'

const INITIAL_FILTERS = {
  page: 1,
}

export default function ReportedComments({ id: commentId }) {
  const [filters, setFilters] = useState(INITIAL_FILTERS)
  const { data, error, isError, isFetching } = useQuery(getReportedComments, {
    keepPreviousData: true,
    variables: filters,
  })

  const {
    data: comments,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)
  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  if (isError) {
    return <Page error={error.message} />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  return (
    <>
      <Page description={description} loading={isFetching} title=" ">
        <Table
          columns={columns}
          data={comments}
          loading={isFetching}
          currentPage={currentPage}
          lastPage={lastPage}
          onPaginate={handlePaginate}
        />
      </Page>
      <CommentDetails commentId={commentId} />
    </>
  )
}

function getColumns() {
  return [
    {
      className: classes.comment,
      title: 'Comment',
      render: (comment) => <>{comment.text}</>,
    },
    {
      title: 'Username',
      render: (comment) => (
        <Link to={`/admin/users?id=${comment.user.id}`}>
          {getUserName(comment.user)}
        </Link>
      ),
    },
    {
      title: 'Video',
      render: (comment) => comment.video.title,
    },
    {
      align: 'center',
      title: 'Reports',
      render: (comment) => comment.reports_count,
    },
    {
      align: 'center',
      title: 'Likes',
      render: (comment) => comment.likes_count,
    },
    {
      align: 'center',
      title: 'Dislikes',
      render: (comment) => comment.dislikes_count,
    },
    {
      align: 'center',
      className: 'w-48',
      title: 'Create Date',
      render: (comment) => formatDate(comment.created_at),
    },
    {
      render: (comment) => (
        <Button
          component="link"
          size="sm"
          to={`/admin/reports/comments/${comment.id}`}
          secondary
          outlined>
          Details
        </Button>
      ),
    },
  ]
}
