type UpdateBannerAdsSettings = {
  tiersData: Array<Object>,
  tiersDiscounts: Array<Object>,
}

export default function updateBannerAdsSettings(
  variables: UpdateBannerAdsSettings = {}
) {
  const { tiersData, tiersDiscounts } = variables
  return {
    path: '/admin/ads/settings',
    method: 'PUT',
    params: {
      tiers_data: tiersData,
      tiers_discounts: tiersDiscounts,
    },
  }
}
