import { useMemo } from 'react'
import dayjs from 'dayjs'
import useQuery from 'core/react-query/useQuery'
import getChannelPerformance from 'publisher/queries/getChannelPerformance'

export default function useChannelMonthlyPerformance() {
  const from = useMemo(
    () => dayjs().subtract(12, 'month').startOf('month').format('YYYY-MM-DD'),
    []
  )
  const to = useMemo(
    () => dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
    []
  )

  const qr = useQuery(getChannelPerformance, {
    usePreviousData: true,
    variables: {
      from,
      to,
      type: 'monthly',
    },
  })

  const data = useMemo(() => {
    if (!qr.data || !qr.data.json) {
      return []
    }

    const result = []

    let date = dayjs(from)

    while (!date.isAfter(to, 'month')) {
      const dateKey = date.format('YYYY-MM')
      if (qr.data.json[dateKey]) {
        result.push(qr.data.json[dateKey])
      } else {
        result.push({
          date: dateKey,
        })
      }
      date = date.add(1, 'month')
    }

    return result
  }, [from, to, qr.data])

  return {
    ...qr,
    data,
  }
}
