import { useEffect, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import get from 'lodash/get'
import Drawer from 'shared/components/kit/drawer'
import Accordion from 'shared/components/kit/accordion'
import getUser from 'admin/queries/getUser'
import MutePublisher from './MutePublisher'
import PublisherStats from './PublisherStats'
import PublisherInfo from './PublisherInfo'
import DeletePublisher from './DeletePublisher'
import HeroMembership from './HeroMembership'
import EditInfo from './EditInfo'
import classes from './ManagePublisherDrawer.module.css'

export default function ManagePublisherDrawer(props) {
  const { userId, onClose, visible } = props

  const [activeName, setActiveName] = useState('')

  const { data, isLoading, error, isError } = useQuery(getUser, {
    enabled: userId > 0,
    variables: {
      id: userId,
    },
  })

  useEffect(() => {
    if (!visible) {
      setActiveName('')
    }
  }, [visible])

  const handleCloseDrawer = () => {
    onClose()
    setActiveName('')
  }

  const handleChangeAccordion = (name) => {
    setActiveName((prevName) => (prevName === name ? '' : name))
  }

  const user = data?.json || { id: userId }

  const userError = get(error, 'json', {})

  return (
    <Drawer
      error={userError}
      isError={isError}
      loading={isLoading}
      onClose={handleCloseDrawer}
      visible={visible}>
      {isLoading === false && isError === false && (
        <>
          <PublisherInfo user={user} />
          <PublisherStats user={user} />
          <Accordion
            activeName={activeName}
            className={classes.actions}
            onChange={handleChangeAccordion}>
            <Accordion.Item name="hodl" title="Hodl Membership">
              <HeroMembership user={user} />
            </Accordion.Item>
            <Accordion.Item name="info" title="Publisher Info">
              <EditInfo user={user} />
            </Accordion.Item>
            <Accordion.Item
              name="mute"
              title={user.is_mute ? 'Unmute Publisher' : 'Mute Publisher'}>
              <MutePublisher user={user} />
            </Accordion.Item>
            <Accordion.Item name="delete" title="Delete Publisher">
              <DeletePublisher user={user} />
            </Accordion.Item>
          </Accordion>
        </>
      )}
    </Drawer>
  )
}
