import Select from 'shared/components/form/select'

export default function SelectCommentDates(props) {
  const timeOptions = [
    {
      label: 'Last Hour',
      value: 'last_hour',
    },
    {
      label: 'Last 24 hours',
      value: 'last_day',
    },
    {
      label: 'Last 7 days',
      value: 'last_week',
    },
    {
      label: 'Last 30 days',
      value: 'last_month',
    },
    {
      label: 'Last 90 days',
      value: 'last_season',
    },
  ]
  return <Select {...props} options={timeOptions} />
}
