import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import SelectCompanies from './SelectCompanies'
import classes from './BannerAdsCreateCampaign.module.css'

export default function SelectCompanySection({
  form,
  isEditMode,
  step,
  setVisibleCustomerModal,
}) {
  return (
    <div className={classes.fieldsInfo}>
      <Field
        help={
          step === '1' &&
          !isEditMode && (
            <span onClick={() => setVisibleCustomerModal(true)}>
              + Create new customer
            </span>
          )
        }
        className={classes.selectCompany}
        label="Company name">
        <SelectCompanies
          isDisabled={step !== '1' || isEditMode}
          {...form.getInputPropsFor('companyId')}
        />
      </Field>
      <Field label="Customer ID">
        <Input disabled {...form.getInputPropsFor('companyId')} />
      </Field>
      <Field className={classes.campaignName} label="Campaign name">
        <Input disabled={step !== '1'} {...form.getInputPropsFor('name')} />
      </Field>
      <Field label="Campaign ID">
        <Input disabled {...form.getInputPropsFor('id')} />
      </Field>
    </div>
  )
}
