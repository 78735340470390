// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "form.Fitlers_filters__23JuH {\n  display: flex;\n  margin-bottom: 2rem;\n}\n\nform.Fitlers_filters__23JuH input {\n  margin-right: 1rem;\n  width: 24rem;\n}\n\nform.Fitlers_filters__23JuH .Fitlers_select__1kDj6 {\n  margin-right: 1rem;\n  width: 15rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/emails-list/components/Fitlers.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,mBAAmB;ADAnB;;AAEA;ECFA,kBAAmB;EAAnB,YAAmB;ADInB;;AAEA;ECNA,kBAAmB;EAAnB,YAAmB;ADQnB","sourcesContent":["form.filters {\n  @apply flex mb-8;\n}\n\nform.filters input {\n  @apply mr-4 w-96;\n}\n\nform.filters .select {\n  @apply mr-4 w-60;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": "Fitlers_filters__23JuH",
	"select": "Fitlers_select__1kDj6"
};
export default ___CSS_LOADER_EXPORT___;
