import useTokenPointsOverview from './useTokenPointsOverview'
import useUserClaimableTokens from './useUserClaimableTokens'
import useUserWalletAssets from './useUserWalletAssets'
import useProfile from './useProfile'

export default function useTcgTokenData() {
  const {
    totalTokensDistributed,
    userLockedTokens,
    userTotalTokens,
    isLoading: tokenPointsOverviewIsLoading,
  } = useTokenPointsOverview()

  const { data } = useProfile()

  const wallet_address = data.json?.eth_address

  const claimable = useUserClaimableTokens(wallet_address)
  const wallet = useUserWalletAssets(wallet_address)
  const myHoldings = Number(wallet.data?.tcg || 0)
  const claimableTokens = claimable.balance ? Number(claimable.balance) : 0
  const userHoldingTokens = myHoldings + claimableTokens + userLockedTokens

  return {
    myHoldings,
    claimableTokens,
    userHoldingTokens,
    totalTokensDistributed,
    userTotalEarningsTokens: userTotalTokens,
    userLockedTokens,
    tokenPointsOverviewIsLoading,
    claimableIsLoading: claimable.isLoading,
    walletIsLoading: wallet.isLoading,
    isQualified: userTotalTokens !== userLockedTokens,
  }
}
