// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".field_container__c8lCY {\n  margin-bottom: 1.5rem;\n}\n\n.field_container__c8lCY .field_label__2WrL0 {\n  display: block;\n  font-weight: 400;\n  line-height: 1rem;\n  margin-bottom: 0.5rem;\n  opacity: 0.75;\n  width: 100%;\n}\n\n.field_container__c8lCY .field_label__2WrL0 .field_required__1rAwp {\n  font-weight: 700;\n  --tw-text-opacity: 1;\n  color: rgba(220, 38, 38, var(--tw-text-opacity));\n}\n\n.field_container__c8lCY .field_error__2h3ET {\n  cursor: pointer;\n  display: block;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  margin-bottom: 0.5rem;\n  margin-top: -0.25rem;\n  --tw-text-opacity: 1;\n  color: rgba(248, 113, 113, var(--tw-text-opacity));\n  width: 100%;\n}\n\n.field_container__c8lCY .field_help__3DwLO {\n  cursor: pointer;\n  display: block;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  margin-top: 0.5rem;\n  color: var(--neutral);\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/form/field/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,qBAAmB;ADAnB;;AAEA;ECFA,cAAmB;EAAnB,gBAAmB;EAAnB,iBAAmB;EAAnB,qBAAmB;EAAnB,aAAmB;EAAnB,WAAmB;ADInB;;AAEA;ECNA,gBAAmB;EAAnB,oBAAmB;EAAnB,gDAAmB;ADQnB;;AAEA;ECVA,eAAmB;EAAnB,cAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,qBAAmB;EAAnB,oBAAmB;EAAnB,oBAAmB;EAAnB,kDAAmB;EAAnB,WAAmB;ADYnB;;AAEA;ECdA,eAAmB;EAAnB,cAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,kBAAmB;EAAnB,qBAAmB;EAAnB,WAAmB;ADgBnB","sourcesContent":[".container {\n  @apply mb-6;\n}\n\n.container .label {\n  @apply block font-normal leading-4 mb-2 opacity-75 w-full;\n}\n\n.container .label .required {\n  @apply font-bold text-red-600;\n}\n\n.container .error {\n  @apply block cursor-pointer mb-2 -mt-1 text-red-400 text-sm w-full;\n}\n\n.container .help {\n  @apply block cursor-pointer mt-2 text-neutral text-sm w-full;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "field_container__c8lCY",
	"label": "field_label__2WrL0",
	"required": "field_required__1rAwp",
	"error": "field_error__2h3ET",
	"help": "field_help__3DwLO"
};
export default ___CSS_LOADER_EXPORT___;
