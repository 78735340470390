type RequestPasswordResetVariables = {
  email: string,
  scope: string,
  captcha: string,
  captchaKey: string,
}

export default function requestPasswordReset(
  variables: RequestPasswordResetVariables
) {
  const { email, scope, captcha, captchaKey } = variables

  return {
    path: '/password/send',
    params: {
      email,
      scope,
      captcha,
      captcha_key: captchaKey,
    },
  }
}
