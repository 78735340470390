type notifyTypeVariables = {
  email: string,
  location: string,
}

export default function notify(variables: notifyTypeVariables = {}) {
  const { email, location } = variables
  return {
    method: 'POST',
    path: '/mail-list',
    params: {
      email,
      location,
    },
  }
}
