// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportedVideos_thumbnail__3hts7 img {\n  height: 45px;\n  width: 80px;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/reports/components/videos/ReportedVideos.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".thumbnail img {\n  height: 45px;\n  width: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnail": "ReportedVideos_thumbnail__3hts7"
};
export default ___CSS_LOADER_EXPORT___;
