import { useHistory, useParams } from 'react-router'
import { useMemo } from 'react'
import get from 'lodash/get'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import useQuery from 'core/react-query/useQuery'
import RichText from 'shared/components/form/rich-text'
import CampaignStatus from 'admin/components/campaign-status'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Spin from 'shared/components/kit/spin'
import Index from 'admin/components/form/campaign-image'
import SelectCampaignStatus from 'shared/components/form/select-campaign-status'
import SelectCryptocurrency from 'shared/components/form/select-cryptocurrency'
import Button from 'shared/components/kit/button'
import Tabs from 'shared/components/kit/tabs'
import Page from 'admin/components/page'
import Icon from 'shared/components/kit/icon'
import { ReactComponent as CloseSVG } from 'svg/close-icon.svg'
import getCampaignDetails from 'admin/queries/getCampaignDetails'
import editCampaign from 'admin/queries/editCampaign'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import deleteCampaign from 'admin/queries/deleteCampaign'
import Statistics from '../statistics/Statistics'
import classes from './EditCampaign.module.css'

const VALIDATION = {
  exchangeReferralUrl: {
    label: 'Exchange referral url',
    rule: 'required',
  },
  exchangeMainUrl: {
    label: 'Exchange main url',
    rule: 'required',
  },
  exchangeName: {
    label: 'Exchange Name',
    rule: 'required',
  },
  status: {
    label: 'Status',
    rule: 'required',
  },
  name: {
    label: 'Name',
    rule: 'required',
  },
  cryptoCurrencies: {
    label: 'CryptoCurrencies',
    rule: (value) => {
      if (value.length === 0) {
        return 'CryptoCurrencies is required.'
      }
    },
  },
}

export default function EditCampaign() {
  const history = useHistory()
  const { activeTab, campaignId } = useParams()
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(getCampaignDetails, {
    variables: {
      campaignId,
    },
  })

  const campaign = get(data, 'json', {})

  const deleteMutation = useMutation(deleteCampaign, {
    onSuccess() {
      notification.success({ title: 'Campaign deleted successfully!' })
      history.push(`/admin/ads-manager/campaigns`)
    },
  })

  const mutation = useMutation(editCampaign, {
    onSuccess() {
      queryClient.invalidateQueries({
        query: getCampaignDetails,
        variables: {
          campaignId,
        },
      })
      notification.success({ title: 'Campaign updated successfully!' })
    },
  })

  const form = useMiniForm({
    validation: VALIDATION,
    initialValues: useMemo(() => {
      return {
        id: campaign.id,
        name: campaign.name,
        headline: campaign.headline,
        description: campaign.description,
        exchangeName: campaign.exchange_name,
        exchangeMainUrl: campaign.exchange_main_url,
        exchangeReferralUrl: campaign.exchange_referral_url,
        thumbnail: campaign.thumbnail,
        status: campaign.status,
        cryptoCurrencies: get(campaign, 'crypto_currencies', []).map(
          (currency) => ({
            data: currency,
            label: currency.name,
            value: currency.id,
          })
        ),
      }
    }, [campaignId, isLoading]),
    onSubmit(values) {
      let cryptoCurrencies = values.cryptoCurrencies.map((item) => item.value)
      mutation.mutate({ ...values, cryptoCurrencies })
    },
  })

  const removeCoin = (coin) => {
    let coins = form.values.cryptoCurrencies.filter(
      (item) => item.value !== coin.value
    )
    form.change('cryptoCurrencies', coins)
  }

  const handleTabChange = (val) => {
    history.push(`/admin/ads-manager/campaigns/${campaignId}/${val}`)
  }

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <Icon>
          <Spin />
        </Icon>
        <div>Loading...</div>
      </div>
    )
  }

  const title = (
    <>
      <span className="mr-4">Edit campaign: {campaign.name} </span>
      <CampaignStatus status={campaign.status} />
    </>
  )

  const handleDelete = () => {
    Modal.open({
      title: 'Delete Campaign',
      description: 'Are you sure you want to delete this campaign?',
      onOk() {
        deleteMutation.mutate({
          id: campaignId,
        })
      },
    })
  }

  return (
    <Tabs activeName={activeTab} onTabChange={handleTabChange}>
      <Tabs.Tab title="Edit" name="edit">
        <Page title={title}>
          <form onSubmit={form.submit}>
            <div className={classes.container}>
              <div className={classes.name}>
                <Field
                  label="Campaign name (Internal)"
                  {...form.getErrorPropsFor('name')}>
                  <Input {...form.getInputPropsFor('name')} />
                </Field>
              </div>
              <div className={classes.id}>
                <Field label="Campaign ID">
                  <Input {...form.getInputPropsFor('id')} disabled />
                </Field>
              </div>
              <div className={classes.img}>
                <Field label="Image">
                  <Index
                    className="w-48"
                    {...form.getInputPropsFor('thumbnail')}
                    placeholder="Choose a Picture"
                  />
                </Field>
              </div>
              <div className={classes.exchangeName}>
                <Field
                  label="Exchange name"
                  {...form.getErrorPropsFor('exchangeName')}>
                  <Input {...form.getInputPropsFor('exchangeName')} />
                </Field>
              </div>
              <div className={classes.crypto}>
                <Field
                  {...form.getErrorPropsFor('cryptoCurrencies')}
                  label="Add or remove cryptocurrencies for this campaign">
                  <ul>
                    {form?.values?.cryptoCurrencies?.map((coin, key) => {
                      const handleRemove = (event) => {
                        event.preventDefault()
                        removeCoin(coin)
                      }
                      return (
                        <li key={key}>
                          <span>{coin.label}</span>
                          <button onClick={handleRemove}>
                            <CloseSVG />
                          </button>
                        </li>
                      )
                    })}
                  </ul>
                  <SelectCryptocurrency
                    {...form.getInputPropsFor('cryptoCurrencies')}
                    controlShouldRenderValue={false}
                    isClearable={false}
                  />
                </Field>
              </div>
              <div className={classes.headline}>
                <Field
                  label="Campaign headline"
                  {...form.getErrorPropsFor('headline')}>
                  <Input {...form.getInputPropsFor('headline')} />
                </Field>
              </div>
              <div className={classes.exchangeUrl}>
                <Field
                  label="Main exchange URL (for display only)"
                  {...form.getErrorPropsFor('exchangeMainUrl')}>
                  <Input {...form.getInputPropsFor('exchangeMainUrl')} />
                </Field>
              </div>
              <div className={classes.status}>
                <Field label="Change status">
                  <SelectCampaignStatus {...form.getInputPropsFor('status')} />
                </Field>
              </div>
              <div className={classes.referralUrl}>
                <Field
                  label="Referral URL (redirect URL)"
                  {...form.getErrorPropsFor('exchangeReferralUrl')}>
                  <Input
                    type="url"
                    {...form.getInputPropsFor('exchangeReferralUrl')}
                  />
                </Field>
              </div>
              <div className={classes.description}>
                <Field
                  label="Campaign description"
                  {...form.getErrorPropsFor('description')}>
                  <RichText {...form.getInputPropsFor('description')} />
                </Field>
              </div>
              <div className={classes.actionButtons}>
                <div className={classes.actionButtons}>
                  <Button
                    type="button"
                    onClick={handleDelete}
                    transparent
                    mood="neutral">
                    Delete campaign
                  </Button>
                  <Button>Save changes</Button>
                </div>
              </div>
            </div>
          </form>
        </Page>
      </Tabs.Tab>
      <Tabs.Tab title="Statistics" name="statistics">
        <Statistics campaign={campaign} />
      </Tabs.Tab>
    </Tabs>
  )
}
