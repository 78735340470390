import { useMemo, useState } from 'react'
import { useParams } from 'react-router'
import useQuery from 'core/react-query/useQuery'
import formatDate from 'helpers/utils/formatDate'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import getUserName from 'helpers/utils/getUserName'
import Button from 'shared/components/kit/button'
import Table from 'shared/components/kit/table'
import ChannelAvatar from 'shared/components/channel-avatar'
import UserAvatar from 'shared/components/user-avatar'
import Page from 'admin/components/page'
import getMessages from 'admin/queries/getMessages'
import Message from './components/Message'
import AudiencesPopover from './components/AudiencesPopover'
import SendPublisherMessageButton from './components/SendPublisherMessageButton'
import SendUserMessageButton from './components/SendUserMessageButton'
import classes from './index.module.css'

const INITIAL_FILTERS = {
  page: 1,
}

export default function Messages() {
  const { messageId } = useParams()

  const [filters, setFilters] = useState(INITIAL_FILTERS)

  const { data, error, isError, isFetching } = useQuery(getMessages, {
    keepPreviousData: true,
    variables: filters,
  })

  const {
    data: messages,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  if (isError) {
    return <Page error={error.message} />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  const actions = (
    <>
      <SendPublisherMessageButton />
      <SendUserMessageButton />
    </>
  )

  return (
    <>
      <Page actions={actions} description={description} title="Messages">
        <Table
          columns={columns}
          data={messages}
          loading={isFetching}
          currentPage={currentPage}
          lastPage={lastPage}
          onPaginate={handlePaginate}
          rowClassName={rowClassName}
        />
      </Page>
      <Message messageId={messageId} />
    </>
  )
}

function getColumns() {
  return [
    {
      title: 'ID',
      render: (message) => message.id,
    },
    {
      className: 'w-1/6',
      title: 'Subject',
      render: (message) => message.subject,
    },
    {
      className: 'w-1/6',
      title: 'From',
      render: (message) => (
        <div className={classes.fromAvatars}>
          {message.from.channel ? (
            <>
              {' '}
              <ChannelAvatar
                className={classes.channelAvatar}
                channel={message.from.channel}
              />{' '}
              &nbsp; {message.from.channel.name}{' '}
            </>
          ) : (
            <>
              {' '}
              <UserAvatar
                className={classes.avatarImg}
                user={message.from}
              />{' '}
              &nbsp; {getUserName(message.from)}
            </>
          )}
        </div>
      ),
    },
    {
      className: 'w-1/6',
      title: 'To',
      render: (message) =>
        message.user_group === 'publisher' ? (
          'All Publishers'
        ) : message.user_group === 'non-publisher' ? (
          'All Users'
        ) : message.user_group === 'hero' ? (
          'Hero Users'
        ) : message.user_group === 'non-hero' ? (
          'Non-Hero Users'
        ) : (
          <AudiencesPopover message={message} />
        ),
    },
    {
      className: 'w-42',
      title: 'Department',
      render: (message) => message.department.name,
    },
    {
      className: 'w-42',
      title: 'Date',
      render: (message) => formatDate(message.created_at),
    },
    {
      className: 'text-right',
      render: (message) => (
        <Button
          component="link"
          to={`/admin/messages/${message.id}`}
          size="sm"
          secondary
          outlined>
          Open Conversation
        </Button>
      ),
    },
  ]
}

function rowClassName(row) {
  if (row.status === 'new_by_user') {
    return classes.new
  }
}
