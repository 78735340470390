import { useState } from 'react'
import Icon from 'shared/components/kit/icon'
import Popover from 'shared/components/kit/popover'
import { ReactComponent as VerifiedSVG } from 'svg/verify.svg'

export default function PublisherBadge() {
  const [showPublisher, setShowPublisher] = useState(false)

  const handleTogglePublisher = (event) => {
    event.preventDefault()
    setShowPublisher((prev) => !prev)
  }

  const handleHidePublisher = () => {
    setShowPublisher(false)
  }

  return (
    <Popover
      content="User is also a Publisher"
      onHide={handleHidePublisher}
      visible={showPublisher}>
      <a
        onMouseEnter={handleTogglePublisher}
        onMouseLeave={handleHidePublisher}>
        <Icon>
          <VerifiedSVG />
        </Icon>
      </a>
    </Popover>
  )
}
