import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import getTokenPointsOverview from 'publisher/queries/getTokenPointsOverview'

export default function useTokenPointsOverview() {
  const qr = useQuery(getTokenPointsOverview)
  const overview = get(qr.data, 'json', {})
  return {
    userTotalTokens: !qr.isLoading ? overview.user_total_tokens : 0,
    userLockedTokens: !qr.isLoading ? overview.user_locked_tokens : 0,
    totalTokensDistributed: !qr.isLoading
      ? overview.total_tokens_distributed
      : 0,
    tcgDistributedPercent: (!qr.isLoading
      ? (100 * overview.total_tokens_distributed) / overview.total_tokens
      : 0
    ).toFixed(2),
    totalTokens: !qr.isLoading ? overview.total_tokens : 0,
    today_tokens_distributed: !qr.isLoading
      ? overview.today_tokens_distributed
      : 0,
    isLoading: qr.isLoading,
    isQualified: !qr.isLoading
      ? overview.user_total_tokens
      : 0 !== !qr.isLoading
      ? overview.user_locked_tokens
      : 0,
  }
}
