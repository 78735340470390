import { useParams } from 'react-router'
import Page from 'publisher/components/page'
import useVideo from 'publisher/hooks/useVideo'
import Studio from 'publisher/components/studio'

export default function VideoStudio() {
  const params = useParams()
  const id = Number(params.id)
  const { video } = useVideo(id)

  return (
    <Page>
      <Studio
        chapters={video.chapters || []}
        duration={video.duration}
        id={video.id}
        video={video}
        url={video.url}
      />
    </Page>
  )
}
