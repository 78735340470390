import { useMemo } from 'react'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import Table from 'shared/components/kit/table'
import Button from 'shared/components/kit/button'
import formatDate from 'helpers/utils/formatDate'
import getPlans from 'admin/queries/getPlans'
import Page from 'admin/components/page'
import PlanStatus from 'admin/components/plan-status'
import Plan from './components/Plan'
import CreatePlan from './components/CreatePlan'

export default function Plans() {
  const { data, error, isError, isFetching, isLoading } = useQuery(getPlans, {
    keepPreviousData: true,
  })

  const plans = get(data, 'json', [])
  const columns = useMemo(() => getColumns(), [])

  if (isError) {
    return <Page error={error.message} />
  }
  const actions = <CreatePlan />
  return (
    <>
      <Page actions={actions} title="Membership Plans">
        <Table columns={columns} data={plans} loading={isFetching} />
      </Page>
      <Plan />
    </>
  )
}

function getColumns() {
  return [
    {
      title: 'Plan Name',
      render: (plan) => plan.name,
    },
    {
      title: 'Interval',
      render: (plan) => plan.interval,
    },
    {
      title: 'Status',
      render: (plan) => <PlanStatus status={plan.status} />,
    },
    {
      className: 'w-42',
      title: 'Date',
      render: (plan) => formatDate(plan.created_at),
    },
    {
      className: 'text-right',
      render: (plan) => (
        <Button
          component="link"
          to={`/admin/plans/${plan.id}`}
          size="sm"
          secondary
          outlined>
          Edit
        </Button>
      ),
    },
  ]
}
