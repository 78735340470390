import { useMemo, useState } from 'react'
import { useParams } from 'react-router'
import useQuery from 'core/react-query/useQuery'
import Table from 'shared/components/kit/table'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import formatDate from 'helpers/utils/formatDate'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import getPublishersPoAHistory from 'admin/queries/getPublishersPoAHistory'
import Page from 'admin/components/page'

const INITIAL_FILTERS = {
  page: 1,
}

export default function AddressHistory() {
  const [filters, setFilters] = useState(INITIAL_FILTERS)
  const params = useParams()

  const channelId = Number(params.id)

  const { data, error, isError, isFetching } = useQuery(
    getPublishersPoAHistory,
    {
      keepPreviousData: true,
      variables: {
        channelId,
      },
    }
  )

  const {
    data: paymentDetailsHistory,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  if (isError) {
    return <Page error={error.message} />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  return (
    <Page description={description} title="Address history">
      <Table
        columns={columns}
        data={paymentDetailsHistory}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
    </Page>
  )
}

function getColumns() {
  return [
    {
      title: 'VAT number',
      render: (history) => history.vat_number,
    },
    {
      title: 'ETH address',
      render: (history) => history.eth_address,
    },
    {
      title: 'Address',
      render: (history) => (
        <>
          {history.first_name} {history.last_name}
          <br /> {history.company_name} <br /> {history.street_address}{' '}
          {history.street_number}
          <br /> {history.postal_code} {history.city} <br /> {history.country}
        </>
      ),
    },
    {
      title: 'Code sent date',
      render: (history) => formatDate(history.code_sent_at),
    },
  ]
}
