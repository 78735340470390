import classes from './index.module.css'

export default function InputGroup(props) {
  const { children } = props

  return <div className={classes.wrapper}>{children}</div>
}

InputGroup.Text = ({ children }) => (
  <div className={classes.text}>{children}</div>
)
