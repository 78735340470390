import readableNumber from 'shared/helpers/readableNumber'
import classes from './PublisherStats.module.css'

export default function PublisherStats({ user }) {
  return (
    <ul className={classes.stats}>
      <li>
        <p>
          Total
          <br />
          Likes
        </p>
        <p>{user.liked_videos_count}</p>
      </li>
      <li>
        <p>
          Total
          <br />
          Dislikes
        </p>
        <p>{user.disliked_videos_count}</p>
      </li>
      <li>
        <p>
          Total
          <br />
          Watch Times
        </p>
        <p>{readableNumber((user.watch_time || 0) / 3600)}</p>
      </li>
      <li>
        <p>
          Total
          <br />
          Subscribes
        </p>
        <p>{user.subscribed_channels_count}</p>
      </li>
      <li>
        <p>
          Total
          <br />
          Comments
        </p>
        <p>{user.comments_count}</p>
      </li>
    </ul>
  )
}
