type Variables = {
  month?: string,
}

export default function getTotalDistributedMoney(variables: Variables) {
  return {
    path: `/admin/earnings/total-distributed-money`,
    params: {
      month: variables?.month,
    },
  }
}
