import { useMemo, useState } from 'react'
import useMiniForm from 'core/useMiniForm'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import notification from 'shared/components/kit/notification'
import Modal from 'shared/components/kit/modal'
import Input from 'shared/components/form/input'
import Filed from 'shared/components/form/field'
import Button from 'shared/components/kit/button'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import updateTag from 'admin/queries/updateTag'
import getTags from 'admin/queries/getTags'

const VALIDATION = {
  name: {
    label: 'Tag Name',
    rule: 'required',
  },
  status: {
    label: 'Status',
    rule: 'required',
  },
}
export default function UpdateTagModal({ tag }) {
  const [visible, setVisible] = useState(false)
  const queryClient = useQueryClient()

  const updateTagMutation = useMutation(updateTag, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getTags,
      })
      notification.success({
        title: 'Tag updated successfully!',
      })
    },
    onError: (error) => {
      notification.error({
        title: error.json.errors.name[0],
      })
    },
  })

  const initialValues = useMemo(
    () => ({
      name: tag.name,
      status: tag.status,
    }),
    [tag]
  )

  const form = useMiniForm({
    resetOnSuccess: true,
    validation: VALIDATION,
    initialValues: initialValues,
    onSubmit(values) {
      try {
        setVisible(false)
        updateTagMutation.mutate({
          id: tag.id,
          name: values.name,
          status: values.status,
        })
      } catch (error) {
        throw error
      }
    },
  })

  const handleShow = () => {
    setVisible(true)
  }

  const handleClose = () => {
    setVisible(false)
    form.reset()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    form.submit()
  }

  return (
    <>
      <Button onClick={handleShow} size="sm" secondary outlined>
        Edit
      </Button>
      <Modal
        size="sm"
        onCancel={handleClose}
        onOk={form.submit}
        title="Edit Tag"
        visible={visible}>
        <form onSubmit={handleSubmit}>
          <Filed label="Name" {...form.getErrorPropsFor('name')}>
            <Input {...form.getInputPropsFor('name')} />
          </Filed>
          <Filed label="Status" {...form.getErrorPropsFor('status')}>
            <HtmlSelect className="w-full" {...form.getInputPropsFor('status')}>
              <option value="published">Published</option>
              <option value="delisted">Delisted</option>
            </HtmlSelect>
          </Filed>
        </form>
      </Modal>
    </>
  )
}
