import { useState } from 'react'
import get from 'lodash/get'
import useMutation from 'core/react-query/useMutation'
import useQuery from 'core/react-query/useQuery'
import useMiniForm from 'core/useMiniForm'
import useQueryClient from 'core/useQueryClient'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import Field from 'shared/components/form/field'
import Radio from 'shared/components/form/radio'
import Input from 'shared/components/form/input'
import getReasons from 'shared/queries/getReasons'
import reportComment from 'publisher/queries/reportComment'

export default function ReportButton({ children, commentId }) {
  const [step, setStep] = useState('')
  const { data } = useQuery(getReasons, {
    variables: {
      key: 'comment_report_reasons',
    },
  })

  const reasons = get(data, 'json', [])
  const form = useMiniForm({
    async onSubmit({ custom, reason }) {
      try {
        await mutation.mutateAsync({
          id: commentId,
          reason: reason >= 0 ? reasons[reason] : custom,
        })
        notification.success({
          title: 'Comment has been reported successfully!',
        })
        setStep('')
        form.reset()
      } catch (error) {}
    },
  })

  const queryClient = useQueryClient()

  const mutation = useMutation(reportComment, {
    onSuccess() {
      queryClient.invalidateQueries('comments')
    },
    onError(error) {
      setStep('')
      notification.error({ title: error.json.message })
    },
  })

  const handleShow = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setStep('confirm')
  }

  const handleCancel = () => {
    setStep('')
    form.reset()
  }

  const handleOk = async () => {
    if (step === 'confirm') {
      setStep('reason')
    } else {
      await form.submit()
    }
  }

  const canOk =
    step === 'confirm' ||
    form.get('reason') >= 0 ||
    (form.get('reason') === -1 && form.get('custom'))

  return (
    <>
      <button onClick={handleShow}>{children}</button>
      <Modal
        loading={mutation.isLoading}
        okButtonProps={{
          disabled: !canOk,
        }}
        okText="Report Comment"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Report Comment"
        visible={step !== ''}>
        {step === 'confirm' && (
          <p>Are you sure you want to report this comment?</p>
        )}
        {step === 'reason' && (
          <>
            <Field label="What's the reason of reporting this comment?">
              {reasons.map((text, key) => (
                <Radio
                  checked={form.get('reason') === key}
                  onChange={() => form.change('reason', key)}
                  description={text.value}
                  key={key}
                />
              ))}
            </Field>
            {form.get('reason') === -1 && (
              <Field>
                <Input
                  {...form.getInputPropsFor('custom')}
                  placeholder="Add your custom reason here..."
                  multiline
                />
              </Field>
            )}
          </>
        )}
      </Modal>
    </>
  )
}
