import { Link } from 'react-router-dom'
import useQuery from 'core/react-query/useQuery'
import formatDate from 'helpers/utils/formatDate'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import getUserName from 'helpers/utils/getUserName'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import Alert from 'shared/components/kit/alert'
import Button from 'shared/components/kit/button'
import Table from 'shared/components/kit/table'
import UserAvatar from 'shared/components/user-avatar'
import readableNumber from 'shared/helpers/readableNumber'
import PageHeader from 'admin/components/page-header'
import getUsers from 'admin/queries/getUsers'
import AccountStatus from 'admin/components/account-status'
import CreateUserButton from './CreateUserButton'
import PublisherBadge from './PublisherBadge'
import Filters from './Filters'
import MuteBadge from './MuteBadge'
import { ReactComponent as LikeSVG } from 'svg/like-filled.svg'
import { ReactComponent as DislikeSVG } from 'svg/dislike-filled.svg'
import { ReactComponent as CommentsSVG } from 'svg/comments.svg'
import { ReactComponent as GroupSVG } from 'svg/group-filled.svg'
import { ReactComponent as TimeSVG } from 'svg/time-filled.svg'
import { ReactComponent as ReferralSVG } from 'svg/add-friend.svg'
import LoginMethod from './LoginMethod'
import TCGEarningsClaimed from './TCGEarningsClaimed'
import classes from './UsersList.module.css'

const FILTER_CONFIG = {
  base: '/admin/users',
  params: {
    page: 'pagination',
    search: 'any',
    sort: 'any',
    isHero: null,
    loginType: null,
  },
}

export default function UsersList() {
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)

  const { data, error, isError, isFetching } = useQuery(getUsers, {
    keepPreviousData: true,
    variables: {
      page: filters.page,
      search:
        !filters.search?.trim().toLowerCase().startsWith('ref=') &&
        !filters.search?.trim().toLowerCase().startsWith('sub=') &&
        !filters.search?.trim().toLowerCase().startsWith('ref =') &&
        !filters.search?.trim().toLowerCase().startsWith('sub =')
          ? filters.search
          : '',
      ref:
        filters.search?.trim().toLowerCase().startsWith('ref=') ||
        filters.search?.trim().toLowerCase().startsWith('ref =')
          ? filters.search
              .toLowerCase()
              .replaceAll('ref=', '')
              .replaceAll('ref =', '')
          : '',
      sub:
        filters.search?.trim().toLowerCase().startsWith('sub=') ||
        filters.search?.trim().toLowerCase().startsWith('sub =')
          ? filters.search
              .toLowerCase()
              .replaceAll('sub=', '')
              .replaceAll('sub =', '')
          : '',
      sort: filters.sort,
      isHero: filters.isHero,
      loginType: filters.loginType,
    },
  })

  const {
    data: users,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleFilter = (nextFilters) => {
    setFilters({
      ...nextFilters,
      page: undefined,
    })
  }

  if (isError) {
    return <Alert title={error} content={error?.message} type="error" />
  }

  const actions = <CreateUserButton />

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  return (
    <>
      <PageHeader actions={actions} description={description} title="Users" />
      <Filters initialFilters={filters} onSubmit={handleFilter} />
      <Table
        columns={columns(filters)}
        data={users}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
    </>
  )
}

const columns = (filters) => {
  return [
    {
      className: classes.avatar,
      render: (user) => <UserAvatar user={user} />,
    },
    {
      title: 'Username',
      className: 'whitespace-nowrap',
      render: (user) => (
        <>
          <span className="mr-2">{getUserName(user)}</span>
          <span className="inline-flex items-center space-x-3 text-xl">
            {user.role === 'publisher' && <PublisherBadge user={user} />}
            {user.is_mute && <MuteBadge user={user} />}
          </span>
        </>
      ),
    },
    {
      title: 'Login method',
      align: 'center',
      render: (user) => <LoginMethod user={user} />,
    },
    {
      title: (
        <span className="text-center">
          TCG <br /> earnings/claimed
        </span>
      ),
      align: 'center',
      render: (user) => <TCGEarningsClaimed user={user} />,
    },
    {
      title: <LikeSVG fill="#7996b6" width="18" title="Likes" />,
      render: (user) => user.liked_videos_count,
    },
    {
      title: <DislikeSVG fill="#7996b6" width="18" title="Dislikes" />,
      render: (user) => user.disliked_videos_count,
    },
    {
      title: <CommentsSVG fill="#7996b6" width="18" title="Comments" />,
      render: (user) => user.comments_count,
    },
    {
      title: <GroupSVG fill="#7996b6" width="18" title="Subscribes" />,
      render: (user) => user.subscribed_channels_count,
    },
    {
      title: <TimeSVG fill="#7996b6" width="18" title="Watch time" />,
      render: (user) => readableNumber((user.watch_time || 0) / 3600),
    },
    {
      title: <ReferralSVG fill="#7996b6" width="18" title="Referral counts" />,
      render: (user) =>
        user.email ? (
          <Link
            className="text-secondary"
            to={
              `/admin/users?search=Ref%3D${user.email}  ${
                filters.isHero ? `&isHero=${filters.isHero}` : ''
              }` +
              (filters.sort ? `&sort=${filters.sort}` : '') +
              (filters.loginType ? `&loginType=${filters.loginType}` : '')
            }>
            {user.referrals_count}
          </Link>
        ) : (
          user.referrals_count
        ),
    },
    {
      title: 'Last active',
      render: (user) => formatDate(user.last_actived_at),
    },
    {
      align: 'center',
      title: 'Account status',
      render: (user) => (
        <AccountStatus
          status={user.email_verified_at ? 'verified' : 'not_verified'}
        />
      ),
    },
    {
      title: 'Signed Up Date',
      render: (user) => formatDate(user.created_at),
    },
    {
      align: 'right',
      render: (user) => (
        <Button
          to={
            `/admin/users?${
              filters.isHero ? `&isHero=${filters.isHero}` : ''
            }` +
            (filters.loginType > 1 ? `&loginType=${filters.loginType}` : '') +
            (filters.page > 1 ? `&page=${filters.page}` : '') +
            (filters.sort ? `&sort=${filters.sort}` : '') +
            (filters.search ? `&search=${filters.search}` : '') +
            `&id=${user.id}`
          }
          component="link"
          size="sm"
          outlined>
          Manage
        </Button>
      ),
    },
  ]
}
