import { useRef } from 'react'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import Box from 'shared/components/kit/box'
import CaptchaInput from 'shared/components/form/captcha'
import FormError from 'shared/components/form/error'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Button from 'shared/components/kit/button'
import requestPasswordReset from 'shared/queries/requestPasswordReset'
import Result from 'shared/components/kit/result'
import classes from './ForgotPassword.module.css'

const validation = {
  email: {
    label: 'Email',
    rule: 'required',
  },
}

export default function ForgotPassword({ onLogin }) {
  const { isSuccess, mutateAsync } = useMutation(requestPasswordReset)

  const captchaRef = useRef()

  const form = useMiniForm({
    validation,
    async onSubmit(values, event) {
      event.preventDefault()
      try {
        await mutateAsync({
          email: values.email,
          captcha: values.captcha,
          captchaKey: values.captchaKey,
          scope: 'admin',
        })
      } catch (error) {
        captchaRef.current.reload()
        throw error.json
      }
    },
  })

  if (isSuccess) {
    return (
      <Box className={classes.forgot}>
        <Result
          title="Email Sent!"
          status="info"
          description="You will receive an email to reset your password in a few minutes."
          footer={
            <Button onClick={onLogin} block>
              Log In
            </Button>
          }
        />
      </Box>
    )
  }
  return (
    <Box className={classes.forgot}>
      <h2>Reset Password</h2>

      <form onSubmit={form.submit}>
        {form.error ? <FormError>{form.error.message}</FormError> : null}
        <Field {...form.getErrorPropsFor('email')} label="Your email" required>
          <Input
            type="email"
            {...form.getInputPropsFor('email')}
            placeholder="Your email to reset password"
            required
          />
        </Field>
        <Field
          {...form.getErrorPropsFor('captcha')}
          label="Prove that you are human"
          required>
          <CaptchaInput
            ref={captchaRef}
            onLoad={(captcha) => form.change('captchaKey', captcha.key)}
            {...form.getInputPropsFor('captcha')}
            required
          />
        </Field>
        <Button loading={form.submitting} type="submit" block>
          Send Reset Link
        </Button>
      </form>
      <p className={classes.login}>
        <Button onClick={onLogin} block outlined secondary>
          Log In
        </Button>
      </p>
    </Box>
  )
}
