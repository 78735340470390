import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { parse } from 'qs'

export default function useParsedLocation() {
  const location = useLocation()
  return useMemo(
    () => ({
      ...location,
      queryParams: parse(location.search ? location.search.substr(1) : ''),
    }),
    [location]
  )
}
