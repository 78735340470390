import { useMemo } from 'react'
import useQuery from 'core/react-query/useQuery'
import Button from 'shared/components/kit/button'
import Table from 'shared/components/kit/table'
import formatDateOnly from 'helpers/utils/formatDateOnly'
import getMyPlaylists from 'publisher/queries/getMyPlaylists'
import Page from 'publisher/components/page'
import CreatePlaylistButton from './components/CreatePlaylistButton'

export default function Playlists() {
  const { data, error, isError, isLoading } = useQuery(getMyPlaylists)

  const playlists = useMemo(() => data?.json || [], [data?.json])

  const columns = useMemo(() => getColumns(), [])

  if (isError) {
    return <Page error={error.message} />
  }

  const actions = <CreatePlaylistButton />

  return (
    <Page actions={actions} title="My Playlists">
      <Table columns={columns} data={playlists} loading={isLoading} />
    </Page>
  )
}

function getColumns() {
  return [
    {
      title: 'Playlist',
      className: 'w-1/3',
      render: (playlist) => playlist.name,
    },
    {
      title: 'Uploads',
      className: 'w-24',
      render: (playlist) => playlist.all_videos_count,
    },
    {
      title: 'Created',
      render: (playlist) => formatDateOnly(playlist.created_at),
    },
    {
      align: 'right',
      title: '',
      render: (playlist) => (
        <Button
          component="link"
          mood="primary"
          to={`/publisher/playlists/${playlist.id}`}
          transparent>
          Manage Playlist
        </Button>
      ),
    },
  ]
}
