import { useCallback, useEffect, useMemo, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import getMyVideos from 'publisher/queries/getMyVideos'
import formatDateOnly from 'helpers/utils/formatDateOnly'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import VideoStatus from 'shared/components/video-status'
import VideoThumbnail from 'shared/components/video-thumbnail'
import Page from 'publisher/components/page'
import Button from 'shared/components/kit/button'
import Table from 'shared/components/kit/table'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import TruncatedList from 'shared/components/kit/truncated-list'
import BulkDeleteButton from './components/BulkDeleteButton'
import Filters from './components/Filters'
import AddPinnedComment from './components/AddPinnedComment'
import PublishMediaModal from './components/PublishMediaModal'
import YouTubeSyncStatus from './components/YouTubeSyncStatus'
import { ReactComponent as ViewSVG } from 'svg/view-filled.svg'
import { ReactComponent as CommentsSVG } from 'svg/comments.svg'
import { ReactComponent as LikeSVG } from 'svg/like-filled.svg'
import classes from './index.module.css'

export default function MyVideos() {
  const INITIAL_FILTERS = useMemo(
    () => ({
      base: '/publisher/media',
      params: {
        page: 'pagination',
        sort: 'any',
        search: 'any',
        excludePlaylist: 'any',
        excludeVideos: 'any',
        includeCategoryRelation: 'boolean',
        includePlaylistsRelation: 'boolean',
        includeUserRelation: 'boolean',
        playlistId: 'number',
        mediaType: 'string',
      },
    }),
    []
  )

  const [filters, setFilters] = useFiltersFromURL(INITIAL_FILTERS)
  const [selectedRows, setSelectedRows] = useState([])
  const [mediaToPublish, setMediaToPublish] = useState()

  const { data, error, isError, isFetching, refetch } = useQuery(getMyVideos, {
    keepPreviousData: true,
    variables: {
      includeCategoryRelation: true,
      includePlaylistsRelation: true,
      ...filters,
    },
  })

  useEffect(() => {
    setSelectedRows([])
  }, [isFetching])

  const {
    data: videos,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleRowSelect = (keys) => {
    setSelectedRows(keys)
  }

  const handleCancelPublishMedia = useCallback(() => {
    setMediaToPublish(null)
  }, [])

  const bulkActions = (
    <>
      {/*<RemoveFromPlaylistButton videos={selectedRows}/>*/}
      {/*<MoveToPlaylistButton videos={selectedRows}/>*/}
      <AddPinnedComment videos={selectedRows} />
      <BulkDeleteButton videos={selectedRows} />
    </>
  )

  if (isError) {
    return <Page error={error.message} />
  }

  return (
    <Page
      actions={bulkActions}
      description={`Page ${filters.page || 1} - Total Uploads: ${total}`}
      title={
        <>
          My Media &nbsp;
          <YouTubeSyncStatus onProgress={refetch} />
        </>
      }>
      <Filters initialFilters={filters} onSubmit={setFilters} />
      <Table
        columns={getColumns(setMediaToPublish, filters.page)}
        data={videos}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
        onRowSelect={handleRowSelect}
        selectedRows={selectedRows}
      />
      <PublishMediaModal
        media={mediaToPublish}
        onCancel={handleCancelPublishMedia}
      />
    </Page>
  )
}

function getColumns(onPublishRequest, page) {
  return [
    {
      className: classes.thumbnail,
      title: 'Thumbnail',
      render: (video) => <VideoThumbnail video={video} />,
    },
    {
      className: classes.info,
      title: 'Title',
      render: (video) => (
        <>
          {video.title ? (
            video.title
          ) : (
            <span className={classes.untitled}>Untitled</span>
          )}
        </>
      ),
    },
    {
      className: classes.categories,
      title: 'Category',
      render: (video) => video.category?.name || '-',
    },
    {
      title: 'Playlists',
      className: classes.playlists,
      render: (video) => (
        <TruncatedList visible={1}>
          {video.playlists.map((playlist) => (
            <li key={playlist.id} className={classes.playlist}>
              {playlist.name}
            </li>
          ))}
        </TruncatedList>
      ),
    },
    {
      align: 'center',
      className: classes.views,
      title: <ViewSVG fill="#7996b6" width="18" title="View" />,
      render: (video) => video.view_count,
    },
    {
      align: 'center',
      className: classes.comments,
      title: <CommentsSVG fill="#7996b6" width="18" title="Comment" />,
      render: (video) => video.comment_count,
    },
    {
      align: 'center',
      className: classes.rating,
      title: <LikeSVG fill="#7996b6" width="18" title="Like" />,
      render: (video) => video.rating,
    },
    {
      className: classes.status,
      title: 'Status',
      render: (video) => <VideoStatus status={video.status} />,
    },
    {
      className: classes.publishDate,
      title: 'Publish Date',
      render: (video) => {
        if (video.status === 'draft_yi') {
          return (
            <Button
              mood="secondary"
              onClick={() => onPublishRequest(video)}
              size="sm">
              Publish
            </Button>
          )
        }
        if (video.published_at) {
          return formatDateOnly(video.published_at)
        }
        return null
      },
    },
    {
      className: classes.actions,
      render: (video) => (
        <Button
          component="link"
          mood="primary"
          to={`/publisher/media/${video.id}${page > 1 ? `?page=${page}` : ''}`}
          transparent>
          Manage
        </Button>
      ),
    },
  ]
}
