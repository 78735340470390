// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "form.Filters_filters__38b6_ {\n  background-color: var(--light);\n  display: flex;\n  margin-bottom: 1.5rem;\n  margin-top: -1.5rem;\n  margin-right: -1.5rem;\n  margin-left: -1.5rem;\n  padding: 1.5rem;\n  padding-bottom: 0px;\n  position: sticky;\n  top: -1.5rem;\n  z-index: 10;\n}\n\n.dark form.Filters_filters__38b6_ {\n  background-color: var(--dark);\n}\n\nform.Filters_filters__38b6_ input {\n  margin-right: 1rem;\n  width: 24rem;\n}\n\nform.Filters_filters__38b6_ select, form.Filters_filters__38b6_ .Filters_select__8sbp5 {\n  margin-right: 1rem;\n  width: 14rem;\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/choose-video-modal/components/Filters.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,8BAAmB;EAAnB,aAAmB;EAAnB,qBAAmB;EAAnB,mBAAmB;EAAnB,qBAAmB;EAAnB,oBAAmB;EAAnB,eAAmB;EAAnB,mBAAmB;EAAnB,gBAAmB;EAAnB,YAAmB;EAAnB,WAAmB;ADCnB;;ACDA;EAAA;AAAmB;;ADGnB;ECHA,kBAAmB;EAAnB,YAAmB;ADKnB;;ACLA;EAAA,kBAAmB;EAAnB;AAAmB","sourcesContent":["form.filters {\n  @apply bg-light flex mb-6 -ml-6 -mr-6 -mt-6 p-6 pb-0 sticky -top-6 z-10;\n  @apply dark:bg-dark;\n}\n\nform.filters input {\n  @apply mr-4 w-96;\n}\n\nform.filters select,\nform.filters .select {\n  @apply mr-4 w-56;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": "Filters_filters__38b6_",
	"dark": "dark",
	"select": "Filters_select__8sbp5"
};
export default ___CSS_LOADER_EXPORT___;
