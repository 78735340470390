type DeleteVideoMutationVariables = {
  id: number,
}

export default function deleteVideo(variables: DeleteVideoMutationVariables) {
  return {
    method: 'delete',
    url: `/admin/videos/${variables.id}`,
    variables,
  }
}
