type contactTypeVariables = {
  firstName: string,
  lastName: string,
  email: string,
  subject: string,
  message: string,
}

export default function contact(variables: contactTypeVariables = {}) {
  const { firstName, lastName, email, subject, message } = variables
  return {
    method: 'POST',
    path: '/forms/contact-us',
    params: {
      first_name: firstName,
      last_name: lastName,
      email,
      subject,
      message,
    },
  }
}
