import Box from 'shared/components/kit/box'
import Button from 'shared/components/kit/button'

export default function ManagePublisher({ channel }) {
  const { id, user_id } = channel
  return (
    <Box className="p-6">
      <h4 className="mb-2">Manage Publisher Info</h4>
      <p className="h-16 text-overlayColor-mutedText">
        In this area, you can manage this channel's publisher info.
      </p>
      <footer className="text-right">
        <Button
          component="link"
          size="sm"
          to={`/admin/channels/${id}?user-id=${user_id}`}
          outlined
          secondary>
          Manage Publisher Info
        </Button>
      </footer>
    </Box>
  )
}
