type GetCryptoCurrenciesQueryVariables = {
  perPage?: number,
  search?: string,
}

export default function getCryptocurrencies(
  variables: GetCryptoCurrenciesQueryVariables
) {
  const { perPage, search } = variables
  return {
    name: 'cryptocurrencies',
    path: '/cryptocurrencies',
    params: {
      per_page: perPage,
      filters: search
        ? {
            search,
          }
        : undefined,
    },
  }
}
