import { useState } from 'react'
import colors from 'tailwindcss/colors'
import dayjs from 'dayjs'
import VideoUploadsChart from 'shared/components/video-uploads-chart'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import Box from 'shared/components/kit/box'
import Page from 'admin/components/page'
import useChannelStatistics from '../hooks/useChannelStatistics'
import Filters from './Filters'
import classes from '../index.module.css'

const chartedData = (statistics, range) => {
  const getMonths = () => {
    return Object.keys(statistics).map((statistic) => {
      return dayjs(statistic).format('MMM')
    })
  }

  const getUploads = () => {
    return Object.values(statistics).map((statistic) => {
      return Math.floor(statistic.upload_videos_total)
    })
  }

  const getDates = () => {
    if (range === 'this_year') {
      return getMonths()
    } else {
      return Object.keys(statistics).map((stat, i) => {
        return [dayjs(stat).format('DD'), dayjs(stat).format('ddd')]
      })
    }
  }

  return {
    labels: getDates(),
    datasets: [
      {
        label: 'Uploads',
        backgroundColor: colors.yellow['300'],
        borderColor: colors.yellow['300'],
        data: getUploads(),
      },
    ],
  }
}

export default function VideoUploads() {
  const [range, setRange] = useState('this_month')

  const statistics = useChannelStatistics(range)

  if (statistics.isError) {
    return <Page error={statistics.error.message} />
  }

  if (statistics.isLoading) {
    return (
      <Box className={classes.boxLoading}>
        <div className={classes.loading}>
          <Icon>
            <Spin />
          </Icon>
          <div>Loading...</div>
        </div>
      </Box>
    )
  }

  return (
    <VideoUploadsChart
      chartedData={chartedData}
      data={statistics.data}
      Filter={<Filters range={range} onChange={setRange} />}
      range={range}
      onChange={setRange}
      title="Video Uploads"
    />
  )
}
