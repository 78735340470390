import { useMemo } from 'react'
import dayjs from 'dayjs'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import Table from 'shared/components/kit/table'
import Page from 'admin/components/page'
import getTotalDistributedMoney from 'admin/queries/getTotalDistributedMoney'
import EarningsExportExcel from './components/EarningsExportExcel'
import CalculateMonthlyEarning from './components/CalculateMonthlyEarning'
import SetTotalDistributedMoney from './components/SetTotalDistributedMoney'

export default function Earnings() {
  const earnings = []
  const { data, error, isError, isLoading } = useQuery(getTotalDistributedMoney)
  const totalMoneys = get(data, 'json', {})
  for (let i = -1; i < 6; i++) {
    const monthName = dayjs().subtract(i, 'month').format('MMMM')
    const month = dayjs().subtract(i, 'month').format('YYYY-MM')
    earnings.push({
      monthName: monthName,
      month: month,
      totalMoney: totalMoneys[month],
    })
  }

  const columns = useMemo(() => getColumns(), [])
  const actions = <EarningsExportExcel />
  return (
    <Page actions={actions} title="Earnings">
      <Table columns={columns} data={earnings} />
    </Page>
  )
}

function getColumns() {
  const currentMonth = dayjs().format('YYYY-MM')
  return [
    {
      title: 'Month',
      render: (earning) => earning.monthName,
    },
    {
      align: 'center',
      title: 'Total Distributed Money',
      render: (earning) => earning.totalMoney,
    },
    {
      align: 'right',
      render: (earning) => (
        <SetTotalDistributedMoney
          initialValues={{
            month: earning.month,
            totalDistributedMoney: earning.totalMoney,
          }}
        />
      ),
    },
    {
      align: 'right',
      render: (earning) =>
        currentMonth < earning.month ? (
          <span className="text-neutral block">
            Unable to calculate for the next month
          </span>
        ) : currentMonth > earning.month ? (
          <CalculateMonthlyEarning
            initialValues={{
              month: earning.month,
              monthName: earning.monthName,
            }}
          />
        ) : (
          <span className="text-neutral block">
            Unable to calculate for the current month
          </span>
        ),
    },
  ]
}
