import ReactDOM from 'react-dom'
import classnames from 'classnames'
import classes from './index.module.css'

type Params = {
  description?: any,
  timeout?: number,
  title?: any,
  type: 'error' | 'success',
}

export default function notification(params: Params) {
  const description = params.description
  const title = params.title
  const timeout = params.timeout ? params.timeout * 1000 : 5000
  const type = params.type

  const data = {}

  if (!data.container) {
    data.container = document.getElementById('notifications-container')

    if (!data.container) {
      data.container = document.createElement('div')
      document.body.appendChild(data.container)
      data.container.setAttribute('id', 'notifications-container')
      data.container.setAttribute('class', classes.container)
    }
  }

  data.container.style.top = notification.top || '1rem'

  if (!data.item) {
    data.item = document.createElement('div')
    data.container.appendChild(data.item)
  }

  const destroy = () => {
    if (data.item && data.item.parentNode) {
      data.item.parentNode.removeChild(data.item)
    }
  }

  const wrapperClassName = classnames(classes.notification, classes[type])

  const content = (
    <div>
      <div className={wrapperClassName}>
        <button className={classes.close} onClick={destroy}>
          ✕
        </button>
        {title ? <h4>{title}</h4> : null}
        {description ? <div>{description}</div> : null}
      </div>
    </div>
  )

  ReactDOM.render(content, data.item)

  data.timeoutRef = window.setTimeout(destroy, timeout)

  return {
    destroy: () => {
      if (data.timeoutRef) {
        window.clearTimeout(data.timeoutRef)
        destroy()
      }
    },
  }
}

type ShorthandParams = {
  description?: any,
  timeout?: number,
  title?: any,
}

notification.error = (params: ShorthandParams) => {
  notification({
    ...params,
    type: 'error',
  })
}

notification.success = (params: ShorthandParams) => {
  notification({
    ...params,
    type: 'success',
  })
}
