type GetCompanyDetails = {
  companyId: number,
}

export default function getCompanyDetails(variables: GetCompanyDetails = {}) {
  const { companyId } = variables
  return {
    path: `/admin/companies/${companyId}`,
  }
}
