import { useRef, useState } from 'react'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import Button from 'shared/components/kit/button'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import createUser from 'admin/queries/createUser'
import getUsers from 'admin/queries/getUsers'

const VALIDATION = {
  username: {
    label: 'Username',
    rule: 'required',
  },
  email: {
    label: 'Email',
    rule: 'required',
  },
}

export default function CreateUserButton() {
  const submitButtonRef = useRef()

  const [visible, setVisible] = useState(false)

  const queryClient = useQueryClient()

  const mutation = useMutation(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({ query: getUsers })
    },
  })

  const form = useMiniForm({
    resetOnSuccess: true,
    validation: VALIDATION,
    async onSubmit(values) {
      try {
        await mutation.mutateAsync(values)
        setVisible(false)
        notification.success({ title: 'User created!' })
      } catch (error) {
        throw error
      }
    },
  })

  const handleCancel = () => {
    setVisible(false)
    form.reset()
  }

  const handleOk = () => {
    submitButtonRef.current.click()
  }

  return (
    <>
      <Button onClick={() => setVisible(true)} outlined>
        Create a User
      </Button>

      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Create a User"
        visible={visible}>
        <form onSubmit={form.submit}>
          <Field label="Username" {...form.getErrorPropsFor('username')}>
            <Input required {...form.getInputPropsFor('username')} />
          </Field>
          <Field label="Email" {...form.getErrorPropsFor('email')}>
            <Input type="email" required {...form.getInputPropsFor('email')} />
          </Field>
          <button ref={submitButtonRef} hidden type="submit" />
        </form>
      </Modal>
    </>
  )
}
