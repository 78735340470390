import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router'
import AppWrapper from 'core/wrappers/AppWrapper'
import LandingsApp from 'landings/LandingsApp'
import PublisherApp from 'publisher/PublisherApp'
import AdminApp from 'admin/AdminApp'
import 'shared/helpers/colorScheme'

export default function App() {
  return (
    <Suspense fallback={SUSPENSE_FALLBACK}>
      <BrowserRouter>
        <AppWrapper>
          <Switch>
            <Route path="/landings*" component={LandingsApp} />
            <Route path="/admin*" component={AdminApp} />
            <Route path="*" component={PublisherApp} />
          </Switch>
        </AppWrapper>
      </BrowserRouter>
    </Suspense>
  )
}

const SUSPENSE_FALLBACK = (
  <div className="h-screen w-screen flex items-center justify-center">
    LOADING...
  </div>
)
