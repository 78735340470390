type DeleteAdsCampaign = {
  id: number,
}

export default function deleteAdsCampaign(variables: DeleteAdsCampaign = {}) {
  const { id } = variables
  return {
    method: 'DELETE',
    path: `/admin/ads/campaigns/${id}`,
  }
}
