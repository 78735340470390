// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Boxes_boxes__Bu5D6 {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  margin-left: auto;\n  margin-right: auto;\n  text-align: center;\n  color: var(--light-2);\n  gap: 1rem;\n}\n\n@media (min-width: 768px) {\n  .Boxes_boxes__Bu5D6 {\n    flex-direction: row;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/login/components/Boxes/Boxes.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js","<no source>"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,sBAAmB;EAAnB,8BAAmB;EAAnB,iBAAmB;EAAnB,kBAAmB;EAAnB,kBAAmB;EAAnB,qBAAmB;EAAnB,SAAmB;ADAnB;;AEFA;EDEA;IAAA;EAAmB;CCFnB","sourcesContent":[".boxes {\n  @apply flex flex-col md:flex-row justify-between text-center mx-auto text-light-2 gap-4;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxes": "Boxes_boxes__Bu5D6"
};
export default ___CSS_LOADER_EXPORT___;
