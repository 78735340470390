import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import Layout from 'admin/components/layouts/ads-manager'
import BannerAds from './banner-ads'
import BannerAdsCampaign from './ads-manager-campaigns'
import BuyButtons from './buy-buttons'
import CreateCampaign from './buy-buttons/components/create/CreateCampaign'
import EditCampaign from './buy-buttons/components/edit/EditCampaign'
import BannerAdsCreateCampaign from './ads-manager-campaigns/components/BannerAdsCreateCampaign'
import AdsSettings from './settings'

export default function AdsManager() {
  return (
    <Layout>
      <Switch>
        <Route
          path="/admin/ads-manager/banner-ads-campaigns"
          component={BannerAdsCampaign}
          exact
        />
        <Route
          path="/admin/ads-manager/banner-ads-campaigns/create"
          component={BannerAdsCreateCampaign}
          exact
        />
        <Route
          path="/admin/ads-manager/banner-ads-campaigns/:campaignID/edit"
          component={BannerAdsCreateCampaign}
          exact
        />
        <Route
          path="/admin/ads-manager/banner-ads/:displayType"
          component={BannerAds}
          exact
        />
        <Redirect
          from="/admin/ads-manager"
          to="/admin/ads-manager/banner-ads/desktop"
          exact
        />
        <Redirect
          from="/admin/ads-manager/banner-ads"
          to="/admin/ads-manager/banner-ads/desktop"
        />
        <Route
          path="/admin/ads-manager/campaigns"
          component={BuyButtons}
          exact
        />

        <Route
          path="/admin/ads-manager/campaigns/create"
          component={CreateCampaign}
          exact
        />

        <Route
          path="/admin/ads-manager/campaigns/:campaignId/:activeTab"
          component={EditCampaign}
          exact
        />

        <Route path="/admin/ads-manager/settings" component={AdsSettings} />
      </Switch>
    </Layout>
  )
}
