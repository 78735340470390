import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import updateChannel from 'admin/queries/updateChannel'
import getChannel from 'admin/queries/getChannel'
import notification from 'shared/components/kit/notification'
import { useState } from 'react'
import Box from 'shared/components/kit/box'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'

export default function ChangeChannelStatus({ channel }) {
  const queryClient = useQueryClient()

  const mutation = useMutation(updateChannel, {
    onSuccess(res) {
      queryClient.invalidateQueries({
        query: getChannel,
        variables: {
          id: channel.id,
        },
      })

      notification.success({
        title: `The channel ${res.json.status} successfully!`,
      })
    },
  })

  const handlePublish = () => {
    mutation.mutate({
      id: channel.id,
      status: 'published',
    })
  }
  const handleDraft = () => {
    mutation.mutate({
      id: channel.id,
      status: 'draft',
    })
  }

  return channel.status === 'draft' ? (
    <PublishChannel
      isLoading={mutation.isLoading}
      onUpdate={handlePublish}
      channel={channel}
    />
  ) : (
    <DraftChannel
      isLoading={mutation.isLoading}
      onUpdate={handleDraft}
      channel={channel}
    />
  )
}

function PublishChannel({ channel, onUpdate, isLoading }) {
  const [visible, setVisible] = useState(false)

  const handleCancel = () => {
    setVisible(false)
  }

  const handleOk = () => {
    onUpdate()
    setVisible(false)
  }

  return (
    <>
      <Box className="p-6">
        <h4 className="mb-2">Publish Channel</h4>
        <p className="h-16 text-overlayColor-mutedText">
          In this area, you can publish this channel.
        </p>
        <footer className="text-right">
          <Button
            loading={isLoading}
            onClick={() => {
              setVisible(true)
            }}
            size="sm"
            secondary
            outlined>
            Publish this channel
          </Button>
        </footer>
      </Box>
      <Modal
        okText="Publish Channel"
        onOk={handleOk}
        onCancel={handleCancel}
        size="sm"
        title={`Publish Channel: ${channel.name}`}
        visible={visible}>
        <p>Are you sure you want to publish this channel?</p>
      </Modal>
    </>
  )
}

function DraftChannel({ channel, onUpdate, isLoading }) {
  const [visible, setVisible] = useState(false)
  const handleOk = () => {
    onUpdate()
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <Box className="p-6">
        <h4 className="mb-2">Draft Channel</h4>
        <p className="h-16 text-overlayColor-mutedText">
          In this area, you can draft this channel.
        </p>
        <footer className="text-right">
          <Button
            loading={isLoading}
            onClick={() => {
              setVisible(true)
            }}
            secondary
            outlined
            size="sm">
            Draft this channel
          </Button>
        </footer>
      </Box>
      <Modal
        okText="Draft Channel"
        onOk={handleOk}
        onCancel={handleCancel}
        size="sm"
        title={`Draft Channel: ${channel.name}`}
        visible={visible}>
        <p>Are you sure you want to draft this channel?</p>
      </Modal>
    </>
  )
}
