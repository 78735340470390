// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Summary_wrapper__3vkfB {\n  margin-bottom: 1.5rem;\n}\n\n.Summary_wrapper__3vkfB ul {\n  display: flex;\n  padding-top: 1.5rem;\n  padding-bottom: 1.5rem;\n}\n\n.Summary_wrapper__3vkfB ul li {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  flex-grow: 1;\n  padding-left: 3rem;\n  padding-right: 3rem;\n  text-align: center;\n  width: 20%;\n}\n\n.Summary_wrapper__3vkfB ul li:not(:first-child) {\n  border-color: var(--light-2);\n}\n\n.dark .Summary_wrapper__3vkfB ul li:not(:first-child) {\n  border-color: var(--dark-3);\n}\n\n.Summary_wrapper__3vkfB ul li:not(:first-child) {\n  border-left-width: 1px;\n}\n\n.Summary_wrapper__3vkfB ul li small {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  color: var(--neutral);\n}\n\n.Summary_wrapper__3vkfB ul li span {\n  font-size: 1.875rem;\n  line-height: 2.25rem;\n  margin-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/video/overview/components/Summary.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,qBAAmB;ADAnB;;AAEA;ECFA,aAAmB;EAAnB,mBAAmB;EAAnB,sBAAmB;ADInB;;AAEA;ECNA,aAAmB;EAAnB,sBAAmB;EAAnB,mBAAmB;EAAnB,YAAmB;EAAnB,kBAAmB;EAAnB,mBAAmB;EAAnB,kBAAmB;EAAnB,UAAmB;ADQnB;;AAEA;ECVA,4BAAmB;ADYnB;;ACZA;EAAA;AAAmB;;AAAnB;EAAA;AAAmB;;ADcnB;ECdA,eAAmB;EAAnB,mBAAmB;EAAnB,qBAAmB;ADgBnB;;AAEA;EClBA,mBAAmB;EAAnB,oBAAmB;EAAnB,mBAAmB;ADoBnB","sourcesContent":[".wrapper {\n  @apply mb-6;\n}\n\n.wrapper ul {\n  @apply flex py-6;\n}\n\n.wrapper ul li {\n  @apply flex flex-col flex-grow items-center px-12 text-center w-1/5;\n}\n\n.wrapper ul li:not(:first-child) {\n  @apply border-l border-light-2 dark:border-dark-3;\n}\n\n.wrapper ul li small {\n  @apply text-neutral text-base;\n}\n\n.wrapper ul li span {\n  @apply mb-4 text-3xl;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Summary_wrapper__3vkfB",
	"dark": "dark"
};
export default ___CSS_LOADER_EXPORT___;
