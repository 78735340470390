import { useMemo } from 'react'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import Field from 'shared/components/form/field'
import CrappedImageInput from 'shared/components/form/image/crappedImageInput'
import ChannelAvatar from 'shared/components/form/channel-avatar'
import Input from 'shared/components/form/input'
import RichText from 'shared/components/form/rich-text'
import UrlInput from 'shared/components/form/url'
import Drawer from 'shared/components/kit/drawer'
import Button from 'shared/components/kit/button'
import notification from 'shared/components/kit/notification'
import getChannel from 'admin/queries/getChannel'
import getChannels from 'admin/queries/getChannels'
import updateChannel from 'admin/queries/updateChannel'

export default function EditChannelDrawer(props) {
  const { channel, onClose, visible } = props

  const queryClient = useQueryClient()

  const update = useMutation(updateChannel, {
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        query: getChannel,
        variables: {
          id: channel.id,
        },
      })
      queryClient.invalidateQueries({ query: getChannels })
    },
  })

  const initialValues = useMemo(
    () => ({
      avatar: channel?.avatar,
      cover: channel?.cover,
      description: channel?.description,
      facebook: channel?.facebook,
      instagram: channel?.instagram,
      linkedin: channel?.linkedin,
      name: channel?.name,
      reddit: channel?.reddit,
      slogan: channel?.slogan,
      telegram: channel?.telegram,
      tiktok: channel?.tiktok,
      twitter: channel?.twitter,
      website: channel?.website,
    }),
    [channel]
  )

  const form = useMiniForm({
    initialValues,
    async onSubmit(values, event) {
      event.preventDefault()
      try {
        await update.mutateAsync({ id: channel.id, ...values })
        notification.success({ title: 'Channel data saved successfully!' })
        onClose()
      } catch (error) {
        throw error
      }
    },
  })

  return (
    <Drawer
      loading={form.submitting}
      onClose={onClose}
      title={`Edit Channel: ${channel.name}`}
      visible={visible}>
      <form onSubmit={form.submit}>
        <Field label="Channel Cover">
          <div className="w-96">
            <CrappedImageInput
              {...form.getInputPropsFor('cover')}
              placeholder="Upload Channel Cover"
              height={1440}
              width={2560}
            />
          </div>
        </Field>

        <Field label="Channel Avatar">
          <div className="w-48">
            <ChannelAvatar
              {...form.getInputPropsFor('avatar')}
              placeholder="Upload Avatar"
              height={800}
              width={800}
            />
          </div>
        </Field>

        <Field label="Channel Name">
          <Input
            {...form.getInputPropsFor('name')}
            placeholder="Channel Name"
          />
        </Field>

        <Field label="Channel Punchline">
          <Input
            {...form.getInputPropsFor('slogan')}
            placeholder="Channel Punchline"
          />
        </Field>

        <Field label="Website" help="Example: www.website.com">
          <UrlInput
            {...form.getInputPropsFor('website')}
            placeholder="Your Website URL"
          />
        </Field>

        <Field label="Twitter" {...form.getErrorPropsFor('twitter')}>
          <UrlInput
            {...form.getInputPropsFor('twitter')}
            placeholder="username"
            prefix="https://twitter.com/"
          />
        </Field>

        <Field label="Facebook" {...form.getErrorPropsFor('facebook')}>
          <UrlInput
            {...form.getInputPropsFor('facebook')}
            placeholder="username"
            prefix="https://facebook.com/"
          />
        </Field>

        <Field label="Instagram" {...form.getErrorPropsFor('instagram')}>
          <UrlInput
            {...form.getInputPropsFor('instagram')}
            placeholder="username"
            prefix="https://instagram.com/"
          />
        </Field>

        <Field label="Telegram" {...form.getErrorPropsFor('telegram')}>
          <UrlInput
            {...form.getInputPropsFor('telegram')}
            placeholder="username"
            prefix="https://t.me/"
          />
        </Field>

        <Field label="Reddit" {...form.getErrorPropsFor('reddit')}>
          <UrlInput
            {...form.getInputPropsFor('reddit')}
            placeholder="username"
            prefix="https://reddit.com/user/"
          />
        </Field>

        <Field label="LinkedIn" {...form.getErrorPropsFor('linkedin')}>
          <UrlInput
            {...form.getInputPropsFor('linkedin')}
            placeholder="username"
            prefix="https://linkedin.com/in/"
          />
        </Field>

        <Field label="TikTok" {...form.getErrorPropsFor('tiktok')}>
          <UrlInput
            {...form.getInputPropsFor('tiktok')}
            placeholder="username"
            prefix="https://tiktok.com/@"
          />
        </Field>

        <Field label="Channel Bio">
          <RichText
            {...form.getInputPropsFor('description')}
            placeholder="Your Channel Bio"
            multiline
          />
        </Field>

        <Button type="submit">Update Channel</Button>
      </form>
    </Drawer>
  )
}
