import Box from 'shared/components/kit/box'
import Button from 'shared/components/kit/button'
import UnSubscribersChart from './components/UnSubscribersChart'
import SubscribersChart from './components/SubscribersChart'

export default function ChannelSubscribersChart({
  currentChart,
  setCurrentChart,
  data,
  Filter,
  range,
  subscribersChartedData,
  unSubscribersChartedData,
  title,
}) {
  return (
    <Box>
      <header>
        <h4>{title}</h4>
        {Filter}
      </header>
      <div className="space-x-2 text-center">
        <Button
          onClick={() => setCurrentChart('subscribers')}
          size="sm"
          secondary={currentChart !== 'subscribers'}
          outlined>
          Subscribers
        </Button>
        <Button
          onClick={() => setCurrentChart('unSubscribers')}
          size="sm"
          secondary={currentChart !== 'unSubscribers'}
          outlined>
          Unsubscribers
        </Button>
      </div>
      {currentChart === 'subscribers' && (
        <div>
          <SubscribersChart
            chartedData={subscribersChartedData}
            data={data}
            range={range}
          />
        </div>
      )}
      {currentChart === 'unSubscribers' && (
        <div>
          <UnSubscribersChart
            chartedData={unSubscribersChartedData}
            data={data}
            range={range}
          />
        </div>
      )}
    </Box>
  )
}
