import { Link } from 'react-router-dom'
import { ReactComponent as BackSVG } from 'svg/arrow-back.svg'
import classes from './index.module.css'

export default function BackTo(url, text) {
  return (
    <Link className={classes.back} to={url}>
      {' '}
      <BackSVG /> {text}
    </Link>
  )
}
