type LoginVariables = {
  login: string,
  captcha: string,
  captchaKey: string,
}

export default function magicLogin(variables: LoginVariables) {
  const { login, captcha, captchaKey } = variables
  return {
    method: 'post',
    path: '/login/magic/publisher',
    params: {
      login,
      captcha,
      captcha_key: captchaKey,
    },
  }
}
