type Variables = {
  type: 'default' | 'thumb' | 'star',
  location: string,
  page?: number,
}
export default function getFeedbacks(variables: Variables = {}) {
  const { type, location, page } = variables
  return {
    path: '/admin/feedback',
    params: {
      page: page > 1 ? page : undefined,
      filters: {
        type,
        location,
      },
    },
  }
}
