import formatDate from 'helpers/utils/formatDate'
import getUserName from 'helpers/utils/getUserName'
import truncate from 'helpers/utils/truncate'
import UserAvatar from 'shared/components/user-avatar'
import classes from './UserInfo.module.css'

export default function UserInfo({ email, user }) {
  return (
    <div className={classes.wrapper}>
      <UserAvatar className={classes.avatar} user={user} />
      {!isNaN(user.id) ? (
        <>
          <h3>{getUserName(user)}</h3>
          <ul>
            <li title={user.email?.length > 35 ? user.email : ''}>
              {truncate(user.email, 35)}
            </li>
            <li>Signed Up on {formatDate(user.created_at)}</li>
          </ul>
        </>
      ) : (
        <>
          <h3>-</h3>
          <ul>
            <li>{email}</li>
          </ul>
        </>
      )}
    </div>
  )
}
