import { forwardRef } from 'react'
import classnames from 'classnames'
import classes from './index.module.css'

function Checkbox(props, ref) {
  const { checked, className, description, onChange, ...rest } = props
  const handleChange = (event) => {
    onChange(event.target.checked)
  }
  return (
    <div>
      <label className={classnames(className, classes.label)}>
        <input
          {...rest}
          checked={checked}
          onChange={handleChange}
          ref={ref}
          type="checkbox"
        />
        {description ? <span>{description}</span> : null}
      </label>
    </div>
  )
}

export default forwardRef(Checkbox)
