type Variables = {
  id: number,
}

export default function getComment(variables: Variables = {}) {
  const { id } = variables

  return {
    path: `/publisher/comments/${id}`,
  }
}
