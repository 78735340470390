import React from 'react'
import { Redirect } from 'react-router-dom'
import { Route, Switch } from 'react-router'
import { Helmet } from 'react-helmet'
import Auth from 'admin/wrappers/Auth'
import Administrators from 'admin/pages/setting/administrators'
import AdminDefaultLayout from 'admin/components/layouts/default'
import Dashboard from 'admin/pages/dashboard'
import Channel from 'admin/pages/channel'
import Channels from 'admin/pages/channels'
import Login from 'admin/pages/login'
import Messages from 'admin/pages/messages'
import Requests from 'admin/pages/requests'
import Users from 'admin/pages/users'
import Videos from 'admin/pages/videos'
import Setting from 'admin/pages/setting'
import AdsManager from 'admin/pages/ads-manager'
import Reports from 'admin/pages/reports'
import CryptoCurrencies from './pages/cryptoCurrencies'
import Emails from './pages/emails-list'
import Notifications from './pages/notifications'
import Earnings from './pages/earnings'
import PaymentHistory from './pages/payments'
import Monetization from './pages/monetization'
import Feedbacks from './pages/feedbacks'
import Plans from './pages/plans'
import Lottery from './pages/lottery'
import ResetPassword from './pages/reset-password'
import Tags from './pages/tags'
import FAQ from './pages/faq'
import PaymentDetails from './pages/payment-details'
import DeletedChannels from './pages/deleted-channels'
import CRM from './pages/crm'
import CreateCRM from './pages/crm/components/CreateCRM'
import EditCRM from './pages/crm/components/EditCRM'
import TCG from './pages/TCG'
import Traffic from './pages/logs/traffic'

export default function AdminApp() {
  return (
    <>
      <Helmet>
        <title>Admin Panel - Today's crypto</title>
      </Helmet>
      <Switch>
        <Route path="/admin/login" component={Login} exact />
        <Route
          path="/admin/password/reset/:token"
          component={ResetPassword}
          exact
        />
        <Route path="/admin*">
          <Auth>
            <Switch>
              <Route path="/admin/channels/:channelId" component={Channel} />
              <Route path="/admin/setting" component={Setting} />
              <Route path="/admin/monetization" component={Monetization} />
              <Route path="/admin/ads-manager" component={AdsManager} />
              <Route path="/admin*">
                <AdminDefaultLayout>
                  <Switch>
                    <Route path="/admin" component={Dashboard} exact />
                    <Route path="/admin/videos" component={Videos} exact />
                    <Route
                      path="/admin/payment-details/:id?"
                      component={PaymentDetails}
                    />
                    <Route path="/admin/requests" component={Requests} exact />
                    <Route path="/admin/channels" component={Channels} exact />
                    <Route
                      path="/admin/deleted-channels"
                      component={DeletedChannels}
                      exact
                    />
                    <Route path="/admin/companies" component={CRM} exact />
                    <Route
                      path="/admin/companies/create"
                      component={CreateCRM}
                      exact
                    />
                    <Route
                      path="/admin/companies/:companyId/edit"
                      component={EditCRM}
                      exact
                    />
                    <Route path="/admin/earnings" component={Earnings} exact />
                    <Route
                      path="/admin/feedbacks"
                      component={Feedbacks}
                      exact
                    />
                    <Route
                      path="/admin/payments"
                      component={PaymentHistory}
                      exact
                    />
                    <Route path="/admin/users" component={Users} exact />
                    <Route
                      path="/admin/administrators"
                      component={Administrators}
                      exact
                    />
                    <Route
                      path="/admin/messages/:messageId?"
                      component={Messages}
                    />
                    <Route path="/admin/faq/:activeTab" component={FAQ} />
                    <Redirect from="/admin/faq" to="/admin/faq/publisher" />
                    <Route
                      path="/admin/reports/:activeTab/:id?"
                      component={Reports}
                    />
                    <Redirect
                      from="/admin/reports"
                      to="/admin/reports/videos"
                    />
                    <Route path="/admin/plans/:planId?" component={Plans} />
                    <Route
                      path="/admin/cryptoCurrencies"
                      component={CryptoCurrencies}
                    />
                    <Route path="/admin/emails" component={Emails} />
                    <Route
                      path="/admin/notifications/:notificationId?"
                      component={Notifications}
                    />
                    <Route path="/admin/tags" component={Tags} />
                    <Route path="/admin/lottery" component={Lottery} />
                    <Route path="/admin/tcg" component={TCG} />
                    <Route path="/admin/security/traffic" component={Traffic} />
                  </Switch>
                </AdminDefaultLayout>
              </Route>
            </Switch>
          </Auth>
        </Route>
      </Switch>
    </>
  )
}
