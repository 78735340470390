import { useMemo, useRef } from 'react'
import useQueryClient from 'core/react-query/useQueryClient'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import ImageInput from 'shared/components/form/image'
import notification from 'shared/components/kit/notification'
import Field from 'shared/components/form/field'
import uploadAdSpaces from 'admin/queries/uploadAdSpaces'
import getAdSpaces from 'admin/queries/getAdSpaces'
import Modal from 'shared/components/kit/modal'
import Input from 'shared/components/form/input'
import classes from './AdItem.module.css'

export default function AdItem({
  adsKey,
  height,
  title,
  status,
  src,
  redirectTo,
  width,
}) {
  const ref = useRef()
  const queryClient = useQueryClient()
  const form = useMiniForm({
    initialValues: useMemo(() => {
      return {
        redirectTo: redirectTo,
        file: src,
      }
    }, [redirectTo, src]),
    resetOnSuccess: true,
    async onSubmit(values) {
      try {
        await mutation.mutateAsync({
          key: adsKey,
          url: values.file,
          redirectTo: values.redirectTo,
        })
      } catch (error) {
        throw error
      }
    },
  })

  const mutation = useMutation(uploadAdSpaces, {
    onSuccess: () => {
      notification.success({
        title: 'Ad uploaded successfully!',
      })
      queryClient.invalidateQueries({
        query: getAdSpaces,
      })
    },
  })

  const deleteMutation = useMutation(uploadAdSpaces, {
    onSuccess: () => {
      notification.success({
        title: 'Ad removed successfully!',
      })
      queryClient.invalidateQueries({
        query: getAdSpaces,
      })
    },
  })

  const handlePublish = () => {
    form.submit()
  }

  const handleDelete = () => {
    Modal.open({
      title: 'Delete Ad',
      description: 'Are you sure you want to delete this Ad?',
      okText: 'yes',
      cancelText: 'No',
      onOk: () => {
        deleteMutation.mutate({
          key: adsKey,
        })
        form.change('redirectTo', '')
      },
    })
  }

  return (
    <div className={classes.wrapper}>
      <div className="mb-6">
        <div>{title}</div>
        <div className={classes.status}>
          Status:{' '}
          <span
            className={status === 'Live' ? 'text-green-400' : 'text-red-400'}>
            {status}
          </span>
        </div>
        <div className="flex gap-4 ">
          <Button onClick={() => ref.current.click()} secondary size="sm">
            Upload
          </Button>
          {src === undefined ||
          src !== form.values.file ||
          redirectTo !== form.values.redirectTo ? (
            <Button
              disabled={!form.values.file}
              onClick={handlePublish}
              size="sm">
              Publish
            </Button>
          ) : (
            <Button mood="neutral" onClick={handleDelete} size="sm">
              Delete
            </Button>
          )}
        </div>
      </div>
      <form style={{ width: '604px' }}>
        <Field label="Preview">
          <ImageInput
            ref={ref}
            {...form.getInputPropsFor('file')}
            canUpload={true}
            pixels={true}
            height={height}
            width={width}
          />
        </Field>
        <Field label="Redirect to">
          <Input
            placeholder="redirect URL..."
            {...form.getInputPropsFor('redirectTo')}
          />
        </Field>
      </form>
    </div>
  )
}
