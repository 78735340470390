import Select from 'shared/components/form/select'

export default function SelectCampaignStatus(props) {
  const options = [
    {
      label: 'Draft',
      value: 'draft',
    },
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Archive',
      value: 'archive',
    },
  ]
  return (
    <Select
      defaultValue={{ label: 'Draft', value: 'draft' }}
      {...props}
      options={options}
    />
  )
}
