import Page from 'admin/components/page'
import ProgressBar from './components/ProgressBar'
import DistributionChart from './components/DistributionChart'

export default function TCG() {
  return (
    <Page title="Token distribution dashboard">
      <ProgressBar />
      <DistributionChart />
    </Page>
  )
}
