import classes from './index.module.css'

type Props = {
  current: number,
  last: number,
  onPaginate: Function,
}

export default function Pagination(props: Props) {
  const { current, last, onPaginate } = props

  const parts = []

  const addPage = (page) => {
    parts.push(
      <li key={page}>
        <button onClick={() => onPaginate(page)}>{page}</button>
      </li>
    )
  }

  const addCurrent = (page) => {
    parts.push(
      <li key={page} className={classes.current}>
        <span>{page}</span>
      </li>
    )
  }

  const addDots = (key = 'a') => {
    parts.push(
      <li key={`dots-${key}`}>
        <span>...</span>
      </li>
    )
  }

  if (last > 10) {
    if (current > 1) {
      parts.push(
        <li key="prev">
          <button onClick={() => onPaginate(current - 1)}>Previous</button>
        </li>
      )
    }
    if (current <= 3) {
      for (let i = 1; i <= 5; i++) {
        if (i === current) {
          addCurrent(current)
        } else {
          addPage(i)
        }
      }
      addDots()
      addPage(last - 1)
      addPage(last)
    } else if (last - current <= 3) {
      addPage(1)
      addPage(2)
      addDots()
      for (let i = 4; i >= 0; i--) {
        if (last - i === current) {
          addCurrent(current)
        } else {
          addPage(last - i)
        }
      }
    } else {
      addPage(1)
      addDots('a')
      for (let i = current - 2; i <= current + 2; i++) {
        if (i === current) {
          addCurrent(current)
        } else {
          addPage(i)
        }
      }
      addDots('b')
      addPage(last)
    }
    if (current < last) {
      parts.push(
        <li key="next">
          <button onClick={() => onPaginate(current + 1)}>Next</button>
        </li>
      )
    }
  } else {
    for (let i = 1; i <= last; i++) {
      if (i === current) {
        addCurrent(current)
      } else {
        addPage(i)
      }
    }
  }

  return (
    <nav className={classes.wrapper}>
      <ul className={classes.pagination}>{parts}</ul>
    </nav>
  )
}
