import Button from 'shared/components/kit/button'
import Notification from './Notification'

export default function Index(props) {
  const {
    children,
    isFetching,
    hasNextPage,
    onLoadNextPage,
    onMarkAllAsRead,
    unreadCount,
  } = props
  return (
    <>
      <header>
        <span>Notifications</span>
        <div className={`${unreadCount.count > 0 ? '' : 'hidden'} `}>
          <Button onClick={onMarkAllAsRead} size="sm" outlined secondary>
            Mark all as read
          </Button>
        </div>
      </header>
      <ul>
        <>
          {children}
          {hasNextPage && (
            <li className="text-center py-2">
              <Button
                loading={isFetching}
                onClick={onLoadNextPage}
                size="sm"
                outlined
                secondary>
                Load More Notifications
              </Button>
            </li>
          )}
        </>
      </ul>
    </>
  )
}

Index.Item = Notification
