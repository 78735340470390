import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import env from 'core/env'
import Logo from 'shared/components/logo'
import useForceColorScheme from 'shared/hooks/useForceColorScheme'
import Footer from 'shared/components/footer'
import Icon from 'shared/components/kit/icon'
import LoginForm from './components/LoginForm'
import Boxes from './components/Boxes/Boxes'
import { ReactComponent as MenuSVG } from 'icons/menu.svg'
import { ReactComponent as CloseSVG } from 'icons/close.svg'
import classes from './index.module.css'

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}

export default function Index() {
  useForceColorScheme('dark')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const refSecTwo = useRef()
  const location = useLocation()

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const handleScrollToInfo = (e) => {
    const { offsetTop } = refSecTwo.current
    window.scrollTo({ top: offsetTop + 10, behavior: 'smooth' })
  }

  useEffect(() => {
    if (location.hash === '#want-to-know-more') {
      handleScrollToInfo()
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [location])

  const triggerMenu = () => {
    setIsMenuOpen((prev) => !prev)
  }

  return (
    <div className={classes.wrapper}>
      <ul className={classes.nav}>
        <li className={classes.logo}>
          <Link to="/">
            <div>
              <Logo forceDarkMode={true} />
            </div>
          </Link>
        </li>
        {(isMenuOpen || windowSize.innerWidth > 768) && (
          <>
            <li>
              <Link to="/#want-to-know-more">Want to know more?</Link>
            </li>
            <li>
              <Link to="/landings/monetize">Monetize</Link>
            </li>
            <li>
              <Link
                to={{ pathname: env('REACT_APP_END_USER_LANDING_URL') }}
                target="_blank">
                User Signup
              </Link>
            </li>
          </>
        )}
        <li className={classes.menu}>
          <a onClick={triggerMenu}>
            <Icon>{isMenuOpen === true ? <CloseSVG /> : <MenuSVG />}</Icon>
          </a>
        </li>
      </ul>
      <section className={classes.firstView}>
        <div className={classes.content}>
          <div className={classes.text}>
            <h1>
              Reach a <span className="text-primary">passionate</span> community
              and <span className="text-primary">scale</span> your audience.
            </h1>
            <br />
            <h2>
              Be a part of the world’s first and most secure streaming <br />
              service for reliable crypto-related content.
            </h2>
          </div>
          <div className={classes.image}>
            <img src="/publisher/landing/two-monitors.png" />
          </div>
        </div>
        <div>
          <LoginForm />
        </div>
      </section>
      <section ref={refSecTwo} className={classes.secondView}>
        <Boxes />
      </section>
      <div className={classes.footerContainer}>
        <Footer />
      </div>
    </div>
  )
}
