import classnames from 'classnames'
import { ReactComponent as LockSVG } from 'svg/lock-filled.svg'
import classes from './Slot.module.css'

export default function Slot({ color, prefilled, selected }) {
  const classNames = classnames(
    classes.slot,
    selected || prefilled ? classes[color] : ''
  )

  return <div className={classNames}>{prefilled && <LockSVG />}</div>
}
