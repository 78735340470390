type FeedbackMutationVariablesType = {
  type: 'default' | 'thumb' | 'star',
  location: string,
  value: number,
  text?: string,
}

export default function feedback(
  variables: FeedbackMutationVariablesType = {}
) {
  const origin = window.location.href
  return {
    method: 'post',
    path: '/feedback',
    params: {
      ...variables,
      origin,
    },
  }
}
