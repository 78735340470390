import { useEffect } from 'react'
import pusher from 'core/pusher'
import useProfile from './useProfile'

function useWatchNotifications(
  scope: 'admin' | 'publisher',
  listener: Function,
  deps?: Array
) {
  const { user } = useProfile()

  const userId = user?.id

  useEffect(() => {
    const channel = pusher().subscribe(`User.${userId}`)

    if (!channel) {
      return
    }

    const updateData = ({ data }) => {
      if (data && (data.scope === 'global' || data.scope === scope)) {
        listener(data)
      }
    }

    channel.bind('notification', updateData)

    return () => {
      channel.unbind(updateData)
    }
  }, [pusher, userId, ...deps])
}

export default useWatchNotifications
