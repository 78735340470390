import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import useQueryClient from 'core/react-query/useQueryClient'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import useQuery from 'core/react-query/useQuery'
import getMyChannel from 'publisher/queries/getMyChannel'
import updateChannel from 'publisher/queries/updateChannel'
import Page from 'publisher/components/page'
import ChannelAvatar from 'shared/components/form/channel-avatar'
import CrappedImageInput from 'shared/components/form/image/crappedImageInput'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import UrlInput from 'shared/components/form/url'
import RichText from 'shared/components/form/rich-text'
import Button from 'shared/components/kit/button'
import getProfile from 'shared/queries/getProfile'
import notification from 'shared/components/kit/notification'
import EmailVerificationModal from 'shared/components/email-verification-modal'
import classes from './index.module.css'

const validation = {
  name: {
    label: 'Channel Name',
    rule: 'required',
  },
}

export default function MyChannel() {
  const { data, error, isError, isLoading } = useQuery(getMyChannel)
  const [showEmailVerification, setShowEmailVerification] = useState(false)

  const queryClient = useQueryClient()

  const update = useMutation(updateChannel, {
    onSuccess: (response) => {
      queryClient.setQueryData({
        query: getMyChannel,
        updater: response,
      })
      queryClient.invalidateQueries({
        query: getProfile,
      })
    },
  })

  const channel = data ? data.json : undefined

  useEffect(() => {
    setLinkedinType(channel?.linkedin?.split('/')[3] || '')
  }, [channel])

  const [linkedinType, setLinkedinType] = useState('')

  const initialValues = useMemo(
    () => ({
      avatar: channel?.avatar,
      cover: channel?.cover,
      description: channel?.description,
      facebook: channel?.facebook,
      instagram: channel?.instagram,
      linkedin: channel?.linkedin,
      name: channel?.name,
      reddit: channel?.reddit,
      slogan: channel?.slogan,
      telegram: channel?.telegram,
      tiktok: channel?.tiktok,
      twitter: channel?.twitter,
      website: channel?.website,
    }),
    [channel]
  )

  const form = useMiniForm({
    initialValues,
    validation,
    async onSubmit(values, event) {
      if (event) {
        event.preventDefault()
      }
      try {
        await update.mutateAsync(values)
        notification.success({ title: 'Channel data saved successfully!' })
      } catch (error) {
        if (error?.json?.code === 'email_verification.require') {
          setShowEmailVerification(true)
        } else {
          if (error.json.message) {
            notification.error({ title: error.json.message })
          }
          throw error.json
        }
      }
    },
  })

  if (isError) {
    return <Page error={error.message} />
  }

  const handleCancel = () => {
    setShowEmailVerification(false)
  }

  const handleVerified = () => {
    setShowEmailVerification(false)
    form.submit()
  }

  const handleLinkedinType = (e, val) => {
    e.preventDefault()
    setLinkedinType(val)
    const replaceValue = val === `in` ? `/company/` : `/in/`
    form.change(
      'linkedin',
      form.getInputPropsFor('linkedin').value?.replace(replaceValue, `/${val}/`)
    )
  }

  const linkedinLabel = `Linkedin  ${
    linkedinType === 'in'
      ? '- Individual page'
      : linkedinType === 'company'
      ? '- Company page'
      : ''
  }`

  const linkedinHelp =
    linkedinType === '' ? null : linkedinType === 'in' ? (
      <span
        className="cursor-pointer"
        onClick={(e) =>
          handleLinkedinType(e, linkedinType === 'in' ? 'company' : 'in')
        }>{`${
        linkedinType === 'company' ? 'Individual' : 'Company'
      } page instead?`}</span>
    ) : (
      <span
        className="cursor-pointer"
        onClick={(e) =>
          handleLinkedinType(e, linkedinType === 'company' ? 'in' : 'company')
        }>{`${
        linkedinType === 'company' ? 'Individual' : 'Company'
      } page instead?`}</span>
    )

  return (
    <Page title="Edit Your Channel Info" width="sm">
      <form onSubmit={form.submit}>
        <Field>
          <CrappedImageInput
            {...form.getInputPropsFor('cover')}
            placeholder="Upload Channel Cover"
            height={423}
            width={1855}
          />
        </Field>

        <div className={classes.otherFields}>
          <div className={classes.avatar}>
            <ChannelAvatar
              {...form.getInputPropsFor('avatar')}
              placeholder="Upload Avatar"
              height={800}
              width={800}
              circle
            />
          </div>

          <Field
            help={
              <Link
                className="text-secondary"
                to="/publisher/tickets/create/change-channel-name-request">
                Request name change
              </Link>
            }
            label="Channel Name"
            {...form.getErrorPropsFor('name')}>
            <Input
              disabled
              {...form.getInputPropsFor('name')}
              placeholder="Channel Name"
            />
          </Field>

          <Field
            help="The channel summary will be displayed when users are searching for channels, so make it punchy! (Max 70 characters)."
            label="Channel Summary">
            <Input
              className={classes.summary}
              maxLength={70}
              {...form.getInputPropsFor('slogan')}
              placeholder="Channel Summary"
              multiline
            />
          </Field>

          <Field label="Website" help="Example: www.website.com">
            <UrlInput
              {...form.getInputPropsFor('website')}
              placeholder="Your Website URL"
            />
          </Field>

          <Field label="Twitter" {...form.getErrorPropsFor('twitter')}>
            <UrlInput
              {...form.getInputPropsFor('twitter')}
              placeholder="username"
              prefix="https://twitter.com/"
            />
          </Field>

          <Field label="Facebook" {...form.getErrorPropsFor('facebook')}>
            <UrlInput
              {...form.getInputPropsFor('facebook')}
              placeholder="username"
              prefix="https://facebook.com/"
            />
          </Field>

          <Field label="Instagram" {...form.getErrorPropsFor('instagram')}>
            <UrlInput
              {...form.getInputPropsFor('instagram')}
              placeholder="username"
              prefix="https://instagram.com/"
            />
          </Field>

          <Field label="Telegram" {...form.getErrorPropsFor('telegram')}>
            <UrlInput
              {...form.getInputPropsFor('telegram')}
              placeholder="username"
              prefix="https://t.me/"
            />
          </Field>

          <Field label="Reddit" {...form.getErrorPropsFor('reddit')}>
            <UrlInput
              {...form.getInputPropsFor('reddit')}
              placeholder="username"
              prefix="https://reddit.com/user/"
            />
          </Field>

          <Field
            label={linkedinLabel}
            help={linkedinHelp}
            {...form.getErrorPropsFor('linkedin')}>
            {linkedinType.length > 0 || isLoading ? (
              <UrlInput
                {...form.getInputPropsFor('linkedin')}
                placeholder="username"
                prefix={`https://linkedin.com/${linkedinType}/`}
              />
            ) : (
              <div className="flex gap-4">
                <Button
                  className="w-1/2"
                  onClick={(e) => handleLinkedinType(e, 'company')}
                  secondary>
                  Company page
                </Button>
                <Button
                  className="w-1/2"
                  onClick={(e) => handleLinkedinType(e, 'in')}
                  secondary>
                  Individual page
                </Button>
              </div>
            )}
          </Field>

          <Field label="TikTok" {...form.getErrorPropsFor('tiktok')}>
            <UrlInput
              {...form.getInputPropsFor('tiktok')}
              placeholder="username"
              prefix="https://tiktok.com/@"
            />
          </Field>

          <Field label="Channel Bio">
            <RichText
              {...form.getInputPropsFor('description')}
              placeholder="Your Channel Bio"
              multiline
            />
          </Field>

          <Button loading={form.submitting} type="submit">
            SAVE CHANGES
          </Button>
        </div>
      </form>

      <EmailVerificationModal
        onCancel={handleCancel}
        onVerified={handleVerified}
        visible={showEmailVerification}
      />
    </Page>
  )
}
