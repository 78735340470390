import { useEffect } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import useQuery from 'core/react-query/useQuery'
import Button from 'shared/components/kit/button'
import Result from 'shared/components/kit/result'
import Loading from 'shared/components/kit/loading'
import notification from 'shared/components/kit/notification'
import verifyEmailVerificationToken from 'publisher/queries/verifyEmailVerificationToken'

export default function Verify() {
  const params = useParams()
  const urlToken = params.token
  const result = useQuery(verifyEmailVerificationToken, {
    retry: false,
    variables: {
      token: urlToken,
    },
  })

  useEffect(() => {
    if (result.isSuccess) {
      notification.success({ title: result.data.json.message })
    }
  }, [result.isSuccess])

  if (result.isLoading) {
    return <Loading />
  }
  if (result.status !== 'success') {
    return (
      <Result
        className="mt-48"
        title="Page Not Found"
        status="error"
        description="Sorry, the page you were looking for could not be found."
        footer={
          <Link to="/">
            <Button>Log In</Button>
          </Link>
        }
      />
    )
  } else {
    return (
      <Result
        className="mt-48"
        title={result.data.json.message}
        status="success"
        description="You will now be able to log in to the Publisher Panel and complete your application."
        footer={
          <Link to="/">
            <Button className="w-48">Log In</Button>
          </Link>
        }
      />
    )
  }
}
