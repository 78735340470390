import { useState } from 'react'
import dayjs from 'dayjs'
import colors from 'tailwindcss/colors'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import getAdminTokenPointsOverview from 'admin/queries/getAdminTokenPointsOverview'
import Page from 'admin/components/page'
import Box from 'shared/components/kit/box'
import Chart from 'shared/components/chart'
import classes from './DistributionChart.module.css'
import Filters from './Filters'

const chartedData = (statistics, statisticsPeriod) => {
  const monthlyFilters = ['last_365d']
  const dailyFilters = [
    'last_7d',
    'last_14d',
    'last_30d',
    'last_90d',
    'last_180d',
  ]

  const getHodlMembers = () => {
    return Object.values(statistics).map((statistic) => {
      return Math.floor(statistic.hero)
    })
  }

  const getUsers = () => {
    return Object.values(statistics).map((statistic) => {
      return Math.floor(statistic.user)
    })
  }

  const getPublishers = () => {
    return Object.values(statistics).map((statistic) => {
      return Math.floor(statistic.publisher)
    })
  }

  const labels = () => {
    if (dailyFilters.includes(statisticsPeriod)) {
      return statistics.map((item) => dayjs(item.date).format('D MMM'))
    } else if (monthlyFilters.includes(statisticsPeriod)) {
      return statistics.map((item) => dayjs(item.date).format('MMM'))
    }
    return []
  }

  return {
    labels: labels(),
    datasets: [
      {
        label: 'HODL Members',
        backgroundColor: colors.red['300'],
        borderColor: colors.red['300'],
        borderWidth: 1,
        data: getHodlMembers(),
        stack: 'Stack 0',
      },
      {
        label: 'Users',
        backgroundColor: colors.gray['500'],
        borderColor: colors.gray['500'],
        borderWidth: 1,
        data: getUsers(),
        stack: 'Stack 0',
      },
      {
        label: 'Publishers',
        backgroundColor: colors.yellow['500'],
        borderColor: colors.yellow['500'],
        borderWidth: 1,
        data: getPublishers(),
        stack: 'Stack 0',
      },
    ],
  }
}

export default function DistributionChart() {
  const [statisticsPeriod, setStatisticsPeriod] = useState('last_7d')
  const { data, isError } = useQuery(getAdminTokenPointsOverview, {
    variables: {
      statisticsPeriod: statisticsPeriod,
    },
  })
  const statisticsObject = get(data, 'json.statistics', [])
  const statistics = Object.values(statisticsObject)

  if (isError) {
    return <Page error={statistics.error.message} />
  }

  return (
    <div className={classes.charts}>
      <Box className={classes.box}>
        <header>
          <h4>Distribution</h4>
          <Filters
            statisticsPeriod={statisticsPeriod}
            onChange={setStatisticsPeriod}
          />
        </header>
        <div>
          <Chart
            config={{
              type: 'bar',
              data: chartedData(statistics, statisticsPeriod),
              options: {
                tooltips: {
                  callbacks: {
                    label: (tooltipItem) => tooltipItem.value + ' Click',
                  },
                },
                elements: {
                  line: {
                    tension: 0,
                  },
                  point: {
                    radius: 0,
                  },
                },
                responsive: true,
                legend: {
                  display: false,
                },
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        callback: function (value) {
                          if (value % 1 === 0) {
                            return value
                          }
                        },
                      },
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        callback: function (value) {
                          if (value % 1 === 0) {
                            return value
                          }
                        },
                      },
                    },
                  ],
                },
                title: {
                  display: false,
                },
              },
            }}
          />
        </div>
      </Box>
    </div>
  )
}
