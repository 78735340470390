import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import SelectPublisher from 'admin/components/form/select-publisher'
import classes from './Filters.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <SelectPublisher
        className={classes.select}
        {...form.getInputPropsFor('publisherId')}
        isClearable
        placeholder="Select publisher..."
      />
      <HtmlSelect {...form.getInputPropsFor('sort')}>
        <option value="">Sort By...</option>
        <option value="latest"> Latest</option>
        <option value="oldest">Oldest</option>
      </HtmlSelect>
      <HtmlSelect className="w-full" {...form.getInputPropsFor('status')}>
        <option value="">Status...</option>
        <option value="new">New</option>
        <option value="code-sent">Code Sent</option>
        <option value="verified">Verified</option>
        <option value="expired">Expired</option>
        <option value="canceled">Canceled</option>
      </HtmlSelect>
      <HtmlSelect {...form.getInputPropsFor('onlyArchived')}>
        <option value="">Non Archived</option>
        <option value="true">Archived</option>
      </HtmlSelect>
      <Button outlined>Search</Button>
    </form>
  )
}
