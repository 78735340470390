// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "label.radio_label__32zl7 {\n  display: flex;\n  align-items: center;\n  padding-top: 0.25rem;\n  padding-bottom: 0.25rem;\n}\n\nlabel.radio_label__32zl7 input {\n  -webkit-appearance: none;\n          appearance: none;\n  border-color: var(--neutral);\n  border-radius: 9999px;\n  border-width: 2px;\n  border-width: 1px;\n  height: 1rem;\n  margin: 0px;\n  --tw-shadow: 0 0 #0000;\n  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);\n  width: 1rem;\n}\n\nlabel.radio_label__32zl7 input:checked {\n  border-color: var(--primary);\n  border-width: 5px;\n}\n\nlabel.radio_label__32zl7 > span {\n  margin-left: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/form/radio/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,uBAAmB;ADAnB;;AAEA;ECFA,wBAAmB;UAAnB,gBAAmB;EAAnB,4BAAmB;EAAnB,qBAAmB;EAAnB,iBAAmB;EAAnB,iBAAmB;EAAnB,YAAmB;EAAnB,WAAmB;EAAnB,sBAAmB;EAAnB,uGAAmB;EAAnB,WAAmB;ADInB;;AAEA;ECNA,4BAAmB;EDQjB,iBAAiB;AACnB;;AAEA;ECXA,mBAAmB;ADanB","sourcesContent":["label.label {\n  @apply flex items-center py-1;\n}\n\nlabel.label input {\n  @apply appearance-none border border-2 border-neutral h-4 m-0 rounded-full shadow-none w-4;\n}\n\nlabel.label input:checked {\n  @apply border-primary;\n  border-width: 5px;\n}\n\nlabel.label > span {\n  @apply ml-2;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "radio_label__32zl7"
};
export default ___CSS_LOADER_EXPORT___;
