import { useMemo } from 'react'
import dayjs from 'dayjs'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import getPayoutEarningStatistics from 'admin/queries/getPayoutEarningStatistics'

export default function usePayoutEarningStatistics() {
  const fromThisYear = useMemo(() =>
    dayjs().startOf('year').format('YYYY-MM-DD')
  )

  const toThisYear = useMemo(() => dayjs().endOf('year').format('YYYY-MM-DD'))

  const fromParam = fromThisYear
  const toParam = toThisYear

  const { data, isLoading, isError, error } = useQuery(
    getPayoutEarningStatistics,
    {
      variables: {
        from: fromParam,
        to: toParam,
      },
    }
  )

  return {
    data: get(data, 'json', []),
    isLoading,
    isError,
    error,
  }
}
