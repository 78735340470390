import { useState } from 'react'
import useMiniForm from 'core/useMiniForm'
import cookie from 'core/cookie'
import env from 'core/env'
import Button from 'shared/components/kit/button'
import Field from 'shared/components/form/field'
import Modal from 'shared/components/kit/modal'
import SelectMonths from 'admin/components/form/select-months'
import SelectYears from './SelectYears'

const VALIDATION = {
  year: {
    label: 'Year',
    rule: 'required',
  },
  month: {
    label: 'Month',
    rule: 'required',
  },
}

export default function EarningsExportExcel() {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const form = useMiniForm({
    resetOnSuccess: true,
    validation: VALIDATION,
    async onSubmit(values) {
      try {
        setLoading(true)
        const month = `${values.year}-${values.month}`

        let myHeaders = new Headers()
        myHeaders.append('Authorization', `Bearer ${cookie().get('jwt')}`)

        let requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        }
        const apiBase = env('API_URL')
        await fetch(
          `${apiBase}/admin/users/publishers-earnings/export?filters[month]=${month}`,
          requestOptions
        )
          .then((response) => response.blob())
          .then((blob) => {
            const href = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', `publisher-earning-${month}.xlsx`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
          .catch((error) => console.log('error', error))
        setVisible(false)
      } catch (error) {
        throw error
      } finally {
        setLoading(false)
      }
    },
  })

  const handleCancel = () => {
    setVisible(false)
    form.reset()
  }

  const handleOk = () => {
    form.submit()
  }

  return (
    <>
      <Button onClick={() => setVisible(true)} outlined>
        Export to Excel
      </Button>

      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        loading={loading}
        okText="Export"
        size="sm"
        title="Export publisher earnings as excel"
        visible={visible}>
        <form onSubmit={form.submit}>
          <Field label="Year" {...form.getErrorPropsFor('year')}>
            <SelectYears
              key={visible ? '1' : '0'}
              placeholder="Select year..."
              isClearable={true}
              menuPosition="fixed"
              {...form.getInputPropsFor('year')}
            />
          </Field>
          <Field label="Month" {...form.getErrorPropsFor('month')}>
            <SelectMonths
              key={visible ? '1' : '0'}
              placeholder="Select month..."
              isClearable={true}
              menuPosition="fixed"
              {...form.getInputPropsFor('month')}
            />
          </Field>
        </form>
      </Modal>
    </>
  )
}
