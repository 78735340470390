import cookie from 'core/cookie'
import useMutation from 'core/react-query/useMutation'

export default function useLogout() {
  return useMutation(logoutMutation, {
    onSuccess: () => {
      cookie().remove('jwt')
      window.localStorage.removeItem('color-scheme')
    },
  })
}

function logoutMutation() {
  return {
    method: 'get',
    path: '/logout',
  }
}
