import isBeta from 'core/isBeta'
import Button from 'shared/components/kit/button'

export default function ClaimsHistory({ address }) {
  const polygonscanAddress = isBeta()
    ? `https://mumbai.polygonscan.com/address/${address}`
    : `https://polygonscan.com/address/${address}`
  if (!address) {
    return (
      <div className="text-center text-primary">There is no wallet address</div>
    )
  }

  return (
    <div className="text-center">
      <Button secondary>
        <a className="select-none" target="_blank" href={polygonscanAddress}>
          Claim history
        </a>
      </Button>
    </div>
  )
}
