import { useMemo } from 'react'
import useQuery from 'core/react-query/useQuery'
import UserAvatar from 'shared/components/user-avatar'
import Table from 'shared/components/kit/table'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import getUserName from 'helpers/utils/getUserName'
import formatDate from 'helpers/utils/formatDate'
import formatPrice from 'helpers/utils/formatPrice'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import getPaymentHistory from 'admin/queries/getPaymentHistory'
import Page from 'admin/components/page'
import PageHeader from 'admin/components/page-header'
import Filters from './components/Filters'
import PaymentStatus from 'admin/components/payment-status'
import classes from './index.module.css'

const FILTER_CONFIG = {
  base: '/admin/payments',
  params: {
    userId: 'number',
    status: 'string',
    includeUser: 'boolean',
    page: 'number',
  },
}

export default function PaymentHistory() {
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)
  const { data, error, isError, isFetching } = useQuery(getPaymentHistory, {
    keepPreviousData: true,
    variables: {
      includeUser: true,
      userId: filters.userId,
      status: filters.status,
      page: filters.page,
    },
  })

  const {
    data: payments,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  if (isError) {
    return <Page error={error.message} />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  const handleFilter = (nextFilters) => {
    setFilters({
      ...nextFilters,
      page: undefined,
    })
  }

  return (
    <>
      <PageHeader description={description} title="Payment History" />
      <Filters initialFilters={filters} onSubmit={handleFilter} />
      <Table
        columns={columns}
        data={payments}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
    </>
  )
}

function getColumns() {
  return [
    {
      className: classes.avatar,
      render: (payment) => <UserAvatar user={payment.user} />,
    },
    {
      title: 'Username',
      render: (payment) => (
        <>
          <span className="mr-4">{getUserName(payment.user)}</span>
          <span className="inline-flex items-center space-x-3 text-xl"></span>
        </>
      ),
    },
    {
      title: 'Plan Name',
      render: (payment) => payment.plan.name,
    },
    {
      className: 'w-42',
      title: 'Amount',
      render: (payment) =>
        payment.pricing.currency === 'usd'
          ? formatPrice(payment.pricing.amount)
          : payment.pricing.amount +
            <span>{payment.pricing.currency.toUpperCase()}</span>,
    },
    {
      className: 'w-1/6',
      title: 'Payment Method',
      render: (payment) => payment.payment_method.name,
    },
    {
      className: 'w-1/6',
      title: 'Status',
      render: (payment) => <PaymentStatus status={payment.status} />,
    },
    {
      className: 'w-42',
      title: 'Date',
      render: (payment) => formatDate(payment.created_at),
    },
  ]
}
