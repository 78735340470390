type CreateAdminMutationVariables = {
  username: string,
  email: string,
}

export default function createAdmin(variables: CreateAdminMutationVariables) {
  return {
    method: 'post',
    path: '/admin/admins',
    params: variables,
  }
}
