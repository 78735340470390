import Page from 'publisher/components/page'
import Summary from './components/Summary'
import Charts from './components/Charts'

export default function PublisherDashboard() {
  return (
    <Page width="xl">
      <Summary />
      <Charts />
    </Page>
  )
}
