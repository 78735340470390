import { useMemo } from 'react'
import Chart from 'shared/components/chart'
import useGetCSSColor from 'shared/hooks/useGetCSSColor'

export default function EarnedReferralPointsChart({ data, labels, getDate }) {
  const getCSSColor = useGetCSSColor()

  const config = useMemo(() => {
    return {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            label: 'Points',
            backgroundColor: getCSSColor('secondary', 0.2),
            borderColor: getCSSColor('secondary'),
            data: data.map((item) => item.points),
            fill: true,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          title: {
            text: 'Earned Referral Points',
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              title: (tooltipItems) => {
                return getDate(data[tooltipItems[0].dataIndex].date)
              },
            },
          },
        },
        scales: {
          x: {
            stacked: false,
          },
          y: {
            beginAtZero: true,
            stacked: false,
            ticks: {
              callback: function (value) {
                if (value % 1 === 0) {
                  return value
                }
              },
            },
          },
        },
      },
    }
  }, [getCSSColor, data, labels, getDate])

  return <Chart config={config} />
}
