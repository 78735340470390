type CreatePlaylistVariables = {
  name: string,
}

export default function createPlaylist(variables: CreatePlaylistVariables) {
  const { name } = variables

  return {
    path: '/playlists',
    params: {
      name,
    },
  }
}
