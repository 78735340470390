type SendMessageMutationVariables = {
  audienceType: string,
  canReply: boolean,
  message: string,
  subject: string,
  type: string,
  userGroup: string,
  userIds: Array<number>,
  userId: number,
}

export default function sendMessage(variables: SendMessageMutationVariables) {
  let {
    audienceType,
    canReply,
    message,
    subject,
    type,
    userGroup,
    userIds,
    userId,
  } = variables
  if (audienceType === 'single') {
    userIds = [userId]
    userGroup = 'custom'
  } else if (audienceType === 'many' && userGroup !== 'custom') {
    userIds = []
  }

  return {
    method: 'post',
    url: `/admin/messages`,
    variables: {
      can_reply: canReply,
      type,
      user_group: userGroup,
      user_ids: userIds,
      subject,
      message,
    },
  }
}
