type SaveEthAddressVariables = {
  ethAddress: string,
}

export default function saveEthAddress(
  variables: SaveEthAddressVariables = {}
) {
  const { ethAddress } = variables

  return {
    method: 'POST',
    path: '/publisher/profile/eth-address',
    params: {
      eth_address: ethAddress,
    },
  }
}
