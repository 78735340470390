type GetUsersQueryVariables = {
  page?: number,
  search?: string,
  isHero?: string,
  loginType?: string,
  sort?:
    | ''
    | 'latest_signup'
    | 'last_active'
    | 'most_like'
    | 'most_comment'
    | 'most_subscribes'
    | 'most_watch_hours'
    | 'most_referrals',
  onlyDeleted?: boolean,
  ref?: string,
  sub?: string,
}

export default function getUsers(variables: GetUsersQueryVariables = {}) {
  const { page, search, sort, isHero, loginType, onlyDeleted, ref, sub } =
    variables
  return {
    name: 'users',
    path: '/admin/users',
    params: {
      page,
      sort: sort,
      filters: {
        search,
        is_hero: isHero,
        login_type: loginType,
        only_deleted: onlyDeleted,
        ref,
        sub,
      },
    },
  }
}
