import { useMemo, useState } from 'react'
import env from 'core/env'
import useQuery from 'core/react-query/useQuery'
import cookie from 'core/cookie'
import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import CampaignStatus from 'admin/components/campaign-status'
import getCryptoCampaignsStatistics from 'admin/queries/getCryptoCampaignsStatistics'
import get from 'lodash/get'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import Field from 'shared/components/form/field'
import Modal from 'shared/components/kit/modal'
import Overview from './Overview'
import Chart from './Chart'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import classes from './Statistics.module.css'

const VALIDATION = {
  statisticsPeriod: {
    label: 'Statistics Period',
    rule: 'required',
  },
}

export default function Statistics({ campaign }) {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const { data, isLoading } = useQuery(getCryptoCampaignsStatistics, {
    variables: {
      id: campaign.id,
    },
  })

  const form = useMiniForm({
    resetOnSuccess: true,
    validation: VALIDATION,
    initialValues: useMemo(() => {
      return {
        statisticsPeriod: 'last_7d',
      }
    }, [campaign.id]),
    async onSubmit(values) {
      try {
        setLoading(true)

        let myHeaders = new Headers()
        myHeaders.append('Authorization', `Bearer ${cookie().get('jwt')}`)

        let requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        }
        const apiBase = env('API_URL')
        await fetch(
          `${apiBase}/admin/crypto-campaigns/${campaign.id}/statistics/export?filters[statistics_period]=${values.statisticsPeriod}`,
          requestOptions
        )
          .then((response) => response.blob())
          .then((blob) => {
            const href = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = href
            link.setAttribute(
              'download',
              `campaign-statistics-${values.statisticsPeriod}.xlsx`
            )
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
          .catch((error) => console.log('error', error))
        setVisible(false)
      } catch (error) {
        throw error
      } finally {
        setLoading(false)
      }
    },
  })

  const statistics = get(data, 'json', {})

  const handleCancel = () => {
    setVisible(false)
    form.reset()
  }

  const handleOk = () => {
    form.submit()
  }

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <Icon>
          <Spin />
        </Icon>
        <div>Loading...</div>
      </div>
    )
  }

  return (
    <>
      <div className={classes.header}>
        <div className={classes.title}>
          <span className="mr-4">Statistics: {campaign.name} </span>
          <CampaignStatus status={campaign.status} />
        </div>
        <Button onClick={() => setVisible(true)} outlined>
          Export to Excel
        </Button>
      </div>
      <div className={classes.body}>
        <Overview statistics={statistics} />
        <Chart campaignId={campaign.id} />
      </div>
      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        loading={loading}
        okText="Export"
        size="sm"
        title="Export campaign statistics as excel"
        visible={visible}>
        <form onSubmit={form.submit}>
          <Field
            label="Statistics Period"
            {...form.getErrorPropsFor('statisticsPeriod')}>
            <HtmlSelect
              value={form.values.statisticsPeriod}
              onChange={(val) => form.change('statisticsPeriod', val)}>
              <option value="last_7d">Last 7 Days</option>
              <option value="last_14d">Last 14 Days</option>
              <option value="last_30d">Last 30 Days</option>
              <option value="last_90d">Last 3 Months</option>
              <option value="last_180d">Last 6 Months</option>
              <option value="last_365d">Last 12 Months</option>
            </HtmlSelect>
          </Field>
        </form>
      </Modal>
    </>
  )
}
