// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "form.Filters_filters__2-NhO {\n  display: flex;\n  margin-bottom: 2rem;\n}\n\nform.Filters_filters__2-NhO .Filters_users__1BQsS {\n  margin-right: 1rem;\n  width: 20rem;\n}\n\nform.Filters_filters__2-NhO .Filters_status__OXs3R {\n  margin-right: 1rem;\n  width: 13rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/payments/components/Filters.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,mBAAmB;ADAnB;;AAEA;ECFA,kBAAmB;EAAnB,YAAmB;ADInB;;AAEA;ECNA,kBAAmB;EAAnB,YAAmB;ADQnB","sourcesContent":["form.filters {\n  @apply flex mb-8;\n}\n\nform.filters .users {\n  @apply mr-4 w-80;\n}\n\nform.filters .status {\n  @apply mr-4 w-52;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": "Filters_filters__2-NhO",
	"users": "Filters_users__1BQsS",
	"status": "Filters_status__OXs3R"
};
export default ___CSS_LOADER_EXPORT___;
