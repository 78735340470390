import Button from 'shared/components/kit/button'
import SlotsCalendar from './SlotsCalendar'
import PricesCalendar from './PricesCalendar'
import { useState } from 'react'
import dayjs from 'dayjs'
import classes from './BannerAdsCreateCampaign.module.css'

export default function Step1({
  changeStep,
  edited,
  form,
  onSelectedSlot,
  onClearSelection,
  selectedSlots,
  tiersData,
  slots,
  tiers,
}) {
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'))

  const next2Week = dayjs(startDate).add(14, 'day').format('YYYY-MM-DD')
  const prev2Week = dayjs(startDate).add(-14, 'day').format('YYYY-MM-DD')

  const handleClickPrev = () => {
    let today = dayjs().format('YYYY-MM-DD')
    if (prev2Week >= today) {
      setStartDate(prev2Week)
    }
  }

  const handleClickNext = () => {
    setStartDate(next2Week)
  }

  return (
    <div>
      <SlotsCalendar
        selectedSlots={selectedSlots}
        onClearSelection={
          form.values.status === 'archived' ? null : onClearSelection
        }
        onSelectedSlot={onSelectedSlot}
        tiersData={tiersData}
        startDate={startDate}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        slots={slots}
        tiers={tiers}
        minValidDate={prev2Week}
      />

      <PricesCalendar startDate={startDate} tiers={tiers} />
      <div className={classes.footerStep1}>
        <div className={classes.estimate}>
          <span>Estimated reach: 50,4k</span> |{' '}
          <span>Total Campaign Price: $ 5244</span>
        </div>
        <div className={classes.actions}>
          <Button disabled={!edited}>Save Changes</Button>
          <Button
            disabled={!form.getInputPropsFor('companyId').value}
            onClick={() => changeStep('2')}
            mood="secondary">
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}
