import Chart from 'shared/components/chart'
export default function ViewChart({ data, range, chartedData }) {
  return (
    <Chart
      config={{
        type: 'bar',
        data: chartedData(range, data),
        options: {
          tooltips: {
            callbacks: {
              label: (tooltipItem) => tooltipItem.value + ' Views',
            },
          },
          elements: {
            line: {
              tension: 0,
            },
            point: {
              radius: 0,
            },
          },
          responsive: true,
          legend: {
            position: 'top',
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  callback: function (value, index) {
                    if (range !== 'this_month' && range !== 'last_month') {
                      return value
                    } else if (index === 0 || (index + 1) % 5 === 0) {
                      return value
                    }
                  },
                  autoSkip: false,
                  maxRotation: 0,
                  minRotation: 0,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  callback: function (value) {
                    if (value % 1 === 0) {
                      return value
                    }
                  },
                },
              },
            ],
          },
          title: {
            display: false,
          },
        },
      }}
    />
  )
}
