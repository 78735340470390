import { createElement } from 'react'
import { Link, NavLink } from 'react-router-dom'
import classnames from 'classnames'
import Logo from 'shared/components/logo'
import Alert from 'shared/components/kit/alert'
import Icon from 'shared/components/kit/icon'
import useProfile from 'shared/hooks/useProfile'
import Button from 'shared/components/kit/button'
import { ReactComponent as ErrorSVG } from 'icons/error-circle-filled.svg'
import TopNavigation from 'publisher/components/layouts/TopNavigation'
import classes from './Base.module.css'

export default function PublisherBaseLayout({ main, sidebar }) {
  const { channelStatus, isPublisher, publisherRequest } = useProfile()

  let beforeTopNav

  const getFooter = (readMoreLink) => (
    <Link to={readMoreLink || '#'}>
      <Button secondary size="sm">
        Read more
      </Button>
    </Link>
  )

  if (!isPublisher) {
    if (publisherRequest.status === 'pending') {
      beforeTopNav = (
        <Alert
          content="Once your channel is approved and good to go, we will reach out to you by email."
          icon={<ErrorSVG />}
          type="info"
          title="YOUR PUBLISHER APPLICATION IS PENDING REVIEW"
        />
      )
    }
    if (publisherRequest.status === 'rejected') {
      beforeTopNav = (
        <Alert
          content="Sorry, your request to become a publisher has been rejected, for more details, please contact our support."
          icon={<ErrorSVG />}
          type="error"
          title="YOUR PUBLISHER APPLICATION IS REJECTED"
        />
      )
    }
  }

  if (isPublisher && channelStatus === 'freeze') {
    beforeTopNav = (
      <Alert
        content="You are not allowed to do actions in your publisher panel because your channel is frozen. For more details, please contact Today's Crypto support."
        icon={<ErrorSVG />}
        type="error"
        title="Your Channel is Frozen"
      />
    )
  }

  return (
    <>
      <div className={classes.body}>
        <TopNavigation />
        {beforeTopNav ? (
          <div className={classes.beforeTopNav}>{beforeTopNav}</div>
        ) : null}
        <div className={classes.main}>{main}</div>
      </div>

      <div className={classes.nav}>
        <Link className={classes.logo} to="/publisher">
          <Logo />
        </Link>
        {sidebar}
      </div>
    </>
  )
}

PublisherBaseLayout.NavLink = ({
  className,
  disabled,
  icon,
  image,
  text,
  to,
  ...props
}) => {
  return createElement(!disabled && to ? NavLink : 'a', {
    ...props,
    className: disabled
      ? classnames(className, classes.disabled)
      : (isActive) =>
          classnames(className, {
            [classes.active]: isActive,
            [classes.disabled]: disabled,
            [classes.enabled]: !disabled,
          }),
    to: disabled ? undefined : to,
    children: (
      <>
        {image ? (
          <span>{image}</span>
        ) : (
          <Icon className={classes.icon}>{icon}</Icon>
        )}
        <span className={classes.text}>{text}</span>
      </>
    ),
  })
}
