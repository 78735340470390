import { useEffect } from 'react'

export default function useForceColorScheme(colorScheme: 'light' | 'dark') {
  useEffect(() => {
    window.COLOR_SCHEME.force(colorScheme)

    return () => {
      window.COLOR_SCHEME.unForce()
    }
  }, [colorScheme])
}
