import { forwardRef } from 'react'
import classnames from 'classnames'
import classes from './index.module.css'

type Props = {
  checked?: boolean,
  className?: string,
  description?: string,
  onChange?: Function,
}

function Switch(props: Props, ref) {
  const { checked, className, description, size, onChange, ...rest } = props
  const handleChange = (event) => {
    onChange(event.target.checked)
  }

  return (
    <label className={classnames(className, classes.label)}>
      <div className={checked == true ? classes.active : undefined}>
        <input
          {...rest}
          checked={checked}
          onChange={handleChange}
          ref={ref}
          type="checkbox"
        />
      </div>
      {description ? <span>{description}</span> : null}
    </label>
  )
}

export default forwardRef(Switch)
