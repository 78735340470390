import { useCallback, useState } from 'react'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import SignIn from './SignIn'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'
import classes from './index.module.css'

export default function Login() {
  const location = useParsedLocation()
  const [form, setForm] = useState(location.queryParams.form || 'login')

  const handleRegister = useCallback(() => setForm('register'), [])

  const handleLogin = useCallback(() => setForm('login'), [])

  const handleForgot = useCallback(() => setForm('forgot'), [])

  return (
    <div className={classes.container}>
      {form === 'login' && (
        <SignIn onRegister={handleRegister} onForgot={handleForgot} />
      )}
      {form === 'register' && <SignUp onLogin={handleLogin} />}
      {form === 'forgot' && <ForgotPassword onLogin={handleLogin} />}
    </div>
  )
}
