// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChoosePositionInput_wrapper__2Yo8c {\n  border-color: var(--light-4);\n  border-radius: 0.75rem;\n  border-style: dashed;\n  border-width: 2px;\n  height: 24rem;\n  position: relative;\n}\n\n.ChoosePositionInput_wrapper__2Yo8c > div {\n  background-color: var(--light-4);\n  border-radius: 0.5rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 5rem;\n  position: absolute;\n  color: var(--light);\n  width: 9rem;\n  transition-property: all;\n  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n  transition-duration: 150ms;\n}\n\n.ChoosePositionInput_wrapper__2Yo8c > div:hover, .ChoosePositionInput_wrapper__2Yo8c > div:focus {\n  background-color: var(--neutral);\n}\n\n.ChoosePositionInput_wrapper__2Yo8c > div[data-selected='yes'] {\n  background-color: var(--primary);\n}\n", "",{"version":3,"sources":["webpack://src/publisher/components/studio/components/ChoosePositionInput.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,4BAAmB;EAAnB,sBAAmB;EAAnB,oBAAmB;EAAnB,iBAAmB;EAAnB,aAAmB;EAAnB,kBAAmB;ADAnB;;AAEA;ECFA,gCAAmB;EAAnB,qBAAmB;EAAnB,aAAmB;EAAnB,mBAAmB;EAAnB,uBAAmB;EAAnB,YAAmB;EAAnB,kBAAmB;EAAnB,mBAAmB;EAAnB,WAAmB;EAAnB,wBAAmB;EAAnB,wDAAmB;EAAnB,0BAAmB;ADInB;;ACJA;EAAA;AAAmB;;ADWnB;ECXA,gCAAmB;ADanB","sourcesContent":[".wrapper {\n  @apply border-2 border-dashed border-light-4 h-96 relative rounded-xl;\n}\n\n.wrapper > div {\n  @apply absolute bg-light-4 flex h-20 items-center justify-center rounded-lg text-light transition-all w-36;\n}\n\n.wrapper > div:hover,\n.wrapper > div:focus {\n  @apply bg-neutral;\n}\n\n.wrapper > div[data-selected='yes'] {\n  @apply bg-primary;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ChoosePositionInput_wrapper__2Yo8c"
};
export default ___CSS_LOADER_EXPORT___;
