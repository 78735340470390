import classnames from 'classnames'
import classes from './index.module.css'
import useColorScheme from '../../hooks/useColorScheme'

export default function Logo({ forceDarkMode, monoColor }) {
  const svgClassName = classnames(classes.logo, {
    [classes.forceDarkMode]: forceDarkMode,
    [classes.monoColor]: monoColor,
  })

  const scheme = useColorScheme()

  return scheme === 'dark' || forceDarkMode ? (
    <img src="/images/tc-logo-dark.png" alt="TodaysCrypto" />
  ) : (
    <img src="/images/tc-logo-light.png" alt="TodaysCrypto" />
  )

  return (
    <svg
      className={svgClassName}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 947.67 145.09">
      <path
        className={classes.path1}
        d="M55,126.64a3.54,3.54,0,0,1-3.45-3.44v-64H27.78a3.24,3.24,0,0,1-2.47-1,3.54,3.54,0,0,1-1-2.52V40.07a3.4,3.4,0,0,1,1-2.41,3.24,3.24,0,0,1,2.47-1h79.45a3.41,3.41,0,0,1,2.52,1,3.3,3.3,0,0,1,1,2.41V55.69a3.53,3.53,0,0,1-3.56,3.55H83.58v64a3.42,3.42,0,0,1-1,2.41,3.24,3.24,0,0,1-2.47,1Z"
        transform="translate(-24.33 -13.67)"
      />
      <path
        className={classes.path1}
        d="M155.22,118.83Q145.35,128,128.58,128t-26.52-9.19q-10-9.3-10-25.26t10-25.37Q112.17,59,128.58,59t26.64,9.18q10,9.3,10,25.37T155.22,118.83Zm-17.57-35.3q-3.21-3.61-9.07-3.62t-9,3.62q-3.21,3.61-3.22,10t3.22,10.05q3.21,3.61,9,3.62t9.07-3.62q3.22-3.62,3.22-10.05T137.65,83.53Z"
        transform="translate(-24.33 -13.67)"
      />
      <path
        className={classes.path1}
        d="M223.77,119c-1.08.84-2.15,1.66-3.22,2.47s-2.22,1.62-3.44,2.47Q210.9,128,200.92,128q-14,0-22.5-9.88-8.16-9.52-8.16-25.48t7.93-25.49q8.26-9.89,22.61-9.88a30.63,30.63,0,0,1,12.29,2.64,23.57,23.57,0,0,0,2.18,1,16.14,16.14,0,0,1,2.07,1V40a3.42,3.42,0,0,1,1-2.42,3.25,3.25,0,0,1,2.47-1h19.75A3.52,3.52,0,0,1,244,40V123.2a3.52,3.52,0,0,1-3.44,3.44H230a3.44,3.44,0,0,1-1.84-.57,3.31,3.31,0,0,1-1.26-1.27Zm-8-14.07c.65-.42,1.16-.78,1.55-1.09V80.14a10.89,10.89,0,0,1-1.32-.63c-.5-.27-1.06-.56-1.67-.86A11.09,11.09,0,0,0,208.27,77q-6.21,0-9.42,4.54T195.64,94q0,6.9,2.81,10.85a9.22,9.22,0,0,0,8,4,13.12,13.12,0,0,0,6.89-2.41C214.31,105.82,215.14,105.31,215.79,104.88Z"
        transform="translate(-24.33 -13.67)"
      />
      <path
        className={classes.path1}
        d="M298.68,123.2a13.2,13.2,0,0,1-1.2-1.27,63.25,63.25,0,0,1-11,4.37A42.47,42.47,0,0,1,274.51,128q-11.94,0-18.19-5.63t-6.26-15.15q0-10.21,9.53-16.77a38,38,0,0,1,10.62-4.76,41.13,41.13,0,0,1,13.15-1.32l9.64.46a6.46,6.46,0,0,0-2.41-5.28,10.31,10.31,0,0,0-6.32-1.73,35.59,35.59,0,0,0-5.74.46,27.81,27.81,0,0,0-5.62,1.61q-2.64,1-4.83,2c-1.45.62-2.83,1.23-4.13,1.84a3.14,3.14,0,0,1-2.7.17,3.3,3.3,0,0,1-2-1.89l-4.36-10a3.44,3.44,0,0,1-.06-2.07,3.57,3.57,0,0,1,.87-1.72,20.06,20.06,0,0,1,4-2.58,69.44,69.44,0,0,1,7.06-3.16,59.77,59.77,0,0,1,9.07-2.53,52.34,52.34,0,0,1,10-.91,61.33,61.33,0,0,1,14.69,1.54,25.62,25.62,0,0,1,10.22,4.88,19.88,19.88,0,0,1,6,8.67,37.83,37.83,0,0,1,2,12.92v23.54l.81.69.8.57a3.53,3.53,0,0,1,2.13.63,3.33,3.33,0,0,1,1.32,3.5,3.25,3.25,0,0,1-1.27,1.84l-12.17,10.1a15.21,15.21,0,0,1-10.1-3.44Q299.31,123.77,298.68,123.2Zm-7.06-15.45,1.72-.74v-5.4H293q-17.56,0-17.57,5.63c0,1.91,2,2.87,5.86,2.87a22.31,22.31,0,0,0,8.27-1.5C290.32,108.31,291,108,291.62,107.75Z"
        transform="translate(-24.33 -13.67)"
      />
      <path
        className={classes.path1}
        d="M392,60.28a3.26,3.26,0,0,1,3,1.49,3.41,3.41,0,0,1,.11,3.33l-32.26,73.14a3.35,3.35,0,0,1-1.32,1.43,3.49,3.49,0,0,1-1.9.63H340.76a3.39,3.39,0,0,1-3-1.49,3.07,3.07,0,0,1-.12-3.33l7.7-17.56L318,65.33a3.37,3.37,0,0,1-.11-3.5,3.29,3.29,0,0,1,3.1-1.55h22.73a3.49,3.49,0,0,1,1.89.63,2.65,2.65,0,0,1,1.21,1.43L359,89l11.14-26.52a3.2,3.2,0,0,1,1.32-1.55,3.49,3.49,0,0,1,1.89-.63Z"
        transform="translate(-24.33 -13.67)"
      />
      <path
        className={classes.path1}
        d="M450.87,123.59q-7.25,5.52-20.1,5.51a83.8,83.8,0,0,1-13.14-1,70.5,70.5,0,0,1-11.31-2.7,3.13,3.13,0,0,1-1.5-1,3.27,3.27,0,0,1-.8-1.73l-1.49-14.12a3.35,3.35,0,0,1,1.2-3.21,3,3,0,0,1,3.39-.23q1,.45,3.21,1.32t5.63,2.12a35.73,35.73,0,0,0,13.21,2.53,11.69,11.69,0,0,0,3.44-.4q1.26-.41,1.26-1.32c0-1-1.05-1.92-3.16-2.76s-4.95-1.91-8.55-3.21v.11a51.82,51.82,0,0,1-11.25-5.74q-7.7-4.94-7.69-15.73a22.36,22.36,0,0,1,1.95-9.64,18.2,18.2,0,0,1,6.2-7.12,26.21,26.21,0,0,1,8.61-4A41.7,41.7,0,0,1,430.77,60a39.06,39.06,0,0,1,5.57.4c1.88.27,3.67.59,5.4,1a48.24,48.24,0,0,1,4.76,1.32c1.46.5,2.72,1,3.79,1.44a2.37,2.37,0,0,1,1.38,1.26,4.07,4.07,0,0,1,.57,2V80.88A3.2,3.2,0,0,1,450.52,84a3.11,3.11,0,0,1-3.44-.4c-.31-.16-1-.52-2-1.09s-2.43-1.28-4.19-2.13a21.66,21.66,0,0,0-9.65-2.52,5.23,5.23,0,0,0-2.75.63,1.76,1.76,0,0,0-1,1.43q0,1.62,3.05,3.16a106.07,106.07,0,0,0,10.16,4.19q8.27,3.1,12.86,8a17.48,17.48,0,0,1,4.59,12.46Q458.1,118.09,450.87,123.59Z"
        transform="translate(-24.33 -13.67)"
      />
      <path
        className={classes.path2}
        d="M411.14,34.12a1.57,1.57,0,0,1,.68-.56,2.14,2.14,0,0,1,.88-.22l11.83,0a1.77,1.77,0,0,1,1.73.93,1.52,1.52,0,0,1-.35,1.86L414,48.75a2,2,0,0,1-.68.39,2.14,2.14,0,0,1-.75.19l-8,0a1.73,1.73,0,0,1-1.66-.84,1.58,1.58,0,0,1,.1-1.75Z"
        transform="translate(-24.33 -13.67)"
      />
      <rect
        className={classes.path2}
        x="455.85"
        width="491.82"
        height="145.09"
        rx="25.98"
      />
      <path
        className={classes.path3}
        d="M536.12,82.49A22.62,22.62,0,0,0,538,92a20.59,20.59,0,0,0,5.34,7.18q7.33,6.19,20.2,6.2a70.14,70.14,0,0,0,17.11-2.07h-.23q4.37-1.26,7.87-2.3a60.69,60.69,0,0,0,6.25-2.18,3.49,3.49,0,0,1,4.83,3.33L599,119.34a3.29,3.29,0,0,1-.69,1.9,3.49,3.49,0,0,1-1.6,1.32,89.16,89.16,0,0,1-17.28,4.25,139.35,139.35,0,0,1-20.27,1.37q-25.38,0-39.73-11.48-15-11.72-15-33.52A55.74,55.74,0,0,1,508,62.51a39,39,0,0,1,10.45-15,46,46,0,0,1,16.88-9.24,75.21,75.21,0,0,1,22.73-3.16,124.22,124.22,0,0,1,19.87,1.43A98.27,98.27,0,0,1,592.84,40a2.89,2.89,0,0,1,1.61,1.14,3.85,3.85,0,0,1,.8,1.84l1.27,16.88A3.19,3.19,0,0,1,595.25,63a3.35,3.35,0,0,1-3.33.4q-5.05-1.84-13-3.91a73.11,73.11,0,0,0-7.75-1.6,51.54,51.54,0,0,0-7.75-.58q-12.86,0-20,6.55t-7.17,18.6Z"
        transform="translate(-24.33 -13.67)"
      />
      <path
        className={classes.path3}
        d="M607.65,126.81a3.09,3.09,0,0,1-2.24-1,3.34,3.34,0,0,1-1-2.47V64.12a3.22,3.22,0,0,1,1-2.36,3.08,3.08,0,0,1,2.24-1h13.43a3,3,0,0,1,1.84.69,2.89,2.89,0,0,1,1.15,1.6L626.48,71a35.61,35.61,0,0,1,8.1-8.67,14.85,14.85,0,0,1,8.89-3.05,9,9,0,0,1,3.91.87,14.9,14.9,0,0,1,5.68,4.59,13.21,13.21,0,0,1,1.44,2.35l-4.83,18.6a3.3,3.3,0,0,1-2.52,2.64A3.06,3.06,0,0,1,643.82,87q-3-3.91-5.51-3.91-2.07,0-4.6,2.53a34.79,34.79,0,0,0-3.78,4.25v33.52a3.35,3.35,0,0,1-1,2.47,3.22,3.22,0,0,1-2.35,1Z"
        transform="translate(-24.33 -13.67)"
      />
      <path
        className={classes.path3}
        d="M735.67,60.44a3.25,3.25,0,0,1,3,1.5,3.41,3.41,0,0,1,.11,3.33L706.51,138.4a3.31,3.31,0,0,1-1.32,1.44,3.49,3.49,0,0,1-1.9.63H684.46a3.41,3.41,0,0,1-3-1.49,3.07,3.07,0,0,1-.12-3.33l7.7-17.57L661.73,65.49a3.37,3.37,0,0,1-.11-3.5,3.3,3.3,0,0,1,3.09-1.55h22.74a3.49,3.49,0,0,1,1.89.63,2.74,2.74,0,0,1,1.21,1.44l12.17,26.64,11.14-26.53a3.24,3.24,0,0,1,1.32-1.55,3.49,3.49,0,0,1,1.89-.63Z"
        transform="translate(-24.33 -13.67)"
      />
      <path
        className={classes.path3}
        d="M777.18,123.36a30.48,30.48,0,0,1-7.87-3v15.84a3.43,3.43,0,0,1-1,2.53,3.29,3.29,0,0,1-2.41,1H746a3.29,3.29,0,0,1-2.41-1,3.43,3.43,0,0,1-1-2.53V63.08a3.43,3.43,0,0,1,1-2.52,3.3,3.3,0,0,1,2.41-1h12.17a3.06,3.06,0,0,1,1.67.58,5.73,5.73,0,0,1,1.32,1.15l2.64,4.36a30.83,30.83,0,0,1,10-5.74,34.2,34.2,0,0,1,10.91-1.72q13.89,0,22.73,9.64v-.11q8.84,9.18,8.84,24,0,14.24-8.5,23.43t-21.93,9.18A38.78,38.78,0,0,1,777.18,123.36ZM773.56,79a17.5,17.5,0,0,0-4.36,3.1v19.52a26.6,26.6,0,0,0,4.24,2.53,16,16,0,0,0,7.12,1.83,9.24,9.24,0,0,0,7.7-3.5Q791,99,791,92.94q0-6.78-3.16-11a9.88,9.88,0,0,0-8.32-4.25A12.69,12.69,0,0,0,773.56,79Z"
        transform="translate(-24.33 -13.67)"
      />
      <path
        className={classes.path3}
        d="M856,107.57a2.45,2.45,0,0,0,2.06,1.1,15.45,15.45,0,0,0,5.17-.81c1.53-.53,2.72-1,3.56-1.38a3,3,0,0,1,3.16.23,3.48,3.48,0,0,1,1.43,3v13.66a3.75,3.75,0,0,1-.57,1.9,2.45,2.45,0,0,1-1.38,1.2,14.89,14.89,0,0,1-2.93,1c-1.18.31-2.58.65-4.19,1s-3.4.69-4.93.92a31.06,31.06,0,0,1-4.6.34q-12.28,0-17.85-4.71t-5.57-15.84V80h-9.3a3.19,3.19,0,0,1-2.3-1,3.43,3.43,0,0,1-1-2.53V64.12a3.24,3.24,0,0,1,1-2.47,3.29,3.29,0,0,1,2.3-1h9.3V46.44a3.52,3.52,0,0,1,.86-2.3,3,3,0,0,1,2-1.15l19.17-3.33a2.89,2.89,0,0,1,2.76.75,3.59,3.59,0,0,1,1.15,2.7l-.12,17.56h12.86a3.24,3.24,0,0,1,2.36,1,3.34,3.34,0,0,1,1,2.47V76.4a3.54,3.54,0,0,1-1,2.53,3.14,3.14,0,0,1-2.36,1H855.19V104.3A5.43,5.43,0,0,0,856,107.57Z"
        transform="translate(-24.33 -13.67)"
      />
      <path
        className={classes.path3}
        d="M937.17,119q-9.87,9.18-26.64,9.18T884,119q-10-9.3-10-25.26t10-25.38q10.11-9.18,26.52-9.18t26.64,9.18q10,9.3,10,25.38T937.17,119ZM919.6,83.69q-3.21-3.61-9.07-3.61t-8.95,3.61q-3.23,3.61-3.22,10c0,4.29,1.07,7.63,3.22,10.05s5.13,3.61,8.95,3.61,6.93-1.2,9.07-3.61,3.22-5.76,3.22-10.05S921.75,86.1,919.6,83.69Z"
        transform="translate(-24.33 -13.67)"
      />
    </svg>
  )
}
