import { useState } from 'react'
import classnames from 'classnames'
import Popover from 'shared/components/kit/popover'
import truncate from 'helpers/utils/truncate'
import UserAvatar from 'shared/components/user-avatar'
import getUserName from 'helpers/utils/getUserName'
import ChannelAvatar from 'shared/components/channel-avatar'
import classes from './AudiencePopover.module.css'

export default function AudiencesPopover({ message }) {
  const [showPopover, setShowPopover] = useState(false)
  const handleTogglePopover = (event) => {
    event.preventDefault()
    setShowPopover((prev) => !prev)
  }

  const handleHidePopover = () => {
    setShowPopover(false)
  }

  const audienceList = (message) => (
    <ul className={classes.audiencePopover}>
      {message.to.map((to, i) => (
        <li key={i}>
          {to.channel ? (
            <>
              <ChannelAvatar
                className={classes.channelAvatar}
                channel={to.channel}
              />{' '}
              &nbsp;{' '}
              <span title={to.channel.name.length > 25 ? to.channel.name : ''}>
                {truncate(to.channel.name, 25)}
              </span>
            </>
          ) : (
            <>
              <UserAvatar className={classes.avatar} user={to} />
              <span> &nbsp; {getUserName(to)}</span>
            </>
          )}
        </li>
      ))}
    </ul>
  )

  return (
    <Popover
      content={audienceList(message)}
      onHide={handleHidePopover}
      visible={showPopover && message.to.length > 1}>
      <div className={classes.avatars} onClick={handleTogglePopover}>
        {message.to.length > 1 ? (
          message.to.map((to, i) => {
            return i < 3 ? (
              to.channel ? (
                <ChannelAvatar
                  key={i}
                  className={classnames(classes.avatar, 'cursor-pointer')}
                  channel={to.channel}
                />
              ) : (
                <UserAvatar
                  key={i}
                  className={classnames(classes.avatar, 'cursor-pointer')}
                  user={to}
                />
              )
            ) : null
          })
        ) : (
          <div className={classes.toAvatars}>
            {message.to.length === 1 ? (
              <>
                {message.to.channel ? (
                  <>
                    <ChannelAvatar
                      className={classes.channelAvatar}
                      channel={message.to[0].channel}
                    />
                    <>&nbsp; {message.to[0].channel.name}</>
                  </>
                ) : (
                  <>
                    <UserAvatar
                      className={classes.avatarImg}
                      user={message.to[0]}
                    />
                    <>&nbsp; {getUserName(message.to[0])}</>
                  </>
                )}
              </>
            ) : (
              <>All </>
            )}
          </div>
        )}
        {message.to.length > 3 ? (
          <span className={classes.moreUsers}>
            {' '}
            and {message.to.length - 3} more{' '}
          </span>
        ) : null}
      </div>
    </Popover>
  )
}
