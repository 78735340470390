import { useHistory, useParams } from 'react-router'
import useProfile2FA from 'shared/hooks/useProfile2FA'
import Accordion from 'shared/components/kit/accordion'
import Page from 'admin/components/page'
import TwoFactorAuthentication from 'publisher/pages/profile/components/TwoFactorAuthentication'
import Status from 'publisher/pages/profile/components/Status'

export default function Security() {
  const { active } = useParams()
  const history = useHistory()
  const { appEnabled, emailEnabled, isLoading: isLoading2FA } = useProfile2FA()

  const handleTabChange = (val) => {
    history.push(`/admin/setting/security/${val}`)
  }

  const twoFaActive = emailEnabled || appEnabled

  const twoFactorAuthenticationStatus = (
    <Status
      active={twoFaActive}
      text={twoFaActive ? 'Active' : 'Inactive'}
      extra={twoFaActive ? `(${emailEnabled && appEnabled ? 2 : 1}/2)` : null}
    />
  )

  return (
    <Page title="Security Settings" width="sm">
      <Accordion
        activeName={active}
        onChange={handleTabChange}
        bordered={false}>
        <Accordion.Item
          name="2fa"
          title="Two-Factor Authentication (2FA)"
          extra={twoFactorAuthenticationStatus}>
          {isLoading2FA ? 'Loading...' : <TwoFactorAuthentication />}
        </Accordion.Item>
      </Accordion>
    </Page>
  )
}
