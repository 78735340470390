import Field from 'shared/components/form/field'
import Select from 'shared/components/form/select'

const OPTIONS = [
  {
    label: 'Video',
    value: 'video',
  },
  {
    label: 'Podcast',
    value: 'podcast',
  },
]

export default function SelectMediaType(props) {
  return <Select {...props} options={OPTIONS} />
}

SelectMediaType.Field = ({ error, help, label, ...inputProps }) => (
  <Field error={error} help={help} label={label}>
    <SelectMediaType {...inputProps} />
  </Field>
)

SelectMediaType.Field.defaultProps = {
  label: 'Select Media Type',
}
