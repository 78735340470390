type GetVideoSubtitles = {
  id: number,
}

export default function getVideoSubtitles(variables: GetVideoSubtitles = {}) {
  const { id } = variables
  return {
    name: 'videoSubtitles',
    path: `/videos/${id}/subtitles`,
  }
}
