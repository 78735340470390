type AssignCategoryToVideo = {
  videoIds: Array<number>,
  categoryId: number,
}

export default function assignCategoryToVideo(
  variables: AssignCategoryToVideo = {}
) {
  const { videoIds, categoryId } = variables
  return {
    method: 'PUT',
    path: '/admin/videos/bulk-assign-category',
    params: {
      media_ids: videoIds,
      category_id: categoryId,
    },
  }
}
