// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page_header__2pAa7 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 2rem;\n  background-color: var(--publisher-panel-bg);\n  min-height: 42px;\n  /*top: var(--publisher-panel-top-nav-height);*/\n}\n\n.page_header__2pAa7 .page_text__2JRYX {\n  color: var(--overlay-color-muted-text);\n}\n\n.page_header__2pAa7 .page_text__2JRYX h1 {\n  font-family: Ubuntu, sans-serif;\n  font-size: 1.5rem;\n  line-height: 2rem;\n  padding: 5px 0;\n}\n\n.page_header__2pAa7 .page_actions__wnC8y > .button {\n  margin-left: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/components/page/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,mBAAmB;EAAnB,8BAAmB;EAAnB,mBAAmB;EDAjB,2CAA2C;EAC3C,gBAAgB;EAChB,8CAA8C;AAChD;;AAEA;ECLA,sCAAmB;ADOnB;;AAEA;ECTA,+BAAmB;EAAnB,iBAAmB;EAAnB,iBAAmB;EDWjB,cAAc;AAChB;;AAEA;ECdA,iBAAmB;ADgBnB","sourcesContent":[".header {\n  @apply flex items-center justify-between mb-8;\n  background-color: var(--publisher-panel-bg);\n  min-height: 42px;\n  /*top: var(--publisher-panel-top-nav-height);*/\n}\n\n.header .text {\n  @apply text-overlayColor-mutedText;\n}\n\n.header .text h1 {\n  @apply font-heading text-2xl;\n  padding: 5px 0;\n}\n\n.header .actions > :global(.button) {\n  @apply ml-4;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "page_header__2pAa7",
	"text": "page_text__2JRYX",
	"actions": "page_actions__wnC8y"
};
export default ___CSS_LOADER_EXPORT___;
