// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "form > span {\n  color: var(--overlay-color-muted-text);\n}\n\nform .SendUserMessageButton_audienceType__1meKB {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/messages/components/SendUserMessageButton.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,sCAAmB;ADAnB;;AAEA;ECFA,aAAmB;EAAnB,8BAAmB;EAAnB,mBAAmB;ADInB","sourcesContent":["form > span {\n  @apply text-overlayColor-mutedText;\n}\n\nform .audienceType {\n  @apply mb-4 flex justify-between;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"audienceType": "SendUserMessageButton_audienceType__1meKB"
};
export default ___CSS_LOADER_EXPORT___;
