import classnames from 'classnames'
import getUserAvatar from 'helpers/utils/getUserAvatar'
import getUserName from 'helpers/utils/getUserName'
import Icon from 'shared/components/kit/icon'
import { ReactComponent as HeroSVG } from 'svg/hero.svg'
import classes from './index.module.css'

export default function UserAvatar({ className, user }) {
  return (
    <figure className={classnames(classes.wrapper, className)}>
      <img
        className="rounded-full"
        src={getUserAvatar(user?.avatar)}
        alt={getUserName(user)}
      />
      {user?.is_hero && (
        <Icon className={classes.badge}>
          <HeroSVG />
        </Icon>
      )}
    </figure>
  )
}
