// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card_card__FyiRf {\n  --tw-bg-opacity: 1;\n  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));\n  --tw-border-opacity: 1;\n  border-color: rgba(243, 244, 246, var(--tw-border-opacity));\n  border-radius: 0.5rem;\n  border-width: 1px;\n  padding: 1rem;\n  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);\n  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/kit/card/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,kBAAmB;EAAnB,2DAAmB;EAAnB,sBAAmB;EAAnB,2DAAmB;EAAnB,qBAAmB;EAAnB,iBAAmB;EAAnB,aAAmB;EAAnB,oFAAmB;EAAnB,uGAAmB;ADAnB","sourcesContent":[".card {\n  @apply bg-white border border-gray-100 p-4 rounded-lg shadow-lg;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "card_card__FyiRf"
};
export default ___CSS_LOADER_EXPORT___;
