type readAllNotificationsMutateVariables = {
  scope: string,
}

export default function readAllNotifications(
  variables: readAllNotificationsMutateVariables
) {
  const { scope } = variables
  return {
    method: 'put',
    url: `/notifications/${scope}/read`,
  }
}
