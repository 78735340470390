import { useState } from 'react'
import useMutation from 'core/useMutation'
import useMiniForm from 'core/useMiniForm'
import sendMessage from 'admin/queries/sendMessage'
import notification from 'shared/components/kit/notification'
import Checkbox from 'shared/components/form/checkbox'
import Box from 'shared/components/kit/box'
import Button from 'shared/components/kit/button'
import Drawer from 'shared/components/kit/drawer'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import SelectDepartment from 'admin/components/form/select-department'
import RichText from 'shared/components/form/rich-text'

const validation = {
  departmentId: {
    label: 'Department',
    rule: 'required',
  },
  subject: {
    label: 'Subject',
    rule: 'required',
  },
  message: {
    label: 'Message',
    rule: 'required',
  },
}

const initialValues = {
  canReply: true,
  type: '',
}

export default function SendMessage({ channel }) {
  const [visible, setVisible] = useState(false)
  const mutation = useMutation(sendMessage, {
    onSuccess: () => {
      setVisible(false)
      notification.success({
        title: 'Message sent successfully!',
      })
    },
  })

  const form = useMiniForm({
    resetOnSuccess: true,
    validation,
    initialValues,
    async onSubmit(values) {
      try {
        await mutation.mutateAsync({
          ...values,
          userId: channel.owner.id,
          audienceType: 'single',
        })
      } catch (error) {
        throw error
      }
    },
  })

  const handleCloseCreateMessageDrawer = () => {
    form.reset()
    setVisible(false)
  }

  const handleShowCreateMessageDrawer = () => {
    setVisible(true)
  }

  return (
    <>
      <Box className="p-6">
        <h4 className="mb-2">Send a Message to Publisher</h4>
        <p className="h-16 text-overlayColor-mutedText">
          Send a message to this channel's publisher or check channel social
          media pages.
        </p>
        <footer className="flex justify-between">
          <span />
          <Button
            onClick={handleShowCreateMessageDrawer}
            size="sm"
            outlined
            secondary>
            Send a Message
          </Button>
        </footer>
      </Box>
      <Drawer
        title="Send a message"
        visible={visible}
        onClose={handleCloseCreateMessageDrawer}
        loading={form.submitting}>
        <form onSubmit={form.submit}>
          <Field label="Department" {...form.getErrorPropsFor('departmentId')}>
            <SelectDepartment
              key={visible ? '1' : '0'}
              {...form.getInputPropsFor('departmentId')}
              isClearable
            />
          </Field>
          <Field label="Subject" {...form.getErrorPropsFor('subject')}>
            <Input
              {...form.getInputPropsFor('subject')}
              placeholder="Subject..."
            />
          </Field>
          <Field label="Message" {...form.getErrorPropsFor('message')}>
            <RichText
              {...form.getInputPropsFor('message')}
              placeholder="Write your message here..."
              multiline
            />
          </Field>
          <Field>
            <Checkbox
              description="Warning message?"
              checked={form.get('type') === 'warning'}
              onChange={(checked) => {
                if (checked) {
                  form.change('type', 'warning')
                } else {
                  form.change('type', '')
                }
              }}
            />
          </Field>
          <Field>
            <Checkbox
              description="Can reply to this message?"
              checked={form.get('canReply') === true}
              onChange={(checked) => form.change('canReply', checked)}
            />
          </Field>
          <Button type="submit">Send Message</Button>
        </form>
      </Drawer>
    </>
  )
}
