import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Select from 'shared/components/form/select'
import SelectVideo from 'publisher/components/form/select-video'
import SelectCommentDates from 'publisher/pages/comments/shared/components/SelectCommentDates'
import classes from './Filters.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    async onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit({ ...values })
    },
  })

  const sortOptions = [
    {
      label: 'Most Liked',
      value: 'most_liked',
    },
    {
      label: 'Oldest',
      value: 'oldest',
    },
  ]

  const sizeOptions = [
    {
      label: '50',
      value: '50',
    },
    {
      label: '75',
      value: '75',
    },
    {
      label: '100',
      value: '100',
    },
  ]

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <SelectVideo
        className={classes.video}
        placeholder="Search media..."
        isClearable={true}
        {...form.getInputPropsFor('video')}
      />

      <SelectCommentDates
        className={classes.select}
        placeholder="Any time..."
        isClearable={true}
        {...form.getInputPropsFor('time')}
      />

      <Select
        placeholder="Latest..."
        options={sortOptions}
        className={classes.select}
        isClearable={true}
        {...form.getInputPropsFor('sort')}
      />

      <Select
        placeholder="50"
        options={sizeOptions}
        className={classes.perPageSelect}
        {...form.getInputPropsFor('perPage')}
      />

      <Button mood="secondary">Apply</Button>
    </form>
  )
}
