type NotifyEmailsListTypeVariables = {
  location: string,
  page?: number,
}
export default function getNotifyEmailsList(
  variables: NotifyEmailsListTypeVariables = {}
) {
  const { location, page } = variables

  return {
    path: '/admin/mail-list',
    params: {
      page: page > 1 ? page : undefined,
      filters: {
        location: location,
      },
    },
  }
}
