import { useState } from 'react'
import { useHistory } from 'react-router'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import useProfile from 'shared/hooks/useProfile'
import useRefetchProfile from 'shared/hooks/useRefetchProfile'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import Button from 'shared/components/kit/button'
import getProfile from 'shared/queries/getProfile'
import formatDate from 'helpers/utils/formatDate'
import { ReactComponent as YouTubeSVG } from 'svg/youtube.svg'
import { ReactComponent as CheckSVG } from 'icons/check.svg'
import classes from './YouTubeSync.module.css'

export default function YouTubeSync() {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { user, channel } = useProfile()
  const refetchProfile = useRefetchProfile()

  const [visible, setVisible] = useState(false)

  const { isLoading, mutateAsync } = useMutation(requestSync)

  const { isLoading: autoIsLoading, mutateAsync: autoMutateAsync } =
    useMutation(toggleAutoImport, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          query: getProfile,
        })
      },
    })

  const handleShow = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleAutoImport = async () => {
    try {
      await autoMutateAsync({
        autoImport: !user.channel_auto_import_is_active,
      })
    } catch (error) {
      debugger
      notification.error({
        title: error.json.message,
      })
    }
  }

  const handleOk = async () => {
    try {
      await mutateAsync()
      await refetchProfile()
      setVisible(false)
      history.push('/publisher/media')
      notification.success({
        title: 'Your sync request has sent successfully!',
      })
    } catch (error) {
      notification.error({
        title: error.json.message,
      })
    }
  }

  if (!channel?.youtube_last_scraped_at) {
    return null
  }

  return (
    <>
      {user.channel_auto_import_is_active && (
        <li className={classes.autoImportItem}>
          <CheckSVG />
          <div className="text-successColor-darker uppercase">
            Auto import enabled
          </div>
        </li>
      )}
      <li className={classes.li}>
        <a onClick={handleShow}>
          <YouTubeSVG />
          <span className="text-white">IMPORT MEDIA</span>
        </a>
      </li>
      <Modal
        okText="Start Import"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        submitting={isLoading}
        title="Import Media"
        visible={visible}>
        <p>Let's import your latest YouTube media.</p>
        <p>Last import: {formatDate(channel?.youtube_last_scraped_at)}</p>
        {user.channel_auto_import_is_active && (
          <p>Next import: {formatDate(channel?.youtube_next_scrap_at)}</p>
        )}
        <p className={classes.autoImport}>
          {user.channel_auto_import_is_active ? (
            <>
              <div>
                <p>AUTO IMPORT ENABLED</p>
                <Button
                  loading={autoIsLoading}
                  onClick={handleAutoImport}
                  size="sm">
                  disable
                </Button>
              </div>
              <small>
                We will automatically import and publish your latest content
                from YouTube. If you wish to import before the next planned time
                you can do so by clicking the START IMPORT button.
              </small>
            </>
          ) : (
            <>
              <div>
                <p>ENABLE AUTO IMPORT</p>
                <Button
                  className={classes.enabledButton}
                  loading={autoIsLoading}
                  onClick={handleAutoImport}
                  size="sm">
                  enable
                </Button>
              </div>
              <small>
                When auto import is enabled, we will automatically import and
                publish your latest content from YouTube.
              </small>
            </>
          )}
        </p>
      </Modal>
    </>
  )
}

function requestSync() {
  return {
    method: 'put',
    path: '/publisher/yi/channels/sync-request',
  }
}

type ToggleAutoImport = {
  autoImport: boolean,
}

function toggleAutoImport(variables: ToggleAutoImport) {
  const { autoImport } = variables
  return {
    method: 'put',
    path: '/publisher/yi/channels/auto-import',
    params: {
      active: autoImport,
    },
  }
}
