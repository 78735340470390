import { useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import useMutation from 'core/react-query/useMutation'
import useFetcher from 'core/useFetcher'
import useQueryClient from 'core/react-query/useQueryClient'
import useMiniForm from 'core/useMiniForm'
import useProfile from 'shared/hooks/useProfile'
import Button from 'shared/components/kit/button'
import notification from 'shared/components/kit/notification'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import getProfile from 'shared/queries/getProfile'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import EmailVerificationModal from 'shared/components/email-verification-modal'
import Modal from 'shared/components/kit/modal'
import Page from 'publisher/components/page'
import saveEthAddress from 'publisher/mutations/saveEthAddress'
import savePaymentDetails from 'publisher/mutations/savePaymentDetails'
import HowItWorksModal from 'publisher/components/how-it-works-modal'
import verifyAddress from 'publisher/mutations/verifyAddress'
import { ReactComponent as IdenfySVG } from 'svg/idenfy.svg'
import { ReactComponent as CheckSVG } from 'svg/check-circle-filled.svg'
import classes from './index.module.css'

const validation = {
  firstName: {
    label: 'First name',
    rule: 'required',
  },
  lastName: {
    label: 'Last name',
    rule: 'required',
  },
  companyName: {
    label: 'Company name',
  },
  streetAddress: {
    label: 'Street address',
    rule: 'required',
  },
  streetNo: {
    label: 'Street no',
    rule: 'required',
  },
  postalCode: {
    label: 'Postal code',
    rule: 'required',
  },
  city: {
    label: 'City',
    rule: 'required',
  },
  country: {
    label: 'Country',
    rule: 'required',
  },
  vatNumber: {
    label: 'Vat number',
  },
  ethAddress: {
    label: 'ERC20 wallet address',
    rule: 'required',
  },
}
export default function PaymentDetails() {
  const queryClient = useQueryClient()
  const fetcher = useFetcher()
  const { user } = useProfile()
  const [ethAddress, setEthAddress] = useState()
  const [ethAddressError, setEthAddressError] = useState()
  const [idenfyUrl, setidenfyUrl] = useState()
  const [howItWorksVisible, setHowItWorksVisible] = useState(false)
  const [proofCode, setProofCode] = useState()
  const [isLoadingGetQR, setIsLoadingGetQR] = useState(false)
  const [showEmailVerification, setShowEmailVerification] = useState(false)
  const [confirmBillingVisible, setConfirmBillingVisible] = useState(false)
  const [lastIdentityVerifiedAt, setLastIdentityVerifiedAt] = useState(
    user.identity_verified_at
  )
  const [showQRCodeBox, setShowQRCodeBox] = useState(
    (!isLoadingGetQR && !!idenfyUrl) || !user.identity_verified_at
  )

  useEffect(() => {
    if (!user.identity_verified_at) {
      setIsLoadingGetQR(true)
      fetcher('/identify/verification-url').then((res) => {
        setIsLoadingGetQR(false)
        setidenfyUrl(res.json)
      })
    }
  }, [])

  useEffect(() => {
    let interval
    if (showQRCodeBox) {
      interval = setInterval(() => {
        queryClient.invalidateQueries({
          query: getProfile,
        })
      }, 5000)
      if (user.identity_verified_at !== lastIdentityVerifiedAt) {
        setShowQRCodeBox(false)
        clearInterval(interval)
      }
    }
    return () => {
      clearInterval(interval)
    }
  }, [showQRCodeBox, user])

  const paymentDetails =
    user.last_payment_details && user.last_payment_details.status === 'new'
      ? user.last_payment_details
      : user.verified_payment_details

  const handleCloseHowItWorks = () => {
    setHowItWorksVisible(false)
  }

  const links = (
    <>
      <Button component="link" to="/publisher/monetize" outlined secondary>
        Points & Earn
      </Button>
      <Button component="link" to="/publisher/payment-details" mood="neutral">
        Payment Details
      </Button>
      <Button component="link" to="/publisher/payout" outlined secondary>
        Payout History
      </Button>
      <Button onClick={() => setHowItWorksVisible(true)}>How it works?</Button>
    </>
  )

  const verifyAddressMutation = useMutation(verifyAddress, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getProfile,
      })
      notification.success({
        title: 'Proof of Address successfully completed!',
      })
      setProofCode('')
    },
    onError: (error) => {
      if (error.json.errors) {
        Object.values(error.json.errors).forEach((er) =>
          notification.error({ title: er })
        )
      } else {
        notification.error({
          title: 'Invalid verification code, please check code and try again.',
        })
      }
      setProofCode('')
    },
  })

  const mutation = useMutation(savePaymentDetails, {
    onSuccess: () => {
      notification.success({
        title: 'Address details preliminary saved!',
      })
      queryClient.invalidateQueries({
        query: getProfile,
      })
      setConfirmBillingVisible(false)
    },
  })

  const saveEthMutation = useMutation(saveEthAddress, {
    onSuccess: () => {
      notification.success({
        title: 'ETH-address saved successfully!',
      })
      queryClient.invalidateQueries({
        query: getProfile,
      })
    },
  })

  const initialValues = useMemo(() => {
    setEthAddress(paymentDetails?.eth_address)
    return {
      firstName: paymentDetails?.first_name,
      lastName: paymentDetails?.last_name,
      companyName: paymentDetails?.company_name,
      streetAddress: paymentDetails?.street_address,
      streetNo: paymentDetails?.street_number,
      postalCode: paymentDetails?.postal_code,
      city: paymentDetails?.city,
      country: paymentDetails?.country,
      vatNumber: paymentDetails?.vat_number,
      ethAddress: paymentDetails?.eth_address,
    }
  }, [user])

  const form = useMiniForm({
    validation,
    initialValues,
    async onSubmit(values) {
      try {
        if (!confirmBillingVisible) {
          setConfirmBillingVisible(true)
        } else {
          await mutation.mutateAsync(values)
        }
      } catch (error) {
        if (error?.json?.code === 'email_verification.require') {
          setShowEmailVerification(true)
        } else {
          notification.error({ title: error.json.message })
        }
        setConfirmBillingVisible(false)
      }
    },
  })

  const handleCancelEmailVerification = () => {
    setShowEmailVerification(false)
  }

  const handleCancelBillingConfirm = () => {
    setConfirmBillingVisible(false)
  }

  const handleVerified = () => {
    setShowEmailVerification(false)
    form.submit()
  }

  const handleVerifyAddress = () => {
    if (proofCode.replaceAll('-', '').length < 16) {
      notification.error({
        title: 'The verification code must be 16 characters.',
      })
      return
    }
    verifyAddressMutation.mutate({
      proofCode: proofCode.replaceAll('-', ''),
    })
  }

  const handleShowQRCode = () => {
    setLastIdentityVerifiedAt(user.identity_verified_at)
    form.reset()
    setShowQRCodeBox(true)
    setidenfyUrl(null)
    setIsLoadingGetQR(true)
    fetcher('/identify/verification-url').then((res) => {
      setIsLoadingGetQR(false)
      setidenfyUrl(res.json)
    })
  }

  const handleChangeVerifyAddress = (value) => {
    setProofCode(
      value &&
        value
          .replaceAll('-', '')
          .match(/.{1,4}/g)
          .join('-')
    )
  }

  const handleBlurEthAddress = (value) => {
    setEthAddress(value.target.value.trim())
  }

  const handleSaveETH = () => {
    setEthAddressError(null)
    if (!ethAddress) {
      setEthAddressError('ETH-address is required.')
      return
    }

    if (!ethAddress.match(/^0x[a-fA-F0-9]{40}$/)) {
      setEthAddressError('ETH-address format is not correct.')
      return
    }
    saveEthMutation.mutate({
      ethAddress: ethAddress,
    })
  }

  const waitingForResponse =
    user.identity_verified_at &&
    (user.last_payment_details?.status === 'code-sent' ||
      user.last_payment_details?.status === 'new')

  const verifyIconPoA =
    user.verified_payment_details && !waitingForResponse ? (
      <span className={classes.verified}>
        <CheckSVG />
      </span>
    ) : null

  return (
    <Page actions={links} title="Payment Details" width="lg">
      <div className={classes.container}>
        {user.identity_verified_at && (
          <div className="w-1/2">
            <p className="mt-12 font-thin">
              Before we can start making payments to you, we need some
              additional information. Please fill in your payment details and
              the billing address in the form below.
            </p>
            <p className="mb-12 mt-4 font-thin text-primary">
              Notice! We will also use the address below to send physical goods
              such as merchandise, achievement rewards, etc.
            </p>
            <fieldset disabled={waitingForResponse || showQRCodeBox}>
              <form onSubmit={form.submit}>
                <div className={classes.nameFields}>
                  <Field
                    label="Channel name"
                    icon={
                      <span className={classes.icon}>{verifyIconPoA}</span>
                    }>
                    <Input
                      label="Channel name"
                      placeholder={user.channel.name}
                      disabled
                    />
                  </Field>
                </div>
                <div className={classes.nameFields}>
                  <Field
                    label="First name"
                    {...form.getErrorPropsFor('firstName')}
                    required={!form.values.firstName}
                    icon={
                      <span className={classes.icon}>{verifyIconPoA}</span>
                    }>
                    <Input
                      {...form.getInputPropsFor('firstName')}
                      label="First name"
                      placeholder="First name"
                    />
                  </Field>
                  <Field
                    label="Last name"
                    {...form.getErrorPropsFor('lastName')}
                    required={!form.values.lastName}
                    icon={
                      <span className={classes.icon}>{verifyIconPoA}</span>
                    }>
                    <Input
                      {...form.getInputPropsFor('lastName')}
                      label="Last name"
                      placeholder="Last name"
                    />
                  </Field>
                </div>

                <div className={classes.streetFields}>
                  <Field
                    label="Company name"
                    {...form.getErrorPropsFor('companyName')}>
                    <Input
                      {...form.getInputPropsFor('companyName')}
                      label="Company name"
                      placeholder="Company name"
                    />
                  </Field>
                  <Field
                    label="VAT number"
                    {...form.getErrorPropsFor('vatNumber')}>
                    <Input
                      {...form.getInputPropsFor('vatNumber')}
                      label="VAT number"
                      placeholder="VAT number"
                    />
                  </Field>
                </div>
                <div className={classes.streetFields}>
                  <Field
                    label="Billing address"
                    {...form.getErrorPropsFor('streetAddress')}
                    required={!form.values.streetAddress}
                    icon={
                      <span className={classes.icon}>{verifyIconPoA}</span>
                    }>
                    <Input
                      {...form.getInputPropsFor('streetAddress')}
                      label="Street address"
                      placeholder="Street address"
                    />
                  </Field>
                  <Field
                    label="Street no"
                    {...form.getErrorPropsFor('streetNo')}
                    required={!form.values.streetNo}
                    icon={
                      <span className={classes.icon}>{verifyIconPoA}</span>
                    }>
                    <Input
                      {...form.getInputPropsFor('streetNo')}
                      label="Street no"
                      placeholder="Street no"
                    />
                  </Field>
                </div>
                <div className={classes.postalCityFields}>
                  <Field
                    label="Postal code"
                    {...form.getErrorPropsFor('postalCode')}
                    required={!form.values.postalCode}
                    icon={
                      <span className={classes.icon}>{verifyIconPoA}</span>
                    }>
                    <Input
                      {...form.getInputPropsFor('postalCode')}
                      label="Postal code"
                      placeholder="Postal code"
                    />
                  </Field>
                  <Field
                    label="City"
                    {...form.getErrorPropsFor('city')}
                    required={!form.values.city}
                    icon={
                      <span className={classes.icon}>{verifyIconPoA}</span>
                    }>
                    <Input
                      {...form.getInputPropsFor('city')}
                      label="City"
                      placeholder="City"
                    />
                  </Field>
                </div>
                <div className={classes.countryVatFields}>
                  <Field
                    label="Country"
                    {...form.getErrorPropsFor('country')}
                    required={!form.values.country}
                    icon={
                      <span className={classes.icon}>{verifyIconPoA}</span>
                    }>
                    <Input
                      {...form.getInputPropsFor('country')}
                      label="Country"
                      placeholder="Country"
                    />
                  </Field>
                </div>
                <div>
                  <Field
                    label="ERC20 wallet address"
                    error={ethAddressError}
                    help="This address will be the one we send your earnings to."
                    {...form.getErrorPropsFor('ethAddress')}
                    required={!form.values.ethAddress}
                    icon={
                      <span className={classes.icon}>{verifyIconPoA}</span>
                    }>
                    <Input
                      {...form.getInputPropsFor('ethAddress')}
                      label="ERC20 wallet address"
                      placeholder="0x..."
                    />
                  </Field>
                </div>
                <Button disabled={waitingForResponse || showQRCodeBox}>
                  Save
                </Button>
              </form>
            </fieldset>
          </div>
        )}
        <div
          className={classnames(
            classes.idenfySection,
            waitingForResponse ? classes.proofDiv : classes.qrDiv,
            user.identity_verified_at && classes.initial
          )}>
          {showQRCodeBox && (
            <>
              <div>
                <h2>IDENTIFICATION</h2>
                <p className="font-thin">
                  Scan the QR code with your mobile device and follow the
                  instructions.
                  <br /> <br />
                  If the identification fails for any reason, please use the
                  refresh button below and scan the QR code once again to start
                  a new session.
                </p>
                <div className={classes.imageWrapper}>
                  <img
                    className={classes.qrImage}
                    src={idenfyUrl?.qrcode}
                    alt=""
                  />
                  {isLoadingGetQR && (
                    <Icon className="absolute top-1/2 ">
                      <Spin />
                    </Icon>
                  )}
                </div>
                <div className={classes.idenfyLogo}>
                  <span>Powered by</span>
                  <IdenfySVG />
                </div>
              </div>
              <span className="text-center block w-3/4 mt-4">
                <Button
                  disabled={!idenfyUrl?.qrcode}
                  onClick={handleShowQRCode}
                  secondary>
                  Refresh
                </Button>
              </span>
            </>
          )}
          {waitingForResponse && (
            <div>
              <h2> PROOF OF ADDRESS </h2>
              <p>
                As the last step, we have sent a “Proof of address” letter by
                post to the provided billing address with a 16-digit
                verification code. <br /> <br />
                Once you have received the letter, please enter the code below
                to complete the verification process. <br /> <br />
                If you have not received the letter within 15 working days then
                please contact us at{' '}
                <a
                  href={'mailto:' + 'helpdesk@todayscrypto.com'}
                  target="_blank">
                  helpdesk@todayscrypto.com
                </a>
              </p>
              <Input
                className="text-center uppercase"
                onChange={handleChangeVerifyAddress}
                value={proofCode}
                maxLength="19"
                placeholder="XXXX-XXXX-XXXX-XXXX"
              />
              <Button onClick={handleVerifyAddress}>Submit code</Button>
            </div>
          )}
        </div>
      </div>

      <EmailVerificationModal
        onCancel={handleCancelEmailVerification}
        onVerified={handleVerified}
        visible={showEmailVerification}
      />
      <HowItWorksModal
        visible={howItWorksVisible}
        onClose={handleCloseHowItWorks}
      />
      <Modal
        cancelText="Edit"
        onCancel={handleCancelBillingConfirm}
        onOk={form.submit}
        okText="Confirm"
        title="Confirm billing address"
        size="sm"
        visible={confirmBillingVisible}>
        <p className="-my-8">
          {form.values.firstName} {form.values.lastName}
          <br />
          {form.values.companyName} <br />
          {form.values.streetAddress} {form.values.streetNo} <br />
          {form.values.postalCode} {form.values.city} <br />
          {form.values.country} <br />
          {form.values.ethAddress}
        </p>
      </Modal>
    </Page>
  )
}
