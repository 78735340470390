// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".datepicker_datepicker__67wyn {\n  border-color: var(--overlay-color-default);\n}\n\n.datepicker_datepicker__67wyn:hover {\n  border-color: var(--overlay-color-highlighted);\n}\n\n.datepicker_datepicker__67wyn:focus {\n  border-color: var(--overlay-color-highlighted);\n}\n\n.datepicker_datepicker__67wyn {\n  border-radius: 0.25rem;\n  border-width: 1px;\n  display: block;\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n  padding-left: 1rem;\n  padding-right: 1rem;\n  width: 100%;\n}\n\n.invalid .datepicker_input__1PqtJ {\n  --tw-border-opacity: 1;\n  border-color: rgba(248, 113, 113, var(--tw-border-opacity));\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/form/datepicker/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,0CAAmB;ADQnB;;ACRA;EAAA;AAAmB;;AAAnB;EAAA;AAAmB;;AAAnB;EAAA,sBAAmB;EAAnB,iBAAmB;EAAnB,cAAmB;EAAnB,mBAAmB;EAAnB,sBAAmB;EAAnB,kBAAmB;EAAnB,mBAAmB;EAAnB;AAAmB;;ADUnB;ECVA,sBAAmB;EAAnB,2DAAmB;ADYnB","sourcesContent":[".datepicker {\n  @apply block\n  border\n  border-overlayColor-default\n  rounded\n  px-4\n  py-2\n  w-full\n  focus:border-overlayColor-highlighted\n  hover:border-overlayColor-highlighted;\n}\n\n:global(.invalid) .input {\n  @apply border-red-400;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datepicker": "datepicker_datepicker__67wyn",
	"input": "datepicker_input__1PqtJ"
};
export default ___CSS_LOADER_EXPORT___;
