type ToggleReadCommentVariables = {
  commentId: number,
}
export default function toggleReadComment(
  variables: ToggleReadCommentVariables = {}
) {
  const { commentId } = variables
  return {
    method: 'PUT',
    path: `/publisher/comments/${commentId}/toggle-read`,
  }
}
