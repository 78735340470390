// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".back-to_back__9tsgJ {\n  display: flex;\n  font-size: 1.125rem;\n  line-height: 1.75rem;\n  margin-left: -0.25rem;\n  color: var(--neutral);\n}\n\n.back-to_back__9tsgJ svg {\n  fill: currentColor;\n  width: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/kit/back-to/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,qBAAmB;EAAnB,qBAAmB;ADAnB;;AAEA;ECFA,kBAAmB;EAAnB,aAAmB;ADInB","sourcesContent":[".back {\n  @apply flex text-lg -ml-1 text-neutral;\n}\n\n.back svg {\n  @apply fill-current w-6;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back": "back-to_back__9tsgJ"
};
export default ___CSS_LOADER_EXPORT___;
