import { useState } from 'react'
import formatDate from 'helpers/utils/formatDate'
import Icon from 'shared/components/kit/icon'
import Popover from 'shared/components/kit/popover'
import { ReactComponent as MuteSVG } from 'svg/muted.svg'

export default function MuteBadge({ user }) {
  const [showMute, setShowMute] = useState(false)

  const handleToggleMute = (event) => {
    event.preventDefault()
    setShowMute((prev) => !prev)
  }

  const handleHideMute = () => {
    setShowMute(false)
  }

  return (
    <Popover
      content={
        user.muted_until
          ? `User is muted until ${formatDate(user.muted_until)}`
          : `User is permanently muted`
      }
      onHide={handleHideMute}
      visible={showMute}>
      <a onMouseEnter={handleToggleMute} onMouseLeave={handleToggleMute}>
        <Icon className="text-overlayColor-mutedText">
          <MuteSVG />
        </Icon>
      </a>
    </Popover>
  )
}
