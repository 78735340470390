type GetMessageQueryVariables = {
  id: number,
}

export default function getMessage(variables: GetMessageQueryVariables = {}) {
  const { id } = variables

  return {
    name: ['message', id],
    path: `/admin/messages/${id}`,
  }
}
