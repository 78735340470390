import Select from 'shared/components/form/select'

export default function SelectMonths(props) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const options = months.map((month, index) => ({
    label: month,
    value: index + 1 > 9 ? index + 1 : '0' + (index + 1),
  }))

  return (
    <>
      <Select {...props} options={options} />
    </>
  )
}
