import { useMemo, useState } from 'react'
import useMutation from 'core/react-query/useMutation'
import notification from 'shared/components/kit/notification'
import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import Field from 'shared/components/form/field'
import SelectPaymentMethod from 'admin/components/form/select-payment-method'
import Input from 'shared/components/form/input'
import manualPaymentEarning from 'admin/queries/manualPaymentEarning'
import useQueryClient from 'core/react-query/useQueryClient'
import getChannelEarnings from 'admin/queries/getChannelEarnings'
import { useParams } from 'react-router'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'

const VALIDATION = {
  paymentMethodId: {
    label: 'Payment Method',
    rule: 'required',
  },
  reference: {
    label: 'Reference',
    rule: 'required',
  },
  id: {
    label: 'Id',
    rule: 'required',
  },
}

export default function ManualPayment({ initialValues }) {
  const queryClient = useQueryClient()
  const params = useParams()
  const channelId = Number(params.id)

  const FILTER_CONFIG = useMemo(
    () => ({
      base: `/admin/channels/${channelId}/earnings`,
      params: {
        status: 'string',
        page: 'pagination',
      },
    }),
    [channelId]
  )
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)
  const [visible, setVisible] = useState(false)

  const mutation = useMutation(manualPaymentEarning, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getChannelEarnings,
        variables: {
          includeTransaction: true,
          channelId,
          status: filters.status,
          page: filters.page,
        },
      })
      notification.success({ title: 'Manual payout payed successfully!' })
    },
  })

  const form = useMiniForm({
    resetOnSuccess: true,
    initialValues: initialValues,
    validation: VALIDATION,
    async onSubmit(values) {
      try {
        await mutation.mutateAsync(values)
        setVisible(false)
      } catch (error) {
        notification.error({ title: error.json.message })
        setVisible(false)
        throw error
      }
    },
  })

  const handleCancel = () => {
    setVisible(false)
    form.reset()
  }

  const handleOk = () => {
    form.submit()
  }

  return (
    <>
      <Button onClick={() => setVisible(true)} outlined>
        Manual payment
      </Button>

      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        okText="Pay"
        loading={mutation.isLoading}
        size="sm"
        title="Manual Payment Earning"
        visible={visible}>
        <form onSubmit={form.submit}>
          <Field
            label="Payment Method"
            {...form.getErrorPropsFor('paymentMethodId')}>
            <SelectPaymentMethod
              placeholder="Select payment method..."
              isClearable={true}
              {...form.getInputPropsFor('paymentMethodId')}
            />
          </Field>
          <Field label="Reference" {...form.getErrorPropsFor('reference')}>
            <Input required {...form.getInputPropsFor('reference')} />
          </Field>
        </form>
      </Modal>
    </>
  )
}
