type GetPaymentDetailsVariables = {
  status?: 'new' | 'code-sent' | 'verified' | 'expired' | 'canceled',
  onlyArchived?: boolean,
  publisherId?: string,
  sort?: string,
  page?: number,
}
export default function getPaymentDetails(
  variables: GetPaymentDetailsVariables = {}
) {
  const { status, search, page, sort, onlyArchived, publisherId } = variables
  return {
    path: '/admin/payment-details',
    params: {
      page: page > 1 ? page : undefined,
      sort,
      filters: {
        search,
        status,
        only_archived: onlyArchived,
        publisher_id: publisherId,
      },
    },
  }
}
