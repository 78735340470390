import classes from './index.module.css'

export default function PageHeader(props) {
  const { actions, description, title } = props

  return (
    <>
      {title || actions ? (
        <header className={classes.header}>
          <div className={classes.text}>
            <h1>{title}</h1>
            {description ? <p>{description}</p> : null}
          </div>
          <div className={classes.actions}>{actions}</div>
        </header>
      ) : null}
    </>
  )
}
