import { useMemo } from 'react'
import { useHistory } from 'react-router'
import createUrl from 'core/createUrl'
import useParsedLocation from 'helpers/hooks/useParsedLocation'

type Options = {
  base: string,
  params: {
    [key: string]: 'number' | 'pagination' | 'any',
  },
}

export default function useFiltersFromURL(options: Options) {
  const { base, params } = options

  const { queryParams } = useParsedLocation()

  const history = useHistory()

  return useMemo(() => {
    const filters = {}

    Object.entries(params).forEach(([name, type]) => {
      if (type === 'number') {
        filters[name] = queryParams[name]
          ? Number(queryParams[name])
          : undefined
      } else if (type === 'pagination') {
        filters[name] =
          Number(queryParams[name]) >= 2 ? Number(queryParams[name]) : undefined
      } else {
        filters[name] = queryParams[name] || undefined
      }
    })

    const setFilters = (nextFilters) => {
      const tempParams =
        typeof nextFilters === 'function' ? nextFilters(filters) : nextFilters

      const nextParams = {}

      Object.entries(params).forEach(([name, type]) => {
        if (type === 'number') {
          nextParams[name] = tempParams[name]
            ? Number(tempParams[name])
            : undefined
        } else if (type === 'pagination') {
          nextParams[name] =
            Number(tempParams[name]) >= 2 ? Number(tempParams[name]) : undefined
        } else {
          nextParams[name] = tempParams[name] || undefined
        }
      })

      history.push(createUrl(base, nextParams))
    }

    return [filters, setFilters]
  }, [base, history, params, queryParams])
}
