import { useState } from 'react'
import colors from 'tailwindcss/colors'
import dayjs from 'dayjs'
import ChannelSubscribersChart from 'shared/components/channel-subscribers-chart'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import Box from 'shared/components/kit/box'
import Page from 'admin/components/page'
import useChannelStatistics from '../hooks/useChannelStatistics'
import Filters from './Filters'
import classes from '../index.module.css'

const subscribersChartedData = (range, statistics) => {
  const getMonths = () => {
    return Object.keys(statistics).map((statistic) => {
      return dayjs(statistic).format('MMM')
    })
  }

  const getHeroSubscribers = () => {
    return Object.values(statistics).map((statistic) => {
      return statistic.subscribers_hero
    })
  }

  const getNonHeroSubscribers = () => {
    return Object.values(statistics).map((statistic) => {
      return statistic.subscribers_non_hero
    })
  }

  const getDates = () => {
    if (range === 'this_year') {
      return getMonths()
    } else {
      return Object.keys(statistics).map((stat, i) => {
        return [dayjs(stat).format('DD'), dayjs(stat).format('ddd')]
      })
    }
  }

  return {
    labels: getDates(),
    datasets: [
      {
        label: 'HODL',
        backgroundColor: colors.red['300'],
        borderColor: colors.red['300'],
        borderWidth: 1,
        data: getHeroSubscribers(),
        stack: 'Stack 0',
      },
      {
        label: 'Non-HODL',
        backgroundColor: colors.gray['500'],
        borderColor: colors.gray['500'],
        borderWidth: 1,
        data: getNonHeroSubscribers(),
        stack: 'Stack 0',
      },
    ],
  }
}

const unSubscribersChartedData = (range, statistics) => {
  const getMonths = () => {
    return Object.keys(statistics).map((statistic) => {
      return dayjs(statistic).format('MMM')
    })
  }

  const getHeroUnSubscribers = () => {
    return Object.values(statistics).map((statistic) => {
      return statistic.unsubscribers_hero
    })
  }

  const getNonHeroUnSubscribers = () => {
    return Object.values(statistics).map((statistic) => {
      return statistic.unsubscribers_non_hero
    })
  }

  const getDates = () => {
    if (range === 'this_year') {
      return getMonths()
    } else {
      return Object.keys(statistics).map((stat, i) => {
        return [dayjs(stat).format('DD'), dayjs(stat).format('ddd')]
      })
    }
  }

  return {
    labels: getDates(),
    datasets: [
      {
        label: 'HODL',
        backgroundColor: colors.red['300'],
        borderColor: colors.red['300'],
        borderWidth: 1,
        data: getHeroUnSubscribers(),
        stack: 'Stack 0',
      },
      {
        label: 'Non-HODL',
        backgroundColor: colors.gray['500'],
        borderColor: colors.gray['500'],
        borderWidth: 1,
        data: getNonHeroUnSubscribers(),
        stack: 'Stack 0',
      },
    ],
  }
}

export default function ChannelSubscribers() {
  const [currentChart, setCurrentChart] = useState('subscribers')
  const [range, setRange] = useState('this_month')

  const statistics = useChannelStatistics(range)

  if (statistics.isError) {
    return <Page error={statistics.error.message} />
  }

  if (statistics.isLoading) {
    return (
      <Box className={classes.boxLoading}>
        <div className={classes.loading}>
          <Icon>
            <Spin />
          </Icon>
          <div>Loading...</div>
        </div>
      </Box>
    )
  }

  return (
    <ChannelSubscribersChart
      currentChart={currentChart}
      data={statistics.data}
      Filter={<Filters range={range} onChange={setRange} />}
      range={range}
      setCurrentChart={setCurrentChart}
      subscribersChartedData={subscribersChartedData}
      unSubscribersChartedData={unSubscribersChartedData}
      title="Channel Subscribers"
    />
  )
}
