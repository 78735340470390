import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import getLocations from 'admin/queries/getLocations'
import Select from 'shared/components/form/select'

export default function SelectLocation(props) {
  const { data, isLoading } = useQuery(getLocations)
  const options = get(data, 'json', []).map((opt) => ({
    label: opt,
    value: opt,
  }))

  return <Select {...props} isLoading={isLoading} options={options} />
}
