type CreatePlanVariables = {
  name: string,
  interval: string,
  description: string,
  isPopular: boolean,
  status: string,
  pricing: Array<Object>,
}

export default function createPlan(variables: CreatePlanVariables = {}) {
  const { name, status, interval, description, isPopular, pricing } = variables
  const pricingList = pricing.map((price) => {
    return {
      amount: price.amount,
      payment_method_id: price.paymentMethodId,
      external_id: price.externalId,
      currency: price.currency,
    }
  })

  return {
    method: 'post',
    path: `/admin/plans`,
    params: {
      name: name,
      status: status ? 'active' : 'inactive',
      interval: interval,
      description: description,
      is_popular: isPopular,
      pricing: pricingList,
    },
  }
}
