import classnames from 'classnames'
import classes from './index.module.css'

type Props = {
  className?: string,
}

export default function Spin(props: Props) {
  const { className } = props
  return (
    <span className={classnames(classes.loader, className)}>
      <svg className={classes.circularLoader} viewBox="25 25 50 50">
        <circle
          className={classes.loaderPath}
          cx="50"
          cy="50"
          r="20"
          fill="none"
        />
      </svg>
    </span>
  )
}
