import Base from '../Base'

export default function AdminDefaultLayout({ children }) {
  const sidebar = (
    <ul>
      <li>
        <Base.NavLink text="Dashboard" to="/admin" exact />
      </li>
      <li>
        <Base.NavLink text="Traffic" to="/admin/security/traffic" exact />
      </li>
      <li>
        <Base.NavLink text="Videos" to="/admin/videos" exact />
      </li>
      <li>
        <Base.NavLink text="Users" to="/admin/users" exact />
      </li>
      <li>
        <Base.NavLink text="Channels" to="/admin/channels" exact />
      </li>
      <li>
        <Base.NavLink
          text="Deleted Channels"
          to="/admin/deleted-channels"
          exact
        />
      </li>
      <li>
        <Base.NavLink text="CRM" to="/admin/companies" />
      </li>
      <li>
        <Base.NavLink text="Earnings" to="/admin/earnings" exact />
      </li>
      <li>
        <Base.NavLink text="Monetization" to="/admin/monetization" exact />
      </li>
      <li>
        <Base.NavLink text="Payment History" to="/admin/payments" exact />
      </li>
      <li>
        <Base.NavLink text="Feedbacks" to="/admin/feedbacks" exact />
      </li>
      <li>
        <Base.NavLink text="PoA Requests" to="/admin/payment-details" exact />
      </li>
      <li>
        <Base.NavLink text="Publisher Requests" to="/admin/requests" exact />
      </li>
      <li>
        <Base.NavLink text="Messages" to="/admin/messages" />
      </li>
      <li>
        <Base.NavLink text="Membership plans" to="/admin/plans" />
      </li>
      <li>
        <Base.NavLink text="Notifications" to="/admin/notifications" />
      </li>
      <li>
        <Base.NavLink text="Lottery" to="/admin/lottery" />
      </li>
      <li>
        <Base.NavLink text="Tags" to="/admin/tags" />
      </li>
      <li>
        <Base.NavLink text="Reports" to="/admin/reports" />
      </li>
      <li>
        <Base.NavLink
          text="Cryptocurrencies"
          to="/admin/cryptoCurrencies"
          exact
        />
      </li>
      <li>
        <Base.NavLink text="Email's list" to="/admin/emails" exact />
      </li>
      <li>
        <Base.NavLink text="Ads manager" to="/admin/ads-manager" />
      </li>
      <li>
        <Base.NavLink text="Setting" to="/admin/setting" exact />
      </li>
      <li>
        <Base.NavLink text="TCG" to="/admin/tcg" exact />
      </li>
      <li>
        <Base.NavLink text="FAQ" to="/admin/faq" />
      </li>
    </ul>
  )

  return <Base main={children} sidebar={sidebar} />
}
