type GetCompanies = {
  page?: number,
}

export default function getCompanies(variables: GetCompanies = {}) {
  const { page } = variables
  return {
    path: '/admin/companies',
    params: {
      page: page > 1 ? page : undefined,
    },
  }
}
