import { useEffect } from 'react'
import cookie from 'core/cookie'
import Page from 'publisher/components/page'
import Result from 'shared/components/kit/result'
import Button from 'shared/components/kit/button'
import getUrlToPublisherPanel from 'helpers/utils/getUrlToPublisherPanel'

export default function ChannelDeleted() {
  useEffect(() => {
    cookie().remove('jwt')
  }, [])

  return (
    <Page>
      <Result
        className="mt-48"
        footer={
          <Button
            block
            className="w-full"
            onClick={() => (window.location.href = getUrlToPublisherPanel())}>
            Go to home page
          </Button>
        }
        title="Your channel has been deleted!"
        status="sad"
      />
    </Page>
  )
}
