// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".box_box__BA04j {\n  background-color: var(--light);\n  border-color: var(--light-1);\n  border-radius: 0.75rem;\n  border-width: 1px;\n  position: relative;\n  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);\n  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);\n}\n\n.dark .box_box__BA04j {\n  background-color: var(--dark-1);\n  border-color: transparent;\n  --tw-shadow: 0 0 #0000;\n  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);\n}\n\n.box_loading__3ikpK {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  font-size: 1.125rem;\n  line-height: 1.75rem;\n  padding: 2rem;\n  color: var(--neutral);\n}\n\n.box_loading__3ikpK > .icon {\n  margin-bottom: 1rem;\n  font-size: 64px;\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/kit/box/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,8BAAmB;EAAnB,4BAAmB;EAAnB,sBAAmB;EAAnB,iBAAmB;EAAnB,kBAAmB;EAAnB,sFAAmB;EAAnB,uGAAmB;ADCnB;;ACDA;EAAA,+BAAmB;EAAnB,yBAAmB;EAAnB,sBAAmB;EAAnB;AAAmB;;ADGnB;ECHA,aAAmB;EAAnB,sBAAmB;EAAnB,mBAAmB;EAAnB,uBAAmB;EAAnB,YAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,aAAmB;EAAnB,qBAAmB;ADKnB;;AAEA;ECPA,mBAAmB;EDSjB,eAAe;AACjB","sourcesContent":[".box {\n  @apply bg-light border border-light-1 relative rounded-xl shadow-xl;\n  @apply dark:bg-dark-1 dark:border-transparent dark:shadow-none;\n}\n\n.loading {\n  @apply flex flex-col h-full items-center justify-center p-8 text-lg text-neutral;\n}\n\n.loading > :global(.icon) {\n  @apply mb-4;\n  font-size: 64px;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "box_box__BA04j",
	"dark": "dark",
	"loading": "box_loading__3ikpK"
};
export default ___CSS_LOADER_EXPORT___;
