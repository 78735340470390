import { useRef, useState } from 'react'
import useQueryClient from 'core/useQueryClient'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import Field from 'shared/components/form/field'
import RichText from 'shared/components/form/rich-text'
import Alert from 'shared/components/kit/alert'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import pinCommentToVideos from 'publisher/queries/pinCommentToVideos'
import getMyVideos from 'publisher/queries/getMyVideos'

export default function AddPinnedComment({ videos }) {
  const [visible, setVisible] = useState(false)
  const queryClient = useQueryClient()
  const submitRef = useRef()
  const pin = useMutation(pinCommentToVideos)

  const form = useMiniForm({
    async onSubmit({ text }, event) {
      event.preventDefault()
      try {
        await pin.mutateAsync({
          text,
          videos,
        })
        setVisible(false)
        queryClient.invalidateQueries({
          query: getMyVideos,
        })
        // notification.success({
        //   title:
        //     'Comment successfully created and pinned to the selected videos.',
        // })
      } catch (error) {
        notification.error({ title: error.message })
        setVisible(false)
        form.change('text', '')
      }
    },
  })

  const handleShow = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    if (!pin.isLoading) {
      setVisible(false)
      form.change('text', '')
    }
  }

  const handleOk = () => {
    submitRef.current.click()
  }

  return (
    <>
      <Button disabled={videos.length === 0} onClick={handleShow}>
        Pin Comment
      </Button>

      <Modal
        closeOnBackdrop={!pin.isLoading}
        okButtonProps={{
          disabled: !form.get('text'),
        }}
        okText="Pin to Selected Videos"
        onCancel={handleCancel}
        size="sm"
        submitting={pin.isLoading}
        onOk={handleOk}
        title={
          videos.length > 1
            ? `Add a Pinned Comment to ${videos.length} Videos`
            : 'Add a Pinned Comment'
        }
        visible={visible}>
        <form onSubmit={form.submit}>
          {form.failed && (
            <Alert className="mb-4" title={form.error.message} type="error" />
          )}
          <Field {...form.getErrorPropsFor('text')}>
            <RichText
              {...form.getInputPropsFor('text')}
              placeholder="Add your comment here..."
            />
          </Field>
          <button ref={submitRef} type="submit" hidden />
        </form>
      </Modal>
    </>
  )
}
