import { useState } from 'react'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import truncate from 'helpers/utils/truncate'
import VideoThumbnail from 'shared/components/video-thumbnail'
import dislikeComment from 'publisher/queries/dislikeComment'
import likeComment from 'publisher/queries/likeComment'
import pinComment from 'publisher/queries/pinComment'
import unpinComment from 'publisher/queries/unpinComment'
import rememberComment from 'publisher/queries/rememberComment'
import getComments from 'publisher/queries/getComments'
import deleteComment from 'publisher/mutations/deleteComment'
import getCommentsStats from 'publisher/queries/getCommentsStats'
import Comment from './Comment'
import classes from './Comments.module.css'

export default function Comments(props) {
  const { comments, onSelectComment, onCloseParent, selectedComment } = props
  const [currentlyPinnedAction, setCurrentlyPinnedAction] = useState('Unpin')
  const parsedLocation = useParsedLocation()

  const isAllCommentsTab = parsedLocation.pathname.endsWith(
    'publisher/comments/all'
  )
  const isRememberedTab = parsedLocation.pathname.endsWith(
    'publisher/comments/remembered'
  )
  const isMentionedTab = parsedLocation.pathname.endsWith(
    'publisher/comments/mentioned'
  )

  const { page, sort, video, time, perPage, unread } =
    parsedLocation.queryParams
  const queryClient = useQueryClient()
  const likeMutation = useMutation(likeComment, {
    onMutate: (res) => {
      const snapshotOfPreviousComment = queryClient.getQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
      })
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            data: prev.json.data.map((item) => {
              if (item.id === res.id) {
                return {
                  ...item,
                  dislikes_count: item.is_disliked
                    ? item.dislikes_count - 1
                    : item.dislikes_count,
                  is_disliked: false,
                  is_liked: !item.is_liked,
                  likes_count: item.likes_count + (item.is_liked ? -1 : 1),
                }
              }
              return item
            }),
          },
        }),
      })
      return {
        snapshotOfPreviousComment,
      }
    },
    onError: (error, res, { snapshotOfPreviousComment }) => {
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
        updater: (prev) => ({
          ...snapshotOfPreviousComment,
        }),
      })
    },
  })
  const dislikeMutation = useMutation(dislikeComment, {
    onMutate: (res) => {
      const snapshotOfPreviousComment = queryClient.getQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
      })
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            data: prev.json.data.map((item) => {
              if (item.id === res.id) {
                return {
                  ...item,
                  dislikes_count:
                    item.dislikes_count + (item.is_disliked ? -1 : 1),
                  is_disliked: !item.is_disliked,
                  is_liked: false,
                  likes_count: item.is_liked
                    ? item.likes_count - 1
                    : item.likes_count,
                }
              }
              return item
            }),
          },
        }),
      })
      return {
        snapshotOfPreviousComment,
      }
    },
    onError: (error, res, { snapshotOfPreviousComment }) => {
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
        updater: (prev) => ({
          ...snapshotOfPreviousComment,
        }),
      })
    },
  })
  const rememberMutation = useMutation(rememberComment, {
    onMutate: (res) => {
      const snapshotOfPreviousComment = queryClient.getQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
      })
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
        updater: (prev) => {
          return {
            ...prev,
            json: {
              ...prev.json,
              data: prev.json.data.map((item) => {
                if (item.id === res.id) {
                  return {
                    ...item,
                    is_remembered: true,
                  }
                }
                return item
              }),
            },
          }
        },
      })
      return snapshotOfPreviousComment
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getCommentsStats,
      })
    },
    onError: (error, res, { snapshotOfPreviousComment }) => {
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
        updater: (prev) => {
          return {
            ...snapshotOfPreviousComment,
          }
        },
      })
    },
  })
  const unRememberMutation = useMutation(rememberComment, {
    onMutate: (res) => {
      const snapshotOfPreviousComment = queryClient.getQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
      })
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
        updater: (prev) => {
          return {
            ...prev,
            json: {
              ...prev.json,
              data: isRememberedTab
                ? prev.json.data.filter((item) => item.id !== res.id)
                : prev.json.data.map((item) => {
                    if (item.id === res.id) {
                      return {
                        ...item,
                        is_remembered: false,
                      }
                    }
                    return item
                  }),
            },
          }
        },
      })
      return {
        snapshotOfPreviousComment,
      }
    },
    onError: (error, res, { snapshotOfPreviousComment }) => {
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
        updater: (prev) => {
          return {
            ...snapshotOfPreviousComment,
          }
        },
      })
    },
  })
  const deleteMutation = useMutation(deleteComment, {
    onMutate: (res) => {
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            data: prev.json.data.filter((item) => item.id !== res.id),
          },
        }),
      })
    },
    onSuccess: () => {
      invalidateComments()
    },
  })
  const pinMutation = useMutation(pinComment, {
    onMutate: (res) => {
      if (video && isAllCommentsTab) {
        if (res.pinnedComment) {
          queryClient.setQueryData({
            query: getComments,
            variables: {
              perPage: 1,
              video: Number(video),
            },
            updater: (prev) => ({
              ...prev,
              json: {
                data: [
                  {
                    ...prev.json.data[0],
                    created_at: res.comment.created_at,
                    dislikes_count: res.comment.dislikes_count,
                    id: res.comment.id,
                    is_disliked: res.comment.is_disliked,
                    is_liked: res.comment.is_liked,
                    // is_pinned: res.comment.is_pinned,
                    is_read_replies: res.comment.is_read_replies,
                    // is_remembered: res.comment.is_remembered,
                    last_mentioned_at: res.comment.last_mentioned_at,
                    likes_count: res.comment.likes_count,
                    replies_count: res.comment.replies_count,
                    // status: res.comment.status,
                    text: res.comment.text,
                    user: res.comment.user,
                    video: res.comment.video,
                  },
                ],
              },
            }),
          })
        } else {
          queryClient.setQueryData({
            query: getComments,
            variables: {
              perPage: 1,
              video: Number(video),
            },
            updater: (prev) => {
              return {
                ...prev,
                json: {
                  ...prev.json,
                  data: prev.json.data.map((itm) => {
                    if (itm.id === res.id) {
                      return {
                        ...itm,
                        is_pinned: true,
                      }
                    }
                    return itm
                  }),
                },
              }
            },
          })
        }
      }
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            data: prev.json.data
              .filter((item) => {
                if (currentlyPinnedAction === 'UnpinDelete') {
                  if (item.id !== res.pinnedComment.id) {
                    return item
                  }
                } else {
                  return item
                }
              })
              .map((item) => {
                if (item.id === res.id) {
                  return {
                    ...item,
                    is_pinned: true,
                  }
                }
                if (res.pinnedComment && item.id === res.pinnedComment.id) {
                  return {
                    ...item,
                    is_pinned: false,
                  }
                }
                return item
              }),
          },
        }),
      })
    },
    onSuccess: (result, variables) => {
      if (currentlyPinnedAction === 'UnpinDelete') {
        deleteMutation.mutate({
          id: variables.pinnedComment.id,
        })
      } else {
        invalidateComments()
      }
      setCurrentlyPinnedAction('Unpin')
      // notification.success({
      //   title: `Comment successfully created and pinned to the selected video. ${
      //     currentlyPinnedAction === 'UnpinDelete'
      //       ? '\n the previously pinned comment with its replies was deleted.'
      //       : ''
      //   }`,
      // })
    },
  })
  const UnpinMutation = useMutation(unpinComment, {
    onMutate: (res) => {
      const snapshotOfPreviousComment = queryClient.getQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
      })
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            data: prev.json.data.map((item) => {
              if (item.id === res.id) {
                return {
                  ...item,
                  is_pinned: false,
                }
              }
              return item
            }),
          },
        }),
      })
      return {
        snapshotOfPreviousComment,
      }
    },
  })

  const handlePin = (comment, pinnedComment) => {
    pinMutation.mutate({ id: comment.id, comment, pinnedComment })
  }

  const handleUnpin = (comment) => {
    UnpinMutation.mutate({ id: comment.id })
  }

  const commentsNotPinned =
    video && isAllCommentsTab
      ? comments.filter((comment) => !comment.is_pinned)
      : comments

  const handleToggleLike = (commentId) => {
    likeMutation.mutate({ id: commentId })
  }

  const handleToggleDislike = (commentId) => {
    dislikeMutation.mutate({ id: commentId })
  }

  const invalidateComments = () =>
    queryClient.invalidateQueries({
      query: getComments,
      variables: {
        page: Number(page) || 1,
        video: Number(video),
        sort,
        time,
        perPage,
        onlyMyMentions: isMentionedTab ? true : false,
        onlyRemembers: isRememberedTab ? true : false,
        onlyUnreadReplies: isMentionedTab && unread === 'true',
      },
    })

  const handleRemember = (comment) => {
    rememberMutation.mutate(
      { id: comment.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            query: getCommentsStats,
          })
        },
      }
    )
  }

  const handleUnRemember = (comment) => {
    unRememberMutation.mutate(
      { id: comment.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            query: getCommentsStats,
          })
          if (isRememberedTab) {
            onCloseParent()
          }
        },
      }
    )
  }

  const handleSelectComment = (comment) => {
    onSelectComment(comment)
    if (isMentionedTab) {
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: isMentionedTab ? true : false,
          onlyRemembers: isRememberedTab ? true : false,
          onlyUnreadReplies: isMentionedTab && unread === 'true',
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            data: prev.json.data.map((item) => {
              if (item.id === comment.id) {
                return {
                  ...item,
                  is_read_replies: true,
                }
              }
              return item
            }),
          },
        }),
      })
    }
  }

  if (commentsNotPinned.length === 0) {
    return (
      <div className="flex justify-center items-center h-24">
        <h1>There are no comments {video ? ' for the selected media' : ''}!</h1>
      </div>
    )
  }

  return (
    <div className={classes.wrapper}>
      <ul className={classes.comments}>
        {commentsNotPinned.map((comment, key) => (
          <li
            key={key}
            className={
              selectedComment && selectedComment.id === comment.id
                ? classes.selected
                : ''
            }
            data-comment-index={key}
            onClick={() => handleSelectComment(comment)}>
            <div className={classes.videoInfo}>
              <VideoThumbnail video={comment.video} />
              <h4 title={comment.video.title}>
                {truncate(comment.video.title, 60)}
              </h4>
            </div>
            <div className={classes.commentInfo}>
              <Comment
                comment={comment}
                onSetCurrentlyPinnedAction={setCurrentlyPinnedAction}
                currentlyPinnedAction={currentlyPinnedAction}
                onLike={() => handleToggleLike(comment.id)}
                onDislike={() => handleToggleDislike(comment.id)}
                onPin={(pinnedComment) => handlePin(comment, pinnedComment)}
                onRemember={() => handleRemember(comment)}
                onUnRemember={() => handleUnRemember(comment)}
                onUnpin={() => handleUnpin(comment)}
                showReplies
                summary
                onCloseParent={onCloseParent}
                video={video}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
