import classnames from 'classnames'
import classes from './index.module.css'

type Props = {
  align?: 'center' | 'left' | 'right',
  className?: string,
  direction?: 'col' | 'row',
}

export default function Skeleton(props: Props) {
  const { align, children, className, direction, ...rest } = props
  const classNames = classnames(classes.wrapper, `items-${align}`, className, {
    [classes.col]: direction === 'col',
    [classes.row]: direction === 'row',
  })
  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  )
}

Skeleton.defaultProps = {
  align: 'left',
  direction: 'col',
}

Skeleton.Circle = Circle
Skeleton.Button = Button
Skeleton.Rect = Rect

type RectTypes = {
  size: number | 'full',
}

function Rect({ height, size, width }: RectTypes) {
  if (size) {
    return <div className={classnames(classes.rect, 'h-4', `w-${size}`)} />
  }
  return (
    <div
      className={classnames(
        classes.rect,
        `h-${height}`,
        width ? `w-${width}` : 'w-full'
      )}
    />
  )
}

Rect.defaultProps = {
  size: 'full',
}

type CircleTypes = {
  size: number,
}

function Circle({ size }: CircleTypes) {
  return (
    <div className={classnames(classes.circle, `h-${size}`, `w-${size}`)} />
  )
}

type ButtonTypes = {
  size: number,
}

function Button({ size, width }: ButtonTypes) {
  return (
    <button
      className={classnames(classes.button, classes[size], `w-${width}`)}
      disabled>
      &nbsp;&nbsp;
    </button>
  )
}

Button.defaultProps = {
  size: 'md',
  width: 32,
}
