import useYouTubeSyncStatus from '../hooks/useYouTubeSyncStatus'
import Spin from 'shared/components/kit/spin'
import { useEffect } from 'react'

export default function YouTubeSyncStatus({ onProgress }) {
  const { data } = useYouTubeSyncStatus()

  const stats = data?.json

  useEffect(() => {
    onProgress()
  }, [onProgress, stats?.synced])

  if (stats?.status === 'sync') {
    return (
      <span className="ml-4 text-neutral">
        <Spin />
        &nbsp; Syncing from YouTube
        {stats.total >= 1 && (
          <>
            &nbsp; ({stats.synced}/{stats.total} media)
          </>
        )}
      </span>
    )
  }

  return null
}
