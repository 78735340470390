// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hiddenVideoReasons_key__16wjb {\n  width: 33.333333%;\n}\n\n.hiddenVideoReasons_value__328bP {\n  width: 33.333333%;\n}\n\n.hiddenVideoReasons_status__1gfXA {\n  width: 33.333333%;\n}\n\n.hiddenVideoReasons_updateReason__Vxt7E {\n  width: 33.333333%;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/setting/hiddenVideoReasons/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,iBAAmB;ADAnB;;AAEA;ECFA,iBAAmB;ADInB;;AAEA;ECNA,iBAAmB;ADQnB;;AAEA;ECVA,iBAAmB;ADYnB","sourcesContent":[".key {\n  @apply w-1/3;\n}\n\n.value {\n  @apply w-1/3;\n}\n\n.status {\n  @apply w-1/3;\n}\n\n.updateReason {\n  @apply w-1/3;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"key": "hiddenVideoReasons_key__16wjb",
	"value": "hiddenVideoReasons_value__328bP",
	"status": "hiddenVideoReasons_status__1gfXA",
	"updateReason": "hiddenVideoReasons_updateReason__Vxt7E"
};
export default ___CSS_LOADER_EXPORT___;
