import { useState } from 'react'
import { Link } from 'react-router-dom'
import UserAvatar from 'shared/components/user-avatar'
import Page from 'admin/components/page'
import Spin from 'shared/components/kit/spin'
import Table from 'shared/components/kit/table'
import useQuery from 'core/react-query/useQuery'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import readableNumber from 'shared/helpers/readableNumber'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import useMutation from 'core/react-query/useMutation'
import notification from 'shared/components/kit/notification'
import Tag from 'shared/components/kit/tag'
import { ReactComponent as CheckSVG } from 'icons/check-circle-filled.svg'
import { ReactComponent as ErrorSVG } from 'svg/error-circle-filled.svg'
import formatDateOnly from 'helpers/utils/formatDateOnly'
import formatDateForAPI from 'shared/helpers/formatDateForAPI'

export default function Users({ filters, userIds }) {
  const [selectedRows, setSelectedRows] = useState([])

  const { data, isLoading, refetch } = useQuery(getRateLimitUsersInfo, {
    variables: {
      date: filters.date,
      ip_address: filters.ip_address,
      user_ids: userIds,
    },
  })

  const { mutateAsync } = useMutation(blockUsers)

  const handleRowSelect = (keys) => {
    setSelectedRows(keys)
  }

  const handleBlockSelectedUser = () => {
    Modal.open({
      title: 'Block Selected Users',
      description: 'Are you sure you want to block the selected users?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          await mutateAsync({
            user_ids: selectedRows,
          })
          refetch()
          setSelectedRows([])
          Modal.open({
            type: 'success',
            icon: <CheckSVG />,
            title: 'Selected users blocked!',
            buttons: ({ onCancel }) => (
              <Button mood="neutral" onClick={onCancel}>
                Close
              </Button>
            ),
          })
        } catch (error) {
          Modal.open({
            type: 'error',
            icon: <ErrorSVG />,
            title: error?.json?.message,
            description: error?.json?.errors?.user_ids[0],
            buttons: ({ onCancel }) => (
              <Button mood="neutral" onClick={onCancel}>
                Close
              </Button>
            ),
          })
        }
      },
    })
  }

  return (
    <div className="mt-12">
      <header className="flex items-center justify-between mb-8">
        <h3>Users Details</h3>
        <Button
          disabled={selectedRows.length === 0}
          onClick={handleBlockSelectedUser}>
          Block Selected Users
        </Button>
      </header>

      <Table
        columns={getColumns(filters)}
        data={data?.json || []}
        loading={isLoading}
        onRowSelect={handleRowSelect}
        selectedRows={selectedRows}
      />
    </div>
  )
}

function getColumns(filters) {
  return [
    {
      title: '#',
      render: (user) => user.id,
    },
    {
      title: 'Name',
      render: (user) => user.username || '-',
    },
    {
      title: 'Email',
      render: (user) => user.email,
    },
    {
      title: 'Wallet',
      render: (user) => user.wallet,
    },
    {
      title: 'Watchtime',
      render: (user) => readableNumber((user.watch_time || 0) / 3600),
    },
    {
      title: 'Registration IP',
      render: (user) => (
        <Link
          to={`/admin/security/traffic?ip_address=${user.registration_ip}&date=${filters.date}`}>
          {user.registration_ip}
        </Link>
      ),
    },
    {
      title: 'Last Active IP',
      render: (user) => (
        <Link
          to={`/admin/security/traffic?ip_address=${user.last_active_from_ip}&date=${filters.date}`}>
          {user.last_active_from_ip}
        </Link>
      ),
    },
    {
      title: 'Referrer',
      render: (user) => user?.referrer?.id,
    },
    {
      title: 'Status',
      render: (user) =>
        user.status === 'inactive' ? (
          <Tag className="bg-red-500 text-light">Inactive</Tag>
        ) : (
          <Tag className="bg-green-400 text-light">Active</Tag>
        ),
    },
  ]
}

function getRateLimitUsersInfo({ date, ip_address, user_ids }) {
  return {
    path: '/security-rate-limit/users/info',
    params: {
      filters: {
        date: formatDateForAPI(date),
        ip_address,
        user_ids: user_ids.join(','),
      },
    },
  }
}

function blockUsers({ user_ids }) {
  return {
    method: 'delete',
    path: '/security-rate-limit/users/block',
    params: {
      user_ids,
    },
  }
}
