import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import notification from 'shared/components/kit/notification'
import Button from 'shared/components/kit/button'
import archiveNotification from 'admin/queries/archiveNotification'
import getNotification from 'admin/queries/getNotification'
import getNotificationsSentByAdmin from 'admin/queries/getNotificationsSentByAdmin'

export default function ArchiveNotificationButton({ notificationId }) {
  const queryClient = useQueryClient()
  const mutation = useMutation(archiveNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getNotification,
        variables: {
          id: notificationId,
        },
      })
      queryClient.invalidateQueries({
        query: getNotificationsSentByAdmin,
      })
      notification.success({
        title: 'Notification archived successfully!',
      })
    },
  })

  const handleArchiveClick = async (e) => {
    e.preventDefault()
    await mutation.mutateAsync({
      id: notificationId,
    })
  }
  return (
    <Button
      onClick={handleArchiveClick}
      className="ml-2"
      outlined
      secondary
      size="sm">
      RECALL NOTIFICATION
    </Button>
  )
}
