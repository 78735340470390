type SeenMessageMutationVariables = {
  id: number,
}

export default function seenMessage(
  variables: SeenMessageMutationVariables = {}
) {
  const { id } = variables
  return {
    method: 'PUT',
    url: `/admin/messages/${variables.id}/seen`,
    variables: { id },
  }
}
