import { useState } from 'react'
import { render } from 'react-dom'
import classnames from 'classnames'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Button from 'shared/components/kit/button'
import modals from './modals'
import classes from './createModal.module.css'

type Params = {
  buttons?: ({ isLoading?: boolean }) => any,
  closeOnSwipeDown?: boolean,
  cancelButtonProps?: Object,
  cancelText?: any,
  closeOnBackdrop?: boolean,
  description?: any,
  icon?: any,
  okButtonProps?: Object,
  okText?: any,
  onCancel?: Function,
  onOk?: Function,
  title?: any,
  type: 'error' | 'info' | 'neutral' | 'success',
}

export default function createModal(params: Params) {
  const {
    buttons,
    cancelButtonProps,
    cancelText = 'Cancel',
    description,
    closeOnBackdrop = true,
    icon,
    okButtonProps,
    okText = 'Ok',
    onCancel,
    onOk,
    title,
    type = 'neutral',
  } = params

  const id = modals().getNextId()

  const target = document.createElement('div')
  document.body.appendChild(target)

  disableBodyScroll(window)

  const close = () => {
    enableBodyScroll(window)
    target.parentNode.removeChild(target)
    modals().remove(id)
  }

  function Modal() {
    const [isLoading, setIsLoading] = useState(false)

    const handleClickBackdrop = () => {
      if (onCancel && closeOnBackdrop && !isLoading) {
        onCancel()
      }
      close()
    }

    const handleCancel = () => {
      if (onCancel && !isLoading) {
        onCancel()
      }
      close()
    }

    const handleOk = () => {
      if (onOk) {
        const result = onOk()
        if (typeof result?.then === 'function') {
          setIsLoading(true)
          const cb = () => {
            setIsLoading(false)
            close()
          }
          result.then(cb).catch(cb)
        } else {
          close()
        }
      } else {
        close()
      }
    }

    const wrapperClassName = classnames(
      classes.wrapper,
      classes.visible,
      classes[type]
    )

    return (
      <div className={wrapperClassName}>
        <div className={classes.backdrop} onClick={handleClickBackdrop} />
        <div className={classes.modal}>
          <section>
            {icon ? <div className={classes.icon}>{icon}</div> : null}
            {title ? <h3 className={classes.title}>{title}</h3> : null}
            {description ? (
              <div className={classes.description}>{description}</div>
            ) : null}
          </section>
          <footer>
            {typeof buttons === 'function' ? (
              buttons({
                isLoading,
                onCancel: handleCancel,
                onOk: handleOk,
              })
            ) : (
              <>
                <Button
                  {...cancelButtonProps}
                  mood="neutral"
                  onClick={handleCancel}>
                  {cancelText}
                </Button>
                <Button
                  {...okButtonProps}
                  loading={isLoading}
                  onClick={handleOk}>
                  {okText}
                </Button>
              </>
            )}
          </footer>
        </div>
      </div>
    )
  }

  render(<Modal />, target)

  modals().add(id, target)

  return {
    close() {
      close()
    },
  }
}
