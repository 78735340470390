import { useState } from 'react'
import { useHistory } from 'react-router'
import getTimeFromNow from 'helpers/utils/getTimeFromNow'
import truncate from 'helpers/utils/truncate'
import UserAvatar from 'shared/components/user-avatar'
import classnames from 'classnames'
import Button from 'shared/components/kit/button'
import ModalNotification from './ModalNotification'
import classes from './Notification.module.css'

export default function Notification({
  avatar,
  body,
  date,
  icon,
  image,
  message,
  videoTitle,
  modalTitle,
  onMarkAsRead,
  readAt,
  title,
  to,
  redirectButtonTitle,
}) {
  const [visible, setVisible] = useState()
  const history = useHistory()
  const handleCancel = () => {
    localStorage.setItem('notificationPopoverState', 'modalClose')
    setVisible(false)
    if (typeof onMarkAsRead === 'function') {
      onMarkAsRead()
    }
  }
  const handleClick = () => {
    localStorage.setItem('notificationPopoverState', 'modalOpen')
    setVisible(true)
  }

  const handleRedirect = () => {
    history.push(to)
  }

  const footer =
    to !== undefined ? (
      <Button onClick={handleRedirect}>{redirectButtonTitle}</Button>
    ) : null

  return (
    <>
      <li
        className={classnames(classes.wrapper, {
          [classes.new]: !readAt,
        })}
        onClick={handleClick}>
        {icon ? <div className={classes.icon}>{icon}</div> : null}
        {avatar ? (
          <div className={classes.avatar}>{<UserAvatar user={avatar} />}</div>
        ) : null}
        <div className={classes.body}>
          <div>{truncate(title, 140)}</div>
          {videoTitle && <div className={classes.videoTitle}>{videoTitle}</div>}
          <span className={classes.date}>{getTimeFromNow(date)}</span>
        </div>
        <div className={classes.image}>{image}</div>
      </li>
      <ModalNotification
        title={modalTitle}
        visible={visible}
        footer={footer}
        body={body}
        image={image}
        videoTitle={videoTitle}
        message={message}
        date={date}
        onCancel={handleCancel}
      />
    </>
  )
}
