import { Link } from 'react-router-dom'
import classes from './index.module.css'

export default function index() {
  return (
    <div className={classes.wrapper}>
      <div className={classes.intro}>
        <h1>Privacy Policy - Today’s Crypto</h1>
        <p>Last updated: December 1, 2022</p>
      </div>

      <h2>Introduction</h2>
      <p>
        Welcome to Today ́s Crypto. This Privacy Policy describes Our policies
        and procedures on the collection, use, and disclosure of Your
        information when You use the Service and tells You about Your privacy
        rights and how the law protects You.
      </p>

      <p>
        We use Your Personal data to provide and improve the Service. Your
        access and use of the Service are subject to this Privacy Policy and Our
        Terms of Service, collectively referred to as the “Terms”. These Terms
        apply to all visitors, users, and others who access or use the Service.
        Please read These Terms carefully before accessing and using the
        Service. By accessing or using the Service You agree to be bound by
        these Terms. If You disagree with any part of the Terms, or if you do
        not understand them, then You may not access the Service.
      </p>

      <p>
        By using the Service on behalf of a company or organization, You
        represent and warrant that You have the authority to act on behalf of
        that entity, and bind the entity to these Terms.
      </p>

      <h2>Interpretation and Definitions</h2>

      <p>
        <h3>Interpretation</h3>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>

      <p>
        <h3>Definitions</h3>
        For the purposes of this Privacy Policy document:
        <ul>
          <li>
            <b>Account</b> means a unique account created for You to access our
            Service or parts of our Service.
          </li>
          <li>
            <b>Company</b> (referred to as either "the Company", "We", "Us" or
            "Our" in this Agreement) refers to BlockBeast AB, Transportvägen 12,
            246 42, Löddeköpinge, Sweden.
          </li>
          <li>
            <b>Country</b> refers to Sweden
          </li>
          <li>
            <b>Device</b> means any device that can access the Service such as a
            computer, a cellphone, or a digital tablet.
          </li>
          <li>
            <b>Personal Data</b> is any information that relates to an
            identified or identifiable individual.
          </li>
          <li>
            <b>Service</b> refers to the Website.
          </li>
          <li>
            <b>Service Provider</b> means any natural or legal person who
            processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </li>
          <li>
            <b>Third-party</b> means any services or content (including data,
            information, products, or services) provided by a third party that
            may be displayed, included, or made available by the Service.
          </li>
          <li>
            <b>Website</b> refers to Today’s Crypto, accessible from{' '}
            <a target="_blank" href="https://todayscrypto.com">
              <span className="text-blue-400 underline">
                www.todayscrypto.com
              </span>
            </a>
          </li>
          <li>
            <b>You</b> means the individual accessing or using the Service, or
            the company, or other legal entity on behalf of which such
            individual is accessing or using the Service, as applicable.
          </li>
        </ul>
      </p>

      <h2>Types of Data Collected</h2>
      <p>
        <h3>Personal Data</h3>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally, identifiable information may include, but is
        not limited to:
      </p>
      <ul>
        <li>Email address</li>
        <li>Usage Data</li>
      </ul>
      <p>
        <h3>Usage Data</h3>
        Usage Data are collected automatically when using the Service.
        <br />
        <br />
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
        <br />
        <br />
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device’s unique ID,
        the IP address of Your mobile device, Your mobile operating system, the
        type of mobile Internet browser You use, unique device identifiers and
        other diagnostic data.
        <br />
        <br />
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </p>

      <h2>Tracking Technologies and Cookies</h2>

      <p>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. The technologies We use may include:
        <ul>
          <li>
            <b>Cookies or Browser Cookies.</b> A cookie is a small file placed
            on Your Device. You can instruct Your browser to refuse all Cookies
            or to indicate when a Cookie is being sent. However, if You do not
            accept Cookies, You may not be able to use some parts of our
            Service. Unless you have adjusted Your browser setting so that it
            will refuse Cookies, our Service may use Cookies.
          </li>
        </ul>
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
        remain on Your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close Your web browser.
        We use both Session and Persistent Cookies for the purposes set out
        below:
        <ul>
          <li>
            <b>Functional Cookies - Essential</b> <br />
            Type: Session Cookies <br />
            Administered by: Us <br />
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </li>
          <br />
          <li>
            <b>Policy Notice Cookie - Essential</b> <br />
            Type: Persistent Cookies <br />
            Administered by: Us <br />
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </li>
          <br />
          <li>
            <b>Preference Cookies - Optional</b> <br />
            Type: Persistent Cookies <br />
            Administered by: Us <br />
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </li>
          <br />
          <li>
            <b>Performance & Analytics Cookies - Optional</b> <br />
            Type: Persistent Cookies <br />
            Administered by: Us <br />
            Purpose: These Cookies help us to understand how visitors and users
            interact with the Website by collecting and reporting information
            anonymously. The data collected helps us to maintain, develop, and
            improve our services.
          </li>
        </ul>
      </p>

      <h2>Use of Your Personal Data</h2>
      <p>
        The Company may use Personal Data for the following purposes:
        <ul>
          <li>
            <b>To provide and maintain our Service</b>, including monitoring the
            usage of our Service.
          </li>
          <li>
            <b>To manage Your Account:</b> to manage Your registration as a user
            of the Service. The Personal Data You provide can give You access to
            different functionalities of the Service that are available to You
            as a registered user.
          </li>
          <li>
            <b>For the performance of a contract:</b> the development,
            compliance, and undertaking of the purchase contract for the
            products, items, or services You have purchased or of any other
            contract with Us through the Service.
          </li>
          <li>
            <b>To contact You:</b> To contact You by email, or other equivalent
            forms of electronic communication, such as a mobile application's
            push notifications regarding updates or informative communications
            related to the functionalities, products, or contracted services,
            including the security updates, when necessary or reasonable for
            their implementation.
          </li>
          <li>
            <b>To provide You</b> with news, special offers, and general
            information about other goods, services, and events that we offer
            that are similar to those that you have already purchased or
            enquired about unless You have opted not to receive such
            information.
          </li>
          <li>
            <b>To manage Your requests:</b> To attend and manage Your requests
            to Us.
          </li>
          <li>
            <b>For business transfers:</b> We may use Your information to
            evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of Our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Data held by Us about our Service users is among the assets
            transferred.
          </li>
          <li>
            <b>For other purposes</b> We may use Your information for other
            purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns, and
            evaluating and improving our Service, products, services, marketing,
            and your experience.
          </li>
        </ul>
        We may share Your personal information in the following situations:
        <ul>
          <li>
            <b>With Service Providers:</b> We may share Your personal
            information with Service Providers to monitor and analyze the use of
            our Service, to contact You.
          </li>
          <li>
            <b>For business transfers:</b> We may share or transfer Your
            personal information in connection with, or during negotiations of,
            any merger, sale of Company assets, financing, or acquisition of all
            or a portion of Our business to another company.
          </li>
          <li>
            <b>With Affiliates:</b> We may share Your information with Our
            affiliates, in which case we will require those affiliates to honor
            this Privacy Policy. Affiliates include Our parent company and any
            other subsidiaries, joint venture partners or other companies that
            We control or that are under common control with Us.
          </li>
          <li>
            <b>With business partners:</b> We may share Your information with
            Our business partners to offer You certain products, services, or
            promotions.
          </li>
          <li>
            <b>With other users:</b> when You share personal information or
            otherwise interact in public areas with other users, such
            information may be viewed by all users and may be publicly
            distributed outside.
          </li>
          <li>
            <b>With Your consent</b> We may disclose Your personal information
            for any other purpose with Your consent.
          </li>
        </ul>
      </p>

      <p>
        <h3>Retention of Your Personal Data</h3>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
        <br />
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
        <h3>Transfer of Your Personal Data</h3>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to and maintained on computers located outside of Your
        state, province, country, or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
        <br /> <br />
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
        <br /> <br />
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </p>

      <h2>Disclosure of Your Personal Data</h2>

      <p>
        <h3>Business Transactions</h3>
        If the Company is involved in a merger, acquisition, or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
        <h3>Law enforcement</h3>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
        <h3>Other legal requirements</h3>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
        <ul>
          <li>Comply with a legal obligation.</li>
          <li>Protect and defend the rights or property of the Company.</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service.
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public.
          </li>
          <li>Protect against legal liability.</li>
        </ul>
        <h3>Security of Your Personal Data</h3>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </p>
      <h2>Children's Privacy</h2>
      <p>
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 13. If You are a parent or guardian and You are aware that
        Your child has provided Us with Personal Data, please contact Us. If We
        become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, We take steps to
        remove that information from Our servers.
      </p>
      <p>
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent's consent before We collect and use that
        information.
      </p>
      <h2>Data rights and your options</h2>
      <p>
        You may decline to share certain information with Today ́s Crypto, in
        which case Today ́s Crypto may not be able to provide the features or
        functionality found in the Our Services. If you have created an account
        on Our Service, you can update some profile information or delete your
        account by logging in and going to the settings page.
      </p>
      <p>
        We will comply with valid requests to exercise your registered rights in
        accordance with applicable laws in your region. However, please note
        that in certain circumstances we must continue to process your data for
        legitimate purposes or to fulfill legal obligations. If you are subject
        to these rights, they may give you the right to:
      </p>
      <ul>
        <li>
          Access or request a copy of certain personal information we hold about
          you.
        </li>
        <li>
          Stop the processing of your personal data for direct marketing
          purposes (including direct marketing processing based on profiling).
        </li>
        <li>Update personal data that is old or incorrect.</li>
        <li>Delete some personal data we have stored about you.</li>
        <li>
          Limit how we process and disclose specific personal information about
          you.
        </li>
        <li>Transfer your personal data to a third-party service.</li>
        <li>Withdraw previous consent to handle your personal data.</li>
      </ul>
      <p>
        I you wish to exercise these rights you may contact us with your
        request. We may ask you for other information to verify your identity
        (such as IP address, date of birth, transaction ID, and email) before
        responding to your request.
      </p>
      <p>
        If you consider it necessary, you can submit a complaint to the data
        protection authority in the country where you live. Another option is to
        seek a court ruling if you believe that your rights have been violated.
      </p>
      <h2>Links to Other Websites</h2>
      <p>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third-party link, You will be directed to that
        third-party’s site. We strongly advise You to review the Privacy Policy
        of every site You visit. We have no control over and assume no
        responsibility for the content, privacy policies, or practices of any
        third-party sites or services.
      </p>
      <h2>Changes to this Privacy Policy</h2>
      <p>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will inform you via email and/or a prominent notice on Our Service
        before the change becomes effective and update the "Last updated" date
        at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, You can contact us:{' '}
        <br />- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; By using our contact form on our
        website:{' '}
        <a target="_blank" href="https://todayscrypto.com">
          <span className="text-blue-400 underline">www.todayscrypto.com</span>
        </a>
      </p>
    </div>
  )
}
