import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import SelectEmailsLocations from 'admin/components/form/select-emails-locations'
import classes from './Fitlers.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <SelectEmailsLocations
        className={classes.select}
        {...form.getInputPropsFor('location')}
        isClearable={true}
        placeholder="Select location..."
      />
      <Button outlined>Search</Button>
    </form>
  )
}
