import { useMemo } from 'react'
import useQueryClient from 'core/react-query/useQueryClient'
import useQuery from 'core/react-query/useQuery'
import useMiniForm from 'core/useMiniForm'
import get from 'lodash/get'
import Drawer from 'shared/components/kit/drawer'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Button from 'shared/components/kit/button'
import ChannelAvatar from 'shared/components/channel-avatar'
import useMutation from 'core/react-query/useMutation'
import notification from 'shared/components/kit/notification'
import getUser from 'admin/queries/getUser'
import savePaymentDetails from 'admin/queries/savePaymentDetails'
import { ReactComponent as CheckSVG } from 'svg/check-circle-filled.svg'
import classes from './ManagePaymentDetailsDrawer.module.css'

const validation = {
  firstName: {
    label: 'First name',
    rule: 'required',
  },
  lastName: {
    label: 'Last name',
    rule: 'required',
  },
  companyName: {
    label: 'Company name',
  },
  streetAddress: {
    label: 'Street address',
    rule: 'required',
  },
  streetNo: {
    label: 'Street no',
    rule: 'required',
  },
  postalCode: {
    label: 'Postal code',
    rule: 'required',
  },
  city: {
    label: 'City',
    rule: 'required',
  },
  country: {
    label: 'Country',
    rule: 'required',
  },
  vatNumber: {
    label: 'Vat number',
  },
  ethAddress: {
    label: 'ERC20 wallet address',
    rule: 'required',
  },
}

export default function ManagePaymentDetailsDrawer(props) {
  const { userId, onClose, visible } = props

  const queryClient = useQueryClient()

  const { data, isLoading, error, isError } = useQuery(getUser, {
    enabled: userId > 0,
    variables: {
      id: userId,
    },
  })

  const user = data?.json

  const userError = get(error, 'json', {})

  const paymentDetails = user?.verified_payment_details

  const channel = user?.channel

  const verifyIconPoA = (
    <span className={classes.verified}>
      <CheckSVG />
    </span>
  )

  const handleCloseDrawer = () => {
    onClose()
  }

  const mutation = useMutation(savePaymentDetails, {
    onSuccess: () => {
      notification.success({
        title: 'Payment details saved!',
      })
      queryClient.invalidateQueries({
        query: getUser,
      })
    },
  })

  const initialValues = useMemo(() => {
    return {
      firstName: paymentDetails?.first_name,
      lastName: paymentDetails?.last_name,
      companyName: paymentDetails?.company_name,
      streetAddress: paymentDetails?.street_address,
      streetNo: paymentDetails?.street_number,
      postalCode: paymentDetails?.postal_code,
      city: paymentDetails?.city,
      country: paymentDetails?.country,
      vatNumber: paymentDetails?.vat_number,
      ethAddress: paymentDetails?.eth_address,
    }
  }, [paymentDetails])

  const form = useMiniForm({
    validation,
    initialValues,
    async onSubmit(values) {
      try {
        values['userId'] = userId
        await mutation.mutateAsync(values)
      } catch (error) {
        notification.error({ title: error.json.message })
      }
    },
  })

  return (
    <Drawer
      error={userError}
      isError={isError}
      loading={isLoading}
      onClose={handleCloseDrawer}
      visible={visible}>
      <div className={classes.wrapper}>
        <h3>Payment details</h3>

        <div className="py-8 flex justify-center flex-wrap">
          <ChannelAvatar channel={channel} size="24" />
          <h3 className="w-full text-center mt-2">{channel?.name}</h3>
        </div>
        <fieldset>
          <form onSubmit={form.submit}>
            <div className={classes.nameFields}>
              <Field
                label="Channel name"
                icon={<span className={classes.icon}>{verifyIconPoA}</span>}>
                <Input
                  label="Channel name"
                  placeholder={channel?.name}
                  disabled
                />
              </Field>
            </div>
            <div className={classes.nameFields}>
              <Field
                label="First name"
                {...form.getErrorPropsFor('firstName')}
                required={!form.values.firstName}
                icon={<span className={classes.icon}>{verifyIconPoA}</span>}>
                <Input
                  {...form.getInputPropsFor('firstName')}
                  label="First name"
                  placeholder="First name"
                />
              </Field>
              <Field
                label="Last name"
                {...form.getErrorPropsFor('lastName')}
                required={!form.values.lastName}
                icon={<span className={classes.icon}>{verifyIconPoA}</span>}>
                <Input
                  {...form.getInputPropsFor('lastName')}
                  label="Last name"
                  placeholder="Last name"
                />
              </Field>
            </div>

            <div className={classes.streetFields}>
              <Field
                label="Company name"
                {...form.getErrorPropsFor('companyName')}>
                <Input
                  {...form.getInputPropsFor('companyName')}
                  label="Company name"
                  placeholder="Company name"
                />
              </Field>
              <Field label="VAT number" {...form.getErrorPropsFor('vatNumber')}>
                <Input
                  {...form.getInputPropsFor('vatNumber')}
                  label="VAT number"
                  placeholder="VAT number"
                />
              </Field>
            </div>
            <div className={classes.streetFields}>
              <Field
                label="Billing address"
                {...form.getErrorPropsFor('streetAddress')}
                required={!form.values.streetAddress}
                icon={<span className={classes.icon}>{verifyIconPoA}</span>}>
                <Input
                  {...form.getInputPropsFor('streetAddress')}
                  label="Street address"
                  placeholder="Street address"
                />
              </Field>
              <Field
                label="Street no"
                {...form.getErrorPropsFor('streetNo')}
                required={!form.values.streetNo}
                icon={<span className={classes.icon}>{verifyIconPoA}</span>}>
                <Input
                  {...form.getInputPropsFor('streetNo')}
                  label="Street no"
                  placeholder="Street no"
                />
              </Field>
            </div>
            <div className={classes.postalCityFields}>
              <Field
                label="Postal code"
                {...form.getErrorPropsFor('postalCode')}
                required={!form.values.postalCode}
                icon={<span className={classes.icon}>{verifyIconPoA}</span>}>
                <Input
                  {...form.getInputPropsFor('postalCode')}
                  label="Postal code"
                  placeholder="Postal code"
                />
              </Field>
              <Field
                label="City"
                {...form.getErrorPropsFor('city')}
                required={!form.values.city}
                icon={<span className={classes.icon}>{verifyIconPoA}</span>}>
                <Input
                  {...form.getInputPropsFor('city')}
                  label="City"
                  placeholder="City"
                />
              </Field>
            </div>
            <div className={classes.countryVatFields}>
              <Field
                label="Country"
                {...form.getErrorPropsFor('country')}
                required={!form.values.country}
                icon={<span className={classes.icon}>{verifyIconPoA}</span>}>
                <Input
                  {...form.getInputPropsFor('country')}
                  label="Country"
                  placeholder="Country"
                />
              </Field>
            </div>
            <div>
              <Field
                label="ERC20 wallet address"
                help="This address will be the one we send your earnings to."
                {...form.getErrorPropsFor('ethAddress')}
                required={!form.values.ethAddress}
                icon={<span className={classes.icon}>{verifyIconPoA}</span>}>
                <Input
                  {...form.getInputPropsFor('ethAddress')}
                  label="ERC20 wallet address"
                  placeholder="0x..."
                />
              </Field>
            </div>
            <Button>Save</Button>
          </form>
        </fieldset>
      </div>
    </Drawer>
  )
}
