import { useState } from 'react'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import { ReactComponent as SettingSVG } from 'icons/settings-filled.svg'
import { ReactComponent as DeleteSVG } from 'icons/delete-filled.svg'
import useTimelineActions from '../hooks/useTimelineActions'
import EditOverlay from './EditOverlay'

export default function OverlayRightActions({ overlay }) {
  return (
    <div>
      <EditButton overlay={overlay} />
      <DeleteButton id={overlay.id} />
    </div>
  )
}

function EditButton({ overlay }) {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <Button
        disabled={overlay.type === 'subscribe'}
        mood="neutral"
        onClick={() => setVisible(true)}
        square
        transparent>
        <SettingSVG />
      </Button>

      <EditOverlay
        onCancel={() => setVisible(false)}
        overlay={overlay}
        visible={visible}
      />
    </>
  )
}

function DeleteButton({ id }) {
  const { deleteOverlay } = useTimelineActions()

  const [visible, setVisible] = useState(false)

  const handleOk = () => {
    deleteOverlay(id)
    setVisible(false)
  }

  return (
    <>
      <Button
        mood="neutral"
        onClick={() => setVisible(true)}
        square
        transparent>
        <DeleteSVG />
      </Button>

      <Modal
        cancelText="No"
        okText="Yes, Delete Overlay"
        onCancel={() => setVisible(false)}
        onOk={handleOk}
        size="sm"
        title="Delete Overlay"
        visible={visible}>
        Are you sure you want to delete this overlay?
      </Modal>
    </>
  )
}
