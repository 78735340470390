type GetMessagesQueryVariables = {
  page?: number,
  sort?: string,
}

export default function getMessages(variables: GetMessagesQueryVariables = {}) {
  const { page, sort } = variables

  return {
    name: 'messages',
    path: '/admin/messages',
    params: {
      page,
      sort,
    },
  }
}
