import { useHistory, useParams } from 'react-router'
import Tabs from 'shared/components/kit/tabs'
import Page from 'admin/components/page'
import ReportedComments from 'admin/pages/reports/components/comments/ReportedComments'
import ReportedVideos from 'admin/pages/reports/components/videos/ReportedVideos'

export default function Reports() {
  const { activeTab, id } = useParams()
  const history = useHistory()

  const handleTabChange = (val) => {
    history.push(`/admin/reports/${val}`)
  }
  return (
    <Page title="Reports">
      <Tabs activeName={activeTab} onTabChange={handleTabChange}>
        <Tabs.Tab name="videos" title="Reported Videos">
          <ReportedVideos id={id} />
        </Tabs.Tab>
        <Tabs.Tab name="comments" title="Reported Comments">
          <ReportedComments id={id} />
        </Tabs.Tab>
      </Tabs>
    </Page>
  )
}
