// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".choose-video-modal_thumbnail__bvv8z {\n  width: 95px;\n}\n\n.choose-video-modal_thumbnail__bvv8z img {\n  height: 45px;\n  width: 80px;\n}\n\n.choose-video-modal_title__3JMEj {\n  /*max-width: 180px;*/\n}\n\n.choose-video-modal_title__3JMEj .choose-video-modal_untitled__r-OV4 {\n  opacity: 0.5;\n}\n\n.choose-video-modal_publishDate__LOXgb {\n  width: 8rem;\n}\n\n.choose-video-modal_category__2_64q {\n  width: 12rem;\n}\n\n.choose-video-modal_status__1AP4x {\n  width: 10rem;\n}\n", "",{"version":3,"sources":["webpack://src/shared/components/choose-video-modal/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,oBAAoB;AACtB;;AAEA;ECXA,YAAmB;ADanB;;AAEA;ECfA,WAAmB;ADiBnB;;AAEA;ECnBA,YAAmB;ADqBnB;;AAEA;ECvBA,YAAmB;ADyBnB","sourcesContent":[".thumbnail {\n  width: 95px;\n}\n\n.thumbnail img {\n  height: 45px;\n  width: 80px;\n}\n\n.title {\n  /*max-width: 180px;*/\n}\n\n.title .untitled {\n  @apply opacity-50;\n}\n\n.publishDate {\n  @apply w-32;\n}\n\n.category {\n  @apply w-48;\n}\n\n.status {\n  @apply w-40;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnail": "choose-video-modal_thumbnail__bvv8z",
	"title": "choose-video-modal_title__3JMEj",
	"untitled": "choose-video-modal_untitled__r-OV4",
	"publishDate": "choose-video-modal_publishDate__LOXgb",
	"category": "choose-video-modal_category__2_64q",
	"status": "choose-video-modal_status__1AP4x"
};
export default ___CSS_LOADER_EXPORT___;
