// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdItem_wrapper__3fLAn {\n  display: flex;\n  align-items: flex-end;\n  justify-content: space-between;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n}\n\n.AdItem_wrapperSquare__ar843 {\n  display: flex;\n  align-items: flex-end;\n  justify-content: space-between;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n}\n\n.AdItem_wrapperSquare__ar843 form {\n  display: flex;\n  justify-content: space-between;\n  gap: 1rem;\n}\n\n.AdItem_status__10O1X {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  margin-top: 0.5rem;\n  margin-bottom: 0.75rem;\n}\n\n.AdItem_actionButtons__SLXGC {\n  display: flex;\n  flex-direction: row-reverse;\n  margin-top: -2.5rem;\n  gap: 1rem;\n}\n\n.AdItem_actionButtonsSquare__1A3iV {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/ads-manager/ads-manager-campaigns/components/AdItem.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,qBAAmB;EAAnB,8BAAmB;EAAnB,iBAAmB;EAAnB,oBAAmB;ADAnB;;AAEA;ECFA,aAAmB;EAAnB,qBAAmB;EAAnB,8BAAmB;EAAnB,iBAAmB;EAAnB,oBAAmB;ADInB;;AAEA;ECNA,aAAmB;EAAnB,8BAAmB;EAAnB,SAAmB;ADQnB;;AAEA;ECVA,mBAAmB;EAAnB,oBAAmB;EAAnB,kBAAmB;EAAnB,sBAAmB;ADYnB;;AAEA;ECdA,aAAmB;EAAnB,2BAAmB;EAAnB,mBAAmB;EAAnB,SAAmB;ADgBnB;;AAEA;EClBA,aAAmB;EAAnB,sBAAmB;EAAnB,SAAmB;ADoBnB","sourcesContent":[".wrapper {\n  @apply flex justify-between items-end py-4;\n}\n\n.wrapperSquare {\n  @apply flex justify-between items-end py-4;\n}\n\n.wrapperSquare form {\n  @apply flex justify-between gap-4;\n}\n\n.status {\n  @apply text-sm mb-3 mt-2;\n}\n\n.actionButtons {\n  @apply flex gap-4 flex-row-reverse -mt-10;\n}\n\n.actionButtonsSquare {\n  @apply flex flex-col gap-4;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AdItem_wrapper__3fLAn",
	"wrapperSquare": "AdItem_wrapperSquare__ar843",
	"status": "AdItem_status__10O1X",
	"actionButtons": "AdItem_actionButtons__SLXGC",
	"actionButtonsSquare": "AdItem_actionButtonsSquare__1A3iV"
};
export default ___CSS_LOADER_EXPORT___;
