import { useState } from 'react'
import { useParams } from 'react-router'
import useQueryClient from 'core/useQueryClient'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import Field from 'shared/components/form/field'
import Alert from 'shared/components/kit/alert'
import Modal from 'shared/components/kit/modal'
import FileInput from 'shared/components/form/file'
import notification from 'shared/components/kit/notification'
import SelectLanguage from 'shared/components/form/select-language'
import addSubtitle from 'publisher/queries/addSubtitle'
import getVideoSubtitles from 'publisher/queries/getVideoSubtitles'

const validation = {
  file: {
    label: 'File',
    rule: 'required',
  },
  languageId: {
    label: 'Language',
    rule: 'required',
  },
}

export default function UploadSubtitleModal({ onClose, subtitles, visible }) {
  const { id: videoId } = useParams()
  const [step, setStep] = useState('')
  const queryClient = useQueryClient()

  const addSubtitleMutation = useMutation(addSubtitle)

  const [selectedLanguage, setSelectedLanguage] = useState('')

  const form = useMiniForm({
    validation,
    async onSubmit(values) {
      try {
        if (values.file.name.split('.').pop() !== 'srt') {
          notification.error({
            title: 'Invalid file format. Only .srt is allowed.',
          })
          return false
        }

        await addSubtitleMutation.mutateAsync({
          ...values,
          id: Number(videoId),
        })
        onClose()
        form.reset()
        setStep('')
        queryClient.invalidateQueries({
          query: getVideoSubtitles,
        })
        notification.success({
          title: 'Subtitle successfully created.',
        })
      } catch (error) {
        onClose()
        notification.error({ title: error.json.message })
        form.reset()
        setStep('')
        handleCancel()
      }
    },
  })

  const handleCancel = () => {
    onClose()
    if (!addSubtitleMutation.isLoading) {
      setStep('')
    }
    form.reset()
  }

  const handleOk = async (subtitles) => {
    const exist =
      subtitles.filter((sub) => sub.language.id === form.get('languageId'))
        .length > 0
    if (step === '' && exist) {
      setSelectedLanguage(
        subtitles.filter((sub) => sub.language.id === form.get('languageId'))[0]
          .language.display_name
      )

      setStep('exist')
    } else {
      await form.submit()
    }
  }

  return (
    <Modal
      okText={step === '' ? 'Upload Subtitle' : 'Yes'}
      onCancel={handleCancel}
      onOk={() => handleOk(subtitles)}
      size="sm"
      submitting={addSubtitleMutation.isLoading}
      title="Upload Subtitle"
      visible={visible}>
      {step === '' && (
        <form onSubmit={form.submit}>
          {form.failed && (
            <Alert className="mb-4" title={form.error.message} type="error" />
          )}
          <Field
            label="Select language"
            {...form.getErrorPropsFor('languageId')}>
            <SelectLanguage
              key={step ? '1' : '0'}
              {...form.getInputPropsFor('languageId')}
              menuPosition="fixed"
              isClearable
            />
          </Field>
          <Field
            label="Select Subtitle File"
            {...form.getErrorPropsFor('file')}>
            <div>
              <FileInput
                placeholder="Select file..."
                {...form.getInputPropsFor('file')}
              />
            </div>
          </Field>
        </form>
      )}
      {step === 'exist' && (
        <p>
          You have already uploaded {selectedLanguage} subtitle, do you want to
          replace it?
        </p>
      )}
    </Modal>
  )
}
