// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Status_status__2WbPR {\n  display: inline-flex;\n  align-items: center;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  color: var(--neutral);\n  width: 10rem;\n}\n\n.Status_status__2WbPR svg {\n  display: block;\n  height: 1.25rem;\n  fill: currentColor;\n  width: 1.25rem;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/profile/components/Status.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,oBAAmB;EAAnB,mBAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,qBAAmB;EAAnB,YAAmB;ADAnB;;AAEA;ECFA,cAAmB;EAAnB,eAAmB;EAAnB,kBAAmB;EAAnB,cAAmB;ADInB","sourcesContent":[".status {\n  @apply inline-flex items-center text-neutral text-sm w-40;\n}\n\n.status svg {\n  @apply block fill-current h-5 w-5;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "Status_status__2WbPR"
};
export default ___CSS_LOADER_EXPORT___;
