import { useHistory } from 'react-router'
import { useState } from 'react'
import get from 'lodash/get'
import useQueryClient from 'core/useQueryClient'
import useMutation from 'core/useMutation'
import useMiniForm from 'core/useMiniForm'
import useQuery from 'core/react-query/useQuery'
import notification from 'shared/components/kit/notification'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import Field from 'shared/components/form/field'
import Radio from 'shared/components/form/radio'
import Input from 'shared/components/form/input'
import hideVideo from 'admin/queries/hideVideo'
import getReasons from 'admin/queries/getReasons'
import classes from './HideVideoButton.module.css'

export default function HideVideoButton({ id }) {
  const history = useHistory()
  const [step, setStep] = useState('')

  const { data, error, isError, loading, isLoading } = useQuery(getReasons, {
    variables: {
      key: 'video_hide_reasons',
    },
  })

  const reasons = get(data, 'json', [])

  const queryClient = useQueryClient()

  const mutation = useMutation(hideVideo, {
    onSuccess() {
      queryClient.invalidateQueries('reportedVideos')
      queryClient.invalidateQueries(['video', id])
    },
  })

  const form = useMiniForm({
    async onSubmit({ custom, reason }) {
      try {
        await mutation.mutateAsync({
          id,
          reason: reason >= 0 ? reasons[reason].key : custom,
        })
        notification.success({
          title: 'Video is hidden successfully!',
        })
        history.push('/admin/reports/videos')
        setStep('')
        form.reset()
      } catch (error) {
        notification.error({
          title: error.message,
        })
      }
    },
  })

  const handleShow = () => {
    setStep('confirm')
  }

  const handleCancel = () => {
    setStep('')
    form.reset()
  }

  const handleOk = async () => {
    if (step === 'confirm') {
      setStep('reason')
    } else {
      await form.submit()
    }
  }

  const canOk =
    step === 'confirm' ||
    form.get('reason') >= 0 ||
    (form.get('reason') === -1 && form.get('custom'))

  return (
    <>
      <Button className={classes.hideButton} onClick={handleShow}>
        Hide
      </Button>
      <Modal
        loading={mutation.isLoading}
        okButtonProps={{
          disabled: !canOk,
        }}
        okText="Hide Video"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Hide Video"
        visible={step !== ''}>
        {step === 'confirm' && <p>Are you sure you want to hide this video?</p>}
        {step === 'reason' && (
          <>
            <Field label="What's the reason for hiding this video?">
              {reasons.map((text, key) => (
                <Radio
                  checked={form.get('reason') === key}
                  onChange={() => form.change('reason', key)}
                  description={text.value}
                />
              ))}
              <Radio
                checked={form.get('reason') === -1}
                onChange={() => form.change('reason', -1)}
                description="Other..."
              />
            </Field>
            {form.get('reason') === -1 && (
              <Field>
                <Input
                  {...form.getInputPropsFor('custom')}
                  placeholder="Add your custom reason here..."
                  multiline
                />
              </Field>
            )}
          </>
        )}
      </Modal>
    </>
  )
}
