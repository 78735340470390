import dayjs from 'dayjs'
import Select from 'shared/components/form/select'

export default function SelectYears(props) {
  const years = []
  for (let i = 0; i < 7; i++) {
    years.push(dayjs().subtract(i, 'year').format('YYYY'))
  }

  const options = years.map((year) => ({
    label: year,
    value: year,
  }))

  return (
    <>
      <Select {...props} options={options} />
    </>
  )
}
