import useQuery from 'core/react-query/useQuery'
import formatDate from 'helpers/utils/formatDate'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import getUserName from 'helpers/utils/getUserName'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import truncate from 'helpers/utils/truncate'
import Table from 'shared/components/kit/table'
import Alert from 'shared/components/kit/alert'
import UserAvatar from 'shared/components/user-avatar'
import getAdmins from 'admin/queries/getAdmins'
import Page from 'admin/components/page'
import Filters from './components/Filters'
import CreateAdminButton from './components/CreateAdminButton'

const FILTER_CONFIG = {
  base: '/admin/setting/administrators',
  params: {
    page: 'pagination',
    search: 'any',
    sort: 'any',
  },
}

export default function Administrators() {
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)

  const { data, error, isError, isFetching } = useQuery(getAdmins, {
    keepPreviousData: true,
    variables: {
      page: filters.page,
      search: filters.search,
      sort: filters.sort,
      include: 'role',
    },
  })

  const {
    data: admins,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  if (isError) {
    return <Alert title={error} content={error?.message} type="error" />
  }

  const actions = <CreateAdminButton />

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  return (
    <Page actions={actions} description={description} title="Administrators">
      <Filters initialFilters={filters} onSubmit={setFilters} />
      <Table
        columns={columns}
        data={admins}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
    </Page>
  )
}

const columns = [
  {
    className: 'w-20',
    render: (user) => <UserAvatar user={user} />,
  },
  {
    title: 'Username',
    render: (user) => getUserName(user),
  },
  {
    title: 'Email',
    render: (user) => (
      <span title={user.email?.length > 35 ? user.email : ''}>
        {truncate(user.email, 35)}
      </span>
    ),
  },
  {
    title: 'Signed Up Date',
    render: (admin) => formatDate(admin.created_at),
  },
]
