type ReportCommentMutationVariables = {
  id: number,
  reason: string,
}

export default function reportComment(
  variables: ReportCommentMutationVariables
) {
  return {
    method: 'post',
    path: `/comments/${variables.id}/report`,
    params: {
      reason: variables.reason.value,
    },
  }
}
