import useQuery from 'core/react-query/useQuery'
import { useHistory } from 'react-router'
import { useMemo, useState } from 'react'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import getUserName from 'helpers/utils/getUserName'
import Filters from './Filter'
import Table from 'shared/components/kit/table'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getCommentReports from 'admin/queries/getCommentReports'
import UserAvatar from 'shared/components/user-avatar'
import getComment from 'admin/queries/getComment'
import formatDate from 'helpers/utils/formatDate'
import Drawer from 'shared/components/kit/drawer'
import getUserAvatar from 'helpers/utils/getUserAvatar'
import DeleteCommentButton from './DeleteCommentButton'
import Spin from 'shared/components/kit/spin'
import Icon from 'shared/components/kit/icon'
import classes from './CommentDetails.module.css'

const INITIAL_FILTERS = {
  page: 1,
  reason: '',
}

export default function CommentDetails({ commentId }) {
  const history = useHistory()
  const [filters, setFilters] = useState(INITIAL_FILTERS)

  const {
    data: commentData,
    error: commentError,
    isError: commentIsError,
    isFetching: commentIsFetching,
    isLoading: commentIsLoading,
  } = useQuery(getComment, {
    enabled: typeof commentId !== 'undefined',
    keepPreviousData: true,
    variables: {
      id: commentId,
    },
  })

  const handleCloseDrawer = () => {
    history.push('/admin/reports/comments')
  }

  const comment = get(commentData, 'json')

  const { data, error, isError, isFetching } = useQuery(getCommentReports, {
    keepPreviousData: true,
    variables: {
      page: filters.page,
      reason: filters.reason,
      id: commentId,
    },
  })

  const {
    data: reports,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  return (
    <Drawer
      error={commentError?.json}
      isError={commentIsError}
      isloading={commentIsLoading}
      onClose={handleCloseDrawer}
      visible={!!commentId}>
      {commentData ? (
        <>
          <div className={classes.commentInfo}>
            <img src={getUserAvatar(comment.user?.avatar)} />
            <ul>
              <li>{comment.user?.email}</li>
              <li>Signed Up on {formatDate(comment.user?.created_at)}</li>
            </ul>
          </div>
          <p>{comment.text}</p>
          <div className={classes.action}>
            <p>
              This comment is reported {total} times. If you need to take
              action, you can delete this comment using the "Delete" button.
            </p>
            <DeleteCommentButton id={comment.id} />
          </div>
        </>
      ) : null}
      {commentData ? (
        <>
          <h5 className={classes.total}>{total} reports for this comment</h5>
          <Filters
            commentId={commentId}
            initialFilters={INITIAL_FILTERS}
            onSubmit={setFilters}
          />
          <Table
            bordered={false}
            columns={columns}
            data={reports}
            loading={isFetching}
            currentPage={currentPage}
            lastPage={lastPage}
            onPaginate={handlePaginate}
          />
        </>
      ) : (
        <div className={classes.loading}>
          <Icon>
            <Spin />
          </Icon>
          <div>Loading...</div>
        </div>
      )}
    </Drawer>
  )
}

function getColumns() {
  return [
    {
      className: classes.avatar,
      render: (report) => <UserAvatar user={report.user} />,
    },
    {
      title: 'Username',
      render: (report) => (
        <Link to={`/admin/users?id=${report.user.id}`}>
          {getUserName(report.user)}
        </Link>
      ),
    },
    {
      title: 'Reason',
      render: (report) => report.reason_text,
    },
    {
      align: 'center',
      title: 'Create Date',
      render: (report) => formatDate(report.created_at),
    },
  ]
}
