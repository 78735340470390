type Variables = {
  id?: number,
  slug?: string,
}

export default function getContent(variables: Variables = {}) {
  const { id, slug } = variables
  return {
    path: `/contents/${id || slug}`,
  }
}
