// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "ul.Message_messages__21jlC li {\n  margin-bottom: 2rem;\n}\n\nul.Message_messages__21jlC li header {\n  display: flex;\n  align-items: center;\n  margin-bottom: 0.5rem;\n  color: var(--overlay-color-muted-text);\n}\n\nul.Message_messages__21jlC li header img {\n  border-radius: 9999px;\n  height: 2rem;\n  width: 2rem;\n}\n\nul.Message_messages__21jlC li header > span {\n  margin-left: 0.5rem;\n}\n\n.Message_loading__22vON {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  font-size: 1.125rem;\n  line-height: 1.75rem;\n  opacity: 0.5;\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n}\n\n.Message_files__3Q5Nb {\n  display: grid;\n  margin-top: 0.25rem;\n  column-gap: 1rem;\n  row-gap: 1.5rem;\n  grid-template-columns: repeat(5, minmax(0, 1fr));\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/messages/components/Message.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,mBAAmB;ADAnB;;AAEA;ECFA,aAAmB;EAAnB,mBAAmB;EAAnB,qBAAmB;EAAnB,sCAAmB;ADInB;;AAEA;ECNA,qBAAmB;EAAnB,YAAmB;EAAnB,WAAmB;ADQnB;;AAEA;ECVA,mBAAmB;ADYnB;;AAEA;ECdA,aAAmB;EAAnB,mBAAmB;EAAnB,uBAAmB;EAAnB,YAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,YAAmB;EAAnB,kBAAmB;EAAnB,QAAmB;EAAnB,SAAmB;EAAnB,WAAmB;ADgBnB;;AAEA;EClBA,aAAmB;EAAnB,mBAAmB;EAAnB,gBAAmB;EAAnB,eAAmB;EAAnB,gDAAmB;ADoBnB","sourcesContent":["ul.messages li {\n  @apply mb-8;\n}\n\nul.messages li header {\n  @apply flex items-center mb-2 text-overlayColor-mutedText;\n}\n\nul.messages li header img {\n  @apply h-8 rounded-full w-8;\n}\n\nul.messages li header > span {\n  @apply ml-2;\n}\n\n.loading {\n  @apply absolute flex h-full items-center justify-center left-0 opacity-50 text-lg top-0 w-full;\n}\n\n.files {\n  @apply grid gap-y-6 gap-x-4 mt-1 grid-cols-5;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messages": "Message_messages__21jlC",
	"loading": "Message_loading__22vON",
	"files": "Message_files__3Q5Nb"
};
export default ___CSS_LOADER_EXPORT___;
