type GetPayoutItemsQueryVariables = {
  page?: number,
  per_page?: number,
  status?: string,
  month?: string,
}

export default function getPayoutItems(
  variables: GetPayoutItemsQueryVariables = {}
) {
  const { page, status, month, per_page } = variables
  return {
    name: 'monetizationPayoutsForAdmin',
    path: '/admin/monetization/payouts',
    params: {
      page,
      per_page,
      sort: 'amount_desc',
      filters: {
        status,
        month,
      },
    },
  }
}
