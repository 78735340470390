type Variables = {
  id: number,
  name: string,
  status: string,
}

export default function updateTag(variables: Variables = {}) {
  const { name, status, id } = variables
  return {
    method: 'PUT',
    path: `/admin/tags/${id}`,
    params: {
      name,
      status,
    },
  }
}
