import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import useQuery from 'core/react-query/useQuery'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import notification from 'shared/components/kit/notification'
import Modal from 'shared/components/kit/modal'
import getComments from 'publisher/queries/getComments'
import markAllMentionedCommentsAsRead from 'publisher/mutations/markAllMentionedCommentsAsRead'
import Page from 'publisher/components/page'
import Layout from 'publisher/components/page'
import Filters from 'publisher/pages/comments/shared/components/Filters'
import Main from '../shared/components/Main'

export default function MentionedComments() {
  const FILTER_CONFIG = useMemo(
    () => ({
      base: '/publisher/comments/mentioned',
      params: {
        page: 'pagination',
        sort: " '' | most_liked | oldest ",
        video: 'number',
        time: 'string',
        perPage: '50 | 75 | 100',
        onlyMyMentions: 'boolean',
      },
    }),
    []
  )

  const parsedLocation = useParsedLocation()
  const { sort, video, time, perPage, unread } = parsedLocation.queryParams
  const queryClient = useQueryClient()
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)
  const [selectedComment, setSelectedComment] = useState()
  const [onlyShowMentions, setOnlyShowMentions] = useState(
    unread == 'true' || false
  )

  useEffect(() => {
    setOnlyShowMentions(unread == 'true' || false)
  }, [parsedLocation])

  const history = useHistory()
  const handleChangeShowOnlyMentions = () => {
    setOnlyShowMentions((prev) => !prev)
    history.push({
      pathname: '/publisher/comments/mentioned',
      search: `?unread=${!onlyShowMentions}`,
    })
  }

  const { data, error, isError, isFetching, isLoading } = useQuery(
    getComments,
    {
      keepPreviousData: true,
      variables: {
        page: Number(filters.page) || 1,
        sort: filters.sort,
        video: Number(filters.video),
        time: filters.time,
        perPage: filters.perPage,
        onlyMyMentions: true,
        onlyRemembers: false,
        onlyUnreadReplies: onlyShowMentions,
      },
    }
  )

  const {
    data: comments,
    currentPage,
    lastPage,
  } = getPaginatedDataFromAPI(data?.json)

  const markAllAsRead = useMutation(markAllMentionedCommentsAsRead, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getComments,
      })
      notification.success({
        title: 'All mentioned comments read successfully!',
      })
    },
  })

  const handleMarkAllAsRead = async () => {
    Modal.open({
      title: 'Read mentioned comments',
      description:
        'Are you sure you want to mark all mentioned comments as read?',
      okText: 'Yes',
      onOk: () => {
        try {
          markAllAsRead.mutateAsync()
          setSelectedComment()
        } catch (error) {
          notification.error({ title: error.message })
        }
      },
    })
  }

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleCloseDrawer = () => {
    setSelectedComment()
  }

  const handleFilter = (next) => {
    setFilters(next)
    handleCloseDrawer()
  }

  const handleSetSelectedComment = (value) => {
    setSelectedComment(value)
  }

  if (isError) {
    return <Page error={error.message} />
  }

  return (
    <Layout width="lg">
      <Filters initialFilters={filters} onSubmit={handleFilter} />
      <Main
        comments={comments}
        isLoading={isLoading}
        isFetching={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
        onCloseDrawer={handleCloseDrawer}
        onSetSelectedComment={handleSetSelectedComment}
        selectedComment={selectedComment}
        onChangeShowOnlyMentions={handleChangeShowOnlyMentions}
        onlyShowMentions={onlyShowMentions}
        onMarkAllAsRead={handleMarkAllAsRead}
      />
    </Layout>
  )
}
