// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Charts_wrapper__Swq7z {\n  padding: 1.5rem;\n}\n\n.Charts_header__1gqdG {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/invite-earn/components/Charts.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,eAAmB;ADAnB;;AAEA;ECFA,aAAmB;EAAnB,mBAAmB;EAAnB,8BAAmB;EAAnB,qBAAmB;ADInB","sourcesContent":[".wrapper {\n  @apply p-6;\n}\n\n.header {\n  @apply flex items-center justify-between mb-6;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Charts_wrapper__Swq7z",
	"header": "Charts_header__1gqdG"
};
export default ___CSS_LOADER_EXPORT___;
