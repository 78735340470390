import { useMemo, useState } from 'react'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import getMyPlaylists from 'publisher/queries/getMyPlaylists'
import getPlaylist from 'publisher/queries/getPlaylist'
import updatePlaylist from 'publisher/queries/updatePlaylist'

const VALIDATION = {
  name: {
    label: 'Playlist Name',
    rule: 'required',
  },
}

export default function EditPlaylistButton({ playlist }) {
  const queryClient = useQueryClient()

  const update = useMutation(updatePlaylist, {
    onSuccess(response) {
      queryClient.setQueryData({
        query: getPlaylist,
        variables: {
          id: playlist.id,
        },
        updater: () => response,
      })

      queryClient.invalidateQueries({
        query: getMyPlaylists,
      })
    },
  })

  const [visible, setVisible] = useState(false)

  const initialValues = useMemo(
    () => ({
      name: playlist?.name,
    }),
    [playlist]
  )

  const form = useMiniForm({
    initialValues,
    resetOnSuccess: true,
    validation: VALIDATION,
    async onSubmit(values) {
      try {
        await update.mutateAsync({
          id: playlist.id,
          ...values,
        })
        notification.success({
          title: 'Changes are saved to the playlist successfully!',
        })
        setVisible(false)
      } catch (error) {
        throw error
      }
    },
  })

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <Button onClick={() => setVisible(true)}>Edit Playlist Name</Button>

      <Modal
        okText="Save Changes"
        onCancel={handleCancel}
        onOk={form.submit}
        size="sm"
        submitting={form.submitting}
        title="Edit Playlist"
        visible={visible}>
        <Field label="Playlist Name" {...form.getErrorPropsFor('name')}>
          <Input {...form.getInputPropsFor('name')} />
        </Field>
      </Modal>
    </>
  )
}
