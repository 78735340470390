type GetUserQueryVariables = {
  id: number,
}

export default function getUser(variables: GetUserQueryVariables = {}) {
  const { id } = variables
  return {
    name: 'user',
    path: `/admin/users/${id}`,
  }
}
