type RestoreNotificationMutationVariables = {
  id: number,
}

export default function restoreNotification(
  variables: RestoreNotificationMutationVariables
) {
  return {
    method: 'PUT',
    path: `/admin/notifications/${variables.id}/restore`,
  }
}
