// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CoinsActiveCampaigns_name__2YMLx {\n  width: 16.666667%;\n}\n\n.CoinsActiveCampaigns_campaigns__3ewkm {\n  width: 12rem;\n}\n\n.CoinsActiveCampaigns_thumbnail__2BYEq {\n  width: 2rem;\n}\n\n.CoinsActiveCampaigns_cryptoName__45ozr {\n  display: flex;\n  flex-direction: column;\n  gap: 0.25rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/ads-manager/buy-buttons/components/coins-Active-compaign/CoinsActiveCampaigns.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,iBAAmB;ADAnB;;AAEA;ECFA,YAAmB;ADInB;;AAEA;ECNA,WAAmB;ADQnB;;AAEA;ECVA,aAAmB;EAAnB,sBAAmB;EAAnB,YAAmB;ADYnB","sourcesContent":[".name {\n  @apply w-1/6;\n}\n\n.campaigns {\n  @apply w-48;\n}\n\n.thumbnail {\n  @apply w-8;\n}\n\n.cryptoName {\n  @apply flex flex-col gap-1;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "CoinsActiveCampaigns_name__2YMLx",
	"campaigns": "CoinsActiveCampaigns_campaigns__3ewkm",
	"thumbnail": "CoinsActiveCampaigns_thumbnail__2BYEq",
	"cryptoName": "CoinsActiveCampaigns_cryptoName__45ozr"
};
export default ___CSS_LOADER_EXPORT___;
