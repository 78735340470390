import classnames from 'classnames'
import { ReactComponent as TCGSVG } from 'svg/tcg-circle.svg'
import useTokenPointsOverview from 'publisher/hooks/useTokenPointsOverview'
import formatNumber from 'helpers/utils/formatNumber'
import classes from './ProgressBar.module.css'

export default function ProgressBar() {
  const { isLoading, tcgDistributedPercent, totalTokensDistributed } =
    useTokenPointsOverview()

  return (
    <>
      <div className={classes.progressTitle}>
        <div>
          <TCGSVG /> token distribution
        </div>
        {totalTokensDistributed && (
          <span>
            <span className={classes.percent}>
              {formatNumber(totalTokensDistributed)}
            </span>{' '}
            <span className="text-neutral">- distributed</span>{' '}
          </span>
        )}
      </div>
      <div className={classes.progressBackColor}>
        <div className={classes.progress}>
          <div
            className={classnames(
              classes.progressValue,
              tcgDistributedPercent < 1 ? classes.empty : ''
            )}
            style={{ width: `${tcgDistributedPercent}%` }}
          />
        </div>
      </div>
    </>
  )
}
