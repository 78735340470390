import Select from 'shared/components/form/select'

const STATUSES = [
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Published',
    value: 'published',
  },
]
export default function SelectFAQStatus(props) {
  const options = STATUSES.map((status) => ({
    label: status.label,
    value: status.value,
  }))

  return (
    <>
      <Select {...props} options={options} />
    </>
  )
}
