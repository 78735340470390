import { useCallback, useState } from 'react'
import get from 'lodash/get'
import useQueryClient from 'core/react-query/useQueryClient'
import useDebounceCallback from 'helpers/hooks/useDebounceCallback'
import Field from 'shared/components/form/field'
import Select from 'shared/components/form/select'
import getCryptocurrencies from 'shared/queries/getCryptocurrencies'
import classes from './index.module.css'

export default function SelectCryptocurrency(props) {
  const queryClient = useQueryClient()

  const [store, setStore] = useState({
    loading: false,
    options: [],
  })

  const loadOptions = useCallback(
    async (search) => {
      if (search && search.length > 1) {
        try {
          const response = await queryClient.fetchQuery({
            query: getCryptocurrencies,
            variables: {
              perPage: -1,
              search,
            },
          })
          return get(response, 'json', []).map((option) => {
            if (option) {
              return {
                data: option,
                label: option.name,
                value: option.id,
              }
            }
          })
        } catch (e) {}
      }
      return []
    },
    [queryClient]
  )

  const handleInputChange = useDebounceCallback(
    async (search) => {
      setStore((prev) => ({
        ...prev,
        loading: true,
      }))
      try {
        const response = await loadOptions(search)
        setStore({
          loading: false,
          options: response,
        })
      } catch (e) {
        setStore((prev) => ({
          ...prev,
          loading: false,
        }))
      }
    },
    [loadOptions]
  )

  const formatOptionLabel = useCallback(
    ({ data, label, value }, { context }) => {
      if (context === 'value') {
        return label
      }
      return (
        <div className={classes.option}>
          <img className={classes.image} src={data.thumbnails.small} />
          <div className={classes.info}>
            <div>{data.symbol}</div>
            <small>{data.name}</small>
          </div>
        </div>
      )
    },
    []
  )

  const noOptionsMessage = useCallback(
    ({ inputValue }) =>
      inputValue
        ? 'No cryptocurrencies found'
        : 'Type to search cryptocurrencies',
    []
  )

  return (
    <Select
      isMulti={true}
      {...props}
      filterOption={null}
      formatOptionLabel={formatOptionLabel}
      isLoading={store.loading}
      noOptionsMessage={noOptionsMessage}
      onInputChange={handleInputChange}
      options={store.options}
      placeholder="Cryptocurrency..."
      isRawValue
    />
  )
}

SelectCryptocurrency.Field = ({ error, label, ...inputProps }) => (
  <Field
    error={error}
    help="Select the coins or tokens that are relevant to your video/podcast. The user will see the market data for each coin/token you have chosen. Thus, creating added value for the user."
    label={label}>
    <SelectCryptocurrency {...inputProps} />
  </Field>
)

SelectCryptocurrency.Field.defaultProps = {
  label: 'Select Related Cryptocurrencies',
}
