type RejectPublisherRequestMutationVariables = {
  id: number,
  messageId: number,
  reason: string,
}

export default function rejectPublisherRequest(
  variables: RejectPublisherRequestMutationVariables
) {
  const { id, messageId: message_id, reason } = variables
  return {
    method: 'put',
    path: `/admin/publisher-requests/${variables.userId}/reject`,
    params: {
      id,
      message_id,
      reason,
    },
  }
}
