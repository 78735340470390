import { useState } from 'react'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import calculateChannelsEarnings from 'admin/queries/calculateChannelsEarnings'

const VALIDATION = {
  month: {
    label: 'Month',
    rule: 'required',
  },
}

export default function CalculateMonthlyEarning({ initialValues }) {
  const [visible, setVisible] = useState(false)

  const mutation = useMutation(calculateChannelsEarnings, {
    onSuccess: () => {
      notification.success({ title: 'Monthly payouts calculated!' })
    },
  })

  const form = useMiniForm({
    resetOnSuccess: true,
    validation: VALIDATION,
    initialValues: initialValues,
    async onSubmit(values) {
      try {
        await mutation.mutateAsync(values)
        setVisible(false)
      } catch (error) {
        throw error
      }
    },
  })

  const handleCancel = () => {
    setVisible(false)
    form.reset()
  }

  const handleOk = () => {
    form.submit()
  }

  return (
    <>
      <Button onClick={() => setVisible(true)} secondary outlined>
        Calculate Earnings
      </Button>

      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        okText="Calculate"
        loading={mutation.isLoading}
        size="sm"
        title="Calculate Monthly Earnings"
        visible={visible}>
        <p>
          Are you sure you want to calculate <b>{initialValues.monthName}'s</b>{' '}
          earnings?
        </p>
      </Modal>
    </>
  )
}
