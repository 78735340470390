import { ReactComponent as LinkSVG } from 'svg/open-in-new.svg'
import Modal from 'shared/components/kit/modal'
import Button from 'shared/components/kit/button'
import classes from './TransparencyModal.module.css'

export default function TransparencyModal({ visible, onClose }) {
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={
        <Button onClick={onClose} transparent mood="neutral">
          Close
        </Button>
      }>
      <div className={classes.wrapper}>
        <h1>Contracts</h1>
        <h2>Transparency is one of the most essential things in this space.</h2>

        <div className={classes.item}>
          <span>
            TCG Token Contract (Today’s Crypto Governance Token){' '}
            <a
              target="_blank"
              href="https://polygonscan.com/token/0x98B435147EB5496f2180cf4d0836157E1744e9d0">
              <LinkSVG />
            </a>
          </span>
          <small>0x98B435147EB5496f2180cf4d0836157E1744e9d0</small>
        </div>
        <p>
          We have three MultiSign wallets to hold the platform's Development
          Fund Tokens, Equity Program Tokens, and our Airdrop Campaigns Tokens.
          Multiple team members own these wallets, and two thirds of the owners
          must sign the transaction to transfer funds from these wallets.
        </p>
        <div className={classes.item}>
          <span>
            Development Fund{' '}
            <a
              target="_blank"
              href="https://polygonscan.com/token/0xe3c49d3F633216E6A66Ab014De34948c266C3c82">
              <LinkSVG />
            </a>
          </span>
          <small>0xe3c49d3F633216E6A66Ab014De34948c266C3c82</small>
        </div>
        <div className={classes.item}>
          <span>
            Equity Program Tokens{' '}
            <a
              target="_blank"
              href="https://polygonscan.com/token/0xDe32f83ac95d64852c2491CeA34D604FA4889B69">
              <LinkSVG />
            </a>
          </span>
          <small>0xDe32f83ac95d64852c2491CeA34D604FA4889B69</small>
        </div>
        <div className={classes.item}>
          <span>
            Airdrop Campaign Tokens{' '}
            <a
              target="_blank"
              href="https://polygonscan.com/token/0x9c8CC7D9dD29CCB00E9A3895194e1e9DcaC96373">
              <LinkSVG />
            </a>
          </span>
          <small>0x9c8CC7D9dD29CCB00E9A3895194e1e9DcaC96373</small>
        </div>
        <div className={classes.item}>
          <span>
            Reward Treasury{' '}
            <a
              target="_blank"
              href="https://polygonscan.com/address/0x6B6896a69A348BbE2e93045Ca7A91901b78Da14f">
              <LinkSVG />
            </a>
          </span>
          <small>0x6B6896a69A348BbE2e93045Ca7A91901b78Da14f</small>
        </div>
        <p>
          Core Team holdings are completely locked in vesting contracts for
          first 12 months after the contract launch (May 1th, 2023), meaning
          they are not accessible to team members during that time. After that
          period, tokens are vested linearly over two years (May 1th, 2024 to
          May 1th, 2026).
        </p>
        <p>Vesting Contracts.</p>
        <ol className={classes.decimal}>
          <li>
            0xDb17033b0e7086341711698eDf1E515F6a4D4985{' '}
            <a
              target="_blank"
              href="https://polygonscan.com/address/0xDb17033b0e7086341711698eDf1E515F6a4D4985">
              <LinkSVG />
            </a>
          </li>
          <li>
            0x22a25713abBAFd7aAc5C3e4a7fd6AaA3Fc3484A4{' '}
            <a
              target="_blank"
              href="https://polygonscan.com/address/0x22a25713abBAFd7aAc5C3e4a7fd6AaA3Fc3484A4">
              <LinkSVG />
            </a>
          </li>
          <li>
            0x639Aa9E236421Bd726f4039D5F7DdD0149d101BA{' '}
            <a
              target="_blank"
              href="https://polygonscan.com/address/0x639Aa9E236421Bd726f4039D5F7DdD0149d101BA">
              <LinkSVG />
            </a>
          </li>
          <li>
            0x34f2F757Cf0259eb7c0eAcEa56a127DcBEe084bF{' '}
            <a
              target="_blank"
              href="https://polygonscan.com/address/0x34f2F757Cf0259eb7c0eAcEa56a127DcBEe084bF">
              <LinkSVG />
            </a>
          </li>
          <li>
            0x1b33aEA8e8548BB1242214788A5B064Ee24C5f4d{' '}
            <a
              target="_blank"
              href="https://polygonscan.com/address/0x1b33aEA8e8548BB1242214788A5B064Ee24C5f4d">
              <LinkSVG />
            </a>
          </li>
          <li>
            0x3Dfd9e9891Bd1DF0b1A59B6E15A003629e3eA947{' '}
            <a
              target="_blank"
              href="https://polygonscan.com/address/0x3Dfd9e9891Bd1DF0b1A59B6E15A003629e3eA947">
              <LinkSVG />
            </a>
          </li>
          <li>
            0x3D1B808a640De49b7C395Acf86fa7bA0be4969fC{' '}
            <a
              target="_blank"
              href="https://polygonscan.com/address/0x3D1B808a640De49b7C395Acf86fa7bA0be4969fC">
              <LinkSVG />
            </a>
          </li>
          <li>
            0xd0E9338AD4A51C5e2A48B74E845D158c122D6fBE{' '}
            <a
              target="_blank"
              href="https://polygonscan.com/address/0xd0E9338AD4A51C5e2A48B74E845D158c122D6fBE">
              <LinkSVG />
            </a>
          </li>
          <li>
            0x196B3c5983f9e6ef0cA0B0885EAb6d64EE3529b3{' '}
            <a
              target="_blank"
              href="https://polygonscan.com/address/0x196B3c5983f9e6ef0cA0B0885EAb6d64EE3529b3">
              <LinkSVG />
            </a>
          </li>
        </ol>
      </div>
    </Modal>
  )
}
