import { useState } from 'react'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import Accordion from 'shared/components/kit/accordion'
import Button from 'shared/components/kit/button'
import Icon from 'shared/components/kit/icon'
import formatHTML from 'helpers/utils/formatHTML'
import Spin from 'shared/components/kit/spin'
import Page from 'publisher/components/page'
import getContent from 'publisher/queries/getContent'
import classes from './index.module.css'

export default function Support() {
  const [activeTab, setActiveTab] = useState()
  const { data, error, isError, isLoading } = useQuery(getContent, {
    variables: {
      slug: 'pp:faq',
    },
  })

  const faqContent = get(data, 'json', undefined)
  const faqContentList = (faqContent && faqContent.content.questions) || []

  const handleTabChange = (val) => {
    setActiveTab(val === activeTab ? null : val)
  }

  if (isError) {
    return <Page error={error.json.message} />
  }

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <Icon>
          <Spin />
        </Icon>
        <div>Loading...</div>
      </div>
    )
  }

  return (
    <Page title="FAQ" width="md">
      <Accordion
        activeName={activeTab}
        onChange={handleTabChange}
        bordered={false}>
        {faqContentList.map((faqContent, index) => (
          <Accordion.Item name={index} title={faqContent.question}>
            <p
              dangerouslySetInnerHTML={{
                __html: formatHTML(faqContent.answer),
              }}
            />
          </Accordion.Item>
        ))}
      </Accordion>
      <Button className="mt-8">
        <a href={'mailto:' + 'helpdesk@todayscrypto.com'} target="_blank">
          contact us
        </a>
      </Button>
    </Page>
  )
}
