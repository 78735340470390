import { useRef, useState } from 'react'
import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import Field from 'shared/components/form/field'
import SelectChannel from 'shared/components/form/select-channel'
import SelectVideo from 'shared/components/form/select-video'
import ChooseOverlayInput from './ChooseOverlayInput'
import ChoosePositionInput from './ChoosePositionInput'
import useTimelineActions from '../hooks/useTimelineActions'

export default function AddOverlayButton({ duration }) {
  const submitButtonRef = useRef()
  const [step, setStep] = useState('')

  const { insertEmptyOverlay } = useTimelineActions()

  const save = (values) => {
    insertEmptyOverlay({
      ...values,
      start: duration - 20,
      end: duration,
    })
    setStep('')
    form.reset()
  }

  const form = useMiniForm({
    onSubmit(values, event) {
      event.preventDefault()
      const result = {
        position: values.position,
        type: values.type,
      }
      if (values.type === 'channel') {
        result.data_channel_slug = values.data_channel_slug
      }
      if (values.type === 'video') {
        result.data_video_url_hash = values.data_video_url_hash
      }
      save(result)
    },
  })

  const handleChooseType = (type) => {
    form.change('type', type)
    setStep('position')
  }

  const handleChoosePosition = (position) => {
    if (form.get('type') === 'subscribe') {
      save({
        position,
        type: form.get('type'),
      })
    } else {
      form.change('position', position)
      setStep('config')
    }
  }

  const handleConfig = () => {
    submitButtonRef.current.click()
  }

  const handleCancel = () => {
    setStep('')
  }

  return (
    <>
      <Button onClick={() => setStep('type')} transparent>
        Add Overlay
      </Button>

      {step === 'type' && (
        <Modal
          key="modal"
          footer={null}
          onCancel={handleCancel}
          size="md"
          title="What kind of overlay would you like to add?"
          visible>
          <ChooseOverlayInput
            onChange={handleChooseType}
            value={form.get('type')}
          />
        </Modal>
      )}

      {step === 'position' && (
        <Modal
          key="modal"
          footer={null}
          onCancel={handleCancel}
          size="md"
          title="Where do you like to put the overlay?"
          visible>
          <ChoosePositionInput
            onChange={handleChoosePosition}
            value={form.get('position')}
          />
        </Modal>
      )}

      {step === 'config' && (
        <Modal
          key="modal"
          onCancel={handleCancel}
          onOk={handleConfig}
          size="md"
          visible>
          <form onSubmit={form.submit}>
            {form.get('type') === 'channel' && (
              <>
                <Field label="Select the channel you want to show">
                  <SelectChannel
                    {...form.getInputPropsFor('data_channel_slug')}
                    menuPosition="fixed"
                    valueKey="slug"
                  />
                </Field>
              </>
            )}

            {form.get('type') === 'video' && (
              <>
                <Field label="Select the video you want to show">
                  <SelectVideo
                    {...form.getInputPropsFor('data_video_url_hash')}
                    menuPosition="fixed"
                    valueKey="url_hash"
                  />
                </Field>
              </>
            )}

            <button ref={submitButtonRef} type="submit" hidden />
          </form>
        </Modal>
      )}
    </>
  )
}
