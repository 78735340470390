import Input from 'shared/components/form/input'
import InputGroup from 'shared/components/form/input-group'

export default function UrlInput(props) {
  const { onChange, prefix, value, ...rest } = props

  const url = (value || '').replace(prefix, '')

  const handleChange = (text) => {
    onChange(text ? `${prefix}${text}` : '')
  }

  return (
    <InputGroup>
      <InputGroup.Text>{prefix}</InputGroup.Text>
      <Input {...rest} onChange={handleChange} value={url} />
    </InputGroup>
  )
}

UrlInput.defaultProps = {
  prefix: 'https://',
}
