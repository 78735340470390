type UploadAdSpaces = {
  key: string,
  url?: string,
  redirectTo?: string,
}

export default function uploadAdSpaces(variables: UploadAdSpaces) {
  const { key, url, redirectTo } = variables
  return {
    method: 'POST',
    path: '/admin/options/ad-spaces',
    params: {
      key: key,
      url: url,
      redirect_to: redirectTo,
    },
  }
}
