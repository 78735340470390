import { Link } from 'react-router-dom'
import useProfile from 'shared/hooks/useProfile'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'

export default function Email() {
  const { user: profile } = useProfile()

  return (
    <>
      <Field label="Email">
        <Input
          placeholder="Email"
          type="email"
          value={profile?.email}
          disabled
        />
      </Field>

      <p>
        Do you need to update your email address?&nbsp;
        <Link className="text-secondary" to="/publisher/tickets/create">
          Do you need to update your email address? Visit our FAQ to find out
          more
        </Link>
        , and we will help you out.
      </p>
    </>
  )
}
