// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PositionIcon_wrapper__12Hah {\n  border-radius: 0.25rem;\n  border-width: 2px;\n  display: inline-block;\n  position: relative;\n  color: var(--neutral);\n  transition-property: all;\n  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n  transition-duration: 150ms;\n  border-color: currentColor;\n  height: 1.75rem;\n  width: 2.625rem;\n}\n\n.PositionIcon_wrapper__12Hah > span {\n  border-radius: 0.125rem;\n  height: 0.5rem;\n  position: absolute;\n  width: 0.75rem;\n  transition-property: all;\n  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n  transition-duration: 150ms;\n  background-color: currentColor;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/components/studio/components/PositionIcon.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,sBAAmB;EAAnB,iBAAmB;EAAnB,qBAAmB;EAAnB,kBAAmB;EAAnB,qBAAmB;EAAnB,wBAAmB;EAAnB,wDAAmB;EAAnB,0BAAmB;EDAjB,0BAA0B;EAC1B,eAAe;EACf,eAAe;AACjB;;AAEA;ECLA,uBAAmB;EAAnB,cAAmB;EAAnB,kBAAmB;EAAnB,cAAmB;EAAnB,wBAAmB;EAAnB,wDAAmB;EAAnB,0BAAmB;EDOjB,8BAA8B;AAChC","sourcesContent":[".wrapper {\n  @apply border-2 inline-block relative rounded text-neutral transition-all;\n  border-color: currentColor;\n  height: 1.75rem;\n  width: 2.625rem;\n}\n\n.wrapper > span {\n  @apply absolute h-2 rounded-sm transition-all w-3;\n  background-color: currentColor;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PositionIcon_wrapper__12Hah"
};
export default ___CSS_LOADER_EXPORT___;
