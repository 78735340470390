type getReportedCommentsVariables = {
  page?: number,
}

export default function getReportedComments(
  variables: getReportedCommentsVariables = {}
) {
  const { page } = variables
  return {
    name: 'reportedComments',
    path: '/admin/reports/comment',
    params: {
      page: page > 1 ? page : undefined,
    },
  }
}
