import { Route, Switch } from 'react-router'
import Layout from 'admin/components/layouts/monetization'
import Overview from './overview'
import Qualified from './qualified'
import Payouts from './payouts'

export default function Monetization() {
  return (
    <Layout>
      <Switch>
        <Route path="/admin/monetization/overview" component={Overview} exact />
        <Route
          path="/admin/monetization/qualified"
          component={Qualified}
          exact
        />
        <Route path="/admin/monetization/payouts" component={Payouts} exact />
      </Switch>
    </Layout>
  )
}
