import React from 'react'
import classnames from 'classnames'
import styles from './index.module.css'

type Props = {
  error?: any,
  help?: any,
  label?: any,
  required?: boolean,
  icon?: any,
}

export default function Field(props: Props) {
  const { children, className, error, help, label, required, icon } = props

  const wrapperClassName = classnames(styles.container, className, {
    invalid: !!error,
  })

  return (
    <div className={wrapperClassName}>
      {label ? (
        <label className={styles.label}>
          {label}
          {required ? <span className={styles.required}>&nbsp;*</span> : null}
          {icon ? icon : null}
        </label>
      ) : null}
      {error ? <span className={styles.error}>{error}</span> : null}
      {children}
      {help ? <span className={styles.help}>{help}</span> : null}
    </div>
  )
}

Field.defaultProps = {
  required: false,
}
