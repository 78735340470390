import Tag from 'shared/components/kit/tag'
import { ReactComponent as PublishSVG } from 'icons/publish-outlined.svg'
import { ReactComponent as HideSVG } from 'icons/hide-source.svg'
import { ReactComponent as PendingSVG } from 'icons/pending-outlined.svg'

const STATUSES = {
  pending: {
    color: 'bg-light-2 text-neutral dark:bg-dark-3',
    icon: <PendingSVG />,
    label: 'Pending',
  },
  rejected: {
    color:
      'bg-yellow-200 text-yellow-600 dark:bg-yellow-600 dark:text-yellow-50',
    icon: <HideSVG />,
    label: 'Rejected',
  },
  confirmed: {
    color: 'bg-green-100 text-green-500 dark:bg-green-600 dark:text-green-100',
    icon: <PublishSVG />,
    label: 'Confirmed',
  },
}

export default function RequestStatus({ status }) {
  const data = STATUSES[status]
  if (data) {
    return (
      <Tag type={data.color}>
        {data.icon}
        <span>{data.label}</span>
      </Tag>
    )
  }

  return null
}
