// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VideoPreview_wrapper__2OqU8 {\n  background-color: var(--overlay-color-default);\n  border-radius: 0.25rem;\n  overflow: hidden;\n  position: relative;\n}\n\n.VideoPreview_wrapper__2OqU8 img {\n  border-bottom-right-radius: 0px;\n  border-bottom-left-radius: 0px;\n}\n\n.VideoPreview_info__wMpk2 {\n  padding-top: 0.75rem;\n  padding-bottom: 0.75rem;\n  padding-left: 1rem;\n  padding-right: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/components/common/VideoPreview.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,8CAAmB;EAAnB,sBAAmB;EAAnB,gBAAmB;EAAnB,kBAAmB;ADAnB;;AAEA;ECFA,+BAAmB;EAAnB,8BAAmB;ADInB;;AAEA;ECNA,oBAAmB;EAAnB,uBAAmB;EAAnB,kBAAmB;EAAnB,mBAAmB;ADQnB","sourcesContent":[".wrapper {\n  @apply bg-overlayColor-default overflow-hidden relative rounded;\n}\n\n.wrapper img {\n  @apply rounded-bl-none rounded-br-none;\n}\n\n.info {\n  @apply px-4 py-3;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "VideoPreview_wrapper__2OqU8",
	"info": "VideoPreview_info__wMpk2"
};
export default ___CSS_LOADER_EXPORT___;
