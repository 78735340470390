import { useCallback } from 'react'
import get from 'lodash/get'
import useQueryClient from 'core/react-query/useQueryClient'
import getUserName from 'helpers/utils/getUserName'
import ChannelAvatar from 'shared/components/channel-avatar'
import AsyncSelect from 'shared/components/form/select/AsyncSelect'
import getChannels from 'shared/queries/getChannels'
import getChannel from 'shared/queries/getChannel'
import classes from './index.module.css'

type Props = {
  valueKey?: 'id' | 'slug',
}

export default function SelectChannel(props: Props) {
  const { value, valueKey, ...rest } = props

  const queryClient = useQueryClient()

  const loadDefaultOptions = useCallback(async () => {
    try {
      const response = await queryClient.fetchQuery({
        query: getChannel,
        variables: {
          [valueKey]: value,
        },
      })
      return {
        channel: response.json,
        label: response.json.name,
        value: response.json[valueKey],
      }
    } catch (e) {
      return {}
    }
  }, [queryClient, value, valueKey])

  const loadOptions = useCallback(
    async (search) => {
      try {
        const response = await queryClient.fetchQuery({
          query: getChannels,
          variables: {
            includeOwner: true,
            search,
          },
        })
        return get(response, 'json.data', []).map((opt) => ({
          channel: opt,
          label: opt.name,
          value: opt[valueKey],
        }))
      } catch (e) {
        return []
      }
    },
    [queryClient, valueKey]
  )

  const formatOptionLabel = useCallback(({ label, channel }, { context }) => {
    if (context === 'value') {
      return label
    }
    return (
      <div className={classes.option}>
        <ChannelAvatar className={classes.avatar} channel={channel} />
        <div className={classes.info}>
          <div>
            {channel.name}{' '}
            {channel?.status !== 'published' ? (
              <small>{channel?.status}</small>
            ) : null}
          </div>
          <small className="whitespace-nowrap">
            Owner: {channel.owner ? getUserName(channel.owner) : 'Unknown'}
            <>
              {' '}
              {typeof channel?.owner?.email !== 'undefined'
                ? `(${channel?.owner?.email})`
                : null}
            </>
          </small>
        </div>
      </div>
    )
  }, [])

  const noOptionsMessage = useCallback(
    ({ inputValue }) =>
      inputValue ? 'No channels found' : 'Type to search channels',
    []
  )

  return (
    <AsyncSelect
      {...rest}
      formatOptionLabel={formatOptionLabel}
      loadDefaultOptions={loadDefaultOptions}
      loadOptions={loadOptions}
      noOptionsMessage={noOptionsMessage}
      placeholder="Channel..."
      value={value}
    />
  )
}

SelectChannel.defaultProps = {
  valueKey: 'id',
}
