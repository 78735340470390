type DeleteCampaign = {
  id: number,
}
export default function deleteCampaign(variables: DeleteCampaign = {}) {
  const { id } = variables
  return {
    method: 'DELETE',
    path: `/admin/crypto-campaigns/${id}`,
  }
}
