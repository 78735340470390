import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import classes from './index.module.css'

type DatePickerProps = {
  onChange: Function,
  value?: string,
}

export default function DatePicker(props: DatePickerProps) {
  const { onChange, value, ...rest } = props

  const date = value ? new Date(value) : undefined

  return (
    <div className={classes.wrapper}>
      <ReactDatePicker
        className={classes.datepicker}
        dateFormat="MMMM d, yyyy"
        dropdownMode="select"
        onChange={onChange}
        selected={date}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        {...rest}
      />
    </div>
  )
}
