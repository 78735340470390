import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import useFetcher from 'core/useFetcher'
import cookie from 'core/cookie'
import Result from 'shared/components/kit/result'
import Button from 'shared/components/kit/button'

export default function ConfirmAccountDeletion() {
  const history = useHistory()
  const fetcher = useFetcher()
  const { token } = useParams()
  const [result, setResult] = useState()

  useEffect(() => {
    fetcher(`/account/delete/${token}`, {
      method: 'delete',
    })
      .then((res) => {
        if (res.json.status === 'ok') {
          setResult('ok')
        } else {
          setResult('error')
        }
        handleLogout()
      })
      .catch((e) => {
        setResult('error')
      })
  }, [])

  const handleLogout = useCallback(() => {
    cookie().remove('jwt')
    window.location.href = '/'
  }, [])

  if (result && result === 'error') {
    return (
      <Result
        className="mt-48"
        title="Error"
        status="error"
        description="Cannot delete your channel!"
        footer={
          <Button
            onClick={() => {
              history.push('/publisher')
            }}>
            Home
          </Button>
        }
      />
    )
  }

  if (result && result === 'ok') {
    return (
      <Result
        className="mt-48"
        title="Your channel deleted!"
        status="success"
        description="Your channel has been changed successfully."
      />
    )
  }
  return <></>
}
