// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".publisher-request_signUp__UM1TV {\n}\n\n.publisher-request_signUp__UM1TV > h2 {\n  font-size: 1.5rem;\n  line-height: 2rem;\n}\n\n.publisher-request_login__2eXZ- {\n  border-top-width: 1px;\n  margin-top: 1.5rem;\n  padding-top: 1.5rem;\n  text-align: center;\n}\n\n.publisher-request_login__2eXZ- button {\n  width: 300px;\n}\n\n.publisher-request_notice__ePgxy {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 2.5rem;\n  margin-bottom: 2.5rem;\n}\n\n.publisher-request_notice__ePgxy p {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n}\n\n.publisher-request_notice__ePgxy svg {\n  height: 2.5rem;\n  margin-right: 1rem;\n  width: 2.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/publisher-request/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;AACA;;AAEA;ECDA,iBAAmB;EAAnB,iBAAmB;ADGnB;;AAEA;ECLA,qBAAmB;EAAnB,kBAAmB;EAAnB,mBAAmB;EAAnB,kBAAmB;ADOnB;;AAEA;EACE,YAAY;AACd;;AAEA;ECbA,aAAmB;EAAnB,8BAAmB;EAAnB,kBAAmB;EAAnB,qBAAmB;ADenB;;AAEA;ECjBA,mBAAmB;EAAnB,oBAAmB;ADmBnB;;AAEA;ECrBA,cAAmB;EAAnB,kBAAmB;EAAnB,aAAmB;ADuBnB","sourcesContent":[".signUp {\n}\n\n.signUp > h2 {\n  @apply text-2xl;\n}\n\n.login {\n  @apply border-t mt-6 pt-6 text-center;\n}\n\n.login button {\n  width: 300px;\n}\n\n.notice {\n  @apply flex justify-between my-10;\n}\n\n.notice p {\n  @apply text-sm;\n}\n\n.notice svg {\n  @apply h-10 w-10 mr-4;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signUp": "publisher-request_signUp__UM1TV",
	"login": "publisher-request_login__2eXZ-",
	"notice": "publisher-request_notice__ePgxy"
};
export default ___CSS_LOADER_EXPORT___;
