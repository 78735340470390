import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import Input from 'shared/components/form/input'
import classes from './PricesCalendar.module.css'

export default function PricesCalendar({ startDate, tiers }) {
  const [days, setDays] = useState([startDate])

  const generateDays = () => {
    let _arr = [startDate]
    for (let i = 1; i < 14; i++) {
      _arr.push(dayjs(startDate).add(i, 'day').format('YYYY-MM-DD'))
    }
    setDays(_arr)
  }

  useEffect(() => {
    generateDays()
  }, [startDate])

  return (
    <div className={classes.wrapper}>
      <div className={classes.calendarHeaderWrapper}>
        <h3 className={classes.subHeader}>Price for the selected slots</h3>
      </div>
      <div className={classes.wrapperBox}>
        <div className={classes.calendar}>
          {tiers.map((tier, tierIndex) => {
            return (
              <div key={tierIndex} className={classes.calendarRow}>
                {days.map((day, index) => {
                  return (
                    <>
                      {index === 0 && (
                        <div key={index} className={classes.tier}>
                          {tier.name}
                        </div>
                      )}
                      <Input disabled className={classes.input} />
                    </>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className={classes.daysRow}>
          {days.map((day, index) => {
            return <span key={index}>{day}</span>
          })}
        </div>
      </div>
    </div>
  )
}
