import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import useDebounceCallback from 'helpers/hooks/useDebounceCallback'
import uniqueId from 'shared/helpers/uniqueId'
import getVideo from 'shared/queries/getVideo'
import saveVideoStudio from 'publisher/mutations/saveVideoStudio'
import saveVideoStudioDraft from 'publisher/mutations/saveVideoStudioDraft'
import VideoPlayer from './components/VideoPlayer'
import Timeline from './components/Timeline'
import Toolbar from './components/Toolbar'
import getOverlayErrors from './utils/getOverlayErrors'
import { VideoStudioContextProvider } from './context'

type Props = {
  id: number,
  url: string,
}

const initialState = {
  chapters: [],
  overlays: [],
  updateNumber: 0,
  version: 1,
}

export default function VideoStudio(props: Props) {
  const { chapters, duration, id, video, url } = props

  const queryClient = useQueryClient()

  const meta = useMemo(() => keyBy(video.meta, 'key'), [video.meta])

  const [zoom, setZoom] = useState(1)

  const [state, setState] = useState(initialState)

  const actions = useMemo(
    () => ({
      deleteChapter(id) {
        setState((prevData) => {
          return {
            ...prevData,
            updateNumber: prevData.updateNumber + 1,
            chapters: prevData.chapters.filter((chapter) => chapter.id !== id),
          }
        })
      },

      insertChapter(chapter) {
        setState((prevData) => {
          return {
            ...prevData,
            updateNumber: prevData.updateNumber + 1,
            chapters: sortBy(
              [...prevData.chapters, { ...chapter, id: uniqueId() }],
              'from'
            ),
          }
        })
      },

      updateChapter(id, updater) {
        setState((prevData) => {
          return {
            ...prevData,
            updateNumber: prevData.updateNumber + 1,
            chapters: prevData.chapters.map((chapter) => {
              if (chapter.id === id) {
                return updater(chapter)
              }
              return chapter
            }),
          }
        })
      },

      deleteOverlay(id) {
        setState((prevData) => {
          return {
            ...prevData,
            updateNumber: prevData.updateNumber + 1,
            overlays: prevData.overlays.filter((overlay) => {
              return overlay.id !== id
            }),
          }
        })
      },

      insertEmptyOverlay(payload) {
        setState((prevData) => {
          return {
            ...prevData,
            updateNumber: prevData.updateNumber + 1,
            overlays: [
              ...prevData.overlays,
              {
                ...payload,
                id: uniqueId(),
              },
            ],
          }
        })
      },

      updateOverlay(id, updater) {
        setState((prevData) => {
          return {
            ...prevData,
            updateNumber: prevData.updateNumber + 1,
            overlays: prevData.overlays.map((overlay) => {
              if (overlay.id === id) {
                return updater(overlay)
              }
              return overlay
            }),
          }
        })
      },

      zoomIn() {
        setZoom((prev) => Math.min(prev + 1, 5))
      },

      zoomOut() {
        setZoom((prev) => Math.max(prev - 1, 1))
      },
    }),
    []
  )

  const context = useMemo(() => {
    const overlayErrors = getOverlayErrors(state.overlays)
    return {
      ...actions,
      ...state,
      overlayErrors,
      hasError: Object.values(overlayErrors).some((err) => err),
      duration,
      videoId: id,
      zoom,
    }
  }, [actions, state, duration, id, zoom])

  const updateVideoMetaCache = (response) => {
    queryClient.setQueryData({
      query: getVideo,
      variables: {
        id: id,
      },
      updater: (prev) => ({
        ...prev,
        json: {
          ...prev.json,
          meta: prev.json.meta.map((meta) => {
            if (meta.key === response.json.key) {
              return {
                ...meta,
                ...response.json,
              }
            }
            return meta
          }),
        },
      }),
    })
  }

  const saveDraft = useMutation(saveVideoStudioDraft, {
    onSuccess: updateVideoMetaCache,
  })

  const saveDraftDebounced = useDebounceCallback(
    saveDraft.mutate,
    [saveDraft.mutate],
    1000
  )

  const publish = useMutation(saveVideoStudio, {
    onSuccess: updateVideoMetaCache,
  })

  const handlePublish = () => {
    if (context.hasError) {
      return
    }
    publish.mutate({
      data: state,
      videoId: id,
    })
  }

  useEffect(() => {
    if (context.hasError) {
      return
    }
    saveDraftDebounced({
      data: state,
      videoId: id,
    })
  }, [id, saveDraftDebounced, state, context.hasError])

  useEffect(() => {
    const studio = meta?.studio_draft?.value || initialState
    if (studio.updateNumber > state.updateNumber) {
      setState({
        chapters: studio.chapters || [],
        overlays: studio.overlays || [],
        updateNumber: studio.updateNumber || 0,
        version: studio.version || 1,
      })
    }
  }, [meta, state])

  const videoPlayerRef = useRef()
  const timelineRef = useRef()

  const handleTimeUpdate = useCallback((api) => {
    timelineRef.current.setCurrentTime(api.currentTime())
  }, [])

  const handleSeek = useCallback(
    (percent) => {
      videoPlayerRef.current.setCurrentTime((percent * duration) / 100)
    },
    [duration]
  )

  return (
    <VideoStudioContextProvider value={context}>
      <VideoPlayer
        id={id}
        onTimeUpdate={handleTimeUpdate}
        overlays={state.overlays}
        ref={videoPlayerRef}
        url={url}
      />

      <Toolbar
        isPublishing={publish.isLoading}
        isSavingDraft={saveDraft.isLoading}
        meta={meta}
        onPublish={handlePublish}
      />

      <Timeline
        chapters={chapters}
        duration={duration}
        id={id}
        onSeek={handleSeek}
        ref={timelineRef}
      />
    </VideoStudioContextProvider>
  )
}
