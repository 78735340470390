import classnames from 'classnames'
import classes from './index.module.css'

export default function FormError({ children, className }) {
  if (!children) {
    return null
  }

  return (
    <div className={classnames(classes.wrapper, className)}>{children}</div>
  )
}
