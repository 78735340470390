import Page from 'admin/components/page'
import AdItem from './AdItem'

export default function MobilePage({ actions, adsSpaces }) {
  return (
    <Page title="Ads Manager" description="Mobile" actions={actions}>
      <AdItem
        adsKey="home-page-header-mobile"
        src={adsSpaces['home-page-header-mobile']?.url}
        redirectTo={adsSpaces['home-page-header-mobile']?.redirect_to}
        title="Home Page Header - 1100 × 200 px"
        width={650}
        height={100}
        status={
          adsSpaces['home-page-header-mobile'] === undefined ? 'Draft' : 'Live'
        }
      />
      <AdItem
        adsKey="video-page-main-mobile"
        src={adsSpaces['video-page-main-mobile']?.url}
        redirectTo={adsSpaces['video-page-main-mobile']?.redirect_to}
        title="Video Page Main - 1030 x 560 px"
        width={515}
        height={280}
        status={
          adsSpaces['video-page-main-mobile'] === undefined ? 'Draft' : 'Live'
        }
      />
      <AdItem
        adsKey="markets-page-header-mobile"
        src={adsSpaces['markets-page-header-mobile']?.url}
        redirectTo={adsSpaces['markets-page-header-mobile']?.redirect_to}
        title="Markets Page Header - 1100 × 200 px"
        width={650}
        height={100}
        status={
          adsSpaces['markets-page-header-mobile'] === undefined
            ? 'Draft'
            : 'Live'
        }
      />
      <AdItem
        adsKey="all-other-pages-header-mobile"
        src={adsSpaces['all-other-pages-header-mobile']?.url}
        redirectTo={adsSpaces['all-other-pages-header-mobile']?.redirect_to}
        title="All Other Pages Header - 1100 × 200 px"
        width={650}
        height={100}
        status={
          adsSpaces['all-other-pages-header-mobile'] === undefined
            ? 'Draft'
            : 'Live'
        }
      />
    </Page>
  )
}
