import { useRef, useState } from 'react'
import { components } from 'react-select'
import { useHistory } from 'react-router'
import axios from 'axios'
import useQueryClient from 'core/useQueryClient'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import Alert from 'shared/components/kit/alert'
import Spin from 'shared/components/kit/spin'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import RichText from 'shared/components/form/rich-text'
import CrappedImageInput from 'shared/components/form/image/crappedImageInput'
import SelectCategory from 'shared/components/form/select-category'
import SelectLanguage from 'shared/components/form/select-language'
import Checkbox from 'shared/components/form/checkbox'
import SelectCryptocurrency from 'shared/components/form/select-cryptocurrency'
import Result from 'shared/components/kit/result'
import SelectPlaylist from 'shared/components/form/select-playlist'
import Select from 'shared/components/form/select'
import { ReactComponent as ErrorSVG } from 'icons/error-circle-filled.svg'
import { ReactComponent as CheckSVG } from 'icons/check-circle-filled.svg'
import getMyVideos from 'publisher/queries/getMyVideos'
import UploadVideoFile from './components/UploadVideoFile'
import CreatableMultiSelect from 'publisher/components/form/creatable-multi-select'
import VideoPreview from 'publisher/components/common/VideoPreview'
import classes from './index.module.css'

const VIDEO_UPLOAD_CONTENT = {
  uploaded: 'Fill in all the details below and publish your video/podcast.',
  uploading:
    'Please do not wait for us! Instead, fill in all the details below, and we will take care of your upload and let you know once it’s complete.',
}

const VIDEO_UPLOAD_ICON = {
  error: <ErrorSVG />,
  uploaded: <CheckSVG />,
  uploading: <Spin />,
}

const VIDEO_UPLOAD_TITLE = {
  error: () => 'Something happened while uploading your video/podcast file!',
  uploaded: () => 'Upload Complete.',
  uploading: (video) =>
    `Your video/podcast is being uploaded... (${video.progress}%)`,
}

export default function UploadVideoModal(props) {
  const VALIDATION = {
    title: {
      label: 'Media Title',
      rule: (value) => {
        if (value === undefined || value.trim().length === 0) {
          return '*Enter a Title'
        }
      },
    },
    thumbnail: {
      label: 'thumbnail',
      rule: (value) => {
        if (submitTypeRef.current === 'published' && value === undefined) {
          return '*Select Thumbnail'
        }
      },
    },
    category: {
      label: 'category',
      rule: (value) => {
        if (submitTypeRef.current === 'published' && value === undefined) {
          return '*Select Main Category'
        }
      },
    },
    language: {
      label: 'language',
      rule: (value) => {
        if (submitTypeRef.current === 'published' && value === undefined) {
          return '*Select Language'
        }
      },
    },
    tags: {
      label: 'Tags',
      rule: (value) => {
        if (value?.length > 5) {
          return 'You can not select more than 5 tags. '
        }
      },
    },
  }

  const mediaTypeOptions = [
    {
      label: 'Video',
      value: 'video',
    },
    {
      label: 'Podcast',
      value: 'podcast',
    },
  ]

  const history = useHistory()
  const { onCancel, onSuccess, visible } = props

  const [step, setStep] = useState(1)
  const [showPinnedComment, setShowPinnedComment] = useState(false)
  const [video, setVideo] = useState({
    status: 'none',
  })
  const submitButtonRef = useRef()
  const submitTypeRef = useRef()
  const queryClient = useQueryClient()

  const mutation = useMutation(createVideo, {
    onSuccess: async (response) => {
      setStep(1)
      setVideo({ status: 'none' })
      notification.success({ title: 'Video uploaded and saved successfully!' })
      form.reset()
      queryClient.invalidateQueries({
        query: getMyVideos,
      })
      onSuccess(response.json)
      setTimeout(() => {
        if (submitTypeRef.current === 'studio') {
          history.push(`/publisher/media/${response.json.id}/studio`)
        }
      }, 100)
    },
    onError: (error) => {
      Object.values(error.json.errors).forEach((er) =>
        notification.error({ title: er })
      )
    },
  })

  const form = useMiniForm({
    validation: VALIDATION,
    async onSubmit(values) {
      try {
        const response = await mutation.mutateAsync({
          ...values,
          comment_text: showPinnedComment && values.comment_text,
          crypto_currencies: (values.crypto_currencies || []).map(
            (currency) => currency.value
          ),
          file_url: video.url,
          status:
            submitTypeRef.current === 'studio'
              ? 'draft'
              : submitTypeRef.current,
        })
        onSuccess(response.json)
      } catch (error) {
        throw error
      }
    },
  })

  const handleChooseFile = async (file) => {
    let url

    try {
      url = await getPreSignedUrl(queryClient, file.name)
    } catch (error) {
      setVideo({
        error: true,
      })
      return
    }

    setVideo({
      file,
      url: url.split('?')[0],
      progress: 0,
      status: 'uploading',
    })

    setStep(2)

    try {
      await axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (e) => {
          setVideo((prev) => ({
            ...prev,
            progress: Math.floor((e.loaded * 100) / e.total),
          }))
        },
      })

      setVideo((prev) => ({
        ...prev,
        progress: 100,
        status: 'uploaded',
      }))
    } catch (error) {
      setVideo((prev) => ({
        ...prev,
        error,
        status: 'error',
      }))
    }
  }

  const handleCancel = () => {
    onCancel()
    form.reset()
    setVideo((prev) => ({ ...prev, error: false }))
  }

  const handleOk = () => {
    submitButtonRef.current.click()
  }

  const handlePublishVideo = (status) => {
    form.change('status', status)
    submitTypeRef.current = status
    form.submit()
  }

  const handleTogglePinnedComment = () => {
    setShowPinnedComment(!showPinnedComment)
  }

  const isValidNewOption = (inputValue, selectValue) => {
    return (
      inputValue.length <= 25 &&
      inputValue.length > 0 &&
      selectValue.length < 5 &&
      /^[A-Za-z\s]*$/.test(inputValue)
    )
  }

  const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 5 ? (
          props.children
        ) : (
          <div style={{ margin: 10 }}>
            The maximum number of tags can not be more than 5.
          </div>
        )}
      </components.Menu>
    )
  }

  const footer =
    step === 1 ? null : (
      <>
        <Button
          secondary
          disabled={video.status !== 'uploaded' || form.submitting}
          loading={form.submitting && submitTypeRef.current === 'studio'}
          onClick={() => {
            handlePublishVideo('studio')
          }}>
          Studio
        </Button>
        <Button
          secondary
          disabled={video.status !== 'uploaded' || form.submitting}
          loading={form.submitting && submitTypeRef.current === 'draft'}
          onClick={() => {
            handlePublishVideo('draft')
          }}>
          Save Draft
        </Button>
        <Button
          disabled={video.status !== 'uploaded' || form.submitting}
          loading={form.submitting && submitTypeRef.current === 'published'}
          onClick={() => {
            handlePublishVideo('published')
          }}>
          Publish
        </Button>
      </>
    )

  const handleInputChange = (text) => {
    let inputValue = text.replace(/[^a-zA-Z\s]/, '').replace(/\s\s+/g, ' ')
    if (inputValue.length > 25) {
      inputValue = inputValue.substr(0, 25)
    }
    return inputValue
  }

  return (
    <Modal
      cancelText="Save Draft"
      closeOnBackdrop={false}
      footer={footer}
      okText="Publish Video"
      onCancel={handleCancel}
      onOk={handleOk}
      title="Upload Media"
      visible={visible}>
      {step === 1 && video.error !== true && (
        <UploadVideoFile onChange={handleChooseFile} video={video} />
      )}
      {step === 1 && video.error && (
        <div className={classes.error}>
          <Result
            status="error"
            description="Please check your internet connection. If it didn't help, try again a bit later."
            title="Can't Connect to the Server"
          />
        </div>
      )}
      {step === 2 && (
        <form onSubmit={form.submit}>
          <Alert
            className="mb-8"
            type="info"
            icon={VIDEO_UPLOAD_ICON[video.status]}
            title={VIDEO_UPLOAD_TITLE[video.status](video)}
            content={VIDEO_UPLOAD_CONTENT[video.status]}
          />
          <div className={classes.top}>
            <div className={classes.fields}>
              <div className={classes.title}>
                <Field {...form.getErrorPropsFor('title')}>
                  <Input
                    {...form.getInputPropsFor('title')}
                    placeholder="Media Title"
                  />
                </Field>
                <Field {...form.getErrorPropsFor('description')}>
                  <RichText
                    {...form.getInputPropsFor('description')}
                    placeholder="Media Description"
                  />
                </Field>
              </div>

              <Field {...form.getErrorPropsFor('thumbnail')}>
                <div className={classes.thumbnail}>
                  <CrappedImageInput
                    placeholder="Select Thumbnail..."
                    {...form.getInputPropsFor('thumbnail')}
                    height={720}
                    width={1280}
                  />
                </div>
              </Field>
            </div>

            <div className={classes.preview}>
              <VideoPreview
                title={form.get('title')}
                thumbnail={form.get('thumbnail')}
              />
            </div>
          </div>

          <Field label="Media Type">
            <Select
              placeholder="Select Media Type ..."
              options={mediaTypeOptions}
              {...form.getInputPropsFor('media_type')}
            />
          </Field>

          <Field
            label="Select Main Category"
            {...form.getErrorPropsFor('category')}>
            <SelectCategory
              {...form.getInputPropsFor('category')}
              maxMenuHeight={200}
            />
          </Field>

          <Field
            help="Enter the language you speak in the video/podcast. This makes it easier for users with the same language to find your upload."
            label="Select Language"
            {...form.getErrorPropsFor('language')}>
            <SelectLanguage {...form.getInputPropsFor('language')} />
          </Field>

          <Field
            help="Select the coins or tokens that are relevant to your video/podcast. The user will see the market data for each coin/token you have chosen. Thus, creating added value for the user."
            label="Select Related Cryptocurrencies"
            {...form.getErrorPropsFor('crypto_currencies')}>
            <SelectCryptocurrency
              {...form.getInputPropsFor('crypto_currencies')}
              isMulti
            />
          </Field>

          <Field
            help="By selecting tags or creating your own tags, you will reach more users who are interested in more specific topics. Users choose which content they want to see based on the tags they choose."
            label="Media Tags"
            {...form.getErrorPropsFor('tags')}>
            <CreatableMultiSelect
              isMulti={true}
              isValidNewOption={isValidNewOption}
              Menu={{ Menu }}
              isOptionDisabled={() => form.values.tags?.length > 4}
              {...form.getInputPropsFor('tags')}
              onInputChange={handleInputChange}
            />
          </Field>

          <Field
            help="If you want to add your video to any of your existing playlists, do so here."
            label="Playlists"
            {...form.getErrorPropsFor('playlists')}>
            <SelectPlaylist
              {...form.getInputPropsFor('playlists')}
              isMulti={true}
              menuPlacement="top"
            />
          </Field>

          <Field>
            <Checkbox
              className="-mb-6"
              description="I want to add a pinned comment"
              checked={showPinnedComment}
              onChange={handleTogglePinnedComment}
            />
          </Field>
          <Field
            className={!showPinnedComment && 'hidden'}
            {...form.getErrorPropsFor('comment_text')}
            help="Here you can add a pinned comment to your content that will be displayed on top in the comment section for everyone to see.">
            <RichText
              {...form.getInputPropsFor('comment_text')}
              placeholder="Add your Comment here..."
              multiline
            />
          </Field>
        </form>
      )}
    </Modal>
  )
}

function createVideo(variables) {
  const tags = variables.tags?.map((tag) => tag.label)
  return {
    method: 'post',
    path: '/publisher/videos',
    params: { ...variables, tags },
  }
}

async function getPreSignedUrl(client, filename, retry = 0) {
  try {
    const response = await client.fetcher(
      `/publisher/upload/pre-signed-url-for-upload-video?file_name=${filename}`
    )
    return response.json.url
  } catch (error) {
    if (retry < 3) {
      return await getPreSignedUrl(client, filename, retry + 1)
    } else {
      throw error
    }
  }
}
