// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlanForm_row__1mG9t {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  margin-bottom: -1rem;\n  width: 66.666667%;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/plans/components/PlanForm.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,uBAAmB;EAAnB,8BAAmB;EAAnB,oBAAmB;EAAnB,iBAAmB;ADAnB","sourcesContent":[".row {\n  @apply flex justify-between  items-start -mb-4 w-2/3;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "PlanForm_row__1mG9t"
};
export default ___CSS_LOADER_EXPORT___;
