type HideVideoMutationVariables = {
  id: number,
}

export default function hideVideo(variables: HideVideoMutationVariables) {
  return {
    method: 'put',
    url: `/admin/videos/${variables.id}/hide`,
    variables,
  }
}
