import classnames from 'classnames'
import Alert from 'shared/components/kit/alert'
import classes from './index.module.css'

type Props = {
  actions?: any,
  backTo?: any,
  children?: any,
  description?: any,
  error?: any,
  loading?: any,
  title?: any,
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full',
}

export default function Page(props: Props) {
  const {
    actions,
    backTo,
    children,
    description,
    error,
    loading,
    title,
    width,
  } = props

  return (
    <div className={classnames(classes.wrapper, classes[width])}>
      {title || actions ? (
        <header className={classes.header}>
          <div className={classes.text}>
            {backTo ? (
              <>
                {' '}
                <h5>{backTo}</h5>
              </>
            ) : null}
            <h1>{title}</h1>
            {description ? <p>{description}</p> : null}
          </div>
          <div className={classes.actions}>{actions}</div>
        </header>
      ) : null}
      {loading ? <div className={classes.loading}>LOADING...</div> : null}
      {error ? (
        <div className={classes.error}>
          <Alert title={error} type="error" />
        </div>
      ) : null}
      {!loading && !error ? children : null}
    </div>
  )
}

Page.defaultProps = {
  width: 'full',
}
