import classnames from 'classnames'
import getChannelAvatar from 'helpers/utils/getChannelAvatar'
import classes from './index.module.css'

export default function ChannelAvatar({ className, channel, size }) {
  const wrapperClassName = classnames(classes.wrapper, className, {
    [`h-${size} w-${size}`]: !!size,
  })

  return (
    <figure className={wrapperClassName}>
      <img
        className="rounded-full"
        src={getChannelAvatar(channel?.avatar)}
        alt={channel?.name}
      />
    </figure>
  )
}
