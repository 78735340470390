import { useState } from 'react'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import Popover from 'shared/components/kit/popover'
import { ReactComponent as CaptionSVG } from 'icons/closed-caption-outlined.svg'
import { ReactComponent as UploadSVG } from 'svg/upload.svg'
import DeleteSubtitleButton from './DeleteSubtitleButton'
import UploadSubtitle from './UploadSubtitleModal'
import useVideoSubtitles from '../hooks/useVideoSubtitles'
import classes from './SubtitlesButton.module.css'

export default function SubtitlesButton() {
  const [isListVisible, setIsListVisible] = useState(false)
  const [isUploadVisible, setIsUploadVisible] = useState(false)

  const { isLoading, subtitles } = useVideoSubtitles()

  const handleCloseList = () => {
    if (Modal.modals().getVisibleModalsCount() === 0) {
      setIsListVisible(false)
    }
  }

  const handleCloseUploadModal = () => {
    setIsUploadVisible(false)
  }

  const uploadModal = (
    <UploadSubtitle
      onClose={handleCloseUploadModal}
      subtitles={subtitles}
      visible={isUploadVisible}
    />
  )

  if (subtitles.length > 0) {
    return (
      <>
        <Popover
          content={
            <>
              <header className={classes.header}>
                <h4 className="mr-4">Subtitles</h4>
                <Button
                  mood="secondary"
                  onClick={() => setIsUploadVisible(true)}
                  size="sm"
                  noHorizontalPadding
                  transparent>
                  <UploadSVG /> Upload Subtitle
                </Button>
              </header>
              <ul className={classes.subtitles}>
                {subtitles.map((subtitle) => (
                  <li key={subtitle.id} className={classes.subtitle}>
                    <span>{subtitle.language.display_name}</span>
                    <DeleteSubtitleButton id={subtitle.id} />
                  </li>
                ))}
              </ul>
            </>
          }
          onHide={handleCloseList}
          placement="top"
          visible={isListVisible}>
          <Button
            mood="secondary"
            onClick={() => setIsListVisible(true)}
            noHorizontalPadding
            transparent>
            <CaptionSVG /> Subtitles
          </Button>
        </Popover>
        {uploadModal}
      </>
    )
  }

  return (
    <>
      <Button
        disabled={isLoading}
        mood="secondary"
        onClick={() => setIsUploadVisible(true)}
        noHorizontalPadding
        transparent>
        <UploadSVG /> Upload Subtitle
      </Button>
      {uploadModal}
    </>
  )
}
