import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import SelectLocation from 'admin/components/form/select-location'
import classes from './Filters.module.css'
import Select from 'shared/components/form/select'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  const options = [
    {
      label: 'Default',
      value: 'default',
    },
    {
      label: 'Star',
      value: 'star',
    },
    {
      label: 'Thumb',
      value: 'thumb',
    },
  ]

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <Select
        className={classes.select}
        placeholder="Select Type..."
        options={options}
        isClearable={true}
        {...form.getInputPropsFor('type')}
      />
      <SelectLocation
        className={classes.select}
        {...form.getInputPropsFor('location')}
        placeholder="Select Location..."
        isClearable={true}
      />
      <Button outlined>Search</Button>
    </form>
  )
}
