import { Link } from 'react-router-dom'
import useProfile from 'shared/hooks/useProfile'
import Icon from 'shared/components/kit/icon'
import UploadVideoLink from 'publisher/components/upload-video-link'
import { ReactComponent as UploadSVG } from 'svg/upload.svg'
import classes from './index.module.css'

export default function TopNavigation() {
  const { isLoggedIn, role } = useProfile()

  return (
    <div className={classes.wrapper}>
      <header className={classes.header}>
        <Link className={classes.logo} to="/publisher">
          <img src="/tc-logo.png" alt="TodaysCrypto" />
        </Link>

        <nav className={classes.secondaryMenu}>
          <ul>
            {isLoggedIn && role === 'publisher' && (
              <li>
                <UploadVideoLink>
                  <Icon className="text-primaryColor-default">
                    <UploadSVG />
                  </Icon>
                  <span className={classes.text}>Upload Video</span>
                </UploadVideoLink>
              </li>
            )}

            {!isLoggedIn && (
              <li>
                <Link to="/?form=register">Become a Publisher</Link>
              </li>
            )}

            {!isLoggedIn && (
              <li>
                <Link to="/">Log In</Link>
              </li>
            )}
          </ul>
        </nav>
      </header>
    </div>
  )
}
