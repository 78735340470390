import Box from 'shared/components/kit/box'
import classes from './Summary.module.css'

export default function Summary({
  overview,
  isError,
  isLoading,
  monetizeReferralPointsIsActive,
}) {
  return (
    <Box className={classes.wrapper}>
      <ul>
        <SummaryItem
          error={isError}
          loading={isLoading}
          title="referral signups"
          value={overview?.total_referrals}
        />
        <SummaryItem
          error={isError}
          loading={isLoading}
          title="points earned"
          value={overview?.total_referral_points}
        />
        {monetizeReferralPointsIsActive && (
          <>
            <SummaryItem
              error={isError}
              loading={isLoading}
              title="referral signups this month"
              value={overview?.this_month_referrals}
              className="text-secondary"
            />
            <SummaryItem
              error={isError}
              loading={isLoading}
              title="points earned this month"
              value={overview?.this_month_referral_points}
              className="text-secondary"
            />
          </>
        )}
      </ul>
    </Box>
  )
}

function SummaryItem({ className, loading, title, value }) {
  return (
    <li>
      <span className={className}>{loading ? '...' : value}</span>
      <small>Total {title}</small>
    </li>
  )
}
