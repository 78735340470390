// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".playlist_thumbnail__2Yg5J {\n  width: 96px;\n}\n\n.playlist_thumbnail__2Yg5J img {\n  height: 45px;\n  width: 80px;\n}\n\n.playlist_title__XeIy2 {\n  /*max-width: 180px;*/\n}\n\n.playlist_title__XeIy2 .playlist_untitled__1tpVm {\n  opacity: 0.5;\n}\n\n.playlist_categories__2yBN9 {\n  width: 8rem;\n}\n\n.playlist_published__3XXPL {\n  width: 8rem;\n}\n\n.playlist_status__1YpXO {\n  width: 9rem;\n}\n\n.playlist_category__4whMQ {\n  width: 11rem;\n}\n\n.playlist_actions__kmTMT {\n  width: 8rem;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/playlist/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,oBAAoB;AACtB;;AAEA;ECXA,YAAmB;ADanB;;AAEA;ECfA,WAAmB;ADiBnB;;AAEA;ECnBA,WAAmB;ADqBnB;;AAEA;ECvBA,WAAmB;ADyBnB;;AAEA;EC3BA,YAAmB;AD6BnB;;AAEA;EC/BA,WAAmB;ADiCnB","sourcesContent":[".thumbnail {\n  width: 96px;\n}\n\n.thumbnail img {\n  height: 45px;\n  width: 80px;\n}\n\n.title {\n  /*max-width: 180px;*/\n}\n\n.title .untitled {\n  @apply opacity-50;\n}\n\n.categories {\n  @apply w-32;\n}\n\n.published {\n  @apply w-32;\n}\n\n.status {\n  @apply w-36;\n}\n\n.category {\n  @apply w-44;\n}\n\n.actions {\n  @apply w-32;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnail": "playlist_thumbnail__2Yg5J",
	"title": "playlist_title__XeIy2",
	"untitled": "playlist_untitled__1tpVm",
	"categories": "playlist_categories__2yBN9",
	"published": "playlist_published__3XXPL",
	"status": "playlist_status__1YpXO",
	"category": "playlist_category__4whMQ",
	"actions": "playlist_actions__kmTMT"
};
export default ___CSS_LOADER_EXPORT___;
