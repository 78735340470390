import React from 'react'
import { Redirect } from 'react-router'
import cookie from 'core/cookie'
import useQueryClient from 'core/useQueryClient'
import useWatchNotifications from 'shared/hooks/useWatchNotifications'
import useProfile from 'shared/hooks/useProfile'
import getNotifications from 'publisher/queries/getNotifications'
import getUnreadNotifications from 'publisher/queries/getUnreadNotifications'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import notification from 'shared/components/kit/notification'

export default function Auth({ children }) {
  const jwt = cookie().get('jwt')

  return jwt ? <Authenticated>{children}</Authenticated> : <Redirect to="/" />
}

Auth.Member = Member
Auth.Publisher = Publisher
Auth.PendingPublisher = PendingPublisher

function Member({ children }) {
  const { isPublisher, publisherRequest } = useProfile()
  if (!isPublisher && !publisherRequest?.hasRequested) {
    return children
  }
  return null
}

function PendingPublisher({ children }) {
  const { publisherRequest } = useProfile()
  return publisherRequest?.hasRequested ? children : null
}

function Publisher({ children }) {
  const { isPublisher } = useProfile()
  return isPublisher ? children : null
}

function Authenticated({ children }) {
  const { isLoading } = useProfile()

  const queryClient = useQueryClient()

  useWatchNotifications(
    'publisher',
    (data) => {
      notification({
        title: 'You have a new notification',
      })

      queryClient.setQueryData(getUnreadNotifications().name, (prev) => ({
        json: {
          ...prev.json,
          count: prev.json.count + 1,
        },
      }))

      queryClient.invalidateQueries(getNotifications().name)
    },
    [isLoading, queryClient]
  )

  if (isLoading) {
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        <Icon>
          <Spin />
        </Icon>
        <div>Loading...</div>
      </div>
    )
  }

  return children
}
