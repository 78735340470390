import { useState } from 'react'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import getMyVideos from 'publisher/queries/getMyVideos'
import Select from 'shared/components/form/select'
import useDebounceCallback from 'helpers/hooks/useDebounceCallback'

export default function SelectVideo(props) {
  const [search, setSearch] = useState('')

  const { data, isLoading } = useQuery(getMyVideos, {
    variables: {
      search,
    },
  })

  const options = get(data, 'json.data', []).map((opt) => ({
    label: opt.title || 'Untitled Video',
    value: opt.id,
  }))

  const handleInputChange = useDebounceCallback((text) => {
    setSearch(text)
  })

  return (
    <Select
      {...props}
      isLoading={isLoading}
      onInputChange={handleInputChange}
      options={options}
    />
  )
}
