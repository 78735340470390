import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Input from 'shared/components/form/input'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import SelectUserType from './SelectUserType'
import SelectLoginMethod from './SelectLoginMethod'
import classes from './Filters.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <Input
        {...form.getInputPropsFor('search')}
        placeholder="Search Users..."
      />
      <SelectUserType
        {...form.getInputPropsFor('isHero')}
        className={classes.userTypes}
        isClearable
      />
      <SelectLoginMethod
        {...form.getInputPropsFor('loginType')}
        className={classes.userTypes}
        isClearable
      />
      <HtmlSelect {...form.getInputPropsFor('sort')}>
        <option value="">Sort By...</option>
        <option value="last_signup">Latest Sign Up</option>
        <option value="last_active">Last Active</option>
        <option value="most_like">Most Likes</option>
        <option value="most_comment">Most Comments</option>
        <option value="most_subscribes">Most Subscribes</option>
        <option value="most_watch_hours">Most Watch Hours</option>
        <option value="most_referrals">Most Referrals</option>
      </HtmlSelect>
      <Button outlined>Search Users</Button>
    </form>
  )
}
