import notification from 'shared/components/kit/notification'
import Button from 'shared/components/kit/button'
import useMutation from 'core/useMutation'
import archiveMessage from 'admin/queries/archiveMessage'
import useQueryClient from 'core/useQueryClient'
import getMessage from 'admin/queries/getMessage'

export default function ArchiveMessageButton({ messageId }) {
  const queryClient = useQueryClient()
  const mutation = useMutation(archiveMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getMessage,
        variables: { id: messageId },
      })
      notification.success({
        title: 'Message archived successfully!',
      })
    },
  })

  const handleArchiveClick = async (e) => {
    e.preventDefault()
    await mutation.mutateAsync({
      id: messageId,
    })
  }

  return (
    <Button
      onClick={handleArchiveClick}
      className="ml-2"
      outlined
      secondary
      size="sm">
      Archive
    </Button>
  )
}
