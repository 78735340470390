import classnames from 'classnames'
import classes from './index.module.css'

export default function Icon({ children, className }) {
  return (
    <span className={classnames('icon', classes.icon, className)}>
      {children}
    </span>
  )
}
