import classnames from 'classnames'
import { ReactComponent as CheckSVG } from 'svg/check-circle-filled.svg'
import { ReactComponent as ErrorSVG } from 'svg/error-circle-filled.svg'
import { ReactComponent as InfoSVG } from 'svg/info.svg'
import { ReactComponent as SadSVG } from 'svg/sad-filled.svg'
import classes from './index.module.css'

type ResultProps = {
  description?: any,
  footer?: any,
  status?: 'success' | 'error' | 'info' | 'sad',
  title: any,
}

const ICONS = {
  success: <CheckSVG />,
  error: <ErrorSVG />,
  info: <InfoSVG />,
  sad: <SadSVG />,
}

export default function Result(props: ResultProps) {
  const { status, title, description, footer, className } = props
  return (
    <div className={classnames(classes.container, className)}>
      <div
        className={classnames(
          classes.icon,
          status === 'info'
            ? classes.info
            : status === 'error'
            ? classes.error
            : status === 'success'
            ? classes.success
            : status === 'sad'
            ? classes.sad
            : null
        )}>
        {ICONS[status]}
      </div>
      <h4>{title}</h4>
      <div className={classes.description}>{description}</div>
      <footer>{footer}</footer>
    </div>
  )
}

Result.defaultProps = {
  status: 'info',
}
