import { useMemo } from 'react'
import dayjs from 'dayjs'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import getChannelsStatistics from 'admin/queries/getChannelsStatistics'

export default function useChannelStatistics(range) {
  let rangeParam = ''
  let fromParam = ''
  let toParam = ''

  const fromLastWeek = useMemo(() =>
    dayjs()
      .subtract(1, 'week')
      .startOf('week')
      .add('1', 'day')
      .format('YYYY-MM-DD')
  )
  const toLastWeek = useMemo(() =>
    dayjs()
      .subtract(1, 'week')
      .endOf('week')
      .add('1', 'day')
      .format('YYYY-MM-DD')
  )

  const fromThisWeek = useMemo(() =>
    dayjs().startOf('week').add('1', 'day').format('YYYY-MM-DD')
  )
  const toThisWeek = useMemo(() =>
    dayjs().endOf('week').add('1', 'day').format('YYYY-MM-DD')
  )

  const fromLastMonth = useMemo(() =>
    dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
  )
  const toLastMonth = useMemo(() =>
    dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
  )

  const fromThisMonth = useMemo(() =>
    dayjs().startOf('month').format('YYYY-MM-DD')
  )
  const toThisMonth = useMemo(() => dayjs().endOf('month').format('YYYY-MM-DD'))

  const fromThisYear = useMemo(() =>
    dayjs().startOf('year').format('YYYY-MM-DD')
  )
  const toThisYear = useMemo(() => dayjs().endOf('year').format('YYYY-MM-DD'))

  switch (range) {
    case 'this_week':
      rangeParam = 'daily'
      fromParam = fromThisWeek
      toParam = toThisWeek
      break
    case 'last_week':
      rangeParam = 'daily'
      fromParam = fromLastWeek
      toParam = toLastWeek
      break
    case 'this_month':
      rangeParam = 'daily'
      fromParam = fromThisMonth
      toParam = toThisMonth
      break
    case 'last_month':
      rangeParam = 'daily'
      fromParam = fromLastMonth
      toParam = toLastMonth
      break
    case 'this_year':
      rangeParam = 'monthly'
      fromParam = fromThisYear
      toParam = toThisYear
      break
  }

  const { data, error, isError, isLoading } = useQuery(getChannelsStatistics, {
    variables: {
      range: rangeParam,
      from: fromParam,
      to: toParam,
    },
  })

  return {
    data: get(data, 'json', []),
    error,
    isError,
    isLoading,
  }
}
