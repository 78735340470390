import { forwardRef, useImperativeHandle, useState } from 'react'
import classnames from 'classnames'
import useQuery from 'core/react-query/useQuery'
import Input from 'shared/components/form/input'
import Button from 'shared/components/kit/button'
import { ReactComponent as RefreshSVG } from 'icons/refresh.svg'
import classes from './index.module.css'

function CaptchaInput(props, ref) {
  const { forceDarkMode, onLoad, onChange, ...rest } = props

  const [text, setText] = useState('')

  const { data, refetch, isLoading } = useQuery(getCaptcha, {
    refetchInterval: 60000,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
    onSuccess: (response) => {
      onLoad(response.json)
    },
  })

  useImperativeHandle(ref, () => ({
    reload: () => {
      refetch()
    },
  }))

  const handleChangeText = (value) => {
    setText(value)
    onChange(value)
  }

  const handleRefreshCaptcha = (event) => {
    event.preventDefault()
    refetch()
  }

  const img = data?.json.img

  return (
    <div
      className={classnames(classes.wrapper, {
        [classes.forceDarkMode]: forceDarkMode,
      })}>
      <figure>{img ? <img alt="CAPTCHA" src={img} /> : null}</figure>
      <Input
        placeholder="Answer"
        {...rest}
        disabled={isLoading}
        onChange={handleChangeText}
        value={text}
      />
      <Button
        loading={isLoading}
        mood="neutral"
        onClick={handleRefreshCaptcha}
        role="a"
        square
        type="button">
        <RefreshSVG />
      </Button>
    </div>
  )
}

export default forwardRef(CaptchaInput)

function getCaptcha() {
  return {
    name: 'captcha',
    path: '/captcha',
  }
}
