import { useMemo } from 'react'
import Chart from 'shared/components/chart'
import useGetCSSColor from 'shared/hooks/useGetCSSColor'
import readableNumber from 'shared/helpers/readableNumber'

export default function PointsChart({ data, labels, getDate }) {
  const getCSSColor = useGetCSSColor()

  const config = useMemo(() => {
    return {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            label: 'Points',
            backgroundColor: getCSSColor('secondary', 0.2),
            borderColor: getCSSColor('secondary'),
            data: data.map((item) => item.points_total),
            fill: true,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Points',
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              footer: (tooltipItems) => {
                let normal = 0,
                  hero = 0
                tooltipItems.forEach((tooltipItem) => {
                  normal += data[tooltipItem.dataIndex].points_non_hero
                  hero += data[tooltipItem.dataIndex].points_hero
                })
                normal = readableNumber(normal)
                hero = readableNumber(hero)
                return `Users: ${normal}\nHodls: ${hero}`
              },
              title: (tooltipItems) =>
                getDate(data[tooltipItems[0].dataIndex].date),
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                return readableNumber(value)
              },
            },
          },
        },
      },
    }
  }, [getCSSColor, data, labels, getDate])

  return <Chart config={config} />
}
