// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".payments_avatar__3NH_T {\n  width: 5rem;\n}\n\n.payments_avatar__3NH_T img {\n  border-radius: 9999px;\n  display: block;\n  max-height: 2.5rem;\n  max-width: 2.5rem;\n  min-height: 2.5rem;\n  min-width: 2.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/payments/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,WAAmB;ADAnB;;AAEA;ECFA,qBAAmB;EAAnB,cAAmB;EDIjB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".avatar {\n  @apply w-20;\n}\n\n.avatar img {\n  @apply block rounded-full;\n  max-height: 2.5rem;\n  max-width: 2.5rem;\n  min-height: 2.5rem;\n  min-width: 2.5rem;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "payments_avatar__3NH_T"
};
export default ___CSS_LOADER_EXPORT___;
