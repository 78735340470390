import { useState } from 'react'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import getUserName from 'helpers/utils/getUserName'
import useDebounceCallback from 'helpers/hooks/useDebounceCallback'
import truncate from 'helpers/utils/truncate'
import ChannelAvatar from 'shared/components/channel-avatar'
import Select from 'shared/components/form/select'
import getPublishers from 'admin/queries/getPublishers'
import classes from './index.module.css'

export default function SelectPublisher(props) {
  const [search, setSearch] = useState('')

  const { data, isLoading } = useQuery(getPublishers, {
    variables: {
      search,
      include: 'role,channel',
    },
  })

  const options = get(data, 'json.data', []).map((user) => ({
    user,
    label: getUserName(user),
    value: user.id,
  }))

  const handleInputChange = useDebounceCallback((text) => {
    setSearch(text)
  })

  const formatOptionLabel = ({ label, user }, { context }) => {
    if (context === 'value') {
      return user?.channel?.name
    }
    return (
      <div className={classes.option}>
        <ChannelAvatar className={classes.avatar} channel={user.channel} />
        <div className={classes.info}>
          <div>
            {user.channel?.name}
            {user?.channel?.status !== 'published' ? (
              <small className="ml-4">{user?.channel?.status}</small>
            ) : null}
          </div>
          <small>
            Owner: {getUserName(user)} (
            <span title={user.email?.length > 35 ? user.email : ''}>
              {truncate(user.email, 35)}
            </span>
            )
          </small>
        </div>
      </div>
    )
  }

  return (
    <Select
      {...props}
      filterOption={null}
      formatOptionLabel={formatOptionLabel}
      isLoading={isLoading}
      onInputChange={handleInputChange}
      options={options}
    />
  )
}
