// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notifications_wrapper__3wSO_ {\n  overflow-y: auto;\n  max-height: 50vh;\n  width: 400px;\n  overscroll-behavior: contain;\n}\n\n.notifications_wrapper__3wSO_ header {\n  --tw-bg-opacity: 1;\n  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));\n  border-top-left-radius: 0.25rem;\n  border-top-right-radius: 0.25rem;\n  border-bottom-width: 1px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 1rem;\n  position: sticky;\n  top: 0px;\n  z-index: 10;\n}\n\n.notifications_loading__1D1Lr {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 1.125rem;\n  line-height: 1.75rem;\n  opacity: 0.5;\n  padding: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/components/notifications/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,gBAAmB;EDAjB,gBAAgB;EAChB,YAAY;EACZ,4BAA4B;AAC9B;;AAEA;ECLA,kBAAmB;EAAnB,2DAAmB;EAAnB,+BAAmB;EAAnB,gCAAmB;EAAnB,wBAAmB;EAAnB,aAAmB;EAAnB,mBAAmB;EAAnB,8BAAmB;EAAnB,aAAmB;EAAnB,gBAAmB;EAAnB,QAAmB;EAAnB,WAAmB;ADOnB;;AAEA;ECTA,aAAmB;EAAnB,mBAAmB;EAAnB,uBAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,YAAmB;EAAnB,aAAmB;ADWnB","sourcesContent":[".wrapper {\n  @apply overflow-y-auto;\n  max-height: 50vh;\n  width: 400px;\n  overscroll-behavior: contain;\n}\n\n.wrapper header {\n  @apply border-b bg-white flex items-center justify-between p-4 rounded-tl rounded-tr sticky top-0 z-10;\n}\n\n.loading {\n  @apply flex items-center justify-center opacity-50 text-lg p-4;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "notifications_wrapper__3wSO_",
	"loading": "notifications_loading__1D1Lr"
};
export default ___CSS_LOADER_EXPORT___;
