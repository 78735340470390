import Tag from 'shared/components/kit/tag'

const STATUSES = {
  inactive: {
    color: 'bg-light-2 text-neutral dark:bg-dark-3',
    label: 'Inactive',
  },
  active: {
    color: 'bg-green-100 text-green-500 dark:bg-green-600 dark:text-green-100',
    label: 'Active',
  },
}

export default function ReasonStatus({ status }) {
  const data = STATUSES[status]

  if (data) {
    return (
      <Tag type={data.color}>
        {data.icon}
        <span>{data.label}</span>
      </Tag>
    )
  }

  return null
}
