type calculateChannelsEarningsVariables = {
  month: string,
  valuePerShare: string,
}

export default function calculateChannelsEarnings(
  variables: calculateChannelsEarningsVariables = {}
) {
  return {
    method: 'POST',
    path: '/admin/earnings/calc',
    params: {
      month: variables.month,
      value_per_share: variables.valuePerShare,
    },
  }
}
