import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Input from 'shared/components/form/input'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import SelectCategory from 'shared/components/form/select-category'
import classes from './Filters.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <Input
        {...form.getInputPropsFor('search')}
        className={classes.search}
        placeholder="Search videos..."
      />
      <SelectCategory
        {...form.getInputPropsFor('category')}
        className={classes.category}
        isClearable
      />
      <HtmlSelect {...form.getInputPropsFor('sort')} className={classes.sort}>
        <option value="">Sort By...</option>
        <option value="published_at">Publish Date</option>
        <option value="most_commented">Most Commented</option>
        <option value="most_liked">Most Liked</option>
        <option value="most_viewed">Most Viewed</option>
        <option value="most_reported">Most Reported</option>
      </HtmlSelect>
      <Button outlined>Search Videos</Button>
    </form>
  )
}
