type getVideoQueryVariables = {
  id: number,
}

export default function getVideo(variables: getVideoQueryVariables = {}) {
  const { id } = variables
  return {
    name: ['video', id],
    path: `/publisher/videos/${id}`,
    params: {
      include: 'user,crypto_currencies,playlists,category,tags',
    },
  }
}
