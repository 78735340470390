import { useState } from 'react'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import Modal from 'shared/components/kit/modal'
import getComments from 'publisher/queries/getComments'
import deleteComment from 'publisher/mutations/deleteComment'
import getComment from 'publisher/queries/getComment'

export default function DeleteButton({
  children,
  commentId,
  hasReply,
  parentId,
  onCloseParent,
}) {
  const parsedLocation = useParsedLocation()
  const { page, sort, video, time, perPage, unread } =
    parsedLocation.queryParams

  const isRememberedTab = parsedLocation.pathname.endsWith(
    'publisher/comments/remembered'
  )
  const isMentionedTab = parsedLocation.pathname.endsWith(
    'publisher/comments/mentioned'
  )

  const [visible, setVisible] = useState()

  const queryClient = new useQueryClient()

  const handleShow = (e) => {
    e.stopPropagation()
    setVisible(true)
  }

  const { mutateAsync, isLoading } = useMutation(deleteComment, {
    onMutate: () => {
      if (!!parentId) {
        queryClient.setQueryData({
          query: getComment,
          variables: {
            id: parentId,
          },
          updater: (prev) => {
            return {
              ...prev,
              json: {
                ...prev.json,
                replies: [
                  ...prev.json.replies.filter((rep) => rep.id !== commentId),
                ],
              },
            }
          },
        })
      } else {
        onCloseParent()
        queryClient.setQueryData({
          query: getComments,
          variables: {
            page: Number(page) || 1,
            video: Number(video),
            sort,
            time,
            perPage,
            onlyMyMentions: isMentionedTab ? true : false,
            onlyRemembers: isRememberedTab ? true : false,
            onlyUnreadReplies: isMentionedTab && unread === 'true',
          },
          updater: (prev) => {
            return {
              ...prev,
              json: {
                ...prev.json,
                data: prev.json.data.filter((item) => item.id !== commentId),
              },
            }
          },
        })
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getComments,
      })
      // notification.success({
      //   title: `${!!parentId ? 'Reply' : 'Comment'} deleted successfully!`,
      // })
      setVisible(false)
    },
    onError: (error) => {
      setVisible(false)
    },
  })

  const handleOk = async () => {
    try {
      await mutateAsync({ id: commentId })
    } catch (error) {}
  }

  const handleCancel = () => {
    setVisible(false)
  }
  return (
    <>
      <button onClick={handleShow}>{children}</button>
      <Modal
        title={`Confirm ${!!parentId ? 'Reply' : 'Comment'} Deletion`}
        onOk={handleOk}
        visible={visible}
        loading={isLoading}
        onCancel={handleCancel}
        size="sm"
        okText={`Yes, Delete My ${!!parentId ? 'Reply' : 'Comment'}`}>
        <p>
          Are you sure you want to delete your{' '}
          {!!parentId ? 'reply' : 'comment'}?
          <br />
          {hasReply && 'All replies will also be deleted!'}
        </p>
      </Modal>
    </>
  )
}
