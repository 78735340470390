import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import Box from 'shared/components/kit/box'
import readableNumber from 'shared/helpers/readableNumber'
import getDashboardSummaryData from 'publisher/queries/getDashboardSummaryData'
import classes from './Summary.module.css'

export default function Summary() {
  const { data, isError, isLoading } = useQuery(getDashboardSummaryData, {
    keepPreviousData: true,
  })

  const subscribers = readableNumber(
    get(data, 'json.overview.subscribers_total')
  )
  const points = readableNumber(get(data, 'json.overview.points'))
  const likes = readableNumber(get(data, 'json.overview.likes_total'))
  const published = readableNumber(get(data, 'json.overview.published_videos'))
  const views = readableNumber(get(data, 'json.overview.views_total'))
  const watchTime = readableNumber(
    (get(data, 'json.overview.watch_time_total') || 0) / 3600
  )

  return (
    <Box className={classes.wrapper}>
      <ul>
        <SummaryItem
          error={isError}
          loading={isLoading}
          title="Points"
          value={points}
        />
        <SummaryItem
          error={isError}
          loading={isLoading}
          title="Watch Hours"
          value={watchTime}
        />
        <SummaryItem
          error={isError}
          loading={isLoading}
          title="Subscribers"
          value={subscribers}
        />
        <SummaryItem
          error={isError}
          loading={isLoading}
          title="Views"
          value={views}
        />
        <SummaryItem
          error={isError}
          loading={isLoading}
          title="Likes"
          value={likes}
        />
        <SummaryItem
          error={isError}
          loading={isLoading}
          title="Media Published"
          value={published}
        />
      </ul>
    </Box>
  )
}

function SummaryItem({ loading, title, value }) {
  return (
    <li>
      <span>{loading ? '...' : value}</span>
      <small>Total {title}</small>
    </li>
  )
}
