import { useMemo } from 'react'
import { useParams } from 'react-router'
import useQuery from 'core/react-query/useQuery'
import formatDate from 'helpers/utils/formatDate'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import Table from 'shared/components/kit/table'
import VideoStatus from 'shared/components/video-status'
import VideoThumbnail from 'shared/components/video-thumbnail'
import getVideos from 'admin/queries/getVideos'
import Page from 'admin/components/page'
import DeleteVideoButton from './components/DeleteVideoButton'
import HideVideoButton from './components/HideVideoButton'
import UnHideVideoButton from './components/UnHideVideoButton'
import Filters from './components/Filters'
import classes from './index.module.css'

export default function ChannelVideos() {
  const params = useParams()

  const channelId = Number(params.id)

  const FILTER_CONFIG = useMemo(
    () => ({
      base: `/admin/channels/${channelId}/videos`,
      params: {
        category: 'number',
        page: 'pagination',
        search: 'any',
        sort: 'any',
      },
    }),
    [channelId]
  )

  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)

  const { data, error, isError, isFetching } = useQuery(getVideos, {
    keepPreviousData: true,
    variables: {
      channelId,
      categoryId: filters.category,
      page: filters.page,
      search: filters.search,
      sort: filters.sort,
    },
  })

  const {
    data: videos,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  if (isError) {
    return <Page error={error.message} />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  return (
    <Page description={description} title="Videos">
      <Filters onSubmit={setFilters} />
      <Table
        columns={columns}
        data={videos}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
    </Page>
  )
}

function getColumns() {
  return [
    {
      className: classes.thumbnail,
      title: 'Thumbnail',
      render: (video) => <VideoThumbnail video={video} />,
    },
    {
      className: classes.info,
      title: 'Title',
      render: (video) => (
        <>
          {video.title ? (
            video.title
          ) : (
            <span className={classes.untitled}>Untitled</span>
          )}
        </>
      ),
    },
    {
      className: classes.categories,
      title: 'Category',
      render: (video) => video.category?.name || '-',
    },
    {
      align: 'center',
      className: classes.views,
      title: 'Views',
      render: (video) => video.view_count,
    },
    {
      align: 'center',
      className: classes.comments,
      title: 'Comments',
      render: (video) => video.comment_count,
    },
    {
      align: 'center',
      className: classes.likes,
      title: 'Likes',
      render: (video) => video.likes_count,
    },
    {
      align: 'center',
      className: classes.reports,
      title: 'Reports',
      render: (video) => video.reports_count,
    },
    {
      align: 'center',
      className: classes.status,
      title: 'Status',
      render: (video) => <VideoStatus status={video.status} />,
    },
    {
      align: 'center',
      className: classes.publishDate,
      title: 'Publish Date',
      render: (video) => formatDate(video.published_at),
    },
    {
      className: classes.actions,
      render: (video) => (
        <div className="whitespace-nowrap">
          {video.status === 'hidden' ? (
            <UnHideVideoButton id={video.id} />
          ) : (
            <HideVideoButton id={video.id} />
          )}
          &nbsp;&nbsp;
          <DeleteVideoButton id={video.id} />
        </div>
      ),
    },
  ]
}
