import Modal from 'shared/components/kit/modal'
import Button from 'shared/components/kit/button'
import classes from './RoadmapModal.module.css'

export default function RoadmapModal({ visible, onClose }) {
  return (
    <Modal
      onCancel={onClose}
      footer={
        <Button onClick={onClose} transparent mood="neutral">
          Close
        </Button>
      }
      title="Roadmap - 2023"
      visible={visible}
      size="lg">
      <div className={classes.wrapper}>
        <h2 className={classes.title}>Roadmap - 2023/2024</h2>
        <div className={classes.quarterItem}>
          <div className={classes.quarterNumber}>
            <h2>Q3</h2>
          </div>
          <div className={classes.quarterContent}>
            <h3>Let’s Build.</h3>
            <p>
              We have many exciting features to build, one of which will be the
              ads-platform. We constantly push for our partnerships, and this
              month is no exception.
            </p>
            <ul>
              <li>
                <span className={classes.checked} /> Establish Strategic
                Partnerships
              </li>
              <li>
                <span className={classes.checked} /> Marketing Campaigns
              </li>
              <li>
                <span className={classes.checked} /> Launch of Ads-platform
              </li>
              <li>
                <span className={classes.checked} /> Community Airdrop
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.quarterItem}>
          <div className={classes.quarterNumber}>
            <h2>Q4</h2>
          </div>
          <div className={classes.quarterContent}>
            <h3>Time to Scale.</h3>
            <p>
              Growth requires more of everything, and it's what Q4 is all about.
              More helpful features that stimulate expansion are on the table,
              along with other exciting releases.
            </p>
            <ul>
              <li>
                <span className={classes.active} /> Launchpad For TCG Token
              </li>
              <li>
                <span className={classes.checked} /> Governance Preparation
              </li>
              <li>
                <span className={classes.checked} /> TCG Token IEO
              </li>
              <li>
                <span className={classes.checked} /> First Exchange Listing
              </li>
            </ul>
          </div>
        </div>
        <h2 className={classes.title}>2024</h2>
        <div className={classes.quarterItem}>
          <div className={classes.quarterNumber}>
            <h2>Q1</h2>
          </div>
          <div className={classes.quarterContent}>
            <h3>Growth.</h3>
            <p>
              As we enter a brand new year, we focus on bringing value to our
              users and content creators. We will roll out exciting features
              that will increase the overall UX on the platform. We will also
              fire up the monetization engine for our publishers.
            </p>
            <ul>
              <li>
                <span className={classes.unChecked} /> Marketing Campaign
              </li>
              <li>
                <span className={classes.unChecked} /> Improve Platform Market
                Data
              </li>
              <li>
                <span className={classes.unChecked} /> Creator Monetization
                Go-Live
              </li>
              <li>
                <span className={classes.unChecked} /> Exchange Listing
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.quarterItem}>
          <div className={classes.quarterNumber}>
            <h2>Q2</h2>
          </div>
          <div className={classes.quarterContent}>
            <h3>Community.</h3>
            <p>
              From now on, we will focus on building a solid community around
              Today's Crypto and its coming Governance system. We will continue
              to roll out more features and improve the UI/UX further.
            </p>
            <ul>
              <li>
                <span className={classes.unChecked} /> Exchange Listing
              </li>
              <li>
                <span className={classes.unChecked} /> Marketing Campaign
              </li>
              <li>
                <span className={classes.unChecked} /> Community Airdrop
              </li>
              <li>
                <span className={classes.unChecked} /> Community Competition
              </li>
            </ul>
          </div>
        </div>
        <p className={classes.footer}>
          This roadmap is a working document and is subject to change.
        </p>
      </div>
    </Modal>
  )
}
