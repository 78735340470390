import { useMemo, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import { useHistory } from 'react-router'
import Table from 'shared/components/kit/table'
import Button from 'shared/components/kit/button'
import Page from 'admin/components/page'
import getQualifiedChannels from 'admin/queries/getQualifiedChannels'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import ChannelPaymentDetailStatus from './components/ChannelPaymentDetailStatus'
import ManagePaymentDetailsDrawer from './components/ManagePaymentDetailsDrawer'
import ChannelAvatar from 'shared/components/channel-avatar'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import classes from './index.module.css'

export default function Qualified() {
  const [page, setPage] = useState(1)

  const { data, error, isError, isFetching } = useQuery(getQualifiedChannels, {
    keepPreviousData: true,
    variables: {
      page: page,
    },
  })

  const {
    data: qualifiedChannels,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const handlePaginate = (page) => {
    setPage(page)
  }

  const columns = useMemo(
    () => getColumns(qualifiedChannels),
    [qualifiedChannels]
  )

  const history = useHistory()
  const { queryParams } = useParsedLocation()
  const userId = queryParams.id ? Number(queryParams.id) : undefined

  const handleCloseUserDrawer = () => {
    let search = history.location.search.substr(
      0,
      history.location.search.indexOf('&id')
    )
    history.push('/admin/monetization/qualified' + search)
  }

  return (
    <>
      <Page title="Qualified channels">
        <ManagePaymentDetailsDrawer
          userId={userId}
          onClose={handleCloseUserDrawer}
          visible={!!userId}
        />
        <Table
          columns={columns}
          data={qualifiedChannels}
          loading={isFetching}
          currentPage={currentPage}
          lastPage={lastPage}
          onPaginate={handlePaginate}
        />
      </Page>
    </>
  )
}

function getColumns(qualifiedChannels) {
  return [
    {
      className: classes.channelName,
      title: 'Channel name',
      render: (qualifiedChannel) => (
        <div className="flex items-center">
          <ChannelAvatar channel={qualifiedChannel} size={14} />{' '}
          <span className="mx-2">{qualifiedChannel.name}</span>
        </div>
      ),
    },
    {
      className: classes.channelEmail,
      title: 'Channel email',
      render: (qualifiedChannel) => <span>{qualifiedChannel.owner.email}</span>,
    },
    {
      className: classes.paymentDetailsStatus,
      title: 'status',
      render: (qualifiedChannel) => (
        <ChannelPaymentDetailStatus
          paymentDetails={qualifiedChannel.owner?.verified_payment_details}
        />
      ),
    },
    {
      className: classes.action,
      title: 'status',
      render: (qualifiedChannel) => (
        <Button
          to={
            `/admin/monetization/qualified?` +
            `&id=${qualifiedChannel.owner.id}`
          }
          component="link"
          secondary>
          Payment Details
        </Button>
      ),
    },
  ]
}
