import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import getMyPlaylists from 'publisher/queries/getMyPlaylists'
import Select from 'shared/components/form/select'

export default function SelectPlaylist(props) {
  const { data, isLoading } = useQuery(getMyPlaylists)
  const options = get(data, 'json', []).map((opt) => ({
    label: opt.name,
    value: opt.id,
  }))

  return <Select {...props} isLoading={isLoading} options={options} />
}
