// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".channel_loading__3HA2N {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  font-size: 1.125rem;\n  line-height: 1.75rem;\n  opacity: 0.5;\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n}\n\n.channel_error___wYeM {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0px;\n  right: 0px;\n  bottom: 0px;\n  left: 0px;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/channel/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,mBAAmB;EAAnB,uBAAmB;EAAnB,YAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,YAAmB;EAAnB,kBAAmB;EAAnB,QAAmB;EAAnB,SAAmB;EAAnB,WAAmB;ADAnB;;AAEA;ECFA,aAAmB;EAAnB,mBAAmB;EAAnB,uBAAmB;EAAnB,kBAAmB;EAAnB,QAAmB;EAAnB,UAAmB;EAAnB,WAAmB;EAAnB,SAAmB;ADInB","sourcesContent":[".loading {\n  @apply absolute flex h-full items-center justify-center left-0 opacity-50 text-lg top-0 w-full;\n}\n\n.error {\n  @apply absolute bottom-0 flex items-center justify-center left-0 right-0 top-0;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "channel_loading__3HA2N",
	"error": "channel_error___wYeM"
};
export default ___CSS_LOADER_EXPORT___;
