import { useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import get from 'lodash/get'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import useQuery from 'core/react-query/useQuery'
import Drawer from 'shared/components/kit/drawer'
import notification from 'shared/components/kit/notification'
import getPlans from 'admin/queries/getPlans'
import updatePlan from 'admin/queries/updatePlan'
import getPaymentMethods from 'admin/queries/getPaymentMethods'
import PlanForm from './PlanForm'

export default function Plan() {
  const params = useParams()

  const planId = Number(params.planId)

  const queryClient = useQueryClient()

  const history = useHistory()

  const {
    data: plansData,
    isLoading: plansIsLoading,
    error: plansError,
    isError: plansIsError,
  } = useQuery(getPlans, {
    keepPreviousData: true,
  })

  const plans = get(plansData, 'json', [])

  const plan = useMemo(
    () => plans.find((plan) => plan.id === planId),
    [planId, plans]
  )

  const initialValues = useMemo(() => {
    let result = {}

    const planStripe = plan?.pricing.find(
      (price) => price.payment_method.name === 'Stripe'
    )
    const planCoinbase = plan?.pricing.find(
      (price) => price.payment_method.name === 'Coinbase'
    )

    if (plan) {
      result = {
        name: plan.name,
        interval: plan.interval,
        isPopular: plan.is_popular,
        description: plan.description,
        status: plan.status === 'active',
      }
    }

    if (planStripe) {
      result.enableStripeAmount = true
      result.stripePaymentMethodId = planStripe.payment_method.id
      result.stripeAmountId = planStripe.id
      result.stripeAmount = planStripe.amount
    }
    if (planCoinbase) {
      result.enableCoinbaseAmount = true
      result.coinbasePaymentMethodId = planCoinbase.payment_method.id
      result.coinbaseAmountId = planCoinbase.id
      result.coinbaseAmount = planCoinbase.amount
    }

    return result
  }, [plan])

  const { data: paymentMethodsData, isLoading } = useQuery(getPaymentMethods)

  const paymentMethods = get(paymentMethodsData, 'json', [])

  const stripeMethod = paymentMethods.find((method) => method.name === 'Stripe')

  const coinbaseMethod = paymentMethods.find(
    (method) => method.name === 'Coinbase'
  )

  const updateMutation = useMutation(updatePlan, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getPlans,
      })
      notification.success({
        title: 'Membership plan updated successfully!',
      })
      history.push('/admin/plans')
    },
    onError: (error) => {
      notification.error({
        title: error.message,
      })
    },
  })

  const handleCloseDrawer = () => {
    history.push('/admin/plans')
  }

  const handleSubmit = async (values) => {
    try {
      const pricing = []

      if (values.enableStripeAmount) {
        pricing.push({
          id: initialValues.stripeAmountId,
          amount: values.stripeAmount,
          paymentMethodId: stripeMethod.id,
          currency: 'usd',
        })
      }

      if (values.enableCoinbaseAmount) {
        pricing.push({
          id: initialValues.coinbaseAmountId,
          amount: values.coinbaseAmount,
          paymentMethodId: coinbaseMethod.id,
          currency: 'usd',
        })
      }

      await updateMutation.mutateAsync({
        id: planId,
        name: values.name,
        interval: values.interval,
        description: values.description,
        isPopular: values.isPopular,
        status: values.status,
        pricing: pricing,
      })
    } catch (error) {
      throw error
    }
  }

  return (
    <Drawer
      onClose={handleCloseDrawer}
      error={{ message: 'THE RESOURCE YOU ARE LOOKING FOR IS NOT FOUND' }}
      isError={!plan}
      visible={!!planId}
      title={plan && 'Edit Membership Plan'}
      loading={updateMutation.isLoading}>
      {plan && (
        <PlanForm
          submitText="Update Plan"
          onSubmit={handleSubmit}
          initialValues={initialValues}
        />
      )}
    </Drawer>
  )
}
