import React, { useLayoutEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import LandingsLayout from 'landings/components/layout'
import HowItWorks from 'landings/pages/how-it-works'
import Monetize from 'landings/pages/monetize'

export default function LandingsApp() {
  useLayoutEffect(() => {
    document.getElementsByTagName('html')[0].classList.remove('dark')
  }, [])

  return (
    <Switch>
      <Route path="/landings/monetize" component={Monetize} exact />
      <LandingsLayout>
        <Switch>
          <Route path="/landings/how-it-works" component={HowItWorks} exact />
        </Switch>
      </LandingsLayout>
    </Switch>
  )
}
