type CreateAdsCampaign = {
  name: string,
  companyId: number,
  status: string,
  slots: Array<Object>,
  tiersData: Object,
}

export default function createAdsCampaign(variables: CreateAdsCampaign = {}) {
  const { name, companyId, status, slots, tiersData } = variables
  return {
    method: 'POST',
    path: '/admin/ads/campaigns',
    params: {
      name,
      company_id: companyId,
      status,
      slots,
      tiers_data: tiersData,
    },
  }
}
