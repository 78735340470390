type GetMyVideosQueryVariables = {
  excludePlaylist?: number,
  excludeVideos?: Array<number>,
  includeCategoryRelation?: boolean,
  includePlaylistsRelation?: boolean,
  includeUserRelation?: boolean,
  page?: number,
  playlistId?: number,
  mediaType?: string,
  search?: string,
  sort?: string,
}

export default function getMyVideos(variables: GetMyVideosQueryVariables = {}) {
  const {
    excludePlaylist,
    excludeVideos,
    includeCategoryRelation,
    includePlaylistsRelation,
    includeUserRelation,
    page,
    playlistId,
    mediaType,
    search,
    sort,
  } = variables

  const relations = ['crypto_currencies']

  if (includeCategoryRelation) {
    relations.push('category')
  }

  if (includePlaylistsRelation) {
    relations.push('playlists')
  }

  if (includeUserRelation) {
    relations.push('user')
  }

  return {
    name: 'videos',
    path: '/publisher/videos',
    params: {
      exclude_playlist: excludePlaylist,
      exclude_videos: excludeVideos,
      filters: {
        playlist: playlistId,
        search,
        media_type: mediaType,
      },
      include: relations.join(',') || undefined,
      page,
      sort,
    },
  }
}
