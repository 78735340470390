type readNotificationMutationVariables = {
  id: string,
}
export default function readNotification(
  variables: readNotificationMutationVariables
) {
  const { id } = variables
  return {
    method: 'put',
    url: `/notifications/${id}/read`,
    variables: { id },
  }
}
