import { useHistory } from 'react-router'
import { useState } from 'react'
import useMutation from 'core/react-query/useMutation'
import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import Input from 'shared/components/form/input'
import EmailVerificationModal from 'shared/components/email-verification-modal'
import deleteMyAccount from 'publisher/mutations/deleteMyAccount'

export default function AccountDeletion() {
  const history = useHistory()
  const [step, setStep] = useState('')
  const [showEmailVerification, setShowEmailVerification] = useState(false)
  const form = useMiniForm({
    async onSubmit(values) {
      try {
        if (step === 'confirm') {
          setStep('reason')
        } else {
          await deleteAccount.mutateAsync({
            feedback: values.feedback,
          })
        }
      } catch (error) {
        if (error?.json?.code === 'email_verification.require') {
          setShowEmailVerification(true)
        } else {
          throw error.json
        }
      }
    },
  })

  const deleteAccount = useMutation(deleteMyAccount, {
    onSuccess: () => {
      setStep('')
      form.reset()
    },
    onError: (error) => {
      setStep('')
      console.log(error)
    },
  })

  const handleCancel = () => {
    setStep('')
    form.reset()
  }

  if (deleteAccount.isSuccess) {
    history.push('/publisher/channel-deleted')
  }

  const handleCancelEmailVerification = () => {
    setShowEmailVerification(false)
  }

  const handleVerified = () => {
    setShowEmailVerification(false)
    form.submit()
  }

  return (
    <>
      <Button
        onClick={() => {
          setStep('confirm')
        }}
        mood="primary">
        Delete My Channel
      </Button>

      <Modal
        size="sm"
        onCancel={handleCancel}
        onOk={form.submit}
        okButtonProps={{
          loading: deleteAccount.isLoading,
        }}
        okText="Delete My Channel"
        title="Confirm channel deletion"
        visible={step !== ''}>
        <form onSubmit={form.submit}>
          {step === 'confirm' && (
            <p>Are you sure you want to delete your channel?</p>
          )}
          {step === 'reason' && (
            <>
              <p>
                We are sad to see you go, but thankful for the time we have had
                and we hope that your time with us has been joyful. <br />{' '}
                <br />
                Your opinion matters to us and we would appreciate if you would
                take a moment to leave us some feedback on why you are leaving,
                your feedback will help us improve our services and stay
                relevant for you as a publisher and the broader community with a
                passion for Crypto. Thank you!
              </p>
              <br />
              <Input
                {...form.getInputPropsFor('feedback')}
                placeholder="Insert you feedback, good and bad , here... (Optional)"
                multiline
              />
            </>
          )}
        </form>
      </Modal>
      <EmailVerificationModal
        onCancel={handleCancelEmailVerification}
        onVerified={handleVerified}
        visible={showEmailVerification}
      />
    </>
  )
}
