type PinCommentMutationVariables = {
  id: number,
}

export default function pinComment(variables: PinCommentMutationVariables) {
  return {
    method: 'put',
    path: `/comments/${variables.id}/pin`,
  }
}
