import classnames from 'classnames'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import getUserName from 'helpers/utils/getUserName'
import getTimeFromNow from 'helpers/utils/getTimeFromNow'
import truncate from 'helpers/utils/truncate'
import getUserAvatar from 'helpers/utils/getUserAvatar'
import formatHTML from 'helpers/utils/formatHTML'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import useProfile from 'shared/hooks/useProfile'
import Icon from 'shared/components/kit/icon'
import toggleReadComment from 'publisher/mutations/toggleReadComment'
import getComments from 'publisher/queries/getComments'
import { ReactComponent as ReplySVG } from 'svg/reply.svg'
import { ReactComponent as LikeOutlinedSVG } from 'icons/thumb-up-outlined.svg'
import { ReactComponent as LikeFilledSVG } from 'icons/thumb-up-filled.svg'
import { ReactComponent as DislikeOutlinedSVG } from 'icons/thumb-down-outlined.svg'
import { ReactComponent as DislikeFilledSVG } from 'icons/thumb-down-filled.svg'
import { ReactComponent as FlagOutlinedSVG } from 'icons/flag-outlined.svg'
import { ReactComponent as DeleteOutlinedSVG } from 'icons/delete-outlined.svg'
import { ReactComponent as PinOutlinedSVG } from 'svg/pin-outlined.svg'
import { ReactComponent as PinFilledSVG } from 'svg/pin-filled.svg'
import { ReactComponent as StarFilledSVG } from 'icons/star-filled.svg'
import { ReactComponent as StarOutlinedSVG } from 'icons/star-outlined.svg'
import { ReactComponent as HeroSVG } from 'svg/hero.svg'
import ReportButton from './ReportButton'
import DeleteButton from './DeleteButton'
import PinButton from './PinButton'
import ReplyButton from './ReplyButton'
import classes from './Comment.module.css'

export default function Comment(props) {
  const {
    comment,
    onLike,
    onDislike,
    onPin,
    onRemember,
    onUnRemember,
    onUnpin,
    showReplies,
    summary,
    parentId,
    onCloseParent,
    onAddMentioned,
    onRemoveMentioned,
    currentlyPinnedAction,
    onSetCurrentlyPinnedAction,
    mentioned,
    inThread,
  } = props

  const { user } = useProfile()
  const parsedLocation = useParsedLocation()
  const isMentionedTab = parsedLocation.pathname.endsWith(
    'publisher/comments/mentioned'
  )

  const { page, sort, video, time, perPage, unread } =
    parsedLocation.queryParams

  const mentionedComment = (
    <div className="overflow-hidden">
      {comment.mentions?.map((user, index) => (
        <span key={index} className="text-secondary p-1 rounded-xl mr-1">
          @{user.username}
        </span>
      ))}
      <span dangerouslySetInnerHTML={{ __html: formatHTML(comment.text) }} />
    </div>
  )

  const handleLike = (event) => {
    event.stopPropagation()
    onLike()
  }
  const queryClient = useQueryClient()

  const readMutation = useMutation(toggleReadComment, {
    onMutate: (res) => {
      queryClient.setQueryData({
        query: getComments,
        variables: {
          page: Number(page) || 1,
          video: Number(video),
          sort,
          time,
          perPage,
          onlyMyMentions: true,
          onlyRemembers: false,
          onlyUnreadReplies: unread === 'true',
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            data: prev.json.data
              .filter((item) =>
                unread === 'true' ? item.id !== res.commentId : item
              )
              .map((item) => {
                if (item.id === res.commentId) {
                  return {
                    ...item,
                    is_read_replies: !item.is_read_replies,
                  }
                }
                return item
              }),
          },
        }),
      })
    },
    onSuccess: () => {
      // queryClient.invalidateQueries({
      //   query: getComments,
      // })
    },
  })

  const handleRead = (e) => {
    e.stopPropagation()
    onCloseParent()
    readMutation.mutate({
      commentId: comment.id,
    })
  }

  const handleDislike = (event) => {
    event.stopPropagation()
    onDislike()
  }

  const handleRemember = (e) => {
    e.stopPropagation()
    onRemember()
  }

  const handleUnRemember = (e) => {
    e.stopPropagation()
    onUnRemember()
  }

  const handlePin = (pinnedComment) => {
    if (comment.is_pinned) {
      onUnpin()
    } else {
      onPin(pinnedComment)
    }
  }

  const readToggleClass = classnames(
    classes.readToggle,
    !comment.is_read_replies ? classes.unread : ''
  )

  return (
    <>
      <header id={comment.id} className={classes.header}>
        <span className={classes.userInfo}>
          {inThread && (
            <div className={classes.inThread}>
              <span className="w-80">{comment.video.title}</span>
              <span>{getTimeFromNow(comment.created_at)}</span>
            </div>
          )}
          <div className={classes.heroAvatar}>
            <img
              alt={getUserName(comment.user)}
              src={getUserAvatar(comment.user.avatar)}
            />
            {getUserName(comment.user)}
            {comment.user.is_hero && <HeroSVG />}
          </div>
        </span>
        {!inThread && (
          <span className="flex">
            <span className={!parentId ? 'mr-4' : '-mr-2'}>
              {getTimeFromNow(comment.created_at)}
            </span>
            {!parentId && isMentionedTab && (
              <div className={readToggleClass} onClick={handleRead}>
                <div className={classes.readDot} />
              </div>
            )}
          </span>
        )}
      </header>
      <div title={comment.text} className={classes.commentText}>
        {summary ? truncate(comment.text, 120) : mentionedComment}
      </div>

      {(typeof onDislike === 'function' || typeof onLike === 'function') && (
        <footer className={classes.meta}>
          {showReplies && (
            <div className={classes.replyCounter}>
              <Icon className={classes.metaIcon}>
                <ReplySVG />
              </Icon>
              <span className={classes.metaValue}>{comment.replies_count}</span>
            </div>
          )}
          <div>
            <button onClick={handleLike}>
              <Icon className={classes.metaIcon}>
                {comment.is_liked ? (
                  <LikeFilledSVG className={classes.active} />
                ) : (
                  <LikeOutlinedSVG />
                )}
              </Icon>
              <span className={classes.metaValue}>{comment.likes_count}</span>
            </button>
          </div>
          <div>
            <button onClick={handleDislike}>
              <Icon className={classes.metaIcon}>
                {comment.is_disliked ? (
                  <DislikeFilledSVG className={classes.active} />
                ) : (
                  <DislikeOutlinedSVG />
                )}
              </Icon>
              <span className={classes.metaValue}>
                {comment.dislikes_count}
              </span>
            </button>
          </div>
          <div>
            {user.id === comment.user.id && (
              <DeleteButton
                commentId={comment.id}
                hasReply={!!comment.replies_count}
                parentId={parentId}
                onCloseParent={onCloseParent}>
                <Icon className={classes.metaIcon}>
                  <DeleteOutlinedSVG />
                </Icon>
                <span className={classes.metaValue}>Delete</span>
              </DeleteButton>
            )}
            {user.id !== comment.user.id && (
              <ReportButton commentId={comment.id}>
                <Icon className={classes.metaIcon}>
                  <FlagOutlinedSVG />
                </Icon>
                <span className={classes.metaValue}>Report</span>
              </ReportButton>
            )}
          </div>
          {parentId && user.id !== comment.user.id && (
            <div>
              <ReplyButton
                mentioned={mentioned}
                onAddMentioned={onAddMentioned}
                onRemoveMentioned={onRemoveMentioned}
                reply={comment}>
                <Icon className={classes.metaIcon}>
                  <ReplySVG />
                </Icon>
                <span className={classes.metaValue}>Reply</span>
              </ReplyButton>
            </div>
          )}
          <div className="w-24">
            {typeof onPin === 'function' &&
              typeof onUnpin === 'function' &&
              comment.video.user_id === user.id && (
                <PinButton
                  className="w-24"
                  comment={comment}
                  currentlyPinnedAction={currentlyPinnedAction}
                  onSetCurrentlyPinnedAction={onSetCurrentlyPinnedAction}
                  onPin={(pinnedComment) => handlePin(pinnedComment)}>
                  <Icon className={classes.metaIcon}>
                    {comment.is_pinned ? (
                      <PinFilledSVG className={classes.active} />
                    ) : (
                      <PinOutlinedSVG />
                    )}
                  </Icon>
                  <span className={classes.metaValue}>
                    {comment.is_pinned ? 'Pinned' : 'Pin'}
                  </span>
                </PinButton>
              )}
          </div>
          <div>
            {typeof onRemember === 'function' && (
              <button
                className="w-36"
                onClick={(e) =>
                  comment.is_remembered
                    ? handleUnRemember(e)
                    : handleRemember(e)
                }>
                <Icon className={classnames(classes.metaIcon, '-mt-0.5')}>
                  {comment.is_remembered ? (
                    <StarFilledSVG className="fill-current text-yellow-400" />
                  ) : (
                    <StarOutlinedSVG />
                  )}
                </Icon>
                <span className={classes.metaValue}>
                  {comment.is_remembered ? 'Remembered' : 'Remember'}
                </span>
              </button>
            )}
          </div>
        </footer>
      )}
    </>
  )
}
