import { useState } from 'react'
import { useHistory } from 'react-router'
import useMutation from 'core/useMutation'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'

export default function DeletePublisher({ user }) {
  const [visible, setVisible] = useState(false)

  const history = useHistory()

  const { isLoading, mutate } = useMutation(deleteUser, {
    onSuccess: () => {
      notification.success({
        title: 'Publisher deleted successfully!',
      })
      setVisible(false)

      history.push('/admin/channels')
    },
  })

  const handleCancel = () => {
    setVisible(false)
  }

  const handleClick = () => {
    setVisible(true)
  }

  const handleOk = async () => {
    mutate({ id: user.id })
  }

  return (
    <>
      <p className="mb-4 text-red-600">
        By deleting the publisher, all channel information such as videos will
        be deleted as well.
      </p>
      <Button onClick={handleClick}>Delete Publisher</Button>
      <Modal
        loading={isLoading}
        okText="Delete this Publisher"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Delete Publisher"
        visible={visible}>
        <p>Are you sure you want to delete this publisher?</p>
      </Modal>
    </>
  )
}

function deleteUser(variables) {
  return {
    method: 'delete',
    url: `/admin/users/${variables.id}`,
    variables,
  }
}
