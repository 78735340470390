// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomUsersSelect_userInfoBox__1S2nO {\n  border-radius: 0.25rem;\n  border-width: 1px;\n  display: flex;\n  align-items: center;\n  padding: 0.75rem;\n  position: relative;\n}\n\n.CustomUsersSelect_userInfoBox__1S2nO > div {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n}\n\n.CustomUsersSelect_userInfoBox__1S2nO > img {\n  border-radius: 9999px;\n  height: 3rem;\n  margin-right: 0.5rem;\n  width: 3rem;\n}\n\n.CustomUsersSelect_userInfoBox__1S2nO > button {\n  font-size: 0.75rem;\n  line-height: 1rem;\n  position: absolute;\n  top: 1rem;\n  right: 1rem;\n  color: var(--overlay-color-muted-text);\n}\n\n.CustomUsersSelect_wrapperUsersInfos__2uXOe {\n  display: grid;\n  margin-bottom: 2rem;\n  gap: 1rem;\n  grid-template-columns: repeat(2, minmax(0, 1fr));\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/messages/components/CustomUsersSelect.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,sBAAmB;EAAnB,iBAAmB;EAAnB,aAAmB;EAAnB,mBAAmB;EAAnB,gBAAmB;EAAnB,kBAAmB;ADAnB;;AAEA;ECFA,mBAAmB;EAAnB,oBAAmB;ADInB;;AAEA;ECNA,qBAAmB;EAAnB,YAAmB;EAAnB,oBAAmB;EAAnB,WAAmB;ADQnB;;AAEA;ECVA,kBAAmB;EAAnB,iBAAmB;EAAnB,kBAAmB;EAAnB,SAAmB;EAAnB,WAAmB;EAAnB,sCAAmB;ADYnB;;AAEA;ECdA,aAAmB;EAAnB,mBAAmB;EAAnB,SAAmB;EAAnB,gDAAmB;ADgBnB","sourcesContent":[".userInfoBox {\n  @apply border flex rounded items-center p-3 relative;\n}\n\n.userInfoBox > div {\n  @apply text-sm;\n}\n\n.userInfoBox > img {\n  @apply h-12 mr-2 rounded-full w-12;\n}\n\n.userInfoBox > button {\n  @apply absolute right-4 text-overlayColor-mutedText text-xs  top-4;\n}\n\n.wrapperUsersInfos {\n  @apply grid grid-cols-2 gap-4 mb-8;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userInfoBox": "CustomUsersSelect_userInfoBox__1S2nO",
	"wrapperUsersInfos": "CustomUsersSelect_wrapperUsersInfos__2uXOe"
};
export default ___CSS_LOADER_EXPORT___;
