type GetAdminTokenPointsOverview = {
  statisticsPeriod?:
    | 'last_7d'
    | 'last_14d'
    | 'last_30d'
    | 'last_90d'
    | 'last_180d'
    | 'last_365d',
}

export default function getAdminTokenPointsOverview(
  variables: GetAdminTokenPointsOverview = {}
) {
  const { statisticsPeriod } = variables

  const filters = {}
  if (statisticsPeriod) {
    filters.statistics_period = statisticsPeriod
  }

  return {
    path: '/admin/tokens/dashboard',
    params: {
      filters,
    },
  }
}
