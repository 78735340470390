import classnames from 'classnames'
import { ReactComponent as CloseSVG } from 'svg/close-icon.svg'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import DatePicker from 'shared/components/form/datepicker'
import SelectTiers from './SelectTiers'
import Button from 'shared/components/kit/button'
import classes from './SelectTier.module.css'

export default function SelectTier({
  item,
  getInputPropsForByIndex,
  getErrorPropsFor,
  setValues,
  values,
  index,
}) {
  const handleRemoveCPM = () => {
    setValues((prev) => {
      return {
        ...prev,
        tiersDiscounts: prev.tiersDiscounts.filter(
          (_item, _index) => _index != prev.tiersDiscounts.length - 1
        ),
      }
    })
  }

  const getCPM = () =>
    values.tiersData.find((_item) => _item.tier == item.tier)?.cpm

  const helpPercent =
    item.tier != 'all'
      ? `${Number((100 * item.amount) / getCPM()).toFixed(2)}%`
      : ''

  const helpFixed =
    item.tier != 'all'
      ? `$${Number((getCPM() * item.amount) / 100).toFixed(2)}`
      : ''

  return (
    <div className={classes.selectTiersPeriod}>
      <Field
        label="Select Tier"
        {...getErrorPropsFor('tiers_discounts', 'tier', index)}>
        <SelectTiers
          className={classes.selectTiers}
          {...getInputPropsForByIndex('tiersDiscounts', 'tier', index)}
        />
      </Field>
      {item.type === 'fixed' && (
        <Field
          className={classnames(
            classes.discount,
            item.tier != 'all' ? 'mt-8' : ''
          )}
          label="Discount"
          {...getErrorPropsFor('tiers_discounts', 'amount', index)}
          help={helpPercent}>
          <Input
            placeholder="$ 0.00"
            disabled={item.tier === 'all'}
            {...getInputPropsForByIndex('tiersDiscounts', 'amount', index)}
            type="number"
          />
        </Field>
      )}
      {item.type === 'percent' && item.tier != 'all' && (
        <Field
          label="Discount"
          className={classnames(
            classes.discount,
            item.tier != 'all' ? 'mt-8' : ''
          )}
          {...getErrorPropsFor('tiers_discounts', 'amount', index)}
          help={helpFixed}>
          <Input
            placeholder="0.00 %"
            {...getInputPropsForByIndex('tiersDiscounts', 'amount', index)}
            min="0"
            max="100"
            type="number"
          />
        </Field>
      )}

      {item.tier !== 'all' && (
        <div className={classes.buttonGroup} role="group">
          <Button
            secondary
            transparent={item.type !== 'fixed'}
            square
            size="sm"
            onClick={(event) => {
              setValues((prev) => ({
                ...prev,
                tiersDiscounts: prev.tiersDiscounts.map((item, i) =>
                  i == index
                    ? {
                        ...item,
                        type: 'fixed',
                      }
                    : item
                ),
              }))
            }}>
            $
          </Button>
          <Button
            secondary
            transparent={item.type !== 'percent'}
            square
            size="sm"
            onClick={(event) => {
              setValues((prev) => ({
                ...prev,
                tiersDiscounts: prev.tiersDiscounts.map((item, i) =>
                  i == index
                    ? {
                        ...item,
                        type: 'percent',
                      }
                    : item
                ),
              }))
            }}>
            %
          </Button>
        </div>
      )}

      <span className="inline-block text-center flex items-center -mr-6 -ml-4 w-28">
        during period
      </span>
      <Field
        label="Start Date"
        {...getErrorPropsFor('tiers_discounts', 'start_at', index)}>
        <DatePicker
          {...getInputPropsForByIndex('tiersDiscounts', 'start_at', index)}
        />
      </Field>
      <span className="flex -mx-6 inline-block items-center">to</span>
      <Field
        label="End Date"
        {...getErrorPropsFor('tiers_discounts', 'end_at', index)}>
        <DatePicker
          {...getInputPropsForByIndex('tiersDiscounts', 'end_at', index)}
        />
      </Field>
      <span className="w-0">
        {index + 1 === values.tiersDiscounts.length && (
          <CloseSVG onClick={handleRemoveCPM} className={classes.removeSVG} />
        )}
      </span>
    </div>
  )
}
