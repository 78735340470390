import useMiniForm from 'core/useMiniForm'
import Button from 'shared/components/kit/button'
import Input from 'shared/components/form/input'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import classes from 'admin/pages/channels/components/Filters.module.css'

export default function Filters({ initialFilters, onSubmit }) {
  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <Input
        {...form.getInputPropsFor('search')}
        placeholder="Search Channels..."
      />
      <HtmlSelect {...form.getInputPropsFor('sort')}>
        <option value="">Sort By...</option>
        <option value="most_uploads">Most Uploads</option>
        <option value="most_subscribers">Most Subscribers</option>
        <option value="most_points">Most Points</option>
        <option value="most_comments">Most Comments</option>
      </HtmlSelect>
      <Button outlined>Search Channels</Button>
    </form>
  )
}
