import EmailVerification from './EmailVerification'
import GoogleAuthenticator from './GoogleAuthenticator'

export default function TwoFactorAuthentication() {
  return (
    <>
      <p className="mb-8">
        By enabling 2FA you will add extra layers of security to your account,
        you may select Email Verification or Google Authenticator or combine
        both for the highest level of security.
      </p>
      <EmailVerification />
      <GoogleAuthenticator />
    </>
  )
}
