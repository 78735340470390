import { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import useQuery from 'core/react-query/useQuery'
import Button from 'shared/components/kit/button'
import Table from 'shared/components/kit/table'
import Page from 'admin/components/page'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import formatDate from 'helpers/utils/formatDate'
import Filters from './components/Filters'
import getAdsCampaign from 'admin/queries/getAdsCampaign'
import BannerAdsCampaignStatus from 'admin/components/banner-ads-campaign-status'
import SlotsCalendar from './components/SlotsCalendar'

export default function AdsManagerCampaigns() {
  const INITIAL_FILTERS = useMemo(
    () => ({
      base: '/admin/ads-manager/banner-ads-campaigns',
      params: {
        page: 'pagination',
        sort: 'any',
        status: 'any',
        search: 'any',
      },
    }),
    []
  )

  const slots = [1, 2, 3, 4, 5]

  const tiers = [
    { index: '1', name: 'Tier1', color: 'green' },
    { index: '2', name: 'Tier2', color: 'blue' },
    { index: '3', name: 'Tier3', color: 'yellow' },
    { index: '4', name: 'Tier4', color: 'purple' },
    { index: '5', name: 'Tier5', color: 'red' },
  ]
  const tiersData = [
    { tier: 'Tier1', date: '2023-06-25T14:54:56.000000Z', prefilled: 1 },
    { tier: 'Tier1', date: '2023-06-26T14:54:56.000000Z', prefilled: 1 },
    { tier: 'Tier1', date: '2023-06-25T14:54:56.000000Z', prefilled: 1 },
    { tier: 'Tier1', date: '2023-06-26T14:54:56.000000Z', prefilled: 1 },
    { tier: 'Tier1', date: '2023-06-27T14:54:56.000000Z', prefilled: 1 },
    { tier: 'Tier2', date: '2023-06-25T14:54:56.000000Z', prefilled: 2 },
    { tier: 'Tier2', date: '2023-06-24T14:54:56.000000Z', prefilled: 2 },
    { tier: 'Tier3', date: '2023-06-27T14:54:56.000000Z', prefilled: 3 },
    { tier: 'Tier3', date: '2023-06-28T14:54:56.000000Z', prefilled: 3 },
    { tier: 'Tier4', date: '2023-06-29T14:54:56.000000Z', prefilled: 4 },
    { tier: 'Tier4', date: '2023-06-28T14:54:56.000000Z', prefilled: 4 },
    { tier: 'Tier5', date: '2023-06-27T14:54:56.000000Z', prefilled: 5 },
    { tier: 'Tier5', date: '2023-06-27T14:54:56.000000Z', prefilled: 5 },
  ]

  const [selectedSlots, setSelectedSlots] = useState([])

  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'))

  const next2Week = dayjs(startDate).add(14, 'day').format('YYYY-MM-DD')
  const prev2Week = dayjs(startDate).add(-14, 'day').format('YYYY-MM-DD')

  const [filters, setFilters] = useFiltersFromURL(INITIAL_FILTERS)

  const { data, error, isError, isFetching } = useQuery(getAdsCampaign, {
    keepPreviousData: true,
    variables: {
      page: filters.page,
      sort: filters.sort,
      status: filters.status,
      search: filters.search,
    },
  })

  const {
    data: campaigns,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleFilter = (nextFilters) => {
    setFilters({
      ...nextFilters,
      page: undefined,
    })
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  const handleClickPrev = () => {
    let today = dayjs().format('YYYY-MM-DD')
    if (prev2Week >= today) {
      setStartDate(prev2Week)
    }
  }

  const handleClickNext = () => {
    setStartDate(next2Week)
  }

  const actions = (
    <Button
      component="link"
      to="/admin/ads-manager/banner-ads-campaigns/create">
      Create new campaign
    </Button>
  )

  if (isError) {
    return <Page error={error.message} />
  }

  return (
    <Page title="Campaigns" actions={actions} description={description}>
      <Filters filters={filters} onSubmit={handleFilter} />
      <Table
        columns={getColumns(filters)}
        data={campaigns}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
      <h3 className="text-overlayColor-mutedText mt-12">Calendar Overview</h3>
      <SlotsCalendar
        selectedSlots={selectedSlots}
        tiersData={tiersData}
        startDate={startDate}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        slots={slots}
        tiers={tiers}
        minValidDate={prev2Week}
      />
    </Page>
  )
}

function getColumns(filters) {
  return [
    {
      title: 'Campaign ID',
      render: (campaign) => campaign.id,
    },
    {
      title: 'Company name',
      render: (campaign) => campaign.company.name,
    },
    {
      title: 'Customer ID',
      render: (campaign) => campaign.company.id,
    },
    {
      title: 'Campaign name',
      render: (campaign) => campaign.name,
    },
    {
      title: 'Reach',
      render: () => 0,
    },
    {
      title: 'CT',
      render: () => 0,
    },
    {
      title: 'Status',
      render: (campaign) => (
        <BannerAdsCampaignStatus status={campaign.status} />
      ),
    },
    {
      title: 'Creation Date',
      render: (campaign) => formatDate(campaign.created_at),
    },
    {
      render: (campaign) => (
        <Button
          component="link"
          to={`/admin/ads-manager/banner-ads-campaigns/${campaign.id}${
            filters.page > 1 ? `?page=${filters.page}` : ''
          }/edit`}
          secondary
          size="sm">
          Edit
        </Button>
      ),
    },
  ]
}
