import { useMemo } from 'react'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import Button from 'shared/components/kit/button'
import notification from 'shared/components/kit/notification'
import DatePicker from 'shared/components/form/datepicker'
import updateUser from 'admin/queries/updateUser'
import getUser from 'admin/queries/getUser'
import getUsers from 'admin/queries/getUsers'

export default function HeroMembership({ user }) {
  const queryClient = useQueryClient()

  const mutation = useMutation(updateUser, {
    onSuccess: (response) => {
      queryClient.invalidateQueries({ query: getUsers })
      queryClient.setQueryData({
        query: getUser,
        variables: {
          id: user.id,
        },
        updater: (prev) => ({
          ...prev,
          json: {
            ...prev.json,
            ...response.json,
          },
        }),
      })
    },
  })

  const initialValues = useMemo(
    () => ({
      hero_due_at: user.hero_due_at,
    }),
    [user.hero_due_at]
  )

  const form = useMiniForm({
    initialValues,
    async onSubmit(fields) {
      try {
        await mutation.mutateAsync({
          id: user.id,
          fields,
        })
        notification.success({
          title: 'Make user as hodl successfully!',
        })
      } catch (error) {
        notification.error({
          title: error.message,
        })
      }
    },
  })

  return (
    <>
      <p className="mb-4">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
      <form onSubmit={form.submit}>
        <div className="flex space-x-2">
          <DatePicker
            {...form.getInputPropsFor('hero_due_at')}
            placeholder="Membership end date..."
          />
          <Button loading={form.submitting} type="submit">
            Change Due Date
          </Button>
        </div>
      </form>
    </>
  )
}
