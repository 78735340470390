import { useMemo, useState } from 'react'
import useQuery from 'core/react-query/useQuery'
import getTokensHistory from 'admin/queries/getTokensHistory'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import formatDate from 'helpers/utils/formatDate'
import Alert from 'shared/components/kit/alert'
import Table from 'shared/components/kit/table'

const INITIAL_FILTERS = {
  page: 1,
  search: '',
}

export default function EarningsHistory({ userId }) {
  const [filters, setFilters] = useState(INITIAL_FILTERS)

  const { data, error, isError, isFetching } = useQuery(getTokensHistory, {
    keepPreviousData: true,
    enabled: !!userId,
    variables: {
      page: filters.page,
      userId,
    },
  })

  const {
    data: tokensHistory,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const columns = useMemo(() => getColumns(), [])

  if (isError) {
    return <Alert title={error} content={error?.message} type="error" />
  }

  return (
    <Table
      columns={columns}
      data={tokensHistory}
      loading={isFetching}
      currentPage={currentPage}
      lastPage={lastPage}
      onPaginate={handlePaginate}
    />
  )
}

function getColumns() {
  return [
    {
      title: 'Tokens Earned',
      render: (history) => history.amount,
    },
    {
      title: 'Action',
      render: (history) => history.type.replaceAll('_', ' '),
    },
    {
      title: 'Earned Date',
      render: (history) => formatDate(history.date),
    },
  ]
}
