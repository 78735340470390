import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import Button from 'shared/components/kit/button'
import readableNumber from 'shared/helpers/readableNumber'
import useProfile from 'shared/hooks/useProfile'
import useUserWalletAssets from 'shared/hooks/useUserWalletAssets'
import Page from 'publisher/components/page'
import CircleProgress from 'publisher/components/progress/CircleProgress'
import HowItWorksModal from 'publisher/components/how-it-works-modal'
import MonetizationTiersHowItWorksModal from 'publisher/components/monetization-tiers-how-it-works-modal'
import getDashboardData from 'publisher/queries/getDashboardData'
import getDashboardOverviewData from 'publisher/queries/getDashboardOverviewData'
import Charts from './components/Charts'
import Summary from './components/Summary'
import formatPrice from 'helpers/utils/formatPrice'
import { ReactComponent as TCGSVG } from 'svg/tcg-monetization-tiers-index.svg'
import classes from './index.module.css'

export default function Monetize() {
  const { user } = useProfile()
  const [howItWorksVisible, setHowItWorksVisible] = useState(false)
  const [
    monetizationTiersHowItWorksVisible,
    setMonetizationTiersHowItWorksVisible,
  ] = useState(false)

  const { data } = useQuery(getDashboardData, {
    usePreviousData: true,
  })

  const overviewData = useQuery(getDashboardOverviewData)

  const subscribers = get(overviewData, 'data.json.subscribers_total') || 0
  const watchTime =
    (get(overviewData, 'data.json.watch_time_total') || 0) / 3600 || 0

  const watchTimePercentage = ((watchTime / 2000) * 100).toFixed(2)
  const subscribersPercentage = ((subscribers / 500) * 100).toFixed(2)

  const monetizeQualification = user.channel.monetization_qualified_at

  const handleCloseHowItWorks = () => {
    setHowItWorksVisible(false)
  }

  const handleCloseMonetizationTiersHowItWorksVisible = () => {
    setMonetizationTiersHowItWorksVisible(false)
  }

  const { data: profileData } = useProfile()

  const walletAddress = profileData.json?.eth_address

  const wallet = useUserWalletAssets(walletAddress)

  const myHoldingsIsLoading = wallet.isLoading
  const myHoldings = Number(wallet.data?.tcg || 0)

  const links = (
    <>
      {user.channel.monetization_qualified_at && (
        <>
          <Button component="link" to="/publisher/monetize" mood="neutral">
            Points & Earn
          </Button>
          <Button
            component="link"
            to="/publisher/payment-details"
            outlined
            secondary>
            Payment Details
          </Button>
          <Button component="link" to="/publisher/payout" outlined secondary>
            Payout History
          </Button>
        </>
      )}
      <Button onClick={() => setHowItWorksVisible(true)}>How it works?</Button>
    </>
  )

  return (
    <Page actions={links} title="Monetize" width="xl">
      <div className="relative">
        <Summary />
        <Charts />
        {!monetizeQualification && (
          <div className={classes.qualificationModal}>
            <div>
              <span>Subscribers</span>
              <small>{readableNumber(subscribers)}/500</small>
              <CircleProgress
                stroke={12}
                value={subscribers >= 500 ? 100 : subscribersPercentage}
                secondaryColor="overlayColor-default"
                primaryColor={
                  subscribers >= 500 ? 'successColor-default' : 'primary'
                }
              />
            </div>
            <div>
              <span>Watch Hours</span>
              <small>{readableNumber(watchTime)}/2000</small>
              <CircleProgress
                stroke={12}
                value={watchTime >= 2000 ? 100 : watchTimePercentage}
                secondaryColor="overlayColor-default"
                primaryColor={
                  watchTime >= 2000 ? 'successColor-default' : 'primary'
                }
              />
            </div>
            <div className="w-full -mt-64">
              <Link to="/publisher/invite-earn">
                <Button secondary>Invite your audience</Button>
              </Link>
            </div>
          </div>
        )}

        <div className="flex items-center mb-10 mt-14">
          <h1 className="text-2xl inline mr-4 flex-grow">
            Monetization Tiers
            {!myHoldingsIsLoading && myHoldings < 1000000 && (
              <span className="text-primary text-xl mx-4">
                <span className="h-4 w-4 px-2.5 py-0.5 font-bold rounded-full border-2 border-primary mr-1">
                  i
                </span>
                <span>
                  You need an additional{' '}
                  {formatPrice(1000000 - myHoldings, false, 0)} TCG tokens to
                  monetizing your content
                </span>
              </span>
            )}
          </h1>
          <div className="justify-end">
            <Button
              onClick={() => setMonetizationTiersHowItWorksVisible(true)}
              secondary>
              How it works?
            </Button>
          </div>
        </div>

        <div className={classes.monetizationTiers}>
          <div className={classes.tiersTitles}>
            <div className={classes.monetizationTierTitle}>
              <p>None Qualified</p>
              <p>0 TCG</p>
            </div>
            <div className={classes.monetizationTierTitle}>
              <p>Tier 5</p>
              <p>1M TCG</p>
            </div>
            <div className={classes.monetizationTierTitle}>
              <p>Tier 4</p>
              <p>1.5M TCG</p>
            </div>
            <div className={classes.monetizationTierTitle}>
              <p>Tier 3</p>
              <p>2M TCG</p>
            </div>
            <div className={classes.monetizationTierTitle}>
              <p>Tier 2</p>
              <p>2.5M TCG</p>
            </div>
            <div className={classes.monetizationTierTitle}>
              <p>Tier 1</p>
              <p>3M TCG</p>
            </div>
          </div>

          <div className={classes.tiersProgressBar}>
            <div className={classes.monetizationTierBar}>
              {!myHoldingsIsLoading && myHoldings < 1000000 && (
                <MonetizationTierIndex holdings={myHoldings} />
              )}
            </div>

            <div className={classes.monetizationTierBar}>
              {!myHoldingsIsLoading &&
                myHoldings >= 1000000 &&
                myHoldings < 1500000 && (
                  <MonetizationTierIndex holdings={myHoldings} />
                )}
            </div>

            <div className={classes.monetizationTierBar}>
              {!myHoldingsIsLoading &&
                myHoldings >= 1500000 &&
                myHoldings < 2000000 && (
                  <MonetizationTierIndex holdings={myHoldings} />
                )}
            </div>
            <div className={classes.monetizationTierBar}>
              {!myHoldingsIsLoading &&
                myHoldings >= 2000000 &&
                myHoldings < 2500000 && (
                  <MonetizationTierIndex holdings={myHoldings} />
                )}
            </div>
            <div className={classes.monetizationTierBar}>
              {!myHoldingsIsLoading &&
                myHoldings >= 2500000 &&
                myHoldings < 3000000 && (
                  <MonetizationTierIndex holdings={myHoldings} />
                )}
            </div>
          </div>
        </div>
      </div>
      <HowItWorksModal
        visible={howItWorksVisible}
        onClose={handleCloseHowItWorks}
      />
      <MonetizationTiersHowItWorksModal
        visible={monetizationTiersHowItWorksVisible}
        onClose={handleCloseMonetizationTiersHowItWorksVisible}
      />
    </Page>
  )
}

export function MonetizationTierIndex({ holdings }) {
  const monetizationTierIndexPosition =
    ((holdings <= 1000000 ? holdings : holdings % 1000000) /
      (holdings <= 1000000 ? 1000000 : 500000)) *
    88

  const monetizationProgressIndexStyles = {
    left: monetizationTierIndexPosition - 5 + '%',
    textAlign: 'center',
  }

  return (
    <div className={classes.monetizationIndex}>
      <div style={monetizationProgressIndexStyles}>
        <span>
          <TCGSVG />
        </span>
        <p>
          {holdings > 10000
            ? formatPrice(holdings / 1000000, null, 2) + 'M'
            : 0}{' '}
          TCG
        </p>
      </div>
    </div>
  )
}
