type GetReferralStatistics = {
  statisticsPeriod?:
    | 'last_7d'
    | 'last_14d'
    | 'last_30d'
    | 'last_90d'
    | 'last_180d'
    | 'last_365d',
}

export default function getReferralStatistics(
  variables: GetReferralStatistics = {}
) {
  const { statisticsPeriod } = variables

  return {
    path: '/publisher/monetize/referral-statistics',
    params: {
      filters: {
        statistics_period: statisticsPeriod,
      },
    },
  }
}
