import getFileAbsoluteUrl from 'core/getFileAbsoluteUrl'
import formatDate from 'helpers/utils/formatDate'
import Box from 'shared/components/kit/box'
import readableNumber from 'shared/helpers/readableNumber'
import ChannelStatus from 'admin/components/channel-status'
import classes from './Summary.module.css'

export default function Summary({ channel }) {
  const avatar = getFileAbsoluteUrl(
    channel.avatar,
    '/channel-default-avatar.png'
  )

  const socialLinks = [
    { label: 'Facebook', url: channel.facebook },
    { label: 'Instagram', url: channel.instagram },
    { label: 'Twitter', url: channel.twitter },
    { label: 'Telegram', url: channel.telegram },
    { label: 'LinkedIn', url: channel.linkedin },
    { label: 'Reddit', url: channel.reddit },
    { label: 'TikTok', url: channel.tiktok },
  ].filter((item) => item.url)

  return (
    <Box className={classes.wrapper}>
      <div className={classes.channel}>
        <img alt={channel.name} src={avatar} />
        <div>
          <h2 className="mb-2">
            {channel.name}
            &nbsp;&nbsp;
            <ChannelStatus status={channel.status} />
          </h2>
          <ul className={classes.channelMeta}>
            {channel.owner ? (
              <li>
                Publisher: {channel.owner.username} ({channel.owner.email})
              </li>
            ) : null}
            {socialLinks.length > 0 && (
              <li className={classes.socialLinks}>
                Social Links: &nbsp;&nbsp;
                {socialLinks.map((item, key) => (
                  <>
                    <a
                      key={key}
                      href={item.url}
                      target="_blank"
                      rel="noreferrer">
                      {item.label}
                    </a>
                    {key === socialLinks.length - 1 ? null : <>, &nbsp;</>}
                  </>
                ))}
              </li>
            )}
            <li>Creation Date: {formatDate(channel.created_at)}</li>
          </ul>
        </div>
      </div>

      <ul className={classes.stats}>
        <li>
          <h4>
            Total
            <br />
            Subscribers
          </h4>
          <strong>{channel.subscribers_count}</strong>
        </li>
        <li>
          <h4>
            Hodl
            <br />
            Subscribers
          </h4>
          <strong>{channel.hero_subscribers_count}</strong>
        </li>
        <li>
          <h4>
            Total
            <br />
            Likes
          </h4>
          <strong>{channel.total_likes}</strong>
        </li>
        <li>
          <h4>
            Total
            <br />
            Dislikes
          </h4>
          <strong>{channel.total_dislikes}</strong>
        </li>
        <li>
          <h4>
            Total
            <br />
            Comments
          </h4>
          <strong>{channel.comments_count}</strong>
        </li>
        <li>
          <h4>
            Watch
            <br />
            Times
          </h4>
          <strong>{readableNumber((channel.watch_time || 0) / 3600)}</strong>
        </li>
      </ul>
    </Box>
  )
}
