type magicVariables = {
  token: string,
}

export default function MagicLoginVerification(variables: magicVariables) {
  return {
    method: 'post',
    path: `/login/magic/${variables.token}`,
  }
}
