type GetMyChannelEarnings = {
  status: string,
  includeUser?: boolean,
  includeTransaction?: boolean,
  page?: number,
}

export default function getMyChannelEarnings(
  variables: GetMyChannelEarnings = {}
) {
  const { status, includeUser, includeTransaction, page } = variables

  const filters = {}
  const includes = []
  if (status) {
    filters.status = status
  }

  if (includeTransaction) {
    includes.push('transaction')
  }

  if (includeUser) {
    includes.push('user')
  }

  return {
    name: 'myChannelEarnings',
    path: '/publisher/monetization/payouts',
    params: {
      page: page > 0 ? page : undefined,
      filters,
      include: includes.length ? includes.join(',') : undefined,
    },
  }
}
