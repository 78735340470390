export default function getOverlayErrors(overlays) {
  return overlays.reduce((errors, overlay) => {
    overlays.forEach((target) => {
      if (overlay.id !== target.id && overlay.position === target.position) {
        if (
          (overlay.start > target.start && overlay.start < target.end) ||
          (overlay.end > target.start && overlay.end < target.end)
        ) {
          errors[overlay.id] = true
        }
      }
    })
    return errors
  }, {})
}
