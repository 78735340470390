import { useLayoutEffect } from 'react'

export default function useClickOutside(ref, callback) {
  useLayoutEffect(() => {
    const handleClick = (e) => {
      if (ref && ref.current && !ref.current.contains(e.target)) {
        callback()
      }
    }

    document.addEventListener('click', handleClick, {
      capture: true,
    })
    return () => {
      document.removeEventListener('click', handleClick, {
        capture: true,
      })
    }
  }, [ref, callback])
}
