type GetPayoutItemsQueryVariables = {
  page?: number,
  status?: string,
  month?: string,
}

export default function getQualifiedChannels(
  variables: GetPayoutItemsQueryVariables = {}
) {
  const { page, status, month } = variables
  return {
    name: 'monetizationQualifiedForAdmin',
    path: '/admin/monetization/qualified-channels',
    params: {
      page,
    },
  }
}
