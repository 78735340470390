import Box from 'shared/components/kit/box'
import Button from 'shared/components/kit/button'

export default function EditChannel({ channel }) {
  return (
    <Box className="p-6">
      <h4 className="mb-2">Edit Channel Info</h4>
      <p className="h-16 text-overlayColor-mutedText">
        In this area, you can change this channel's info.
      </p>
      <footer className="text-right">
        <Button
          component="link"
          size="sm"
          to={`/admin/channels/${channel.id}/edit`}
          outlined
          secondary>
          Edit Channel Info
        </Button>
      </footer>
    </Box>
  )
}
