import { useState } from 'react'
import useMiniForm from 'core/useMiniForm'
import useMutation from 'core/react-query/useMutation'
import useQueryClient from 'core/react-query/useQueryClient'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import createPlaylist from 'publisher/mutations/createPlaylist'
import getMyPlaylists from 'publisher/queries/getMyPlaylists'

const VALIDATION = {
  name: {
    label: 'Playlist Name',
    rule: 'required',
  },
}

export default function CreatePlaylistButton() {
  const [visible, setVisible] = useState(false)

  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(createPlaylist, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        query: getMyPlaylists,
      })
    },
  })

  const form = useMiniForm({
    resetOnSuccess: true,
    validation: VALIDATION,
    async onSubmit(values) {
      try {
        await mutateAsync(values)
        setVisible(false)
      } catch (error) {
        throw error
      }
    },
  })

  const handleCancel = () => {
    setVisible(false)
  }

  const footer = (
    <>
      <Button onClick={handleCancel}>Cancel</Button>
      <Button loading={form.submitting} onClick={form.submit}>
        Create Playlist
      </Button>
    </>
  )

  return (
    <>
      <Button onClick={() => setVisible(true)} outlined>
        New Playlist
      </Button>

      <Modal
        okText="Create Playlist"
        onCancel={handleCancel}
        onOk={form.submit}
        size="sm"
        submitting={form.submitting}
        title="Create a Playlist"
        visible={visible}>
        <Field label="Playlist Name" {...form.getErrorPropsFor('name')}>
          <Input {...form.getInputPropsFor('name')} />
        </Field>
      </Modal>
    </>
  )
}
