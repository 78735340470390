import Button from 'shared/components/kit/button'
import classes from './Subscribe.module.css'

export default function Subscribe() {
  return (
    <Button className={classes.button} outlined>
      Subscribe
    </Button>
  )
}
