import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import env from 'core/env'
import Logo from 'shared/components/logo'
import Footer from 'shared/components/footer'
import useForceColorScheme from 'shared/hooks/useForceColorScheme'
import Icon from 'shared/components/kit/icon'
import { ReactComponent as LinkedinSVG } from 'icons/linkedin.svg'
import { ReactComponent as CloseSVG } from 'icons/close.svg'
import { ReactComponent as MenuSVG } from 'icons/menu.svg'
import classes from './index.module.css'

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}

export default function About() {
  useForceColorScheme('dark')
  const location = useLocation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [location])

  const triggerMenu = () => {
    setIsMenuOpen((prev) => !prev)
  }

  return (
    <div className={classes.wrapper}>
      <div>
        <ul className={classes.nav}>
          <li className={classes.logo}>
            <Link to="/">
              <div>
                <Logo forceDarkMode={true} />
              </div>
            </Link>
          </li>
          {(isMenuOpen || windowSize.innerWidth > 768) && (
            <>
              <li>
                <Link to="/#want-to-know-more">Want to know more?</Link>
              </li>
              <li>
                <Link to="/landings/monetize">Monetize</Link>
              </li>
              <li>
                <Link
                  to={{ pathname: env('REACT_APP_END_USER_LANDING_URL') }}
                  target="_blank">
                  User Signup
                </Link>
              </li>
            </>
          )}
          <li className={classes.menu}>
            <a onClick={triggerMenu}>
              <Icon>{isMenuOpen === true ? <CloseSVG /> : <MenuSVG />}</Icon>
            </a>
          </li>
        </ul>
        <div className={classes.body}>
          <h1>About us.</h1>
          <p>
            Meet Today’s Crypto, a place where independent content creators can
            share crypto market news, updates, podcasts, and just about anything
            within the crypto sphere, and where everything is packaged in an
            intuitive and user-friendly interface. Build for like-minded
            individuals who believe crypto is the future and expect only the
            highest quality information. Today’s Crypto is reinventing how
            crypto news is consumed and has been created with a focus on the
            creators themselves. We believe that, like Twitch, niche streaming
            services can be successful for those who have a real and genuine
            interest.
          </p>
          <h2>Mission.</h2>
          <p>
            To entertain, educate and inspire crypto enthusiasts around the
            world.
          </p>
          <h2>Vision.</h2>
          <p>
            Become the world’s leading and safest platform for streamed crypto
            content.
          </p>
          <section className={classes.team}>
            <h2>The team.</h2>
            <ul>
              <li>
                <img src="/images/about/founders-avatars-luke.png" alt="" />
                <span> Luke Harte</span>
                <small>CEO</small>
                <a
                  href="https://www.linkedin.com/in/lukeharte/"
                  target="_blank">
                  <LinkedinSVG />
                </a>
              </li>
              <li>
                <img src="/images/about/founders-avatars-robert.png" alt="" />
                <span> Robert Widerberg </span>
                <small>COO</small>
                <a
                  href="https://www.linkedin.com/in/robert-widerberg-942ab81a3/"
                  target="_blank">
                  <LinkedinSVG />
                </a>
              </li>
              <li>
                <img src="/images/about/founders-avatars-filip.png" alt="" />
                <span> Filip Widerberg </span>
                <small>CPO</small>
                <a
                  href="https://www.linkedin.com/in/filip-widerberg-3867b2159/"
                  target="_blank">
                  <LinkedinSVG />
                </a>
              </li>
              <li>
                <img
                  src="/images/about/founders-avatars-kristoffer.png"
                  alt=""
                />
                <span> Kristoffer Ling </span>
                <small>AUDIT</small>
              </li>
              <li>
                <img src="/images/about/dev-avatars-amir.png" alt="" />
                <span> Amir </span>
                <small>Dev lead/Back end</small>
              </li>
              <li>
                <img src="/images/about/dev-avatars-mehdi.png" alt="" />
                <span> Mehdi </span>
                <small>Front end dev</small>
              </li>
              <li>
                <img src="/images/about/dev-avatars-peyman.png" alt="" />
                <span> Peyman </span>
                <small>Front end dev</small>
              </li>
              <li>
                <img src="/images/about/dev-avatars-ali.png" alt="" />
                <span> Ali</span>
                <small>Back end dev</small>
              </li>
            </ul>
          </section>
        </div>
      </div>
      <div className={classes.footerContainer}>
        <Footer />
      </div>
    </div>
  )
}
