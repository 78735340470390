// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".channel_wrapper__2lSlB {\n  padding: 2rem;\n  max-width: 800px;\n}\n\n.channel_avatar__3rEcp {\n  position: absolute;\n  top: 0px;\n  left: 2rem;\n  width: 10rem;\n}\n\n.channel_otherFields__2_02T {\n  padding-left: 14rem;\n  position: relative;\n}\n\n.channel_summary__N_MGK {\n  resize: none;\n  min-height: 4rem !important;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/channel/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EDAjB,gBAAgB;AAClB;;AAEA;ECHA,kBAAmB;EAAnB,QAAmB;EAAnB,UAAmB;EAAnB,YAAmB;ADKnB;;AAEA;ECPA,mBAAmB;EAAnB,kBAAmB;ADSnB;;AAEA;EACE,YAAY;EACZ,2BAA2B;AAC7B","sourcesContent":[".wrapper {\n  @apply p-8;\n  max-width: 800px;\n}\n\n.avatar {\n  @apply absolute left-8 top-0 w-40;\n}\n\n.otherFields {\n  @apply pl-56 relative;\n}\n\n.summary {\n  resize: none;\n  min-height: 4rem !important;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "channel_wrapper__2lSlB",
	"avatar": "channel_avatar__3rEcp",
	"otherFields": "channel_otherFields__2_02T",
	"summary": "channel_summary__N_MGK"
};
export default ___CSS_LOADER_EXPORT___;
