import { useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import Box from 'shared/components/kit/box'
import EarningsChart from './EarningsChart'
import PointsChart from './PointsChart'
import useChannelMonthlyPerformance from '../hooks/useChannelMonthlyPerformance'

export default function Charts() {
  const { data } = useChannelMonthlyPerformance()

  const labels = useMemo(() => {
    return data.map((item) => dayjs(item.date).format('MMM'))
  }, [data])

  const getDate = useCallback((date) => dayjs(date).format('MMMM YYYY'), [])

  return (
    <Box className="grid grid-cols-2 gap-6 mb-6 p-6">
      <PointsChart data={data} labels={labels} getDate={getDate} />
      <EarningsChart data={data} labels={labels} getDate={getDate} />
    </Box>
  )
}
