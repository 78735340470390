import get from 'lodash/get'

export default function getPaginatedDataFromAPI(response) {
  const meta = {
    currentPage: get(response, 'meta.current_page'),
    lastPage: get(response, 'meta.last_page'),
    total: get(response, 'meta.total'),
    data: get(response, 'data', []),
  }

  if (meta.total >= 1) {
    meta.description =
      meta.total === 1 ? 'Found 1 item.' : `Found ${meta.total} items.`
  } else {
    meta.description = 'No item found.'
  }

  return meta
}
