type ContentMutationVariables = {
  id: number,
  key: string,
  content: string,
  status: string,
}

export default function editContents(variables: ContentMutationVariables = {}) {
  const { id, key, content, status } = variables
  return {
    method: 'PUT',
    path: `/admin/contents/${id}`,
    params: {
      key,
      content,
      status,
    },
  }
}
