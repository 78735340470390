import HtmlSelect from 'shared/components/form/select/HtmlSelect'

export default function Filters({ range, onChange }) {
  return (
    <HtmlSelect value={range} onChange={onChange}>
      <option value="this_week">This Week</option>
      <option value="last_week">Last Week</option>
      <option value="this_month">This Month</option>
      <option value="last_month">Last Month</option>
      <option value="this_year">This Year</option>
    </HtmlSelect>
  )
}
