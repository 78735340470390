import UploadTab from './UploadTab'

export default function UploadTabs({ selectedSlots }) {
  return (
    <>
      {selectedSlots.length === 0 && (
        <div className="flex items-center justify-center h-96 text-primary shadow-2xl">
          To see<b>&nbsp;Upload and Configure&nbsp;</b>section, you should
          select a slot from<b>&nbsp;Slots Calendar&nbsp;</b>
        </div>
      )}
      {selectedSlots.find((slot) => slot.tier.toLowerCase() === 'tier1') && (
        <UploadTab
          adLocationText="Header banner on the Home page"
          adItemDesktop="home-page-header-desktop"
          adItemMobile="home-page-header-mobile"
          adItemDesktopTitle="Recommended size: 2250 x 250 px"
          adItemMobileTitle="Required size: 290 x 250 px"
          adItemWidthDesktop={686}
          adItemWidthMobile={230}
          adItemHeightDesktop={57}
          adItemHeightMobile={190}
          tierName="tier 1"
          uploaderText="Uploading either one or two images. if two images are
                    uploaded they will switch according to the time set in the
                    configuration section."
        />
      )}
      {selectedSlots.find((slot) => slot.tier.toLowerCase() === 'tier2') && (
        <UploadTab
          adLocationText="Header banner on the Home page"
          adItemDesktop="home-page-header-desktop"
          adItemMobile="home-page-header-mobile"
          adItemDesktopTitle="Recommended size: 2250 x 250 px"
          adItemMobileTitle="Required size: 290 x 250 px"
          adItemWidthDesktop={686}
          adItemWidthMobile={230}
          adItemHeightDesktop={57}
          adItemHeightMobile={190}
          tierName="tier 2"
          uploaderText="Uploading either one or two images. if two images are
                    uploaded they will switch according to the time set in the
                    configuration section."
        />
      )}
      {selectedSlots.find((slot) => slot.tier.toLowerCase() === 'tier3') && (
        <UploadTab
          adLocationText="Header banner on the Home page"
          adItemDesktop="home-page-header-desktop"
          adItemMobile="home-page-header-mobile"
          adItemDesktopTitle="Recommended size: 2250 x 250 px"
          adItemMobileTitle="Required size: 290 x 250 px"
          adItemWidthDesktop={686}
          adItemWidthMobile={230}
          adItemHeightDesktop={57}
          adItemHeightMobile={190}
          tierName="tier 3"
          uploaderText="Uploading either one or two images. if two images are
                    uploaded they will switch according to the time set in the
                    configuration section."
        />
      )}
      {selectedSlots.find((slot) => slot.tier.toLowerCase() === 'tier4') && (
        <UploadTab
          adLocationText="Header banner on the Home page"
          adItemDesktop="home-page-header-desktop"
          adItemMobile="home-page-header-mobile"
          adItemDesktopTitle="Recommended size: 2250 x 250 px"
          adItemMobileTitle="Required size: 290 x 250 px"
          adItemWidthDesktop={686}
          adItemWidthMobile={230}
          adItemHeightDesktop={57}
          adItemHeightMobile={190}
          tierName="tier 4"
          uploaderText="Uploading either one or two images. if two images are
                    uploaded they will switch according to the time set in the
                    configuration section."
        />
      )}
      {selectedSlots.find((slot) => slot.tier.toLowerCase() === 'tier5') && (
        <UploadTab
          adLocationText="Header banner on the Home page"
          adItemDesktop="home-page-header-desktop"
          adItemMobile="home-page-header-mobile"
          adItemDesktopTitle="Recommended size: 2250 x 250 px"
          adItemMobileTitle="Required size: 290 x 250 px"
          adItemWidthDesktop={686}
          adItemWidthMobile={230}
          adItemHeightDesktop={57}
          adItemHeightMobile={190}
          tierName="tier 5"
          uploaderText="Uploading either one or two images. if two images are
                    uploaded they will switch according to the time set in the
                    configuration section."
        />
      )}
    </>
  )
}
