// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".invite-earn_actions__2QgbJ {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 1rem;\n  gap: 1rem;\n}\n\n.invite-earn_referral__Rvjbj {\n  display: flex;\n  margin-top: 6rem;\n  gap: 2rem;\n}\n\n.invite-earn_referral__Rvjbj .invite-earn_code__2rPJM {\n  display: flex;\n  flex-direction: column;\n  width: 40%;\n}\n\n.invite-earn_referral__Rvjbj .invite-earn_text__1ucKG {\n  border-color: var(--light-1);\n}\n\n.dark .invite-earn_referral__Rvjbj .invite-earn_text__1ucKG {\n  border-color: var(--dark-2);\n}\n\n.invite-earn_referral__Rvjbj .invite-earn_text__1ucKG {\n  border-radius: 0.75rem;\n  border-width: 2px;\n  height: 10rem;\n  overflow: auto;\n  padding: 1rem;\n  width: 60%;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/pages/invite-earn/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,aAAmB;EAAnB,yBAAmB;EAAnB,mBAAmB;EAAnB,SAAmB;ADAnB;;AAEA;ECFA,aAAmB;EAAnB,gBAAmB;EAAnB,SAAmB;ADInB;;AAEA;ECNA,aAAmB;EAAnB,sBAAmB;EAAnB,UAAmB;ADQnB;;AAEA;ECVA,4BAAmB;ADYnB;;ACZA;EAAA;AAAmB;;AAAnB;EAAA,sBAAmB;EAAnB,iBAAmB;EAAnB,aAAmB;EAAnB,cAAmB;EAAnB,aAAmB;EAAnB;AAAmB","sourcesContent":[".actions {\n  @apply flex gap-4 justify-end mb-4;\n}\n\n.referral {\n  @apply flex gap-8 mt-24;\n}\n\n.referral .code {\n  @apply flex flex-col w-2/5;\n}\n\n.referral .text {\n  @apply dark:border-dark-2 border-light-1 border-2 p-4 rounded-xl h-40 overflow-auto w-3/5;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "invite-earn_actions__2QgbJ",
	"referral": "invite-earn_referral__Rvjbj",
	"code": "invite-earn_code__2rPJM",
	"text": "invite-earn_text__1ucKG",
	"dark": "dark"
};
export default ___CSS_LOADER_EXPORT___;
