import React from 'react'
import classes from './index.module.css'
export default function RangeInput({ handleChange, value, step, min, max }) {
  return (
    <div className={classes.wrapper}>
      <input
        step={step}
        min={min}
        max={max}
        onChange={handleChange}
        type="range"
        value={value}
      />
    </div>
  )
}
