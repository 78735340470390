type GetChannelQueryVariables = {
  id: number,
  includeOwner: boolean,
}

const DEFAULT_VARIABLES = {
  includeOwner: true,
}

export default function getChannel(
  variables: GetChannelQueryVariables = DEFAULT_VARIABLES
) {
  const { id, includeOwner } = variables

  const includes = []

  if (includeOwner) {
    includes.push('owner')
  }

  return {
    name: 'channel',
    path: `/admin/channels/${id}`,
    params: {
      include: includes.length ? includes.join(',') : undefined,
    },
  }
}
