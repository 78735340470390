// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reset-password_wrapper__159N4 {\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 12rem;\n  width: 24rem;\n}\n\n.reset-password_wrapper__159N4 h1 {\n  font-family: Ubuntu, sans-serif;\n  font-size: 1.5rem;\n  line-height: 2rem;\n  margin-bottom: 2rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/reset-password/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,iBAAmB;EAAnB,kBAAmB;EAAnB,iBAAmB;EAAnB,YAAmB;ADAnB;;AAEA;ECFA,+BAAmB;EAAnB,iBAAmB;EAAnB,iBAAmB;EAAnB,mBAAmB;ADInB","sourcesContent":[".wrapper {\n  @apply mx-auto mt-48 w-96;\n}\n\n.wrapper h1 {\n  @apply font-heading text-2xl mb-8;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "reset-password_wrapper__159N4"
};
export default ___CSS_LOADER_EXPORT___;
