import { useQuery as useReactQuery } from 'react-query'
import useFetcher from './useFetcher'

export default function useQuery(query, options?) {
  const { variables, ...otherOptions } = options || {}
  const { name, url } = query(variables)
  const fetcher = useFetcher()
  const get = async () => {
    try {
      return await fetcher(url)
    } catch (error) {
      throw error
    }
  }
  const qr = useReactQuery(name, get, otherOptions)
  return {
    ...qr,
    name,
  }
}
