// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdItem_wrapper__1oUxk {\n  border-bottom-width: 1px;\n  display: flex;\n  align-items: flex-end;\n  justify-content: space-between;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n}\n\n.AdItem_status__rYXiZ {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  margin-top: 0.5rem;\n  margin-bottom: 0.75rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/ads-manager/banner-ads/components/AdItem.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,wBAAmB;EAAnB,aAAmB;EAAnB,qBAAmB;EAAnB,8BAAmB;EAAnB,iBAAmB;EAAnB,oBAAmB;ADAnB;;AAEA;ECFA,mBAAmB;EAAnB,oBAAmB;EAAnB,kBAAmB;EAAnB,sBAAmB;ADInB","sourcesContent":[".wrapper {\n  @apply border-b flex justify-between items-end py-4;\n}\n\n.status {\n  @apply text-sm mb-3 mt-2;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AdItem_wrapper__1oUxk",
	"status": "AdItem_status__rYXiZ"
};
export default ___CSS_LOADER_EXPORT___;
