import { useMemo } from 'react'
import useQuery from 'core/react-query/useQuery'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import formatDate from 'helpers/utils/formatDate'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import getTags from 'admin/queries/getTags'
import CreateTagButton from './components/CreateTagButton'
import TagStatus from 'shared/components/tag-status'
import Table from 'shared/components/kit/table'
import PageHeader from 'admin/components/page-header'
import Page from 'admin/components/page'
import Filters from './components/Filters'
import UpdateTagModal from './components/UpdateTagModal'
import DeleteTagButton from './components/DeleteTagButton'
import { useParams } from 'react-router'

export default function Tags() {
  const params = useParams()

  const tagId = Number(params.tagId)

  const INITIAL_FILTERS = useMemo(
    () => ({
      base: '/admin/tags',
      params: {
        page: 'pagination',
        sort: 'any',
        search: 'any',
        status: 'published | delisted',
        includeFavoriteCount: true,
        includeVideoCount: true,
      },
    }),
    []
  )
  const [filters, setFilters] = useFiltersFromURL(INITIAL_FILTERS)
  const { data, error, isError, isFetching } = useQuery(getTags, {
    keepPreviousData: true,
    variables: {
      includeFavoriteCount: true,
      includeVideoCount: true,
      page: filters.page,
      status: filters.status,
      search: filters.search,
      sort: filters.sort,
    },
  })

  const {
    data: tags,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const columns = useMemo(() => getColumns(), [])

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleFilter = (nextFilters) => {
    setFilters({
      ...nextFilters,
      page: undefined,
    })
  }

  if (isError) {
    return <Page error={error.message} />
  }

  const actions = <CreateTagButton />

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  return (
    <>
      <PageHeader actions={actions} description={description} title="Tags" />
      <Filters initialFilters={filters} onSubmit={handleFilter} />
      <Table
        columns={columns}
        data={tags}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
    </>
  )
}

function getColumns() {
  return [
    {
      title: 'Tag Name',
      render: (tag) => tag.name,
    },
    {
      title: 'Followers',
      render: (tag) => tag.favorited_count,
    },
    {
      title: 'Video Mentions',
      render: (tag) => tag.videos_count,
    },
    {
      title: 'Status',
      render: (tag) => <TagStatus status={tag.status} />,
    },
    {
      title: 'Scope',
      className: 'capitalize',
      render: (tag) => tag.creation_scope,
    },
    {
      title: 'Creation Date',
      render: (tag) => formatDate(tag.created_at),
    },
    {
      className: 'text-right',
      render: (tag) => (
        <>
          <UpdateTagModal tag={tag} />
          <DeleteTagButton tag={tag} />
        </>
      ),
    },
  ]
}
