type getPaymentHistoryVariables = {
  userId?: number,
  status: string,
  includeUser: boolean,
  page?: number,
}

export default function getPaymentHistory(
  variables: getPaymentHistoryVariables = {}
) {
  const { includeUser, userId, status, page } = variables
  const includes = []

  if (includeUser) {
    includes.push('user')
  }
  const filters = {}

  if (userId) {
    filters.user_id = userId
  }

  if (status) {
    filters.status = status
  }

  return {
    name: 'paymentHistory',
    path: '/admin/memberships',
    params: {
      include: includes.length ? includes.join(',') : undefined,
      filters: filters,
      page: page > 1 ? page : undefined,
    },
  }
}
