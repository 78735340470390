import { useState } from 'react'
import colors from 'tailwindcss/colors'
import get from 'lodash/get'
import dayjs from 'dayjs'
import useQuery from 'core/react-query/useQuery'
import Page from 'admin/components/page'
import getCryptoCampaignsStatistics from 'admin/queries/getCryptoCampaignsStatistics'
import CampaignChart from './campaign-chart'
import Filters from './Filters'
import classes from './Chart.module.css'

const chartedData = (statistics, statisticsPeriod) => {
  const monthlyFilters = ['last_365d']
  const dailyFilters = [
    'last_7d',
    'last_14d',
    'last_30d',
    'last_90d',
    'last_180d',
  ]

  const getRegisteredClicks = () => {
    return Object.values(statistics).map((statistic) => {
      return Math.floor(statistic.registered_users_clicks)
    })
  }

  const getUnknownClicks = () => {
    return Object.values(statistics).map((statistic) => {
      return Math.floor(statistic.unknown_users_clicks)
    })
  }

  const labels = () => {
    if (dailyFilters.includes(statisticsPeriod)) {
      return statistics.map((item) => dayjs(item.date).format('D MMM'))
    } else if (monthlyFilters.includes(statisticsPeriod)) {
      return statistics.map((item) => dayjs(item.date).format('MMM'))
    }
    return []
  }

  return {
    labels: labels(),
    datasets: [
      {
        label: 'Registered users clicks',
        backgroundColor: colors.red['300'],
        borderColor: colors.red['300'],
        borderWidth: 1,
        data: getRegisteredClicks(),
        stack: 'Stack 0',
      },
      {
        label: 'Unknown users clicks',
        backgroundColor: colors.gray['500'],
        borderColor: colors.gray['500'],
        borderWidth: 1,
        data: getUnknownClicks(),
        stack: 'Stack 0',
      },
    ],
  }
}

export default function Chart({ campaignId }) {
  const [statisticsPeriod, setStatisticsPeriod] = useState('last_7d')
  const { data, isError } = useQuery(getCryptoCampaignsStatistics, {
    variables: {
      id: campaignId,
      statisticsPeriod: statisticsPeriod,
    },
  })

  const statisticsObject = get(data, 'json.statistics', [])
  const statistics = Object.values(statisticsObject)

  if (isError) {
    return <Page error={statistics.error.message} />
  }

  return (
    <div className={classes.charts}>
      <CampaignChart
        chartedData={chartedData}
        data={statistics}
        Filter={
          <Filters
            statisticsPeriod={statisticsPeriod}
            onChange={setStatisticsPeriod}
          />
        }
        statisticsPeriod={statisticsPeriod}
      />
    </div>
  )
}
