import { useEffect, useMemo, useState } from 'react'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import getComments from 'publisher/queries/getComments'
import getComment from 'publisher/queries/getComment'
import Layout from 'publisher/components/page'
import Page from 'publisher/components/page'
import Main from '../shared/components/Main'
import Filters from '../shared/components/Filters'

export default function AllComments() {
  const FILTER_CONFIG = useMemo(
    () => ({
      base: `/publisher/comments/all`,
      params: {
        page: 'pagination',
        sort: '' | 'most_liked' | 'oldest',
        video: 'number',
        time: 'string',
        perPage: 50 | 75 | 100,
        onlyRemembers: 'any',
        onlyMyMentions: 'any',
        onlyUnreadReplies: 'any',
      },
    }),
    []
  )
  const parsedLocation = useParsedLocation()
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)

  const [selectedComment, setSelectedComment] = useState()

  const { commentId } = parsedLocation.queryParams
  const { data: commentData, isLoadingComment } = useQuery(getComment, {
    enabled: commentId !== 'undefined' && !isNaN(commentId),
    variables: {
      id: commentId,
    },
  })

  const comment = get(commentData, 'json', null)
  useEffect(() => {
    if (comment) {
      setSelectedComment(comment)
    }
  }, [comment])

  const { data, error, isError, isFetching, isLoading } = useQuery(
    getComments,
    {
      keepPreviousData: true,
      variables: {
        page: Number(filters.page) || 1,
        sort: filters.sort,
        video: Number(filters.video),
        time: filters.time,
        perPage: filters.perPage,
        onlyRemembers: false,
        onlyMyMentions: false,
        onlyUnreadReplies: false,
      },
    }
  )

  const {
    data: comments,
    currentPage,
    lastPage,
  } = getPaginatedDataFromAPI(data?.json)

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  const handleCloseDrawer = () => {
    setSelectedComment()
  }

  const handleFilter = (next) => {
    setFilters(next)
    handleCloseDrawer()
  }

  const handleSetSelectedComment = (value) => {
    setSelectedComment(value)
  }

  if (isError) {
    return <Page error={error.message} />
  }

  return (
    <Layout width="lg">
      <Filters initialFilters={filters} onSubmit={handleFilter} />
      <Main
        comments={comments}
        isLoading={isLoading}
        isFetching={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
        onCloseDrawer={handleCloseDrawer}
        onSetSelectedComment={handleSetSelectedComment}
        selectedComment={selectedComment}
      />
    </Layout>
  )
}
