// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notifications_wrapper__2bvts {\n  width: 400px;\n}\n\n.notifications_wrapper__2bvts .notifications_list__3r1ur {\n  overflow-y: auto;\n  max-height: 40vh;\n}\n\n.notifications_wrapper__2bvts header {\n  background-color: var(--light);\n  border-color: var(--light-1);\n  border-top-left-radius: 0.25rem;\n  border-top-right-radius: 0.25rem;\n  border-bottom-width: 1px;\n  padding: 1rem;\n  top: 0px;\n  z-index: 10;\n}\n\n.dark .notifications_wrapper__2bvts header {\n  background-color: var(--dark-2);\n  border-color: var(--dark);\n}\n\n.notifications_wrapper__2bvts header div {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.notifications_loading__3PCcK {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 1.125rem;\n  line-height: 1.75rem;\n  opacity: 0.5;\n  padding: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/publisher/components/notifications/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;ECFA,gBAAmB;EDIjB,gBAAgB;AAClB;;AAEA;ECPA,8BAAmB;EAAnB,4BAAmB;EAAnB,+BAAmB;EAAnB,gCAAmB;EAAnB,wBAAmB;EAAnB,aAAmB;EAAnB,QAAmB;EAAnB,WAAmB;ADUnB;;ACVA;EAAA,+BAAmB;EAAnB;AAAmB;;ADYnB;ECZA,aAAmB;EAAnB,mBAAmB;EAAnB,8BAAmB;ADcnB;;AAEA;EChBA,aAAmB;EAAnB,mBAAmB;EAAnB,uBAAmB;EAAnB,mBAAmB;EAAnB,oBAAmB;EAAnB,YAAmB;EAAnB,aAAmB;ADkBnB","sourcesContent":[".wrapper {\n  width: 400px;\n}\n\n.wrapper .list {\n  @apply overflow-y-auto;\n  max-height: 40vh;\n}\n\n.wrapper header {\n  @apply bg-light border-b border-light-1 p-4 rounded-tl rounded-tr top-0 z-10;\n  @apply dark:bg-dark-2 dark:border-dark;\n}\n\n.wrapper header div {\n  @apply flex  items-center justify-between;\n}\n\n.loading {\n  @apply flex items-center justify-center opacity-50 text-lg p-4;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "notifications_wrapper__2bvts",
	"list": "notifications_list__3r1ur",
	"dark": "dark",
	"loading": "notifications_loading__3PCcK"
};
export default ___CSS_LOADER_EXPORT___;
