type PinCommentToVideosMutationVariables = {
  text: string,
  videos: Array<number>,
}

export default function pinCommentToVideos(
  variables: PinCommentToVideosMutationVariables
) {
  return {
    method: 'post',
    path: `/publisher/videos/bulk-pin`,
    params: {
      text: variables.text,
      videos: variables.videos,
    },
  }
}
