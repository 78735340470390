import { useState } from 'react'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/useMutation'
import Button from 'shared/components/kit/button'
import Modal from 'shared/components/kit/modal'
import notification from 'shared/components/kit/notification'
import deleteSubtitle from 'publisher/mutations/deleteSubtitle'
import getVideoSubtitles from 'publisher/queries/getVideoSubtitles'
import { ReactComponent as DeleteSVG } from 'icons/delete-filled.svg'

export default function DeleteSubtitleButton({ id }) {
  const [visible, setVisible] = useState(false)
  const queryClient = useQueryClient()

  const mutation = useMutation(deleteSubtitle, {
    onSuccess() {
      setVisible(false)
      notification.success({
        title: 'Subtitle deleted successfully!',
      })
      queryClient.invalidateQueries({ query: getVideoSubtitles })
    },
    onError(error) {
      setVisible(false)
      notification.error({ title: error.message })
    },
  })

  const handleCancel = () => {
    setVisible(false)
  }

  const handleOk = async () => {
    await mutation.mutateAsync({ id })
  }

  const handleClick = () => {
    setVisible(true)
  }

  return (
    <>
      <Button mood="neutral" onClick={handleClick} size="sm" transparent>
        <DeleteSVG />
      </Button>
      <Modal
        loading={mutation.isLoading}
        okText="Delete Subtitle"
        onCancel={handleCancel}
        onOk={handleOk}
        size="sm"
        title="Delete Subtitle"
        visible={visible}>
        <p>Are you sure you want to delete this Subtitle?</p>
      </Modal>
    </>
  )
}
