type RegisterVariables = {
  email: string,
  password: string,
  captcha: string,
  captchaKey: string,
}

export default function register(variables: RegisterVariables) {
  const { email, password, captcha, captchaKey } = variables

  return {
    path: '/register',
    params: {
      email,
      password,
      captcha,
      captcha_key: captchaKey,
    },
  }
}
