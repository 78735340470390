type VerifyAddressVariables = {
  proofCode: string,
}

export default function verifyAddress(variables: VerifyAddressVariables = {}) {
  const { proofCode } = variables
  return {
    method: 'POST',
    path: '/publisher/profile/payment-details/verify',
    params: {
      proof_code: proofCode,
    },
  }
}
