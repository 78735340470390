type DislikeCommentMutationVariables = {
  id: number,
}

export default function dislikeComment(
  variables: DislikeCommentMutationVariables
) {
  return {
    method: 'put',
    path: `/comments/${variables.id}/dislike`,
  }
}
