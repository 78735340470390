import Base from '../Base'

export default function AdsManagerLayout({ children }) {
  const sidebar = (
    <ul>
      <li>
        <Base.NavLink
          text="Banner ads campaigns"
          to="/admin/ads-manager/banner-ads-campaigns"
        />
      </li>
      <li>
        <Base.NavLink text="Banner ads" to="/admin/ads-manager/banner-ads" />
      </li>
      <li>
        <Base.NavLink
          text="Buy buttons"
          to="/admin/ads-manager/campaigns?status=active"
        />
      </li>
      <li>
        <Base.NavLink text="Settings" to="/admin/ads-manager/settings" />
      </li>
      <li>
        <Base.NavLink text="Back to dashboard" to="/admin" exact />
      </li>
    </ul>
  )
  return <Base main={children} sidebar={sidebar} />
}
