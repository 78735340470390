type DeleteCommentVariables = {
  id: number,
}
export default function deleteComment(variables: DeleteCommentVariables) {
  const { id } = variables

  return {
    method: 'DELETE',
    path: `/comments/${id}`,
  }
}
