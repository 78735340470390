import classnames from 'classnames'
import classes from './CircleProgress.module.css'

type CircleProgressProps = {
  primaryColor?: string,
  secondaryColor?: string,
  stroke?: 8,
  value: number,
}

export default function CircleProgress(props: CircleProgressProps) {
  const { primaryColor, secondaryColor, stroke, value } = props

  const percent = value || 0
  const offset = 339.292 * (1 - percent / 100)

  return (
    <div className={classes.wrapper}>
      <svg viewBox="0 0 120 120">
        <circle
          className={classnames(classes.circle, `text-${secondaryColor}`)}
          cx={60}
          cy={60}
          fill="none"
          r={54}
          strokeWidth={stroke}
        />
        <circle
          className={classnames(classes.progress, `text-${primaryColor}`)}
          cx={60}
          cy={60}
          fill="none"
          r={54}
          strokeDasharray={339.292}
          strokeDashoffset={offset}
          strokeLinecap="round"
          strokeWidth={stroke}
        />
      </svg>

      <span className={`text-${primaryColor}`}>{percent}%</span>
    </div>
  )
}

CircleProgress.defaultProps = {
  primaryColor: 'primaryColor-default',
  secondaryColor: 'overlayColor-light',
  stroke: 8,
}
