// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".payouts_key__3ANFh {\n  width: 33.333333%;\n}\n\n.payouts_value__3jdDJ {\n  width: 33.333333%;\n}\n\n.payouts_status__3KepH {\n  width: 33.333333%;\n}\n\n.payouts_updateReason__1EYki {\n  width: 33.333333%;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/monetization/payouts/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,iBAAmB;ADAnB;;AAEA;ECFA,iBAAmB;ADInB;;AAEA;ECNA,iBAAmB;ADQnB;;AAEA;ECVA,iBAAmB;ADYnB","sourcesContent":[".key {\n  @apply w-1/3;\n}\n\n.value {\n  @apply w-1/3;\n}\n\n.status {\n  @apply w-1/3;\n}\n\n.updateReason {\n  @apply w-1/3;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"key": "payouts_key__3ANFh",
	"value": "payouts_value__3jdDJ",
	"status": "payouts_status__3KepH",
	"updateReason": "payouts_updateReason__1EYki"
};
export default ___CSS_LOADER_EXPORT___;
