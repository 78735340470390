import { useCallback, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import classnames from 'classnames'
import Box from 'shared/components/kit/box'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import formatFullDate from 'shared/helpers/formatFullDate'
import EarnedReferralPointsChart from './EarnedReferralPointsChart'
import ReferralSignupsChart from './ReferralSignupsChart'
import getReferralStatistics from 'publisher/queries/getReferralStatistics'
import classes from './Charts.module.css'

export default function Charts() {
  const [filters, setFilters] = useState(() => ({
    period: 'last_30d',
  }))

  const { data, isFetching } = useQuery(getReferralStatistics, {
    usePreviousData: true,
    variables: {
      statisticsPeriod: filters.period,
    },
  })

  const dataObject = get(data, 'json.statistics') || []

  const dataArray = Object.values(dataObject)

  const labels = useMemo(() => {
    if (filters.period === 'last_180d' || filters.period === 'last_365d') {
      return dataArray.map((item) => dayjs(item.date).format('MMM'))
    }
    return dataArray.map((item) => dayjs(item.date).format('D MMM'))
  }, [data, filters.period])

  const getDate = useCallback(
    (date) => {
      if (filters.period === 'last_180d' || filters.period === 'last_365d') {
        return dayjs(date).format('MMMM YYYY')
      }
      return formatFullDate(date)
    },
    [filters.period]
  )

  const gridClassNames = classnames('grid grid-cols-2 gap-12', {
    'opacity-50': isFetching,
  })

  return (
    <Box className={classes.wrapper}>
      <header className={classes.header}>
        <h2>Charts</h2>
        <div>
          <HtmlSelect
            value={filters.period}
            onChange={(val) => setFilters({ period: val })}>
            <option value="last_7d">Last 7 Days</option>
            <option value="last_14d">Last 14 Days</option>
            <option value="last_30d">Last 30 Days</option>
            <option value="last_90d">Last 3 Months</option>
            <option value="last_180d">Last 6 Months</option>
            <option value="last_365d">Last 12 Months</option>
          </HtmlSelect>
        </div>
      </header>

      <div className={gridClassNames}>
        <ReferralSignupsChart
          data={dataArray}
          labels={labels}
          getDate={getDate}
        />
        <EarnedReferralPointsChart
          data={dataArray}
          labels={labels}
          getDate={getDate}
        />
      </div>
    </Box>
  )
}
