// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".crm_thumbnail__2068M {\n  width: 12rem;\n}\n\n.crm_thumbnail__2068M img {\n  width: 3.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/admin/pages/crm/index.module.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;ECEA,YAAmB;ADAnB;;AAEA;ECFA,aAAmB;ADInB","sourcesContent":[".thumbnail {\n  @apply w-48;\n}\n\n.thumbnail img {\n  @apply w-14;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnail": "crm_thumbnail__2068M"
};
export default ___CSS_LOADER_EXPORT___;
