import env from './env'

export default async function fetcher(url, options = {}) {
  const { baseUrl, formData, body, jwt, method } = options

  const headers = {
    Accept: 'application/json',
  }

  if (jwt) {
    headers.Authorization = `Bearer ${jwt}`
  }

  const apiBase = typeof baseUrl === 'undefined' ? env('API_URL') : baseUrl

  const absoluteUrl = `${apiBase}${url}`

  let payload

  if (body) {
    headers['Content-Type'] = 'application/json'
    payload = JSON.stringify(body)
  } else if (formData) {
    payload = formData
  }

  try {
    const response = await fetch(absoluteUrl, {
      body: payload,
      method,
      headers,
    })

    if (response.status >= 200 && response.status < 300) {
      let json

      try {
        json = await response.json()
      } catch (error) {}

      return {
        json,
      }
    }

    throw await response.json()
  } catch (error) {
    throw error
  }
}
