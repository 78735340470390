type UpdateChannelMutationVariables = {
  cover?: string,
  description?: string,
  facebook?: string,
  avatar?: string,
  id: number,
  instagram?: string,
  linkedin?: string,
  reddit?: string,
  name?: string,
  points?: number,
  slogan?: string,
  status?: 'draft' | 'published' | 'archived' | 'suspended' | 'freeze',
  telegram?: string,
  tiktok?: string,
  twitter?: string,
  website?: string,
}

export default function updateChannel(
  variables: UpdateChannelMutationVariables
) {
  const { id, ...fields } = variables
  return {
    method: 'put',
    path: `/admin/channels/${id}`,
    params: fields,
  }
}
