export const CHANNEL_STATUES = {
  draft: {
    color: 'bg-gray-400',
    label: 'Pending',
  },
  published: {
    color: 'bg-green-400',
    label: 'Published',
  },
  archived: {
    color: 'bg-yellow-500',
    label: 'Archived',
  },
  suspended: {
    color: 'bg-red-400',
    label: 'Suspended',
  },
  freeze: {
    color: 'bg-blue-400',
    label: 'Frozen',
  },
}

export function getAllChannelStatuses() {
  return Object.entries(CHANNEL_STATUES).map(([key, data]) => ({
    ...data,
    key,
  }))
}

export function getChannelStatus(key) {
  return CHANNEL_STATUES[key]
}
