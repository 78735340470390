const isPublisherApp = window.location.pathname.indexOf('/publisher') === 0

window.COLOR_SCHEME = {
  theme: 'dark',

  queuedTheme: null,

  forcedTheme: null,

  listeners: [],

  initialize() {
    if (isPublisherApp) {
      this.theme = localStorage.getItem('color-scheme') || 'dark'
    } else {
      this.theme = 'light'
    }
    document.getElementsByTagName('html')[0].classList.add(this.theme)
  },

  subscribe(listener: Function) {
    const listeners = this.listeners
    listeners.push(listener)
    return () => {
      const index = listeners.indexOf(listener)
      listeners.splice(index, 1)
    }
  },

  force(theme: 'dark' | 'light') {
    this.switch(theme)
    this.forcedTheme = theme
  },

  unForce() {
    this.forcedTheme = null
    this.switch(this.queuedTheme)
    this.queuedTheme = null
  },

  switch(theme: 'dark' | 'light') {
    if (this.forcedTheme) {
      this.queuedTheme = theme
      return
    }
    if (theme) {
      if (theme !== this.theme) {
        this.theme = theme
      }
    } else {
      this.theme = this.theme === 'dark' ? 'light' : 'dark'
    }

    const root = document.getElementsByTagName('html')[0]
    root.classList.remove('dark')
    root.classList.remove('light')
    root.classList.add(this.theme)

    this.listeners.forEach((listener) => {
      listener(this.theme)
    })

    localStorage.setItem('color-scheme', this.theme)
  },
}

window.COLOR_SCHEME.initialize()
