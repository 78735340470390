import useQuery from 'core/react-query/useQuery'
import getPaginatedDataFromAPI from 'helpers/utils/getPaginatedDataFromAPI'
import getPaginatedTableDescription from 'helpers/utils/getPaginatedTableDescription'
import formatDate from 'helpers/utils/formatDate'
import Alert from 'shared/components/kit/alert'
import Table from 'shared/components/kit/table'
import ChannelAvatar from 'shared/components/channel-avatar'
import getChannels from 'admin/queries/getChannels'
import Page from 'admin/components/page'
import useFiltersFromURL from 'helpers/hooks/useFiltersFromURL'
import Filters from './components/Filter'

const FILTER_CONFIG = {
  base: '/admin/deleted-channels',
  params: {
    page: 'pagination',
    search: 'any',
    sort: 'any',
  },
}

export default function DeletedChannels() {
  const [filters, setFilters] = useFiltersFromURL(FILTER_CONFIG)
  const { data, error, isError, isFetching } = useQuery(getChannels, {
    keepPreviousData: true,
    variables: {
      ...filters,
      onlyDeleted: true,
      sort: 'last_deleted',
    },
  })

  const {
    data: channels,
    currentPage,
    lastPage,
    total,
  } = getPaginatedDataFromAPI(data?.json)

  const handlePaginate = (page) => {
    setFilters((prev) => ({ ...prev, page }))
  }

  if (isError) {
    return <Alert title={error} content={error?.message} type="error" />
  }

  const description = getPaginatedTableDescription({
    currentPage: filters.page,
    lastPage,
    total,
  })

  return (
    <Page description={description} title="Deleted Channels">
      <Filters initialFilters={filters} onSubmit={setFilters} />
      <Table
        columns={columns}
        data={channels}
        loading={isFetching}
        currentPage={currentPage}
        lastPage={lastPage}
        onPaginate={handlePaginate}
      />
    </Page>
  )
}

const columns = [
  {
    className: 'w-24',
    render: (channel) => <ChannelAvatar channel={channel} size={14} />,
  },
  {
    className: 'w-1/6',
    title: 'Channel Name',
    render: (channel) => channel.name,
  },
  {
    className: 'w-2/6 break-all',
    title: 'Feedback',
    render: (channel) => channel.owner.deletion_feedback,
  },
  {
    align: 'center',
    className: '1/6',
    title: 'Deletion Date',
    render: (channel) =>
      channel.deleted_at ? formatDate(channel.deleted_at) : '-',
  },

  {
    align: 'center',
    className: '1/6',
    title: 'Creation Date',
    render: (channel) => formatDate(channel.created_at),
  },
]
