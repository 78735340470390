type resetPasswordMutationVariables = {
  token: string,
  password: string,
}

export default function ResetPassword(
  variables: resetPasswordMutationVariables = {}
) {
  const { token, password } = variables
  return {
    method: 'put',
    path: '/password/reset',
    params: {
      token,
      password,
    },
  }
}
