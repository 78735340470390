import colors from 'tailwindcss/colors'
import dayjs from 'dayjs'
import EarningsChart from 'shared/components/earnings-chart'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import Box from 'shared/components/kit/box'
import Page from 'admin/components/page'
import usePayoutEarningStatistics from '../hooks/usePayoutEarningStatistics'
import classes from '../index.module.css'

const chartedData = (statistics) => {
  const getMonths = () => {
    return Object.keys(statistics).map((statistic) => {
      return dayjs(statistic).format('MMM')
    })
  }

  const getAmounts = () => {
    return Object.values(statistics).map((statistic) => {
      return Math.floor(statistic.amount)
    })
  }

  const getDates = () => getMonths()

  return {
    labels: getDates(),
    datasets: [
      {
        label: 'Payouts',
        backgroundColor: colors.lime['300'],
        borderColor: colors.lime['300'],
        data: getAmounts(),
      },
    ],
  }
}

export default function PayoutEarnings() {
  const statistics = usePayoutEarningStatistics()

  if (statistics.isError) {
    return <Page error={statistics.error.message} />
  }

  if (statistics.isLoading) {
    return (
      <Box className={classes.boxLoading}>
        <div className={classes.loading}>
          <Icon>
            <Spin />
          </Icon>
          <div>Loading...</div>
        </div>
      </Box>
    )
  }

  return (
    <EarningsChart
      chartedData={chartedData}
      data={statistics.data}
      Filter={
        <HtmlSelect>
          <option>This Year</option>
        </HtmlSelect>
      }
      title="Payout"
    />
  )
}
