import { Route, Switch, useParams } from 'react-router'
import useChannel from 'admin/hooks/useChannel'
import Alert from 'shared/components/kit/alert'
import Layout from 'admin/components/layouts/channel'
import ChannelOverview from './overview'
import ChannelVideos from './videos'
import Icon from 'shared/components/kit/icon'
import Spin from 'shared/components/kit/spin'
import ChannelEarnings from './earnings'
import AddressHistory from './Address-history'
import classes from './index.module.css'

export default function Channel() {
  const { channelId } = useParams()
  const { error, isError, isLoading } = useChannel(channelId)

  if (isError) {
    return (
      <Layout>
        <div className={classes.error}>
          <Alert title={error.json.message} type="error" />
        </div>{' '}
      </Layout>
    )
  }

  return (
    <Layout>
      {isLoading ? (
        <div className={classes.loading}>
          <Icon>
            <Spin />
          </Icon>
          <div>Loading...</div>
        </div>
      ) : (
        <Switch>
          <Route component={ChannelOverview} path="/admin/channels/:id" exact />
          <Route
            component={ChannelVideos}
            path="/admin/channels/:id/videos"
            exact
          />
          <Route
            component={ChannelEarnings}
            path="/admin/channels/:id/earnings"
            exact
          />
          <Route
            component={AddressHistory}
            path="/admin/channels/:id/address-history"
          />
          <Route
            component={ChannelOverview}
            path="/admin/channels/:id/:section?"
            exact
          />
        </Switch>
      )}
    </Layout>
  )
}
