import { useCallback, useMemo } from 'react'
import useColorScheme from './useColorScheme'

export default function useGetCSSColor() {
  const scheme = useColorScheme()

  const style = useMemo(() => {
    return getComputedStyle(document.body)
  }, [scheme])

  return useCallback(
    (identifiers, opacity = 1) => {
      const keys = identifiers.split(' ').filter((key) => key)

      if (
        keys.length === 0 ||
        keys.length > 2 ||
        (keys.length === 2 && keys[1].indexOf('dark:') !== 0)
      ) {
        throw new Error('CSS color names invalid')
      }

      const light = keys[0]
      const dark = keys[1] ? keys[1].replace('dark:', '') : light
      const color = style.getPropertyValue(
        `--${scheme === 'light' ? light : dark}`
      )
      const opacityHexa =
        opacity < 1 ? Math.round(opacity * 255).toString(16) : ''
      return `${color}${opacityHexa}`
    },
    [style]
  )
}
