type WinnerPay = {
  paymentMethodId: number,
  reference: string,
  amount: number,
  id: number,
}

export default function winnerPay(variables: WinnerPay = {}) {
  const { reference, amount, id, paymentMethodId } = variables

  return {
    method: 'PUT',
    path: `/admin/lotteries/${id}/paid`,
    params: {
      payment_method_id: paymentMethodId,
      reference,
      amount,
    },
  }
}
