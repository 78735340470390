type ContentMutationVariables = {
  key: string,
  content: string,
  status: string,
}

export default function saveContents(variables: ContentMutationVariables = {}) {
  const { key, content, status } = variables
  return {
    method: 'POST',
    path: '/admin/contents',
    params: {
      key,
      content,
      status,
    },
  }
}
