type Variables = {
  data: any,
  videoId: number,
}

export default function saveVideoStudio(variables: Variables) {
  const { data, videoId } = variables

  return {
    path: `/publisher/videos/${videoId}/meta/studio`,
    params: {
      value: data,
    },
  }
}
