import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import get from 'lodash/get'
import useQueryClient from 'core/react-query/useQueryClient'
import useMutation from 'core/react-query/useMutation'
import useQuery from 'core/react-query/useQuery'
import getTimeFromNow from 'helpers/utils/getTimeFromNow'
import Box from 'shared/components/kit/box'
import Spin from 'shared/components/kit/spin'
import notification from 'shared/components/kit/notification'
import useParsedLocation from 'helpers/hooks/useParsedLocation'
import getComment from 'publisher/queries/getComment'
import likeComment from 'publisher/queries/likeComment'
import dislikeComment from 'publisher/queries/dislikeComment'
import Comment from './Comment'
import ReplyForm from './ReplyForm'
import classes from './Replies.module.css'

type Props = {
  commentId?: number,
}

export default function Replies({ commentId, onClose }: Props) {
  const queryClient = useQueryClient()
  const [mentioned, setMentioned] = useState()
  const like = useMutation(likeComment)
  const dislike = useMutation(dislikeComment)
  const history = useHistory()
  const parsedLocation = useParsedLocation()
  const { commentId: _commentId, replyId } = parsedLocation.queryParams

  const { data: commentData, isLoadingComment } = useQuery(getComment, {
    enabled: _commentId !== 'undefined' && !isNaN(_commentId),
    variables: {
      id: _commentId,
    },
  })

  const mentionedComment = get(commentData, 'json', null)
  const mentionedUser =
    mentionedComment &&
    mentionedComment.replies.find((reply) => reply.id == replyId)

  const { data, isLoading } = useQuery(getComment, {
    enabled: !!commentId,
    variables: {
      id: commentId,
    },
  })

  const comment = data?.json

  useEffect(() => {
    if (mentionedUser) {
      handleAddMentioned({
        replyId: Number(replyId),
        user: mentionedUser?.user,
      })
      const mentionedReplyNode = document.getElementById(replyId.toString())
      if (mentionedReplyNode) {
        mentionedReplyNode.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [mentionedUser, mentionedComment, comment])

  const handleAddMentioned = (info) => {
    setMentioned(info)
  }

  const handleRemoveMentioned = () => {
    history.replace({
      search: null,
    })
    setMentioned(null)
  }

  const handleToggleLikeReply = (replyId) => {
    like.mutate(
      { id: replyId },
      {
        onSuccess(response) {
          queryClient.setQueryData({
            query: getComment,
            variables: {
              id: commentId,
            },
            updater: (prev) => ({
              ...prev,
              json: {
                ...prev.json,
                replies: prev.json.replies.map((reply) => {
                  if (reply.id === replyId) {
                    return {
                      ...reply,
                      dislikes_count: reply.is_disliked
                        ? reply.dislikes_count - 1
                        : reply.dislikes_count,
                      is_disliked: false,
                      is_liked: !reply.is_liked,
                      likes_count:
                        reply.likes_count + (reply.is_liked ? -1 : 1),
                    }
                  }
                  return reply
                }),
              },
            }),
          })
        },
        onError(error) {
          notification.error({ title: error.message })
        },
      }
    )
  }

  const handleToggleDislikeReply = (replyId) => {
    dislike.mutate(
      { id: replyId },
      {
        onSuccess() {
          queryClient.setQueryData({
            query: getComment,
            variables: {
              id: commentId,
            },
            updater: (prev) => ({
              ...prev,
              json: {
                ...prev.json,
                replies: prev.json.replies.map((reply) => {
                  if (reply.id === replyId) {
                    return {
                      ...reply,
                      dislikes_count:
                        reply.dislikes_count + (reply.is_disliked ? -1 : 1),
                      is_disliked: !reply.is_disliked,
                      is_liked: false,
                      likes_count: reply.is_liked
                        ? reply.likes_count - 1
                        : reply.likes_count,
                    }
                  }
                  return reply
                }),
              },
            }),
          })
        },
        onError(error) {
          notification.error({ title: error.message })
        },
      }
    )
  }

  if (isLoading) {
    return (
      <Box className={classes.noComment}>
        <Spin />
      </Box>
    )
  }

  if (!comment) {
    return (
      <Box className={classes.noComment}>To reply, click on a comment.</Box>
    )
  }

  return (
    <div className={classes.wrapper}>
      <span onClick={onClose} className={classes.close}>
        Close ✕
      </span>
      <Box className={classes.header}>
        <Comment comment={comment} inThread={true} />
      </Box>

      <ReplyForm
        onRemoveMentioned={handleRemoveMentioned}
        mentioned={mentioned}
        commentId={commentId}
      />

      {comment.replies.length > 0 && (
        <Box className={classes.comments}>
          <div>
            <h3>Comment thread</h3>
            <h4>
              Last reply: {getTimeFromNow(comment.replies[0].created_at)}{' '}
            </h4>
          </div>
          <ul>
            {comment.replies.map((reply) => (
              <li key={reply.id}>
                <Comment
                  comment={reply}
                  onAddMentioned={handleAddMentioned}
                  onRemoveMentioned={handleRemoveMentioned}
                  mentioned={mentioned}
                  parentId={commentId}
                  parentComment={comment}
                  onDislike={() => handleToggleDislikeReply(reply.id)}
                  onLike={() => handleToggleLikeReply(reply.id)}
                />
              </li>
            ))}
          </ul>
        </Box>
      )}
    </div>
  )
}
