type GetCampaignDetails = {
  campaignId: number,
}

export default function getCampaignDetails(variables: GetCampaignDetails = {}) {
  const { campaignId } = variables
  return {
    path: `/admin/crypto-campaigns/${campaignId}`,
  }
}
