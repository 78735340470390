import useQuery from 'core/react-query/useQuery'
import get from 'lodash/get'
import Select from 'shared/components/form/select'
import getLanguages from 'shared/queries/getLanguages'
import Field from '../field'

export default function SelectLanguage(props) {
  const { data, isLoading } = useQuery(getLanguages)
  const languages = get(data, 'json', [])
  const options = languages.map((opt) => ({
    label: opt.display_name,
    value: opt.id,
  }))
  return (
    <Select
      isLoading={isLoading}
      placeholder="Select language..."
      {...props}
      options={options}
    />
  )
}

SelectLanguage.Field = ({ error, label, ...inputProps }) => (
  <Field
    error={error}
    help="Enter the language you speak in the video/podcast. This makes it easier for users with the same language to find your upload."
    label="Select Language">
    <SelectLanguage {...inputProps} />
  </Field>
)
