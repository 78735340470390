type Variables = {
  cover?: string,
  description?: string,
  facebook?: string,
  avatar?: string,
  id: number,
  instagram?: string,
  linkedin?: string,
  reddit?: string,
  slogan?: string,
  telegram?: string,
  tiktok?: string,
  twitter?: string,
  website?: string,
}

export default function updateChannel(variables: Variables) {
  return {
    method: 'put',
    path: '/publisher/channel',
    params: variables,
  }
}
