type ChangePaymentDetailStatus = {
  ids: Array<string>,
  status: string,
}

export default function changePaymentDetailStatus(
  variables: ChangePaymentDetailStatus = {}
) {
  const { ids, status } = variables
  return {
    method: 'POST',
    path: '/admin/payment-details/change-status',
    params: {
      ids,
      status,
    },
  }
}
