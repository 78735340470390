type GetCryptoCampaignsStatistics = {
  id: number,
  statisticsPeriod?: string,
}
export default function getCryptoCampaignsStatistics(
  variables: GetCryptoCampaignsStatistics = {}
) {
  const { id, statisticsPeriod } = variables

  const filters = {}

  if (statisticsPeriod) {
    filters.statistics_period = statisticsPeriod
  }

  return {
    path: `/admin/crypto-campaigns/${id}/statistics`,
    params: {
      filters,
    },
  }
}
