import { Children, useState } from 'react'
import Popover from 'shared/components/kit/popover'
import classes from './index.module.css'

type TruncatedListProps = {
  data?: Array,
  inline?: boolean,
  pluralSuffix?: string,
  singularSuffix?: string,
  visible: number,
}

export default function TruncatedList(props: TruncatedListProps) {
  const {
    children,
    hiddenListClassName,
    inline,
    pluralSuffix,
    singularSuffix,
    visible,
    visibleListClassName,
  } = props

  const [show, setShow] = useState(false)

  const data = Children.map(children, (child) => child)

  if (data.length === 0) {
    return null
  }

  let visibleItems
  let hiddenItems = []
  let visibleContent
  let hiddenContent
  let suffix

  if (data.length <= visible) {
    visibleItems = [...data]
  } else {
    visibleItems = data.slice(0, visible)
    hiddenItems = data.slice(visible)

    if (pluralSuffix || singularSuffix) {
      suffix =
        hiddenItems.length === 1
          ? singularSuffix || pluralSuffix
          : pluralSuffix || singularSuffix
      suffix = ` ${suffix}`
    }

    hiddenContent = <ul className={hiddenListClassName}>{hiddenItems}</ul>
  }

  if (inline) {
    visibleContent = visibleItems.join(', ')
  } else {
    visibleContent = <ul className={visibleListClassName}>{visibleItems}</ul>
  }

  return (
    <>
      {visibleContent}
      {hiddenItems.length > 0 && (
        <Popover
          content={hiddenContent}
          onHide={() => setShow(false)}
          visible={show}>
          <span className={classes.more} onClick={() => setShow(true)}>
            {inline ? (
              <>
                &nbsp;&nbsp;(Show {hiddenItems.length} more{suffix})
              </>
            ) : (
              <>
                Show {hiddenItems.length} more{suffix}
              </>
            )}
          </span>
        </Popover>
      )}
    </>
  )
}

TruncatedList.defaultProps = {
  visible: 1,
}
