import { useHistory, useParams } from 'react-router'
import get from 'lodash/get'
import useQuery from 'core/react-query/useQuery'
import Button from 'shared/components/kit/button'
import DesktopPage from './components/DesktopPage'
import MobilePage from './components/MobilePage'
import getAdSpaces from 'admin/queries/getAdSpaces'

export default function AdsManager() {
  const history = useHistory()
  const { displayType } = useParams()

  const { data } = useQuery(getAdSpaces)

  const adsSpaces = get(data, 'json', [])
  const actions = (
    <div className="flex gap-4">
      <Button
        onClick={() => history.push('/admin/ads-manager/banner-ads/desktop')}
        size="sm"
        transparent={displayType === 'mobile'}
        secondary>
        Desktop
      </Button>
      <Button
        onClick={() => history.push('/admin/ads-manager/banner-ads/mobile')}
        size="sm"
        transparent={displayType === 'desktop'}
        secondary>
        Mobile
      </Button>
    </div>
  )
  return displayType === 'desktop' ? (
    <DesktopPage adsSpaces={adsSpaces} actions={actions} />
  ) : (
    <MobilePage adsSpaces={adsSpaces} actions={actions} />
  )
}
