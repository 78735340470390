import { useRef, useState } from 'react'
import Button from 'shared/components/kit/button'
import Field from 'shared/components/form/field'
import Input from 'shared/components/form/input'
import Modal from 'shared/components/kit/modal'
import useTimelineActions from '../hooks/useTimelineActions'

export default function Chapter({ chapter, videoId }) {
  const [edit, setEdit] = useState(false)
  const [title, setTitle] = useState(chapter.title || '')

  const { updateChapter, deleteChapter } = useTimelineActions()

  const submitButtonRef = useRef()

  const handleCancel = () => {
    setEdit(false)
  }

  const handleOk = () => {
    submitButtonRef.current.click()
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    updateChapter(chapter.id, (prev) => ({
      ...prev,
      from: chapter.from,
      title,
    }))
    setEdit(false)
  }

  const handleDelete = () => {
    deleteChapter(chapter.id)
  }

  const handleClick = (event) => {
    setEdit(true)
  }

  const handleMouseDown = (event) => {
    event.stopPropagation()
  }

  const modalLeftFooter = (
    <Button onClick={handleDelete} noHorizontalPadding transparent>
      Delete Chapter
    </Button>
  )

  const modalRightFooter = (
    <>
      <Button mood="neutral" onClick={handleCancel} transparent>
        Cancel
      </Button>
      <Button onClick={handleOk}>Save</Button>
    </>
  )

  return (
    <>
      <li
        key={chapter.id}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        style={{ width: chapter.width }}>
        {chapter.title}
      </li>

      <Modal
        footerLeft={modalLeftFooter}
        footerRight={modalRightFooter}
        onCancel={handleCancel}
        size="sm"
        title="Edit Chapter"
        visible={edit}>
        <form onSubmit={handleSubmit}>
          <Field label="Chapter Title" required>
            <Input
              onChange={setTitle}
              placeholder="Chapter Title"
              value={title}
              required
            />
          </Field>
          <button ref={submitButtonRef} hidden />
        </form>
      </Modal>
    </>
  )
}
