import { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { ReactComponent as UploadSvg } from 'svg/upload.svg'
import classes from './index.module.css'

type Props = {
  className?: string,
  onChange: Function,
}

export default function FileInput(props: Props) {
  const { className, onChange, placeholder, value } = props

  const ref = useRef()

  const fileSelected = !!value

  useEffect(() => {
    if (typeof value === 'undefined') {
      ref.current.value = null
      setPlaceHolder(placeholder)
    }
  }, [placeholder, value])

  const [placeHolder, setPlaceHolder] = useState(placeholder)

  const handleChange = async (event) => {
    const file = event.target.files[0]
    setPlaceHolder(file ? file.name : placeholder)
    try {
      onChange(file)
    } catch (error) {
      alert(error.message)
    }
  }

  const wrapperClassName = classnames(classes.wrapper, className, {
    [classes.selected]: fileSelected,
  })

  return (
    <label className={wrapperClassName}>
      <input ref={ref} onChange={handleChange} type="file" />
      <div className={classes.placeholder}>
        <UploadSvg />
        <p>{placeHolder}</p>
      </div>
    </label>
  )
}

FileInput.defaultProps = {
  placeholder: 'Choose a File...',
}
