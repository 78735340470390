import { useMemo } from 'react'
import { useParams } from 'react-router'
import useQuery from 'core/react-query/useQuery'
import getVideoSubtitles from 'publisher/queries/getVideoSubtitles'

export default function useVideoSubtitles() {
  const { id } = useParams()

  const { data, ...rest } = useQuery(getVideoSubtitles, {
    keepPreviousData: true,
    variables: {
      id: Number(id),
    },
  })

  const subtitles = useMemo(() => (data ? data.json : []), [data])

  return {
    ...rest,
    data,
    subtitles,
  }
}
