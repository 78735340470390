// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".upload-video-modal_top__134SD > :not([hidden]) ~ :not([hidden]) {\n  --tw-space-x-reverse: 0;\n  margin-right: calc(1.5rem * var(--tw-space-x-reverse));\n  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));\n}\n\n.upload-video-modal_top__134SD {\n  display: flex;\n}\n\n.upload-video-modal_fields__2tRCT {\n  width: 60%;\n}\n\n.upload-video-modal_preview__10xUC {\n  width: 40%;\n}\n\n.upload-video-modal_thumbnail__2mt0p {\n  width: 210px;\n}\n\n.upload-video-modal_title__20Dvr {\n  flex-grow: 1;\n}\n\n.upload-video-modal_error__2-f5X {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 50vh;\n}\n", "",{"version":3,"sources":["webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js","webpack://src/publisher/components/upload-video-modal/index.module.css"],"names":[],"mappings":"AAEA;EAAA,uBAAmB;EAAnB,sDAAmB;EAAnB;AAAmB;;AAAnB;EAAA;AAAmB;;ACEnB;EDFA,UAAmB;ACInB;;AAEA;EDNA,UAAmB;ACQnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EDlBA,aAAmB;EAAnB,mBAAmB;EAAnB,uBAAmB;ECoBjB,YAAY;AACd","sourcesContent":["@tailwind base;\n@tailwind components;\n@tailwind utilities;",".top {\n  @apply flex space-x-6;\n}\n\n.fields {\n  @apply w-3/5;\n}\n\n.preview {\n  @apply w-2/5;\n}\n\n.thumbnail {\n  width: 210px;\n}\n\n.title {\n  flex-grow: 1;\n}\n\n.error {\n  @apply flex items-center justify-center;\n  height: 50vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top": "upload-video-modal_top__134SD",
	"fields": "upload-video-modal_fields__2tRCT",
	"preview": "upload-video-modal_preview__10xUC",
	"thumbnail": "upload-video-modal_thumbnail__2mt0p",
	"title": "upload-video-modal_title__20Dvr",
	"error": "upload-video-modal_error__2-f5X"
};
export default ___CSS_LOADER_EXPORT___;
