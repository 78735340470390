type GetChannelsQueryVariables = {
  includeOwner?: false,
  page?: number,
  search?: string,
}

export default function getChannels(variables: GetChannelsQueryVariables = {}) {
  const { includeOwner, page, search } = variables

  return {
    name: 'channels',
    path: '/channels',
    params: {
      include: includeOwner ? 'owner' : undefined,
      page: page > 0 ? page : undefined,
      filters: search
        ? {
            search,
          }
        : undefined,
    },
  }
}
