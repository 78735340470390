type AddSubtitle = {
  file: any,
  id: number,
  languageId: string,
}

export default function addSubtitle(variables: AddSubtitle = {}) {
  const { languageId, id, file } = variables

  const params = new FormData()

  params.append('file', file)
  params.append('language_id', languageId)

  return {
    method: 'post',
    path: `/publisher/videos/${id}/subtitles`,
    params,
  }
}
