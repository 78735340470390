import { useMemo } from 'react'
import useQuery from 'core/react-query/useQuery'
import get from 'lodash/get'
import getReasons from 'admin/queries/getReasons'
import ReasonStatus from 'shared/components/reason-status'
import Table from 'shared/components/kit/table'
import Page from 'admin/components/page'
import AddReason from './components/AddReason'
import UpdateReason from './components/UpdateReason'
import classes from './index.module.css'

export default function DeletedVideoReasons() {
  const { data, error, isError, loading, isLoading } = useQuery(getReasons, {
    keepPreviousData: true,
    variables: {
      key: 'video_delete_reasons',
    },
  })

  let reasons = get(data, 'json', []).filter((reason) => {
    reason.status = reason.status === 'active' ? 'active' : 'inactive'
    return reason
  })

  const columns = useMemo(() => getColumns(reasons), [reasons])

  const actions = <AddReason reasons={reasons} />

  return (
    <>
      <Page actions={actions} title="Manage delete video reasons">
        <Table columns={columns} data={reasons} loading={isLoading} />
      </Page>
    </>
  )
}

function getColumns(reasons) {
  return [
    {
      className: classes.key,
      title: 'Key',
      render: (reason) => reason.key,
    },
    {
      className: classes.value,
      title: 'Reason',
      render: (reason) => reason.value,
    },
    {
      className: classes.status,
      title: 'Status',
      render: (reason) => <ReasonStatus status={reason.status} />,
    },
    {
      align: 'right',
      className: classes.updateReason,
      render: (reason) => <UpdateReason reason={reason} reasons={reasons} />,
    },
  ]
}
