type getNotificationsSentByAdminQueryVariables = {
  page?: number,
  userGroup?: string,
  userId?: number,
  publisherId?: number,
  justDeleted?: number,
}

export default function getNotificationsSentByAdmin(
  variables: getNotificationsSentByAdminQueryVariables = {}
) {
  const { page, userId, publisherId, userGroup, justDeleted } = variables
  const filters = {}

  if (
    publisherId ||
    userId ||
    filters.user_group === 'singleUser' ||
    filters.user_group === 'singlePublisher'
  ) {
    filters.user_id = userId || publisherId
  } else if (userGroup) {
    filters.user_group = userGroup
  }

  if (justDeleted) {
    filters.just_deleted = justDeleted
  }

  return {
    name: 'notificationsSentByAdmin',
    path: '/admin/notifications/sent-by-admin',
    params: {
      page,
      filters,
    },
  }
}
