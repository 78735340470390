import { useEffect } from 'react'
import useMiniForm from 'core/useMiniForm'
import useQuery from 'core/react-query/useQuery'
import get from 'lodash/get'
import Button from 'shared/components/kit/button'
import HtmlSelect from 'shared/components/form/select/HtmlSelect'
import getReasons from 'admin/queries/getReasons'
import classes from './Filters.module.css'

export default function Filters({ initialFilters, onSubmit, commentId }) {
  const { data } = useQuery(getReasons, {
    variables: {
      key: 'comment_report_reasons',
    },
  })
  let reasons = get(data, 'json', [])

  useEffect(() => {
    form.change('reason', '')
    form.submit()
  }, [commentId])

  const form = useMiniForm({
    initialValues: initialFilters,
    onSubmit(values, event) {
      if (event && event.preventDefault) {
        event.preventDefault()
      }
      onSubmit(values)
    },
  })

  return (
    <form className={classes.filters} onSubmit={form.submit}>
      <HtmlSelect {...form.getInputPropsFor('reason')}>
        <option value="">All Reasons</option>
        {reasons.map((text, key) => (
          <option key={key} value={text.key}>
            {text.value}
          </option>
        ))}
      </HtmlSelect>
      <Button outlined>Search Reports</Button>
    </form>
  )
}
