import Select from 'shared/components/form/select'

export default function SelectTiers(props) {
  const options = [
    {
      label: 'All Tiers',
      value: 'all',
    },
    {
      label: 'Tier 1',
      value: '1',
    },
    {
      label: 'Tier 2',
      value: '2',
    },
    {
      label: 'Tier 3',
      value: '3',
    },
    {
      label: 'Tier 4',
      value: '4',
    },
    {
      label: 'Tier 5',
      value: '5',
    },
  ]

  return <Select {...props} options={options} placeholder="Select Tier..." />
}
