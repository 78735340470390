import React from 'react'
import { Redirect } from 'react-router'
import cookie from 'core/cookie'
import useQueryClient from 'core/useQueryClient'
import useProfile from 'shared/hooks/useProfile'
import useWatchNotifications from 'shared/hooks/useWatchNotifications'
import getUnreadNotifications from 'admin/queries/getUnreadNotifications'
import getNotifications from 'admin/queries/getNotifications'
import Icon from 'shared/components/kit/icon'
import notification from 'shared/components/kit/notification'
import Spin from 'shared/components/kit/spin'

export default function Auth({ children }) {
  const jwt = cookie().get('jwt')

  return jwt ? (
    <Authenticated>{children}</Authenticated>
  ) : (
    <Redirect to="/admin/login" />
  )
}

function Authenticated({ children }) {
  const { isLoading, role } = useProfile()

  const queryClient = useQueryClient()

  useWatchNotifications(
    'admin',
    (data) => {
      notification({
        title: 'You have a new notification',
      })

      queryClient.setQueryData(getUnreadNotifications().name, (prev) => ({
        json: {
          ...prev.json,
          count: prev.json.count + 1,
        },
      }))

      queryClient.invalidateQueries(getNotifications().name)
    },
    [isLoading, queryClient]
  )

  if (isLoading) {
    return (
      <div className="absolute flex h-full items-center justify-center left-0 opacity-50 text-lg top-0 w-full">
        <Icon>
          <Spin />
        </Icon>
        <div>Loading...</div>
      </div>
    )
  }

  if (role !== 'admin') {
    return <Redirect to="/admin/login?error=unauthorized" />
  }

  return children
}
