import { useMemo } from 'react'
import { useQuery as useReactQuery, useQueryClient } from 'react-query'
import createUrl from 'core/createUrl'

export default function useQuery(query, options = {}) {
  const { variables, ...reactQueryOptions } = options

  const { name, path, params } = query(variables)

  const queryClient = useQueryClient()

  const queryKey = useMemo(() => [name, path, params], [name, path, params])

  const queryFn = () => queryClient.fetcher(createUrl(path, params))

  const qr = useReactQuery(queryKey, queryFn, {
    ...reactQueryOptions,
  })

  return { ...qr, queryKey }
}
