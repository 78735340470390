window._TC_VISIBLE_MODALS_ = []
window._TC_NEXT_MODAL_ID_ = 1

export default function modals() {
  function fixStyles() {
    window._TC_VISIBLE_MODALS_.forEach((modal, index) => {
      if (index === window._TC_VISIBLE_MODALS_.length - 1) {
        modal.parent.style.display = 'block'
      } else {
        modal.parent.style.display = 'none'
      }
    })
  }

  return {
    add(id, parent) {
      window._TC_VISIBLE_MODALS_.push({
        id,
        parent,
      })
      fixStyles()
    },

    remove(id) {
      window._TC_VISIBLE_MODALS_ = window._TC_VISIBLE_MODALS_.filter(
        (item) => item.id !== id
      )
      fixStyles()
    },

    getNextId() {
      return window._TC_NEXT_MODAL_ID_++
    },

    getVisibleModalsCount() {
      return window._TC_VISIBLE_MODALS_.length
    },
  }
}
